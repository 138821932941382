import React from "react";

const ProfileButton = ({
  onClick,
  text,
  iconAlign = "",
  size = "",
  variant = "text",
  icon,
  disabled = false,
}) => {
  const customIconAlignClass = (iconAlign) => {
    switch (iconAlign.toLowerCase()) {
      case "left":
        return "flex-row-reverse";
      case "right":
        return "flex-row";
      default:
        return "";
    }
  };
  const customSizeClass = (size) => {
    switch (size.toLowerCase()) {
      case "large":
        return "py-4 text-md  min-w-[120px] ";
      case "small":
        return "py-3 text-sm  min-w-[80px]";
      default:
        return "";
    }
  };
  const customVariantClass = (variant) => {
    switch (variant.toLowerCase()) {
      case "filled":
        return ` text-white bg-primary-blue-500 hover:bg-primary-blue-400 focus:bg-primary-blue-300  active:bg-primary-blue-300 disabled:bg-primary-grey-200 disabled:text-primary-grey-400  `;
      case "quest-filled":
        return ` text-white bg-primary-purple-700 hover:bg-primary-purple-500 focus:bg-primary-purple-500  active:bg-primary-purple-500 disabled:bg-primary-grey-200 disabled:text-primary-grey-400  `;
      case "outline":
        return " text-primary-blue-500 bg-white border border-primary-blue-500  focus:border-2 focus:border-primary-blue-300 focus:bg-primary-grey-100  active:border-2 active:border-primary-blue-300 active:bg-primary-grey-100   disabled:border-primary-grey-300 disabled:text-primary-grey-400  ";
      case "text":
        return " text-primary-grey-400 bg-white  focus:bg-primary-grey-100  active:bg-primary-grey-100  disabled:text-primary-grey-400  ";
      case "quest-text-dark":
        return " text-primary-grey-800 bg-white  focus:bg-primary-grey-700  active:bg-primary-grey-700  disabled:text-primary-grey-400  ";
      default:
        return "text-primary-grey-400";
    }
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex  justify-center items-center gap-3 font-medium  rounded-2xl ${
        variant !== "text" && "hover:shadow-lg focus:shadow-lg active:shadow-lg"
      }  px-6 ${
        icon && iconAlign && iconAlign.toLowerCase() === "left"
          ? "pl-4"
          : iconAlign.toLowerCase() === "right"
          ? "pr-4"
          : ""
      } ${customIconAlignClass(iconAlign)} ${customSizeClass(
        size
      )} ${customVariantClass(variant)} `}
    >
      <p className=" hover:underline font-satoshi  focus:underline  active:underline  underline-offset-4  ">
        {text}
      </p>
      {icon && icon}
    </button>
  );
};

export default ProfileButton;
