import React, { useEffect, useState } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";

import { Toaster } from "react-hot-toast";

// Redux
import { useDispatch } from "react-redux";

// Routes
import { publicRoutes } from "routes/PublicRoute";
import { privateRoutes } from "routes/PrivateRoute";

// Components
import NonAuthMiddleware from "routes/NonAuthMiddleware";
import AuthenticatedRedirects from "routes/AuthenticatedRedirects";
import { authenticatedNoLayoutRoute } from "routes/AuthenticatedNoLayoutRoute";
import AuthenticatedNoLayoutRedirect from "routes/AuthenticatedNoLayoutRedirect";
// APIs

import logError from "helpers/utils/common/logError";
// import { fetchAllSquad } from "redux/squad";
// import { fetchAllUserEvents } from "redux/events";
// import { fetchAllJournals } from "redux/journals";
import { refreshToken } from "config/APIs/auth";
import { fetchAllgoals, updateSprint } from "redux/goal";
import {
  fetchAllRoles,
  fetchAllValueConstructs,
  fetchAllUserServices,
} from "redux/life";
import { fetchAllRoles as fetchAllRolesList } from "redux/roles";
import { fetchAllCollege } from "redux/dropdown";
import { createUserSprints } from "config/APIs/users";
import { fetchAllProjects } from "redux/projects";

function App() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const checkWindowSize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  // Effect to add window resize listener
  useEffect(() => {
    window.addEventListener("resize", checkWindowSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);
  const refresh = async () => {
    try {
      const response = await refreshToken();
      if (response.status === 200) {
        const { token, user } = response.data.data;
        localStorage.setItem("admin", JSON.stringify(user));
        localStorage.setItem("role", "admin");
        localStorage.setItem("token", token);
      }
    } catch (err) {
      logError("actions/auth/refreshToken error", err);
      let response = err?.response;

      switch (response?.status) {
        case "401":
          // Logout
          logError("Refresh token!!", response);
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          break;
        default:
          // Logout
          logError("Refresh token!!", response);
          localStorage.removeItem("token");
          localStorage.removeItem("role");
      }
    }
  };

  const createSprints = async () => {
    try {
      const response = await createUserSprints();
      if (response.status === 200) {
        dispatch(updateSprint({ list: response?.data?.data }));
      }
    } catch (err) {
      logError("actions/auth/refreshToken error", err);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !location?.pathname?.includes("/redirect")) {
      dispatch(fetchAllCollege());
      refresh();

      // dispatch(fetchAllUserEvents());
      dispatch(fetchAllProjects());
      // dispatch(fetchAllSquad());
      dispatch(fetchAllgoals());
      // dispatch(fetchAllJournals());
      dispatch(fetchAllRoles());
      dispatch(fetchAllRolesList());
      dispatch(fetchAllValueConstructs());
      dispatch(fetchAllUserServices());
      createSprints();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          className: "font-lato text-base rounded",
          success: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
          error: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
        }}
        position="top-right"
      />

      <div className="min-h-screen block   bg-white ">
        <Router>
          <Switch>
            {authenticatedNoLayoutRoute.map((route, idx) => (
              <AuthenticatedNoLayoutRedirect
                path={route.path}
                component={route.component}
                key={idx}
              />
            ))}
            {publicRoutes.map((route, idx) => (
              <NonAuthMiddleware
                path={route.path}
                component={route.component}
                key={idx}
              />
            ))}
            {privateRoutes.map((route, idx) => (
              <AuthenticatedRedirects
                path={route.path}
                component={route.component}
                key={idx}
                exact={route.exact}
              />
            ))}{" "}
          </Switch>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
