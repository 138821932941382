import { useDispatch, useSelector } from "react-redux";

import { updateAns, updateDetails } from "redux/typeform";
import Wrapper from "./Wrapper";
import { nextStep, prevStep } from "redux/typeform";
import { useEffect, useState } from "react";

const TextInput = ({
  id,
  question,
  description,
  type,
  subtitle,
  placeholder,
}) => {
  const { details, currentSection } = useSelector((state) => state.typeForm);
  const dispatch = useDispatch();
  const [data, setData] = useState(
    details?.sectionMetaData?.basicForm[currentSection]?.find(
      (item) => item.id === id
    )?.value
  );
  const [error, setError] = useState();
  const handleChange = (value) => {
    let val = value;

    switch (type) {
      case "phoneNumber":
        const phoneRegex = /^[0-9]+$/;

        // Test the phone number against the regular expression
        if (!phoneRegex.test(val)) return;
        if (val?.length > 10) return;
        break;
      case "email":

      default:
        val = value;
    }
    setData(value);
    setError();
  };

  const validate = () => {
    if (type == "email") {
      // Regular expression for a basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the regular expression
      return emailRegex.test(data);
    } else if (type == "phoneNumber") {
      const phoneRegex = /^[0-9]+$/;

      // Test the phone number against the regular expression
      return phoneRegex.test(data) && data?.length == 10;
    }
    return true;
  };

  useEffect(() => {
    setData(
      details?.sectionMetaData?.basicForm[currentSection]?.find(
        (item) => item.id === id
      )?.value || null
    );
    setError();
  }, [id]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <Wrapper
        onNextClick={() => {
          if (data == null || data == undefined || data == "") {
            setError(
              `Please enter ${
                type == "email"
                  ? "a valid email"
                  : type == "phoneNumber"
                  ? "a valid phone number"
                  : "your name"
              }`
            );
            return;
          }
          if (!validate()) {
            setError(
              `Please enter ${
                type == "email"
                  ? "a valid email"
                  : type == "phoneNumber"
                  ? "a valid phone number"
                  : "your name"
              }`
            );
            return;
          }
          dispatch(updateAns({ id: id, value: data, section: currentSection }));
          dispatch(
            updateDetails({
              value: data,
              field:
                type == "text"
                  ? "first_name"
                  : type == "phoneNumber"
                  ? "phone_number"
                  : type == "email"
                  ? "email"
                  : "",
            })
          );
          dispatch(nextStep({ section: true }));
        }}
        onBackClick={() => dispatch(prevStep({ section: true }))}
        disabled={false}
      >
        <div
          key={id}
          className={`flex flex-col w-full max-w-4xl mx-auto px-6 py-16 justify-center items-center`}
        >
          <div className="flex flex-col items-center space-y-4 w-full">
            {subtitle && (
              <div className="w-full capitalize flex flex-col md:flex-row md:items-center justify-start space-y-2 md:space-y-0 md:space-x-3 text-caaryaRed font-satoshi text-sm font-medium lg:tracking-[1.4px] tracking-[1.2px] lg:leading-5">
                <div className="w-5 h-5 lg:w-6 lg:h-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 8C19.55 8 18.74 9.44 19.07 10.51L15.52 14.07C15.22 13.98 14.78 13.98 14.48 14.07L11.93 11.52C12.27 10.45 11.46 9 10 9C8.55 9 7.73 10.44 8.07 11.52L3.51 16.07C2.44 15.74 1 16.55 1 18C1 19.1 1.9 20 3 20C4.45 20 5.26 18.56 4.93 17.49L9.48 12.93C9.78 13.02 10.22 13.02 10.52 12.93L13.07 15.48C12.73 16.55 13.54 18 15 18C16.45 18 17.27 16.56 16.93 15.48L20.49 11.93C21.56 12.26 23 11.45 23 10C23 8.9 22.1 8 21 8Z"
                      fill="#ED4C41"
                    />
                    <path
                      d="M15 9L15.94 6.93L18 6L15.94 5.07L15 3L14.08 5.07L12 6L14.08 6.93L15 9ZM3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z"
                      fill="#ED4C41"
                    />
                  </svg>
                </div>{" "}
                <p>{subtitle}</p>
              </div>
            )}
            <h1
              className={`w-full mt-5 text-black text-left font-satoshi text-xl lg:text-[28px] leading-9 lg:leading-[50.4px]`}
            >
              {question}
            </h1>
            <p className="w-full text-primary-gray-500 text-left font-manrope text-sm lg:text-base font-light">
              {description}
            </p>
          </div>

          <div
            className={`mt-8 lg:mt-12 py-3 px-0 lg:px-6 lg:py-4 text-lg lg:text-2xl flex flex-col space-y-2 w-full transition-all`}
          >
            <input
              className={`text-input ${error ? "error" : ""}`}
              type={
                type == "phoneNumber" && window.innerWidth < 1024
                  ? "number"
                  : "text"
              }
              value={data || ""}
              placeholder={placeholder || "Enter..."}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              name="firstName"
            />
            {error && (
              <p className="text-primary-neutral-800 font-lato text-[10px] leading-3 tracking-[0.64px]">
                {error}
              </p>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default TextInput;
