import { phaseTwoQuestionArr } from "helpers/constants/phaseTwoForm";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateQuizStepPhaseTwo,
  updateStepCompletion,
  updateStepPhaseTwo,
  updateSubStepPhaseTwo,
} from "redux/profile-form";

const usePhaseTwo = () => {
  const { step, subStep } = useSelector((state) => state.profileForm.phaseTwo);
  const dispatch = useDispatch();

  const handleUserKeyPress = (event) => {
    const { key } = event;

    if (key === "Tab") {
      dispatch(updateStepPhaseTwo(step + 1));
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleBackButtonClick = () => {
    dispatch(updateStepPhaseTwo(step - 1));

    if (subStep > 0) dispatch(updateSubStepPhaseTwo(subStep - 1));

    if (subStep >= 0 && step > 1) {
      dispatch(
        updateQuizStepPhaseTwo(
          phaseTwoQuestionArr[subStep]?.questions.length - 1
        )
      );
    }
  };

  const handleStepChangeButtonClick = () => {
    dispatch(updateStepPhaseTwo(step + 1));
    if (subStep > 0) {
      dispatch(updateStepCompletion({ field: `step${subStep - 1}` }));
    }
  };

  return { handleBackButtonClick, handleStepChangeButtonClick };
};

export default usePhaseTwo;
