import { ImOffice } from "react-icons/im";
import { HiDownload } from "react-icons/hi";
import { FiShare2 } from "react-icons/fi";
import Slider from "react-slick";
import { useRef, useState } from "react";
import { disabledDate, listBoosterOptions } from "helpers/utils";
import { MdBolt } from "react-icons/md";
import { FaBook } from "react-icons/fa";
import DatePicker from "rsuite/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "redux/achievementsForm";
import { BsCalendarFill } from "react-icons/bs";

const Tag = ({ label }) => (
  <div className="flex py-1.5 px-2 gap-4 items-center">
    <div className="bg-primary-yellow-30 flex p-1.5 justify-center items-center gap-2 rounded-full">
      <img src="/assets/bulb.svg" alt="" />
    </div>
    <p className="text-black text-lg font-bold">{label}</p>
  </div>
);

export default function Card({ index, card }) {
  const [activeSlide, setActiveSlide] = useState(0);
  let sliderRef = useRef(null);

  const {
    lastStep,
    moreContext: storeMoreCtx,
    boosters,
    achievements,
  } = useSelector((state) => state.achievementsForm);
  const dispatch = useDispatch();

  const categoryText =
    storeMoreCtx?.category?.length > 0
      ? storeMoreCtx?.category
      : storeMoreCtx?.company;

  const description =
    storeMoreCtx?.category === "Academic"
      ? storeMoreCtx?.course
      : storeMoreCtx?.category === "Work"
      ? storeMoreCtx?.company
      : "";

  const handleDateChange = (value) => {
    const currCard = lastStep[index] || {
      time: "",
      cardRole: "",
      value: "",
    };
    const newData = { ...currCard, time: value };
    dispatch(
      setValue({
        key: "lastStep",
        value: [
          ...lastStep.slice(0, index),
          newData,
          ...lastStep.slice(index + 1),
        ],
      })
    );

    const achievementsCopy = JSON.parse(JSON.stringify(achievements));

    let itemIndex = achievementsCopy[card?.roleId]?.findIndex(
      (a) => a.construct_id === card.construct_id
    );

    if (itemIndex !== -1 && itemIndex !== undefined) {
      achievementsCopy[card.roleId][itemIndex] = {
        ...achievementsCopy[card.roleId][itemIndex],
        timeOfCompletion: value,
      };

      dispatch(setValue({ key: "achievements", value: achievementsCopy }));
    }
  };

  return (
    <div className="max-sm:max-w-[300px] sm:w-[320px] max-md:mx-auto flex flex-col rounded-[24px] shadow-lg overflow-hidden font-lato">
      <div className="bg-[linear-gradient(to_right_bottom,rgba(0,0,0,0.38),rgba(0,0,0,0.58)),url(assets/rectangle.png)] bg-center bg-cover w-full text-white py-5 px-4 flex flex-col">
        <div className="flex gap-2 justify-between items-center">
          <div className="flex flex-col">
            <p
              className="text-sm font-bold capitalize"
              style={{
                textShadow:
                  "0px 1px 2px rgba(0, 0, 0, 0.10), 0px 1px 3px rgba(0, 0, 0, 0.10)",
              }}
            >
              {card?.serviceName}
            </p>
            <DatePicker
              shouldDisableDate={disabledDate}
              format="MMM yyyy"
              placeholder="Completed On"
              showMeridian
              caretAs={BsCalendarFill}
              style={{ width: 150 }}
              value={lastStep?.[index]?.time}
              onChange={handleDateChange}
              inputMode="none"
            />
          </div>
          <img
            src="/assets/caaryaLogos/logo.svg"
            alt="Caarya Logo"
            className="h-6 w-6 rounded-full"
          ></img>
        </div>
      </div>

      <div className="py-6 px-5 flex flex-col gap-6 bg-white">
        <Slider
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={false}
          arrows={true}
          swipeToSlide
          ref={(slider) => {
            sliderRef = slider;
          }}
          beforeChange={(_, current) => setActiveSlide(current)}
        >
          {/* Slide 1 */}
          <div className="!flex flex-col items-center gap-8 min-h-[250px]">
            <div className="flex w-full items-center justify-between">
              <p className="text-sm font-bold capitalize text-primary-neutral-500">
                {card?.projectName}
              </p>
              <span className="flex py-1 px-3 justify-center items-center gap-2 rounded-md bg-primary-accent-500 backdrop-blur-[4px] text-2xs text-white font-bold">
                {categoryText}
              </span>
            </div>
            <img
              src="/assets/rectangle.png"
              alt=""
              className="h-[72px] w-[72px] rounded-lg mx-auto"
            />
            <div className="text-center text-[22px] leading-8 font-bold text-black">
              {card?.serviceName}
            </div>
            <p className="text-secondary-indigo-500 text-sm text-center tracking-wide font-bold uppercase">
              {card?.cardRole}
            </p>

            <div className="flex items-center justify-center gap-2 text-primary-neutral-400">
              {storeMoreCtx?.category === "Work" &&
                storeMoreCtx?.company?.length > 0 && (
                  <ImOffice className="h-2.5 w-2.5" />
                )}
              {storeMoreCtx?.category === "Academic" &&
                storeMoreCtx?.course?.length > 0 && (
                  <FaBook className="h-2.5 w-2.5" />
                )}
              <p className="text-xs font-normal capitalize">{description}</p>
            </div>
          </div>
          {/* Slide 2 */}
          <div className="!flex gap-4 flex-col justify-center min-h-[250px]">
            <h3 className="text-base font-normal text-primary-neutral-500">
              As a{" "}
              <span className="text-secondary-indigo-500 font-bold">
                {card?.cardRole}
              </span>
              ,
            </h3>
            <h2 className="text-lg font-medium tracking-[0.96px] text-black">
              {card?.value}
            </h2>
          </div>
          {/* Slide 3 */}
          <div className="!flex flex-col gap-6 min-h-[250px] justify-center w-full">
            <p className="text-primary-neutral-500 text-sm font-bold relative top-0.5">
              Technical Skills Employed
            </p>

            <div className="flex gap-4 flex-col">
              {card?.techSkills?.map((skill) => (
                <Tag label={skill} />
              ))}
            </div>
          </div>
          {/* Slide 4 */}
          <div className="min-h-[250px] flex items-center">
            {listBoosterOptions(storeMoreCtx.boosters, boosters)?.map(
              (option, boosterOptIdx) => {
                return (
                  <div
                    key={boosterOptIdx}
                    className="flex items-center p-2 group w-full justify-between hover:bg-primary-neutral-50 cursor-pointer rounded"
                  >
                    <div className="flex items-center gap-2">
                      <MdBolt
                        className={`bg-primary-accent-500 rounded-full text-white p-0.5 text-xl`}
                      />
                      <span className="text-primary-neutral-800 font-normal text-sm">
                        {option.title}
                      </span>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </Slider>
      </div>
      <div className="py-4 px-6 flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <div className="flex p-2.5 justify-center items-center gap-2 rounded-full bg-primary-neutral-800 text-white">
            <HiDownload className="h-4 w-4" />
          </div>
          <div className="flex p-2.5 justify-center items-center gap-2 rounded-full bg-primary-neutral-800 text-white">
            <FiShare2 className="h-4 w-4" />
          </div>
        </div>
        <div className="flex items-start p-2 gap-2">
          {[...Array(4)].map((_, i) => {
            let isActive = activeSlide === i;
            return (
              <div
                className={`h-[8px] cursor-pointer bg-black rounded-full transition-all ease-in duration-200 ${
                  isActive ? "bg-opacity-100 w-[20px]" : "bg-opacity-20 w-[8px]"
                }`}
                onClick={() => sliderRef.slickGoTo(i)}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
