import {
  Accordion,
  RiasecData,
} from "components/ProfileForm/LaptopForm/components/WebQuizForm/RiasecStats";
import { FormComponents } from "helpers/constants/phaseTwoForm";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RIASECIcon from "./assets/riasec/RIASEC.svg";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { updateStep } from "redux/profile-form";
import { RiasecOptions } from "helpers/constants/profileForm";
import { useInView } from "react-intersection-observer";
import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const MobileRiasecStats = ({ close, quizData }) => {
  const [riasecData, setRiasecData] = useState(RiasecData);
  const { step: formStep, details } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const [ref, isInView] = useInView({
    threshold: 0.75,
  });
  const dispatch = useDispatch();
  const totalSteps = FormComponents(formStep, details).length;
  const getRiasecPercentage = () => {
    let score = {
      Realistic: 0,
      Investigative: 0,
      Artistic: 0,
      Social: 0,
      Enterprising: 0,
      Conventional: 0,
    };
    let allOptions = [];
    quizData.forEach((quiz) =>
      quiz.options.forEach((option) => allOptions.push(option))
    );

    for (var i = 0; i < allOptions.length; i++) {
      const option = allOptions[i];
      for (var j = 0; j < RiasecOptions.length; j++) {
        if (RiasecOptions[j].options.includes(option)) {
          score[RiasecOptions[j].title]++;
        }
      }
    }
    const updatedRiasec = RiasecData.map((_riasec) => ({
      ..._riasec,
      value: (score[_riasec.title] / allOptions.length) * 100,
    }));
    return updatedRiasec;
  };

  useEffect(() => {
    const updatedRiasec = getRiasecPercentage();
    setRiasecData(updatedRiasec);
  }, [quizData]);

  return (
    <div className="relative min-h-screen pt-24 px-4">
      <div className={`flex w-full p-4 font-inter absolute top-0 left-0 z-10`}>
        <div className="shadow-md bg-white flex rounded-full p-1 px-3 ">
          <img
            src="/assets/images/logo/caaryaMe.svg"
            alt=""
            className="h-6 object-contain"
          />
        </div>
      </div>
      <div className="flex flex-col gap-10 w-full pb-28 relative ">
        <h1
          ref={ref}
          className="text-primary-blue-500 text-lg font-medium text-center "
        >
          {details.name}’s Interest Profile
        </h1>
        <div
          className={`grid grid-cols-6 h-60  ${
            !isInView ? "sticky bg-white top-0 pb-6" : ""
          } `}
        >
          {riasecData.map((_sub, idx) => (
            <div
              key={idx}
              className="flex flex-col justify-end items-center h-full w-full "
            >
              <div className="flex flex-col items-center justify-end h-full ">
                <p className="font-satoshi font-bold text-xs text-center">
                  {parseInt(_sub.value)}%
                </p>
                <div
                  style={{ height: _sub.value + 10 + "%" }}
                  className="w-8 rounded-t-md bg-gradient-to-b from-[#FF8800] to-[#F7DE3B] "
                ></div>
              </div>
              <div className="flex flex-col items-center p-2 border-t border-primary-grey-500 w-full ">
                <img
                  src={_sub.icon}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <p className="font-satoshi font-bold text-xxs text-center">
                  {_sub.title}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center">
          <img src={RIASECIcon} alt="" className="h-44 w-44 object-contain " />
          <div className=" w-full">
            <div className="flex flex-col gap-2 font-satoshi text-sm">
              <p className=" font-bold  ">What does this mean?</p>
              <p className=" leading-6 ">
                Lörem ipsum megahiligt mononat, då nen så digada har dining
                savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra
                om prett, hemin. Dint prena utom stenosat: i polyr abevis os och
                gatid avism då dolysm sore miss inklusive lytöktig omönat suvis
                den våper exosamma.
              </p>
            </div>
            {riasecData.map((_sub, idx) => (
              <Accordion key={idx} title={_sub.title} content={_sub.text} />
            ))}
          </div>
        </div>
        <div className={`bg-white z-10 fixed inset-x-0 bottom-0  `}>
          <div className="h-1 w-full bg-primary-grey-200 relative">
            <div
              style={{ width: ((formStep + 1) / totalSteps) * 100 + "%" }}
              className={`absolute  left-0 top-0 bottom-0 bg-primary-blue-500 transition-all duration-300 ease-in-out rounded-r-full `}
            ></div>
          </div>

          <div className="flex justify-between  gap-6 items-center p-4">
            <ProfileButton
              onClick={close}
              text={"Back"}
              size={"large"}
              icon={<ArrowLeftIcon className="w-6" />}
              iconAlign={"left"}
            />

            <ProfileButton
              onClick={() => dispatch(updateStep(formStep + 1))}
              text={"Continue"}
              size={"large"}
              variant={"filled"}
              icon={<ChevronRightIcon className="w-6" />}
              iconAlign={"right"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileRiasecStats;
