import { BiError } from "react-icons/bi";
import { MdArrowBack, MdArrowForward, MdLock } from "react-icons/md";
import { useHistory } from "react-router-dom";

export default function Starting({ page, setPage, assessment }) {
  const history = useHistory();
  return (
    <div className="px-4 flex flex-col justify-between  h-screen overflow-y-auto space-y-8 pt-6 pb-12">
      <div
        onClick={() => {
          if (page == 1) history.goBack();
          else setPage(1);
        }}
        className="flex flex-row items-center space-x-2 text-sm font-satoshi capitalize underline underline-offset-2 font-bold leading-5 text-[#696763]"
      >
        <MdArrowBack size={24} />
        <p>Back</p>
      </div>
      <div className="flex flex-col gap-4 items-center">
        <img
          src={assessment.image}
          className="h-[120px] sm:h-[180px] w-auto mx-auto"
        />
        <p
          className="text-center font-lato text-[28px] font-medium leading-[42px] tracking-[1.7px]"
          style={{
            "-webkit-background-clip": "text",
            WebkitTextFillColor: "transparent",
            background: assessment.gradient,
            "background-clip": "text",
          }}
        >
          {assessment.title}
        </p>
        <p className="font-satoshi text-sm font-normal leading-6 tracking-[0.7px] text-primary-grey-500 text-center">
          {assessment.subheading}
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <button
          className="flex w-full justify-center items-center gap-3 py-4 pl-8 pr-7 rounded-full"
          disabled={assessment.isLocked}
          style={{
            background: assessment.isLocked ? "#9C9A96" : assessment.gradient,
            boxShadow:
              "0px 0px 16px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -4px rgba(0, 0, 0, 0.10)",
          }}
          onClick={() => setPage(2)}
        >
          <span className="text-white font-satoshi text-lg font-bold tracking-[0.72px] capitalize">
            Start Now
          </span>
          {assessment.isLocked ? (
            <MdLock className="h-6 w-6 text-white" />
          ) : (
            <MdArrowForward className="h-6 w-6 text-white" />
          )}
        </button>
        {assessment.isLocked && (
          <div className="flex items-center justify-center gap-2 text-primary-neutral-500 text-center font-satoshi text-xs leading-5">
            <BiError className="h-[18px] w-[18px] text-[#EEA638]" /> Complete
            ‘previous assessment name’ to unlock
          </div>
        )}
      </div>
    </div>
  );
}
