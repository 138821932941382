import { FormComponents } from "helpers/constants/profileForm";
import React from "react";
import { useSelector } from "react-redux";
import WebFormNaviagtion from "./components/WebFormNavigation";

const renderStepComponent = (step, screen) => {
  const formComponents = FormComponents(screen);
  switch (step) {
    case step:
      return formComponents[step]
        ? formComponents[step].component
        : formComponents[0].component;
    default:
      return formComponents[0].component;
  }
};

const LaptopForm = () => {
  const { step, details, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  return (
    <div className="relative">
      {step === 0 && (
        <div className="grid place-items-center p-6 shadow-sm">
          <img
            src="/assets/images/logo/caaryaMe.svg"
            alt=""
            className="h-10 object-contain"
          />
        </div>
      )}

      <div className={`min-h-screen px-20  flex flex-col justify-between`}>
        {step !== 0 && (
          <div className={`flex w-full  py-4 font-inter `}>
            <div className="shadow-md bg-white flex rounded-full p-1 px-3 ">
              <img
                src="/assets/images/logo/caaryaMe.svg"
                alt=""
                className="h-6 object-contain"
              />
            </div>
          </div>
        )}

        <div className="flex-1 w-full max-w-7xl mx-auto ">
          {renderStepComponent(step, "web")}
        </div>
      </div>
      {isNavigationVisible && (
        <div>
          <WebFormNaviagtion
            step={step}
            totalSteps={FormComponents(step, details).length}
            details={details}
            field={FormComponents(step, details)[step]?.field || ""}
          />
        </div>
      )}
    </div>
  );
};

export default LaptopForm;
