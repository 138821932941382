import { RiHeartFill } from "react-icons/ri";

export default function Categories({ selectedCategory, setSelectedCategory }) {
  return (
    <div className="flex py-2 pt-4 gap-[14px] justify-start overflow-x-auto whitespace-nowrap bg-primary-neutral-50 px-4">
      <div
        className={`inline-flex transition-all duration-200 cursor-pointer flex-auto justify-center items-center py-2 w-full px-4 rounded-full border ${
          selectedCategory === "Favorites"
            ? "bg-primary-neutral-800 text-white border-primary-neutral-800"
            : "text-primary-neutral-400 border-primary-neutral-200"
        }`}
        onClick={() => setSelectedCategory("Favorites")}
      >
        <RiHeartFill className="h-[16px] w-[16px] flex-shrink-0 mr-2" />
        <span className="font-satoshi font-medium text-xs leading-5">
          Favorites (8)
        </span>
      </div>
      <div
        className={`inline-flex transition-all duration-200 cursor-pointer flex-auto justify-center items-center py-2 w-full px-4 rounded-full  border ${
          selectedCategory === "Caarya"
            ? "bg-primary-neutral-800 text-white"
            : "text-primary-neutral-400 border-primary-neutral-200"
        }`}
        onClick={() => setSelectedCategory("Caarya")}
      >
        <div className="h-2 w-2 mr-2 bg-primary-red-500 rounded-full"></div>
        <span className="font-satoshi font-medium text-xs leading-5">
          Caarya (2)
        </span>
      </div>
      <div
        className={`inline-flex transition-all duration-200 cursor-pointer flex-auto justify-center items-center py-2 w-full px-4 rounded-full border  ${
          selectedCategory === "Academic"
            ? "bg-primary-neutral-800 text-white"
            : "text-primary-neutral-400 border-primary-neutral-200"
        }`}
        onClick={() => setSelectedCategory("Academic")}
      >
        <div className="h-2 w-2 mr-2 bg-[#FFD844] rounded-full"></div>

        <span className="font-satoshi font-medium text-xs leading-5">
          Academic (1)
        </span>
      </div>
      <div
        className={`inline-flex transition-all duration-200 cursor-pointer flex-auto justify-center items-center py-2 w-full px-4 rounded-full border  ${
          selectedCategory === "Personal"
            ? "bg-primary-neutral-800 text-white"
            : "text-primary-neutral-400 border-primary-neutral-200"
        }`}
        onClick={() => setSelectedCategory("Personal")}
      >
        <div className="h-2 w-2 mr-2 bg-[#3996E3] rounded-full"></div>

        <span className="font-satoshi font-medium text-xs leading-5">
          Personal (1)
        </span>
      </div>
    </div>
  );
}
