import axios from "axios";
import config from "config/APIEndpoints";

const BASE_API_URL = config.getEndpoint()?.coreEndpoint;
const ADMIN_API_URL = BASE_API_URL + "/api/v1";
const HQ_ENDPOINT = BASE_API_URL + "/api/v1/hq";
const LIFE_ENDPOINT = config.getEndpoint()?.lifeEndpoint + "/api/v1";
const BASE_TALENT_API_URL = config.getEndpoint()?.talentEndpoint + "/api/v1";

export { BASE_API_URL };

// Util
export const getHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
};

export const getMultipartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const getFormDataHeader = () => {
  return {
    headers: { "Content-Type": "multipart/form-data" },
  };
};

export const instance = axios.create({
  baseURL: ADMIN_API_URL,
});

export const chronosInstance = axios.create({
  baseURL: ADMIN_API_URL,
});
export const hqInstance = axios.create({
  baseURL: HQ_ENDPOINT,
});

export const lifeInstance = axios.create({
  baseURL: LIFE_ENDPOINT + "/life",
});

export const publicInstance = axios.create({
  baseURL: LIFE_ENDPOINT + "/public",
});

export const talentInstance = axios.create({
  baseURL: BASE_TALENT_API_URL,
});
