import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";

export default function MessageDetails() {
  const history = useHistory();
  return (
    <>
      <div className="flex flex-col">
        <div className="h-[50px] fixed top-0 right-0 left-0 md:hidden bg-white z-30" />
        <div className="flex px-4 mt-[50px] py-6 flex-col space-y-2 w-full bg-white fixed top-0 z-10">
          <p
            className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-lato text-base tracking-[0.64px] leading-6"
            onClick={() => history.goBack()}
          >
            <MdArrowBack />
            <p>Message Details</p>
          </p>
        </div>
      </div>
      <div className="flex p-4 mt-[120px] pb-20 min-h-[calc(100svh-150px)] flex-col items-start w-full gap-4 bg-primary-neutral-50">
        <div className="p-4 flex flex-col items-center gap-10 rounded-lg bg-white w-full">
          <div className="flex flex-col gap-4 w-full">
            <p className="text-lg text-black font-bold leading-8 capitalize">
              Set Up Your Career Goals
            </p>
            <div className="flex flex-row items-center space-x-2 justify-start">
              <img
                src="/assets/images/tie.png"
                alt=""
                className="w-3 h-3 rounded-full"
              />
              <p className="text-primary-neutral-500 font-lato text-2xs font-bold capitalize leading-4 tracking-[0.4px]">
                Personal growth Manager
              </p>
            </div>
          </div>
          <img
            src="/assets/images/target.svg"
            alt=""
            className="w-[250px] h-[250px] object-contain"
          />
          <p className="font-satoshi text-primary-neutral-800 font-normal tracking-[0.32px] leading-7">
            Set up your career goals lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </p>
          <div className="flex flex-row items-center justify-center">
            <div
              onClick={() => history.push("/CareerGoals")}
              className="bg-primary-neutral-900 rounded-full py-3.5 px-14 text-white font-satoshi text-sm font-bold leading-7 tracking-[0.9px] cursor-pointer"
            >
              Set Up Now
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
