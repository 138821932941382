import React from 'react'
import { List } from '../List/List'
import Button from 'helpers/constants/button'
import { Img } from '../Img/Img'
import { Text } from 'helpers/constants/Text'
const WorkExp = () => {
  return (
    <>
        <List
              className="  from-gray-200  flex flex-row gap-3px justify-start rounded-[32px] shadow-bs2 w-2/3"
              orientation="horizontal"
              // style={{background: "rgba(250, 250, 250, 0.9), rgba(250, 250, 250, 1), rgba(250, 250, 250, 0.9), rgba(250, 250, 250, 0)",}}
            >
              {Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className=" bg-gradient-to-b  hover:bg-gradient-to-r flex flex-1 flex-col items-center justify-center p-10 sm:px-5 border border-gray border-opacity-5 rounded-[5%] "
                >
                  <div className="flex flex-col gap-2 items-start justify-center w-auto">
                    <div className="w-16 h-12 relative">
                      <div
                        className="w-[53px] h-[51px] left-[0.63px] top-[1.25px] absolute"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(255, 233, 154, 1) 0%, rgba(255, 126, 110, 1) 100%)",
                        }}
                      >
                        <Button
                          className="flex h-14 items-center justify-center w-14"
                          shape="round"
                          size="sm"
                         

                        >
                          <Img
                            className="h-10"
                            src={`assets/svg/navigation/${
                              index === 0
                                ? "bag"
                                : index === 1
                                ? "notepad"
                                : "people"
                            }.svg`}
                            alt={`images`}
                          />
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-auto">
                      <Text
                        className="text-base text-center text-gray-700 tracking-[0.80px]"
                        size="txtSatoshiBold16"
                      >
                        {index === 0
                          ? "Work Experience"
                          : index === 1
                          ? "Work Contracts"
                          : "Networking Session"}
                      </Text>
                      <Text
                        className="sm:text-4xl md:text-[38px] text-[40px] text-black-900 text-center tracking-[2.00px]"
                        size="txtSatoshiBold40"
                      >
                        00
                      </Text>
                      <Text
                        className="text-center text-gray-700 text-xs tracking-[0.60px]"
                        size="txtSatoshiLight12"
                      >
                        {index === 0
                          ? "Total Hours Gained"
                          : index === 1
                          ? "Total Awarded"
                          : "Total Attended"}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </List>
    
    
    </>
  )
}

export default WorkExp