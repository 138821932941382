import Drawer from "@mui/material/Drawer";
import { useEffect, useRef, useState } from "react";
import ActiveInterestSelectionComponent from "./ActiveInterestSelectionComponent";
import InterestDrawer from "./InterestDrawer";

const InterestSelectionForm = ({ field }) => {
  const [scrollable, setScrollable] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollRef = useRef(null);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setScrollable(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) observer.unobserve(scrollRef.current);
    };
  }, [scrollRef]);

  return (
    <div className=" font-manrope h-full flex flex-col pt-24  ">
      <div ref={scrollRef} className="p-4 md:px-10 flex flex-col  gap-4">
        <h4 className="text-lg text-primary-blue-500 max-w-sm font-medium ">
          How have your career interests evolved?
        </h4>
        <h3 className="text-sm text-primary-grey-600">
          Add the things you’ve been interested in making a career in at
          different points in your life
        </h3>
      </div>

      <ActiveInterestSelectionComponent
        field={field}
        openDrawer={() => setOpenDrawer(true)}
        scrollable={scrollable}
      />

      <Drawer anchor={"bottom"} open={openDrawer} onClose={toggleDrawer}>
        <InterestDrawer field={field} close={() => setOpenDrawer(false)} />
      </Drawer>
    </div>
  );
};

export default InterestSelectionForm;
