import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { typeFormQuestions } from "helpers/constants/driveTypeForm";

const QuestionNavigation = () => {
  const { stepCompleted, currentSectionTotal, currentStep, currentSection } =
    useSelector((state) => state.typeForm);

  let currNum = 1;

  let stat = <></>;

  function scrollNumber(digits) {
    stat.querySelectorAll("span[data-value]").forEach((tick, i) => {
      tick.style.transform = `translateY(-${100 * parseInt(digits[i])}%)`;
    });

    setTimeout(() => {
      stat.style.width = `12px`;
    }, 100);
  }

  function addDigit(digit, fresh) {
    const spanList = Array(10)
      .join(0)
      .split(0)
      .map((x, j) => `<span>${j}</span>`)
      .join("");

    stat.insertAdjacentHTML(
      "beforeend",
      `<span style="transform: translateY(-1000%)" data-value="${digit}">
        ${spanList}
      </span>`
    );

    const firstDigit = stat.lastElementChild;

    setTimeout(
      () => {
        firstDigit.className = "visible";
      },
      fresh ? 0 : 2000
    );
  }

  function removeDigit() {
    const firstDigit = stat.lastElementChild;
    firstDigit.classList.remove("visible");
    setTimeout(() => {
      firstDigit.remove();
    }, 2000);
  }

  function setup(startNum) {
    const digits = startNum.toString().split("");

    for (let i = 0; i < digits.length; i++) {
      addDigit("0", true);
    }

    scrollNumber(["0"]);

    setTimeout(() => scrollNumber(digits), 2000);
    currNum = startNum;
  }

  function update(num) {
    if (!num) {
      num = Math.round(Math.random() * 100);
    }
    const toDigits = num.toString().split("");
    const fromDigits = currNum.toString().split("");

    for (let i = fromDigits.length - toDigits.length; i > 0; i--) {
      removeDigit();
    }
    for (let i = toDigits.length - fromDigits.length; i > 0; i--) {
      addDigit(toDigits[i]);
    }

    scrollNumber(toDigits);
    currNum = num;
  }

  useEffect(() => {
    stat = document.querySelector("#number");
    setup(currentStep);
  }, []);

  useEffect(() => {
    stat = document.querySelector("#number");
    update(currentStep);
  }, [currentStep]);

  return (
    <>
      <div className="fixed top-[65px] w-full lg:top-[75px] bg-white">
        <div className="px-4 py-2 lg:py-4 lg:px-10 flex flex-row items-center justify-between space-x-2">
          <div className="flex flex-row items-center space-x-4">
            <div className="lg:w-6 lg:h-6 w-[18px] h-[18px] capitalize">
              {
                typeFormQuestions?.find(
                  (a, idx) => currentSection === `Section${idx + 1}`
                )?.svg
              }
            </div>
            <p
              className={`section-${currentSection}-name font-poppins text-base lg:text-lg font-medium`}
            >
              {
                typeFormQuestions?.find(
                  (a, idx) => currentSection === `Section${idx + 1}`
                )?.title
              }
            </p>
          </div>
          <div
            style={{
              color: typeFormQuestions?.find(
                (a, idx) => currentSection === `Section${idx + 1}`
              )?.stepColor,
            }}
            className="font-inter text-base lg:text-lg font-medium flex flex-row items-center"
          >
            <span id="number"></span>
            <p>/ {currentSectionTotal}</p>
          </div>
        </div>
        {/* <div className="border-t-2 border-primary-grey-200 relative mt-6 lg:mt-10 xl:mt-6 mx-2 lg:mx-10 xl:mx-0 ">
          <div className="absolute bottom-0 inset-x-0 transform translate-y-6 sm:translate-y-10 lg:translate-y-12 flex justify-between px-2 lg:px-10 items-start  gap-4 lg:gap-6 font-satoshi">
            {typeFormQuestions.map((item, idx) => (
              <div
                onClick={() => handleNavigationClick(idx + 1)}
                key={idx + 1}
                className="flex flex-col z-10 cursor-pointer items-center relative text-center"
              >
                {stepCompleted[`Section${idx + 1}`] === true ? (
                  <div className="w-12 h-12 p-2 lg:w-16 lg:h-16 lg:p-4 bg-white rounded-full relative">
                    <div className="w-8 h-8 grid place-items-center rounded-full bg-primary-error-300 border-2 border-primary-error-300">
                      {" "}
                      <CheckCircle
                        className="w-4 text-white"
                        weight="fill"
                      />{" "}
                    </div>
                  </div>
                ) : (
                  <div className="w-12 h-12 p-2 lg:w-16 lg:h-16 lg:p-4 bg-white rounded-full relative">
                    <CircularProgressbar
                      styles={buildStyles({
                        pathColor: "#FB6F60",
                      })}
                      value={
                        currentSection === `Section${idx + 1}`
                          ? (currentStep / currentSectionTotal) * 100
                          : 0
                      }
                    />
                    <div className=" rounded-full absolute inset-3 lg:inset-6 grid place-items-center text-primary-grey-400 bg-white  ">
                      <img
                        className="w-4 h-4 object-contain"
                        src={
                          currentSection === `Section${idx + 1}`
                            ? item.activeIcons
                            : item.icons
                        }
                        alt=""
                      />
                    </div>
                  </div>
                )}

                <div
                  className={`sm:hidden absolute -bottom-4 w-40  ${
                    idx === 0 && "left-0 text-left"
                  } ${
                    idx === typeFormQuestions.length - 1 && "right-0 text-right"
                  } `}
                >
                  {currentSection === `Section${idx + 1}` && (
                    <p
                      className={`font-light text-xs font-inter lowercase  ${
                        currentSection === `Section${idx + 1}`
                          ? "text-primary-gray-800"
                          : "text-primary-gray-400 "
                      } -translate-y-1   `}
                    >
                      {item.title}
                    </p>
                  )}
                </div>

                <p
                  className={`font-light text-xs font-inter lowercase hidden sm:block ${
                    currentSection === `Section${idx + 1}`
                      ? "text-primary-gray-800"
                      : "text-primary-gray-400 "
                  } -translate-y-1 lg:-translate-y-3 `}
                >
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default QuestionNavigation;
