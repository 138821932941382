import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import usePhaseOne from "components/ProfileForm/hooks/usePhaseOne";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateStep } from "redux/profile-form";

const FormNavigation = ({ totalSteps, field }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { step, details, isCompleted } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const { getDisabledState, handlePhaseOneComplete, handleButtonWrapperClick } =
    usePhaseOne(field);

  useEffect(() => {
    if (isCompleted == true) {
      history.goBack();
    }
  }, [isCompleted]);

  return (
    <div className="bg-white z-20">
      <div className="h-1 w-full bg-primary-grey-200 relative">
        <div
          style={{ width: ((step + 1) / totalSteps) * 100 + "%" }}
          className={`absolute  left-0 top-0 bottom-0 bg-primary-blue-500 transition-all duration-300 ease-in-out rounded-r-full `}
        ></div>
      </div>

      <div className="flex justify-between  gap-6 items-center p-4">
        <ProfileButton
          onClick={() => dispatch(updateStep(step - 1))}
          text={"Back"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
        />
        <div onClick={handleButtonWrapperClick}>
          <ProfileButton
            onClick={() =>
              step >= totalSteps - 1
                ? handlePhaseOneComplete()
                : dispatch(updateStep(step + 1))
            }
            disabled={getDisabledState(step, totalSteps, details, field)}
            text={step === totalSteps - 1 ? "Finish! " : "Continue"}
            size={"large"}
            variant={"filled"}
            icon={step < totalSteps - 1 && <ChevronRightIcon className="w-6" />}
            iconAlign={step < totalSteps - 1 ? "right" : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default FormNavigation;
