import { ReactComponent as CaaryaLogo } from "../../assets/Dashboard/caarya_banner 1.svg";
import { IoApps, IoEllipsisVertical } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { ReactComponent as MyDesk } from "../../assets/SideNavbar/MyDesk.svg";
import { ReactComponent as Sprint2 } from "../../assets/SideNavbar/Sprint2.svg";
import { ReactComponent as CareerFlow } from "../../assets/SideNavbar/CareerFlow.svg";
import { ReactComponent as Sprint } from "../../assets/SideNavbar/Sprint.svg";
import { ReactComponent as Tribe } from "../../assets/SideNavbar/Tribe.svg";
import { ReactComponent as KnowledgeCards } from "../../assets/SideNavbar/Knowledge.svg";
import { ReactComponent as Community } from "../../assets/SideNavbar/Community.svg";
import { ReactComponent as Focus } from "../../assets/SideNavbar/Focus.svg";
import { useState } from "react";

const Sidebar = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("mydesk");
  const navigate = (path) => {
    setActiveTab(path);
    history.push(path);
  };

  const isActive = (tabName) =>
    activeTab === tabName ? "bg-[#FFE9E5] text-[#ED4C41]" : "text-black";
  return (
    <div className="w-[240px] h-[full] flex flex-col">
      <div className="px-4 py-8 flex flex-row items-center justify-start space-x-[10px] h-[96px]">
        <div>
          <CaaryaLogo />
        </div>
        <div className="text-[#ED4C41] font-poppins text-sm">
          <p>LIFE</p>
        </div>
      </div>
      <div className="px-4 py-2 space-y-6 flex flex-col">
        <div className="flex flex-row items-center space-x-2">
          <div
            style={{
              background: "linear-gradient(180deg, #ED4C41 0%, #C63434 100%)",
            }}
            className="px-[20px] py-2   w-[90%] flex flex-row justify-center items-center space-x-3 rounded-lg "
          >
            <button
              onClick={() => navigate("dashboard")}
              className=" space-x-3 flex flex-row"
            >
              <div>
                <IoApps className="text-white" />
              </div>
              <div>
                <p className="font-satoshi font-medium text-xs text-[white]">
                  Dash
                </p>
              </div>
            </button>
          </div>
          <div className="rounded-lg border-[1px] border-opacity-20 p-[6px] w-[32px] h-[32px] items-center justify-center flex">
            <button>
              <IoEllipsisVertical className="text-[grey]" />
            </button>
          </div>
        </div>

        <div className="space-y-2 flex flex-col">
          {[
            {
              Icon: MyDesk,
              label: "My Desk",
              route: "mydesk",
            },
            {
              Icon: Sprint,
              label: "Sprint Card",
              route: "sprint",
            },
            {
              Icon: CareerFlow,
              label: "Career Flow",
              route: "CareerFlow",
            },
            {
              Icon: Tribe,
              label: "My Tribe",
              route: "tribe",
            },
          ].map((item) => (
            <button
              key={item.route}
              onClick={() => navigate(item.route)}
              className={`px-3 py-2 rounded-lg flex flex-row justify-start items-center space-x-4 ${isActive(
                item.route
              )}`}
            >
              <div>
                <item.Icon
                  className={`${
                    activeTab === item.route ? "text-[#ED4C41]" : ""
                  }`}
                />
              </div>
              <div>
                <p
                  className={`font-satoshi text-xs font-medium ${isActive(
                    item.route
                  )}`}
                >
                  {item.label}
                </p>
              </div>
            </button>
          ))}
        </div>

        <div className="space-y-2 flex flex-col">
          <div className="flex px-1 py-2 justify-start items-center">
            {" "}
            <p className="text-[10px] font-satoshi text-[#9C9A96] font-medium">
              Quick Access
            </p>
          </div>

          {[
            {
              Icon: KnowledgeCards,
              label: "Knowledge Cards",
              route: "KnowledgeCard",
            },
            {
              Icon: Community,
              label: "Community AMA",
              route: "Community",
            },
            {
              Icon: Sprint2,
              label: "Sprint Card",
              route: "sprintInsights",
            },
            {
              Icon: Focus,
              label: "Focus Zone",
              route: "FocusZone",
            },
          ].map((item) => (
            <button
              key={item.route}
              onClick={() => navigate(item.route)}
              className={`px-3 py-2 rounded-lg flex flex-row justify-start items-center space-x-4 ${isActive(
                item.route
              )}`}
            >
              <div>
                <item.Icon
                  className={`${
                    activeTab === item.route ? "text-[#ED4C41]" : ""
                  }`}
                />
              </div>
              <div>
                <p
                  className={`font-satoshi text-xs font-medium ${isActive(
                    item.route
                  )}`}
                >
                  {item.label}
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
