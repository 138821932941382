import Agriculture from "../assets/agriculture.svg";
import Architecture from "../assets/architecture.svg";
import Arts from "../assets/brush.svg";
import Business from "../assets/manage_accounts.svg";
import Education from "../assets/school.svg";
import Finance from "../assets/attach_money.svg";
import Government from "../assets/assured_workload.svg";
import Health from "../assets/medical_services.svg";
import Hospitality from "../assets/hotel.svg";
import Human from "../assets/person.svg";
import InformationTechnology from "../assets/computer.svg";
import Law from "../assets/gavel.svg";
import Manufacturing from "../assets/factory.svg";
import Marketing from "../assets/point_of_sale.svg";
import Science from "../assets/science.svg";
import Transportation from "../assets/flight_takeoff.svg";
import {
  MdLooks3,
  MdLooks4,
  MdLooks5,
  MdLooksOne,
  MdLooksTwo,
} from "react-icons/md";
import { FaGraduationCap, FaSchool } from "react-icons/fa";
import { RiBookReadFill } from "react-icons/ri";
import { BsPencil } from "react-icons/bs";

export const TimeframeList = [
  {
    title: "Fifth Year",
    subTitle: "College 5th Year",
    icon: <MdLooks5 />,
  },
  {
    title: "Fourth Year",
    subTitle: "College 4th Year",
    icon: <MdLooks4 />,
  },
  {
    title: "Third Year",
    subTitle: "College 3rd year",
    icon: <MdLooks3 />,
  },
  {
    title: "Second year",
    subTitle: "College 2nd Year",
    icon: <MdLooksTwo />,
  },

  {
    title: "First Year",
    subTitle: "College 1st Year",
    icon: <MdLooksOne />,
  },
  {
    title: "Pre College Days",
    subTitle: "Period Before Starting College",
    icon: <FaGraduationCap />,
  },
  {
    title: "Plus Two",
    subTitle: "Classes 11 - 12",
    icon: <FaSchool />,
  },
  {
    title: "High School",
    subTitle: "Classes 6 - 10",
    icon: <RiBookReadFill />,
  },
  {
    title: "Early Childhood",
    subTitle: "Upto Class 5",
    icon: <BsPencil />,
  },
];

export const interestSearchList = [
  "Photography",
  "Aerial Photography",
  "Amazon Photography",
  "Animal Photography",
  "360 Photography",
];

export const InterestNavigationList = [
  {
    title: "Agriculture, Food & Natural Resources",
    icon: Agriculture,
    subMenu: [
      {
        title: "Agribusiness Systems",
        options: [
          "Financial Managers, Branch or Department",
          "Purchasing Agents and Buyers, Farm Products",
        ],
      },
      {
        title: "Animal Systems",
        options: [
          "Animal Scientists",
          "Veterinarians",
          "Veterinary Technologists and Technicians",
          "Zoologists and Wildlife Biologists",
        ],
      },
      {
        title: "Environmental Service Systems",
        options: [
          "Environmental Engineers",
          "Environmental Science and Protection Technicians, Including Health",
          "Water and Liquid Waste Treatment Plant and System Operators",
        ],
      },
      {
        title: "Natural Resources Systems",
        options: [
          "Cartographers",
          "Geological and Petroleum Technicians",
          "Geological Sample Test Technicians",
          "Natural Sciences Managers",
        ],
      },
      {
        title: "Plant Systems",
        options: [
          "Foresters",
          "Soil and Plant Scientists",
          "Soil and Water Conservationists",
        ],
      },
    ],
  },
  {
    title: "Architecture and Construction",
    icon: Architecture,
    subMenu: [
      {
        title: "Construction",
        options: [
          "Construction Managerst",
          "First-Line Supervisors/Managers of Construction Trades",
          "Solar Energy Installation Managers",
          "Solar Photovoltaic Installers",
        ],
      },
      {
        title: "Design/Pre-Construction",
        options: [
          "Architects, Except Landscape and Naval",
          "Civil Engineers",
          "Interior Designers",
          "Landscape Architects",
          "Mechanical Drafters",
          "Surveyors",
        ],
      },
      {
        title: "Maintenance/Operations",
        options: [
          "Geothermal Technicians",
          "Stationary Engineers and Boiler Operators",
          "Wind Turbine Service Technicians",
        ],
      },
    ],
  },
  {
    title: "Arts, Audio/Video Technology and Communications",
    icon: Arts,
    subMenu: [
      {
        title: "Audio and Video Technology and Film",
        options: ["Graphic Designers", "Sound Engineering Technicians"],
      },
      {
        title: "Journalism and Broadcasting",
        options: [
          "Broadcast News Analysts",
          "Copy Writers",
          "Editors",
          "Radio and Television Announcers",
          "Reporters and Correspondents",
        ],
      },
      {
        title: "Performing Arts",
        options: [
          "Actors",
          "Choreographers",
          "Dancers",
          "Directors- Stage, Motion Pictures, Television, and Radio",
          "Entertainers and Performers, Sports and Related Workers",
          "Film and Video Editors",
          "Makeup Artists, Theatrical and Performance",
          "Music Directors and Composers",
          "Musicians and Singers",
          "Musicians, Instrumental",
          "Producers and Directors",
          "Program Directors",
          "Talent Directors",
          "Technical Directors/Managers",
          "Writers and Authors",
        ],
      },
      {
        title: "Printing Technology",
        options: ["Desktop Publishers", "Job Printers"],
      },
      {
        title: "Telecommunications",
        options: ["Telecommunications Line Installers and Repairers"],
      },
      {
        title: "Visual Arts",
        options: [
          "Commercial and Industrial Designers",
          "Craft Artists",
          "Fine Artists, Including Painters, Sculptors, and Illustrators",
          "Multi-Media Artists and Animators",
          "Photographers",
        ],
      },
    ],
  },
  {
    title: "Business, Management and Administration",
    icon: Business,
    subMenu: [
      {
        title: "Administrative and Information Support",
        options: [
          "Legal Secretaries",
          "Library Assistants, Clerical",
          "Medical Secretaries",
          "New Accounts Clerks",
          "Order Fillers, Wholesale and Retail Sales",
          "Public Relations Specialists",
        ],
      },
      {
        title: "Business Analysis",
        options: [
          "Budget Analysts",
          "Energy Auditors",
          "Management Analysts",
          "Operations Research Analysts",
          "Sustainability Specialists",
        ],
      },
      {
        title: "Business Financial Management and Accounting",
        options: [
          "Accountants and Auditors",
          "Bookkeeping, Accounting, and Auditing Clerks",
          "Chief Executives",
          "Financial Managers",
          "Treasurers and Controllers",
        ],
      },
      {
        title: "Human Resources",
        options: [
          "Compensation and Benefits Managers",
          "Compensation, Benefits, and Job Analysis Specialists",
          "Human Resources Managers",
          "Training and Development Specialists",
        ],
      },
      {
        title: "Management",
        options: [
          "Administrative Services Managers",
          "Agents and Business Managers of Artists, Performers, and Athletes",
          "Chief Sustainability Officers",
          "Compliance Managers",
          "General and Operations Managers",
          "Medical and Health Services Managers",
          "Public Relations Managers",
          "Purchasing Managers",
          "Regulatory Affairs Managers",
          "Wind Energy Project Managers",
        ],
      },
      {
        title: "Marketing",
        options: [
          "Advertising and Promotions Managers",
          "Advertising Sales Agents",
          "Art Directors",
          "Marketing Managers",
          "Sales Managers",
        ],
      },
    ],
  },
  {
    title: "Education and Training",
    icon: Education,
    subMenu: [
      {
        title: "Administration and Administrative Support",
        options: ["Education Administrators", "Instructional Coordinators"],
      },
      {
        title: "Professional Support Services",
        options: [
          "Clinical, Counseling, and School Psychologists",
          "Educational, Vocational, and School Counselors",
          "Instructional Designers and Technologists ",
          "Librarians",
        ],
      },
      {
        title: "Teaching/Training",
        options: [
          "Adaptive Physical Education Specialists",
          "Adult Literacy, Remedial Education, and GED Teachers and Instructors",
          "Anthropology and Archeology Teachers, Postsecondary",
          "Architecture Teachers, Postsecondary",
          "Archivists",
          "Coaches and Scouts",
          "Communications Teachers, Postsecondary",
          "Computer Science Teachers, Postsecondary",
          "Curators",
          "Special Education Teachers",
          "Teachers and Instructors",
          "Vocational Education Teachers",
        ],
      },
    ],
  },
  {
    title: "Finance",
    icon: Finance,
    subMenu: [
      {
        title: "Banking and Related Services",
        options: [
          "Credit Analysts",
          "Loan Officers",
          "Title Examiners, Abstractors, and Searchers",
        ],
      },
      {
        title: "Business Financial Management",
        options: [
          "Financial Analysts",
          "Financial Quantitative Analysts",
          "Fraud Examiners, Investigators and Analysts",
        ],
      },
      {
        title: "Financial and Investment Planning",
        options: [
          "Brokerage Clerks",
          "Investment Fund Managers",
          "Investment Underwriters",
          "Personal Financial Advisors",
          "Risk Management Specialists",
          "Sales Agents, Financial Services",
          "Securities and Commodities Traders",
          "Securities, Commodities, and Financial Services Sales Agents",
          "Tax Preparers",
        ],
      },
      {
        title: "Insurance Services",
        options: [
          "Actuaries",
          "Claims Adjusters, Examiners, and Investigators",
          "Insurance Sales Agents",
          "Insurance Underwriters",
        ],
      },
    ],
  },
  {
    title: "Government and Public Administration",
    icon: Government,
    subMenu: [
      {
        title: "Governance",
        options: ["Legislators"],
      },
      {
        title: "Planning",
        options: [
          "Climate Change Analysts",
          "Economists",
          "Urban and Regional Planners",
        ],
      },
      {
        title: "Public Management and Administration",
        options: ["Court Clerks", "Postmasters and Mail Superintendents"],
      },
      {
        title: "Regulation",
        options: [
          "Compliance Officers",
          "Coroners",
          "Detectives and Criminal Investigators",
          "Financial Examiners",
          "Government Property Inspectors and Investigators",
          "Licensing Examiners and Inspectors",
        ],
      },
      {
        title: "Revenue and Taxation",
        options: [
          "Appraisers and Assessors of Real Estate",
          "Tax Examiners, Collectors, and Revenue Agents",
        ],
      },
    ],
  },
  {
    title: "Health Science",
    icon: Health,
    subMenu: [
      {
        title: "Diagnostic Services",
        options: [
          "    Medical and Clinical Laboratory Technologists",
          "Nuclear Medicine Technologists",
          "Ophthalmic Medical Technologists and Technicians",
          "Radiologic Technologists and Technicians ",
        ],
      },
      {
        title: "Health Informatics",
        options: [
          "    Epidemiologists",
          "Health Educators",
          "Informatics Nurse Specialists",
          "Medical Transcriptionists ",
        ],
      },
      {
        title: "Support Services",
        options: [
          "  Mental Health and Substance Abuse Social Workers",
          "Patient Representatives",
        ],
      },
      {
        title: "Therapeutic Services",
        options: [
          "  Acupuncturists",
          "Allergists and Immunologists",
          "Anesthesiologists",
          "Athletic Trainers",
          "Audiologists",
          "Chiropractors",
          "Critical Care Nurses",
          "Dental Hygienists",
          "Dentists",
          "Dermatologists",
          "Dietitians and Nutritionists",
          "Family and General Practitioners",
          "Genetic Counselors",
          "Hospitalists",
          "Internists",
          " General",
          "Midwives",
          "Naturopathic Physicians",
          "Neurologists",
          "Neuropsychologists and Clinical",
          " Neuropsychologists",
          "Nurse Practitioners",
          "Obstetricians and Gynecologists",
          "Occupational Therapists",
          "Ophthalmologists",
          "Optometrists",
          "Orthodontists",
          "Orthotists and Prosthetists",
          "Pathologists",
          "Pharmacists",
          "Physical Therapists",
          "Physician Assistants",
          "Podiatrists",
          "Psychiatrists",
          "Psychologists",
          "Registered Nurses",
          "Respiratory Therapists",
          "Speech-Language Pathologists",
          "Surgeons",
        ],
      },
    ],
  },
  {
    title: "Hospitality and Tourism",
    icon: Hospitality,
    subMenu: [
      {
        title: "Lodging",
        options: [
          "Concierges",
          "First-Line Supervisors/Managers",
          "Lodging Managers",
          "Recreation",
          " Amusements and Attractions",
          "Animal Trainers",
          "Athletes and Sports Competitors",
          "Museum Technicians and Conservators",
          "Self-Enrichment Education Teachers",
          "Set and Exhibit Designers",
          "Umpires",
          " Referees and Other Sports Officials",
        ],
      },
      {
        title: "Restaurants and Food/Beverage Services",
        options: [
          "Bakers",
          "Bartenders",
          "Chefs and Head Cooks",
          "Food Service Managers",
          "Waiters and Waitresses",
        ],
      },
      {
        title: "Travel and Tourism",
        options: [
          "Interpreters and Translators",
          "Meeting and Convention Planners",
          "Reservation and Transportation",
          " Ticket Agents and Travel Clerks",
          "Travel Agents",
          "Travel Guides",
        ],
      },
    ],
  },
  {
    title: "Human Services",
    icon: Human,
    subMenu: [
      {
        title: "Consumer Services",
        options: [
          "Loan Counselors",
          "Property Real Estate and Community Association Managers",
        ],
      },
      {
        title: "Counseling and Mental Health Services",
        options: [
          "Marriage and Family Therapists",
          "Mental Health Counselors",
          "Rehabilitation Counselors",
          "Social and Community Service Managers",
          "Substance Abuse and Behavioral Disorder Counselors",
        ],
      },
      {
        title: "Early Childhood Development and Services",
        options: [
          "Child Care Workers",
          "Family and Community Services",
          "Child, Family, and School Social Workers",
          "Clergy",
          "Medical and Public Health Social Workers",
          "Residential Advisors",
        ],
      },
      {
        title: "Personal Care Services",
        options: [
          "Embalmers",
          "First-Line Supervisors/Managers of Personal Service Workers",
          "Fitness Trainers and Aerobics Instructors",
          "Funeral Directors",
          "Hairdressers, Hairstylists, and Cosmetologists",
          "Massage Therapists",
        ],
      },
    ],
  },
  {
    title: "Information Technology",
    icon: InformationTechnology,
    subMenu: [
      {
        title: "Information Support and Services",
        options: [
          "Business Intelligence Analysts",
          "Computer Support Specialists",
          "Computer Systems Engineers/Architects",
          "Document Management Specialists",
          "Information Technology Project Managers",
          "Web Administrators",
        ],
      },
      {
        title: "Network Systems",
        options: [
          "Computer Security Specialists",
          "Data Warehousing Specialists",
          "Database Administrators",
          "Database Architects",
          "Network and Computer Systems Administrators",
          "Network Designers",
          "Network Systems and Data Communications Analysts",
          "Telecommunications Specialists",
        ],
      },
      {
        title: "Programming and Software Development",
        options: [
          "Computer and Information Systems Managers",
          "Computer Programmers",
          "Computer Software Engineers",
          "Computer Systems Analysts",
          "Software Quality Assurance Engineers and Testers",
          "Video Game Designers",
          "Web Developers",
        ],
      },
    ],
  },
  {
    title: "Law, Public Safety, Corrections and Security",
    icon: Law,
    subMenu: [
      {
        title: "Correction Services",
        options: [
          " Correctional Officers and Jailers",
          "Probation Officers and Correctional Treatment Specialists",
          "Social Workers, All Other",
        ],
      },
      {
        title: "Emergency and Fire Management Services",
        options: [
          " Emergency Management Specialists",
          "Emergency Medical Technicians and Paramedics",
          "Fire Fighters",
          "Fire Investigators",
        ],
      },
      {
        title: "Law Enforcement Services",
        options: [
          " Criminal Investigators and Special Agents",
          "Forensic Science Technicians",
          "Police and Sheriff's Patrol Officers",
          "Police Detectives",
          "Transportation Security Officers",
        ],
      },
      {
        title: "Legal Services",
        options: [
          " Administrative Law Judges, Adjudicators, and Hearing Officers",
          "Arbitrators, Mediators, and Conciliators",
          "Law Clerks",
          "Lawyers",
          "Paralegals and Legal Assistants",
        ],
      },
      {
        title: "Security and Protective Services",
        options: [
          " Intelligence Analysts",
          "Loss Prevention Managers",
          "Private Detectives and Investigators",
          "Security Management Specialists",
          "Security Managers",
        ],
      },
    ],
  },
  {
    title: "Manufacturing",
    icon: Manufacturing,
    subMenu: [
      {
        title: "Production",
        options: [
          "Biofuels Processing Technicians",
          "Biomass Plant Technicians",
          "Chemical Plant and System Operators",
          "Computer-Controlled Machine Tool Operators, Metal and Plastic",
          "Glass Blowers, Molders, Benders, and Finishers",
          "Grinding and Polishing Workers, Hand",
          "Industrial Engineering Technicians",
          "Industrial Production Managers",
          "Mechanical Engineering Technicians",
          "Nuclear Power Reactor Operators",
          "Numerical Tool and Process Control Programmers",
          "Purchasing Agents, Except Wholesale, Retail, and Farm Products",
          "Semiconductor Processors",
        ],
      },
    ],
  },
  {
    title: "Marketing, Sales and Service",
    icon: Marketing,
    subMenu: [
      {
        title: "Buying and Merchandising",
        options: [
          "Online Merchants",
          "Procurement Clerks",
          "Stock Clerks, Sales Floor",
        ],
      },
      {
        title: "Marketing Information Management and Research",
        options: ["Market Research Analysts"],
      },
      {
        title: "Professional Sales and Marketing",
        options: [
          "Appraisers, Real Estate",
          "Electronic Commerce Specialists",
          "Fashion Designers",
          "Real Estate Brokers",
          "Real Estate Sales Agents",
          "Sales Representatives",
          "Wholesale and Retail Buyers",
        ],
      },
    ],
  },
  {
    title: "Science, Technology, Engineering and Mathematics",
    icon: Science,
    subMenu: [
      {
        title: "Engineering and Technology",
        options: [
          "Aerospace Engineers",
          "Architectural and Civil Drafters",
          "Biochemical Engineers",
          "Chemical Engineers",
          "Computer Hardware Engineers",
          "Electrical Engineers",
          "Energy Engineers",
          "Engineering Managers",
          "Fuel Cell Engineers",
          "Industrial Engineers",
          "Logistics Engineers",
          "Manufacturing Engineers",
          "Marine Engineers and Naval Architects",
          "Materials Engineers",
          "Mechanical Engineers",
          "Nanosystems Engineers",
          "Nuclear Engineers",
          "Petroleum Engineers",
          "Product Safety Engineers",
          "Quality Control Systems Managers",
          "Robotics Engineers",
          "Solar Energy Systems Engineers",
          "Technical Writers",
          "Transportation Engineers",
          "Validation Engineers",
          "Water/Wastewater Engineers",
          "Wind Energy Engineers",
        ],
      },
      {
        title: "Science and Math",
        options: [
          "Atmospheric and Space Scientists",
          "Biochemists and Biophysicists",
          "Biofuels/Biodiesel Technology and Product Development Managers",
          "Bioinformatics Scientists",
          "Biostatisticians",
          "Chemists",
          "Environmental Economists",
          "Environmental Restoration Planners",
          "Geospatial Information Scientists and Technologists",
          "Hydrologists",
          "Industrial Ecologists",
          "Materials Scientists",
          "Microbiologists",
          "Molecular and Cellular Biologists",
          "Non-Destructive Testing Specialists",
          "Park Naturalists",
          "Quality Control Analysts",
          "Statisticians",
          "Survey Researchers",
          "Water Resource Specialists",
        ],
      },
    ],
  },
  {
    title: "Transportation, Distribution and Logistics",
    icon: Transportation,
    subMenu: [
      {
        title: "Health, Safety and Environmental Management",
        options: ["Industrial Safety and Health Engineers"],
      },
      {
        title: "Logistics Planning and Management Services",
        options: [
          "Logistics Analysts",
          "Logistics Managers",
          "Sales and Service",
          "Cargo and Freight Agents",
        ],
      },
      {
        title: "Transportation Operations",
        options: [
          "Air Traffic Controllers",
          "Airfield Operations Specialists",
          "Airline Pilots, Copilots, and Flight Engineers",
          "Ship Engineers",
          "Subway and Streetcar Operators",
        ],
      },
      {
        title:
          "Transportation Systems/Infrastructure Planning, Management and Regulation",
        options: [
          "Aviation Inspectors",
          "Customs Brokers",
          "Freight and Cargo Inspectors",
          "Transportation Planners",
        ],
      },
      {
        title: "Warehousing and Distribution Center Operations",
        options: [
          "First-Line Supervisors/Managers of Transportation and Material-Moving Machine and Vehicle Operators",
          "Laborers and Freight, Stock, and Material Movers, Hand",
          "Shipping, Receiving, and Traffic Clerks",
          "Storage and Distribution Managers",
          "Supply Chain Managers",
          "Tank Car, Truck, and Ship Loaders",
        ],
      },
    ],
  },
];
