import { QuestionArr } from "helpers/constants/profileForm";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import uuid from "react-uuid";
import { updateDetails, updateQuizStep } from "redux/profile-form";
import {
  createMeProfile,
  uploadPhaseoneProfile,
} from "redux/profile-form/action";
import { valueQuestionArr } from "../LaptopForm/components/WebValueQuizForm/helpers/constant";
import { showToast } from "redux/toaster";

const usePhaseOne = (field) => {
  const dispatch = useDispatch();
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const user = useSelector((state) => state?.user?.user);

  const getDisabledState = (step, totalSteps, details, field) => {
    if (field === "" && step <= totalSteps - 1) return false;
    if (field === "dreamCompany") {
      return details[field].length === 4 ? false : true;
    }
    if (field === "careerInterest") {
      return details[field].length >= 3 ? false : true;
    }

    if (
      !details[field] ||
      details[field] === "" ||
      details[field].length === 0
    ) {
      return true;
    }

    return false;
  };

  const handleFileChange = (e) => {
    uploadImage(e.target.files[0], `${details.name}-profile-pic-${uuid()}`);
  };

  const uploadImage = async (imageFile, name) => {
    try {
      setIsImageLoading(true);
      dispatch(uploadPhaseoneProfile(imageFile, name));
      setIsImageLoading(false);
    } catch (err) {
      setIsImageLoading(false);
      console.log(err.message);
    }
  };

  const handleOptionDeselect = (quizStep, option) => {
    let updatedQuiz;
    const currentQuiz = details[field]?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );

    if (currentQuiz && currentQuiz?.options.includes(option)) {
      if (currentQuiz.options.length === 1) {
        updatedQuiz = details[field].quizData.filter(
          (quiz) => quiz.quizStep !== quizStep
        );

        dispatch(
          updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
        );

        return;
      }
      updatedQuiz = details[field].quizData.map((quiz) => {
        if (quiz.quizStep === quizStep) {
          return {
            ...quiz,
            options: quiz.options.filter((_option) => _option !== option),
          };
        }
        return quiz;
      });

      dispatch(
        updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
      );

      return;
    }
  };

  const handleAnswerSelection = (quizStep, option) => {
    let updatedQuiz;
    const currentQuiz = details[field]?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );

    if (currentQuiz) {
      if (currentQuiz?.options.length === 2) {
        dispatch(
          showToast({
            message: "You can only select upto 2 options .",
            type: "error",
          })
        );

        return;
      }
      updatedQuiz = details[field].quizData.map((quiz) => {
        if (quiz.quizStep === quizStep) {
          return {
            ...quiz,
            options: [...quiz.options, option],
          };
        }
        return quiz;
      });
    } else {
      updatedQuiz = [
        ...details[field].quizData,
        {
          quizStep,
          options: [option],
        },
      ];
    }

    dispatch(
      updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
    );
  };

  const checkUnattemptedQuiz = () => {
    for (var i = 0; i < QuestionArr.length; i++) {
      const index = details[field].quizData.findIndex(
        (quiz) => quiz.quizStep === i
      );
      if (
        index !== -1 &&
        details[field]?.quizData[index].options.length === 0
      ) {
        return i;
      } else if (index === -1) {
        return i;
      }
    }
  };

  const handleButtonWrapperClick = () => {
    if (field === "valueQuiz") {
      if (details[field]?.quizData.length !== valueQuestionArr.length) {
        dispatch(updateQuizStep(checkUnattemptedQuiz()));
        dispatch(
          showToast({
            message:
              "You have to select at least one option from each section .",
            type: "error",
          })
        );
      }
    }

    return;
  };

  const handlePhaseOneComplete = async () => {
    try {
      const {
        name,
        course,
        mobile,
        college,
        collegeYear,
        profileImage,
        quiz,
        valueQuiz,
        careerInterest,
        dreamCompany,
        achievements,
      } = details;
      const postData = {
        name: name,
        personalInfo: {
          course: course,
          collegeName: college,
          collegeYear: collegeYear,
        },
        phoneNumber: mobile,
        image: profileImage,
        interests: { career: careerInterest, sdg: dreamCompany },
        riasec: { data: quiz.quizData },
        values: { data: valueQuiz.quizData },
        achievements: achievements,
      };

      await dispatch(createMeProfile(postData, user?._id));
    } catch (err) {
      console.log(err.message);
    }
  };

  return {
    getDisabledState,
    handlePhaseOneComplete,
    handleButtonWrapperClick,
    checkUnattemptedQuiz,
    uploadImage,
    isImageLoading,
    handleFileChange,
    handleOptionDeselect,
    handleAnswerSelection,
  };
};
export default usePhaseOne;
