import { scrollToBottom } from "helpers/utils/profileForm/functions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/profile-form";
import { dreamCompanyOptions } from "./utils/constant";
import { showToast } from "redux/toaster";

const DreamCompanyForm = ({ field }) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();

  const handleCompanySelection = (val) => {
    if (details[field].includes(val)) {
      const filteredArr = details[field].filter((comp) => comp !== val);
      dispatch(updateDetails({ field, value: filteredArr }));
      return;
    }
    if (details[field].length === 4) {
      dispatch(
        showToast({
          message: "'Cannot select more than 4 options",
          type: "error",
        })
      );
      return;
    }
    dispatch(updateDetails({ field, value: [...details[field], val] }));
  };
  useEffect(() => {
    if (details[field].length === 4) {
      scrollToBottom();
    }
  }, [details[field]]);
  return (
    <div className=" font-manrope h-full flex flex-col pt-24 p-4 md:px-10  ">
      <div className="flex flex-col gap-4">
        <h4 className="text-lg text-primary-blue-500 max-w-sm font-medium ">
          Which of these causes would you like your work to positively impact?
        </h4>
        <div className="flex flex-col">
          <p className="font-satoshi text-primary-grey-600 text-md ">
            Select the ones you like the most
          </p>
          <div className="flex flex-col  items-end w-full text-center ">
            <p className="font-satoshi text-primary-grey-600 text-md text-center font-bold tracking-widest">
              ({details[field].length} / 4)
            </p>
            <p className="font-satoshi text-primary-grey-600 text-xs text-center ">
              selected
            </p>
          </div>
        </div>
      </div>
      <div className="py-6 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 ">
        {dreamCompanyOptions.map((option, idx) => (
          <div
            onClick={() => handleCompanySelection(option.title)}
            key={idx}
            className={`cursor-pointer py-4 px-2 ${
              details[field].includes(option.title) &&
              "border border-primary-blue-500 bg-primary-grey-100"
            } rounded-2xl flex flex-col gap-4 items-center hover:bg-primary-grey-150 `}
          >
            <img className="w-14 h-14 object-contain" src={option.icon} />

            <p className="font-satoshi text-primary-grey-800 text-xs text-center ">
              {option.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DreamCompanyForm;
