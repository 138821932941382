import { useDispatch, useSelector } from "react-redux";

import { updateAns, updateDetails } from "redux/typeform";
import Wrapper from "./Wrapper";
import { nextStep, prevStep } from "redux/typeform";
import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/20/solid";

const College = ({
  id,
  question,
  description,
  type,
  subtitle,
  placeholder,
}) => {
  const { details, currentSection } = useSelector((state) => state.typeForm);
  const dispatch = useDispatch();
  const [data, setData] = useState(
    details?.sectionMetaData?.basicForm[currentSection]?.find(
      (item) => item.id === id
    )?.value || {}
  );
  const [error, setError] = useState();

  const [newCollege, setNewCollege] = useState(false);
  const [showCollegesDropdown, setShowCollegesDropdown] = useState(false);
  const [showCollegesLocationDropdown, setShowCollegesLocationDropdown] =
    useState(false);
  const colleges = useSelector((state) => state?.dropdown?.colleges);
  const [filteredItems, setFilteredItems] = useState(colleges);
  const [filteredLocationItems, setFilteredLocationItems] = useState(state);

  const dropdownRef = useRef(null);
  const dropdownLocationRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowCollegesDropdown(false);
    }
    if (
      dropdownLocationRef.current &&
      !dropdownLocationRef.current.contains(event.target)
    ) {
      setShowCollegesLocationDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredItems(colleges);
  }, [colleges]);
  useEffect(() => {
    setData(
      details?.sectionMetaData?.basicForm[currentSection]?.find(
        (item) => item.id === id
      )?.value || {}
    );
    setError();
  }, [id]);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <Wrapper
        onNextClick={() => {
          if (data == null || data == undefined || data == "") {
            setError(`Please enter a valid college`);
            return;
          }

          dispatch(updateAns({ id: id, value: data, section: currentSection }));

          dispatch(
            updateDetails({
              field: newCollege ? "newCollege" : "college_id",
              value: newCollege ? data : data?.id,
            })
          );
          dispatch(
            updateDetails({
              field: "college_name",
              value: data?.college_name,
            })
          );

          dispatch(nextStep({ section: true }));
        }}
        onBackClick={() => dispatch(prevStep({ section: true }))}
        disabled={false}
      >
        <div
          key={id}
          className={`flex flex-col w-full max-w-4xl mx-auto px-6 py-16 justify-center items-center`}
        >
          <div className="flex flex-col items-center space-y-4 w-full">
            {subtitle && (
              <div className="w-full capitalize flex flex-col md:flex-row md:items-center justify-start space-y-2 md:space-y-0 md:space-x-3 text-caaryaRed font-satoshi text-sm font-medium lg:tracking-[1.4px] tracking-[1.2px] lg:leading-5">
                <div className="w-5 h-5 lg:w-6 lg:h-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 8C19.55 8 18.74 9.44 19.07 10.51L15.52 14.07C15.22 13.98 14.78 13.98 14.48 14.07L11.93 11.52C12.27 10.45 11.46 9 10 9C8.55 9 7.73 10.44 8.07 11.52L3.51 16.07C2.44 15.74 1 16.55 1 18C1 19.1 1.9 20 3 20C4.45 20 5.26 18.56 4.93 17.49L9.48 12.93C9.78 13.02 10.22 13.02 10.52 12.93L13.07 15.48C12.73 16.55 13.54 18 15 18C16.45 18 17.27 16.56 16.93 15.48L20.49 11.93C21.56 12.26 23 11.45 23 10C23 8.9 22.1 8 21 8Z"
                      fill="#ED4C41"
                    />
                    <path
                      d="M15 9L15.94 6.93L18 6L15.94 5.07L15 3L14.08 5.07L12 6L14.08 6.93L15 9ZM3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z"
                      fill="#ED4C41"
                    />
                  </svg>
                </div>{" "}
                <p>{subtitle}</p>
              </div>
            )}
            <h1
              className={`w-full mt-5 text-black text-left font-satoshi text-xl lg:text-[28px] leading-9 lg:leading-[50.4px]`}
            >
              {question}
            </h1>
            <p className="w-full text-primary-gray-500 text-left font-manrope text-sm lg:text-base font-light">
              {description}
            </p>
          </div>

          <div
            className={`mt-8 lg:mt-12 py-3 lg:py-4 text-lg lg:text-2xl flex flex-col space-y-2 w-full transition-all`}
          >
            <div className="flex flex-col space-y-10 w-full max-w-[560px]">
              <div className="relative w-full flex flex-row items-center">
                {data?.new_college_name ? (
                  <div
                    onFocus={() => setShowCollegesDropdown(true)}
                    className="flex w-full flex-row items-center justify-between text-lg lg:text-2xl text-dropdown"
                  >
                    <p>{data?.new_college_name}</p>
                    <XCircleIcon
                      className="w-6 h-6"
                      onClick={() => {
                        setData({});
                        setNewCollege(false);
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-between text-lg lg:text-2xl text-dropdown">
                    <input
                      type="text"
                      className="w-11/12 border-0 p-0"
                      placeholder="Select your college"
                      value={data.college_name}
                      onChange={(e) => {
                        setData({
                          ...data,

                          college_name: e.target.value,
                        });
                        const filtered = colleges.filter((item) =>
                          item.college_name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        );
                        setFilteredItems(filtered);
                        setShowCollegesDropdown(true);
                      }}
                      onFocus={() => setShowCollegesDropdown(true)}
                    />
                    <ChevronDownIcon
                      className="text-black absolute right-6 top-5 lg:top-6 w-6 h-6"
                      onClick={() => setShowCollegesDropdown(true)}
                    />
                  </div>
                )}
                {showCollegesDropdown && (
                  <div
                    className="top-16 absolute z-10 mt-2 bg-white border rounded-md shadow-md w-full max-h-48 overflow-scroll"
                    ref={dropdownRef}
                  >
                    {filteredItems.map((item) => (
                      <div
                        key={item.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer inter font-light text-sm leading-[150%] tracking-[5%] text-black"
                        onClick={() => {
                          setData({
                            id: item.id,
                            college_name: item.college_name,
                            college_location: item.college_location,
                          });
                          setShowCollegesDropdown(false);
                          setNewCollege(false);
                        }}
                      >
                        {item.college_name}
                      </div>
                    ))}
                    <div
                      className="p-2 hover:bg-gray-100 cursor-pointer inter font-semibold text-sm leading-[150%] tracking-[5%] text-[#ED4C41]"
                      onClick={() => {
                        setData({
                          new_college_name: data?.college_name,
                          college_name: "Other",
                        });
                        setNewCollege(true);
                        setShowCollegesDropdown(false);
                      }}
                    >
                      + Add "{data?.college_name}"
                    </div>
                  </div>
                )}
              </div>

              {(newCollege || data?.college_name == "Other") && (
                <>
                  <div className="relative w-full flex flex-row items-center">
                    <input
                      type="text"
                      className="flex w-full flex-row items-center justify-between text-lg lg:text-2xl text-dropdown"
                      placeholder="Select state"
                      value={data.college_location}
                      onChange={(e) => {
                        setData({
                          ...data,
                          college_location: e.target.value,
                        });
                        const filtered = state.filter((item) =>
                          item
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        );
                        setFilteredLocationItems(filtered);
                        setShowCollegesLocationDropdown(true);
                      }}
                      onFocus={() => setShowCollegesLocationDropdown(true)}
                    />
                    <ChevronDownIcon className="text-black absolute right-2 top-5 lg:top-6 w-6 h-6" />

                    {showCollegesLocationDropdown && (
                      <div
                        className="top-16 absolute z-10 mt-2 bg-white border rounded-md shadow-md w-full max-h-48 overflow-scroll"
                        ref={dropdownLocationRef}
                      >
                        {filteredLocationItems.map((item) => (
                          <div
                            key={item}
                            className="p-2 hover:bg-gray-100 cursor-pointer inter font-light text-sm leading-[150%] tracking-[5%] text-black"
                            onClick={() => {
                              setData({
                                ...data,

                                college_location: item,
                              });
                              setShowCollegesLocationDropdown(false);
                            }}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            {error && (
              <p className="text-primary-neutral-800 font-lato text-[10px] leading-3 tracking-[0.64px]">
                {error}
              </p>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default College;
let state = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
