import ViewCard, { ViewCardSmall } from "components/Achievements/Add/ViewCard";
import Toast from "components/Achievements/Common/Toast";
import { ViewProjectFilter } from "components/Achievements/View/FilterDrawer";
import { findProjectById, getBoosters } from "config/APIs/achievementCards";
import { getBoosterCount, getColor, getImage } from "helpers/utils";
import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { CgMoreVerticalAlt, CgSpinner } from "react-icons/cg";
import { FaBook } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { IoMdAddCircle } from "react-icons/io";
import {
  MdArrowBack,
  MdBolt,
  MdClose,
  MdExpandMore,
  MdSort,
} from "react-icons/md";
import { RiArrowDownSFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { setBoosters } from "redux/achievementsForm";
import { fetchAllRoles } from "redux/roles";
import { useToaster } from "rsuite";

export default function ViewPage() {
  const { projectId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    project: null,
    cards: null,
    originalCards: null,
  });
  const [selectedCard, setSelectedCard] = useState(null);
  const { boosters } = useSelector((state) => state.achievementsForm);
  const dispatch = useDispatch();
  const availableRoles = useSelector((state) => state.roles.list);
  const toaster = useToaster();
  const [sortBy, setSortBy] = useState({
    open: false,
    value: "recent",
  });
  const [role, setRole] = useState({
    open: false,
    value: "all",
    roles: [],
  });

  const fetchProject = async () => {
    try {
      const {
        data: { data: response },
      } = await findProjectById(projectId);

      let cards =
        response?.cards?.cards?.map((card) => {
          const role = availableRoles?.find(
            (role) => role._id === card.role_id
          );
          const selectedConstruct = role?.valueConstructs?.find(
            (construct) => construct._id === card.construct_id
          );

          return {
            time: card.completedOn ? new Date(card.completedOn) : null,
            roleId: card.role_id,
            cardRole: role?.name,
            value: selectedConstruct?.responsibilityStatement,
            techSkills: selectedConstruct?.technicalSkills || [],
            serviceName: selectedConstruct?.name,
            construct_id: card.construct_id,
          };
        }) || [];

      cards = cards.sort((a, b) => b.time - a.time);

      setState({
        loading: false,
        project: response.project,
        cards,
        originalCards: cards,
      });

      const uniqueRoleIds = new Set(cards?.map((card) => card.roleId));
      const matchingRoles = availableRoles.filter((role) =>
        uniqueRoleIds.has(role._id)
      );
      setRole((prev) => ({
        ...prev,
        roles: matchingRoles.map((r) => ({ value: r._id, label: r.name })),
      }));
    } catch (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      toaster.push(<Toast />, {
        placement: "topEnd",
      });
    }
  };

  const fetchBoosters = async () => {
    if (boosters.length > 0) return;
    try {
      const {
        data: { data: boosters },
      } = await getBoosters();
      dispatch(setBoosters(boosters));
    } catch (error) {
      console.log(error);
      toaster.push(<Toast />, {
        placement: "topEnd",
      });
    }
  };

  useEffect(() => {
    if (availableRoles?.length === 0 || !availableRoles) {
      dispatch(fetchAllRoles());
    } else {
      fetchBoosters();
      fetchProject();
    }
  }, [availableRoles]);

  useEffect(() => {
    if (state.cards?.length) {
      const sortFn = (a, b) => {
        if (sortBy.value === "oldest") {
          return new Date(a?.time) - new Date(b?.time);
        } else {
          return new Date(b?.time) - new Date(a?.time);
        }
      };

      setState((prev) => ({
        ...prev,
        cards: [...prev.cards].sort(sortFn),
      }));
    }
  }, [sortBy.value]);

  useEffect(() => {
    if (state.cards?.length) {
      if (role.value === "all") {
        setState((prev) => ({
          ...prev,
          cards: [...prev.originalCards],
        }));
        return;
      } else {
        setState((prev) => ({
          ...prev,
          cards: [...prev.originalCards].filter((c) => c.roleId === role.value),
        }));
      }
    }
  }, [role.value]);

  if (state.loading) {
    return (
      <section className="bg-white h-screen w-full flex items-center justify-center">
        <CgSpinner className="text-3xl text-black animate-spin" />
      </section>
    );
  }

  return (
    <section className="pt-[53px] pb-20">
      <ViewProjectFilter
        title="Sort Achievements By"
        icon={<MdSort size={20} color="#363430" />}
        closeModal={() => setSortBy((prev) => ({ ...prev, open: false }))}
        isOpen={sortBy.open}
        value={sortBy.value}
        handleChange={(v) => {
          setSortBy((prev) => ({
            ...prev,
            value: v,
          }));
        }}
        options={[
          { value: "recent", label: "Recently Completed First" },
          { value: "oldest", label: "Oldest Completed First" },
        ]}
      />
      <ViewProjectFilter
        title="Roles In View"
        closeModal={() => setRole((prev) => ({ ...prev, open: false }))}
        isOpen={role.open}
        value={role.value}
        handleChange={(v) => {
          setRole((prev) => ({
            ...prev,
            value: v,
          }));
        }}
        options={[{ value: "all", label: "All" }, ...role.roles]}
      />
      {selectedCard && (
        <div className="fixed h-screen top-0 z-[80] w-full bg-black/20 flex justify-center gap-10 flex-col items-center">
          <ViewCard card={selectedCard} moreCtx={state.project?.details} />
          <div
            className="flex p-2 items-center gap-2 rounded-full cursor-pointer bg-black/50 backdrop-blur-[2px]"
            onClick={() => setSelectedCard(null)}
          >
            <MdClose size={32} className="text-white" />
          </div>
        </div>
      )}
      <div className="flex px-4 py-6 flex-row justify-between w-full bg-white">
        <div className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-satoshi text-base tracking-[0.64px] leading-6">
          <MdArrowBack
            className="text-primary-neutral-800 text-xl cursor-pointer"
            onClick={() => history.goBack()}
          />
          <p className="text-[16px]">Your Achievements</p>
        </div>
        <div className="relative">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() =>
              history.push(`/achievements/add?projectId=${state.project?._id}`)
            }
          >
            <IoMdAddCircle className="h-5 w-5 fill-black" />
            <p className="font-satoshi font-bold leading-5 text-sm tracking-[0.21px] text-primary-neutral-900 underline underline-offset-2">
              Add Now
            </p>
          </div>
        </div>
      </div>
      <main className="bg-primary-neutral-50 px-6 sm:px-20 md:px-32 flex flex-col gap-6">
        <div className="flex w-full justify-between py-4 sm:py-10 max-w-[1200px]">
          <div className="flex flex-col items-start gap-4 w-full">
            <div className="flex flex-col items-start gap-2 w-full">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-4 w-full">
                  <h1 className="text-black text-2xl md:text-3xl font-bold capitalize">
                    {state?.project?.name}
                  </h1>
                  <div
                    className="flex py-0.5 px-2 justify-center items-center gap-2 rounded text-white font-satoshi text-2xs md:text-xs font-bold"
                    style={{
                      background: getColor(state.project?.details?.category),
                    }}
                  >
                    {state.project?.details?.category}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-1">
                    <MdBolt
                      size={16}
                      className={`bg-primary-accent-500 rounded-full text-white p-0.5 text-xl `}
                    />
                    <span className="font-satoshi text-xs font-bold leading-5 text-primary-neutral-800">
                      {getBoosterCount(state.project?.details?.boosters)}
                    </span>
                  </div>
                  <CgMoreVerticalAlt
                    size={24}
                    className="text-primary-neutral-500"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center gap-2 text-primary-neutral-400">
                {state.project?.details?.category === "Work" &&
                  state.project?.details?.companyName?.length > 0 && (
                    <ImOffice className="h-2.5 w-2.5" />
                  )}
                {state.project?.details?.category === "Academic" &&
                  state.project?.details?.courseName?.length > 0 && (
                    <FaBook className="h-2.5 w-2.5" />
                  )}{" "}
                <p className="text-xs font-normal capitalize">
                  {state.project?.details?.category === "Academic"
                    ? state.project?.details?.courseName
                    : state.project?.details?.category === "Work"
                    ? state.project?.details?.companyName
                    : ""}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <span className="font-satoshi text-primary-neutral-500 font-bold capitalize leading-4 tracking-[0.4px] text-2xs">
                  {state.cards?.length} Achievements
                </span>
                <BsDot className="h-1 w-1 rounded-full bg-primary-neutral-500" />
                <span className="font-satoshi text-primary-neutral-500 font-bold capitalize leading-4 tracking-[0.4px] text-2xs">
                  {new Set(state?.cards?.map((card) => card.roleId)).size} Roles
                </span>
              </div>
            </div>

            <div className="flex items-start gap-2 w-full justify-between">
              <p className="text-primary-neutral-800 flex-[1_0_0] self-stretch font-satoshi text-xs font-normal tracking-[0.48px]">
                {state.project?.details?.description}
              </p>
              <img
                src={getImage(state.project?.details?.category)}
                alt={state?.project?.name}
                className="h-[72px] w-[72px] object-contain"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between py-2 items-center">
          <div
            onClick={() => setRole((prev) => ({ ...prev, open: true }))}
            className="p-2 cursor-pointer pl-4 flex items-center justify-center gap-2 rounded-lg border border-primary-neutral-100 bg-white"
          >
            <span className="text-primary-neutral-500 font-satoshi text-xs font-normal leading-5">
              Roles:{" "}
              <span className="text-primary-neutral-800 font-bold">
                {role.value === "all"
                  ? "All"
                  : availableRoles.find((r) => r._id === role.value).name}
              </span>
            </span>
            <RiArrowDownSFill size={16} />
          </div>
          <div
            onClick={() => setSortBy((prev) => ({ ...prev, open: true }))}
            className="p-2 cursor-pointer flex items-center justify-center gap-2 rounded-lg border border-primary-neutral-100 bg-white"
          >
            <MdSort size={16} />
            <span className="text-primary-neutral-800 font-satoshi text-xs font-bold leading-5">
              Sort
            </span>
            <MdExpandMore size={16} />
          </div>
        </div>

        <div className="flex flex-col w-full gap-10 mb-10">
          <div className="flex flex-wrap max-md:flex-col sm:px-6 gap-6">
            {state.cards?.map((card, i) => (
              <div className="flex gap-2 max-w-[800px] items-start" key={i}>
                <ViewCardSmall
                  card={card}
                  moreCtx={state.project?.details}
                  handleClick={() => setSelectedCard(card)}
                />
              </div>
            ))}
          </div>
        </div>
      </main>
    </section>
  );
}
