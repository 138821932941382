import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";

const PreFooter = ({ locked = false }) => {
  const history = useHistory();
  const { ref, inView } = useInView({
    threshold: 0.4,
  });
  return (
    <div ref={ref} className="relative overflow-hidden">
      <div
        className={`bottom-0 left-0 w-full text-white px-6 pt-14 pb-20 gap-8 flex flex-col items-center justify-center`}
      >
        <div
          className={`absolute top-2 left-0 right-0 h-full clip-path duration-[600ms] ${
            locked ? "bg-primary-neutral-500" : "bg-[#3996E3]"
          } ${
            inView
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-[100%]"
          }`}
          style={{
            clipPath: "ellipse(120% 100% at 50% 100%)",
          }}
        ></div>

        <div
          className={`z-10 flex flex-col justify-center items-center py-3 gap-2 transition-all duration-[800ms] delay-150 ${
            inView
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-[50px]"
          }`}
        >
          <p className=" font-bold font-lato   text-lg leading-7 tracking-[0.36px] ">
            Unlock your ME
          </p>

          <p className="font-satoshi text-sm font-normal text-center text-white leading-5 tracking-[0.56px]">
            Your Measured Excellence, Your ME
          </p>
        </div>

        <div
          className={`z-10 flex flex-wrap justify-center gap-2 transition-all duration-[800ms] delay-300 w-full ${
            inView
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-[300px]"
          }`}
        >
          <div className="flex space-y-8 items-center text-center flex-col w-full">
            <img
              src={
                locked
                  ? "/assets/images/MeOp.svg"
                  : "/assets/images/MeOp_unlocked.png"
              }
              alt=""
              className="w-[120px] h-[120px]"
            />

            <div className="w-full xs:flex justify-center items-center-+">
              <div
                // onClick={() => history.push("/meProfile")}
                className={`bg-black/25 space-x-2 xs:space-x-3 items-center flex flex-row rounded-full py-4 px-14 max-xs:w-full max-xs:mx-auto xs:px-14 ${
                  locked ? "text-[#CFCDC9]" : "text-white"
                } font-satoshi text-sm font-bold leading-7 tracking-[0.9px] cursor-pointer`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5332 6.8835H6.60572V4.87803C6.60572 3.83628 7.45806 2.98397 8.49978 2.98397C9.35547 2.98397 10.0833 3.55903 10.3161 4.34178C10.4947 4.94272 11.2863 5.06619 11.6516 4.57666C11.7978 4.38075 11.8392 4.14909 11.7697 3.91475C11.3514 2.50481 10.0407 1.46875 8.49978 1.46875C6.62466 1.46875 5.09047 3.00294 5.09047 4.87806V6.8835H4.46638C3.77803 6.8835 3.21484 7.44669 3.21484 8.13503V14.2797C3.21484 14.9681 3.77803 15.5312 4.46638 15.5312H12.5332C13.2215 15.5312 13.7847 14.9681 13.7847 14.2797V8.13503C13.7847 7.44669 13.2215 6.8835 12.5332 6.8835ZM8.96856 11.213V12.7982C8.96856 13.0571 8.75869 13.267 8.49981 13.267C8.24094 13.267 8.03106 13.0571 8.03106 12.7982V11.213C7.66613 11.038 7.41412 10.6652 7.41412 10.2334C7.41412 9.63384 7.90019 9.14775 8.49981 9.14775C9.09944 9.14775 9.5855 9.63384 9.5855 10.2334C9.5855 10.6652 9.3335 11.038 8.96856 11.213Z"
                    fill="white"
                  />
                </svg>
                <p>Learn how to Unlock</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreFooter;
