import React from "react";

function PageHeader({ heading, img, description }) {
  const essentialsClass =
    heading === "Essentials" || heading === "Tribe Vibe"
      ? "text-2xl tracking-tight items-center justify-center"
      : "text-xl";
  const headerClass =
    heading === "Lets Make Us Better"
      ? "text-primary-black-medium"
      : "text-primary-red-medium";
  return (
    <>
      <div className={`flex flex-col space-y-2 pb-4 ${essentialsClass} `}>
        {img ? (
          <div>
            {" "}
            <img src={img} />{" "}
          </div>
        ) : (
          ""
        )}
        <h1
          style={
            heading === "Essentials" || heading === "Tribe Vibe"
              ? { letterSpacing: "3.6px" }
              : {}
          }
          className={`font-satoshi ${essentialsClass} font-bold leading-[42px] ${headerClass}`}
        >
          {heading}
        </h1>
        <p
          style={
            heading === "Essentials" || heading === "Tribe Vibe"
              ? { letterSpacing: "0.96px" }
              : {}
          }
          className="text-gray-800 font-lato text-xs font-light leading-5"
        >
          {description}
        </p>
      </div>
    </>
  );
}

export default PageHeader;
