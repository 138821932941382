import InViewWrapper from "components/Animation/InViewWrapper";
import { PassSectionPublic } from "components/Pass/PassSection";
import { experiences, membership } from "helpers/constants/accessPass";
import { Disclosure } from "@headlessui/react";
import { ArrowRight } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { getColleges } from "config/APIs/colleges";
import { enrollStudent } from "config/APIs/enroll";

function PublicPass() {
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    college: {
      id: "",
      college_name: "",
      college_location: "",
    },
  });
  const [newCollege, setNewCollege] = useState(false);
  const [showCollegesDropdown, setShowCollegesDropdown] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [filteredItems, setFilteredItems] = useState(colleges);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowCollegesDropdown(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await getColleges();
        setColleges(response.data);
        setFilteredItems(response.data);
      } catch (err) {
        console.log(err);
      }
    })();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const enroll = async () => {
    try {
      const body = {
        ...inputData,
      };
      if (newCollege) {
        body.college.isNew = true;
      }
      setInputData({
        name: "",
        email: "",
        phone: "",
        college: {
          id: "",
          college_name: "",
          college_location: "",
        },
      });
      const { data: response } = await enrollStudent(body);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-white -mb-10">
      <Disclosure
        as="nav"
        className="public-nav fixed top-0 left-0 right-0 z-40"
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <a href="/" className="flex flex-shrink-0 items-center">
                    <img
                      className="h-7 w-auto"
                      src="/assets/caaryaLogos/life.svg"
                      alt="Your Company"
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div
        style={{
          background: "linear-gradient(180deg, #ED4C41 0%, #F57D34 100%)",
        }}
        className="relative h-[50vh] lg:h-[60vh] flex flex-row items-center justify-center"
      >
        <InViewWrapper className="block" delay="0.1" yInitial="200">
          <div className="pt-28 w-full px-4 h-full flex flex-col items-center justify-center space-y-6">
            <div className="flex flex-col items-center lg:space-y-2">
              <h1 className="text-white max-w-4xl mx-auto w-full text-center font-poppins text-2xl lg:text-[40px] font-medium lg:leading-[60px]">
                Your Caarya Life
              </h1>
              <p className="text-white max-w-4xl mx-auto w-full text-center text-xs lg:text-base font-inter font-light leading-7 tracking-[0.36px]">
                Caarya (Kaarya): Work
              </p>
            </div>
            <p className="text-white max-w-4xl mx-auto w-full text-center text-base lg:text-xl font-inter font-light leading-7 tracking-[0.36px] lg:tracking-[1.6px]">
              Your journey at Caarya begins here. Some lorem ipsum giving basic
              info about the page
            </p>
          </div>
        </InViewWrapper>
      </div>
      <div className="bg-white relative flex flex-col items-stretch card z-30">
        <div className="h-[5vh] relative">
          <div
            style={{ clipPath: "ellipse(52vw 25px at 50% 100%)" }}
            className="absolute bottom-[1px] right-0 left-0 bg-[#F57D34] h-[5vh] rotate-180"
          />{" "}
        </div>
      </div>
      <InViewWrapper className="block mt-9" delay="0.2" yInitial="200">
        <PassSectionPublic {...experiences[0]} idx={0} />
      </InViewWrapper>
      {experiences?.map((item, index) => {
        if (index !== 0) return <PassSectionPublic {...item} idx={index} />;
      })}
      <div className="relative w-full max-w-7xl mx-auto px-4 py-8 lg:py-16 flex flex-col items-center space-y-8">
        <h1 className="text-primary-neutral-800 text-center font-poppins text-2xl font-medium">
          Get a Caarya Life Membership Today
        </h1>
        <p className="text-primary-neutral-800 text-center font-inter text-sm lg:text-xl font-light">
          Benefits Include
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {membership?.map((item) => {
            return (
              <div className="p-2 flex flex-col items-center space-y-4">
                <div
                  className="flex flex-row items-center justify-center rounded-lg h-10 w-10 lg:w-20 lg:h-20"
                  style={{ background: item?.bgColor, color: item?.color }}
                >
                  {item?.svg}
                </div>
                <p className="text-primary-neutral-800 font-inter text-2xs lg:text-sm font-light text-center">
                  {item?.label}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          background: "url('/assets/images/enroll.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="px-5 pt-12 pb-20 relative"
      >
        <div className="max-w-2xl mx-auto w-full flex flex-col items-center space-y-8">
          <div className="py-3 flex flex-col items-center space-y-2">
            <h1 className="text-white font-poppins text-lg lg:text-2xl font-semibold">
              Enroll Now!
            </h1>
            <p className="text-white font-inter text-sm lg:text-base font-light">
              Quis autem vel eum iure reprehenderit qui in ea voluptate
            </p>
          </div>

          <div className="flex flex-col space-y-6 w-full">
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-white font-poppins text-sm font-bold">
                Name*
              </label>
              <input
                required
                type="text"
                value={inputData.name}
                onChange={(e) =>
                  setInputData({ ...inputData, name: e.target.value })
                }
                className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
              />
            </div>
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-white font-poppins text-sm font-bold">
                Email*
              </label>
              <input
                required
                type="email"
                value={inputData.email}
                onChange={(e) =>
                  setInputData({ ...inputData, email: e.target.value })
                }
                className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
              />
            </div>
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-white font-poppins text-sm font-bold">
                Phone No.
              </label>
              <input
                type="number"
                value={inputData.phone}
                onChange={(e) =>
                  setInputData({ ...inputData, phone: e.target.value })
                }
                className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
              />
            </div>
            {!newCollege ? (
              <div className="flex flex-col space-y-2 w-full relative">
                <label className="text-white font-poppins text-sm font-bold">
                  College*
                </label>
                <div className="relative w-full">
                  <input
                    type="text"
                    className="bg-white w-full rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
                    placeholder="Start Typing..."
                    value={inputData.college.college_name}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        college: {
                          ...inputData.college,
                          college_name: e.target.value,
                        },
                      });
                      const filtered = colleges.filter((item) =>
                        item.college_name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                      setFilteredItems(filtered);
                      setShowCollegesDropdown(true);
                    }}
                    onFocus={() => setShowCollegesDropdown(true)}
                  />
                  {showCollegesDropdown && (
                    <div
                      className="absolute z-10 mt-2 bg-white border rounded-md shadow-md w-full max-h-48 overflow-scroll"
                      ref={dropdownRef}
                    >
                      {filteredItems.map((item) => (
                        <div
                          key={item.id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            setInputData({
                              ...inputData,
                              college: {
                                id: item.id,
                                college_name: item.college_name,
                                college_location: item.college_location,
                              },
                            });
                            setShowCollegesDropdown(false);
                          }}
                        >
                          {item.college_name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col space-y-2 w-full">
                  <label className="text-white font-poppins text-sm font-bold">
                    College Name*
                  </label>
                  <input
                    type="text"
                    value={inputData.college.college_name}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        college: {
                          ...inputData.college,
                          college_name: e.target.value,
                        },
                      })
                    }
                    required
                    className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
                  />
                </div>

                <div className="flex flex-col space-y-2 w-full">
                  <label className="text-white font-poppins text-sm font-bold">
                    College Location*
                  </label>
                  <input
                    type="text"
                    value={inputData.college.college_location}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        college: {
                          ...inputData.college,
                          college_location: e.target.value,
                        },
                      })
                    }
                    required
                    className="bg-white rounded-lg py-3 px-4 text-primary-neutral-800 placeholder:text-primary-neutral-400 font-poppins text-sm font-light border-0"
                  />
                </div>
              </>
            )}
            {!newCollege && (
              <div
                className="w-full text-center text-white font-poppins text-sm font-bold underline underline-offset-2 cursor-pointer"
                onClick={() => {
                  setNewCollege(true);
                  setInputData({
                    ...inputData,
                    college: {
                      id: "",
                      college_name: "",
                      college_location: "",
                    },
                  });
                }}
              >
                Can’t find my college
              </div>
            )}
          </div>

          <div
            onClick={enroll}
            className="bg-black bg-opacity-80 rounded-lg py-3 px-8 flex flex-row items-center space-x-2 justify-center text-white font-poppins text-base font-bold cursor-pointer"
          >
            <p className="">Apply</p>
            <ArrowRight size={16} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicPass;
