import React from "react";
import { ReactComponent as Emoji1 } from "../../assets/FocusArea/Emoji1.svg";
import { ReactComponent as Emoji2 } from "../../assets/FocusArea/Emoji2.svg";
import { ReactComponent as Emoji3 } from "../../assets/FocusArea/Emoji3.svg";
import { ReactComponent as Emoji4 } from "../../assets/FocusArea/Emoji4.svg";
import { ReactComponent as Emoji5 } from "../../assets/FocusArea/Emoji5.svg";
import { useSelector } from "react-redux";
import moment from "moment";
function calculateSessionDuration(startTime, endTime) {
  // Parse the start and end times as Date objects
  const start = new Date(startTime);
  const end = new Date(endTime);

  // Calculate the difference in milliseconds
  const difference = end - start;

  // Convert difference from milliseconds to hours and minutes
  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.floor((difference / (1000 * 60)) % 60);

  // Return the duration in the format "Xh Ym"
  return `${hours}h ${minutes}m`;
}
function RecentSessions({ sprint }) {
  const list = useSelector((state) => state?.journals?.journals)?.filter(
    (a) =>
      moment(sprint?.startDate).unix() < moment(a?.startTime).unix() &&
      moment(sprint?.endDate).unix() > moment(a?.endTime).unix()
  );
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-center justify-between">
        <p className="text-black font-lato text-sm font-bold leading-5 tracking-[0.7px]">
          Recent Sessions
        </p>
        <p className="text-primary-neutral-800 underline font-satoshi text-xs font-bold leading-5 tracking-[0.6px] cursor-pointer">
          View All
        </p>
      </div>
      {list?.length > 0 ? (
        <div className="flex flex-col bg-white rounded-lg">
          {list?.map((a) => {
            return (
              <div className="p-4 flex flex-row items-center space-x-4 justify-center">
                <img
                  // src={`/assets/FocusArea/Emoji${a?.metaData?.emoji + 1}.svg`}
                  src="/assets/images/session_emoji.png"
                  className="w-6 h-6"
                  alt=""
                />

                {a?.comments ? (
                  <p className="text-primary-neutral-800 font-satoshi text-xs font-bold leading-5 tracking-[0.6px] flex-[1_0_0]">
                    {a?.comments}
                  </p>
                ) : (
                  <p className="text-primary-neutral-400 italic font-satoshi text-xs font-bold leading-5 tracking-[0.6px] flex-[1_0_0]">
                    No Details
                  </p>
                )}
                <p className="text-primary-neutral-800 font-satoshi text-xs font-bold leading-5 tracking-[0.6px]">
                  {calculateSessionDuration(a?.startTime, a?.endTime)}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="rounded-[20px] bg-primary-neutral-100 p-8 space-y-4 flex flex-col items-center">
          <img
            src="/assets/images/session.png"
            alt=""
            className="w-20 h-20 mx-auto"
          />
          <p className="text-primary-neutral-800 font-satoshi text-center text-xs leading-4 tracking-[0.6px]">
            You have had{" "}
            <span className="text-[#F57D34] font-bold">0 sessions</span> in the
            past 2 days
          </p>
        </div>
      )}
    </div>
  );
}

export default RecentSessions;
