import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "redux/toaster";
import * as GoalAPI from "config/APIs/task/goal";
import { Plus, TShirt, X } from "@phosphor-icons/react";
import SimpleInput from "components/Common/Inputs/SimpleInput";
import { fetchAllgoals } from "redux/goal";
import SimpleTextArea from "components/Common/Inputs/SimpleTextArea";
import {
  TRACK_CATEGORY,
  TRACK_CATEGORY_ACADEMICS,
  TRACK_CATEGORY_CAARYA,
  TRACK_CATEGORY_PERSONAL,
  defaultTracks,
} from "helpers/constants/tracks";
import SearchBox from "components/Common/Inputs/SearchBox";
import { DateSelectOptions } from "components/Common/Inputs/Date";
import { Disclosure, Transition } from "@headlessui/react";
import { getGoalPriority } from "helpers/utils/goal";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { create } from "config/APIs/project";
import { fetchAllProjects } from "redux/projects";

const defaultValue = {
  title: "",
  date: null,
  status: null,
  important: false,
  urgent: false,
};

function GoalCreateEdit({
  closeModal,
  isOpen,
  onCreate,
  project,
  goal,
  track,
}) {
  const { user } = useSelector((state) => state.user);
  const projectsList = useSelector((state) => state.projects?.list);

  const dispatch = useDispatch();
  const [data, setData] = useState(defaultValue);
  const [showDescription, setShowDescription] = useState(false);
  const [addDetails, setAddDetails] = useState(false);
  const [openProjectList, setOpenProjectList] = useState(false);
  const [titleFocus, setTitleFocus] = useState(false);
  const [creating, setCreating] = useState(false);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
    searchProject: "",
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let obj = {};

      if (project) {
        obj = { ...obj, projectId: project?._id, project: project };
      }
      if (track) {
        obj = {
          ...obj,
          track: track,
          trackId: track?.id,
          trackCategory: track?.category,
        };
      }

      setData(obj);
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen, project, goal, track]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleCreate = async () => {
    if (!isButtonEnabled()) return;
    if (!data.dueDate) {
      data.dueDate = new Date();
    }

    setCreating(true);

    let obj = { ...data };

    const { owners, collaborators, ...newTask } = obj;

    if (!obj?.status) {
      newTask["status"] = "NotStarted";
    }

    if (obj?.track && getCollaborator(obj?.track)) {
      newTask["ownerId"] = getCollaborator(obj?.track)?.id;
    }

    newTask["priority"] = getGoalPriority(newTask?.important, newTask?.urgent);

    if (defaultTracks?.map((a) => a?.title)?.includes(data?.track?.title)) {
      newTask["ownerId"] = user?.id;
    }
    try {
      let body = {
        ...newTask,
      };

      await GoalAPI.createGoal(body);

      closeModal();
      if (onCreate) onCreate(obj);
      setData(defaultValue);
      dispatch(fetchAllgoals());

      showSuccessNotification("Goal created successfully!");
    } catch (err) {
      console.log("Error", err);
      console.log(err?.response);
      showErrorNotification(err.response?.data?.message);
    }
    setCreating(false);
  };

  const getCollaborator = (item) => {
    let user1 = item?.members?.filter((m) => m?.id !== user?.id);
    return user1?.length > 0 ? user1[0] : null;
  };

  const isButtonEnabled = () => data?.track && data?.title && data?.project;

  const createNewProject = async () => {
    if (!search.searchText) {
      showErrorNotification("Title cannot be empty!");
      return;
    }
    const { data: response } = await create({
      project: {
        name: search.searchText,
        type: data?.track?.toLowerCase(),
      },
    });
    setData({
      ...data,
      project: response.data,
      projectId: response.data?._id,
    });
    setOpenProjectList(false);
    dispatch(fetchAllProjects());
  };

  const closeAndReset = () => {
    setData(defaultValue);
    setAddDetails(false);
    setSearch({
      searchText: "",
      isSearch: false,
      searchProject: "",
    });
    closeModal();
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          maxHeight: "100vh",
          height: window.innerWidth < 1024 ? "auto" : "100%",
          width: window.innerWidth < 1024 ? "100%" : "560px",
        },
      }}
      open={isOpen}
      onClose={closeAndReset}
      transitionDuration={250}
    >
      <button
        aria-label="Close"
        type="button"
        onClick={closeAndReset}
        className="self-center mb-2 bg-primary-gray-1000 p-2 rounded-full"
      >
        <XMarkIcon className="h-6 w-6 text-white" />
      </button>
      <div
        className="md:max-w-xl relative max-lg:h-full max-h-[570px] xs:max-h-[640px] lg:h-screen pt-6 pb-[70px] rounded-t-[32px] lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150"
        style={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 12px -2px, rgba(0, 0, 0, 0.3) 0px 1px 7px -3px",
        }}
      >
        <div className="flex flex-row items-center justify-between px-4 pb-4 space-x-2 text-primary-neutral-800 font-satoshi text-base font-bold rounded-t-2xl border-b border-b-primary-neutral-200">
          <div className="flex flex-row items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.66667C5.39774 1.66667 1.66667 5.39774 1.66667 10C1.66667 14.6023 5.39774 18.3333 10 18.3333C14.6023 18.3333 18.3333 14.6023 18.3333 10C18.3333 5.39774 14.6023 1.66667 10 1.66667ZM0 10C0 4.47726 4.47726 0 10 0C15.5227 0 20 4.47726 20 10C20 15.5227 15.5227 20 10 20C4.47726 20 0 15.5227 0 10ZM4.16667 10C4.16667 6.78226 6.78226 4.16667 10 4.16667C13.2177 4.16667 15.8333 6.78226 15.8333 10C15.8333 13.2177 13.2177 15.8333 10 15.8333C6.78226 15.8333 4.16667 13.2177 4.16667 10ZM10 5.83333C7.70274 5.83333 5.83333 7.70274 5.83333 10C5.83333 12.2973 7.70274 14.1667 10 14.1667C12.2973 14.1667 14.1667 12.2973 14.1667 10C14.1667 7.70274 12.2973 5.83333 10 5.83333ZM8.33333 10C8.33333 9.07976 9.07976 8.33333 10 8.33333C10.9202 8.33333 11.6667 9.07976 11.6667 10C11.6667 10.9202 10.9202 11.6667 10 11.6667C9.07976 11.6667 8.33333 10.9202 8.33333 10Z"
                fill="#ED4C41"
              />
            </svg>
            <p className="">Add New Goal</p>
          </div>
        </div>

        <div className="pt-6 pb-14 px-2 flex w-full flex-col items-start gap-8 transition-all ease-in-out duration-150 h-full max-h-[460px] xs:max-h-[520px] overflow-y-scroll">
          <div className="flex flex-col space-y-3 px-2">
            <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]">
              Track
            </p>
            <div className="flex flex-row items-stretch space-x-3">
              {TRACK_CATEGORY?.map((item) => {
                const isSelected = data?.track === item?.value;
                return (
                  <div
                    onClick={() => {
                      setData({
                        ...data,
                        track: item?.value,
                        project: null,
                        projectId: null,
                      });
                    }}
                    style={{
                      borderColor: isSelected ? item?.color : "#E7E6E5",
                    }}
                    className={`py-1.5 px-3 flex flex-row items-center border space-x-2 rounded-[8px] cursor-pointer`}
                  >
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{ background: item?.color }}
                    />
                    <p
                      className={`${
                        isSelected
                          ? "text-primary-neutral-800"
                          : "text-primary-neutral-400"
                      } font-satoshi text-xs font-bold leading-5 tracking-[0.48px]`}
                    >
                      {item?.label}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {data?.track && (
            <>
              <div className="flex w-full flex-col items-start space-y-4 px-2 !font-satoshi">
                <SimpleInput
                  label="Goal Title"
                  labelClass="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]"
                  field="title"
                  details={data}
                  customInputClass="border-b border-b-primary-neutral-200"
                  placeholder="Goal title added here"
                  setDetails={setData}
                  onFocus={() => {
                    setTitleFocus(true);
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      setTitleFocus(false);
                    }, 100);
                  }}
                />{" "}
                <div
                  onClick={() => {
                    setShowDescription(!showDescription);
                  }}
                  className="flex cursor-pointer flex-row items-center space-x-2 text-primary-neutral-400 font-satoshi text-xs font-bold"
                >
                  <Plus size={12} />
                  <p className="underline underline-offset-1">
                    Add Description
                  </p>
                </div>
                {showDescription && (
                  <SimpleTextArea
                    labelClass="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px] block mb-1"
                    label="Goal Description"
                    field="description"
                    details={data}
                    setDetails={setData}
                  />
                )}
                {addDetails && (
                  <div className="flex flex-col space-y-2 w-full">
                    <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px] block mb-1">
                      Select Project
                    </p>

                    <Disclosure
                      as="div"
                      className=" w-full"
                      defaultOpen={openProjectList}
                    >
                      <Disclosure.Button className="w-full">
                        {openProjectList ? (
                          <div className="w-full">
                            <SearchBox
                              placeholder="Search project"
                              search={search}
                              setSearch={setSearch}
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              if (!data?.project) {
                                setOpenProjectList(true);
                              }
                            }}
                            className="px-3 w-full flex py-2 border-b border-primary-neutral-200"
                          >
                            {data?.project ? (
                              <div className="pl-4 pr-3 py-2 border rounded-full border-primary-neutral-200 flex flex-row items-center space-x-2 text-primary-gray-500 font-satoshi text-xs font-semibold leading-5">
                                {data?.project?.image?.url ? (
                                  <img
                                    src={data?.project?.image?.url}
                                    alt=""
                                    className="w-5 h-5 rounded object-cover"
                                  />
                                ) : (
                                  <div className="h-6 w-6 border border-primary-neutral-200 bg-primary-neutral-50 rounded-[4px]"></div>
                                )}
                                <p>{data?.project?.name}</p>
                                <X
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      project: null,
                                      projectId: null,
                                    });
                                  }}
                                  size={16}
                                />
                              </div>
                            ) : (
                              <p className="w-full text-left text-primary-gray-225 font-satoshi text-sm font-light">
                                Start typing to search for project
                              </p>
                            )}
                          </div>
                        )}
                      </Disclosure.Button>

                      <Transition
                        show={!data?.projectId && openProjectList}
                        className="w-full"
                        enter="transition duration-150 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-150 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel>
                          {({ close }) => (
                            <div className="w-full bg-white space-y-3">
                              <div className=" w-full flex flex-col items-start  overflow-y-auto">
                                {projectsList
                                  ?.filter((item) => {
                                    switch (data?.track) {
                                      case TRACK_CATEGORY_CAARYA:
                                        return item?.type === "caarya";
                                      case TRACK_CATEGORY_ACADEMICS:
                                        return item?.type === "academics";
                                      case TRACK_CATEGORY_PERSONAL:
                                        return item?.type === "personal";
                                      default:
                                        return false;
                                    }
                                  })
                                  ?.filter((item) => {
                                    if (!search.searchText.length) return item;
                                    if (
                                      item?.name
                                        ?.toLowerCase()
                                        ?.includes(
                                          search.searchText.toLowerCase()
                                        )
                                    )
                                      return item;
                                  })
                                  ?.map((item) => {
                                    return (
                                      <div
                                        key={item.id}
                                        onClick={() => {
                                          close();
                                          setData({
                                            ...data,
                                            project: item,
                                            projectId: item?._id,
                                          });
                                          setOpenProjectList(false);
                                        }}
                                        className="w-full border-b border-primary-gray-200 px-2 py-3 flex flex-row items-center justify-between space-x-4"
                                      >
                                        <div className="flex flex-row items-center gap-x-4 cursor-pointer">
                                          <div className="h-6 w-6 border border-primary-neutral-200 bg-primary-neutral-50 rounded-[4px]"></div>
                                          <p className="text-primary-gray-800 font-satoshi text-sm font-medium">
                                            {item?.name}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </Disclosure.Panel>
                      </Transition>
                    </Disclosure>
                  </div>
                )}
                {data?.track !== TRACK_CATEGORY_CAARYA &&
                  addDetails &&
                  !data?.projectId && (
                    <div
                      onClick={createNewProject}
                      className="flex cursor-pointer flex-row items-center py-2 space-x-2 text-primary-neutral-400 font-satoshi text-xs font-bold"
                    >
                      <Plus size={12} />
                      <p className="underline underline-offset-1">
                        New Project
                      </p>
                    </div>
                  )}
              </div>
            </>
          )}

          {data?.project && data?.title !== "" && data?.track && (
            <div className="flex w-full flex-col items-start gap-4 px-2">
              <div className="w-full flex flex-col">
                <div className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]">
                  Due Date
                </div>
                <DateSelectOptions
                  date={data?.dueDate}
                  setDate={(val) => {
                    setData({ ...data, dueDate: val });
                  }}
                  canChange
                />
              </div>

              <div className="w-full flex flex-col gap-3">
                <div className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]">
                  How much effort might this require?
                </div>
                <div className="py-2 flex flex-row items-stretch space-x-2">
                  {[
                    { label: "1h", value: 1, color: "#4CA6E5" },
                    { label: "2h", value: 2, color: "#33A329" },
                    { label: "4h", value: 4, color: "#FFBC00" },
                    { label: "8h", value: 8, color: "#FF8800" },
                    { label: "16h", value: 16, color: "#FB6F60" },
                  ]?.map((p) => {
                    const isSelected = data?.metaData?.hours === p?.value;
                    return (
                      <div
                        key={p.value}
                        onClick={() => {
                          setData({
                            ...data,
                            metaData: { ...data?.metaData, hours: p?.value },
                          });
                        }}
                        className={`p-1 cursor-pointer`}
                      >
                        <div
                          style={{
                            color: p?.color,
                            borderColor: isSelected ? p?.color : "#E7E6E5",
                          }}
                          className={`py-1.5 px-2 flex flex-row items-center space-x-1.5 rounded-[8px] border `}
                        >
                          <TShirt size={14} />
                          <p className="text-primary-gray-800 font-satoshi text-xs font-semibold">
                            {p?.label}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="absolute bottom-0 right-0 left-0">
          <div
            className="grid grid-cols-2 bg-[#fafafa]"
            style={{
              boxShadow: "0px -4px 6px -4px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="py-6 flex flex-row items-center justify-center space-x-2">
              {addDetails ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M16.9167 9.82552H6.775L11.4333 5.16719L10.25 3.99219L3.58334 10.6589L10.25 17.3255L11.425 16.1505L6.775 11.4922H16.9167V9.82552Z"
                      fill="#363430"
                    />
                  </svg>
                  <p className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-5 tracking-[0.28px]">
                    Back
                  </p>
                </>
              ) : (
                <>
                  <p className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-5 tracking-[0.28px]">
                    Quick Add
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M9.4165 9.46094V4.46094H11.0832V9.46094H16.0832V11.1276H11.0832V16.1276H9.4165V11.1276H4.4165V9.46094H9.4165Z"
                      fill="#363430"
                    />
                  </svg>
                </>
              )}
            </div>

            <div
              onClick={() =>
                addDetails ? handleCreate() : setAddDetails(true)
              }
              className={`py-6 flex flex-row items-center justify-center space-x-2 border-l border-l-primary-neutral-200 ${
                !addDetails
                  ? "bg-primary-neutral-50"
                  : isButtonEnabled()
                  ? "bg-primary-red-500"
                  : "bg-primary-neutral-500"
              }`}
            >
              {addDetails ? (
                <>
                  <p className="text-white font-satoshi text-sm font-bold leading-5 tracking-[0.28px">
                    Add Now
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M9.46945 9.82031V4.82031H11.1361V9.82031H16.1361V11.487H11.1361V16.487H9.46945V11.487H4.46945V9.82031H9.46945Z"
                      fill="white"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <p
                    className={`text-[#ED4C41] font-satoshi text-sm font-bold leading-5 tracking-[0.28px`}
                  >
                    Add Details{" "}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.6241 10.3002L7.21338 5.88945L8.39189 4.71094L13.9811 10.3002L8.39189 15.8894L7.21338 14.7109L11.6241 10.3002Z"
                      fill={"#ED4C41"}
                    />
                  </svg>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default GoalCreateEdit;
