import { lifeInstance } from "config";
import { hqInstance, getHeader } from "config";

export const findOneJournal = (id) =>
  hqInstance.get(`/journals/${id}`, getHeader());

export const findAllJournals = (query = {}) =>
  hqInstance.get(`/journals?${new URLSearchParams(query)}`, getHeader());

export const createAJournal = (body) =>
  hqInstance.post("/journals", body, getHeader());

export const updateAJournal = (id, body) =>
  hqInstance.patch(`/journals/${id}`, body, getHeader());

export const destroyAJournal = (id) =>
  hqInstance.delete(`/journals/${id}`, getHeader());

export const findMyJournals = (query = {}) =>
  lifeInstance.get(`/sessions/my`, getHeader());

export const getOnGoingJournal = (query = {}) =>
  lifeInstance.get(`/sessions/ongoing`, getHeader());

export const getManualCheckoutJournals = () =>
  lifeInstance.get("/sessions/manual", getHeader());
