import React, { useState } from "react";
import InfoCards from "./InfoCards";
import LifeCards from "./LifeCards";

function Explore({ locked }) {
  const [selected, setSelected] = useState("Info Cards");
  return locked ? (
    <div className="py-12 px-6 flex flex-col items-center justify-center space-y-14">
      <div className="flex flex-col items-center space-y-2">
        <div className="w-6 h-6 rounded-full flex flex-row items-center justify-center border border-[#FF9C5D] text-[#FF9C5D]">
          !
        </div>
        <p className="text-primary-neutral-500 font-satoshi text-2xs font-bold leading-4 tracking-[0.4px] capitalize">
          Knowledge exploring is currently locked
        </p>
      </div>
      <div className="flex flex-col items-center space-y-6">
        <div className="w-[160px] h-[160px] rounded-2xl bg-primary-neutral-100" />
        <p className="text-primary-neutral-800 font-satoshi text-center text-sm leading-5 tracking-[0.56px]">
          Complete your first assessment to start exploring knowledge
        </p>
      </div>
    </div>
  ) : (
    <div className="bg-[#fafafa] min-h-screen">
      <div
        className="bg-white border-b-2 border-primary-neutral-200  px-4 overflow-x-auto flex flex-row space-x-3"
        id="roles"
      >
        {[
          { name: "Info Cards" },
          { name: "Life Cards" },
          { name: "Knowledge Cards", locked: true },
          { name: "Resource Cards", locked: true },
        ]?.map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelected(item?.name);
              }}
              className={`text-xs min-w-max font-bold flex flex-col items-center font-lato py-3 px-6 space-y-2 ${
                selected == item?.name
                  ? "text-primary-neutral-800  border-b-2 border-primary-neutral-800"
                  : "text-primary-neutral-400"
              }`}
            >
              <div className="w-6 h-6 object-cover rounded bg-primary-neutral-100 flex flex-row items-center justify-center">
                {item?.locked && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                  >
                    <path
                      d="M6.5 1C5.1215 1 4 2.1215 4 3.5V4.5H3.5C2.9485 4.5 2.5 4.9485 2.5 5.5V10C2.5 10.5515 2.9485 11 3.5 11H9.5C10.0515 11 10.5 10.5515 10.5 10V5.5C10.5 4.9485 10.0515 4.5 9.5 4.5H9V3.5C9 2.1215 7.8785 1 6.5 1ZM5 3.5C5 2.673 5.673 2 6.5 2C7.327 2 8 2.673 8 3.5V4.5H5V3.5ZM9.501 10H7V8.861C7.2975 8.6875 7.5 8.3685 7.5 8C7.5 7.4485 7.0515 7 6.5 7C5.9485 7 5.5 7.4485 5.5 8C5.5 8.368 5.7025 8.6875 6 8.861V10H3.5V5.5H9.5L9.501 10Z"
                      fill="#9C9A96"
                    />
                  </svg>
                )}
              </div>
              <p>{item?.name}</p>
            </div>
          );
        })}
      </div>
      {selected == "Info Cards" && <InfoCards />}
      {selected == "Life Cards" && <LifeCards />}
      {(selected == "Knowledge Cards" || selected == "Resource Cards") && (
        <div className="flex flex-col items-center pt-20 space-y-6">
          <div className="w-[160px] h-[160px] rounded-2xl bg-primary-neutral-200" />
          <p className="text-primary-neutral-800 font-satoshi text-center text-sm leading-5 tracking-[0.56px]">
            Coming Soon!{" "}
          </p>
        </div>
      )}
    </div>
  );
}

export default Explore;
