import { instance } from "config";
import { getHeader, lifeInstance, hqInstance } from "config";

export const getUser = (id) => {
  return lifeInstance.get(`/users/${id}`, getHeader());
};

export const getMyProfile = () => {
  return lifeInstance.get(`/profile`, getHeader());
};

export const getAllUsers = (query = {}) => {
  return hqInstance.get(`/users?` + new URLSearchParams(query), getHeader());
};

export const getTimesheetData = () => {
  return hqInstance.get("/users/timesheet", getHeader());
};

// NEW BACKEND

export const updateMyProfile = (id, body) => {
  return lifeInstance.patch(`/users/${id}`, body, getHeader());
};
export const updateUserOnboard = (body) => {
  return lifeInstance.patch(`/users/onboard`, body, getHeader());
};

export const createPhaseOneProfile = async (id, reqData) => {
  return await lifeInstance.patch(`/users/me/${id}`, reqData, getHeader());
};

export const updateAchievement = async (reqData) => {
  return await lifeInstance.patch(`/users/achievement`, reqData, getHeader());
};

export const updateUserService = (body) => {
  return lifeInstance.patch(`/users/service`, body, getHeader());
};

export const getUserServices = async () => {
  return await lifeInstance.get(`/users/service`, getHeader());
};

export const getUserSprints = async () => {
  return await lifeInstance.get(`/users/sprint`, getHeader());
};

export const createUserSprints = (body) => {
  return lifeInstance.post(`/users/sprint`, body, getHeader());
};
