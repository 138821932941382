import { getHeader, lifeInstance } from "config";

export const getAllEvents = (query) => {
  return lifeInstance.get(
    `/events${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};

export const getUserEvents = (type, query) => {
  return lifeInstance.get(
    `/events/${type}${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};
