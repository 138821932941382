import ProgressBar from "./ProgressBar";

const QuestionNavigation = () => {
  return (
    <>
      <div
        className={`flex justify-between p-4 md:px-16 font-inter flex-col md:flex-row`}
      >
        <div className="md:shadow-md bg-white rounded-full p-1 px-3 inline">
          <img
            src="/assets/images/logo/caaryaMe.svg"
            alt=""
            className="h-8 md:h-6 object-contain mx-auto md:mx-0"
          />
        </div>
        <div className="h-[1px] md:hidden w-full bg-primary-grey-200 my-5"></div>
        <div className="flex sm:flex md:block xl:flex justify-between  gap-6 items-center bottom-full px-2 sm:px-8 md:px-0 font-satoshi text-sm">
          <p className="text-primary-grey-400 text-xs ">Saved 2 minutes Ago</p>
          <button className="text-primary-blue-500 font-bold ">
            Save & Exit
          </button>
        </div>
      </div>
      <div className="py-2 px-4 md:px-16 font-inter">
        <ProgressBar />
      </div>
    </>
  );
};

export default QuestionNavigation;
