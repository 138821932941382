import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

function Logout({ isOpen, closeModal, onLogout }) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition-all ease-in-out duration-300 sm:duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="pointer-events-auto w-screen">
                  <div className="fixed bg-black/25 top-0 left-0 w-full h-screen flex justify-center items-center z-[100]">
                    <div className="flex flex-col bg-white rounded-3xl overflow-hidden w-[320px]">
                      <div className="flex p-6 flex-col gap-2 items-center justify-center">
                        <p className="text-center font-lato text-xl text-primary-neutral-800 font-bold ">
                          Logout
                        </p>
                        <p className="text-center font-lato text-sm text-primary-neutral-800 font-normal ">
                          Are you sure you want to Log Out?
                        </p>
                      </div>
                      <div className="flex w-full pt-[1px] justify-center items-end gap-[1px] bg-primary-neutral-100">
                        <button
                          onClick={closeModal}
                          className="flex w-full p-5 text-center justify-center items-center bg-white font-satoshi text-black text-sm font-bold"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={onLogout}
                          className="flex w-full p-5 text-center justify-center items-center bg-white font-satoshi text-caaryaRed text-sm font-bold"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Logout;
