import React from "react";

const sizeClasses = {
  txtPoppinsLight14: "font-light font-poppins",
  txtSatoshiRegular14: "font-normal font-satoshi",
  txtSatoshiMedium14Bluegray900: "font-medium font-satoshi",
  txtSatoshiBold14: "font-bold font-satoshi",
  txtSatoshiRegular16: "font-normal font-satoshi",
  txtSatoshiBold14Gray500: "font-bold font-satoshi",
  txtSatoshiRegular14Gray500: "font-normal font-satoshi",
  txtSatoshiBold16WhiteA700: "font-bold font-satoshi",
  txtSatoshiBold16: "font-bold font-satoshi",
  txtSatoshiMedium14: "font-medium font-satoshi",
  txtManropeSemiBold16: "font-manrope font-semibold",
  txtManropeSemiBold18: "font-manrope font-semibold",
  txtManropeLight14: "font-light font-manrope",
  txtSatoshiBold14Gray700: "font-bold font-satoshi",
  txtSatoshiBold40: "font-bold font-satoshi",
  txtSatoshiBold16Gray900: "font-bold font-satoshi",
  txtSatoshiMedium14Gray700: "font-medium font-satoshi",
  txtSatoshiBold14Red500: "font-bold font-satoshi",
  txtSatoshiMedium14Gray500: "font-medium font-satoshi",
  txtManropeSemiBold20: "font-manrope font-semibold",
  txtSatoshiMedium40: "font-medium font-satoshi",
  txtPoppinsSemiBold20: "font-poppins font-semibold",
  txtManropeSemiBold24: "font-manrope font-semibold",
  txtSatoshiBold32: "font-bold font-satoshi",
  txtSatoshiBold16IndigoA200: "font-bold font-satoshi",
  txtSatoshiLight12: "font-light font-satoshi",
  txtKaushanScriptRegular32: "font-kaushanscript font-normal",
  txtSatoshiLight14: "font-light font-satoshi",
  txtSatoshiBold14Bluegray900: "font-bold font-satoshi",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };