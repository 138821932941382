import { MdArrowBack, MdExpandMore } from "react-icons/md";
import { useState, useEffect } from "react";
import { CgCalendar } from "react-icons/cg";
import "react-circular-progressbar/dist/styles.css";
import * as JournalAPI from "../../config/APIs/journals";
import SessionCards from "./SessionCards";
import SprintDrawer from "./SprintDrawer";
import { useSelector } from "react-redux";
import { getTotalTime } from "helpers/utils/common/clock";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const SprintInsights = () => {
  const history = useHistory();
  const initialStreakDays = [
    { day: "S", status: "upcoming" },
    { day: "M", status: "upcoming" },
    { day: "T", status: "upcoming" },
    { day: "W", status: "upcoming" },
    { day: "T", status: "upcoming" },
    { day: "F", status: "upcoming" },
    { day: "S", status: "upcoming" },
  ];
  const sprints = useSelector((state) => state.goals?.sprints);

  const [streakDays, setStreakDays] = useState(initialStreakDays);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState("");
  const [selectedSprintLabel, setSelectedSprintLabel] = useState("");
  const [progress, setProgress] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allJournals, setAllJournals] = useState([]);
  const [workedGoalCount, setWorkedGoalCount] = useState([]);
  // Sprint start and end dates should be in Date objects for comparison
  const [time, setTime] = useState(0);
  // Assuming the week starts on Sunday (0)
  const todayIndex = new Date().getDay();

  const getCurrWeek = () => {
    var currentDate = moment();

    var weekStart = currentDate.clone().startOf("week");

    var days = [];

    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days"));
    }

    return days;
  };

  useEffect(() => {
    let temp = getCurrWeek()?.map((day, index) => {
      let obj = { date: day, day: moment(day).format("dddd").substring(0, 1) };
      if (index < todayIndex) {
        // Past days default to 'missed' (for example, this logic might be different in your app)
        obj = { ...obj, status: "missed" };
      } else if (index === todayIndex) {
        // Mark the current day as 'active'
        obj = { ...obj, status: "active" };
      }
      if (
        allJournals?.find(
          (a) =>
            moment(a?.session?.clockIn).format("L") == moment(day).format("L")
        )
      ) {
        obj = { ...obj, hasSession: true };
      }
      return obj;
    });
    setStreakDays(temp);
  }, [todayIndex, allJournals]);

  useEffect(() => {
    fetchAllMyJournals();
    window.scrollTo({ top: 0 });
  }, []);

  const fetchAllMyJournals = async () => {
    try {
      const response = await JournalAPI.findMyJournals(); // Add `await` here
      console.log(response.status);
      if (response.status === 200) {
        let data = response.data;
        console.log(data);
        setAllJournals(data.data);
        let sess = allJournals?.map((a) => a?.session);
        let g = [];
        let timeSecs = 0;
        console.log(sess);
        sess?.map((item) => {
          if (
            moment(item?.session?.clockIn).unix() >
              moment().startOf("week")?.unix() &&
            moment(item?.session?.clockIn).unix() <
              moment().endOf("week")?.unix()
          ) {
            if (item?.clockOut) {
              let timeDiff = moment
                .duration(moment(item?.clockOut).diff(moment(item?.clockIn)))
                .asSeconds();

              timeSecs = timeSecs + timeDiff;
            }
          }
        });
        setProgress((timeSecs / (40 * 60 * 60)) * 100);
        let t = getTotalTime(sess);
        console.log("time", t);
        setTime(t);

        data?.data?.response?.map((a) => {
          if (
            moment(a?.session?.clockIn).unix() >
              moment().startOf("week")?.unix() &&
            moment(a?.session?.clockIn).unix() < moment().endOf("week")?.unix()
          ) {
            if (g?.includes(a?.goalId)) {
            } else {
              g?.push(a?.goalId);
            }
          }
        });
        console.log("goalss", g);
        setWorkedGoalCount(g?.length);
      }
    } catch (error) {
      console.error("An error occurred while fetching journals:", error);
    }
  };

  const getJournals = () => {
    let temp = [];
    temp = allJournals?.filter(
      (item) =>
        moment(item?.session?.clockIn).format("L") ===
        moment(currentDate).format("L")
    );
    return temp?.reverse();
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectSprint = (sprintId) => {
    const sprint = sprints.find((s) => s._id === sprintId);
    setSelectedSprint(sprint._id);
    setSelectedSprintLabel(sprint.name);
    setIsDropdownOpen(false);
    // if (sprint) {
    //   setSelectedSprint(sprint.label);
    //   setIsDropdownOpen(false);

    //   const dates = sprint.label
    //     .split(" ")[2]
    //     .split(" - ")
    //     .map((dateStr) => new Date(dateStr));
    //   setSprintStart(dates[0]);
    //   setSprintEnd(dates[1]);

    //   setCurrentDate(dates[1]); // Optional: Set current date to the end of the selected sprint
    // }
  };

  function calculateSessionDuration(startTime, endTime) {
    // Parse the start and end times as Date objects
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the difference in milliseconds
    const difference = end - start;

    // Convert difference from milliseconds to hours and minutes
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference / (1000 * 60)) % 60);

    // Return the duration in the format "Xh Ym"
    return `${hours}h ${minutes}m`;
  }

  return (
    <div
      className={`bg-[#fafafa] pt-[52.5px] lg:px-6 lg:pt-0 h-[95vh] overflow-y-auto`}
    >
      <div className="flex px-3 py-[20px] flex-row justify-between w-full    bg-[#ffffff] md:bg-gradient-to-b md:from-[#FaFaFa] md:to-[#FaFaFa]">
        <div
          onClick={() => history.goBack()}
          className="text-primary-neutral-800 flex flex-row items-center space-x-4 font-bold font-satoshi text-base tracking-[0.64px] leading-6"
        >
          <MdArrowBack />
          <p>Sprint Insights</p>
        </div>
        <div>
          <button
            onClick={handleDropdownToggle}
            className="bg-[#ffffff] min-w-[84px] justify-between flex flex-row items-center space-x-2 cursor-pointer text-[#ED4C41] font-bold text-xs font-lato"
          >
            <p>{selectedSprint || "Current Sprint"}</p>
            <MdExpandMore className="text-[#ED4C41]" />
          </button>
          {isDropdownOpen && (
            <div className="absolute  min-w-[84px] bg-white border border-[#9C9A96] z-10">
              {sprints?.map((sprint) => (
                <div
                  key={sprint.id}
                  className="px-4 flex flex-row justify-center items-center py-2 hover:bg-gray-100 min-w-[84px] cursor-pointer"
                  onClick={() => handleSelectSprint(sprint._id)}
                >
                  <p className="text-[#363430] font-normal text-xs font-lato">
                    {sprint.name}
                  </p>
                  {sprint._id === selectedSprint && (
                    <p className="text-[#696763] ml-[2px] font-lato font-light text-xs">
                      (current)
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="bg-[#f3f2f2] h-2" />
      <div className="flex flex-col space-y-[48px] pt-4 px-4 pb-20 bg-[#FAFAFA]">
        <div className="flex flex-row justify-between">
          <div>
            <p className="text-[#ED4C41] text-xl font-lato font-bold">
              Sprint 3
            </p>
          </div>

          <div className="justify-center space-x-2 items-center flex">
            <CgCalendar className="text-[#696763]" />
            <p className="font-lato font-normal text-[10px] text-[#696763]">
              {selectedSprintLabel?.split(" ")[2] +
                selectedSprintLabel?.split(" ")[3] +
                selectedSprintLabel?.split(" ")[4] || "18/03/24 - 27/03/24"}
            </p>
          </div>
        </div>

        <div className="space-y-6 md:p-4 md:gap-6 md:flex-row md:flex-wrap md:flex md:space-y-0 py-2">
          <div className="w-full flex justify-center">
            <div className="flex rounded-lg bg-[#f3f2f2] px-4 py-2">
              <p className="font-lato font-normal text-xs text-[#363430]">
                {moment().format("LL")}
              </p>
            </div>
          </div>
          {console.log(getJournals())}
          <div className="flex flex-col">
            {getJournals()?.length > 0 ? (
              getJournals()?.map((journal, index) => {
                return (
                  <div className="flex flex-row space-x-3">
                    <div className="flex flex-col items-center">
                      <div className="w-6 h-6 border-[#E7E6E5] border-2 rounded-full p-2 flex justify-center items-center">
                        <p className="font-lato font-bold text-[10px] text-[#696763]">
                          {index + 1}
                        </p>
                      </div>
                      <div className="border-[#E7E6E5] border-2 flex-grow" />
                    </div>
                    <SessionCards
                      key={journal?.id}
                      onCardClick={openDrawer}
                      startTime={journal?.startTime}
                      endTime={journal?.endTime}
                      goalTitle={journal?.contract?.title}
                      duration={calculateSessionDuration(
                        journal?.startTime,
                        journal?.endTime
                      )}
                      mood={journal?.metaData}
                      comments={journal?.comments}
                    />
                  </div>
                );
              })
            ) : (
              <>
                <div className="flex flex-col items-center space-y-2">
                  <img
                    src="/assets/svg/inSession.png"
                    alt=""
                    className="w-[120px] h-[120px]"
                  />
                  <p className="font-primary-neutral-500 font-lato text-center text-base leading-6">
                    No sessions found!
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <SprintDrawer isOpen={isDrawerOpen} closeModal={closeDrawer} />
    </div>
  );
};
export default SprintInsights;
