import Object from "components/Evalucon/Object";
import { evaluconList } from "helpers/constants/evalucon";
import React from "react";

import ReactFullpage from "@fullpage/react-fullpage";
import Carousel from "components/Evalucon/Carousel";

function Evalucon() {
  return (
    <div className="bg-white">
      <div className="w-full fixed top-0 right-0 left-0 evalucon-nav z-20">
        <div className="w-full max-w-7xl mx-auto p-4 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            <img
              src="/assets/caaryaLogos/caaryaLife.svg"
              alt=""
              className="w-8 h-8"
            />
            <h1 className="text-white font-poppins text-xl font-semibold">
              caarya
            </h1>
            <p className="text-white text-sm font-poppins font-light mt-0.5">
              LIFE
            </p>
          </div>
        </div>
      </div>
      <ReactFullpage
        afterLoad={(origin, destination, direction, trigger) => {
          var leavingSection = this;

          console.log(destination);

          if (origin.index == 1 && direction == "up") {
            let ele = document.getElementById("changeColor");
            if (ele) {
              // ele.style.animation = "background 2s eas-in-out";
              ele.style.backgroundColor =
                "radial-gradient(106.28% 81.96% at -1.01% 98.89%, #AD231F 0%, #5E4FBA 100%)";
            }
          } else {
            document.getElementById(`evalucon-1`)?.classList?.remove("active");
            document.getElementById(`evalucon-2`)?.classList?.remove("active");
            document.getElementById(`evalucon-3`)?.classList?.remove("active");
            document.getElementById(`evalucon-4`)?.classList?.remove("active");
            document.getElementById(`evalucon-5`)?.classList?.remove("active");
            document.getElementById(`evalucon-6`)?.classList?.remove("active");
            document
              .getElementById(`evalucon-1`)
              ?.classList?.remove("sticky-thc");
            document
              .getElementById(`evalucon-2`)
              ?.classList?.remove("sticky-thc");
            document
              .getElementById(`evalucon-3`)
              ?.classList?.remove("sticky-thc");
            document
              .getElementById(`evalucon-4`)
              ?.classList?.remove("sticky-thc");
            document
              .getElementById(`evalucon-5`)
              ?.classList?.remove("sticky-thc");
            document
              .getElementById(`evalucon-6`)
              ?.classList?.remove("sticky-thc");
            let ele = document.getElementById(`evalucon-${destination.index}`);
            if (ele) {
              ele.classList.add("active");
              if (direction == "down") {
                ele.classList.add("sticky-thc");
              }
            }
          }
        }}
        licenseKey={"416E7CEC-568043E0-BEC39CBC-192C1963"}
        scrollingSpeed={1000} /* Options here */
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div
                id="changeColor"
                // style={{
                //   background:
                //     "radial-gradient(106.28% 81.96% at -1.01% 98.89%, #AD231F 0%, #5E4FBA 100%)",
                // }}
                className="section relative bg-black py-16 w-full px-6 h-screen flex flex-col items-center justify-center "
              >
                <h1 className="evalucon-text  text-white max-w-2xl mx-auto w-full text-center font-poppins text-[32px] lg:text-[64px] font-medium leading-[64px] tracking-[14px] lg:tracking-[26px]">
                  EVALUCON
                </h1>
                <p className="text-white max-w-2xl mx-auto w-full text-center text-base lg:text-lg font-inter font-semibold mt-6 lg:mt-7 leading-7 tracking-[0.36px]">
                  Experiential quests for you to take you through a journey that
                  helps us understand what values and competenciesyou align with
                </p>
                <div className="absolute top-11 left-0 bottom-0 hidden lg:block">
                  <img
                    src="/assets/images/evalucon/hero.png"
                    alt=""
                    className="object-cover h-full"
                  />
                </div>
                <img
                  src="/assets/images/evalucon/evaluconBlur.svg"
                  alt=""
                  className="absolute top-0 right-0 bottom-0 h-screen w-screen object-cover block"
                />
                <img
                  src="/assets/images/evalucon/sideBlur.svg"
                  alt=""
                  className="absolute left-0 bottom-0 object-cover block"
                />
              </div>
              <div
                // id={`evalucon-${idx}`}
                className="sticky-thc section min-h-screen px-4 lg:px-20 pt-20 pb-10 pb-2 lg:pb-20 flex flex-col lg:justify-center"
              >
                <Carousel />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
}

export default Evalucon;
