import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useMemo, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdEdit, MdOutlineFileDownload } from "react-icons/md";
import CoverTab from "./achievementTabComponents/CoverTab";
import ExecutionObstacleTab from "./achievementTabComponents/ExecutionObstacleTab";
import PlanningResearchTab from "./achievementTabComponents/PlanningResearchTab";
import ReflectionTakeawaysTab from "./achievementTabComponents/ReflectionTakeawaysTab";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { updateDetails } from "redux/profile-form";
import coverIcon from "./achievementTabComponents/assets/cover.svg";
import executionIcon from "./achievementTabComponents/assets/execution.svg";
import planningIcon from "./achievementTabComponents/assets/planning.svg";
import reflectionIcon from "./achievementTabComponents/assets/reflection.svg";
import {
  ArrowLeftIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";

export const tabs = [
  {
    title: "Cover",
    value: 0,
    icon: coverIcon,
  },
  {
    title: "Planning & Research",
    value: 1,
    icon: planningIcon,
  },
  {
    title: "Execution & Obstacles",
    value: 2,
    icon: executionIcon,
  },
  {
    title: "Reflection & Takeaways",
    value: 3,
    icon: reflectionIcon,
  },
];

export const tabComponents = (tab, input, setInput, type) => {
  switch (tab) {
    case 0:
      return <CoverTab input={input} setInput={setInput} type={type} />;
    case 1:
      return (
        <PlanningResearchTab input={input} setInput={setInput} type={type} />
      );
    case 2:
      return (
        <ExecutionObstacleTab input={input} setInput={setInput} type={type} />
      );
    case 3:
      return (
        <ReflectionTakeawaysTab input={input} setInput={setInput} type={type} />
      );
    default:
      return <CoverTab input={input} setInput={setInput} type={type} />;
  }
};

const WebAchievementEditForm = ({
  goBack,
  field,
  type = "ADD",
  id,
  achievementDataModalProps,
  setAchievementDataModalProps,
}) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  const achievements = details[field];

  const [showTab, setShowTab] = useState(tabs[0].value);
  const doesAchievementExist =
    achievements.draft.find((_achieve) => _achieve.id === id) ||
    achievements.added.find((_achieve) => _achieve.id === id);

  const existedAchievement = useMemo(() => {
    if (doesAchievementExist) {
      const existAsDraft = achievements.draft.find(
        (_achieve) => _achieve.id === id
      );
      const existAsAdded = achievements.added.find(
        (_achieve) => _achieve.id === id
      );
      return existAsDraft ? existAsDraft : existAsAdded;
    } else return null;
  }, [achievements]);

  const [achievementInput, setAchievementInput] = useState(
    existedAchievement !== null
      ? existedAchievement
      : {
          title: "",
          field: "",
          background: "",
          approach: "",
          research: "",
          executionProcess: "",
          appliedKnowledge: "",
          obstacles: "",
          learnings: "",
          growthOpportunities: "",
        }
  );

  const handleNavigationLeftClick = () => {
    if (showTab === 0) {
      setShowTab(tabs[tabs.length - 1].value);
    } else {
      setShowTab(showTab - 1);
    }
  };
  const handleNavigationRightClick = () => {
    if (showTab === tabs.length - 1) {
      setShowTab(tabs[0].value);
    } else {
      setShowTab(showTab + 1);
    }
  };

  const handleAddAchievement = () => {
    let updatedAchievements;
    if (type === "EDIT") {
      if (achievements.draft.find((_achieve) => _achieve.id === id)) {
        updatedAchievements = {
          draft: achievements.draft.filter((_achieve) => _achieve.id !== id),
          added: [...achievements.added, { ...achievementInput }],
        };
      } else {
        updatedAchievements = {
          draft: achievements.draft,
          added: achievements.added.map((_achieve) => {
            if (_achieve.id === id) {
              return {
                id,
                ...achievementInput,
              };
            }
            return { ..._achieve };
          }),
        };
      }
    } else {
      updatedAchievements = {
        ...achievements,
        added: [...achievements.added, { ...achievementInput, id: uuid() }],
      };
    }

    dispatch(updateDetails({ field, value: updatedAchievements }));
    goBack();
  };

  const handleAddToDraft = () => {
    let updatedAchievements;
    if (type === "EDIT") {
      if (achievements.added.find((_achieve) => _achieve.id === id)) {
        updatedAchievements = {
          added: achievements.draft.filter((_achieve) => _achieve.id !== id),
          draft: [...achievements.draft, { ...achievementInput, id }],
        };
      } else {
        updatedAchievements = {
          added: achievements.added,
          draft: achievements.draft.map((_achieve) => {
            if (_achieve.id === id) {
              return {
                id,
                ...achievementInput,
              };
            }
            return { ..._achieve };
          }),
        };
      }
    } else {
      updatedAchievements = {
        ...achievements,
        draft: [...achievements.draft, { ...achievementInput, id: uuid() }],
      };
    }
    dispatch(updateDetails({ field, value: updatedAchievements }));
    goBack();
  };

  const handleBackClick = () => {
    if (type === "EDIT" || type === "ADD") {
      if (achievements.added.find((_achieve) => _achieve.id === id)) {
        goBack();
      } else handleAddToDraft();
    } else {
      goBack();
    }
  };

  const handleEditAchievement = () => {
    setAchievementDataModalProps({
      ...achievementDataModalProps,
      open: true,
      type: "EDIT",
      editID: id,
    });
  };

  return (
    <div className="flex  flex-col gap-4 items-center w-full max-w-8xl mx-auto h-screen p-6">
      <div className="flex justify-between items-center gap-10 w-full ">
        <ProfileButton
          onClick={handleBackClick}
          text={"Back"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
        />
        {type !== "VIEW" ? (
          <div className="flex items-center gap-14">
            <button
              className="text-md flex items-center gap-2 font-satoshi text-primary-blue-500 font-bold text-center"
              onClick={handleAddToDraft}
            >
              <MdOutlineFileDownload className="text-2xl" />
              Save As Draft
            </button>
            <ProfileButton
              onClick={handleAddAchievement}
              text={type === "EDIT" ? "Save" : "Add Now"}
              size={"large"}
              variant={"filled"}
              icon={
                type === "EDIT" ? (
                  <CheckIcon className="w-6" />
                ) : (
                  <PlusCircleIcon className="w-6" />
                )
              }
              iconAlign={"left"}
            />
          </div>
        ) : (
          <ProfileButton
            onClick={handleEditAchievement}
            text={"Edit"}
            size={"large"}
            variant={"filled"}
            icon={<MdEdit />}
            iconAlign={"left"}
          />
        )}
      </div>
      <div className="px-28 w-full h-full flex-1 ">
        <div className="w-full h-full shadow-lg bg-white  border p-10 px-20 rounded-xl relative">
          {tabComponents(showTab, achievementInput, setAchievementInput, type)}

          <img
            className="w-20 h-20 object-contain absolute bottom-6 right-6 opacity-40 "
            src={tabs.find((_tab) => _tab.value === showTab).icon}
            alt=""
          />
        </div>
      </div>

      <div className="flex items-center gap-6 p-2 px-6 shadow-lg rounded-xl bg-white">
        <button
          className="text-xl text-primary-grey-500 font-extrabold "
          onClick={handleNavigationLeftClick}
        >
          <BsChevronLeft />
        </button>
        <div className="w-full grid grid-cols-4">
          {tabs.map((tab, idx) => (
            <div
              onClick={() => setShowTab(tab.value)}
              key={idx}
              className={`text-xs font-bold p-4 rounded-xl flex flex-col items-center gap-2 cursor-pointer ${
                showTab === tab.value
                  ? "border border-primary-blue-500 bg-primary-blue-50 text-black"
                  : "text-primary-grey-500"
              }`}
            >
              <img src={tab.icon} alt="" className="w-6 h-6 object-contain" />
              <p>{tab.title}</p>
            </div>
          ))}
        </div>

        <button
          className="text-xl text-primary-grey-500 font-extrabold"
          onClick={handleNavigationRightClick}
        >
          <BsChevronRight />
        </button>
      </div>
    </div>
  );
};

export default WebAchievementEditForm;
