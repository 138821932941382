export const essentials = [
  {
    name: "What is Caarya",
    path: "/essentials/what",
    image: "/assets/images/essentials/foundation/1.png",
    saved: true,
  },
  {
    name: "Caarya for Students",
    path: "/essentials/students",
    image: "/assets/images/essentials/foundation/2.png",
    saved: true,
  },
  {
    name: "Caarya for Startups",
    path: "/essentials/startups",
    image: "/assets/images/essentials/foundation/3.png",
  },
  {
    name: "Caarya for Ventures",
    path: "/essentials/ventures",
    image: "/assets/images/essentials/foundation/4.png",
  },
  {
    name: "Our Values",
    path: "/essentials/values",
    image: "/assets/images/essentials/foundation/5.png",
  },
  {
    name: "Caarya Growth Centers (CGCs)",
    path: "/essentials/cgc",
    image: "/assets/images/essentials/foundation/6.png",
  },
  {
    name: "CREWs",
    path: "/essentials/crew",
    image: "/assets/images/essentials/foundation/7.png",
  },
  {
    name: "Topic Clusters",
    path: "/essentials/clusters",
    image: "/assets/images/essentials/foundation/8.png",
  },
  {
    name: "Our Socials",
    path: "/essentials/socials",
    image: "/assets/images/essentials/foundation/9.png",
  },
  {
    name: "Tools and Products",
    path: "/essentials/toolsProducts",
    image: "/assets/images/essentials/foundation/10.png",
  },
];
