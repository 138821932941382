import InViewWrapper from "components/Animation/InViewWrapper";
import PassSection from "components/Pass/PassSection";
import { experiences } from "helpers/constants/accessPass";
import { Disclosure } from "@headlessui/react";
import { List } from "@phosphor-icons/react";

function Pass() {
  return (
    <div className="bg-white -mb-10">
      <Disclosure
        as="nav"
        className="public-nav fixed top-0 left-0 right-0 z-40"
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <a href="/" className="flex flex-shrink-0 items-center">
                    <img
                      className="h-7 w-auto"
                      src="/assets/caaryaLogos/life.svg"
                      alt="Your Company"
                    />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-6 text-white">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <div className="inline-flex items-center px-1 pt-1 text-sm hover:underline underline-offset-4 text-white font-poppins">
                      <div className="w-8 h-8 flex flex-row items-center justify-center text-2xs font-poppins font-medium text-primary-neutral-900 bg-white rounded-full">
                        AB
                      </div>
                    </div>
                    {/* <div className="inline-flex items-center px-1 pt-1 text-sm hover:underline underline-offset-4 text-white font-poppins">
                      <p>Visit Our Website</p>
                    </div> */}
                  </div>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <div className="inline-flex items-center px-1 pt-1 text-sm text-white font-poppins">
                    <div className="w-8 h-8 flex flex-row items-center justify-center text-2xs font-poppins font-medium text-primary-neutral-900 bg-white rounded-full">
                      AB
                    </div>
                  </div>{" "}
                  <div className="inline-flex items-center px-1 pt-1 text-sm text-white font-poppins">
                    <List size={32} />
                  </div>{" "}
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div
        style={{
          background: "linear-gradient(180deg, #ED4C41 0%, #F57D34 100%)",
        }}
        className="relative h-[50vh] lg:h-[60vh] flex flex-row items-center justify-center"
      >
        <InViewWrapper className="block" delay="0.1" yInitial="200">
          <div className="pt-28 w-full px-4 h-full flex flex-col items-center justify-center space-y-6">
            <div className="flex flex-col items-center lg:space-y-2">
              <h1 className="text-white max-w-4xl mx-auto w-full text-center font-poppins text-2xl lg:text-[40px] font-medium lg:leading-[60px]">
                Your Caarya Life
              </h1>
              <p className="text-white max-w-4xl mx-auto w-full text-center text-xs lg:text-base font-inter font-light leading-7 tracking-[0.36px]">
                Caarya (Kaarya): Work
              </p>
            </div>
            <p className="text-white max-w-4xl mx-auto w-full text-center text-base lg:text-xl font-inter font-light leading-7 tracking-[0.36px] lg:tracking-[1.6px]">
              Your journey at Caarya begins here. Some lorem ipsum giving basic
              info about the page
            </p>
          </div>
        </InViewWrapper>
      </div>
      <div className="bg-white relative flex flex-col items-stretch card z-30">
        <div className="h-[5vh] relative">
          <div
            style={{ clipPath: "ellipse(52vw 25px at 50% 100%)" }}
            className="absolute bottom-[1px] right-0 left-0 bg-[#F57D34] h-[5vh] rotate-180"
          />{" "}
        </div>
      </div>
      <InViewWrapper className="block mt-9" delay="0.2" yInitial="200">
        <PassSection {...experiences[0]} idx={0} />
      </InViewWrapper>
      {experiences?.map((item, index) => {
        if (index !== 0) return <PassSection {...item} idx={index} />;
      })}

      {/* <ReactFullpage
        licenseKey={"416E7CEC-568043E0-BEC39CBC-192C1963"}
        scrollingSpeed={1000}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section bg-primary-red-400 relative">
                <div className="py-20 w-full px-4 h-screen flex flex-col items-start lg:items-center justify-center space-y-6">
                  <div className="flex flex-col items-start lg:items-center lg:space-y-2">
                    <h1 className="text-white max-w-2xl mx-auto w-full text-left lg:text-center font-poppins text-2xl lg:text-[64px] font-medium lg:leading-[64px]">
                      Your Caarya Life
                    </h1>
                    <p className="text-white max-w-2xl mx-auto w-full text-left lg:text-center text-xs lg:text-sm font-inter font-light leading-7 tracking-[0.36px]">
                      Caarya (Kaarya): Work
                    </p>
                  </div>
                  <p className="text-white max-w-2xl mx-auto w-full text-left lg:text-center text-sm lg:text-lg font-inter font-light leading-7 tracking-[0.36px]">
                    Your journey at Caarya begins here. Some lorem ipsum giving
                    basic info about the page{" "}
                  </p>
                </div>
              </div>
              {accessPassExperience?.map((item, index) => {
                return <PassSection {...item} idx={index + 1} />;
              })}
            </ReactFullpage.Wrapper>
          );
        }}
      /> */}
    </div>
  );
}

export default Pass;
