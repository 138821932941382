import React, { useState, useEffect } from "react";

function PageLoader({ disappear }) {
  const [showText, setshowText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setshowText(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (disappear) {
      setTimeout(() => {
        setshowText(false);
      }, 400);
    }
  }, [disappear]);

  return (
    <div
      className={`w-full z-50 relative bg-white flex flex-col items-center justify-center ${
        disappear ? "loader-disappear" : "block loader-appear"
      }`}
    >
      <img className={`w-[80px] h-[80px] `} src="assets/caaryaLogos/logo.svg" />
      <h1
        className={`text-container ${
          !disappear || showText ? "" : "pop-out hidden"
        } loader-text loading text-center mt-6 px-2 py-2 md:px-6 text-sm lg:text-6xl text-black font-satoshi leading-5 tracking-[0.56px] capitalize`}
      >
        <span>Initializing career development pathways</span>
        <span>Setting up achievement card samplers</span>
        <span>Activating service profiles</span>
        <span>Creating assesments</span>
        <span>Populating your caarya space</span>
      </h1>
    </div>
  );
}

export default PageLoader;
