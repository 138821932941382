import { useSelector } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BreadCrumb from "components/Common/BreadCrumb";
import SearchBox from "components/Common/Inputs/SearchBox";
import ProfileCards from "./ProfileCardV2";

function KnowYourTribe() {
  const history = useHistory();
  const [searchItem, setSearchItem] = useState("");
  const users = useSelector((state) => state?.dropdown?.users?.list);
  return (
    <div className="pt-20 pb-10">
      <BreadCrumb back page1="Essentials" page2="Know Your Tribe" />
      <div
        style={{
          fontSize: "16px",
          letterSpacing: "0.36px",
          lineHeight: "150%",
        }}
        className="px-4 py-6 font-lato font-semibold"
      >
        Get to know your team members better
      </div>
      <div className="flex flex-col space-y-4 px-4">
        <SearchBox
          placeholder="Start typing to find..."
          search={searchItem}
          setSearch={setSearchItem}
        />
      </div>

      <div className="bg-primary-gray-50 px-4 w-full pt-4 pb-20 flex flex-col space-y-6 h-70vh lg:h-auto overflow-y-auto">
        {users?.map((item) => {
          if (item?.user_type == "caarya-core")
            return (
              <ProfileCards
                person={item}
                onClick={() => {
                  history.push(`/user/${item?.id}`);
                }}
              />
            );
        })}
      </div>
    </div>
  );
}

export default KnowYourTribe;
