import PhaseThreeFormType1 from "components/PhaseThreeForm/Type1";
import { phaseThreeDetailsType1 } from "helpers/constants/phaseThree";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updatePhaseThreeCurrentQuestion,
  updatePhaseThreeDetails,
} from "redux/profile-form";

const PhaseThreePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updatePhaseThreeDetails(phaseThreeDetailsType1));
    dispatch(
      updatePhaseThreeCurrentQuestion(phaseThreeDetailsType1.questions[0].id)
    );
  }, []);
  // useEffect(() => {
  //   dispatch(updatePhaseThreeDetails(phaseThreeDetailsType2));
  //   dispatch(
  //     updatePhaseThreeCurrentQuestion(phaseThreeDetailsType2.questions[0].id)
  //   );
  // }, []);
  return (
    <>
      {/* <PhaseThreeFormType2 />; */}
      <PhaseThreeFormType1 />
    </>
  );
};

export default PhaseThreePage;
