import { Drawer } from "@mui/material";
import { Clock, Sparkle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { RiTimer2Line } from "react-icons/ri";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
function InSessionModal({ isOpen, closeModal, onClick }) {
  const ongoingGoal = useSelector((state) => state.journals?.onGoing);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "32px 32px 0px 0px" : "0px",
          maxHeight: "100vh",
          height: window.innerWidth < 1024 ? "auto" : "100%",
          width: window.innerWidth < 1024 ? "100%" : "560px",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      transitionDuration={250}
    >
      <div className="h-[95vh] relative flex flex-col items-stretch justify-end">
        <div className="flex justify-center items-center flex-row left-0 right-0 absolute top-0 ">
          <button className="inline-flex " onClick={closeModal}>
            <div className="w-[40px] h-[40px] rounded-40px bg-[#363430] p-2 flex justify-center items-center">
              <MdClose className="w-[14px] h-[14px] text-[white]" />
            </div>
          </button>
        </div>
        <div
          style={{
            boxShadow: "-5px 0px 10px #E7E6E5",
          }}
          className="md:max-w-xl lg:h-screen rounded-t-2xl lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150"
        >
          <div
            style={{
              boxShadow:
                "0px -8px 15px -3px rgba(0, 0, 0, 0.10), 0px -4px 6px -4px rgba(0, 0, 0, 0.10)",
            }}
            className="flex border-b border-primary-neutral-300 flex-row items-center justify-center space-x-4 p-6 rounded-t-2xl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <g clip-path="url(#clip0_2916_33468)">
                <path
                  d="M1.08594 3.55469H8.74219V5.3125C8.74219 5.63637 9.00426 5.89844 9.32812 5.89844H12.8438C13.1676 5.89844 13.4297 5.63637 13.4297 5.3125V0.585938C13.4297 0.26207 13.1676 0 12.8438 0H9.32812C9.00426 0 8.74219 0.26207 8.74219 0.585938V2.38281H1.08594C0.76207 2.38281 0.5 2.64488 0.5 2.96875C0.5 3.29262 0.76207 3.55469 1.08594 3.55469Z"
                  fill="#363430"
                />
                <path
                  d="M20.0995 8.2725L16.5448 7.10063C16.4847 7.08063 16.4223 7.07031 16.3594 7.07031H8.15625C7.83238 7.07031 7.57031 7.33238 7.57031 7.65625V12.3438C7.57031 12.6676 7.83238 12.9297 8.15625 12.9297H8.74219V14.7266C8.74219 15.6959 7.95367 16.4844 6.98438 16.4844H5.8125C5.48863 16.4844 5.22656 16.7464 5.22656 17.0703V19.4141C5.22656 19.7379 5.48863 20 5.8125 20H9.32812C9.65199 20 9.91406 19.7379 9.91406 19.4141C9.91406 19.0908 10.1767 18.8281 10.5 18.8281H12.8438C13.167 18.8281 13.4297 19.0908 13.4297 19.4141C13.4297 19.7379 13.6918 20 14.0156 20H17.5312C17.8551 20 18.1172 19.7379 18.1172 19.4141V17.0703C18.1172 16.7464 17.8551 16.4844 17.5312 16.4844H16.9453C15.6527 16.4844 14.6016 15.3942 14.6016 14.1016C14.6016 12.867 15.6309 11.7578 16.9453 11.7578H19.9141C20.2379 11.7578 20.5 11.4957 20.5 11.1719V8.82812C20.5 8.57578 20.3386 8.35203 20.0995 8.2725Z"
                  fill="#363430"
                />
                <path
                  d="M6.22677 6.89864C6.45563 6.66978 6.45563 6.29896 6.22677 6.07009L5.05489 4.89821C4.82603 4.66935 4.45521 4.66935 4.22634 4.89821C3.99747 5.12708 3.99747 5.4979 4.22634 5.72677L5.39821 6.89864C5.62712 7.12755 5.9979 7.12755 6.22677 6.89864Z"
                  fill="#363430"
                />
                <path
                  d="M15.6018 5.72677L16.7736 4.55489C17.0025 4.32603 17.0025 3.95521 16.7736 3.72634C16.5448 3.49747 16.174 3.49747 15.9451 3.72634L14.7732 4.89821C14.5443 5.12708 14.5443 5.4979 14.7732 5.72677C15.0021 5.95563 15.3729 5.95567 15.6018 5.72677Z"
                  fill="#363430"
                />
                <path
                  d="M6.50531 12.9297C6.43965 12.7455 6.39844 12.5502 6.39844 12.3438V8.24219H2.29687C1.97301 8.24219 1.71094 8.50426 1.71094 8.82812C1.71094 11.0895 3.55113 12.9297 5.8125 12.9297H6.50531Z"
                  fill="#363430"
                />
              </g>
              <defs>
                <clipPath id="clip0_2916_33468">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p className="text-primary-neutral-800 font-satoshi text-base font-bold leading-6 tracking-[0.64px] capitalize">
              Goal Already In Session
            </p>
          </div>
          <div className="flex flex-col space-y-14 py-8 px-6">
            <div className="rounded-2xl border border-primary-neutral-200 bg-white w-full py-3.5 px-4 flex flex-row items-center space-x-4">
              <img src="/assets/svg/target.svg" alt="" className="w-5 h-5" />
              <p className="text-black font-lato text-sm font-semibold leading-5 tracking-[0.28px]">
                {ongoingGoal?.data?.title}
              </p>
            </div>
            <div className="flex flex-col items-center space-y-2">
              <img
                src="/assets/svg/inSession.png"
                alt=""
                className="w-[120px] h-[120px]"
              />
              <p className="font-primary-neutral-500 font-lato text-center text-base leading-6">
                Please clock out of the current session to start a new one.
              </p>
            </div>
            <div
              onClick={onClick}
              className="bg-[#ED4C41] cursor-pointer shadow-lg rounded-3xl flex flex-row items-center justify-center w-full py-6 px-5 text-white font-lato text-base font-bold leading-6 space-x-3"
            >
              <ArrowRight size={18} className="-rotate-45" />
              <p className="">Go to Session</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default InSessionModal;
