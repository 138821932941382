import ServiceCard from "components/CareerFlow/ServiceCard";
import ServiceFooter from "components/CareerFlow/ServiceFooter";
import { useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { IoChevronUpOutline } from "react-icons/io5";

const Services = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const param = params.get("role");

  const roles = useSelector((state) => state?.life?.roles);
  const [actionType, setActionType] = useState("");
  const [selectedRole, setSelectedRole] = useState();

  useEffect(() => {
    if (actionType?.length) {
      setTimeout(() => {
        setActionType();
      }, 1500);
    }
  }, [actionType]);
  const userServices = useSelector((state) => state?.life?.userServices);

  useEffect(() => {
    if (roles?.length > 0) {
      setSelectedRole(roles[0]);
    }
  }, [roles]);

  useEffect(() => {
    if (param) {
      const r = roles?.find((r) => r.name === param);
      setSelectedRole(r);
    }
  }, [param]);

  const handleDetails = (id) => history.push(`/service/${id}`);

  const updateSearchParams = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(newParams).forEach((key) => {
      searchParams.set(key, newParams[key]);
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <div className="relative overflow-y-auto max-h-[90vh] bg-[#fafafa]">
      <div className="bg-primary-neutral-100">
        <div className="h-[50px] fixed top-0 right-0 left-0 md:hidden bg-white z-30" />
        <div className="flex px-4 mt-[50px] py-6 flex-col space-y-2 w-full bg-white fixed top-0 z-10">
          <p
            className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-lato text-base tracking-[0.64px] leading-6"
            onClick={() => history.goBack()}
          >
            <MdArrowBack />
            <p>Services</p>
          </p>
        </div>
        <div
          className="bg-white border-b-2 mt-[110px] border-primary-neutral-200  px-4 overflow-x-auto flex flex-row space-x-3"
          id="roles"
        >
          {roles?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setSelectedRole(item);
                  updateSearchParams({ role: item.name });
                }}
                style={{
                  scrollMargin: "200px",
                }}
                id={item?._id}
                className={`text-xs min-w-max font-bold flex flex-col items-center font-lato py-3 px-6 space-y-2 ${
                  selectedRole?._id == item?._id
                    ? "text-primary-neutral-800  border-b border-primary-neutral-800"
                    : "text-primary-neutral-400"
                }`}
              >
                <img
                  src={`/assets/images/role${
                    index % 4 == 0 ? "4" : index % 4
                  }.png`}
                  alt=""
                  className="w-6 h-6 object-cover"
                />
                <p>{item?.name}</p>
              </div>
            );
          })}
        </div>
        {actionType && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
            exit={{ opacity: 0 }}
            className="text-white z-50 fixed bottom-32 left-1/2 transform -translate-x-1/2 font-bold font-satoshi text-xs w-[180px] bg-primary-neutral-800 px-2 py-3 rounded-lg text-center mx-auto"
          >
            {actionType === "added"
              ? "Service Activated!"
              : "Service Deactivated!"}
          </motion.div>
        )}
        <div className="bg-primary-neutral-50 py-4 space-y-6">
          <div
            className="p-5 flex flex-row justify-between items-center space-x-4"
            id="top"
            style={{
              scrollMargin: "100px",
            }}
          >
            <div className="flex flex-col">
              <h1 className="text-primary-neutral-800 font-lato text-lg font-bold leading-7 tracking-[0.72px]">
                {selectedRole?.name}
              </h1>
              <h1 className="text-primary-neutral-500 font-satoshi text-xs leading-4 tracking-[0.24px]">
                {selectedRole?.services?.filter((a) =>
                  userServices?.map((s) => s?.service)?.includes(a?._id)
                )?.length || 0}
                /{selectedRole?.services?.length || 0} Services Active
              </h1>
            </div>
            <p className="text-primary-neutral-800 font-satoshi text-xs font-bold leading-4 tracking-[0.24px] underline">
              View Guidebook
            </p>
          </div>
          <div className="px-4 pb-10 space-y-6 overflow-y-auto">
            {roles?.map((item) => {
              if (
                !selectedRole ||
                (selectedRole && item?._id == selectedRole?._id)
              )
                return (
                  <ServiceList
                    heading={item?.name}
                    services={item?.services}
                    setActionType={setActionType}
                    handleDetails={handleDetails}
                  />
                );
            })}
          </div>
        </div>
        <ServiceFooter
          role={roles?.find((a) => a?._id == selectedRole)?.name || "all roles"}
        />
      </div>

      {/* <div className="z-[9999]">
        <BottomSheet
          isOpen={isActivateModalOpen.open}
          close={() =>
            setIsActivateModalOpen({
              ...isActivateModalOpen,
              open: false,
            })
          }
          className="z-50"
        >
          <ActiveServiceModal
            isActivateModalOpen={isActivateModalOpen}
            onActivate={onActivate}
          />
        </BottomSheet>

        <BottomSheet
          isOpen={isActivatedModalOpen}
          close={() => setIsActivatedModalOpen(false)}
          className="z-50"
        >
          <ServiceActivatedModal />
        </BottomSheet>
      </div> */}

      {/* <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <ConfirmationModal
          setConfirmationModal={setConfirmationModal}
          completed={2}
        />
      </Modal> */}
    </div>
  );
};

export default Services;

function ServiceList({ services, role, setActionType, handleDetails }) {
  const userServices = useSelector((state) => state?.life?.userServices);
  return (
    <>
      <div className="space-y-2 max-w-[480px] mx-auto">
        <h1 className="px-2 text-primary-neutral-800 font-lato text-xs font-bold capitalize leading-5 tracking-[0.48px]">
          Active Services
        </h1>
        <div className="py-2 flex flex-col space-y-6">
          {services
            ?.filter((a) =>
              userServices?.map((s) => s?.service)?.includes(a?._id)
            )
            ?.map((item, index) => {
              return (
                <ServiceCard
                  index={index}
                  service={item}
                  isActive
                  setActionType={setActionType}
                  role={{ name: role }}
                  handleDetails={handleDetails}
                />
              );
            })}
        </div>
      </div>

      <div className="space-y-2 max-w-[480px] mx-auto">
        <h1 className="px-2 text-primary-neutral-800 font-lato text-xs font-bold capitalize leading-5 tracking-[0.48px]">
          Inactive Services
        </h1>
        <div className="py-2 flex flex-col space-y-6">
          {services
            ?.filter(
              (a) => !userServices?.map((s) => s?.service)?.includes(a?._id)
            )
            ?.map((item, index) => {
              return (
                <ServiceCard
                  index={index}
                  service={item}
                  role={{ name: role }}
                  setActionType={setActionType}
                  handleDetails={handleDetails}
                />
              );
            })}
        </div>
      </div>

      <div
        onClick={() => {
          document.getElementById("top").scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }}
        className="fixed cursor-pointer bottom-20 right-2 bg-black rounded-full flex flex-row items-center w-10 h-10 justify-center z-40"
      >
        <IoChevronUpOutline size={20} className="text-white" />{" "}
      </div>
    </>
  );
}
