import { Text } from 'helpers/constants/Text'
import React from 'react'
import { Img } from '../Img/Img'
import Button from 'helpers/constants/button'
import { List } from '../List/List'
const Rtc = () => {
  return (
    <>
    <div className="flex flex-col font-manrope gap-6 items-start justify-start py-4 w-full">
              <Text
                className="text-blue_gray-900 text-center text-lg tracking-[0.90px] w-auto ml-2"
                size="txtManropeSemiBold18"
              >
                Your Road to A Contract
              </Text>
              <div className="flex md:flex-col flex-row font-satoshi gap-8 items-center justify-start pr-4 w-full">
                <div className=" thirdDiv bg-white-A700 border border-b-0 flex flex-1 flex-col items-center justify-center p-6 sm:px-5 rounded-[24px] shadow-bs3 w-1/2">
                  <List className="flex gap-3 justify-start w-full">
                    <div className="flex flex-1 flex-col h-full items-start justify-start sm:ml-[0] ">
                      <Img
                        className=""
                        src="/assets/svg/pass/img_frame427318997.svg"
                        alt="frame427318997"
                      />
                      <div className="flex flex-col gap-1 items-center justify-center p-2 w-full ml-[-15px]">
                        <Img
                          className="h-14 rounded-[50%] w-14"
                          src="/assets/svg/pass/img_frame427319002.svg"
                          alt="frame427319002"
                        />
                        <Text
                          className="text-center text-red-500 text-sm tracking-[0.70px] w-auto"
                          size="txtSatoshiMedium14"
                        >
                          Explorer
                        </Text>
                      </div>
                      <Img
                        className=""
                        src="/assets/svg/pass/img_frame427318996.svg"
                        alt="frame427318996"
                      />
                    </div>
                    <div className="flex flex-1 flex-col h-full items-start justify-start sm:ml-[0] ">
                      <Img
                        className=""
                        src="/assets/svg/pass/img_frame427318997_gray_300.svg"
                        alt="frame427318997"
                      />
                      <div className="flex flex-col gap-2 items-center justify-center p-2 w-full">
                        <Img
                          className="h-14 w-14"
                          src="/assets/svg/pass/img_frame427319003.svg"
                          alt="frame427319003"
                        />
                        <Text
                          className="text-center text-gray-500 text-sm tracking-[0.70px] w-auto"
                          size="txtSatoshiMedium14Gray500"
                        >
                          Learner
                        </Text>
                      </div>
                      <Img
                        className=""
                        src="/assets/svg/pass/img_frame427318996_blue_gray_100.svg"
                        alt="frame427318996"
                      />
                    </div>
                    <div className="flex flex-1 flex-col h-full items-start justify-start sm:ml-[0] ">
                      <Img
                        className=""
                        src="/assets/svg/pass/img_frame427318997.svg"
                        alt="frame427318997"
                      />
                      <div className="flex flex-col gap-2 items-center justify-center p-2 w-full">
                        <Img
                          className="h-10 w-10"
                          src="/assets/svg/pass/fi_1063376.svg"
                          alt="frame427319003"
                        />
                        <Text
                          className="text-center text-gray-500 text-sm tracking-[0.70px] w-auto"
                          size="txtSatoshiMedium14Gray500"
                        >
                          Worker
                        </Text>
                      </div>
                      <Img
                        className="pt-4"
                        src="/assets/svg/pass/img_frame427318996.svg"
                        alt="frame427318996"
                      />
                    </div>
                  </List>
                  <div className="frame-35">
                    <div
                      className="frame-36 "
                      style={{ border: "8px solid #FF7E6E" }}
                    >
                      <div className="overlap-group-2">
                        <p className="element-3">
                          <span className="text-wrapper-10">4.5</span>
                        </p>
                        <div className="star-wrapper">
                          <img
                            className="star"
                            alt="Star"
                            src="assets/svg/pass/star.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="overlap">
                      <p className="employability-score">
                        <span className="text-wrapper-11">
                          Employability <br />
                          Score
                        </span>
                      </p>
                      <div className="question-mark-wrapper">
                        <Button>
                          <img
                            className="question-mark"
                            alt="Question mark"
                            src="assets/svg/pass/question_mark.svg"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
            <div className=" customDiv flex flex-col gap-4 items-start justify-start  w-auto">
              <List
                className="grid  gap-4 md:grid-cols-2 lg:grid-cols-3 p-2 w-full "
                orientation="horizontal"
              >
                {["Working", "Learning", "Knowledge Cars"].map(
                  (category, index) => (
                    <div
                      key={index}
                      className="flex flex-1 flex-col gap-4 items-start justify-center p-8 w-128"
                    >
                      <Button
                        className="flex h-10 items-center justify-center w-10 "
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(255, 233, 154, 1) 0%, rgba(255, 126, 110, 1) 100%)",
                        }}
                        shape="round"
                        size="sm"
                      >
                        <Img
                          className="h-6"
                          src="assets/svg/pass/bolt.svg"
                          alt="bolt"
                        />
                      </Button>
                      <div className="flex flex-col items-start justify-center w-full">
                        <Text
                          className="text-gray-700 text-sm tracking-[0.70px] w-full"
                          size="txtSatoshiMedium14Gray700"
                        >
                          {`${category} Quests Completed`}
                        </Text>
                        <Text
                          className="md:text-3xl sm:text-[28px] text-[32px] text-blue_gray-900 text-center tracking-[1.60px] w-auto"
                          size="txtSatoshiBold32"
                        >
                          00
                        </Text>
                      </div>
                    </div>
                  )
                )}
              </List>
            </div>
    
    </>
  )
}

export default Rtc