import { useEffect, useState, useRef } from "react";

function Tabs({ tabs, selectedTab, setSelectedTab }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  useEffect(() => {
    let idx = tabs?.findIndex((a) => a?.value == selectedTab);
    setActiveTabIndex(idx);
  }, [selectedTab]);

  return (
    <div className="pt-5 pb-2 relative">
      <div className="flex flex-row items-center text-xs justify-center overflow-x-auto gap-[20px] w-min">
        {tabs.map((item, idx) => {
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              ref={(el) => (tabsRef.current[idx] = el)}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max"
            >
              <div
                className={`flex px-3 pb-1 flex-row cursor-pointer text-sm leading-5 tracking-[0.28px] items-end justify-center font-satoshi text-center hover:font-bold ${
                  selectedTab == item?.value
                    ? "font-medium text-black "
                    : "font-medium text-primary-neutral-400"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>

      <span
        className="absolute bottom-1 rounded-sm block h-[3px] bg-[#903795] transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
}

function DividerTabs({ tabs, selectedTab, setSelectedTab, dark, black }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  useEffect(() => {
    let idx = tabs?.findIndex((a) => a?.value == selectedTab);
    setActiveTabIndex(idx);
  }, [selectedTab]);

  return dark ? (
    <div className="relative w-full">
      <div className="w-full md:w-[269px] grid grid-cols-2">
        {tabs.map((item, idx) => {
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max w-full flex flex-row items-center justify-center"
            >
              <div
                ref={(el) => (tabsRef.current[idx] = el)}
                className={`p-4 w-full cursor-pointer text-xs sm:text-sm font-satoshi text-center hover:font-bold ${
                  selectedTab == item?.value
                    ? `font-bold ${
                        black
                          ? "text-[#363430] "
                          : "text-[#000] md:text-[#ED4C41]"
                      }`
                    : "font-bold text-[#9C9A96]"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>

      <span
        className={`absolute bottom-0 block h-1 ${
          black ? "bg-[#363430]" : "bg-[#000] md:bg-[#ED4C41]"
        } transition-all duration-300`}
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  ) : (
    <div className="bg-white relative">
      <div className="w-full grid grid-cols-2 divide-x-2 divide-primary-neutral-200 border-t border-b border-primary-neutral-200">
        {tabs.map((item, idx) => {
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max pt-4 pb-2 w-full flex flex-row items-center justify-center"
            >
              <div
                ref={(el) => (tabsRef.current[idx] = el)}
                className={` px-6 pb-2 cursor-pointer text-base lg:text-base font-lato text-center hover:font-bold ${
                  dark
                    ? selectedTab == item?.value
                      ? "font-bold text-white"
                      : "font-bold text-[#FEB8B1]"
                    : selectedTab == item?.value
                    ? "font-bold text-[#903795]"
                    : "font-bold text-primary-neutral-400"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>

      <span
        className={`absolute bottom-2 rounded-full block h-1 ${
          dark ? "bg-[#ED4C41]" : "bg-[#903795]"
        } transition-all duration-300`}
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
}

export default Tabs;

export { DividerTabs };
