import { MdDoneAll } from "react-icons/md";
import { ReactComponent as Learner1 } from "assets/svg/learner1.svg";
import { ReactComponent as Learner2 } from "assets/svg/learner2.svg";
import { ReactComponent as Learner3 } from "assets/svg/learner3.svg";

const getLearner = (i) => {
  switch (i) {
    case 0:
      return <Learner1 className="text-[#FFBC00]" />;
    case 1:
      return <Learner2 className="text-[#FFBC00]" />;
    case 2:
      return <Learner3 className="text-[#FFBC00]" />;
    case 3:
      return <Learner1 className="text-[#816FE9]" />;
    case 4:
      return <Learner2 className="text-[#816FE9]" />;
    case 5:
      return <Learner3 className="text-[#816FE9]" />;
    default:
      return;
  }
};

export default function ConfirmationModal({ setConfirmationModal, completed }) {
  return (
    <div className="flex w-full max-w-[320px] p-6 flex-col gap-8 items-center rounded-[32px] bg-white">
      <div className="flex flex-col items-center gap-2">
        <img
          src="/assets/images/demo2.png"
          alt=""
          className={`w-12 h-12 object-cover`}
        />
        <p className="text-center font-satoshi font-bold text-xl tracking-[0.8px]">
          Added to Career Pathway!
        </p>
      </div>

      <div className="flex p-2">
        {[...Array(6)].map((_, i) => {
          const active = i < completed;
          const nextActive = i + 1 < completed;
          return (
            <div className="flex w-full flex-col justify-center items-center gap-2 flex-[1_0_0]">
              {getLearner(i)}
              <div className="w-full flex items-center">
                <div
                  className={`w-4 h-0.5 ${
                    active ? "bg-[#FFD844]" : "bg-primary-neutral-200"
                  }`}
                />
                <div
                  className={`w-[10px] h-[10px] rounded-full  ${
                    active ? "bg-[#FFD844]" : "bg-primary-neutral-100"
                  }`}
                />
                <div
                  className={`w-4 h-0.5 ${
                    nextActive ? "bg-[#FFD844]" : "bg-primary-neutral-200"
                  }`}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-col items-center gap-2">
        <div className="flex px-4 py-2 items-center gap-4 rounded-lg bg-primary-neutral-50">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M11.875 0H2.29167C1.025 0 0 1.025 0 2.29167V15.2083C0 16.475 1.025 17.5 2.29167 17.5H8.44167L8.625 16.475C8.70833 16.0083 8.925 15.5917 9.25833 15.25L14.1667 10.35V2.29167C14.1667 1.025 13.1417 0 11.875 0ZM3.33333 3.33333H6.66667C7.125 3.33333 7.5 3.70833 7.5 4.16667C7.5 4.625 7.125 5 6.66667 5H3.33333C2.875 5 2.5 4.625 2.5 4.16667C2.5 3.70833 2.875 3.33333 3.33333 3.33333ZM7.5 11.6667H3.33333C2.875 11.6667 2.5 11.2917 2.5 10.8333C2.5 10.375 2.875 10 3.33333 10H7.5C7.95833 10 8.33333 10.375 8.33333 10.8333C8.33333 11.2917 7.95833 11.6667 7.5 11.6667ZM10.8333 8.33333H3.33333C2.875 8.33333 2.5 7.95833 2.5 7.5C2.5 7.04167 2.875 6.66667 3.33333 6.66667H10.8333C11.2917 6.66667 11.6667 7.04167 11.6667 7.5C11.6667 7.95833 11.2917 8.33333 10.8333 8.33333Z"
              fill="#FFBC00"
            />
            <path
              d="M10.44 20C10.2758 20 10.1158 19.935 9.99833 19.8167C9.85417 19.6725 9.78917 19.4675 9.825 19.2658L10.2667 16.7617C10.2883 16.6358 10.35 16.5192 10.44 16.4283L16.6275 10.2417C17.3875 9.48 18.1342 9.68583 18.5425 10.0942L19.5733 11.125C20.1425 11.6933 20.1425 12.6183 19.5733 13.1875L13.3858 19.375C13.2958 19.4658 13.1792 19.5267 13.0525 19.5483L10.5483 19.99C10.5125 19.9967 10.4758 20 10.44 20Z"
              fill="#FFBC00"
            />
          </svg>
          <p className="text-primary-neutral-800 font-satoshi font-medium text-xs capitalize">
            8 new work contracts unlocked for you!
          </p>
        </div>
        <div className="flex px-4 py-2 items-center gap-4 rounded-lg bg-primary-neutral-50">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_4924_47437)">
              <path
                d="M9.41191 10.2717C9.06552 10.4726 8.66383 10.5882 8.23544 10.5882C6.93803 10.5882 5.8825 9.53271 5.8825 8.23529H7.05897C7.05897 8.89584 7.60614 9.43098 8.27105 9.41122C8.91093 9.39224 9.41191 8.85039 9.41191 8.21024V1.18369C9.41191 0.549529 8.90685 0 8.23544 0C7.34485 0 6.56834 0.497412 6.16885 1.22894C7.02265 1.38404 7.78563 1.87753 8.27861 2.61408L7.30089 3.26847C6.9172 2.69522 6.27701 2.35294 5.58838 2.35294C3.80442 2.35294 2.35308 3.80431 2.35308 5.58824C2.35308 5.90612 2.40073 6.22369 2.49281 6.52757C1.32065 7.2769 0.588379 8.58647 0.588379 10C0.588379 11.6218 1.90779 12.9412 3.52956 12.9412C4.25438 12.9412 4.95069 12.6753 5.49018 12.1924L6.27477 13.069C5.18912 14.0407 3.70936 14.3391 2.38591 13.9559C2.09167 16.0128 3.79889 17.8228 5.8825 17.6337V17.6471C5.8825 18.9445 6.93803 20 8.23544 20C8.90728 20 9.41191 19.4501 9.41191 18.8163V10.2717Z"
                fill="#C44EB9"
              />
              <path
                d="M15.5804 12.3529H14.7256V11.1765H15.5883C16.7631 11.1765 17.7934 11.806 18.3603 12.7453C19.7618 11.1757 19.7618 8.82431 18.3603 7.25466C17.7934 8.194 16.7631 8.82353 15.5883 8.82353H14.7256V7.64706H15.5804C16.7139 7.64706 17.6479 6.71961 17.6472 5.58608C17.6459 3.69792 16.021 2.19349 14.1178 2.36623V2.35294C14.1178 1.05482 13.0588 -0.00706266 11.752 3.53788e-05C11.1061 0.00352558 10.5884 0.537761 10.5884 1.18368V6.47059C10.5884 7.11929 11.1161 7.64706 11.7648 7.64706V8.82353C11.3364 8.82353 10.9347 8.70788 10.5884 8.50698V12.9412C10.5884 13.5899 11.1161 14.1176 11.7648 14.1176V15.2941C11.3364 15.2941 10.9347 15.1785 10.5884 14.9776V18.8164C10.5884 19.4505 11.0934 20 11.7648 20C13.0623 20 14.1178 18.9445 14.1178 17.6471V17.6338C16.0209 17.8065 17.646 16.3023 17.6472 14.4139C17.6479 13.2804 16.7139 12.3529 15.5804 12.3529Z"
                fill="#C44EB9"
              />
            </g>
            <defs>
              <clipPath id="clip0_4924_47437">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="text-primary-neutral-800 font-satoshi font-medium text-xs capitalize">
            New Knowledge Cards Unlocked on Space!
          </p>
        </div>
      </div>
      <p className="text-black text-center font-satoshi font-normal text-xs capitalize">
        Complete work contracts to upskill and progress in User Research Studies
      </p>
      <button
        onClick={() => setConfirmationModal(false)}
        className={`bg-black w-full flex flex-row items-center justify-center space-x-2 rounded-full pr-10 pl-8 py-4 cursor-pointer text-white font-satoshi text-sm font-bold leading-6`}
      >
        <MdDoneAll className="h-5 w-5" />
        <span>Done</span>
      </button>
    </div>
  );
}
