import "../../assets/styles/css/index.css";
import { useState } from "react";
import FirstTab from "./tabs/FirstTab";
import ThirdTab from "./tabs/ThirdTab";
import Navigation from "./Navigation";
import SecondTab from "./tabs/SecondTab";

const GoProStudentLife = () => {
  const [step, setStep] = useState(0);

  const TabContent = () => {
    switch (step) {
      case 0:
        return <FirstTab step={step} setStep={setStep} />;
      case 1:
        return <SecondTab step={step} setStep={setStep} />;
      case 2:
        return <ThirdTab step={step} setStep={setStep} />;
      default:
        return <FirstTab step={step} setStep={setStep} />;
    }
  };
  return (
    <>
      <div className="bg-white">
        <Navigation step={step} setStep={setStep}/>
        <TabContent />
      </div>
    </>
  );
};

export default GoProStudentLife;
