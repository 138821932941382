import ProjectList from "components/Achievements/View/projects";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";

export default function ProjectsPage() {
  const history = useHistory();

  return (
    <div className={`bg-[#fafafa] pt-[52.5px]`}>
      <div className="flex px-4 py-6 flex-row justify-between w-full bg-white">
        <div className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-satoshi text-base tracking-[0.64px] leading-6">
          <MdArrowBack
            className="text-primary-neutral-800 text-xl cursor-pointer"
            onClick={() => history.goBack()}
          />
          <p className="text-[16px]">Your Achievements</p>
        </div>
        <div className="relative">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => history.push("/achievements/add")}
          >
            <IoMdAddCircle className="h-5 w-5 fill-black" />
            <p className="font-satoshi font-bold leading-5 text-sm tracking-[0.21px] text-primary-neutral-900 underline underline-offset-2">
              Add Now
            </p>
          </div>
        </div>
      </div>
      <ProjectList />
    </div>
  );
}
