export const phaseThreeDetailsType1 = {
  limitPerPage: 5,
  questions: [
    {
      id: "10000",
      question:
        "0 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10001",
      question:
        "1 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10002",
      question:
        "2 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10003",
      question:
        "3 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10004",
      question:
        "4 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10005",
      question:
        "5 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10006",
      question:
        "6 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10007",
      question:
        "7 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
    {
      id: "10009",
      question:
        "8 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
    },
  ],
};

export const phaseThreeDetailsType2 = {
  limitPerPage: 3,
  questions: [
    {
      id: "10000",
      question:
        "0 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
      options: [
        {
          id: "110000",
          option:
            "0 Option 1 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "110001",
          option:
            "1 Option 2 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "110002",
          option:
            "2 Option 3 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "110003",
          option:
            "3 Option 4 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
      ],
    },
    {
      id: "10001",
      question:
        "1 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
      options: [
        {
          id: "120000",
          option:
            "0 Option 1 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120001",
          option:
            "1 Option 2 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120002",
          option:
            "2 Option 3 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120003",
          option:
            "3 Option 4 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
      ],
    },
    {
      id: "10006",
      question:
        "2 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
      options: [
        {
          id: "120000",
          option:
            "0 Option 1 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120001",
          option:
            "1 Option 2 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120002",
          option:
            "2 Option 3 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120003",
          option:
            "3 Option 4 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
      ],
    },
    {
      id: "10007",
      question:
        "3 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
      options: [
        {
          id: "120000",
          option:
            "0 Option 1 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120001",
          option:
            "1 Option 2 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120002",
          option:
            "2 Option 3 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120003",
          option:
            "3 Option 4 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
      ],
    },
    {
      id: "10008",
      question:
        "4 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
      options: [
        {
          id: "120000",
          option:
            "0 Option 1 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120001",
          option:
            "1 Option 2 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120002",
          option:
            "2 Option 3 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120003",
          option:
            "3 Option 4 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
      ],
    },
    {
      id: "10009",
      question:
        "5 Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ",
      options: [
        {
          id: "120000",
          option:
            "0 Option 1 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120001",
          option:
            "1 Option 2 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120002",
          option:
            "2 Option 3 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
        {
          id: "120003",
          option:
            "3 Option 4 Velit officia consequat duis enim velit mollit. lorem ipsum dolor nighir",
        },
      ],
    },
  ],
};
