import { essentials } from "helpers/constants/fyi";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function InfoCards() {
  const history = useHistory();
  const [selected, setSelected] = useState("Caarya");
  return (
    <div className="py-8 space-y-10">
      <div className="px-4 overflow-x-auto flex flex-row space-x-3">
        {[
          { name: "Caarya" },
          { name: "Project Name" },
          { name: "Initiative / Startup" },
        ]?.map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelected(item?.name);
              }}
              className={`text-2xs min-w-max font-medium py-2 px-4 rounded-full border flex flex-col items-center font-satoshi ${
                selected == item?.name
                  ? "text-white border-primary-neutral-800 bg-primary-neutral-800"
                  : "text-primary-neutral-500 border-primary-neutral-200"
              }`}
            >
              <p>{item?.name}</p>
            </div>
          );
        })}
      </div>
      <div className="px-4 flex flex-col space-y-6 pb-14">
        {essentials?.map((item) => {
          return (
            <div className="">
              <div
                className="relative cursor-pointer bg-white p-4 rounded-2xl w-full flex flex-row items-center justify-between space-x-4"
                onClick={() => {
                  item?.path && history.push(item?.path);
                }}
              >
                <img
                  src={item?.image}
                  alt=""
                  className="w-12 h-12 object-cover"
                />
                <div className="flex flex-col space-y-2 w-8/12">
                  <h1
                    onClick={() => {
                      item?.path && history.push(item?.path);
                    }}
                    className="z-20 text-primary-neutral-800 font-lato text-sm leading-5 tracking-[0.56px] font-bold"
                  >
                    {item?.name}
                  </h1>
                  <div className="flex flex-row items-center space-x-2 text-primary-neutral-500 font-satoshi text-2xs font-bold leading-4 tracking-[0.4px]">
                    2 min
                  </div>
                </div>

                {item?.saved ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                  >
                    <path
                      d="M1.16683 0.664062H12.8335C13.0545 0.664063 13.2665 0.75186 13.4228 0.90814C13.579 1.06442 13.6668 1.27638 13.6668 1.4974V17.4499C13.6669 17.5244 13.647 17.5976 13.6092 17.6618C13.5715 17.726 13.5171 17.7789 13.4519 17.815C13.3868 17.851 13.3131 17.869 13.2386 17.8669C13.1641 17.8649 13.0916 17.8429 13.0285 17.8032L7.00016 14.0224L0.971829 17.8024C0.908803 17.842 0.836319 17.864 0.761914 17.8661C0.68751 17.8682 0.613901 17.8503 0.548742 17.8143C0.483583 17.7784 0.429252 17.7256 0.391398 17.6615C0.353545 17.5974 0.333551 17.5243 0.333496 17.4499V1.4974C0.333496 1.27638 0.421294 1.06442 0.577574 0.90814C0.733854 0.75186 0.945816 0.664063 1.16683 0.664062Z"
                      fill="#363430"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                  >
                    <path
                      d="M1.16683 0.664062H12.8335C13.0545 0.664063 13.2665 0.75186 13.4228 0.90814C13.579 1.06442 13.6668 1.27638 13.6668 1.4974V17.4499C13.6669 17.5244 13.647 17.5976 13.6092 17.6618C13.5715 17.726 13.5171 17.7789 13.4519 17.815C13.3868 17.851 13.3131 17.869 13.2386 17.8669C13.1641 17.8649 13.0916 17.8429 13.0285 17.8032L7.00016 14.0224L0.971829 17.8024C0.908803 17.842 0.836319 17.864 0.761914 17.8661C0.68751 17.8682 0.613901 17.8503 0.548742 17.8143C0.483583 17.7784 0.429252 17.7256 0.391398 17.6615C0.353545 17.5974 0.333551 17.5243 0.333496 17.4499V1.4974C0.333496 1.27638 0.421294 1.06442 0.577574 0.90814C0.733854 0.75186 0.945816 0.664063 1.16683 0.664062ZM12.0002 2.33073H2.00016V15.1907L7.00016 12.0566L12.0002 15.1907V2.33073Z"
                      fill="#363430"
                    />
                  </svg>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InfoCards;
