import React from "react";
import Lottie from "react-lottie-player";
import phaseOneLottie from "../../../../../assets/lotties/phaseOne.json";
import { useSelector } from "react-redux";

const WebFinalStepForm = () => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);

  return (
    <div className="px-28 py-8 min-h-[85vh] gap-6 flex flex-col  ">
      <div className="flex flex-col gap-2 text-center">
        <h4 className="text-3xl text-primary-blue-500  font-medium ">
          Congratulations {details?.name}, Your MeProfile is ready !
        </h4>
        <p className="font-satoshi text-primary-grey-600 text-md ">
          You are all set to begin your journey!
        </p>
      </div>
      <div className="w-full max-w-md mx-auto h-96 grid place-items-center">
        <Lottie
          loop
          animationData={phaseOneLottie}
          play
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>
    </div>
  );
};

export default WebFinalStepForm;
