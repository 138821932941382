import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

function Personal({ details, setDetails, onNext }) {
  return (
    <section className="personal lg:h-auto w-full flex flex-col justify-between pb-6">
      <div className="pb-6 px-5 space-y-10">
        <div className="text-primary-red-500 font-satoshi text-base font-bold leading-6 tracking-[0.32px] capitalize flex flex-col items-center text-center">
          We see that you are not part of our network yet!
        </div>

        <div className="flex flex-col gap-8 w-full">
          <div className="flex flex-col space-y-2">
            <h1 className="text-primary-neutral-800 font-satoshi text-2xl font-bold leading-9 tracking-[0.96px]">
              One Last Step
            </h1>
            <p className="text-primary-neutral-800 font-satoshi text-base leading-6 tracking-[0.64px] flex flex-col">
              Before we generate your pass
            </p>
          </div>

          <div className="flex flex-col space-y-2">
            <p className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-5 tracking-[0.35px]">
              Your Name
            </p>
            <div className="flex flex-row items-center justify-between text-lg lg:text-2xl p-4 border rounded-xl border-primary-neutral-200 w-full">
              <input
                type="text"
                className="w-full border-0 p-0 focus:outline-none focus:ring-0 focus:border-0"
                placeholder="Full Name Here"
                value={details?.name}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    name: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <p className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-5 tracking-[0.35px]">
              Email
            </p>
            <div className="flex flex-row items-center justify-between text-lg lg:text-2xl p-4 border rounded-xl border-primary-neutral-200 w-full">
              <input
                type="email"
                className="w-full border-0 p-0 focus:outline-none focus:ring-0 focus:border-0"
                placeholder="Email ID Here"
                value={details?.email}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <p className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-5 tracking-[0.35px]">
              Mobile
            </p>
            <div className="flex flex-row items-center justify-between text-lg lg:text-2xl p-4 border rounded-xl border-primary-neutral-200 w-full">
              <input
                type="number"
                className="w-full border-0 p-0 focus:outline-none focus:ring-0 focus:border-0"
                placeholder="Phone Number Here"
                value={details?.phoneNumber}
                onChange={(e) => {
                  e.target.value = e.target.value
                    .trimStart()
                    .replace(/[^0-9]/gi, "");
                  if (e.target.value?.length > 10) return;
                  setDetails({
                    ...details,
                    phoneNumber: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="flex flex-row max-w-[360px] mx-auto items-center justify-center w-full">
        <div
          onClick={() => {
            if (
              details?.name &&
              details?.email &&
              details?.phoneNumber &&
              details?.phoneNumber?.length == 10
            ) {
              onNext();
            }
          }}
          className={`${
            details?.name &&
            details?.email &&
            details?.phoneNumber &&
            details?.phoneNumber?.length == 10
              ? "bg-black"
              : "bg-primary-neutral-400"
          } w-full nextButton flex flex-row items-center justify-center space-x-2 rounded-full px-8 py-4 cursor-pointer text-primary-gray-100 font-lato text-base font-bold leading-6`}
        >
          <p>Next</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default Personal;
