import Option from "./Option";

export default function Question({
  number,
  category,
  subtext,
  options,
  selected,
  setSelect,
  selectedSub,
  setSelectedSub,
  getSelectedCount,
}) {
  return (
    <div className="flex space-y-10 flex-col w-full">
      <div className="flex flex-col w-full items-center space-y-1">
        <h2 className="w-full text-[#0497AE] text-left font-lato text-lg font-semibold leading-7 tracking-[0.72px] capitalize">
          Goals for {category}
        </h2>
        <p className="text-primary-neutral-500 text-left font-satoshi text-xs leading-5 tracking-[0.48px]">
          {subtext}
        </p>
      </div>
      <div className="flex flex-col space-y-4">
        {options?.map((item, idx) => {
          return (
            <Option
              {...item}
              idx={idx}
              number={number}
              key={number + idx}
              selected={selected}
              setSelect={setSelect}
              selectedSub={selectedSub}
              setSelectedSub={setSelectedSub}
              getSelectedCount={getSelectedCount}
            />
          );
        })}
      </div>
    </div>
  );
}
