import { useMediaQuery, useTheme } from "@mui/material";
import LaptopForm from "components/PhaseTwoForm/LaptopForm";
import MobileForm from "components/PhaseTwoForm/MobileForm";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const PhaseTwoPage = () => {
  const theme = useTheme();
  const largeView = useMediaQuery(theme.breakpoints.up("lg"));
  const { step } = useSelector((state) => state.profileForm.phaseTwo);

  useEffect(() => {
    window.scrollTo({ top: "0px", behavior: "smooth" });
  }, [step]);

  return <div>{largeView ? <LaptopForm /> : <MobileForm />}</div>;
};

export default PhaseTwoPage;
