import { createSlice } from "@reduxjs/toolkit";
import { getRoles, getValueConstructs } from "config/APIs/life";
import { getUserServices } from "config/APIs/users";

const initialState = {
  roles: [],
  valueConstructs: [],
  userServices: [],
};

// Storing life
export const life = createSlice({
  name: "life",
  initialState,
  reducers: {
    updateRoles: (state, action) => {
      state.roles = action.payload.roles;
    },
    updateValueConstructs: (state, action) => {
      state.valueConstructs = action.payload;
    },
    updateUserServices: (state, action) => {
      state.userServices = action.payload.services;
    },
  },
});

export const { updateRoles, updateValueConstructs, updateUserServices } =
  life.actions;

export default life.reducer;

export const fetchAllRoles = () => {
  return async (dispatch) => {
    try {
      const response = await getRoles();
      if (response.status === 200) {
        let data = response.data?.data;
        // Dispatching life data to redux-store
        dispatch(
          updateRoles({
            roles: data,
          })
        );
      }
    } catch (err) {
      console.log("life fetching error", err);
    }
  };
};

export const fetchAllValueConstructs = () => {
  return async (dispatch) => {
    try {
      const response = await getValueConstructs();
      if (response.status === 200) {
        let data = response.data?.data;
        // Dispatching life data to redux-store
        dispatch(updateValueConstructs(data));
      }
    } catch (err) {
      console.log("life fetching error", err);
    }
  };
};

export const fetchAllUserServices = () => {
  return async (dispatch) => {
    try {
      const response = await getUserServices();
      if (response.status === 200) {
        let data = response.data?.data;
        // Dispatching life data to redux-store
        dispatch(
          updateUserServices({
            services: data,
          })
        );
      }
    } catch (err) {
      console.log("life fetching error", err);
    }
  };
};
