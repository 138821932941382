import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { useState } from "react";
import Variation from "./Variation";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " bg-white bg-opacity-20 w-8 lg:w-12 h-8 lg:h-12"}
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",

        borderRadius: "50%",
        border: "1px solid #fff",
        marginRight: "-24px",
      }}
      onClick={onClick}
    >
      <ArrowRight size={window.innerWidth ? 20 : 24} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " bg-white bg-opacity-20 w-8 lg:w-12 h-8 lg:h-12"}
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",

        borderRadius: "50%",
        border: "1px solid #fff",
        marginLeft: "-24px",
      }}
      onClick={onClick}
    >
      <ArrowLeft size={window.innerWidth ? 20 : 24} />
    </div>
  );
}
function Topics({ list, task }) {
  const [open, setOpen] = useState();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Variation
        isOpen={open ? true : false}
        closeModal={() => setOpen()}
        item={open}
        task={task}
        list={list}
      />
      <div className="max-w-7xl max-auto w-full px-6">
        <Slider {...settings}>
          {list?.map((item) => {
            return (
              <div className="px-4">
                <div
                  onClick={() => {
                    setOpen(item);
                  }}
                  className="relative flex flex-col items-stretch bg-white rounded-lg"
                >
                  <img
                    src={item?.image}
                    alt=""
                    className="w-full aspect-video object-cover rounded-t-lg"
                  />
                  <h1 className="bg-white  bg-opacity-80 px-2 py-1 rounded capitalize text-primary-neutral-800 font-inter absolute top-2 left-2 text-xs font-light leading-5 tracking-[1.8px]">
                    {item?.name}
                  </h1>
                  <div className="p-4 flex flex-col items-stretch space-y-3">
                    <p className="text-black h-28 font-inter text-sm lg:text-base font-semibold leading-6">
                      {item?.description}
                    </p>
                    <div className="w-full text-primary-neutral-800 font-inter text-sm font-semibold underline cursor-pointer flex flex-row items-center justify-end">
                      <p>See Details</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default Topics;
