import { ReactComponent as DigitalTransformation } from "assets/svg/digital_transformation.svg";
import { ReactComponent as Branding } from "assets/svg/branding.svg";
// import { ReactComponent as BusinessDevelopment } from "assets/svg/business_development.svg";
// import { ReactComponent as TalentManagement } from "assets/svg/talent_management.svg";
import { ReactComponent as Marketing } from "assets/svg/marketing.svg";
import { ReactComponent as Step4one } from "assets/svg/step4_1.svg";
import { ReactComponent as Step4two } from "assets/svg/step4_2.svg";
import { ReactComponent as Academic } from "assets/svg/academic.svg";
import { ReactComponent as Work } from "assets/svg/work.svg";
import { ReactComponent as Personal } from "assets/svg/personal.svg";

export const projectAspects = {
  options: [
    {
      id: "product_and_engineering",
      title: "Product and Engineering",
      subtitle:
        "some info on what this means and what kind of roles fit within this",
      icon: (
        <DigitalTransformation className="h-20 w-20 text-primary-accent-300 fill-current" />
      ),
      selectedIcon: (
        <DigitalTransformation className="h-20 w-20 text-primary-accent-500 fill-current" />
      ),
    },
    {
      id: "design_and_branding",
      title: "Design and Branding",
      subtitle:
        "some info on what this means and what kind of roles fit within this",
      icon: (
        <Branding className="h-20 w-20 text-primary-accent-300 fill-current" />
      ),
      selectedIcon: (
        <Branding className="h-20 w-20 text-primary-accent-500 fill-current" />
      ),
    },
    {
      id: "marketing",
      title: "Marketing",
      subtitle:
        "some info on what this means and what kind of roles fit within this",
      icon: (
        <Marketing className="h-20 w-20 text-primary-accent-300 fill-current" />
      ),
      selectedIcon: (
        <Marketing className="h-20 w-20 text-primary-accent-500 fill-current" />
      ),
    },
    // {
    //   id: "business_development",
    //   title: "Business Development",
    //   subtitle:
    //     "some info on what this means and what kind of roles fit within this",
    //   icon: (
    //     <BusinessDevelopment className="h-20 w-20 text-primary-accent-300 fill-current" />
    //   ),
    //   selectedIcon: (
    //     <BusinessDevelopment className="h-20 w-20 text-primary-accent-500 fill-current" />
    //   ),
    // },
    // {
    //   id: "talent_management",
    //   title: "Talent Management",
    //   subtitle:
    //     "some info on what this means and what kind of roles fit within this",
    //   icon: (
    //     <TalentManagement className="h-20 w-20 text-primary-accent-300 fill-current" />
    //   ),
    //   selectedIcon: (
    //     <TalentManagement className="h-20 w-20 text-primary-accent-500 fill-current" />
    //   ),
    // },
  ],
};

export const achievements = {
  title: "Select all the things you did as a ",
  subtitle:
    "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al",
  options: [
    {
      icon: <Step4one className="h-[32px] w-[32px] md:h-14 md:w-14" />,
      heading: "Strategy and Ideation",
      subheading:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al",
      opts: [
        {
          id: 1,
          value: (
            <span>
              I crafted{" "}
              <strong className="bold-data">strategic design frameworks</strong>{" "}
              aligning UX with our long-term business goals through critical
              thinking.
            </span>
          ),
          text: "I crafted strategic design frameworks aligning UX with our long-term business goals through critical thinking.",
          serviceName: "User Persona Development",
        },
        {
          id: 2,
          value: (
            <span>
              I developed and maintained{" "}
              <strong className="bold-data">usability roadmaps</strong>{" "}
              integrating user feedback and market trends.
            </span>
          ),
          text: "I developed and maintained usability roadmaps integrating user feedback and market trends.",
          serviceName: "User Persona Development",
        },
        {
          id: 3,
          value: (
            <span>
              I ensured{" "}
              <strong className="bold-data">design consistency</strong> across
              all product interfaces, resonating with our brand ethos.
            </span>
          ),
          text: "I ensured design consistency across all product interfaces, resonating with our brand ethos.",
          serviceName: "User Persona Development",
        },
        {
          id: 4,
          value: (
            <span>
              I led{" "}
              <strong className="bold-data">design thinking sessions</strong>{" "}
              with cross-functional teams to embed UX principles in product
              strategy.
            </span>
          ),
          text: "I led design thinking sessions with cross-functional teams to embed UX principles in product strategy.",
          serviceName: "User Persona Development",
        },
        {
          id: 5,
          value: (
            <span>
              I created detailed{" "}
              <strong className="bold-data">user personas and scenarios</strong>{" "}
              to guide our design decisions.
            </span>
          ),
          text: "I created detailed user personas and scenarios to guide our design decisions.",
          serviceName: "User Persona Development",
        },
      ],
    },
    {
      icon: <Step4two className="h-[32px] w-[32px] md:h-14 md:w-14" />,
      heading: "Research & Documentation",
      subheading:
        "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al",
      opts: [
        {
          id: 1,
          value: (
            <span>
              I conducted{" "}
              <strong className="bold-data">in-depth user research</strong> to
              inform our design decisions.
            </span>
          ),
          text: "I conducted in-depth user research to inform our design decisions.",
          serviceName: "User Persona Development",
        },
        {
          id: 2,
          value: (
            <span>
              I produced{" "}
              <strong className="bold-data">
                comprehensive design documentation
              </strong>
              , capturing user flows, wireframes, and specifications.
            </span>
          ),
          text: "I produced comprehensive design documentation, capturing user flows, wireframes, and specifications.",
          serviceName: "User Persona Development",
        },
        {
          id: 3,
          value: (
            <span>
              I implemented{" "}
              <strong className="bold-data">iterative design testing</strong> to
              refine usability and accessibility.
            </span>
          ),
          text: "I implemented iterative design testing to refine usability and accessibility.",
          serviceName: "User Persona Development",
        },
        {
          id: 4,
          value: (
            <span>
              I developed a <strong className="bold-data">UX strategy</strong>{" "}
              prioritizing user satisfaction and engagement.
            </span>
          ),
          text: "I developed a UX strategy prioritizing user satisfaction and engagement.",
          serviceName: "User Persona Development",
        },
        {
          id: 5,
          value: (
            <span>
              I ensured all our designs meet{" "}
              <strong className="bold-data">accessibility standards.</strong>
            </span>
          ),
          text: "I ensured all our designs meet accessibility standards.",
          serviceName: "User Persona Development",
        },
      ],
    },
  ],
};

export const moreContext = {
  title: "Give some more context about your project",
  subtitle:
    "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al",
  options: [
    {
      title: "How would you categorize this project?",
      type: "radio",
      show: "always",
      opts: [
        {
          title: "Academic",
          subtitle: "Projects done as part of an academic curriculum",
          icon: <Academic className="md:h-20 md:w-20 w-12 h-12" />,
        },
        {
          title: "Work",
          subtitle: "Projects done for an employer under contract",
          icon: <Work className="md:h-20 md:w-20 w-12 h-12" />,
        },
        {
          title: "Personal",
          subtitle: "Projects done out of self interest",
          icon: <Personal className="md:h-20 md:w-20 w-12 h-12" />,
        },
      ],
    },
    {
      title: "Please specify the course you were pursuing at the time",
      type: "text",
      label: "Course",
      show: "Academic",
    },
    {
      title: "Please specify the company you were working for at the time",
      type: "text",
      label: "Company Name",
      show: "Work",
    },
    {
      title: "Add additional insights about your nature of work & takeaways",
      type: "boosters",
    },
    {
      title: "Please give a small description of your project?",
      type: "textarea",
      label: "Description",
      show: "always",
    },
  ],
};

export const finalStep = {
  title: "preview achievement cards",
  subtitle: "Preview your achievement cards give them completion dates",
};
