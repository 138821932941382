import { useDispatch, useSelector } from "react-redux";

import { updateAns } from "redux/typeform";
import Wrapper from "./Wrapper";
import { nextStep, prevStep } from "redux/typeform";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
const activeSectionVariant = {
  active: {
    opacity: 1,
    // y: "0",
    transition: {
      duration: 0.3,
    },
  },
  inactive: {
    opacity: 0,
    // y: "-100%",
    transition: {
      duration: 0.3,
    },
  },
};
const Scale = ({
  id,
  question,
  description,
  subtitle,
  scores,
  left,
  right,
}) => {
  const [loaded, setLoaded] = useState(false);
  const { details, currentSection } = useSelector((state) => state.typeForm);
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(
      updateAns({
        id: id,
        value,
        section: currentSection,
        score: scores[parseInt(value) - 1],
      })
    );
    if (value !== null) {
      setTimeout(() => {
        setLoaded(false);
      }, 300);
      setTimeout(() => {
        dispatch(nextStep({ section: true }));
      }, 800);
    }
  };
  const handleDeSelect = () => {
    dispatch(
      updateAns({
        id: id,
        value: null,
        section: currentSection,
      })
    );
  };
  const getChecked = (id) => {
    return details?.sectionMetaData?.basicForm[currentSection]?.find(
      (item) => item.id === id
    );
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, [id]);
  return (
    <>
      <Wrapper
        onNextClick={() => {
          dispatch(nextStep({ section: true }));
        }}
        onBackClick={() => dispatch(prevStep({ section: true }))}
        disabled={
          getChecked(id)?.value == null || getChecked(id)?.value == undefined
        }
      >
        <button id="vvvv" />
        <motion.div
          initial={activeSectionVariant.inactive}
          animate={
            loaded ? activeSectionVariant.active : activeSectionVariant.inactive
          }
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`hidden lg:flex flex-col w-full max-w-4xl mx-auto py-8 h-[60vh] justify-center items-center`}
        >
          {subtitle && (
            <div className="w-full capitalize flex flex-col md:flex-row md:items-center justify-start space-y-2 md:space-y-0 md:space-x-3 text-caaryaRed font-satoshi text-sm font-bold lg:tracking-[1.4px] tracking-[1.2px] lg:leading-5">
              <div className="w-5 h-5 lg:w-6 lg:h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 8C19.55 8 18.74 9.44 19.07 10.51L15.52 14.07C15.22 13.98 14.78 13.98 14.48 14.07L11.93 11.52C12.27 10.45 11.46 9 10 9C8.55 9 7.73 10.44 8.07 11.52L3.51 16.07C2.44 15.74 1 16.55 1 18C1 19.1 1.9 20 3 20C4.45 20 5.26 18.56 4.93 17.49L9.48 12.93C9.78 13.02 10.22 13.02 10.52 12.93L13.07 15.48C12.73 16.55 13.54 18 15 18C16.45 18 17.27 16.56 16.93 15.48L20.49 11.93C21.56 12.26 23 11.45 23 10C23 8.9 22.1 8 21 8Z"
                    fill="#ED4C41"
                  />
                  <path
                    d="M15 9L15.94 6.93L18 6L15.94 5.07L15 3L14.08 5.07L12 6L14.08 6.93L15 9ZM3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z"
                    fill="#ED4C41"
                  />
                </svg>
              </div>{" "}
              <p>{subtitle}</p>
            </div>
          )}
          <h1
            className={`w-full mt-5 text-black text-left font-satoshi text-xl lg:text-[28px] leading-9 tracking-[1px] lg:tracking-[2.24px] lg:leading-[50.4px]`}
          >
            {" "}
            {question}
          </h1>
          <div
            className={`mt-20 flex justify-between items-center gap-5 w-full max-w-3xl mx-auto transition-all my-6`}
          >
            <CheckBoxLg
              type={0}
              id={id}
              handleChange={handleChange}
              isChecked={getChecked}
              handleDeSelect={handleDeSelect}
              tooltip={true}
              valueName={left}
            />
            <CheckBoxMd
              type={1}
              id={id}
              handleChange={handleChange}
              handleDeSelect={handleDeSelect}
              isChecked={getChecked}
              tooltip={true}
            />
            {/* <CheckBoxSm
              type={2}
              id={id}
              handleChange={handleChange}
              isChecked={getChecked}
              tooltip={true}
            /> */}
            <CheckBoxCenter
              type={2}
              id={id}
              handleChange={handleChange}
              handleDeSelect={handleDeSelect}
              isChecked={getChecked}
              tooltip={true}
            />
            {/* <CheckBoxSm
              type={4}
              id={id}
              handleChange={handleChange}
              isChecked={getChecked}
              tooltip={true}
            /> */}
            <CheckBoxMd
              type={3}
              id={id}
              handleChange={handleChange}
              handleDeSelect={handleDeSelect}
              isChecked={getChecked}
              tooltip={true}
            />
            <CheckBoxLg
              type={4}
              id={id}
              handleChange={handleChange}
              handleDeSelect={handleDeSelect}
              isChecked={getChecked}
              valueName={right}
              tooltip={true}
            />
          </div>
        </motion.div>
        <motion.div
          initial={activeSectionVariant.inactive}
          animate={
            loaded ? activeSectionVariant.active : activeSectionVariant.inactive
          }
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`flex lg:hidden justify-start flex-col w-full max-w-3xl mx-auto py-16 px-6`}
        >
          {subtitle && (
            <div className="w-full capitalize flex flex-col md:flex-row md:items-center justify-start space-y-2 md:space-y-0 md:space-x-3 text-caaryaRed font-satoshi text-sm font-bold lg:tracking-[1.4px] tracking-[1.2px] lg:leading-5">
              <div className="w-5 h-5 lg:w-6 lg:h-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21 8C19.55 8 18.74 9.44 19.07 10.51L15.52 14.07C15.22 13.98 14.78 13.98 14.48 14.07L11.93 11.52C12.27 10.45 11.46 9 10 9C8.55 9 7.73 10.44 8.07 11.52L3.51 16.07C2.44 15.74 1 16.55 1 18C1 19.1 1.9 20 3 20C4.45 20 5.26 18.56 4.93 17.49L9.48 12.93C9.78 13.02 10.22 13.02 10.52 12.93L13.07 15.48C12.73 16.55 13.54 18 15 18C16.45 18 17.27 16.56 16.93 15.48L20.49 11.93C21.56 12.26 23 11.45 23 10C23 8.9 22.1 8 21 8Z"
                    fill="#ED4C41"
                  />
                  <path
                    d="M15 9L15.94 6.93L18 6L15.94 5.07L15 3L14.08 5.07L12 6L14.08 6.93L15 9ZM3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z"
                    fill="#ED4C41"
                  />
                </svg>
              </div>{" "}
              <p>{subtitle}</p>
            </div>
          )}
          <h1
            className={`w-full mt-2 text-black text-left font-satoshi text-xl lg:text-[28px] leading-9 lg:leading-[50.4px] font-normal`}
          >
            {question}
          </h1>
          <div
            className={`flex flex-col space-y-4 w-full max-w-[960px] mx-auto mt-10`}
          >
            <div className="flex flex-row items-center w-full justify-between">
              <CheckBoxLg
                type={0}
                id={id}
                handleChange={handleChange}
                handleDeSelect={handleDeSelect}
                isChecked={getChecked}
              />
              <CheckBoxMd
                type={1}
                id={id}
                handleChange={handleChange}
                handleDeSelect={handleDeSelect}
                isChecked={getChecked}
              />
              {/* <CheckBoxSm
                type={2}
                id={id}
                handleChange={handleChange}
                isChecked={getChecked}
              /> */}
              <CheckBoxCenter
                type={2}
                id={id}
                handleChange={handleChange}
                handleDeSelect={handleDeSelect}
                isChecked={getChecked}
              />
              {/* <CheckBoxSm
                type={4}
                id={id}
                handleChange={handleChange}
                isChecked={getChecked}
              /> */}
              <CheckBoxMd
                type={3}
                id={id}
                handleChange={handleChange}
                handleDeSelect={handleDeSelect}
                isChecked={getChecked}
              />
              <CheckBoxLg
                type={4}
                id={id}
                handleChange={handleChange}
                handleDeSelect={handleDeSelect}
                isChecked={getChecked}
              />
            </div>
            <div className="flex flex-row items-center justify-between text-primary-neutral-400 font-lato text-xs lg:text-sm font-normal">
              <p>{left}</p>
              <p>{right}</p>
            </div>
          </div>
        </motion.div>
      </Wrapper>
    </>
  );
};

export default Scale;

const options = [
  {
    value: 1,
    name: "",
    type: 1,
  },
  {
    value: 2,
    type: 2,
  },
  {
    value: 3,
    name: "Neutral",
    type: 3,
  },
  {
    value: 4,
    type: 4,
  },
  {
    value: 5,
    name: "",
    type: 5,
  },
];

export const CheckBoxParent = ({ children }) => {
  const className = `flex flex-col items-center gap-2 group relative border-caaryaRed`;
  return <div className={className}>{children}</div>;
};

export const CheckBoxLg = ({
  type,
  id,
  handleChange,
  isChecked,
  handleDeSelect,
  valueName,
  cls,
}) => {
  const checked = isChecked(id);
  const { value, name } = options[type];
  const clsx = cls ? cls : "bg-caaryaRed active border-caaryaRed";

  return (
    <>
      <CheckBoxParent>
        <div
          name={id}
          id={id + type}
          key={id + type}
          onClick={() => {
            if (checked?.value == value) handleDeSelect();
            else handleChange(value);
          }}
          className={`flex flex-row items-center font-lato text-sm lg:text-xl scale fill justify-center rounded-full w-[48px] h-[48px] lg:w-[64px] lg:h-[64px] hover:bg-primary-red-50 cursor-pointer border  ${
            checked?.value === value
              ? `font-semibold text-white ${clsx}`
              : "font-light text-black border-primary-neutral-300"
          }`}
        >
          {value}
        </div>
        <p className="text-primary-gray-800 font-inter text-sm font-light hidden lg:block">
          {valueName}
        </p>
      </CheckBoxParent>
    </>
  );
};

export const CheckBoxMd = ({
  type,
  id,
  handleDeSelect,
  handleChange,
  isChecked,
  cls,
}) => {
  const checked = isChecked(id);
  const { value } = options[type];
  const clsx = cls ? cls : "bg-caaryaRed active border-caaryaRed";

  return (
    <CheckBoxParent>
      <div
        name={id}
        key={id + type}
        id={id + type}
        onClick={() => {
          if (isChecked?.value == value) handleDeSelect();
          else handleChange(value);
        }}
        className={`flex flex-row items-center font-lato text-sm lg:text-xl scale fill justify-center rounded-full w-[38px] h-[38px] lg:w-[56px] lg:h-[56px] hover:bg-primary-red-50 cursor-pointer border  ${
          checked?.value === value
            ? `font-semibold text-white ${clsx}`
            : "font-light text-black border-primary-neutral-300"
        }`}
      >
        {value}
      </div>
      <p className="text-white font-inter text-sm font-light hidden lg:block">
        {"text"}
      </p>{" "}
    </CheckBoxParent>
  );
};

export const CheckBoxSm = ({
  type,
  id,
  name,
  handleChange,
  isChecked,
  handleDeSelect,
  tooltip = false,
}) => {
  const checked = isChecked(id);
  const { variant, value } = options[type];
  const className = `custom_radio w-[28px] h-[28px] cursor-pointer border text-${variant} border-${variant}`;
  return (
    <CheckBoxParent>
      <input
        type="radio"
        name={name}
        checked={checked?.value === value}
        onChange={() => handleChange(value)}
        className={className}
      />
    </CheckBoxParent>
  );
};

export const CheckBoxCenter = ({
  type,
  id,
  handleChange,
  isChecked,
  handleDeSelect,
  cls,
}) => {
  const checked = isChecked(id);
  const { value, name } = options[type];
  const clsx = cls ? cls : "bg-caaryaRed active border-caaryaRed";

  return (
    <CheckBoxParent>
      <div
        name={id}
        id={id + type}
        key={id + type}
        onClick={() => {
          if (isChecked?.value == value) handleDeSelect();
          else handleChange(value);
        }}
        className={`flex flex-row items-center font-lato text-sm lg:text-xl scale fill justify-center rounded-full w-[48px] h-[32px] lg:w-[62px] lg:h-[38px] hover:bg-primary-red-50 cursor-pointer border  ${
          checked?.value === value
            ? `font-semibold text-white ${clsx}`
            : "font-light text-black border-primary-neutral-300"
        }`}
      >
        {value}
      </div>
      <p className="text-primary-gray-800 font-inter text-sm font-light hidden lg:block">
        {name}
      </p>{" "}
    </CheckBoxParent>
  );
};
