import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phaseOne: {
    loading: false,
    isLoadingData: false,
    isInititalDataFetched: false,
    error: null,
    step: 0,
    details: {
      college: "",
      name: "",
      lastName: "",
      course: "",
      profileImage: "",
      collegeYear: "",
      quiz: { quizStep: 0, quizData: [] },
      valueQuiz: { quizStep: 0, quizData: [] },
      careerInterest: [],
      achievements: { draft: [], added: [] },
      dreamCompany: [],
      email: "",
      mobile: "",
    },
    isNavigationVisible: true,
    isCompleted: false,
  },
  phaseTwo: {
    step: 0,
    subStep: 0,
    quizStep: 0,
    quiz: {},
    stepCompleted: {
      step0: false,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
    },
    isCompleted: false,
  },
  phaseThree: {
    step: 0,
    currentQuestion: 0,
    details: {},
    response: [],
    isCompleted: false,
  },
};

export const profileFormSlice = createSlice({
  name: "profileForm",
  initialState,
  reducers: {
    createProfileSuccess(state) {
      state.phaseOne.loading = false;
    },
    fetchProfileDataSuccess(state) {
      state.phaseOne.isLoadingData = false;
      state.phaseOne.isInititalDataFetched = true;
    },
    fetchProfileDataStart(state) {
      state.phaseOne.isLoadingData = true;
    },
    fetchProfileDataFail(state, action) {
      state.phaseOne.isLoadingData = true;
      state.phaseOne.error = action.payload;
    },
    createProfileFailure(state, action) {
      state.phaseOne.loading = false;
      state.phaseOne.error = action.payload;
    },
    createProfileStart(state) {
      state.phaseOne.loading = true;
    },
    updateStep: (state, action) => {
      const { payload } = action;
      state.phaseOne.step = payload ?? 0;
    },
    updateQuizStep: (state, action) => {
      const { payload } = action;
      state.phaseOne.details.quiz.quizStep = payload;
    },
    updateValueQuizStep: (state, action) => {
      const { payload } = action;
      state.phaseOne.details.valueQuiz.quizStep = payload;
    },
    updateDetails: (state, action) => {
      const { payload } = action;
      const { field, value } = payload;

      state.phaseOne.details[field] = value;
    },

    showNavigation: (state) => {
      state.phaseOne.isNavigationVisible = true;
    },
    hideNavigation: (state) => {
      state.phaseOne.isNavigationVisible = false;
    },
    updateStepPhaseTwo: (state, action) => {
      const { payload } = action;
      state.phaseTwo.step = payload ?? 0;
    },
    updateSubStepPhaseTwo: (state, action) => {
      const { payload } = action;
      state.phaseTwo.subStep = payload ?? 0;
    },
    updateQuizStepPhaseTwo: (state, action) => {
      const { payload } = action;
      state.phaseTwo.quizStep = payload ?? 0;
    },
    updatePhaseTwoQuiz: (state, action) => {
      const { payload } = action;
      state.phaseTwo.quiz = payload;
    },

    updateStepCompletion: (state, action) => {
      const { field } = action.payload;
      state.phaseTwo.stepCompleted[field] = true;
    },

    updateStepPhaseThree: (state, action) => {
      const { payload } = action;
      state.phaseThree.step = payload ?? 0;
    },
    updatePhaseThreeDetails: (state, action) => {
      const { payload } = action;
      state.phaseThree.details = payload ?? {};
    },
    updatePhaseThreeResponse: (state, action) => {
      const { payload } = action;
      // If response is already present for a question, update it
      const index = state.phaseThree.response.findIndex(
        (item) => item.questionId === payload.questionId
      );
      if (index !== -1) {
        state.phaseThree.response[index] = payload;
        return;
      }
      state.phaseThree.response = [...state.phaseThree.response, payload];
    },
    updatePhaseThreeCurrentQuestion: (state, action) => {
      const { payload } = action;
      state.phaseThree.currentQuestion = payload ?? 0;
      state.phaseThree.step = Math.floor(
        (state.phaseThree.response.length /
          state.phaseThree.details.questions.length) *
          100
      );
    },
    completePhaseOne: (state) => {
      state.phaseOne.isCompleted = true;
    },
    completePhaseTwo: (state) => {
      state.phaseTwo.isCompleted = true;
    },
    completePhaseThree: (state) => {
      state.phaseThree.isCompleted = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  createProfileFailure,
  createProfileStart,
  createProfileSuccess,
  updateStep,
  updateQuizStep,
  updateValueQuizStep,
  updateDetails,
  showNavigation,
  hideNavigation,
  updateStepCompletion,
  updateQuizStepPhaseTwo,
  updateStepPhaseTwo,
  updateSubStepPhaseTwo,
  updatePhaseTwoQuiz,
  updateStepPhaseThree,
  updatePhaseThreeDetails,
  updatePhaseThreeResponse,
  updatePhaseThreeCurrentQuestion,
  completePhaseOne,
  completePhaseTwo,
  completePhaseThree,
  fetchProfileDataSuccess,
  fetchProfileDataFail,
  fetchProfileDataStart,
} = profileFormSlice.actions;

export default profileFormSlice.reducer;
