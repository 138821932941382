import MultilineTextInput from "../inputs/MultilineTextInput";
import TextInput from "../inputs/TextInput";
import {
  appliedKnowledgeProps,
  executionProps,
  knowledgeTags,
  obstacleProps,
  obstacleTags,
} from "./tags";

const ExecutionObstacleTab = ({ input, setInput, type }) => {
  return (
    <div className="flex flex-col gap-10  w-full h-full ">
      <h1 className="text-primary-blue-500 text-lg md:text-xl font-manrope ">
        Execution & Obstacles
      </h1>
      <div className="flex flex-col gap-8 h-[55vh] overflow-y-scroll w-full max-w-3xl scrollbar-hidden pb-8">
        <MultilineTextInput
          input={input}
          setInput={setInput}
          type={type}
          {...executionProps}
        />
        <MultilineTextInput
          input={input}
          setInput={setInput}
          iconType={"sparkles"}
          type={type}
          {...appliedKnowledgeProps}
        />
        <TextInput
          input={input}
          setInput={setInput}
          iconType={"sparkles"}
          type={type}
          {...obstacleProps}
        />
      </div>
    </div>
  );
};

export default ExecutionObstacleTab;
