import GoogleSignInButton from "components/Auth/GoogleSignIn";
import PublicNavbar from "components/Common/Navigation/PublicNavbar";
import { useHistory } from "react-router-dom";

function GoogleSignIn() {
  const history = useHistory();
  return (
    <section
      className={`bg-white lg:bg-primary-orange relative min-h-[90vh] max-w-[100vw] overflow-y-auto lg:pb-10`}
    >
      <PublicNavbar />
      <div className="mt-16 z-10 block relative max-w-[1440px] mx-auto">
        <img
          src="/assets/images/person1.png"
          alt=""
          className="w-[120px] h-[120px] rounded-full object-cover hidden lg:block absolute top-[120px] left-56"
        />
        <img
          src="/assets/images/person2.png"
          alt=""
          className="w-20 h-20 rounded-full object-cover hidden lg:block absolute top-[120px] right-60"
        />
        <img
          src="/assets/images/person3.png"
          alt=""
          className="w-[120px] h-[120px] rounded-full object-cover hidden lg:block absolute top-6 right-10"
        />
        <img
          src="/assets/images/person4.png"
          alt=""
          className="w-[56] h-[56] rounded-full object-cover hidden lg:block absolute top-6 left-10"
        />
        <div className="py-10 hidden lg:flex flex-col items-center space-y-4">
          <p className="text-white text-base font-inter font-semibold">
            What You Aspire, You become
          </p>
          <h1 className="text-white text-[40px] font-poppins font-semibold">
            Your Mission. Our Vision
          </h1>
          <button
            onClick={() => history.push("/life")}
            className="rounded-full bg-white px-8 py-4 text-primary-orange-450 font-inter text-sm font-bold"
          >
            Learn More About Us
          </button>
        </div>
        <section className="bg-transparent max-w-4xl mx-auto min-h-[40vh] lg:bg-white py-10 px-4 lg:px-10 flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-10 lg:rounded-2xl lg:shadow-lg">
          <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start space-y-1 lg:space-y-4">
            <h2 className="text-black font-poppins text-2xl font-semibold text-center lg:text-clip lg:text-[32px]">
              Welcome to your
            </h2>
            <h1 className="text-primary-orange font-poppins font-semibold text-center lg:text-left lg:text-[56px] text-[40px]">
              caarya life
            </h1>
          </div>

          <div className="w-full lg:w-1/2 p-2 lg:px-4 space-y-8 flex flex-col items-center lg:items-start">
            <h1 className="text-primary-gray-800 font-poppins text-lg lg:text-xl font-semibold text-center lg:text-left">
              Sync your Caarya account with Google
            </h1>

            <GoogleSignInButton />
          </div>
        </section>
      </div>
      <div
        style={{
          backgroundImage: `url(/assets/images/loginOrangeBG.svg)`,
          backgroundSize: "cover",
        }}
        className="py-10 px-5 lg:hidden flex flex-col items-center space-y-4 z-10 relative"
      >
        <p className="text-white text-base font-inter font-semibold mt-4">
          What You Aspire, You become
        </p>
        <h1 className="text-white text-2xl lg:text-[40px] font-poppins font-semibold">
          Your Mission. Our Vision
        </h1>
        <button
          onClick={() => history.push("/life")}
          className="rounded-full bg-white px-6 lg:px-8 py-3 lg:py-4 text-primary-orange-450 font-inter text-sm font-bold"
        >
          Learn More About Us
        </button>
      </div>
      <div className="fixed bottom-0 right-0 left-0 hidden lg:block">
        <img
          src="/assets/images/loginWhiteBG.svg"
          alt=""
          className="w-full object-cover"
        />
      </div>{" "}
    </section>
  );
}

export default GoogleSignIn;
