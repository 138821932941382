import InViewWrapper from "components/Animation/InViewWrapper";
import { ReactComponent as ForgeIcon } from "../../../assets/GoProPage/Forge-Icon.svg";
import { ReactComponent as Logo } from "../../../assets/GoProPage/Polygon.svg";
import CaaryaMe from "../../../assets/GoProPage/caarya-ME.png";
import HQ from "../../../assets/GoProPage/hq.png";
import { ReactComponent as Navlogo } from "../../../assets/GoProPage/nav-logo.svg";
import sec2 from "../../../assets/GoProPage/sec-2.png";
import sec3 from "../../../assets/GoProPage/sec-3.png";
import sec4 from "../../../assets/GoProPage/sec-4.png";
import "../../../assets/styles/css/index.css";

const FirstTab = ({ step, setStep }) => {
  return (
    <div className="flex flex-col items-center -z-30 gap-4">
      <div className="lg:px-40 px-8 font-inter gap-4">
        <InViewWrapper classes="w-full pt-12 pb-6 text-2xl">
          <h1 className="max-sm:flex justify-around items-center font-poppins">
            The Go Pro Student Life
          </h1>
        </InViewWrapper>

        <InViewWrapper delay="0.1">
          <div className="flex justify-between w-full gap-14 py-4 min-[900px]:pt-6 min-[900px]:py-10">
            <div className="flex flex-col lg:pr-8 w-full">
              <div className="flex justify-around items-center w-full min-[900px]:hidden mb-8">
                <img
                  src={HQ}
                  className="object-cover w-60 h-60 object-contain my-8"
                />
              </div>
              <div className="mb-4">
                <Logo />
              </div>
              <div className="mb-4 text-[#CE5511] text-xl lg:text-2xl font-poppins">
                Introducing Virtual Office Spaces for Students
              </div>
              <div className="mb-8 text-base">
                Join our <span className="font-bold">CREW</span>: Caarya
                Regional Experiential Workplace
              </div>
              <div className="text-sm">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
              </div>
            </div>
            <img
              src={HQ}
              className=" object-cover w-60 h-60 object-contain my-8 max-[900px]:hidden"
            />
          </div>
        </InViewWrapper>

        <InViewWrapper>
          <div className="flex justify-between w-full items-center gap-14 lg:py-4 min-[900px]:py-10">
            <div className="flex flex-col lg:w-4/5 mr-0">
              <div className="flex justify-around items-center w-full min-[900px]:hidden mb-8">
                <img
                  src={sec2}
                  className="object-cover w-90 h-90 object-contain"
                />
              </div>
              <div className="mb-4">
                <Logo />
              </div>
              <div className="mb-4 text-[#CE5511] text-xl lg:text-2xl font-poppins">
                Get access to your own personal remote suite of productivity
              </div>
              <div className="mb-8 text-base">
                A digitized solution for the remote student pro
              </div>
              <div className="text-sm flex flex-col space-y-12 w-full">
                <div className="flex flex-row space-x-4">
                  <div className="pb-4">
                    <Navlogo />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <h2 className="font-bold text-sm">
                      Manage your daily schedules with our LIFE calendar
                    </h2>
                    <h2>A few lines here about what the calendar is is</h2>
                    <h2>Learn More</h2>
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <img src={CaaryaMe} className="object-cover pb-4" />
                  <div className="flex flex-col space-y-2">
                    <h2 className="font-bold text-sm">
                      Manage your daily schedules with our LIFE calendar
                    </h2>
                    <h2>A few lines here about what the calendar is is</h2>
                    <h2>Learn More</h2>
                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="pb-4">
                    <ForgeIcon />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <h2 className="font-bold text-sm">
                      Get work done in our Forge
                    </h2>
                    <h2>A few lines here about what Forge is</h2>
                    <h2>Learn More</h2>
                  </div>
                </div>
              </div>
            </div>

            <img
              src={sec2}
              className="object-cover w-90 h-90 object-contain my-8 max-[900px]:hidden"
            />
          </div>
        </InViewWrapper>

        <InViewWrapper>
          <div className="flex justify-between w-full gap-14 py-4 min-[900px]:py-10">
            <div className="flex flex-col lg:pr-8 w-full">
              <div className="flex justify-around items-center w-full min-[900px]:hidden mb-8">
                <img
                  src={sec3}
                  className="object-cover w-[280px] h-[280px] object-contain"
                />
              </div>
              <div className="mb-4">
                <Logo />
              </div>
              <div className="mb-4 text-[#CE5511] text-xl lg:text-2xl font-poppins">
                Earn student work contracts to beef up your resume and bank
                balance
              </div>
              <div className="mb-8 text-base">Subtitle to come here</div>
              <div className="text-sm">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
              </div>
            </div>

            <img
              src={sec3}
              className="object-cover w-[280px] h-[280px] object-contain my-8 max-[900px]:hidden"
            />
          </div>
        </InViewWrapper>

        <InViewWrapper>
          <div className="flex justify-between w-full gap-14 py-4 min-[900px]:py-10">
            <div className="flex flex-col lg:pr-8 w-full">
              <div className="flex justify-around items-center w-full min-[900px]:hidden mb-8">
                <img
                  src={sec4}
                  className="object-cover w-[280px] h-[280px] object-contain "
                />
              </div>
              <div className="mb-4">
                <Logo />
              </div>
              <div className="mb-4 text-[#CE5511] text-xl lg:text-2xl font-poppins">
                Avail personalized services catering to your branding, identity
                building and career growth
              </div>
              <div className="mb-8 text-base">
                A digitized solution for the remote student pro
              </div>
              <div className="text-sm">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
              </div>
            </div>

            <img
              src={sec4}
              className="object-cover w-[280px] h-[280px] object-contain max-[900px]:hidden rounded-ful"
            />
          </div>
        </InViewWrapper>

        <InViewWrapper>
          <div className="px-4 py-10 bg-[#FFF1EB] text-center w-full">
            <div>
              <h1 className="font-medium leading-8 text-base mb-4 capitalize font-poppins">
                To learn about our offerings in more detail, attend our Virtual
                orientation
              </h1>
            </div>
            <div className="font-light leading-6 text-sm mb-4">
              <h1>Upcoming Orientation Scheduled in</h1>
            </div>
            <div>
              <h1 className="leading-6 fond-bold text-xl text-[#CE5511] mb-5 font-poppins">
                2 Days
              </h1>
            </div>
            <div className="font-light text-sm mb-4">
              <h1>01:00 P.M., 20th August, 2023</h1>
            </div>
            <div>
              <button className="py-4 px-8 button-border w-fit mb-4">
                <span className="button-text w-fit text-sm">Register Now</span>
              </button>
            </div>
          </div>
        </InViewWrapper>
      </div>

      <div className="py-10">
        <button
          onClick={() => setStep(step + 1)}
          className="py-4 px-8 button-back-outer rounded-2xl w-fit lg:mb-12 bg-gradient-to-r from-primary-orange-button1 to-primary-orange-button2"
        >
          <span className="text-white font-bold w-fit text-sm">Next</span>
        </button>
      </div>
    </div>
  );
};

export default FirstTab;
