// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCv3skhF34EUA-MM3u5ZOXheccY94IW2zw",
//   authDomain: "caarya-life.firebaseapp.com",
//   projectId: "caarya-life",
//   storageBucket: "caarya-life.appspot.com",
//   messagingSenderId: "25375786661",
//   appId: "1:25375786661:web:6b09b17dc19f6d91eaef4c",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

import firebase from "firebase";
// Configuration for lego leaders (ADD)

const environment = process.env.REACT_APP_ENV || "development";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const devFirebaseConfig = {
  apiKey: "AIzaSyBSjU-Y7j3d3SJ0tQwDL26xLftVazeUXjA",
  authDomain: "chronos-react.firebaseapp.com",
  projectId: "chronos-react",
  storageBucket: "chronos-react.appspot.com",
  messagingSenderId: "217691302099",
  appId: "1:217691302099:web:cfe6a1772d46708cbdcc26",
  measurementId: "G-Z9VM37BE3Y",
};

// Prod Firebase Config
const prodFirebaseConfig = {
  apiKey: "AIzaSyChZ092eyqvnVkGLYKX9LzzA4M_dLEwsb8",
  authDomain: "chronos-app-prod.firebaseapp.com",
  projectId: "chronos-app-prod",
  storageBucket: "chronos-app-prod.appspot.com",
  messagingSenderId: "510369198881",
  appId: "1:510369198881:web:1b537e2300da19e3388460",
};

const getFirebaseConfig = () => {
  switch (environment) {
    case "local":
    //   return devFirebaseConfig;
    // case "development":
    // Commented because dev BE is using Prod firebase config
    // return devFirebaseConfig;
    case "production":
      return prodFirebaseConfig;
    default:
      return prodFirebaseConfig;
  }
};

// Initialize Firebase
firebase.initializeApp(getFirebaseConfig());
firebase.analytics();

export default firebase;
