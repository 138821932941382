import { lifeInstance } from "config";
import { getHeader } from "config";

export const startSession = (body) =>
  lifeInstance.post(`/sessions/start`, body, getHeader());

export const endSession = (body) =>
  lifeInstance.put(`/sessions/end`, body, getHeader());

export const extendSession = (body) =>
  lifeInstance.post("/sessions/update", body, getHeader());
