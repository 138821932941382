import { MdArrowBack } from "react-icons/md";
import { IoReader } from "react-icons/io5";
import { MdPlayCircleFilled } from "react-icons/md";
import {
  RiBookmarkLine,
  RiHeadphoneFill,
  RiPlayMiniFill,
} from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import { BsEye, BsStars } from "react-icons/bs";
import { TiThumbsDown, TiThumbsUp } from "react-icons/ti";
import { FiShare2 } from "react-icons/fi";
import { IoIosPlayCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";

export default function NewLifeCardPage() {
  const history = useHistory();
  return (
    <section className="pt-[52.5px] pb-20 lg:pt-5 relative overflow-y-auto max-h-[95vh] bg-white">
      <div
        className="h-[300px]"
        style={{
          background:
            "url('/assets/images/newLifeCardPage.png') lightgray 50% / cover no-repeat",
        }}
      >
        <div className="flex justify-between items-center py-2 px-3 absolute w-full">
          <div
            className="flex p-2 items-center gap-2 bg-black/20 rounded-full text-white cursor-pointer"
            onClick={() => history.goBack()}
          >
            <MdArrowBack size={20} />
          </div>
          <div className="flex px-4 py-2 items-center justify-center rounded-full bg-black/50 backdrop-blur-[10px]">
            <span className="font-satoshi text-xs font-bold capitalize text-white">
              For a Sense of Purpose
            </span>
          </div>
        </div>
      </div>
      <div className="px-4 mt-4 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <p className="text-primary-neutral-800 font-lato font-bold capitalize text-left">
            Identifying Personal Values
          </p>
          <div className="flex items-center justify-between">
            <div className="flex items-center font-satoshi text-primary-neutral-500 text-xs font-bold">
              <div className="flex items-center gap-1">
                <MdPlayCircleFilled />
                <span>2</span>
              </div>
              <div className="h-[15px] w-[15px] flex items-center justify-center">
                <GoDotFill size={8} />
              </div>
              <div className="flex items-center gap-1">
                <RiHeadphoneFill />
                <span>2</span>
              </div>
              <div className="h-[15px] w-[15px] flex items-center justify-center">
                <GoDotFill size={8} />
              </div>
              <div className="flex items-center gap-1">
                <IoReader />
                <span>2</span>
              </div>
            </div>
            <div className="flex p-2 items-center justify-center rounded-full gap-2 ">
              <BsEye className="text-[#903795] h-[14px] w-[14px]" />
              <span className="font-satoshi text-xs font-normal capitalize text-[#903795]">
                200+ Views
              </span>
            </div>
          </div>
        </div>

        <button className="flex items-center justify-center gap-2 rounded-2xl bg-black p-4">
          <RiPlayMiniFill className="text-white" size={20} />
          <span className="text-white font-satoshi text-sm font-bold">
            Start
          </span>
        </button>

        <div className="flex flex-wrap gap-2">
          <span className="font-satoshi text-xs text-primary-neutral-800 font-normal">
            #personal motivations
          </span>
          <span className="font-satoshi text-xs text-primary-neutral-800 font-normal">
            #personal motivations
          </span>
          <span className="font-satoshi text-xs text-primary-neutral-800 font-normal">
            #personal motivations
          </span>
        </div>

        <div className="w-full px-4 py-3 bg-[#FFF2F0] rounded-lg flex items-center gap-2">
          <BsStars color="#FF7E6E" />
          <p className="text-caaryaRed text-[12px] font-medium font-satoshi leading-[18px] tracking-wide ">
            You are viewing content personalized for “User’s” career
          </p>
        </div>

        <div className="flex py-2 justify-between items-center">
          <button className="flex py-[14px] flex-1 px-6 capitalize justify-center items-center gap-2 rounded-2xl border border-primary-neutral-800 font-satoshi text-xs font-bold text-primary-neutral-800 ">
            personalize for My Career
          </button>

          <div className="flex px-4 gap-2 py-2 justify-center items-center cursor-pointer">
            <FiShare2 size={16} />
            <span className="text-primary-neutral-800 font-bold text-xs capitalize">
              Share
            </span>
          </div>
        </div>

        <div className="flex py-2 justify-between items-center">
          <div className="flex flex-col gap-2 flex-[1_0_0] items-center justify-center">
            <div className="flex items-center gap-4">
              <div className="flex p-2 items-center rounded-full border border-primary-neutral-500">
                <TiThumbsUp size={20} />
              </div>
              <div className="flex p-2 items-center rounded-full border border-primary-neutral-500">
                <TiThumbsDown size={20} />
              </div>
            </div>
            <p className="font-satoshi text-xs font-normal text-primary-neutral-800 capitalize tracking-[0.8px]">
              Rate
            </p>
          </div>

          <div className="flex flex-col gap-2 flex-[1_0_0] items-center justify-center">
            <div className="flex items-center gap-4">
              <div className="flex p-2 items-center rounded-full border border-primary-neutral-500">
                <FiShare2 size={20} />
              </div>
            </div>
            <p className="font-satoshi text-xs font-normal text-primary-neutral-800 capitalize tracking-[0.8px]">
              Share
            </p>
          </div>

          <div className="flex flex-col gap-2 flex-[1_0_0] items-center justify-center">
            <div className="flex items-center gap-4">
              <div className="flex p-2 items-center rounded-full border border-primary-neutral-500">
                <RiBookmarkLine size={20} />
              </div>
            </div>
            <p className="font-satoshi text-xs font-normal text-primary-neutral-800 capitalize tracking-[0.8px]">
              save to space
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 mt-10">
        <div className="border-b border-b-primary-neutral-200">
          <p className="py-2 px-4 text-primary-neutral-800 text-sm font-bold tracking-[0.56px]">
            All Content
          </p>
        </div>
        <div className="px-4 flex flex-col gap-6">
          <Content
            hasStarted
            icon={<IoIosPlayCircle size={32} />}
            title="How to discover your core values"
            duration="2 min 15 sec"
            progress={60}
          />
          <div className="h-0 5 rounded-full bg-primary-neutral-100"></div>
          <Content
            hasStarted
            icon={<RiHeadphoneFill size={32} />}
            title="The role of values in career choices"
            duration="2 min 15 sec"
            progress={60}
          />
          <div className="h-0 5 rounded-full bg-primary-neutral-100"></div>

          <Content
            icon={<IoReader size={32} />}
            title="Exercises to uncover personal values"
            duration="2 min 15 sec"
          />
          <div className="h-0 5 rounded-full bg-primary-neutral-100"></div>

          <Content
            icon={<IoIosPlayCircle size={32} />}
            title="Any mechanical keyboard enthusiasts in design?"
            duration="2 min 15 sec"
          />
          <div className="h-0 5 rounded-full bg-primary-neutral-100"></div>

          <Content
            icon={<IoReader size={32} />}
            title="How to design a product that can grow itself 10x in year"
            duration="2 min 15 sec"
          />
        </div>
      </div>
    </section>
  );
}

const Content = ({ icon, title, duration, progress, hasStarted }) => {
  return (
    <div className="flex gap-4">
      <div
        className={`flex w-14 h-14 rounded-2xl ${
          hasStarted ? "bg-[#903795] text-white" : "bg-[#F9E3FC] text-[#B557BA]"
        } items-center justify-center`}
      >
        {icon}
      </div>
      <div className="flex flex-col items-start gap-2 flex-[1_0_0]">
        <p className="font-satoshi text-primary-neutral-800 text-sm font-bold capitalize tracking-[0.56px]">
          {title}
        </p>
        <p className="font-satoshi text-primary-neutral-500 text-xs font-normal capitalize tracking-[0.48px]">
          {duration}
        </p>
        {hasStarted && (
          <div className="flex h-1 flex-[1_0_0] bg-primary-neutral-200 rounded-full w-full relative">
            <div
              className="absolute h-full bg-[#2BB656]"
              style={{
                width: `${progress}%`,
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
