import { useEffect, useRef, useState } from "react";
import { BsTextLeft } from "react-icons/bs";
import { HiSparkles } from "react-icons/hi";

const TextInput = ({
  input,
  setInput,
  field,
  tags = [],
  iconType = "text",
  title,
  type,
  placeholder = "",
  subtitle = "",
  example,
  isTitle = false,
}) => {
  const inputComponentRef = useRef(null);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputComponentRef.current &&
        !inputComponentRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputComponentRef]);

  const showTag = input[field] === "" || tags.includes(input[field].trim());

  const handleTagClick = (tag) => {
    setInput({ ...input, [field]: tag });
    inputRef.current.focus();
  };

  return (
    <div
      id="hover_input"
      ref={inputComponentRef}
      onClick={() => type !== "VIEW" && setIsFocused(true)}
      className={`flex flex-col ${
        isFocused ? "gap-8" : "gap-4"
      } items-start w-full`}
    >
      <div className="flex gap-4 items-center w-full">
        {iconType === "text" ? (
          <div className="border rounded-sm border-primary-blue-500 ">
            <BsTextLeft className="text-primary-blue-500 font-bold" />
          </div>
        ) : (
          <HiSparkles className="text-primary-blue-300 font-bold mt-1 text-lg" />
        )}
        <h2 className="font-manrope text-md lg:text-base text-primary-grey-800 font-medium">
          {title}
        </h2>
      </div>
      <>
        {isFocused ? (
          <div className="flex flex-col gap-2 w-full  lg:pl-9 ">
            <h4 className="font-satoshi text-md text-primary-grey-800 ">
              {subtitle}
            </h4>

            <textarea
              type="text"
              ref={inputRef}
              value={input[field]}
              onChange={(e) => setInput({ ...input, [field]: e.target.value })}
              placeholder={placeholder}
              className={` ${
                isFocused
                  ? "border border-primary-blue-300 p-4 resize-y"
                  : "border-none p-0 resize-none"
              }   leading-6 ${
                isTitle ? "text-lg leading-8" : "text-sm"
              } rounded-lg w-full  outline-none focus:outline-none focus:ring-transparent placeholder:text-primary-grey-400`}
            />

            <div className="flex flex-col gap-6">
              {example.length > 0 && (
                <div className="flex gap-2 font-satoshi text-sm ">
                  <p className="font-bold">Eg.</p>
                  <div>
                    {example.map((example, idx) => (
                      <p key={idx}>{example}</p>
                    ))}
                  </div>
                </div>
              )}

              {showTag && tags.length > 0 && (
                <div className="flex flex-col lg:flex-row gap-4">
                  <h3 className="font-satoshi font-bold text-primary-grey-500 text-xs">
                    You might start with :
                  </h3>
                  <div className="flex gap-2 flex-wrap items-center w-full max-w-md ">
                    {tags.map((_tag, idx) => (
                      <div
                        key={idx}
                        onClick={() => handleTagClick(_tag)}
                        className="p-2 py-[2px] border hover:bg-primary-blue-100 hover:text-primary-grey-600 hover:border-primary-grey-300 cursor-pointer rounded-full font-satoshi text-xs text-primary-grey-400 "
                      >
                        {_tag} ...
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <p
            id="hover_input_border"
            className={`leading-6  ${
              isTitle ? "text-lg leading-8" : "text-sm"
            }  w-full  ${
              input[field].length === 0 && "text-primary-grey-400"
            } `}
          >
            {input[field].length > 0 ? input[field] : placeholder}
          </p>
        )}
      </>
    </div>
  );
};

export default TextInput;
