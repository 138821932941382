export default function EmptyState({
  text = "No Items Found!",

  image = "/assets/images/empty/accessDenied.svg",
}) {
  return (
    <div className="flex justify-center items-center flex-col py-10 space-y-4 h-[60vh]">
      <div className="relative max-w-sm overflow-hidden">
        <img
          src={image}
          alt="empty epics"
          className="relative h-[25vh] w-full object-cover"
        />
      </div>{" "}
      <p className="text-gray-500 text-sm">{text}</p>
    </div>
  );
}
