import { useEffect, useState } from "react";
import stringSimilarity from "string-similarity";

const ContinuousSearch = ({
  searchInput,
  interest,
  setInterest,
  close,
  interestSearchList = [],
}) => {
  const [filteredSearch, setFilteredSearch] = useState(interestSearchList);

  useEffect(() => {
    if (searchInput.length >= 2)
      setFilteredSearch(
        interestSearchList.filter(
          (interest) =>
            interest.toLowerCase().includes(searchInput.toLowerCase()) ||
            stringSimilarity.compareTwoStrings(interest, searchInput) > 0.5
        )
      );
  }, [searchInput]);

  const handleOptionSelect = (val) => {
    setInterest({ ...interest, interest: val });
    close();
  };

  return (
    <div className="flex flex-col w-full text-sm border h-60 bg-white ">
      <div className="flex flex-col overflow-y-scroll scrollbar-hidden">
        {filteredSearch.map((interest, idx) => (
          <p
            onClick={() => handleOptionSelect(interest)}
            key={idx}
            className="p-2 cursor-pointer hover:bg-primary-blue-100 "
          >
            {interest}
          </p>
        ))}
      </div>
      {searchInput.length > 0 && (
        <div
          onClick={() => handleOptionSelect(searchInput)}
          className="flex gap-2 p-2 text-primary-blue-500 font-medium cursor-pointer  hover:bg-primary-blue-100  "
        >
          + suggest {` " ${searchInput} " `}
        </div>
      )}
    </div>
  );
};

export default ContinuousSearch;
