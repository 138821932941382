import { Transition } from "@headlessui/react";
import { useState, useEffect } from "react";

const opts = [
  "Crafting Your Identity...",
  "Initializing Your Knowledge Space...",
  "Personalizing your Caarya ME...",
  "Your ME Profile is Ready!",
];

export default function MeProfile() {
  const [index, setIndex] = useState(0);
  const [isShowing, setIsShowing] = useState(true);
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    if (showData) return;

    const interval = setInterval(() => {
      setIsShowing(false);
      setTimeout(() => {
        setIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % opts.length;
          if (nextIndex === opts.length - 1) {
            setShowData(true);
            return opts.length - 1;
          }
          return nextIndex;
        });
        setIsShowing(true);
      }, 500); // Match this duration with the Transition leave duration
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, [showData]);

  return (
    <section className="h-screen fixed bg-white top-0 left-0 w-full z-[100]">
      <div className="h-full flex flex-col w-full items-center justify-center gap-4">
        <img
          src="/assets/images/MeOp2.svg"
          alt=""
          className={`w-[60px] h-[60px] ${showData ? "" : "animate-pulse"}`}
        />
        <Transition
          show={isShowing}
          enter="transition-all ease duration-500"
          enterFrom="opacity-0 transform translate-y-4"
          enterTo="opacity-100 transform translate-y-0"
          leave="transition-all ease duration-500"
          leaveFrom="opacity-100 transform translate-y-0"
          leaveTo="opacity-0 transform -translate-y-4"
        >
          <p className="text-center text-primary-neutral-500 text-sm font-normal">
            {opts[index]}
          </p>
        </Transition>
        <Transition
          show={showData}
          enter="transition-all ease duration-500"
          enterFrom="opacity-0 transform translate-y-4"
          enterTo="opacity-100 transform translate-y-0 w-full"
          leave="transition-all ease duration-500"
          leaveFrom="opacity-100 transform translate-y-0 w-full"
          leaveTo="opacity-0 transform -translate-y-4"
        >
          <div className="flex flex-col items-center justify-center mt-5 w-full px-6">
            <div className="h-16 w-16 bg-primary-neutral-200 rounded-full"></div>
            <p className="text-base text-primary-neutral-800 mt-2">
              caarya.me/username
            </p>

            <div className="bg-primary-neutral-800 space-x-3 mt-12 w-full items-center flex flex-row rounded-full py-3 px-14 text-[#CFCDC9] font-satoshi text-base font-bold leading-7 tracking-[0.9px]">
              <p className="text-center w-full">View Now</p>
            </div>
          </div>
        </Transition>
      </div>
    </section>
  );
}
