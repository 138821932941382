import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function ContinueModal({
  isOpen = false,
  onContinue = () => {},
}) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="max-w-[350px] sm:max-w-[460px] w-full rounded-[24px] bg-white py-8 px-16 sm:px-24 flex flex-col items-center gap-6 ">
        <h2 className="text-primary-neutral-800 text-center capitalize font-satoshi text-2xl font-medium">
          You’re doing Great, mr. chronos!
        </h2>
        <p className="text-primary-neutral-500 text-center font-lato font-normal">
          Your Achievement Cards are almost ready. Just{" "}
          <strong className="text-black">2</strong> more steps to go.
        </p>
        <button
          className="w-max flex gap-2 py-4 pl-8 pr-6 items-center bg-primary-accent-700 rounded-2xl shadow-lg text-white"
          onClick={onContinue}
        >
          Continue <MdOutlineKeyboardArrowRight className="text-2xl" />
        </button>
      </div>
    </div>
  );
}
