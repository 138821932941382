import Shortcuts from "components/Layout/Shortcuts";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Logout from "./Logout";

const bgColor = (path) => {
  switch (path) {
    case "dashboard":
      return "linear-gradient(180deg, #FEB8B1 0%, #FFF6F5 100%)";
    case "CareerFlow":
      return "linear-gradient(180deg, #FFE99A 0%, #FDF7E9 100%)";
    case "inbox":
      return "linear-gradient(180deg, #C2E8F0 0%, #F5FBFF 100%)";
    case "sprint":
    case "careerInsights":
      return "linear-gradient(180deg, #D8D7F9 0%, #F7F7FF 100%)";
    case "rewards":
      return "linear-gradient(180deg, #F9E3FC 0%, #FDF2FF 80%, #FFF 100%)";
    case "achievements/add":
    case "achievements":
    case "achievements/projects":
      return "white";
    case "sprintInsights":
    case "boostCareer":
      return "#ffffff";
    case "myDesk":
      return "transparent";
    default:
      return "#fff";
  }
};

function TopBar({ focusZone = false }) {
  const user = useSelector((state) => state?.user?.user);
  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const onLogout = () => {
    setOpenLogoutModal(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("navList");
    localStorage.removeItem("selectedTab");
    window.location.href = window.location.origin + "/";
  };

  return (
    <>
      <div
        id="navbar"
        className="fixed w-full top-0 right-0 left-0 px-4 py-3 flex flex-row items-center justify-between z-40 lg:hidden"
        style={{
          background: bgColor(pathname.slice(1)),
        }}
      >
        <a href="/">
          <div className="flex flex-row items-center space-x-1.5">
            <img
              src="/assets/images/life.jpg"
              alt=""
              className="w-6 h-6 scale-105 rounded-full"
            />
            <p
              className={`${
                focusZone ? "text-white" : "text-black"
              } font-poppins text-base font-semibold tracking-[0.45px]`}
            >
              caarya
            </p>
            {/* <p className="text-black font-poppins mt-[2.5px] text-2xs font-light tracking-[0.3px]">
              LIFE
            </p> */}
          </div>
        </a>
        <div className="flex flex-row items-center space-x-2">
          <img
            src={user?.image?.url || "/assets/images/demo.png"}
            alt=""
            className=" rounded-full object-cover w-8 h-8"
          />
          <div
            onClick={() => {
              setOpenModal(true);
            }}
            className="w-8 h-8 rounded-lg bg-white shadow-sm flex flex-row items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_2527_30393)">
                <path
                  d="M1.9203 0.140625C0.888577 0.140625 0.0493164 0.979943 0.0493164 2.01161C0.0493164 3.04327 0.888229 3.88253 1.9203 3.88253C2.95237 3.88253 3.79128 3.04327 3.79128 2.01161C3.79128 0.979943 2.95197 0.140625 1.9203 0.140625Z"
                  fill="#9C9A96"
                />
                <path
                  d="M7.97572 0.140625C6.94405 0.140625 6.10474 0.979943 6.10474 2.01161C6.10474 3.04327 6.94405 3.88253 7.97572 3.88253C9.00744 3.88253 9.84688 3.04327 9.84688 2.01161C9.84688 0.979943 9.00779 0.140625 7.97572 0.140625Z"
                  fill="#9C9A96"
                />
                <path
                  d="M14.129 3.88259C15.1605 3.88259 16 3.04333 16 2.01167C16 0.980001 15.1612 0.140625 14.129 0.140625C13.0969 0.140625 12.2581 0.979943 12.2581 2.01161C12.2581 3.04327 13.0975 3.88259 14.129 3.88259Z"
                  fill="#9C9A96"
                />
                <path
                  d="M1.87133 9.8749C2.903 9.8749 3.74249 9.03581 3.74249 8.00397C3.74249 6.97207 2.903 6.13281 1.87133 6.13281C0.839666 6.13281 0 6.97213 0 8.00397C0 9.03581 0.839666 9.8749 1.87133 9.8749Z"
                  fill="#9C9A96"
                />
                <path
                  d="M7.92665 9.8749C8.95814 9.8749 9.79763 9.03581 9.79763 8.00397C9.79763 6.97207 8.95883 6.13281 7.92665 6.13281C6.89463 6.13281 6.05566 6.97207 6.05566 8.00397C6.05566 9.03581 6.89498 9.8749 7.92665 9.8749Z"
                  fill="#9C9A96"
                />
                <path
                  d="M14.0807 9.8749C15.1126 9.8749 15.9516 9.03581 15.9516 8.00397C15.9516 6.97207 15.1126 6.13281 14.0807 6.13281C13.0488 6.13281 12.209 6.97207 12.209 8.00397C12.209 9.03581 13.0485 9.8749 14.0807 9.8749Z"
                  fill="#9C9A96"
                />
                <path
                  d="M1.91406 12.1094C0.882391 12.1094 0.0427246 12.9485 0.0427246 13.9804C0.0427246 15.0119 0.882391 15.8513 1.91406 15.8513C2.94572 15.8513 3.78498 15.0123 3.78498 13.9804C3.78498 12.9485 2.94572 12.1094 1.91406 12.1094Z"
                  fill="#9C9A96"
                />
                <path
                  d="M7.96996 12.1094C6.93824 12.1094 6.09863 12.9485 6.09863 13.9804C6.09863 15.0119 6.93795 15.8513 7.96996 15.8513C9.00146 15.8513 9.84089 15.0123 9.84089 13.9804C9.84089 12.9485 9.00146 12.1094 7.96996 12.1094Z"
                  fill="#9C9A96"
                />
                <path
                  d="M14.1232 12.1094C13.0917 12.1094 12.2522 12.9485 12.2522 13.9804C12.2522 15.0119 13.0917 15.8513 14.1232 15.8513C15.1551 15.8513 15.9942 15.0123 15.9942 13.9804C15.9942 12.9485 15.1547 12.1094 14.1232 12.1094Z"
                  fill="#9C9A96"
                />
              </g>
              <defs>
                <clipPath id="clip0_2527_30393">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        {/* <SignOut
        onClick={() => {
          onLogout();
        }}
        className="h-6 w-6 text-primary-pink-medium"
      /> */}
      </div>
      <Shortcuts
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        setOpenLogoutModal={setOpenLogoutModal}
      />
      <Logout
        isOpen={openLogoutModal}
        closeModal={() => {
          setOpenLogoutModal(false);
          setOpenModal(true);
        }}
        onLogout={onLogout}
      />
    </>
  );
}

export default TopBar;
