import Card from "./Card";
const list = [
  {
    name: "Know your tribe",
    path: "/essentials/kyt",
    image: "/assets/images/essentials/1.png",
    background: "/assets/images/essentials/bg1.png",
    color: "#2BB656",
  },
  // {
  //   name: "Events Calendar",
  //   key: "applicantInFTP",
  //   image: "/assets/images/essentials/2.png",
  // background: "/assets/images/essentials/bg2.png",
  // color: "#2BB656",
  // },
  {
    name: "Foundation Essentials",
    path: "/essentials/essentials",
    image: "/assets/images/essentials/3.png",
    background: "/assets/images/essentials/bg3.png",
    color: "#FB6F60",
  },
  {
    name: "Knowledge Boosters",
    path: "/learning",
    image: "/assets/images/essentials/4.png",
    background: "/assets/images/essentials/bg4.png",
    color: "#8DA1FC",
  },
  // {
  //   name: "Chronicles ",
  //   key: "eventCount",
  //   image: "/assets/images/essentials/5.png",
  //  background: "/assets/images/essentials/bg5.png",
  // color: "#2BB656",
  // },
  {
    name: "Let's talk about us",
    key: "newApplicantCount",
    image: "/assets/images/essentials/6.png",
    background: "/assets/images/essentials/bg6.png",
    color: "#33D6E1",
  },
  {
    name: "Let's make us better",
    path: "/essentials/better",
    image: "/assets/images/essentials/7.png",
    background: "/assets/images/essentials/bg7.png",
    color: "#FFD844",
  },
  // {
  //   name: "Quick Links",
  //   path: "/essentials/appLink",
  //   image: "/assets/images/essentials/6.png",
  //   background: "/assets/images/essentials/bg6.png",
  //   color: "#33D6E1",
  // },
];

function Verse() {
  return (
    <div className="h-[90vh] overflow-y-auto">
      <div className="bg-[#FF7E6E] pt-20 pb-10">
        <div className="py-5 px-4 space-x-4 flex justify-center flex-row items-center">
          <h1 className="text-xl text-white font-satoshi font-bold tracking-[1.6px] capitalizeleading-[30px]">
            Yoshi Crew
          </h1>
        </div>
      </div>
      <div className="px-4 w-full pt-4 pb-20 grid grid-cols-1 gap-6 h-85vh lg:h-auto overflow-y-auto">
        {list?.map((item) => {
          return <Card item={item} />;
        })}
      </div>
    </div>
  );
}

export default Verse;
