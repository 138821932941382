import { Hexagon } from "@phosphor-icons/react";
import InViewWrapper from "components/Animation/InViewWrapper";
import PublicNavbar from "components/Common/Navigation/PublicNavbar";
import ReactFullpage from "@fullpage/react-fullpage";
import { useEffect, useRef, useState, useMemo } from "react";

function GoProStudentLife() {
  const ref = useRef(null);
  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const [inView, setInView] = useState(0);
  const [heading, setHeading] = useState("");

  const isInViewport = useIsInViewport(ref);
  const isInViewport0 = useIsInViewport(ref0);
  const isInViewport1 = useIsInViewport(ref1);
  console.log("isInViewport1: ", isInViewport1);

  const isInViewport2 = useIsInViewport(ref2);
  const isInViewport3 = useIsInViewport(ref3);
  const isInViewport4 = useIsInViewport(ref4);
  console.log("isInViewport2: ", isInViewport2);

  useEffect(() => {
    if (isInViewport) {
      setInView(0);
      setHeading("");
    } else if (isInViewport4) {
      setHeading(
        "Avail personalized services catering to your branding, identity building and career growth"
      );
      setInView(4);
    } else if (isInViewport3) {
      setHeading(
        "Earn student work contracts to beef up your resume and bank balance"
      );
      setInView(3);
    } else if (isInViewport2) {
      setHeading(
        "Get access to your own personal remote suite of productivity"
      );
      setInView(2);
    } else if (isInViewport1) {
      setHeading("Introducing Virtual Office Spaces for Students");
      setInView(1);
    } else if (isInViewport0) {
      setInView(0);
      setHeading("");
    } else {
      setInView(0);
      setHeading("");
    }
  }, [
    isInViewport1,
    isInViewport2,
    isInViewport3,
    isInViewport4,
    isInViewport0,
    isInViewport,
  ]);

  return (
    <div className="bg-white">
      <PublicNavbar />
      {heading !== "" && (
        <div className="fixed z-10 top-16 right-0 left-0 bg-white p-2.5 flex flex-col items-start space-y-3">
          <Hexagon size={32} className="text-primary-red-500" />
          <h1 className="text-primary-red-500 text-left font-poppins lg:text-2xl font-medium lg:leading-9 lg:tracking-[4.8px] capitalize">
            {heading}
          </h1>
        </div>
      )}

      <ReactFullpage
        licenseKey={"416E7CEC-568043E0-BEC39CBC-192C1963"}
        scrollingSpeed={1000}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="relative w-full h-screen section flex flex-row items-center justify-center goPro-bg">
                <div
                  ref={ref}
                  className="max-w-4xl min-h-[80vh] section flex flex-row items-center justify-center overflow-hidden mx-auto text-white text-center font-poppins text-5xl leading-[56px] lg:text-[64px] font-medium lg:leading-[96px] lg:tracking-[25.6px]"
                >
                  <p className="overflow-hidden">The Go Pro Student Life</p>
                </div>
              </div>

              <div ref={ref0} className="relative w-full min-h-screen section">
                <InViewWrapper
                  className="min-h-screen max-w-5xl mx-auto px-6 py-20 lg:py-6 flex flex-row items-center justify-center"
                  delay="0"
                  yInitial="300"
                >
                  <div className="flex flex-col items-center space-y-8">
                    <div className="flex flex-col items-center space-y-3">
                      <Hexagon
                        size={32}
                        className="text-primary-red-500 invisible lg:visible"
                      />
                      <h1 className="text-primary-red-500 invisible lg:visible text-center font-poppins lg:text-2xl font-medium lg:leading-9 lg:tracking-[4.8px] capitalize">
                        Introducing Virtual Office Spaces for Students
                      </h1>
                      <p className="text-primary-neutral-800 text-center font-inter lg:text-xl font-light lg:leading-[30px] lg:tracking-[1px] capitalize">
                        Join our <b>CREW</b>: Caarya Regional Experiential
                        Workplace
                      </p>
                    </div>
                    <img
                      src="/assets/images/goPro/hq.png"
                      alt=""
                      className="max-w-sm object-contain aspect-square mx-auto"
                    />
                    <p className="text-primary-neutral-800 text-center font-inter lg:text-base font-light lg:leading-8 lg:tracking-[0.8px]">
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id es cupidatat non
                      proident, sunt in culpa Excepteur sint occaecat cupidatat
                      non proident, sunt in culpa qui officia deserunt mollit
                      anim id es cupidatat non proident, sunt in culpa Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id escupidatat non proident.
                    </p>
                  </div>
                </InViewWrapper>
              </div>

              <div ref={ref1} className="relative w-full min-h-screen section">
                <InViewWrapper
                  className="min-h-screen max-w-5xl mx-auto px-6 py-20 lg:py-6 flex flex-row items-center justify-center"
                  delay="0"
                  yInitial="300"
                >
                  <div className="flex flex-col items-center space-y-8 lg:space-y-[120px]">
                    <div className="flex flex-col items-center space-y-3">
                      <Hexagon
                        size={32}
                        className="text-primary-red-500 invisible lg:visible"
                      />
                      <h1 className="text-primary-red-500 invisible lg:visible text-center font-poppins lg:text-2xl font-medium lg:leading-9 lg:tracking-[4.8px] capitalize">
                        Get access to your own personal remote suite of
                        productivity
                      </h1>
                      <p className="text-primary-neutral-800 text-center font-inter lg:text-xl font-light lg:leading-[30px] lg:tracking-[1px] capitalize">
                        A digitized solution for the remote student pro
                      </p>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
                      {[
                        {
                          image: "/assets/images/goPro/1.png",
                          name: "Manage your daily schedules with our LIFE calendar ",
                          description:
                            "A few lines here about what the calendar is is",
                        },
                        {
                          image: "/assets/images/goPro/2.png",
                          name: "Grow your pedagogy with CaaryaME ",
                          description: "A few lines here about what ME is",
                        },
                        {
                          image: "/assets/images/goPro/3.png",
                          name: "Get work done in our Forge",
                          description: "A few lines here about what Forge is",
                        },
                      ]?.map((item) => {
                        return (
                          <div className="white-card p-4 space-y-8 flex flex-col items-center">
                            <img
                              src={item?.image}
                              alt=""
                              className="w-14 object-contain aspect-square mx-auto"
                            />

                            <div className="flex flex-col w-full space-y-2">
                              <h1 className="h-12 text-primary-neutral-800 font-inter text-sm font-semibold leading-5">
                                {item?.name}
                              </h1>
                              <p className="h-20 text-primary-neutral-800 font-inter text-sm font-light leading-5">
                                {item?.description}
                              </p>
                              <div className="flex flex-row w-full items-center justify-end">
                                <p className="text-primary-red-500 font-inter text-sm font-semibold leading-6 underline underline-offset-2">
                                  Learn More
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </InViewWrapper>
              </div>

              <div ref={ref2} className="relative w-full min-h-screen section">
                <InViewWrapper
                  className="min-h-screen max-w-5xl mx-auto px-6 py-20 lg:py-6 flex flex-row items-center justify-center"
                  delay="0"
                  yInitial="300"
                >
                  <div className="flex flex-col items-center space-y-8">
                    <div className="flex flex-col items-center space-y-3">
                      <Hexagon
                        size={32}
                        className="text-primary-red-500 invisible lg:visible"
                      />
                      <h1 className="text-primary-red-500 invisible lg:visible text-center font-poppins lg:text-2xl font-medium lg:leading-9 lg:tracking-[4.8px] capitalize">
                        Earn student work contracts to beef up your resume and
                        bank balance
                      </h1>
                      <p className="text-primary-neutral-800 text-center font-inter lg:text-xl font-light lg:leading-[30px] lg:tracking-[1px] capitalize">
                        Subtitle to come here
                      </p>
                    </div>
                    <img
                      src="/assets/images/goPro/workContract.png"
                      alt=""
                      className="w-[280px] object-contain aspect-square mx-auto"
                    />
                    <p className="text-primary-neutral-800 text-center font-inter lg:text-base font-light lg:leading-8 lg:tracking-[0.8px]">
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id es Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id es Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id es Excepteur sint occaecat cupidatat non
                      proident, sunt in culpa qui officia deserunt mollit anim
                      id es
                    </p>
                  </div>
                </InViewWrapper>
              </div>

              <div ref={ref3} className="relative w-full min-h-screen section">
                <InViewWrapper
                  className="min-h-screen max-w-5xl mx-auto px-6 py-20 lg:py-6 flex flex-row items-center justify-center"
                  delay="0"
                  yInitial="300"
                >
                  <div className="flex flex-col items-center space-y-8">
                    <div className="flex flex-col items-center space-y-3">
                      <Hexagon
                        size={32}
                        className="text-primary-red-500 invisible lg:visible"
                      />
                      <h1 className="text-primary-red-500 invisible lg:visible text-center font-poppins lg:text-2xl font-medium lg:leading-9 lg:tracking-[4.8px] capitalize">
                        Avail personalized services catering to your branding,
                        identity building and career growth
                      </h1>
                      <p className="text-primary-neutral-800 text-center font-inter lg:text-xl font-light lg:leading-[30px] lg:tracking-[1px] capitalize">
                        A digitized solution for the remote student pro
                      </p>
                    </div>
                    <img
                      src="/assets/images/goPro/identity.png"
                      alt=""
                      className="w-[280px] object-contain aspect-square mx-auto"
                    />
                    <p
                      ref={ref4}
                      className="text-primary-neutral-800 text-center font-inter lg:text-base font-light lg:leading-8 lg:tracking-[0.8px]"
                    >
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id es Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id es Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt
                      mollit anim id es Excepteur sint occaecat cupidatat non
                      proident, sunt in culpa qui officia deserunt mollit anim
                      id es
                    </p>
                  </div>
                </InViewWrapper>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
      <div className="w-full fixed bottom-0 left-0 right-0 grid grid-cols-2">
        <div className="w-full flex flex-row items-stretch justify-end">
          <div
            style={{ width: `${12.5 * inView}vw` }}
            className="bg-primary-red-500 h-2 progress-bar"
          />
        </div>
        <div className="w-full flex flex-row items-stretch justify-start">
          <div
            style={{ width: `${12.5 * inView}vw` }}
            className="bg-primary-red-500 h-2 progress-bar"
          />
        </div>
      </div>
    </div>
  );
}

export default GoProStudentLife;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
