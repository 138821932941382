import { Drawer } from "@mui/material";
import usePhaseOne from "components/ProfileForm/hooks/usePhaseOne";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { motion } from "framer-motion";
import { FormComponents, QuestionArr } from "helpers/constants/profileForm";
import {
  scrollToBottom,
  scrollToTop,
} from "helpers/utils/profileForm/functions";
import { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { hideNavigation, updateQuizStep } from "redux/profile-form";
import MobileRiasecStats from "./MobileRiasecStats";
import QuizNavigation from "./QuizNavigation";
import SelectedOption from "./assets/selected.svg";
import UnselectedOption from "./assets/unselected.svg";
import { showToast } from "redux/toaster";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const ArrowButton = ({ type, onClick }) => {
  return (
    <button
      className={`fixed z-10 top-1/2 ${
        type === "next" ? "right-0 " : "left-0  "
      } transform -translate-y-1/2 disabled:text-primary-grey-300 p-3 rounded-full hover:bg-primary-grey-100 hover:shadow-md `}
      onClick={onClick}
    >
      {type === "next" ? (
        <ChevronRightIcon className="w-6" />
      ) : (
        <ChevronLeftIcon className="w-6" />
      )}
    </button>
  );
};

const QuizForm = (props) => {
  const quizStep = useSelector(
    (state) => state.profileForm.phaseOne.details.quiz.quizStep
  );
  const { details, step, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );

  const [showRiasecInfo, setShowRiasecInfo] = useState(false);
  const [questions, setQuestions] = useState(QuestionArr);
  const dispatch = useDispatch();
  const sliderRef = useRef(null);
  const [questionRef, isQuestionInView] = useInView({ threshold: 0.5 });

  const { field } = props;
  const { checkUnattemptedQuiz, handleAnswerSelection, handleOptionDeselect } =
    usePhaseOne(field);
  const settings = {
    customPaging: function (index) {
      return (
        <motion.p
          className={` ${
            quizStep === index
              ? " underline-offset-2 text-primary-grey-600 font-semibold underline "
              : "text-primary-grey-400"
          } `}
        >
          {index + 1}
        </motion.p>
      );
    },
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    dotsClass: "slick-dots slick-thumb",
    className: "slides",
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => dispatch(updateQuizStep(next)),
    afterChange: (current) => dispatch(updateQuizStep(current)),
  };

  useEffect(() => {
    if (details[field].quizData.length === QuestionArr.length) {
      scrollToTop();
    } else {
      scrollToBottom();
    }
  }, [details[field]]);

  useEffect(() => {
    sliderRef.current.slickGoTo(quizStep);
    if (details[field].quizData.length !== QuestionArr.length) {
      scrollToBottom();
    }

    dispatch(hideNavigation());
  }, [quizStep, sliderRef]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    setQuestions(QuestionArr.map((question) => shuffle(question)));
    if (
      details[field].quizData.length >= QuestionArr.length - 1 &&
      quizStep === QuestionArr.length - 1
    ) {
      setShowRiasecInfo(true);
    }
  }, []);

  const getDisabledState = () => {
    const currentQuiz = details[field]?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );
    if (currentQuiz && currentQuiz?.options.length > 0) return false;
    return true;
  };

  const handleButtonWrapperClick = () => {
    if (details[field]?.quizData.length !== QuestionArr.length) {
      dispatch(updateQuizStep(checkUnattemptedQuiz()));
      if (quizStep !== checkUnattemptedQuiz() - 1) {
        dispatch(
          showToast({
            message:
              "You have to select at least one option from each section .",
            type: "error",
          })
        );
      }
    }
  };

  return (
    <div className=" font-manrope flex flex-col relative scrollbar-hidden  pt-24 ">
      <div
        ref={questionRef}
        className="flex flex-col gap-4  h-full px-4 md:px-10  "
      >
        <h1 className="text-primary-blue-400 text-lg font-medium ">
          Which of these would you be most interested in doing?
        </h1>

        <h3 className="text-sm text-primary-grey-600">
          Select the ones that you think you’ll enjoy doing the most. The ones
          that fascinate you, irrespective of whether or not you’ll actually do
          it.
        </h3>
      </div>
      <div
        className={` ${
          !isQuestionInView && "sticky top-0  "
        } px-4  flex flex-col gap-3 mt-3 `}
      >
        <div className="flex justify-between gap-2  items-center">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 font-bold">
              <CircularProgressbar
                styles={buildStyles({
                  pathColor: "#FBC920",
                  textSize: "2rem",
                  textColor: "#FF8800",
                  trailColor: "#F7F4D4",
                })}
                value={((quizStep + 1) / QuestionArr.length) * 100}
                text={QuestionArr.length - quizStep}
              />
            </div>

            <p className="text-primary-grey-500 text-xs flex-1">
              Steps away from understanding your natural inclinations
            </p>
          </div>
          {details[field]?.quizData.find(
            (quiz) => quiz.quizStep === quizStep
          ) ? (
            <div className="flex flex-1 flex-col-reverse items-center gap-2">
              <p className="text-primary-grey-500 text-xs text-center ">
                {2 -
                  details[field]?.quizData.find(
                    (quiz) => quiz.quizStep === quizStep
                  )?.options.length}{" "}
                Choices Remaining
              </p>

              <div className="flex items-center gap-3">
                {[0, 1].map((idx) => {
                  if (
                    details[field]?.quizData.find(
                      (quiz) => quiz.quizStep === quizStep
                    )?.options[idx]
                  ) {
                    return (
                      <img
                        key={idx}
                        src={SelectedOption}
                        alt=""
                        className="w-6 h-6 object-contain"
                      />
                    );
                  } else
                    return (
                      <img
                        key={idx}
                        src={UnselectedOption}
                        alt=""
                        className="w-5 h-6 object-contain"
                      />
                    );
                })}
              </div>
            </div>
          ) : (
            <div className="flex flex-1 flex-col-reverse items-center gap-2">
              <p className="text-primary-grey-500 text-xs ">
                2 Choices Remaining
              </p>
              <div className="flex items-center gap-3">
                <img
                  src={UnselectedOption}
                  alt=""
                  className="w-6 h-6 object-contain"
                />
                <img
                  src={UnselectedOption}
                  alt=""
                  className="w-5 h-6 object-contain"
                />
              </div>
            </div>
          )}
        </div>
        <div className="p-2 border-b-2 border-primary-grey-200 flex flex-col gap-5  min-h-[110px] ">
          {details[field]?.quizData.find(
            (quiz) => quiz.quizStep === quizStep
          ) &&
            details[field]?.quizData
              .find((quiz) => quiz.quizStep === quizStep)
              ?.options.map((option, idx) => (
                <div
                  key={idx}
                  onClick={() => handleOptionDeselect(quizStep, option)}
                  className="p-2 px-3 rounded-full border hover:bg-primary-grey-150 border-primary-grey-200 text-center cursor-pointer  active:border-primary-blue-250 active:bg-primary-blue-50 "
                >
                  <p className="font-satoshi text-xs text-primary-grey-800">
                    {option}
                  </p>
                </div>
              ))}
        </div>
        <div className={` pb-28  `}>
          <Slider ref={sliderRef} {...settings}>
            {questions.map((options, idx) => (
              <div
                key={idx}
                className={` flex flex-col h-[55vh] ${
                  !isQuestionInView && "overflow-y-scroll"
                }  items-center px-4 `}
              >
                {options.map((option, idx) => {
                  const currentQuiz = details[field]?.quizData.find(
                    (quiz) => quiz.quizStep === quizStep
                  );
                  return (
                    <div
                      key={idx}
                      onClick={() => handleAnswerSelection(quizStep, option)}
                      className={`w-full  p-2 px-3 rounded-full text-center border mt-6 text-xs  active:border-primary-blue-250 active:bg-primary-blue-50 ${
                        currentQuiz && currentQuiz?.options.includes(option)
                          ? "bg-primary-grey-150 text-primary-grey-150 border-primary-grey-150 cursor-not-allowed"
                          : "  hover:bg-primary-grey-150 border-primary-grey-200  text-primary-grey-800 cursor-pointer"
                      }  `}
                    >
                      <p>{option}</p>
                    </div>
                  );
                })}
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {showRiasecInfo && (
        <Drawer
          anchor={"bottom"}
          open={showRiasecInfo}
          onClose={() => setShowRiasecInfo(false)}
        >
          <MobileRiasecStats
            close={() => setShowRiasecInfo(false)}
            quizData={details[field]?.quizData}
          />
        </Drawer>
      )}

      {!isNavigationVisible && (
        <QuizNavigation
          totalSteps={FormComponents(step, details).length}
          field={field}
          isInView={isQuestionInView}
          setShowRiasecInfo={setShowRiasecInfo}
        />
      )}
      <div className="grid place-items-center p-4 bg-white fixed bottom-0 inset-x-0 ">
        <div
          onClick={handleButtonWrapperClick}
          className={` transition-all duration-300 ease-in-out  ${
            !isQuestionInView
              ? " opacity-100 translate-y-0 "
              : "translate-y-full opacity-0"
          }   `}
        >
          <ProfileButton
            onClick={() => dispatch(updateQuizStep(quizStep + 1))}
            disabled={getDisabledState()}
            text={"Next"}
            size={"large"}
            variant={"filled"}
            icon={<ChevronRightIcon className="w-6" />}
            iconAlign={"right"}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizForm;
