import CheckPoint from "./CheckPoint";
import { useEffect, useRef, useState } from "react";
import ScrollToTopButton from "components/ProfileForm/ui-components/ScrollToTopButton";

const CheckpointForm = () => {
  const intersectionRef = useRef(null);
  const [showUpButton, setShowUpButton] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowUpButton(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) observer.unobserve(intersectionRef.current);
    };
  }, [intersectionRef]);
  return (
    <div className=" font-manrope h-full flex flex-col  p-4 md:px-10 gap-4 pt-24  ">
      <div ref={intersectionRef} className="flex flex-col gap-4">
        <h4 className="text-xl text-primary-blue-500  font-medium ">
          Get to know yourself better!
        </h4>
        <h4 className="text-lg text-primary-grey-500  font-medium ">
          Find out where your heart lies in 5 simple steps
        </h4>

        <p className="text-md text-primary-grey-500  font-normal my-6 ">
          You are the one who knows yourself best! We are here to help you ask
          the right questions, so that you can better align your efforts towards
          your purpose.
        </p>
      </div>{" "}
      <CheckPoint />
      {showUpButton && <ScrollToTopButton />}
    </div>
  );
};

export default CheckpointForm;
