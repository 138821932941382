import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { hideNavigation, showNavigation } from "redux/profile-form";
import AchievementCard from "./AchievementCard";
import WebAchievementEditForm from "./WebAchievementEditForm";
import WebAchievementNavigation from "./WebAchievementNavigation";

const WebAchievementForm = ({ field }) => {
  const { details, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const dispatch = useDispatch();
  const achievements = details[field];

  const [achievementDataModalProps, setAchievementDataModalProps] = useState({
    open: false,
    type: "ADD",
    editID: null,
  });

  useEffect(() => {
    if (details[field].draft.length > 0 || details[field].added.length > 0) {
      dispatch(showNavigation());
    } else {
      dispatch(hideNavigation());
    }
  }, [details[field]]);

  return (
    <div className="flex flex-col gap-12">
      {achievementDataModalProps.open && (
        <Drawer
          anchor={"bottom"}
          open={achievementDataModalProps.open}
          onClose={() =>
            setAchievementDataModalProps({
              ...achievementDataModalProps,
              open: false,
            })
          }
        >
          <WebAchievementEditForm
            type={achievementDataModalProps.type}
            goBack={() =>
              setAchievementDataModalProps({
                ...achievementDataModalProps,
                open: false,
              })
            }
            id={achievementDataModalProps.editID}
            field={field}
            achievementDataModalProps={achievementDataModalProps}
            setAchievementDataModalProps={setAchievementDataModalProps}
          />
        </Drawer>
      )}
      <div className="flex flex-col gap-2 py-6   ">
        <h1 className="text-primary-blue-500 text-xl font-medium ">
          What are some things you have accomplished in your life that you are
          proud of?
        </h1>

        <h3 className="text-md text-primary-grey-600">
          This could be anything from overcoming a personal challenge to
          excelling in a particular subject or skill.
        </h3>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
        <div
          onClick={() =>
            setAchievementDataModalProps({
              ...achievementDataModalProps,
              open: true,
              editID: null,
            })
          }
          className=" cursor-pointer p-6  rounded-3xl border-2 border-dashed hover:shadow-md flex flex-col gap-4 items-center justify-center text-center hover:border-solid hover:border-primary-blue-500 "
        >
          <div className="w-14 h-14 bg-primary-blue-100 grid place-items-center rounded-full text-primary-blue-500">
            <MdAdd className="text-3xl" />
          </div>
          <h2 className="text-md font-bold font-satoshi">
            Add New Accomplishment
          </h2>
        </div>
        {achievements.draft.map((_achievement) => (
          <AchievementCard
            key={_achievement.id}
            field={field}
            draft
            category={"Un-Supported"}
            achievement={_achievement}
            type={achievementDataModalProps.type}
            achievementDataModalProps={achievementDataModalProps}
            setAchievementDataModalProps={setAchievementDataModalProps}
          />
        ))}
        {achievements.added.map((_achievement) => (
          <AchievementCard
            key={_achievement.id}
            field={field}
            achievement={_achievement}
            type={achievementDataModalProps.type}
            achievementDataModalProps={achievementDataModalProps}
            setAchievementDataModalProps={setAchievementDataModalProps}
          />
        ))}
      </div>

      {!isNavigationVisible && <WebAchievementNavigation />}
    </div>
  );
};

export default WebAchievementForm;
