import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CollegeIcon } from "assets/svg/college.svg";

function College({ details, setDetails, onNext, openCrewModal }) {
  const [newCollege, setNewCollege] = useState(false);
  const [showCollegesDropdown, setShowCollegesDropdown] = useState(false);
  const [showCollegesLocationDropdown, setShowCollegesLocationDropdown] =
    useState(false);
  const [yearOfStudyDropdown, setYearOfStudyDropdown] = useState(false);
  const colleges = useSelector((state) => state?.dropdown?.colleges);
  const [filteredItems, setFilteredItems] = useState(colleges);
  const [filteredLocationItems, setFilteredLocationItems] = useState(state);

  const dropdownRef = useRef(null);
  const dropdownLocationRef = useRef(null);
  const dropdownYearOfStudyRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowCollegesDropdown(false);
    }
    if (
      dropdownLocationRef.current &&
      !dropdownLocationRef.current.contains(event.target)
    ) {
      setShowCollegesLocationDropdown(false);
    }
    if (
      dropdownYearOfStudyRef.current &&
      !dropdownYearOfStudyRef.current.contains(event.target)
    ) {
      setYearOfStudyDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredItems(colleges);
  }, [colleges]);
  return (
    <section className="college lg:h-auto w-full flex flex-col justify-between pb-6">
      <div className="pb-6 px-5 space-y-10">
        <div className="text-primary-red-500 font-satoshi text-base font-bold leading-6 tracking-[0.32px] capitalize flex flex-col items-center text-center">
          We see that you are not part of our network yet!
        </div>

        <div className="flex flex-col gap-8 w-full !mb-4">
          <p className="text-primary-neutral-800 font-satoshi text-base leading-6 tracking-[0.64px] flex flex-col text-left">
            Please Select Your College{" "}
          </p>
          <div className="flex flex-col space-y-10 w-full max-w-[560px]">
            <div className="relative w-full flex flex-row items-center">
              {details?.newCollegeName ? (
                <div
                  onFocus={() => setShowCollegesDropdown(true)}
                  className="flex flex-row items-center justify-between text-lg lg:text-2xl text-dropdown p-4 border rounded-xl border-primary-neutral-200 w-full"
                >
                  <p>{details?.newCollegeName}</p>
                  <XCircleIcon
                    className="w-6 h-6"
                    onClick={() => {
                      setDetails({
                        collegeName: "",
                      });
                      setFilteredItems(colleges);
                      setNewCollege(false);
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-row items-center justify-between text-lg lg:text-2xl p-4 border rounded-xl border-primary-neutral-200 w-full">
                  <input
                    type="text"
                    className="w-11/12 border-0 p-0 focus:outline-none focus:ring-0 focus:border-0"
                    placeholder="Select your college"
                    value={details.collegeName}
                    onChange={(e) => {
                      const value = e.target.value;
                      setDetails({
                        ...details,
                        collegeId: "",
                        collegeName: value,
                      });
                      if (value.length) {
                        const filtered = colleges.filter((item) =>
                          item.college_name
                            .toLowerCase()
                            .includes(value.toLowerCase())
                        );
                        setFilteredItems(filtered);
                      } else {
                        setFilteredItems(colleges);
                      }
                      setShowCollegesDropdown(true);
                    }}
                    onFocus={() => setShowCollegesDropdown(true)}
                  />
                  <ChevronDownIcon
                    className="text-black absolute right-6 top-5 lg:top-6 w-6 h-6"
                    onClick={() => setShowCollegesDropdown(true)}
                  />
                </div>
              )}
              {showCollegesDropdown && (
                <div
                  className="top-16 absolute z-10 mt-2 bg-white border rounded-md shadow-md w-full max-h-48 overflow-scroll"
                  ref={dropdownRef}
                >
                  {filteredItems.map((item) => (
                    <div
                      key={item.id}
                      className="p-2 hover:bg-gray-100 cursor-pointer inter font-light text-sm leading-[150%] tracking-[5%] text-black"
                      onClick={() => {
                        setDetails({
                          ...details,
                          collegeId: item.id,
                          collegeName: item.college_name,
                          collegeLocation: item.college_location,
                        });
                        setShowCollegesDropdown(false);
                        setNewCollege(false);
                      }}
                    >
                      {item.college_name}
                    </div>
                  ))}
                  <div
                    className="p-2 hover:bg-gray-100 cursor-pointer inter font-semibold text-sm leading-[150%] tracking-[5%] text-[#ED4C41]"
                    onClick={() => {
                      setDetails({
                        newCollegeName: details?.collegeName,
                        collegeName: "Other",
                      });
                      setNewCollege(true);
                      setShowCollegesDropdown(false);
                    }}
                  >
                    + Add "{details?.collegeName}"
                  </div>
                </div>
              )}
            </div>

            {details?.collegeName && (
              <div className="relative w-full flex flex-row items-center text-lg lg:text-2xl">
                <input
                  type="text"
                  className="flex cursor-default w-full flex-row items-center justify-between text-dropdown p-4 border rounded-xl border-primary-neutral-200"
                  placeholder="Year of Study"
                  value={details.yearOfStudy}
                  onKeyDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      yearOfStudy: e.target.value,
                    });
                  }}
                  onFocus={() => setYearOfStudyDropdown(true)}
                />
                <ChevronDownIcon className="text-black absolute right-2 top-5 lg:top-6 w-6 h-6" />

                {yearOfStudyDropdown && (
                  <div
                    className="top-16 absolute z-10 mt-2 bg-white border rounded-md shadow-md w-full max-h-48 overflow-scroll"
                    ref={dropdownYearOfStudyRef}
                  >
                    {["1st", "2nd", "3rd", "4th", "5th"]?.map((item) => (
                      <div
                        key={item}
                        className="p-2 hover:bg-gray-100 cursor-pointer inter font-light text-sm leading-[150%] tracking-[5%] text-black"
                        onClick={() => {
                          setDetails({
                            ...details,
                            yearOfStudy: item,
                          });
                          setYearOfStudyDropdown(false);
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {(newCollege || details?.collegeName == "Other") && (
              <>
                <div className="relative w-full flex flex-row items-center">
                  <input
                    type="text"
                    className="flex w-full flex-row items-center justify-between text-lg lg:text-2xl text-dropdown p-4 border rounded-xl border-primary-neutral-200"
                    placeholder="Select state"
                    value={details.collegeLocation}
                    onChange={(e) => {
                      setDetails({
                        ...details,
                        collegeLocation: e.target.value,
                      });
                      const filtered = state.filter((item) =>
                        item
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                      setFilteredLocationItems(filtered);
                      setShowCollegesLocationDropdown(true);
                    }}
                    onFocus={() => setShowCollegesLocationDropdown(true)}
                  />
                  <ChevronDownIcon className="text-black absolute right-2 top-5 lg:top-6 w-6 h-6" />

                  {showCollegesLocationDropdown && (
                    <div
                      className="top-16 absolute z-10 mt-2 bg-white border rounded-md shadow-md w-full max-h-48 overflow-scroll"
                      ref={dropdownLocationRef}
                    >
                      {filteredLocationItems?.map((item) => (
                        <div
                          key={item}
                          className="p-2 hover:bg-gray-100 cursor-pointer inter font-light text-sm leading-[150%] tracking-[5%] text-black"
                          onClick={() => {
                            setDetails({
                              ...details,
                              collegeLocation: item,
                            });
                            setShowCollegesLocationDropdown(false);
                          }}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}

            {((details?.collegeName !== "Other" &&
              details?.collegeName &&
              details?.collegeId) ||
              (newCollege &&
                details?.collegeName == "Other" &&
                details?.collegeLocation)) && (
              <div className="flex flex-col items-center w-full space-y-6">
                <div className="w-full rounded-[24px] border border-[#FEB8B1] p-4 flex flex-row items-center space-x-4">
                  <CollegeIcon />
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-black font-satoshi text-lg font-bold leading-7 tracking-[0.45px]">
                      {details?.newCollegeName || details?.collegeName}
                    </h1>
                    <div className="flex flex-row items-center space-x-2">
                      <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-[18px] tracking-[0.3px]">
                        CGC {details?.collegeLocation}
                      </p>
                    </div>
                  </div>
                </div>
                <p
                  onClick={openCrewModal}
                  className="w-full text-center text-primary-red-500 font-satoshi text-sm font-bold leading-5 underline capitalize cursor-pointer"
                >
                  Learn more about your CREW
                </p>
              </div>
            )}
          </div>
        </div>
      </div>{" "}
      <div className="flex flex-row max-w-[360px] mx-auto items-center justify-center w-full">
        <div
          onClick={() => {
            if (
              (details?.collegeName !== "Other" && details?.collegeName) ||
              (newCollege &&
                details?.collegeName == "Other" &&
                details?.collegeLocation)
            ) {
              onNext();
            }
          }}
          className={`${
            (details?.collegeName !== "Other" && details?.collegeName) ||
            (newCollege &&
              details?.collegeName == "Other" &&
              details?.collegeLocation)
              ? "bg-black"
              : "bg-primary-neutral-400"
          } w-full nextButton flex flex-row items-center justify-center space-x-2 rounded-full px-8 py-4 cursor-pointer text-primary-gray-100 font-lato text-base font-bold leading-6`}
        >
          <p>Next</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default College;
let state = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
