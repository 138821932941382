import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectName: "",
  aspect: "",
  roles: [],
  currentRoleIdx: -1,
  achievements: {},
  moreContext: {},
  lastStep: [],
  boosters: [],
};

const achievementsFormSlice = createSlice({
  name: "achievementsForm",
  initialState,
  reducers: {
    setExisting: (state, action) => {
      const { projectName, aspect, roles, achievements, moreContext } =
        action.payload;
      Object.assign(state, {
        projectName,
        aspect,
        roles,
        achievements,
        moreContext,
      });
    },
    setValue: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    addLastStep: (state, action) => {
      state.lastStep = [...state.lastStep, action.payload];
    },
    setBoosters: (state, action) => {
      state.boosters = action.payload;
    },
  },
});

export const { setExisting, setValue, addLastStep, setBoosters } =
  achievementsFormSlice.actions;
export default achievementsFormSlice.reducer;
