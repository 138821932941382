import { motion } from "framer-motion";
import { evaluconList } from "helpers/constants/evalucon";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import Object from "./Object";

const Carousel = () => {
  const [positionIndexes, setPositionIndexes] = useState(
    Array.from(Array(evaluconList.length).keys())
  );
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const positions = ["center", "left1", "left", "right2", "right", "right1"];

  const imageVariants = {
    center: { x: window.innerWidth < 1024 ? "0" : "-30%", scale: 1, zIndex: 5 },
    left1: {
      x: window.innerWidth < 1024 ? "50%" : "-100%",
      scale: 0.8,
      zIndex: 3,
    },
    left: { x: "-150%", scale: 0.5, zIndex: 2 },
    right: { x: "100%", scale: 0.6, zIndex: 1 },
    right1: { x: "50%", scale: 0.7, zIndex: 2 },
    right2: { x: "140%", scale: 0.5, zIndex: 1 },
  };

  const handleNavClick = (clickedIndex) => {
    const currentIndex = positionIndexes.findIndex(
      (positionIndex) => positions[positionIndex] === "center"
    );

    if (currentIndex !== clickedIndex) {
      const indexDiff =
        (clickedIndex - currentIndex + positionIndexes.length) %
        positionIndexes.length;
      let updatedIndexes = positionIndexes.map(
        (prevIndex) => (prevIndex + indexDiff) % positionIndexes.length
      );

      // Ensure the clicked index is centered
      updatedIndexes[currentIndex] = positionIndexes[clickedIndex];

      setPositionIndexes(updatedIndexes);
    }
  };

  return (
    <div className="flex gap-10 items-center flex-col justify-center relative w-full max-w-[100vw] overflow-x-hidden">
      {show && activeTab && <Object {...activeTab} setShow={setShow} />}
      {!show && (
        <h1 className="font-poppins text-primary-neutral-500 text-3xl w-full text-left  font-light leading-7">
          Choose a task that piques your interest.
        </h1>
      )}
      {!show &&
        evaluconList.map((evalucon, index) => {
          const card = evalucon.card;
          return (
            <motion.div
              key={index}
              className={`flex flex-col p-5 py-6 gap-4 rounded-3xl text-white max-w-md  font-poppins shadow-xl cursor-pointer `}
              initial="center"
              animate={positions[positionIndexes[index]]}
              variants={imageVariants}
              transition={{ duration: 0.5 }}
              style={{
                position: "absolute",
                background: `linear-gradient(to bottom, ${card.bgTop}, ${card.bgBottom})`,
              }}
              onClick={() => {
                setActiveTab(evalucon);
                setShow(true);
              }}
            >
              <h3 className="text-base tracking-widest pl-4 border-l-4 border-white ">
                UNMASK {index + 1}
              </h3>
              <h1 className="text-[28px] leading-10 tracking-[2%] ">
                Create a visually engaging reel to introduce yourself.
              </h1>
              <img
                src={evalucon.image}
                className="w-60 h-60 object-contain mx-auto"
              />
              <div className="flex gap-2 items-center">
                <p className="font-inter text-base font-semibold underline underline-offset-4">
                  View Details
                </p>
                <FiArrowRight />
              </div>
            </motion.div>
          );
        })}
      {!show && (
        <>
          <div className="flex flex-row gap-3 mt-[450px] "></div>
        </>
      )}
      {!show && (
        <div className="flex gap-3 items-center justify-center mt-4">
          {evaluconList.map((evalucon, index) => {
            const card = evalucon.card;

            const active = positions[positionIndexes[index]] === "center";
            return (
              <div
                key={index}
                onClick={() => handleNavClick(index)}
                style={{
                  background: `linear-gradient(to bottom, ${card.bgTop}, ${card.bgBottom})`,
                }}
                className={`w-10 h-10 rounded-md cursor-pointer ${
                  active && "transform scale-150 mx-2"
                } `}
              >
                <img
                  src={evalucon.image}
                  className="w-full h-full object-contain"
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;
