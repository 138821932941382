export const focusPoints = [
  "Choice based education",
  "Collaborative leadership",
  "Cross Domain Tskills",
  "Student Entrepreneurship",
  "Participatory Learning",
  "The student currency",
  "A community for change",
  "students for startups",
  "The caarya Network",
];
