import { useState } from "react";
import { dummyDataValueAssessment } from "../helper";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { ReactComponent as RiasecSvg } from "assets/svg/riasec.svg";

export default function ValueAssessment({ page, setPage, setCompleted }) {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState({});
  const handleNext = () => {
    setQuestionNumber(questionNumber + 1);
    document.getElementById("question").scrollIntoView();
  };
  return (
    <section>
      <div
        onClick={() => {
          if (page == 1) history.goBack();
          else setPage(page - 1);
        }}
        className="flex px-4 py-6 flex-row items-center justify-between space-x-2 text-sm font-satoshi"
      >
        <MdArrowBack size={24} />
        <div className="flex text-sm gap-2 pl-4 items-center text-primary-neutral-800">
          <RiasecSvg className="h-5 w-5" />
          <span className="text-sm font-medium leading-[21px] tracking-[0.7px]">
            Natural Interest Profiling
          </span>
        </div>
        <p className="font-lato text-primary-neutral-400 font-normal">
          {questionNumber + 1}/{dummyDataValueAssessment.questions.length}
        </p>
      </div>
      <div className="h-[6px] relative bg-primary-neutral-200 w-[100vw]">
        <div
          style={{
            width: `${
              ((questionNumber + 1) * 100) /
              dummyDataValueAssessment.questions.length
            }%`,
            background: "#ED4C41",
          }}
          className="h-[6px] relative w-full bg-opacity-60"
        ></div>
      </div>

      <div className="py-10 px-6 flex flex-col items gap-8 h-[calc(100vh-165px)] overflow-y-auto">
        <div className="flex flex-col gap-2" id="question">
          <p className="text-caaryaRed font-lato font-medium leading-[24px] tracking-[0.8px]">
            {dummyDataValueAssessment.question}
          </p>
          <p className="text-primary-neutral-500 font-satoshi font-normal text-sm text-left">
            {dummyDataValueAssessment.subheading}
          </p>
        </div>

        <div className="flex flex-col items-center gap-4 py-4">
          <div className="w-[144px] h-[144px]">
            <img
              src={dummyDataValueAssessment.questions[questionNumber].icon}
            />
          </div>
          <p className="text-primary-neutral-800 font-bold font-lato leading-6">
            {questionNumber + 1}.{" "}
            {dummyDataValueAssessment.questions[questionNumber].heading}
          </p>
        </div>
        <div className="flex flex-col gap-8 items-start">
          {dummyDataValueAssessment.questions[questionNumber].options.map(
            (opt, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    const updatedAnswers = answers ? { ...answers } : {};
                    if (!updatedAnswers[questionNumber])
                      updatedAnswers[questionNumber] = -1;

                    if (updatedAnswers[questionNumber] === i) {
                      updatedAnswers[questionNumber] = -1;
                    } else {
                      updatedAnswers[questionNumber] = i;
                    }

                    setAnswers(updatedAnswers);
                  }}
                  className={`cursor-pointer rounded-full border bg-white flex min-w-[200px] w-full py-[14px] px-5 flex-col justify-center items-center gap-2 ${
                    answers[questionNumber] === i
                      ? "border-caaryaRed text-caaryaRed"
                      : "text-primary-gray-800"
                  }`}
                >
                  <span className="font-satoshi text-sm font-normal text-center ">
                    {opt}
                  </span>
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className="flex p-4 justify-between items-center border-t border-primary-neutral-100 bg-white">
        <div
          onClick={() => {
            if (questionNumber > 0) {
              setQuestionNumber(questionNumber - 1);
              document.getElementById("question").scrollIntoView();
            }
          }}
          className={`bg-transparent  flex flex-row items-center justify-center space-x-2 rounded-full px-8 py-4 cursor-pointer text-primary-gray-500 font-lato text-base font-bold leading-6`}
        >
          <MdArrowBack className="h-5 w-5" />
          <p>Back</p>
        </div>
        <div
          onClick={() =>
            questionNumber < dummyDataValueAssessment.questions.length - 1
              ? handleNext()
              : setCompleted(true)
          }
          className={`bg-black  flex flex-row items-center justify-center space-x-2 rounded-full pr-8 pl-10 py-4 cursor-pointer text-white font-lato text-base font-bold leading-6`}
        >
          <p>
            {questionNumber === dummyDataValueAssessment.questions.length - 1
              ? "Submit"
              : "Next"}
          </p>
          <MdArrowForward className="h-5 w-5" />
        </div>
      </div>
    </section>
  );
}
