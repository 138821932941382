import React from "react";

function MySpace() {
  return (
    <div className="py-8 space-y-8 px-4">
      {[
        { name: "Info Cards", free: 96, total: 100 },
        { name: "Life Cards", free: 80, total: 80 },
        { name: "Knowledge Cards", free: 150, total: 150 },
        { name: "Resource Cards", free: 100, total: 100 },
      ]?.map((item) => {
        return (
          <div className="flex flex-row items-center space-x-6">
            <div className="w-16 h-16 rounded-lg bg-primary-neutral-200" />
            <div className="flex flex-col space-y-2 w-8/12">
              <h1 className="text-primary-neutral-800 font-lato text-base font-bold leading-6 tracking-[0.64px] capitalize">
                {item?.name}
              </h1>
              <div className="w-full h-2 rounded-full bg-primary-neutral-100">
                <div
                  className="h-2 rounded-full bg-[#2BB656]"
                  style={{
                    width: `${
                      ((item?.total - item?.free) * 100) / item?.total
                    }%`,
                  }}
                />
              </div>
              <p className="text-primary-neutral-500 font-satoshi text-xs leading-5 tracking-[0.48px] capitalize">
                {item?.free}/{item?.total} Slots Free
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default MySpace;
