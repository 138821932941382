import ImprovementIcon from "../assets/Personal Development.svg";
import EmphaticGrowthIcon from "../assets/emphatic growth.svg";
import CommunityIcon from "../assets/inclusive community.svg";
import GrowthIcon from "../assets/resiliant growth.svg";
import LeaderIcon from "../assets/leadership.svg";
import HarmonyIcon from "../assets/harmony.svg";
import BalancedIcon from "../assets/balance.svg";

export const valueQuestionArr = [
  {
    title: "Self-improvement.",
    icon: ImprovementIcon,
    options: [
      "Embrace equality",
      "Take responsibility",
      "Seek feedback",
      "Learn from mistakes",
      "Be honest and transparent",
      "Set goals",
    ],
    dark: "#E72113",
  },
  {
    title: "Emphatic-growth",
    icon: EmphaticGrowthIcon,
    options: [
      "Practice self-awareness",
      "Develop empathy",
      "Listen actively",
      "Show respect for boundaries",
      "Be open to feedback",
      "Prioritize personal growth",
    ],
    dark: "#F7DE3B",
  },
  {
    title: "Inclusive-community",
    icon: CommunityIcon,
    options: [
      "Embrace diversity",
      "Create a culture of inclusivity",
      "Foster collaboration",
      "Listen actively",
      "Show respect",
      "Be a role model",
    ],
    dark: "#4ACADB",
  },
  {
    title: "Resilient-growth",
    icon: GrowthIcon,
    options: [
      "Embrace challenges",
      "Learn from mistakes",
      "Pursue knowledge",
      "Cultivate a growth mindset",
      "Take initiative",
      "Take calculated risks",
    ],
    dark: "#B07AD1",
  },
  {
    title: "Expert-leadership",
    icon: LeaderIcon,
    options: [
      "Develop expertise",
      "Lead by example",
      "Prioritize responsibility",
      "Communicate effectively",
      "Take action",
      "Continuously learn and improve",
    ],
    dark: "#FBA804",
  },
  {
    title: "Harmonious-coexistence",
    icon: HarmonyIcon,
    options: [
      "Build positive relationships",
      "Communicate effectively",
      "Encourage open communication",
      "Mediate conflicts",
      "Lead by example",
      "Promote forgiveness",
    ],
    dark: "#7DD04A",
  },
  {
    title: "Balanced-liberty",
    icon: BalancedIcon,
    options: [
      "Respect diversity",
      "Promote self-expression",
      "Embrace equality",
      "Create opportunities for participation",
      "Address bias & discrimination",
      "Foster a culture of accountability",
    ],
    dark: "#2BA9EF",
  },
];
