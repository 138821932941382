export const questions = [
  {
    number: "01",
    category: "Career Exploration",
    subtext:
      "This stage is for exploring a wide range of industries, roles, and skills to discover various career possibilities.",
    options: [
      {
        name: "Explore Diverse Career Landscapes",
        options: [
          {
            text: "Industry Awareness",
            subtext: "Learn about roles in various industries.",
          },
          {
            text: "Professional Environments",
            subtext: "Explore settings to find interests.",
          },
          {
            text: "Interdisciplinary Insights",
            subtext: "Study role interactions in industries.",
          },
          {
            text: "Start-Up Domains",
            subtext: "Discover skill alignments in startups.",
          },
          {
            text: "Industry AwarenessProfessional Roles",
            subtext: "Investigate roles within startup departments.",
          },
        ],
      },
      {
        name: "Build Professional Networks",
        options: [
          {
            text: "Industry Professionals",
            subtext: "Expand networks with industry experts.",
          },
          {
            text: "Start-Up Experts",
            subtext: "Connect with startup professionals.",
          },
          {
            text: "Mentorship Opportunities",
            subtext: "Gain guidance from experienced mentors.",
          },
          {
            text: "Professional Groups",
            subtext: "Join groups for industry insights.",
          },
          {
            text: "Networking Events",
            subtext: "Attend events with diverse professionals.",
          },
        ],
      },
      {
        name: "Develop Essential Skills and Knowledge",
        options: [
          {
            text: "Skill Identification",
            subtext: "Identify required skills across sectors.",
          },
          {
            text: "Hands-On Experience",
            subtext: "Apply skills in simulations and tasks.",
          },
          {
            text: "Targeted Skill Development",
            subtext: "Cultivate skills for specific domains.",
          },
          {
            text: "Foundational Understanding",
            subtext: "Understand basics of industry work.",
          },
          {
            text: "Real-World Application",
            subtext: "Engage in workplace-relevant activities.",
          },
        ],
      },
      {
        name: "Identify and Refine Personal and Professional Goals",
        options: [
          {
            text: "Strengths and Weaknesses",
            subtext: "Find personal attributes via industry exposure.",
          },
          {
            text: "Career Objectives",
            subtext: "Refine goals through experiences.",
          },
          {
            text: "Talent Discovery",
            subtext: "Unearth talents with professional help.",
          },
          {
            text: "Career Alignment",
            subtext: "Match strengths to industry demands.",
          },
          {
            text: "Self-Discovery Journeys",
            subtext: "Start explorations to find career fit.",
          },
        ],
      },
      {
        name: "Strategize for Long-Term Career Growth",
        options: [
          {
            text: "Strategic Planning",
            subtext: "Plan future steps using exploration insights.",
          },
          {
            text: "Informed Career Choices",
            subtext: "Make decisions based on industry knowledge.",
          },
          {
            text: "Professional Growth",
            subtext: "Grow through feedback and engagement.",
          },
          {
            text: "Career Advancement",
            subtext: "Navigate opportunities with career insights.",
          },
          {
            text: "Dynamic Workforce Entry",
            subtext: "Prepare for a dynamic career start.",
          },
        ],
      },
    ],
  },
  {
    number: "02",
    category: "Career Alignment",
    subtext:
      "This stage focuses on aligning students' skills and interests with potential career paths.",
    options: [
      {
        name: "Enhance Career Path with Expert Insights and Experiences",
        options: [
          {
            text: "Practical Experience",
            subtext: "Align aspirations through real tasks.",
          },
          {
            text: "Expert Discussions",
            subtext: "Engage in expert-led career discussions.",
          },
          {
            text: "Role Clarity",
            subtext: "Deepen understanding of specific roles.",
          },
          {
            text: "Mentorship",
            subtext: "Benefit from personalized career advice.",
          },
          {
            text: "Career Navigation",
            subtext: "Navigate choices with expert guidance.",
          },
        ],
      },
      {
        name: "Engage in Reflective and Practical Learning",
        options: [
          {
            text: "Alignment Tasks",
            subtext: "Participate in real job-related tasks.",
          },
          {
            text: "Professional Environments",
            subtext: "Explore fitting professional settings.",
          },
          {
            text: "Self-Reflection",
            subtext: "Reflect to clarify career direction.",
          },
          {
            text: "Skill Application",
            subtext: "Apply skills in professional scenarios.",
          },
          {
            text: "Inspiration from Leaders",
            subtext: "Gain insights from industry leaders.",
          },
        ],
      },
      {
        name: "Prepare for Modern and Remote Work Environments",
        options: [
          {
            text: "Remote Work Insights",
            subtext: "Understand remote work dynamics.",
          },
          {
            text: "Pod-Based Learning",
            subtext: "Prepare for pod collaborative work.",
          },
          {
            text: "Modern Skills",
            subtext: "Develop digital and remote work skills.",
          },
          {
            text: "Workflow Understanding",
            subtext: "Learn remote work responsibilities.",
          },
          {
            text: "Distributed Team Dynamics",
            subtext: "Master distributed team collaboration.",
          },
        ],
      },
      {
        name: "Develop Adaptive Skills for Evolving Work Settings",
        options: [
          {
            text: "Role-Specific Training",
            subtext: "Train for impactful roles.",
          },
          {
            text: "Remote Work Challenges",
            subtext: "Tackle real remote work projects.",
          },
          {
            text: "Global Networking",
            subtext: "Build a broad professional network.",
          },
          {
            text: "Adaptability",
            subtext: "Enhance adaptability in remote settings.",
          },
          {
            text: "Career Flexibility",
            subtext: "Explore flexible remote career paths.",
          },
        ],
      },
      {
        name: "Build Comprehensive Career Strategies",
        options: [
          {
            text: "Strategic Career Decisions",
            subtext: "Make informed career decisions.",
          },
          {
            text: "Professional Growth",
            subtext: "Grow through continuous learning.",
          },
          {
            text: "Networking and Relationships",
            subtext: "Foster supportive professional relationships.",
          },
          {
            text: "Practical Training",
            subtext: "Get trained to face professional challenges.",
          },
          {
            text: "Global Opportunities",
            subtext: "Pursue opportunities in global settings.",
          },
        ],
      },
    ],
  },

  {
    number: "03",
    category: "Career Activation",
    subtext:
      "This stage is about actively starting a career path through specialized training and skill development.",
    options: [
      {
        name: "Initiate Career Through Specialized Training and Experiences",
        options: [
          {
            text: "Role-Specific Training",
            subtext: "Undertake role-focused training.",
          },
          {
            text: "Practical Experiences",
            subtext: "Engage in field-specific tasks.",
          },
          {
            text: "Professional Identity",
            subtext: "Establish a unique professional presence.",
          },
          {
            text: "Industry Immersion",
            subtext: "Dive into relevant technologies.",
          },
          {
            text: "Career Path Activation",
            subtext: "Start career with focused experiences.",
          },
        ],
      },
      {
        name: "Develop Essential Professional Skills",
        options: [
          {
            text: "Technical Skills",
            subtext: "Build crucial technical skills.",
          },
          {
            text: "Transferable Skills",
            subtext: "Enhance versatile professional skills.",
          },
          {
            text: "Application in Real-World",
            subtext: "Implement skills in practical scenarios.",
          },
          {
            text: "Performance Assessments",
            subtext: "Undergo skill readiness evaluations.",
          },
          {
            text: "Continuous Learning",
            subtext: "Pursue ongoing technological learning.",
          },
        ],
      },
      {
        name: "Prepare for Leadership and Strategic Roles",
        options: [
          {
            text: "Strategic Decision-Making",
            subtext: "Train for strategic leadership.",
          },
          {
            text: "Leadership Skills",
            subtext: "Improve leadership and teamwork abilities.",
          },
          {
            text: "Mentorship from Leaders",
            subtext: "Gain insights from industry mentors.",
          },
          {
            text: "Career Path Clarification",
            subtext: "Define clear career objectives.",
          },
          {
            text: "Leadership Development",
            subtext: "Prepare for advanced leadership positions.",
          },
        ],
      },
      {
        name: "Enhance Career Readiness and Professional Growth",
        options: [
          {
            text: "Mentorship Guidance",
            subtext: "Receive career-start advice from experts.",
          },
          {
            text: "Practical Application",
            subtext: "Apply skills to exceed expectations.",
          },
          {
            text: "Career Readiness",
            subtext: "Refine skills through feedback.",
          },
          {
            text: "Collaborative Work",
            subtext: "Boost teamwork capabilities.",
          },
          {
            text: "Professional Growth",
            subtext: "Develop a competitive professional edge.",
          },
        ],
      },
      {
        name: "Establish a Foundation for Advanced Career Opportunities",
        options: [
          {
            text: "Technical Proficiency",
            subtext: "Master essential technical skills.",
          },
          {
            text: "Strategic Planning",
            subtext: "Implement strategic career planning.",
          },
          {
            text: "Industry-Specific Skills",
            subtext: "Adapt to new industry technologies.",
          },
          {
            text: "Professional Trajectory",
            subtext: "Align experiences with career goals.",
          },
          {
            text: "Professional Distinction",
            subtext: "Stand out in the job market.",
          },
        ],
      },
    ],
  },

  {
    number: "04",
    category: "Career Enhancement",
    subtext:
      "This stage is aimed at enhancing and refining skills and knowledge within a chosen career path.",
    options: [
      {
        name: "Strengthen Individual and Team Contributions",
        options: [
          {
            text: "Professional Excellence",
            subtext: "Deliver high-standard work.",
          },
          {
            text: "Strategic Decision-Making",
            subtext: "Develop team-oriented strategic skills.",
          },
          {
            text: "Role Enhancement",
            subtext: "Transition to leadership roles.",
          },
          {
            text: "Innovative Projects",
            subtext: "Tackle innovative challenges.",
          },
          {
            text: "Collaborative Learning",
            subtext: "Contribute and learn within the team.",
          },
        ],
      },
      {
        name: "Advance Leadership and Management Skills",
        options: [
          {
            text: "Leadership Development",
            subtext: "Build leadership capabilities.",
          },
          {
            text: "Performance Feedback",
            subtext: "Utilize feedback for growth.",
          },
          {
            text: "Responsibility Management",
            subtext: "Manage increasing responsibilities.",
          },
          {
            text: "Team Dynamics",
            subtext: "Navigate and enhance team interactions.",
          },
          {
            text: "Ownership and Initiative",
            subtext: "Lead and manage projects.",
          },
        ],
      },
      {
        name: "Foster Professional Growth and Adaptability",
        options: [
          {
            text: "Professional Growth",
            subtext: "Develop personally and professionally.",
          },
          {
            text: "Skill Refinement",
            subtext: "Keep skills updated with market trends.",
          },
          {
            text: "Adapting to Change",
            subtext: "Adjust to technological advancements.",
          },
          {
            text: "Problem Solving",
            subtext: "Improve processes through innovative solutions.",
          },
          {
            text: "Strategic Contributions",
            subtext: "Influence organizational success.",
          },
        ],
      },
      {
        name: "Expand Professional Networks and Collaborations",
        options: [
          {
            text: "Network Expansion",
            subtext: "Widen professional connections.",
          },
          {
            text: "Collaborative Interactions",
            subtext: "Engage actively with peers.",
          },
          {
            text: "Interdisciplinary Collaboration",
            subtext: "Collaborate across fields.",
          },
          {
            text: "Professional Interactions",
            subtext: "Gain insights through networking.",
          },
          {
            text: "Networking Skills",
            subtext: "Enhance networking effectiveness.",
          },
        ],
      },
      {
        name: "Demonstrate and Enhance Competencies in Competitive Settings",
        options: [
          {
            text: "Competency Demonstration",
            subtext: "Show ability in key responsibilities.",
          },
          {
            text: "Real-World Application",
            subtext: "Apply skills in practical settings.",
          },
          {
            text: "Competitive Excellence",
            subtext: "Strive for top performance.",
          },
          {
            text: "Feedback Utilization",
            subtext: "Use feedback to improve.",
          },
          {
            text: "Professional Excellence",
            subtext: "Maintain high professional standards.",
          },
        ],
      },
    ],
  },

  {
    number: "05",
    category: "Career Advancement",
    subtext:
      "This stage involves progressing to more advanced roles, often involving leadership or specialized expertise.",
    options: [
      {
        name: "Transition into Leadership and Ownership Roles",
        options: [
          {
            text: "Role Enhancement",
            subtext: "Shift from contributor to leader.",
          },
          {
            text: "Contract Handling",
            subtext: "Manage work contracts effectively.",
          },
          {
            text: "Team Ownership",
            subtext: "Own and lead teams.",
          },
          {
            text: "Leadership Pathway",
            subtext: "Define a leadership career path.",
          },
          {
            text: "Career Ownership",
            subtext: "Take full ownership of career progress.",
          },
        ],
      },
      {
        name: "Develop Advanced Leadership Capabilities",
        options: [
          {
            text: "Leadership Skills",
            subtext: "Enhance project oversight and team leadership.",
          },
          {
            text: "Strategic Leadership",
            subtext: "Make key organizational decisions.",
          },
          {
            text: "Performance Standards",
            subtext: "Uphold high leadership standards.",
          },
          {
            text: "Leadership Identity",
            subtext: "Establish a leadership professional identity.",
          },
          {
            text: "Responsibility Management",
            subtext: "Handle growing responsibilities.",
          },
        ],
      },
      {
        name: "Innovate and Drive Organizational Change",
        options: [
          {
            text: "Innovation Leadership",
            subtext: "Lead innovative projects.",
          },
          {
            text: "Strategic Decision-Making",
            subtext: "Shape organizational direction.",
          },
          {
            text: "Change Management",
            subtext: "Implement and manage change.",
          },
          {
            text: "Project Management",
            subtext: "Direct strategic projects.",
          },
          {
            text: "Organizational Impact",
            subtext: "Influence organizational outcomes.",
          },
        ],
      },
      {
        name: "Expand Influence and Professional Network",
        options: [
          {
            text: "Networking with Leaders",
            subtext: "Connect with top industry leaders.",
          },
          {
            text: "Influence Expansion",
            subtext: "Widen organizational and industry influence.",
          },
          {
            text: "High-Level Networking",
            subtext: "Network for career advancement.",
          },
          {
            text: "Professional Relationships",
            subtext: "Foster supportive professional ties.",
          },
          {
            text: "Industry Leadership",
            subtext: "Position as an industry leader.",
          },
        ],
      },
      {
        name: "Foster a Culture of Excellence and Improvement",
        options: [
          {
            text: "Professional Excellence",
            subtext: "Set industry standards.",
          },
          {
            text: "Continuous Improvement",
            subtext: "Lead ongoing enhancements.",
          },
          {
            text: "Performance Refinement",
            subtext: "Continuously improve leadership.",
          },
          {
            text: "Strategic Growth",
            subtext: "Propel team and organizational growth.",
          },
          {
            text: "Culture Leadership",
            subtext: "Develop a culture focused on excellence.",
          },
        ],
      },
    ],
  },
];
