export default function Option({
  name,
  idx,
  options,
  number,
  selected,
  setSelect,
  selectedSub,
  setSelectedSub,
  getSelectedCount,
}) {
  const active = selected?.[number]?.includes(idx);
  return (
    <div className="flex space-y-4 flex-col w-full">
      <div
        onClick={() => {
          const selectedOpts = { ...selected };
          const arr = selectedOpts[number] ?? [];

          if (arr.includes(idx)) {
            selectedOpts[number] = arr.filter((a) => a !== idx);
          } else {
            selectedOpts[number] = [...arr, idx];
          }

          setSelect(selectedOpts);
        }}
        className={`${
          active
            ? "border-[#ED4C41] text-[#ED4C41]"
            : "border-primary-grey-150 text-primary-neutral-800"
        } font-satoshi cursor-pointer rounded-2xl text-left border text-sm font-medium leading-5 tracking-[0.28px] py-5 px-6 flex flex-row items-center justify-between space-x-4`}
      >
        <p>{name}</p>
        {active && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9.00033 17.3307C4.39783 17.3307 0.666992 13.5999 0.666992 8.9974C0.666992 4.3949 4.39783 0.664062 9.00033 0.664062C13.6028 0.664062 17.3337 4.3949 17.3337 8.9974C17.3337 13.5999 13.6028 17.3307 9.00033 17.3307ZM9.00033 7.81906L6.64366 5.46156L5.46449 6.64073L7.82199 8.9974L5.46449 11.3541L6.64366 12.5332L9.00033 10.1757L11.357 12.5332L12.5362 11.3541L10.1787 8.9974L12.5362 6.64073L11.357 5.46156L9.00033 7.81906Z"
              fill="#282724"
            />
          </svg>
        )}
      </div>
      {active && (
        <div className="px-4 space-y-2 flex flex-col items-start">
          <p className="text-primary-neutral-400 font-lato text-xs font-bold leading-5 capitalize">
            Anything Specific you'd like to focus on?
          </p>
          <div className="divide-primary-grey-150 w-full">
            {options?.map((item, index) => {
              const isSelected = selectedSub?.[number]?.[idx]?.includes(index);
              return (
                <div
                  onClick={() => {
                    setSelectedSub((prevSelectedSub) => {
                      const selectedOpts = { ...prevSelectedSub };
                      const current = { ...selectedOpts[number] } ?? {};

                      const arr = current[idx] ? [...current[idx]] : [];

                      if (!arr.includes(index) && getSelectedCount() < 5) {
                        arr.push(index);
                      } else {
                        const indexPos = arr.indexOf(index);
                        arr.splice(indexPos, 1);
                      }

                      current[idx] = arr;
                      selectedOpts[number] = current;
                      return selectedOpts;
                    });
                  }}
                  className={`pl-4 w-full py-2 flex flex-row items-center space-x-4 cursor-pointer ${
                    isSelected ? "bg-[#fafafa]" : ""
                  }`}
                >
                  <input
                    className="accent-black text-black !shadow-none !ring-0"
                    type="checkbox"
                    checked={isSelected}
                  />
                  <div className=" flex flex-col items-start">
                    <h1 className="text-primary-neutral-800 font-satoshi text-xs font-medium capitalize leading-5">
                      {item?.text}
                    </h1>
                    <h1 className="text-primary-neutral-500 font-satoshi leading-4 text-2xs">
                      {item?.subtext}
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
