import CheckPoint from "components/PhaseTwoForm/MobileForm/components/CheckpointForm/CheckPoint";
import ScrollToTopButton from "components/ProfileForm/ui-components/ScrollToTopButton";
import { useEffect, useRef, useState } from "react";

const WebCheckpointForm = () => {
  const intersectionRef = useRef(null);
  const [showUpButton, setShowUpButton] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowUpButton(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) observer.unobserve(intersectionRef.current);
    };
  }, [intersectionRef]);
  return (
    <div className=" font-manrope h-full    ">
      <div ref={intersectionRef} className="flex flex-col p-4 md:px-10 gap-2">
        <h4 className="text-3xl text-primary-blue-400  font-medium ">
          Get to know yourself better!
        </h4>
        <h4 className="text-xl text-primary-grey-500  font-medium ">
          Find out where your heart lies in 5 simple steps
        </h4>
        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light">
          You are the one who knows yourself best! We are here to help you ask
          the right questions, so that you can better align your efforts towards
          your purpose.
        </p>
      </div>
      <div className="py-20">
        <CheckPoint />
      </div>
      {showUpButton && <ScrollToTopButton />}
    </div>
  );
};

export default WebCheckpointForm;
