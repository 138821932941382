import { useState } from "react";
import Starting from "./Starting";
import FormSubmitted from "./FormSubmitted";
import { assessments } from "./BoostCareer";
import { useLocation } from "react-router-dom";
import AlreadySubmitted from "./AlreadySubmitted";
import Riasec from "./forms/Riasec";
import AI5 from "./forms/AI5";
import AgileFit from "./forms/AgileFit";
import WorkPreferences from "./forms/WorkPreferences";
import ValueAssessment from "./forms/ValueAssessment";

export default function Assessment() {
  const [page, setPage] = useState(1);
  const { pathname } = useLocation();
  const assessment = assessments.find((a) => a.path === pathname);

  const [completed, setCompleted] = useState(false);

  if (completed) return <FormSubmitted />;

  if (assessment.isCompleted) return <AlreadySubmitted />;

  const getComponent = (pathname) => {
    switch (pathname) {
      case "/assessments/RIASEC":
        return (
          <Riasec page={page} setPage={setPage} setCompleted={setCompleted} />
        );
      case "/assessments/AI5":
        return (
          <AI5 page={page} setPage={setPage} setCompleted={setCompleted} />
        );
      case "/assessments/AgileFit":
        return (
          <AgileFit page={page} setPage={setPage} setCompleted={setCompleted} />
        );
      case "/assessments/WorkPreferences":
        return (
          <WorkPreferences
            page={page}
            setPage={setPage}
            setCompleted={setCompleted}
          />
        );
      case "/assessments/valueAssessment":
        return (
          <ValueAssessment
            page={page}
            setPage={setPage}
            setCompleted={setCompleted}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="bg-white fixed top-0 bottom-0 right-0 left-0 z-50 overflow-y-auto">
      {page === 1 ? (
        <Starting page={page} setPage={setPage} assessment={assessment} />
      ) : (
        <>{getComponent(pathname)}</>
      )}
    </div>
  );
}
