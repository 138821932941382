import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { FormComponents } from "helpers/constants/phaseTwoForm";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateQuizStep, updateStep } from "redux/profile-form";
import RealisticIcon from "../../../MobileForm/components/QuizForm/assets/riasec/realistic.svg";
import InvestigateIcon from "../../../MobileForm/components/QuizForm/assets/riasec/investigative.svg";
import ArtisticIcon from "../../../MobileForm/components/QuizForm/assets/riasec/artistic.svg";
import SocialIcon from "../../../MobileForm/components/QuizForm/assets/riasec/social.svg";
import EnterprisingIcon from "../../../MobileForm/components/QuizForm/assets/riasec/enterprising.svg";
import ConventionalIcon from "../../../MobileForm/components/QuizForm/assets/riasec/conventional.svg";
import RIASECIcon from "../../../MobileForm/components/QuizForm/assets/riasec/RIASEC.svg";
import { AnimatePresence, motion } from "framer-motion";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { RiasecOptions } from "helpers/constants/profileForm";
import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

export const RiasecData = [
  {
    title: "Realistic",
    icon: RealisticIcon,
    value: 20,
    text: "Lörem ipsum megahiligt mononat, då nen så digada har dining savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra om prett, hemin.",
  },
  {
    title: "Investigative",
    icon: InvestigateIcon,
    value: 40,
    text: "Lörem ipsum megahiligt mononat, då nen så digada har dining savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra om prett, hemin.",
  },
  {
    title: "Artistic",
    icon: ArtisticIcon,
    value: 30,
    text: "Lörem ipsum megahiligt mononat, då nen så digada har dining savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra om prett, hemin.",
  },
  {
    title: "Social",
    icon: SocialIcon,
    value: 10,
    text: "Lörem ipsum megahiligt mononat, då nen så digada har dining savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra om prett, hemin.",
  },
  {
    title: "Enterprising",
    icon: EnterprisingIcon,
    value: 0,
    text: "Lörem ipsum megahiligt mononat, då nen så digada har dining savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra om prett, hemin.",
  },
  {
    title: "Conventional",
    icon: ConventionalIcon,
    value: 0,
    text: "Lörem ipsum megahiligt mononat, då nen så digada har dining savoren vanade, dinas derat gyr minde vaskapet ultranar kod måra om prett, hemin.",
  },
];

export const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-primary-grey-300  py-[10px] px-2">
      <button
        className="flex items-center justify-between w-full focus:outline-none"
        onClick={toggleAccordion}
      >
        <h3 className="font-satoshi font-bold text-sm">{title}</h3>
        <span className="transform duration-200 ease-in-out font-bold">
          {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        </span>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="overflow-hidden"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto" },
              collapsed: { height: 0 },
            }}
            transition={{ duration: 0.3 }}
          >
            <div className="mt-2 text-sm leading-6 font-satoshi">{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const RiasecStats = ({ close, quizData }) => {
  const [riasecData, setRiasecData] = useState(RiasecData);
  const { step: formStep, details } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const dispatch = useDispatch();
  const totalSteps = FormComponents(formStep, details).length;

  const getRiasecPercentage = () => {
    let score = {
      Realistic: 0,
      Investigative: 0,
      Artistic: 0,
      Social: 0,
      Enterprising: 0,
      Conventional: 0,
    };
    let allOptions = [];
    quizData.forEach((quiz) =>
      quiz.options.forEach((option) => allOptions.push(option))
    );

    for (var i = 0; i < allOptions.length; i++) {
      const option = allOptions[i];
      for (var j = 0; j < RiasecOptions.length; j++) {
        if (RiasecOptions[j].options.includes(option)) {
          score[RiasecOptions[j].title]++;
        }
      }
    }
    const updatedRiasec = RiasecData.map((_riasec) => ({
      ..._riasec,
      value: (score[_riasec.title] / allOptions.length) * 100,
    }));
    return updatedRiasec;
  };

  useEffect(() => {
    const updatedRiasec = getRiasecPercentage();
    setRiasecData(updatedRiasec);
  }, [quizData]);

  return (
    <div className="px-20">
      <div className={`flex w-full  py-4 font-inter `}>
        <div className="shadow-md bg-white flex rounded-full p-1 px-3 ">
          <img
            src="/assets/images/logo/caaryaMe.svg"
            alt=""
            className="h-6 object-contain"
          />
        </div>
      </div>

      <div className=" min-h-screen ">
        <div className=" w-full px-28 py-8   ">
          <div className="flex flex-col gap-10 w-full max-w-4xl mx-auto pb-20 ">
            <h1 className="text-primary-blue-500 text-xl font-medium text-center ">
              {details.name}’s Interest Profile
            </h1>

            <div className="grid grid-cols-6 h-60">
              {riasecData.map((_sub, idx) => (
                <div
                  key={idx}
                  className="flex flex-col justify-end items-center h-full w-full "
                >
                  <div className="flex flex-col items-center justify-end h-full ">
                    <p className="font-satoshi font-bold text-xs text-center">
                      {_sub.value}%
                    </p>
                    <div
                      style={{ height: _sub.value + 10 + "%" }}
                      className="w-14 rounded-t-xl bg-gradient-to-b from-[#FF8800] to-[#F7DE3B] "
                    ></div>
                  </div>
                  <div className="flex flex-col items-center p-2 border-t border-primary-grey-500 w-full ">
                    <img
                      src={_sub.icon}
                      alt=""
                      className="w-8 h-8 object-contain"
                    />
                    <p className="font-satoshi font-bold text-sm text-center">
                      {_sub.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex gap-14">
              <img
                src={RIASECIcon}
                alt=""
                className="h-60 w-60 object-contain flex-1 "
              />
              <div className=" w-full overflow-y-scroll h-80 pr-6">
                <div className="flex flex-col gap-2 font-satoshi text-sm">
                  <p className=" font-bold  ">What does this mean?</p>
                  <p className=" leading-6 ">
                    Lörem ipsum megahiligt mononat, då nen så digada har dining
                    savoren vanade, dinas derat gyr minde vaskapet ultranar kod
                    måra om prett, hemin. Dint prena utom stenosat: i polyr
                    abevis os och gatid avism då dolysm sore miss inklusive
                    lytöktig omönat suvis den våper exosamma.
                  </p>
                </div>
                {riasecData.map((_sub, idx) => (
                  <Accordion key={idx} title={_sub.title} content={_sub.text} />
                ))}
              </div>
            </div>
            <button
              onClick={close}
              className="font-satoshi text-md p-4 font-bold text-primary-blue-500"
            >
              Make Changes
            </button>
            {/* navigation ui starts */}
            <div className="bg-white z-10 fixed bottom-0 inset-x-0">
              <div className="h-1 w-full bg-primary-grey-200 relative">
                <div
                  style={{ width: (formStep / totalSteps) * 100 + "%" }}
                  className={`absolute  left-0 top-0 bottom-0 bg-primary-blue-500 transition-all duration-300 ease-in-out rounded-r-full `}
                ></div>
              </div>

              <div className="flex justify-between relative  gap-6 items-center p-4 lg:py-2 lg:px-20">
                <ProfileButton
                  onClick={close}
                  text={"Back"}
                  size={"large"}
                  icon={<ArrowLeftIcon className="w-6" />}
                  iconAlign={"left"}
                />
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                  <ProfileButton
                    onClick={() => dispatch(updateStep(formStep + 1))}
                    text={"Continue"}
                    size={"large"}
                    variant={"filled"}
                    icon={<ChevronRightIcon className="w-6" />}
                    iconAlign={"right"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiasecStats;
