import moment from "moment";
import { useEffect, useState } from "react";

const dummyData = [
  {
    eventStart: "2024-04-22T08:00:46.980Z",
    eventEnd: "2024-04-22T09:00:46.980Z",
    title: "Design Demo",
    link: "https://abc.com",
  },
  {
    eventStart: "2024-04-22T15:00:46.980Z",
    eventEnd: "2024-04-22T16:00:46.980Z",
    title: "Night Talks",
    link: "https://abc.com",
  },
  {
    eventStart: "2024-04-24T11:00:46.980Z",
    eventEnd: "2024-04-24T12:00:46.980Z",
    title: "Ted Talk Mornings",
    link: "https://abc.com",
  },
  {
    eventStart: "2024-04-25T12:00:46.980Z",
    eventEnd: "2024-04-25T13:00:46.980Z",
    title: "Weekly Catchup",
    link: "https://abc.com",
  },
];

export default function CuratedExperiences() {
  const [dates, setDates] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const getSprint = () => {
    const today = moment();
    const currentWeekStartDate = moment().startOf("isoWeek");

    const allDates = [];
    let currentDate = currentWeekStartDate.clone();

    while (currentDate.isSameOrBefore(today)) {
      allDates.push(currentDate.clone());
      currentDate.add(1, "day");
    }
    setDates(allDates);
  };
  useEffect(() => {
    getSprint();
  }, []);

  const getEventsForDate = (date) => {
    const formattedDate = date.format("YYYY-MM-DD"); // Format date to match event date format
    return dummyData.filter(
      (event) => moment(event.eventStart).format("YYYY-MM-DD") === formattedDate
    );
  };

  return (
    <div className="!my-0">
      <div className="h-2 bg-primary-neutral-100 w-full"></div>
      <div className="py-6 flex flex-col gap-2 items-start">
        <div className="font-satoshi text-sm font-bold px-4 text-primary-neutral-800">
          {moment(selectedDate).format("MMMM")}
        </div>
        <div className="flex justify-start items-center self-stretch overflow-x-auto">
          {dates?.map((d, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setSelectedDate(d);
                  let el = document.getElementById(moment(d).toISOString());
                  if (el) {
                    el.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className={`p-2 flex flex-col cursor-pointer gap-2 w-[48px] rounded-lg hover:bg-primary-neutral-50 font-lato ${
                  selectedDate === d ? "bg-primary-neutral-50" : ""
                }`}
              >
                <span className="text-xs w-[32px] h-[32px] font-normal text-primary-neutral-500 text-center flex justify-center items-center">
                  {d.format("dd")[0]}
                </span>
                <span
                  className={`relative text-sm w-[32px] h-[32px] rounded-full font-bold text-center text-primary-neutral-800 flex flex-col gap-2 justify-center items-center ${
                    d.isSame(moment(), "day") ? "bg-[#EAEAF9]" : ""
                  }`}
                >
                  {d.format("DD")}
                  {getEventsForDate(d)?.length > 0 && (
                    <div className="h-1 w-1 bg-[#816FE9] rounded-full absolute bottom-1"></div>
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="h-2 bg-primary-neutral-100 w-full"></div>
      <div className="py-6 flex flex-col items-start gap-10 bg-primary-neutral-50 font-lato">
        <div className="px-4 flex flex-col gap-6 w-full">
          <div className="flex gap-4 items-start">
            <p className="text-sm font-bold text-primary-neutral-800">
              {moment().format("MMM")} {moment().format("DD")}
            </p>
            <p className="text-sm font-normal text-primary-neutral-500">
              Today
            </p>
          </div>
          <div className="flex flex-col gap-4 w-full">
            {getEventsForDate(moment())?.length > 0 ? (
              getEventsForDate(moment()).map((event, i) => {
                return <Card event={event} key={i} />;
              })
            ) : (
              <div className="h-20 flex items-center justify-center rounded-2xl bg-primary-neutral-100">
                <span className="text-primary-neutral-300 text-xs font-bold">
                  Nothing Scheduled For Today
                </span>
              </div>
            )}
          </div>
        </div>
        {dates?.map((date, i) => {
          const events = getEventsForDate(date);
          if (events?.length === 0 || date.isSame(moment(), "day")) return;

          return (
            <div
              className="px-4 flex flex-col gap-6 w-full"
              key={i}
              id={moment(date).toISOString()}
            >
              <div className="flex gap-4 items-start">
                <p className="text-sm font-bold text-primary-neutral-800">
                  {moment(date).format("MMM")} {moment(date).format("DD")}
                </p>
                <p className="text-sm font-normal text-primary-neutral-500">
                  {date.format("dddd")}
                </p>
              </div>
              <div className="flex flex-col gap-4 w-full">
                {events.map((event, i) => {
                  return <Card event={event} key={i} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const Card = ({ event }) => {
  return (
    <div className="p-4 flex gap-4 items-center bg-white rounded-2xl border border-primary-neutral-100 shadow-md">
      <div className="h-12 !w-12 ">
        <img
          src="https://cdn.pixabay.com/photo/2016/07/07/16/46/dice-1502706_640.jpg"
          alt=""
          className="h-full w-full object-cover rounded-lg"
        />
      </div>
      <div
        className="flex flex-col gap-2"
        style={{
          flex: "1 0 0",
        }}
      >
        <h4 className="text-black font-semibold">{event.title}</h4>
        <p className="text-xs text-primary-neutral-500 font-normal">
          {moment(event.eventStart).format("hh:mm a")} -{" "}
          {moment(event.eventEnd).format("hh:mm a")}
        </p>
      </div>
      <button className="text-[#816FE9] border border-[#816FE9] rounded-lg font-bold text-xs py-1.5 px-3">
        Join
      </button>
    </div>
  );
};
