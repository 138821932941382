import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import usePhaseTwo from "components/PhaseTwoForm/hooks/usePhaseTwo";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";

const WebStepOne = () => {
  const { handleBackButtonClick, handleStepChangeButtonClick } = usePhaseTwo();

  return (
    <div className="flex justify-between px-28 py-20 gap-10 ">
      <div className=" font-manrope w-1/2 flex flex-col gap-10 ">
        <h4 className="text-xl text-primary-grey-500 font-light">
          STEP 1 :
          <span className="text-primary-red-500 font-medium ml-1 ">
            What I Love
          </span>
        </h4>

        <p className="text-md font-satoshi text-primary-grey-500">
          You cannot go wrong doing the things you love. Figuring out and
          understanding what gives you happiness will help you in your efforts
          to find joy in your daily tasks.
        </p>
        <p className="text-md font-satoshi text-primary-grey-500">
          In the upcoming section, take a minute of introspection before
          answering the questions. Focus on the things that give you joy. You
          don’t necessarily have to be good at it. All that matters is that you
          love doing it!
        </p>

        <div>
          <ProfileButton
            onClick={handleStepChangeButtonClick}
            disabled={false}
            text={"Lets Go !"}
            size={"large"}
            variant={"filled"}
          />{" "}
          <p className="text-xs font-satoshi text-primary-grey-400 my-1">
            or press tab to continue
          </p>
        </div>
        <div>
          {" "}
          <ProfileButton
            onClick={handleBackButtonClick}
            text={"Back"}
            size={"large"}
            icon={<ArrowLeftIcon className="w-6" />}
            iconAlign={"left"}
          />
        </div>
      </div>
      <div className="flex w-1/2 flex-col items-end justify-center ">
        <img
          className="w-full max-w-[450px] object-contain"
          src="https://www.gynprog.com.br/wp-content/uploads/2017/06/wood-blog-placeholder.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default WebStepOne;
