import LoginCard from "./LoginCard";

function SignIn() {
  return (
    <section
      className={`relative bg-white h-[100vh] max-w-[100vw] overflow-y-hidden flex flex-col items-center`}
    >
      <div className="flex relative header flex-col md:min-h-[30vh] pt-10 items-center space-y-2 lg:space-y-3">
        <div className="items-center space-y-2 lg:space-y-3 flex flex-col lg:min-h-[26vh] justify-center">
          <img
            src="/assets/images/life.jpg"
            alt=""
            className="w-16 h-16 lg:w-20 rounded-full lg:h-20"
          />
          <p className="font-poppins font-semibold text-[20px] text-black ">
            caarya
          </p>

          <p className="font-lato capitalize text-black text-sm font-light tracking-[0.84px]">
            Own Your Career
          </p>
        </div>
        {/* <!--Waves Container--> */}
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
        {/* <!--Waves end--> */}
      </div>
      <LoginCard />
    </section>
  );
}

export default SignIn;
