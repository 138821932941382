import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { ReactComponent as Daylight } from "assets/FocusArea/daylight.svg";
import { ReactComponent as Nightwatch } from "assets/FocusArea/nightwatch.svg";
import { ReactComponent as Sundown } from "assets/FocusArea/sundown.svg";
import moment from "moment";

const options = [
  {
    title: "Daylight",
    timeRange: "04:00 A.M - 12:00 P.M",
    icon: <Daylight className="text-[#FFBC00]" />,
  },
  {
    title: "Sundown",
    timeRange: "12:00 P.M - 08:00 P.M",
    icon: <Sundown className="text-[#F57D34]" />,
  },
  {
    title: "Nightwatch",
    timeRange: "08:00 P.M - 04:00 A.M",
    icon: <Nightwatch className="text-[#6775F5]" />,
  },
];

function SwitchZone({
  closeModal,
  isOpen,
  setSelectedFocus,
  selectedFocus,
  getGoals,
}) {
  const [sortedOptions, setSortedOptions] = useState([]);
  useEffect(() => {
    let time = moment().format("HH");

    if (time >= 0 && time <= 8) {
      setSortedOptions([
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
      ]);
    } else if (time > 8 && time <= 16) {
      setSortedOptions([
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
      ]);
    } else {
      setSortedOptions([
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
      ]);
    }
  }, []);
  return (
    <>
      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        PaperProps={{
          style: {
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100%" : "560px",
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0.25) !important",
          },
        }}
        open={isOpen}
        onClose={() => {
          closeModal();
        }}
        transitionDuration={250}
      >
        <button
          aria-label="Close"
          type="button"
          onClick={() => {
            closeModal();
          }}
          className="self-center mb-2 bg-primary-gray-1000 p-2 rounded-full"
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
        <div
          style={{
            boxShadow: "-5px 0px 10px #E7E6E5",
          }}
          className="rounded-t-2xl lg:rounded-t-none bg-white relative md:max-w-xl  lg:h-screen lg:pt-20 mx-auto w-full transform transition-all ease-in-out duration-150"
        >
          <div className="flex flex-row items-end justify-between p-6 rounded-t-3xl lg:rounded-t-none border-b border-b-primary-neutral-300">
            <h5 className="font-satoshi font-bold text-lg text-primary-neutral-800 capitalize">
              Switch Zone in View
            </h5>
          </div>
          <div className="px-4 pt-6 pb-12 flex flex-col gap-2 items-center">
            {sortedOptions.map((opt, i) => {
              const isSelected = selectedFocus === opt.title;
              return (
                <div
                  className={`p-3 w-full flex items-center justify-between gap-4 rounded-[24px] cursor-pointer ${
                    isSelected
                      ? "rounded-20px border border-primary-neutral-800"
                      : ""
                  }`}
                  key={i}
                  onClick={() => setSelectedFocus(opt.title)}
                >
                  <div className="flex items-center gap-4">
                    {/* <img src={opt.icon} alt="" /> */}
                    {opt.icon}

                    <div
                      className={`flex flex-col font-lato ${
                        isSelected
                          ? "text-primary-neutral-800"
                          : "text-primary-neutral-400"
                      } `}
                    >
                      <div className="font-bold capitalize leading-6">
                        {opt.title}
                      </div>
                      <div
                        className="text-ellipsis text-sm overflow-hidden font-normal leading-5"
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {opt.timeRange}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-center w-7 h-7 rounded-full ${
                      isSelected
                        ? "bg-primary-neutral-800 text-white"
                        : "bg-primary-neutral-100"
                    }`}
                  >
                    <div
                      className="text-ellipsis text-sm font-bold overflow-hidden leading-5"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {getGoals(opt.title)?.length || 0}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default SwitchZone;
