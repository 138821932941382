import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  MdClose,
  MdDeleteOutline,
  MdDone,
  MdDragIndicator,
} from "react-icons/md";
import ContinuousSearch from "./ContinuousSearch";
import InterestTimeDropdown from "./InterestTimeDropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/profile-form";
import uuid from "react-uuid";
import { interestSearchList } from "./utils/constant";
import { scrollToBottom } from "helpers/utils/profileForm/functions";

const InterestCard = ({
  setShowNewCard,
  type = "card",
  data,
  field,
  isFirstCard = false,
}) => {
  const [interest, setInterest] = useState(
    data
      ? data
      : {
          timeframe: isFirstCard ? "Presently" : "",
          pictured: "",
          interest: "",
          present: false,
        }
  );
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const [showEdit, setShowEdit] = useState(type === "new" ? true : false);
  const [showContinuousSearch, setShowContinuousSearch] = useState(false);
  const [interestSearch, setInterestSearch] = useState("");
  const interestSearchRef = useRef(null);
  const interestCardRef = useRef(null);
  const dispatch = useDispatch();

  const isButtonDisabled = useMemo(() => {
    return Object.values(interest).includes("") ? true : false;
  }, [interest]);

  const handleInterestSearch = (e) => {
    if (e.target.value.length > 2) {
      setShowContinuousSearch(true);
    } else {
      setShowContinuousSearch(false);
    }
    setInterestSearch(e.target.value);
  };
  const handleInterestDeletion = () => {
    setInterest({ ...interest, interest: "" });
    interestSearchRef.current.focus();
    setInterestSearch("");
  };

  const handleInterestSelection = (val) => {
    if (details[field].length >= 2) {
      scrollToBottom();
    }
    if (details[field].find((interest) => interest.id === val.id)) {
      const res = details[field].map((interest) => {
        if (interest.id === val.id) return { ...val };

        return interest;
      });

      dispatch(updateDetails({ field, value: res }));
      setShowEdit(false);
      return;
    }
    let res = [];
    if (isFirstCard) {
      res = [...details[field], { ...val, present: true, index: -1 }];
    } else {
      res = [...details[field], { ...val }];
    }

    dispatch(updateDetails({ field, value: res }));
    if (type === "new") {
      setShowNewCard(false);
    }
  };

  const handleDeleteInterest = () => {
    const res = details[field].filter((interest) => interest.id !== data.id);
    dispatch(updateDetails({ field, value: res }));
  };
  const handleEnableEdit = () => {
    if (type === "new") return;
    if (showEdit) return;

    setShowEdit(true);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        interestCardRef.current &&
        !interestCardRef.current.contains(event.target) &&
        type !== "new"
      ) {
        setShowEdit(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [interestCardRef]);

  return (
    <div>
      <div className="flex justify-between pb-3 sm:pb-0 items-center gap-10">
        <div id="hover_view_elements" className=" sm:hidden">
          <MdDragIndicator className="text-primary-grey-200 text-3xl" />
        </div>{" "}
        {type !== "new" ? (
          <>
            {interest.present === false && (
              <button
                id="hover_view_elements"
                onClick={handleDeleteInterest}
                className="w-10 h-10 grid place-items-center bg-primary-grey-150 rounded-full text-primary-grey-400 text-xl sm:hidden "
              >
                <MdDeleteOutline />
              </button>
            )}
          </>
        ) : (
          <button
            id="hover_view_elements"
            disabled={isFirstCard}
            onClick={() => setShowNewCard(false)}
            className="w-10 h-10 grid place-items-center bg-primary-grey-150 rounded-full text-primary-grey-400 text-xl sm:hidden "
          >
            <MdClose />
          </button>
        )}
      </div>
      <div id="hover_view" className="flex gap-4">
        <div id="hover_view_elements" className="py-8 hidden sm:block ">
          <MdDragIndicator className="text-primary-grey-200 text-3xl" />
        </div>
        <div
          onClick={handleEnableEdit}
          ref={interestCardRef}
          className="w-full flex flex-col gap-4"
        >
          <div
            className={`p-4 py-6 md:p-6 flex flex-col gap-6 rounded-3xl ${
              showEdit ? "border-primary-blue-500" : "border-primary-grey-300"
            } border   w-full `}
          >
            <div className="flex flex-col sm:flex-row gap-2  sm:items-center">
              <h4 className="font-satoshi text-md text-primary-grey-800">
                {isFirstCard || interest.present
                  ? "Presently, I am interested in"
                  : "During My"}
              </h4>
              {!isFirstCard && !interest.present && (
                <div className="flex-1">
                  {showEdit ? (
                    <InterestTimeDropdown
                      interest={interest}
                      setInterest={setInterest}
                    />
                  ) : (
                    <div className="p-4  border-b border-primary-yellow-500 font-bold">
                      {interest.timeframe}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
              <h4 className="font-satoshi text-md text-primary-grey-800">
                {!isFirstCard && !interest.present && (
                  <span>I was interested in</span>
                )}
              </h4>

              {showEdit ? (
                <div className=" flex-1 px-2 sm:px-4 py-2 flex gap-2 border-b border-primary-yellow-500 w-full ">
                  <div
                    onClick={() =>
                      setInterest({ ...interest, pictured: "becoming a/an" })
                    }
                    className={`p-1 px-4 rounded-full border text-sm cursor-pointer font-bold ${
                      interest.pictured === "becoming a/an"
                        ? " border-primary-yellow-500 bg-primary-yellow-50 "
                        : "text-primary-grey-400 border-primary-grey-200"
                    } `}
                  >
                    becoming a/an
                  </div>
                  <div
                    onClick={() =>
                      setInterest({
                        ...interest,
                        pictured: "doing something in",
                      })
                    }
                    className={`p-1 px-4 rounded-full border text-sm cursor-pointer font-bold ${
                      interest.pictured === "doing something in"
                        ? " border-primary-yellow-500 bg-primary-yellow-50 "
                        : "text-primary-grey-400 border-primary-grey-200 "
                    } `}
                  >
                    doing something in
                  </div>
                </div>
              ) : (
                <div className=" flex-1 px-2 sm:px-4 py-2 flex gap-2 border-b border-primary-yellow-500 w-full ">
                  <div
                    className={`p-1 px-4 rounded-full border text-sm  font-bold ${
                      interest.pictured === "becoming a/an"
                        ? " border-primary-yellow-500 bg-primary-yellow-50 "
                        : "text-primary-grey-400 border-primary-grey-200"
                    } `}
                  >
                    becoming a/an
                  </div>
                  <div
                    className={`p-1 px-4 rounded-full border text-sm  font-bold ${
                      interest.pictured === "doing something in"
                        ? " border-primary-yellow-500 bg-primary-yellow-50 "
                        : "text-primary-grey-400 border-primary-grey-200 "
                    } `}
                  >
                    doing something in
                  </div>
                </div>
              )}
            </div>
            <div className="relative w-full md:max-w-max md:min-w-[50%]">
              {showEdit ? (
                <div className="p-4  text-sm font-bold font-satoshi border-b border-primary-yellow-500 w-full">
                  {interest.interest.length > 0 && (
                    <div className="lg:px-10">
                      <div className="flex gap-2  bg-primary-yellow-50 p-2 px-4 max-w-max rounded-lg ">
                        <p>{interest.interest}</p>{" "}
                        <button
                          disabled={isFirstCard}
                          onClick={handleInterestDeletion}
                        >
                          <MdClose />
                        </button>
                      </div>
                    </div>
                  )}
                  <input
                    ref={interestSearchRef}
                    value={interestSearch}
                    placeholder="Type your interest"
                    onChange={handleInterestSearch}
                    className={` outline-none w-full md:px-10 ${
                      interest.interest.length > 0 && "hidden"
                    } `}
                  />
                </div>
              ) : (
                <div className="p-4  text-sm font-bold font-satoshi border-b border-primary-yellow-500 w-full">
                  {interest.interest.length > 0 && (
                    <div className="lg:px-10">
                      <div className="flex gap-2  bg-primary-yellow-50 p-2 px-4 max-w-max rounded-lg ">
                        <p>{interest.interest}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {showContinuousSearch && (
                <div className="absolute top-full left-0 w-full z-50">
                  <ContinuousSearch
                    searchInput={interestSearch}
                    interest={interest}
                    setInterest={setInterest}
                    close={() => setShowContinuousSearch(false)}
                    interestSearchList={interestSearchList}
                  />
                </div>
              )}
            </div>
            {type === "new" ? (
              <div className="flex justify-end ">
                <button
                  disabled={isButtonDisabled}
                  onClick={() =>
                    handleInterestSelection({ ...interest, id: uuid() })
                  }
                  className="flex gap-3 items-center font-satoshi text-primary-blue-500 text-md font-bold disabled:text-primary-grey-300 "
                >
                  <MdDone className="text-lg" /> Add Now
                </button>
              </div>
            ) : (
              <Fragment>
                {showEdit && (
                  <div className="flex justify-between items-center gap-10">
                    <button
                      onClick={() => setShowEdit(false)}
                      className="flex gap-3 items-center font-satoshi text-primary-red-500 text-md font-bold"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isButtonDisabled}
                      onClick={() => handleInterestSelection(interest)}
                      className="flex gap-3 items-center font-satoshi text-primary-blue-500 text-md font-bold disabled:text-primary-grey-300 "
                    >
                      Save
                    </button>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {type === "new" && (
            <>
              {isFirstCard ? (
                <p className="font-satoshi text-xs px-4 ">
                  Eg. Presently, I am interested in doing something in Marketing
                </p>
              ) : (
                <p className="font-satoshi text-xs px-4 ">
                  Eg. During My <strong>my Plus 2</strong>, I pictured myself{" "}
                  <strong>doing something in, marketing</strong>
                </p>
              )}
            </>
          )}
        </div>
        {type !== "new" ? (
          <>
            {interest.present === false ? (
              <button
                id="hover_view_elements"
                onClick={handleDeleteInterest}
                className="w-10 h-10  place-items-center bg-primary-grey-150 rounded-full text-primary-grey-400 text-xl hidden sm:grid "
              >
                <MdDeleteOutline />
              </button>
            ) : (
              <div className="w-10 h-10 hidden sm:grid "></div>
            )}
          </>
        ) : (
          <button
            id="hover_view_elements"
            onClick={() => setShowNewCard(false)}
            disabled={isFirstCard}
            className="w-10 h-10  place-items-center bg-primary-grey-150 rounded-full text-primary-grey-400 text-xl  hidden sm:grid"
          >
            <MdClose />
          </button>
        )}
      </div>
    </div>
  );
};

export default InterestCard;
