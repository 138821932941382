import fiIcon from "assets/svg/fiIcon.svg";
import rank from "assets/svg/rank.svg";
import rectangle from "assets/images/icons/rectangle.png";
import ConfirmDeactivate from "./ConfirmDeactivateModal";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";

export const Heading = ({ title }) => (
  <div className="w-full flex justify-between items-center gap-4">
    <h1 className="text-primary-neutral-800 font-bold font-lato  text-base">
      {title}
    </h1>
    <div className="w-5 h-5 bg-primary-neutral-50 rounded-full border border-stone-300 flex justify-center items-center">
      <div className="text-center text-stone-400 text-[10px] font-black font-lato ">
        ?
      </div>
    </div>
  </div>
);

const ProficiencyCard = ({ value, title }) => (
  <div className="flex p-3 sm:p-6 flex-col items-center gap-1 sm:gap-2 flex-1 border border-primary-neutral-100 rounded-3xl">
    <div className="text-center text-[32px] leading-[40px] font-bold font-lato tracking-[1.6px] text-black">
      {value}
    </div>
    <p className="text-primary-neutral-500 text-center font-normal text-[12px] font-satoshi leading-5">
      {title}
    </p>
  </div>
);

const WorkContracts = () => (
  <div className="flex p-4 flex-col items-start gap-5 rounded-[20px] border border-primary-neutral-100 bg-white w-full">
    <div className="flex items-center gap-4">
      <img
        src="/assets/images/achievement.png"
        className="w-10 h-10 rounded-lg"
      />
      <div className="flex-col flex font-satoshi flex-1">
        <div className="text-black text-sm font-bold  capitalize leading-5 tracking-wide">
          User Research Studies
        </div>
        <div className="text-primary-neutral-500 text-2xs font-normal capitalize leading-[21px] tracking-wider">
          UX Designer
        </div>
      </div>
    </div>
    <p
      className="text-primary-neutral-800 font-normal font-satoshi text-[10px] leading-[15px] tracking-[0.4px]"
      style={{
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
    >
      Description of work here .... sint occaecat cupidatat non proident, sunt
      in culpa qui officia deserunt mollit anim id es
    </p>
    <div className="flex flex-col font-satoshi text-[10px] font-normal leading-4 capitalize">
      <p className="text-primary-neutral-300 font-bold">Deliverables</p>
      <p className="text-primary-neutral-800">
        Deliverable 1 , Deliverable 2 , Deliverable 3
      </p>
    </div>
  </div>
);

const ServiceActivated = ({ onDeactivate, loading, service }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ConfirmDeactivate
        isOpen={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
        onDeactivate={() => {
          setOpenModal(false);
          onDeactivate();
        }}
      />
      <div className="w-full mt-[110px] flex flex-col gap-10 items-center pt-8 pb-28 px-4">
        <div className="flex gap-4 w-full items-center px-6">
          <img src={rectangle} className="w-10 h-10" alt="dashboard" />
          <div className="flex-col flex font-satoshi flex-1">
            <div className="text-black text-base font-bold  capitalize leading-[30px] tracking-wide">
              {service?.name}
            </div>
            <div className="text-primary-neutral-500 text-2xs font-normal uppercase leading-[21px] tracking-wider">
              {service?.niche}
            </div>
          </div>
        </div>
        <div className="pt-8 pb-20 px-4 flex flex-col gap-10 ">
          <p className="text-primary-neutral-800 text-sm font-satoshi font-normal leading-5">
            {service?.description}
          </p>

          <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-4">
            <Heading title="Current Proficiency" />

            <div className="flex flex-col gap-2 px-4 py-2 w-full relative">
              <div className="flex h-20 justify-center items-center">
                {[...Array(6)].map((_, i) => {
                  const isActive = i === 0;
                  return (
                    <div
                      className="flex items-center justify-center flex-1"
                      key={i}
                    >
                      <div
                        className={`flex-1 h-[2px] ${
                          isActive ? "bg-[#FFD844]" : "bg-primary-neutral-100"
                        }`}
                      ></div>
                      <div
                        className={`h-[10px] w-[10px] rounded-full ${
                          isActive ? "bg-[#FFD844]" : "bg-primary-neutral-100"
                        }`}
                      ></div>
                      <div className="flex-1 h-[2px] bg-primary-neutral-100"></div>
                      {isActive && (
                        <img src={rank} className="absolute top-0" />
                      )}
                      {isActive && (
                        <p className="uppercase text-primary-neutral-500 text-xs font-lato font-bold leading-4 absolute bottom-2 tracking-[1px]">
                          Learner 1
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex justify-center items-start gap-4 w-full">
              <ProficiencyCard title="Worker Contracts" value="00" />
              <ProficiencyCard title="Learner Contracts" value="00" />
            </div>

            <div className="flex justify-center items-start gap-4 w-full">
              <ProficiencyCard title="Total Experience" value="00h" />
              <ProficiencyCard title="Achievement Cards" value="00" />
            </div>
          </div>

          {/* <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-4">
          <Heading title="Associated Technical Skills" />

          <div className="flex py-4 px-2 flex-col items-start gap-4 w-full">
            <div className="flex gap-2 items-center w-full">
              <div className="w-[22px] h-[22px] p-1 bg-[#FDF7E9] rounded-full flex justify-center items-center">
                <img src={fiIcon} />
              </div>
              <p className="font-satoshi text-sm font-bold text-primary-neutral-500 leading-5">
                Persona Creation
              </p>
            </div>

            <div className="flex items-center gap-3 w-full">
              {[...Array(6)].map((_, i) => {
                return (
                  <div
                    key={i}
                    className={`flex-1 rounded-full h-[12px] ${
                      i < 3 ? "bg-primary-accent-500" : "bg-primary-neutral-100"
                    }`}
                  ></div>
                );
              })}
            </div>
          </div>

          <div className="flex py-4 px-2 flex-col items-start gap-4 w-full">
            <div className="flex gap-2 items-center w-full">
              <div className="w-[22px] h-[22px] p-1 bg-[#FDF7E9] rounded-full flex justify-center items-center">
                <img src={fiIcon} />
              </div>
              <p className="font-satoshi text-sm font-bold text-primary-neutral-500 leading-5">
                Scenario Development
              </p>
            </div>

            <div className="flex items-center gap-3 w-full">
              {[...Array(6)].map((_, i) => {
                return (
                  <div
                    key={i}
                    className={`flex-1 rounded-full h-[12px] ${
                      i < 3 ? "bg-primary-accent-500" : "bg-primary-neutral-100"
                    }`}
                  ></div>
                );
              })}
            </div>
          </div>
        </div> */}
          <div className="w-full flex justify-center items-start gap-24">
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="w-20 h-20 p-1 bg-orange-50 rounded-xl flex justify-center items-center">
                <img src={fiIcon}></img>
              </div>
              <div className="w-full text-center text-black text-sm font-medium font-satoshi leading-[21px] tracking-wide">
                Persona Creation
              </div>
            </div>
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="w-20 h-20 p-1 bg-orange-50 rounded-xl flex justify-center items-center">
                <img src={fiIcon} />
              </div>
              <h1 className="w-full text-center text-black text-sm font-medium font-satoshi leading-[21px]  tracking-wide">
                Scenario <br /> Development
              </h1>
            </div>
          </div>

          <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-4">
            <Heading title="Service Deliverables" />
            <p className="w-full text-primary-neutral-800 text-sm font-normal font-satoshi tracking-[0.56px]">
              You'll explore what other companies are doing and what potential
              customers need, helping startups carve their niche.
            </p>
            <div className="w-full h-[185px] flex flex-col justify-start items-start gap-3">
              <h1 className="text-stone-400 text-sm font-bold font-satoshi">
                Deliverables:
              </h1>
              <div className="w-full flex flex-col justify-start items-start gap-3">
                {service?.deliverables?.map((d, k) => {
                  return (
                    <div
                      className="w-full p-2 bg-primary-neutral-50 rounded-lg flex justify-start items-center gap-4"
                      key={k}
                    >
                      <div className="min-w-[40px] w-[40px] h-10 bg-[#D9D9D9] rounded" />
                      <div className="flex flex-col justify-start items-start">
                        <span className="w-full text-primary-neutral-800 text-xs font-bold font-satoshi capitalize">
                          {d}
                        </span>
                        <p className="w-full text-primary-neutral-500 text-xs font-normal font-satoshi capitalize leading-[18px] tracking-wide">
                          Short description of what this deliverable would
                          comprise of
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-4">
            <Heading title="Available Work Contracts" />

            <div className="flex flex-col items-center gap-4 w-full">
              <WorkContracts />
              <WorkContracts />
              <WorkContracts />
            </div>

            <p className="text-caaryaRed text-center font-satoshi text-sm underline underline-offset-2 tracking-[0.56px] leading-5 font-bold">
              + 12 More
            </p>
          </div>
          <div
            className="px-6 py-4 rounded-full bg-primary-neutral-800 justify-center items-center gap-2 inline-flex"
            onClick={() => setOpenModal(true)}
          >
            <button className="text-white text-base font-bold font-satoshi capitalize leading-normal tracking-wider">
              {loading ? (
                <div className="flex items-center gap-2">
                  <CgSpinner className="animate-spin" size={20} />
                  Deactivating Service
                </div>
              ) : (
                "Deactivate Service"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceActivated;
