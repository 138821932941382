import { useDispatch, useSelector } from "react-redux";

import { updateAns } from "redux/typeform";
import Wrapper from "./Wrapper";
import { nextStep, prevStep } from "redux/typeform";
import { Sparkle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";

const Choices = ({
  id,
  question,
  description,
  selectOne,
  options,
  questionComponent,
  subtitle,
  likeOption,
  optionsImages,
}) => {
  const { details, currentSection } = useSelector((state) => state.typeForm);
  const dispatch = useDispatch();
  const [data, setData] = useState(
    details?.sectionMetaData?.basicForm[currentSection]?.find(
      (item) => item.id === id
    )?.value
  );
  const handleChange = (value) => {
    if (selectOne) {
      if (data !== null && typeof data !== "object") setData(value);
      else setData();
    } else {
      if (data?.includes("Not Interested")) {
        let temp = data || [];
        if (temp?.includes(value)) {
          setData(temp?.filter((i) => i !== value));
        } else {
          setData(["Not Interested"]);
        }
      } else {
        let temp = data || [];
        if (temp?.includes(value)) {
          setData(temp?.filter((i) => i !== value));
        } else {
          if (value == "Not Interested") setData(["Not Interested"]);
          else setData([...temp, value]);
        }
      }
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setData(
      details?.sectionMetaData?.basicForm[currentSection]?.find(
        (item) => item.id === id
      )?.value
    );
  }, [id]);
  function scrollToTargetAdjusted() {
    var element = document.getElementById("startingPoint");
    var headerOffset = 2000;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
  return (
    <>
      <Wrapper
        onNextClick={() => {
          dispatch(updateAns({ id: id, value: data, section: currentSection }));
          dispatch(nextStep({ section: true }));
          scrollToTargetAdjusted();
        }}
        onBackClick={() => {
          dispatch(prevStep({ section: true }));
          scrollToTargetAdjusted();
        }}
        disabled={data == null || data == undefined || data?.length == 0}
      >
        <div
          key={id}
          className="max-h-[80vh] overflow-y-auto w-full block pt-10"
        >
          <div
            className={`flex flex-col w-full max-w-4xl mx-auto px-6 py-16 justify-center items-center`}
          >
            <div className="flex flex-col items-start space-y-2 lg:space-y-4 w-full">
              {subtitle && (
                <div className="w-full capitalize flex flex-col md:flex-row md:items-center justify-start space-y-2 md:space-y-0 md:space-x-3 text-caaryaRed font-satoshi text-sm font-medium lg:tracking-[1.4px] tracking-[1.2px] lg:leading-5">
                  <div className="w-5 h-5 lg:w-6 lg:h-6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21 8C19.55 8 18.74 9.44 19.07 10.51L15.52 14.07C15.22 13.98 14.78 13.98 14.48 14.07L11.93 11.52C12.27 10.45 11.46 9 10 9C8.55 9 7.73 10.44 8.07 11.52L3.51 16.07C2.44 15.74 1 16.55 1 18C1 19.1 1.9 20 3 20C4.45 20 5.26 18.56 4.93 17.49L9.48 12.93C9.78 13.02 10.22 13.02 10.52 12.93L13.07 15.48C12.73 16.55 13.54 18 15 18C16.45 18 17.27 16.56 16.93 15.48L20.49 11.93C21.56 12.26 23 11.45 23 10C23 8.9 22.1 8 21 8Z"
                        fill="#ED4C41"
                      />
                      <path
                        d="M15 9L15.94 6.93L18 6L15.94 5.07L15 3L14.08 5.07L12 6L14.08 6.93L15 9ZM3.5 11L4 9L6 8.5L4 8L3.5 6L3 8L1 8.5L3 9L3.5 11Z"
                        fill="#ED4C41"
                      />
                    </svg>
                  </div>
                  <p>{subtitle}</p>
                </div>
              )}
              <h1
                id="startingPoint"
                className={`w-full text-black text-left font-satoshi text-xl lg:text-[28px] leading-9 lg:leading-[50.4px] tracking-[1px] lg:tracking-[2.24px] ${
                  questionComponent ? "font-normal" : "font-normal"
                }`}
              >
                {question
                  ? question
                  : React.cloneElement(questionComponent, {})}
              </h1>
              <p className="w-full text-primary-neutral-900 leading-8 tracking-[0.9px] lg:text-black lg:leading-[43px] lg:tracking-[1.92px]  text-left font-satoshi text-lg lg:text-2xl font-light">
                {description}
              </p>
            </div>
            <div
              className={`mt-12 lg:mt-16 flex flex-col space-y-6 w-full transition-all`}
            >
              <p className="text-caaryaRed font-lato text-sm lg:text-base font-normal leading-5 lg:leading-6 lg:tracking-[1.28px] tracking-[1.12px]">
                {selectOne ? "Choose one" : "Choose as many as you like"}
              </p>
              <div
                className={
                  likeOption
                    ? "flex flex-col w-full space-y-4"
                    : "flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:items-center lg:flex-wrap lg:-ml-3 lg:-mt-3"
                }
              >
                {options?.map((item, ind) => {
                  return (
                    <div>
                      {likeOption ? (
                        <div
                          onClick={() => {
                            handleChange(item);
                          }}
                          className={`cursor-pointer focus:bg-white text-primary-gray-800 hover:bg-primary-neutral-50 px-4 py-4 lg:px-6 lg:py-4 flex flex-row items-center justify-between space-x-4 text-center rounded-[12px] lg:rounded-lg text-sm lg:text-base bg-white font-inter font-light leading-5 tracking-[0.35px] lg:leading-6 lg:tracking-[0.4px] ${
                            selectOne
                              ? data == item
                                ? "border-primary-neutral-300 bg-primary-neutral-50 border"
                                : "bg-white"
                              : data?.includes(item)
                              ? "border-primary-neutral-300 bg-primary-neutral-50 border"
                              : "bg-white"
                          }`}
                        >
                          <div className="flex flex-row items-center space-x-4 lg:space-x-6">
                            {optionsImages ? (
                              <img
                                src={optionsImages[ind]}
                                alt=""
                                className="w-6 h-6"
                              />
                            ) : (
                              <Sparkle
                                size={24}
                                weight="fill"
                                color="#FBA804"
                              />
                            )}
                            <p>{item}</p>
                          </div>
                          {selectOne ? (
                            data == item ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.9997 1.97108C18.6567 -4.87192 35.3007 7.10258 11.9997 22.5001C-11.3013 7.10408 5.34267 -4.87192 11.9997 1.97108Z"
                                  fill="#E72113"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M11.9998 4.12207L10.9243 3.01657C8.39984 0.421573 3.77084 1.31707 2.09984 4.57957C1.31534 6.11407 1.13834 8.32957 2.57084 11.1571C3.95084 13.8796 6.82184 17.1406 11.9998 20.6926C17.1778 17.1406 20.0473 13.8796 21.4288 11.1571C22.8613 8.32807 22.6858 6.11407 21.8998 4.57957C20.2288 1.31707 15.5998 0.420073 13.0753 3.01507L11.9998 4.12207ZM11.9998 22.5001C-10.9997 7.30207 4.91834 -4.55993 11.7358 1.71457C11.8258 1.79707 11.9143 1.88257 11.9998 1.97107C12.0845 1.88265 12.1725 1.79759 12.2638 1.71607C19.0798 -4.56293 34.9993 7.30057 11.9998 22.5001Z"
                                  fill="#CFCDC9"
                                />
                              </svg>
                            )
                          ) : data?.includes(item) ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.9997 1.97108C18.6567 -4.87192 35.3007 7.10258 11.9997 22.5001C-11.3013 7.10408 5.34267 -4.87192 11.9997 1.97108Z"
                                fill="#E72113"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M11.9998 4.12207L10.9243 3.01657C8.39984 0.421573 3.77084 1.31707 2.09984 4.57957C1.31534 6.11407 1.13834 8.32957 2.57084 11.1571C3.95084 13.8796 6.82184 17.1406 11.9998 20.6926C17.1778 17.1406 20.0473 13.8796 21.4288 11.1571C22.8613 8.32807 22.6858 6.11407 21.8998 4.57957C20.2288 1.31707 15.5998 0.420073 13.0753 3.01507L11.9998 4.12207ZM11.9998 22.5001C-10.9997 7.30207 4.91834 -4.55993 11.7358 1.71457C11.8258 1.79707 11.9143 1.88257 11.9998 1.97107C12.0845 1.88265 12.1725 1.79759 12.2638 1.71607C19.0798 -4.56293 34.9993 7.30057 11.9998 22.5001Z"
                                fill="#CFCDC9"
                              />
                            </svg>
                          )}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            handleChange(item);
                          }}
                          className={`cursor-pointer lg:m-3 px-3 py-3.5 lg:px-6 lg:py-3.5 text-center rounded-full border text-sm lg:text-base leading-5 tracking-[1.12px] bg-white font-lato lg:leading-6 lg:tracking-[1.28px] ${
                            selectOne
                              ? data == item
                                ? "border-[#ED4C41] text-[#ED4C41]"
                                : "border-primary-neutral-200 text-primary-neutral-500 hover:border-primary-neutral-200 hover:bg-primary-neutral-50"
                              : data?.includes(item)
                              ? "border-[#ED4C41] text-[#ED4C41]"
                              : "border-primary-neutral-200 text-primary-neutral-500 hover:border-primary-neutral-200 hover:bg-primary-neutral-50"
                          }`}
                        >
                          {item}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Choices;
