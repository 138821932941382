import CareerCard from "components/Dashboard/Components/CareerCard";
import { ReactComponent as NeoProfile } from "assets/Dashboard/NeoProfiling.svg";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MdArrowBack } from "react-icons/md";

export const assessments = [
  {
    title: "Neo-Generalist Profiling",
    time: "3-4 min",
    IconComponent: NeoProfile,
    image: "/assets/images/neo1.png",
    path: "/assessments/AI5",
    isLocked: false,
    isCompleted: false,
    isActive: true,
    form: {
      step: 3,
      currentSection: "Section1",
      currentStep: 0,
      currentSectionTotal: 8,
      stepCompleted: {},
    },
    gradient:
      "linear-gradient(180deg, rgba(242, 130, 245, 0.95) 0%, rgba(94, 79, 186, 0.95) 100%)",
    subheading:
      "Neo-generalists are in. If you are a jack of all trades, you are valuable to a startup. This section helps us understand your Neo-generalist fit.",
  },
  {
    title: "Agile Fit Assessment",
    time: "3-4 min",
    image: "/assets/images/neo2.png",
    IconComponent: NeoProfile,
    isLocked: false,
    path: "/assessments/AgileFit",
    isCompleted: false,
    isActive: true,
    gradient: "linear-gradient(186deg, #FFBC00 4.65%, #F57D34 95.35%)",
    subheading:
      "Delivery cycles in industry 5.0 are hyper agile requiring you to take ownership, make independent decisions and be proficient with remote communication. This section tests your aptitude to be a remote agile worker.",
    form: {
      step: 13,
      currentSection: "Section2",
      currentStep: 1,
      currentSectionTotal: 7,
      stepCompleted: { Section1: true },
    },
  },
  {
    title: "Natural Interest Profiling",
    time: "3-4 min",
    image: "/assets/images/riasec.png",
    IconComponent: NeoProfile,
    isLocked: false,
    isCompleted: false,
    isActive: true,
    path: "/assessments/RIASEC",
    form: {
      step: 1,
    },
    gradient: "linear-gradient(92deg, #4CA6E5 0%, #6775F5 100%)",
    subheading: "lorem ipsum dolor sit",
  },
  {
    title: "Interests Over Time",
    time: "3-4 min",
    image: "/assets/images/neo4.png",
    IconComponent: NeoProfile,
    isLocked: true,
    isCompleted: false,
    isActive: false,
    path: "/phaseOne",
    form: {
      step: 4,
    },
  },
  {
    title: "Work Preferences",
    time: "3-4 min",
    image: "/assets/images/neo5.png",
    IconComponent: NeoProfile,
    isLocked: false,
    isCompleted: false,
    isActive: true,
    gradient: "linear-gradient(92deg, #33D6E1 0%, #2BB656 100%)",
    subheading:
      "Let us know your professional and learning preferences and help us deliver tailor made opportunities for you.",
    path: "/assessments/WorkPreferences",
    form: {
      step: 31,
      currentSection: "Section4",
      currentStep: 1,
      currentSectionTotal: 4,
      stepCompleted: { Section1: true, Section2: true, Section3: true },
    },
  },
  {
    title: "Value Assessment",
    time: "3-4 min",
    image: "/assets/images/neo6.png",
    IconComponent: NeoProfile,
    isLocked: false,
    isCompleted: false,
    isActive: true,
    path: "/assessments/valueAssessment",
    gradient: "linear-gradient(92deg, #C63434 0%, #ED4C41 100%)",
    subheading: "Lorem Ipsum dolor sit",
    form: {
      step: 9,
    },
  },
];

function BoostCareer() {
  return (
    <div className=" bg-[#fafafa]">
      <div className="bg-white pt-[52.5px] lg:pt-5 z-20">
        <div className="flex gap-4 px-3 py-5 flex-row items-center w-full text-primary-neutral-800 font-satoshi font-bold leading-6 tracking-[0.64px] text-base">
          <MdArrowBack className="h-5 w-5" />
          <p className="">Assessments and Affinities</p>
        </div>
      </div>
      <div className="pt-6 pb-16 space-y-6 ">
        <div className="px-4 flex flex-col space-y-2">
          <h1 className="text-primary-neutral-800 font-satoshi text-lg font-bold leading-7 tracking-[0.36px] capitalize">
            Boost Your Me Profile
          </h1>
          <p className="text-primary-neutral-500 font-satoshi text-xs tracking-[0.48px] leading-4">
            Take assessments for identifying and showcasing your information on
            your ME Profiles
          </p>
        </div>

        <div className="flex flex-col space-y-4 pt-4 pb-10">
          {assessments.map((data, index) => (
            <div className="flex flex-row px-4 space-x-4">
              <div className="h-[158px] flex flex-col items-center">
                <div
                  style={{
                    backgroundImage:
                      data.isCompleted || data.isActive
                        ? "repeating-linear-gradient(to bottom, #FEB8B1, #FEB8B1 10px, transparent 10px, transparent 20px)"
                        : "repeating-linear-gradient(to bottom, #E0E0E0, #E0E0E0 10px, transparent 10px, transparent 20px)",
                  }}
                  className={`flex-grow ${index === 0 && "opacity-0"} w-[2px]`}
                />
                <div
                  className={`w-4 h-4 border-[#CFCDC9] border-2 rounded-full ${
                    data.isCompleted && "bg-[#FF7E6E] !border-[#FF7E6E]"
                  } ${data.isActive && "bg-[#FEB8B1] !border-[#FF7E6E]"}`}
                />
                <div
                  style={{
                    backgroundImage: data.isCompleted
                      ? "repeating-linear-gradient(to bottom, #FEB8B1, #FEB8B1 10px, transparent 10px, transparent 20px)"
                      : "repeating-linear-gradient(to bottom, #E0E0E0, #E0E0E0 10px, transparent 10px, transparent 20px)",
                  }}
                  className=" flex-grow w-[2px]"
                />
              </div>
              <CareerCard key={index} {...data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BoostCareer;
