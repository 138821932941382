import { AiOutlineSearch } from "react-icons/ai";
import { GoBellFill } from "react-icons/go";

export default function Navbar() {
  return (
    <nav className="px-6 py-4 shadow-sm flex justify-between">
      <div className="h-8 w-24">
        <img
          src="/assets/caaryaLogos/nav-logo.png"
          className="h-full w-full object-cover"
          alt="logo"
        />
      </div>
      <div className="flex items-center gap-4">
        <div className="relative bg-gray-100 items-center h-9 rounded-md outline-none pl-8 hidden md:flex lg:w-[380px]">
          <AiOutlineSearch className="absolute left-4 " />
          <input
            type="text"
            placeholder="Search..."
            className="bg-transparent w-full border-none outline-none px-2 py-1 focus:outline-none focus:ring-0"
          />
        </div>
        <GoBellFill className="text-2xl" />
        <div className="w-9 h-9">
          <img
            src="https://i.pinimg.com/736x/bb/d5/eb/bbd5eb8953cff9d99ddc96063cf2b63e.jpg"
            alt=""
            className="w-full h-full rounded-full"
          />
        </div>
      </div>
    </nav>
  );
}
