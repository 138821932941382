import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Inactive } from "assets/svg/learner0.svg";
import { ReactComponent as Learner } from "assets/svg/learner.svg";
import { ReactComponent as Earner } from "assets/svg/earner.svg";

function ServiceCard({
  index,
  service,
  role,
  setActionType,
  handleDetails,
  isActive,
  interested = false,
}) {
  const userServices = useSelector((state) => state?.life?.userServices);
  const [liked, setLiked] = useState(true);

  useEffect(() => {
    if (userServices?.find((a) => a?.service == service?._id)) {
      setLiked(true);
    } else {
      setLiked(false);
    }
  }, [service, userServices]);

  return (
    <div
      key={index}
      style={{
        boxShadow:
          "0px 24px 32px -32px rgba(0, 0, 0, 0.15), 0px 2px 4px -2px rgba(0, 0, 0, 0.08), 0px 4px 6px -1px rgba(0, 0, 0, 0.05)",
      }}
      className="relative h-[222px] bg-white flex flex-col max-w-[480px] w-full mx-auto rounded-2xl"
    >
      <svg
        className="absolute top-0 left-0 bottom-0 rounded-l-2xl"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="222"
        viewBox="0 0 24 222"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3197 0L14.9163 2.59C15.513 5.365 16.7063 10.545 15.513 15.91C14.3197 21.09 10.7397 26.455 11.9331 31.635C13.1264 37 19.0929 42.365 20.8828 47.545C22.6728 52.91 20.2862 58.09 20.8828 63.455C21.4795 68.635 25.0594 74 23.2695 79.365C21.4795 84.545 14.3197 89.91 12.5297 95.09C10.7397 100.455 14.3197 105.635 17.3029 111C20.2862 116.365 22.6728 121.545 19.6895 126.91C16.7063 132.09 8.35314 137.455 8.94979 142.635C9.54645 148 19.0929 153.365 20.8828 158.545C22.6728 163.91 16.7063 169.09 17.3029 174.455C17.8996 179.635 25.0594 185 23.8661 190.365C22.6728 195.545 13.1264 200.91 11.3364 206.09C9.54644 211.455 15.513 216.635 18.4962 219.41L21.4795 222H0V219.41C0 216.635 0 211.455 0 206.09C0 200.91 0 195.545 0 190.365C0 185 0 179.635 0 174.455C0 169.09 0 163.91 0 158.545C0 153.365 0 148 0 142.635C0 137.455 0 132.09 0 126.91C0 121.545 0 116.365 0 111C0 105.635 0 100.455 0 95.09C0 89.91 0 84.545 0 79.365C0 74 0 68.635 0 63.455C0 58.09 0 52.91 0 47.545C0 42.365 0 37 0 31.635C0 26.455 0 21.09 0 15.91C0 10.545 0 5.365 0 2.59V0H14.3197Z"
          fill={!liked ? "#E7E6E5" : !interested ? "#D8D7F9" : "#FFE99A"}
        />
      </svg>
      <div className="py-4 pr-4 pl-9 flex flex-col space-y-3">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            {isActive ? (
              !interested ? (
                <Earner
                  style={{
                    color: "#D8D7F9",
                  }}
                />
              ) : (
                <Learner
                  style={{
                    color: "#FFE99A",
                  }}
                />
              )
            ) : (
              <Inactive />
            )}
            <p className="text-primary-neutral-400 font-lato text-xs font-bold">
              {isActive ? (!interested ? "Earner" : "Learner") : "Inactive"}
            </p>
          </div>
          {isActive &&
            (liked ? (
              <svg
                onClick={() => {
                  // onUpdate();
                  setActionType("removed");
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.001 4.52898C14.35 2.41998 17.98 2.48998 20.243 4.75698C22.505 7.02498 22.583 10.637 20.479 12.993L11.999 21.485L3.52101 12.993C1.41701 10.637 1.49601 7.01898 3.75701 4.75698C6.02201 2.49298 9.64501 2.41698 12.001 4.52898Z"
                  fill="#FF7E6E"
                  stroke="#ED4C41"
                />
              </svg>
            ) : (
              <svg
                onClick={() => {
                  // onUpdate();
                  setActionType("added");
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.001 4.52898C14.35 2.41998 17.98 2.48998 20.243 4.75698C22.505 7.02498 22.583 10.637 20.479 12.993L11.999 21.485L3.52101 12.993C1.41701 10.637 1.49601 7.01898 3.75701 4.75698C6.02201 2.49298 9.64501 2.41698 12.001 4.52898Z"
                  fill="white"
                  stroke="#363430"
                />
              </svg>
            ))}
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col space-y-2">
            <h1
              className={`${
                liked ? "text-primary-neutral-500" : "text-black"
              } font-satoshi text-base font-bold leading-6 capitalize tracking-[0.64px] line-clamp-2`}
            >
              {service?.name}
            </h1>
            <p
              className={`text-primary-neutral-500 font-satoshi font-normal text-2xs uppercase leading-4`}
            >
              {role?.name}
            </p>
          </div>

          <img
            src={
              !interested
                ? "/assets/images/experienced.png"
                : "/assets/images/demo2.png"
            }
            alt=""
            className={`w-20 h-20 object-cover ${
              liked ? "" : "filter grayscale"
            }`}
          />
        </div>
        <div className="flex flex-row items-end space-x-4">
          <div className="flex flex-col space-y-2">
            {service?.deliverables?.map((item) => {
              return (
                <div className="py-0.5 px-1.5 max-w-max truncate border border-primary-neutral-200 rounded flex flex-row items-center justify-center text-primary-neutral-800 font-satoshi text-2xs font-normal leading-4 tracking-[0.4px] capitalize">
                  {item}
                </div>
              );
            })}
          </div>{" "}
          <div className="flex flex-row items-center justify-end w-full cursor-pointer">
            <p
              onClick={() => handleDetails(service?._id)}
              className="text-xs font-satoshi font-bold capitalize underline text-primary-neutral-800"
            >
              Details
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
