import React, { useEffect, useState } from "react";
import Carousel from "./Carousel";
import { useSelector } from "react-redux";
const Onboarding = () => {
  const user = useSelector((state) => state?.user?.user);
  const [scale, setScale] = useState(false);
  const [slide, setSlide] = useState(false);
  const [disclamer, setDisclamer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setScale(true);
    }, 2000);
    setTimeout(() => {
      setSlide(true);
    }, 2300);
    setTimeout(() => {
      setDisclamer(true);
    }, 3000);
  }, []);
  return (
    <>
      {!disclamer ? (
        <div className="flex flex-col justify-center items-center h-screen">
          <img
            className={`w-[80px] h-[80px] ${
              scale ? "scale-75 ease-in-out duration-300" : ""
            }`}
            src="assets/caaryaLogos/logo.svg"
          />
          {slide && (
            <div className="text-center mt-4 ease-in-out duration-300 transition-all">
              <h1 className="text-2xl font-neutral-800 font-light">
                Hello {user?.name}!
              </h1>
              <h1 className="text-3xl mt-2">
                Welcome to <span className="text-caarya-red-lighter">HQ</span>
              </h1>
            </div>
          )}
        </div>
      ) : (
        <Carousel />
      )}
    </>
  );
};

export default Onboarding;
