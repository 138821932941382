import { X } from "@phosphor-icons/react";

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";

function Shortcuts({ isOpen, closeModal, setOpenLogoutModal }) {
  const history = useHistory();
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          closeModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition-all ease-in-out duration-300 sm:duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="pointer-events-auto w-screen">
                  <div className="py-4 px-2 flex flex-col items-center space-y-6 w-full ">
                    <div
                      onClick={() => {
                        closeModal();
                      }}
                      style={{
                        boxShadow:
                          "0px 8px 10px -6px rgba(0, 0, 0, 0.10), 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 0px 16px 1px rgba(0, 0, 0, 0.05)",
                      }}
                      className="text-white flex flex-row items-center justify-center bg-black rounded-full w-10 h-10"
                    >
                      <X size={24} />
                    </div>
                    <div className="flex flex-col space-y-6 w-full">
                      <div className="grid grid-cols-2 gap-3 w-full">
                        {[
                          {
                            background:
                              "radial-gradient(183.65% 100% at 50% 100%, #FFBC00 0%, #FFD844 100%)",

                            name: "Work Contracts",
                            image: "/assets/images/shortcut/Work Contracts.svg",
                          },
                          {
                            background:
                              "radial-gradient(183.65% 100% at 50% 100%, #8DA1FC 0%, #816FE9 100%)",

                            image: "/assets/images/shortcut/caaryaSpace.svg",
                            name: "Caarya Space",
                            path: "/rewards",
                          },
                          {
                            background:
                              "radial-gradient(183.65% 100% at 50% 100%, #33D6E1 0%, #75DD67 100%)",

                            image:
                              "/assets/images/shortcut/Career Pathways.svg",
                            name: "Career Pathways",
                          },
                          {
                            background:
                              "radial-gradient(183.65% 100% at 50% 100%, #E0447C 0%, #F282F5 100%)",

                            image:
                              "/assets/images/shortcut/Community Experiences.svg",
                            name: "Community Experiences",
                            path: "/tribe",
                          },
                        ]?.map((item) => {
                          return (
                            <div
                              onClick={() => {
                                if (item?.path) history.push(item?.path);
                              }}
                              className="bg-white p-2 rounded-xl"
                            >
                              <Card item={item} />
                            </div>
                          );
                        })}
                      </div>

                      <div className="p-2 flex flex-col space-y-4 items-center rounded-3xl w-full bg-[#F5FCFD]">
                        <div className="w-full text-center text-primary-neutral-500 font-satoshi text-2xs font-bold leading-4">
                          Measure Your Growth
                        </div>
                        <div className="w-full grid grid-cols-4 gap-2">
                          {[
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #3996E3 0%, #33D6E1 100%)",

                              image: "/assets/images/shortcut/Me Profiles.svg",
                              name: "Me Profiles",
                              path: "/phaseOne",
                            },
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #8DA1FC 0%, #816FE9 100%)",

                              image:
                                "/assets/images/shortcut/Achievement Cards.svg",
                              name: "Achievement Cards",
                              path: "/achievementsRedirect",
                            },
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #F57D34 0%, #FF76AE 100%)",

                              image: "/assets/images/shortcut/Quests.svg",
                              name: "Quests",
                            },
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #33D6E1 0%, #75DD67 100%)",

                              image:
                                "/assets/images/shortcut/Skill Maps & Radars.svg",
                              name: "Skill Maps & Radars",
                            },
                          ]?.map((item) => {
                            return (
                              <Card
                                onClick={() => {
                                  if (item?.path) history.push(item?.path);
                                }}
                                item={item}
                              />
                            );
                          })}
                        </div>
                        <div className="flex flex-row items-center justify-center space-x-2">
                          <img
                            src="/assets/images/me.png"
                            alt=""
                            className="w-3 h-3"
                          />
                          <p className="text-primary-neutral-400 font-lato text-3xs font-bold leading-3">
                            Caarya ME
                          </p>
                        </div>
                      </div>

                      <div className="p-2 flex flex-col space-y-4 items-center rounded-3xl w-full bg-[#FFF2F0]">
                        <div className="w-full text-center text-primary-neutral-500 font-satoshi text-2xs font-bold leading-4">
                          Your Caarya Life
                        </div>
                        <div className="w-full grid grid-cols-4 gap-2">
                          {[
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #FF76AE 0%, #FF7E6E 100%)",

                              image: "/assets/images/shortcut/FYI.svg",
                              name: "FYI",
                            },
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #FFBC00 0%, #FFD844 100%)",

                              image: "/assets/images/shortcut/Journal.svg",
                              name: "Journal",
                            },
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #33D6E1 0%, #75DD67 100%)",

                              image: "/assets/images/shortcut/Rewards.svg",
                              name: "Rewards",
                              path: "/rewards",
                            },
                            {
                              background:
                                "radial-gradient(183.65% 100% at 50% 100%, #5E4FBA 0%, #816FE9 100%)",
                              image: "/assets/images/shortcut/logout.svg",
                              name: "Logout",
                              action: "logout",
                            },
                          ]?.map((item) => {
                            return (
                              <Card
                                item={item}
                                closeModal={closeModal}
                                setOpenLogoutModal={setOpenLogoutModal}
                              />
                            );
                          })}
                        </div>
                        <div className="flex flex-row items-center justify-center space-x-2">
                          <img
                            src="/assets/images/crew.png"
                            alt=""
                            className="w-3 h-3"
                          />
                          <p className="text-primary-neutral-400 font-lato text-3xs font-bold leading-3">
                            Togo CREW{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Shortcuts;

const Card = ({ item, closeModal, setOpenLogoutModal }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (item?.path) {
          history.push(item?.path);
          return;
        }
        if (item.action === "logout") {
          closeModal();
          setOpenLogoutModal(true);
        }
      }}
      className="flex flex-col items-center space-y-2 w-full"
    >
      <img src={item?.image} alt="" className="w-12 h-12" />

      <p className="text-primary-neutral-800 text-center font-satoshi text-2xs font-bold leading-4">
        {item?.name}
      </p>
    </div>
  );
};
