import GoalCreateEdit from "components/Modals/Goal/GoalCreate/GoalCreateEdit";
import { TASK_STATUS_INPROGRESS, TASK_STATUS_NOTSTARTED } from "helpers/task";
import { Plus } from "@phosphor-icons/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyListGoals, fetchAllgoals } from "redux/goal";
import GoalCreate from "components/Modals/Goal/GoalCreate";
import { updateGoal } from "config/APIs/task/goal";
import PlannerMode from "components/Modals/Goal/PlannerMode";
import { DividerTabs } from "components/Common/Tabs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import FooterContent from "components/Sprint/SprintFooter";

import CuratedExperiences from "components/Sprint/CuratedExperiences";
import SprintGoalsSection from "components/Sprint/SprintGoalsSection";

function Sprint() {
  const history = useHistory();
  const dispatch = useDispatch();
  const plannerMode = useSelector((state) => state.goals?.plannerMode);
  const myGoals = useSelector((state) => state.goals?.owner?.goals);
  const sprints = useSelector((state) => state.goals?.sprints);
  const [sprint, setSprint] = useState(0);

  const [openAdd, setOpenAdd] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedTab, setSelectedTab] = useState("goals");
  const [plannerHeading, setPlannerHeading] = useState(false);
  const realTimeUpdates = useSelector((state) => state.goals?.updates);
  const [updateGoalModal, setUpdateGoalModal] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(async () => {
    window.scrollTo({ top: 0 });
    if (sprints?.length > 0) {
      setSprint(sprints[0]);
    }
  }, [sprints]);

  const getGoals = (list, time) => {
    let temp = [];
    switch (time) {
      case "today":
        temp = list?.filter(
          (i) =>
            i?.status == TASK_STATUS_INPROGRESS ||
            (i?.status == TASK_STATUS_NOTSTARTED && i?.priority == 1) ||
            moment(i?.deadline).unix() <= moment().unix()
        );
        break;
      case "week":
        temp = list?.filter(
          (i) =>
            (i?.status == TASK_STATUS_NOTSTARTED &&
              (i?.priority == 2 || i?.priority == 3)) ||
            (moment(i?.deadline).unix() <= moment().add(7, "days").unix() &&
              moment(i?.deadline).unix() > moment().unix())
        );
        break;
      case "later":
        temp = list?.filter(
          (i) =>
            (i?.status == TASK_STATUS_NOTSTARTED && i?.priority > 3) ||
            !i?.priority
        );
        break;
      default: {
        temp = list;
      }
    }

    return temp;
  };
  const updateChanges = async () => {
    try {
      await Promise.all(
        realTimeUpdates?.map(async (change) => {
          let body = {};
          if (change?.status) body["status"] = change?.status;
          if (change?.focus) body["focusIn"] = change?.focus;
          await updateGoal(change?.goalId, body);
        })
      );
      dispatch(emptyListGoals());
      dispatch(fetchAllgoals());
    } catch (err) {
      console.log("Error", err);
      console.log(err?.response);
    }
  };
  useEffect(() => {
    if (plannerMode) {
      setTimeout(() => {
        setPlannerHeading(true);
      }, 500);
    }
  }, [plannerMode]);
  useEffect(() => {
    if (!plannerHeading) {
      setTimeout(() => {
        setPlannerHeading(false);
      }, 50);
    }
  }, [plannerHeading]);

  return (
    <>
      <GoalCreateEdit
        isOpen={createNew}
        closeModal={() => setCreateNew(false)}
      />{" "}
      {updateGoalModal && (
        <PlannerMode setUpdateGoalModal={setUpdateGoalModal} />
      )}{" "}
      <GoalCreate
        isOpen={openAdd}
        closeModal={() => setOpenAdd(null)}
        onCreate={() => {}}
      />{" "}
      <div
        className={`bg-[#fafafa] pt-[52.5px] lg:pt-0 h-[95vh] overflow-y-auto`}
      >
        <div
          className="flex px-4 py-6 flex-row justify-between items-center w-full"
          style={{
            background: "linear-gradient(180deg, #F7F7FF 0%, #FFF 100%)",
          }}
        >
          <div className="text-[#816FE9] flex flex-row items-center space-x-2 font-bold font-satoshi text-base tracking-[0.64px] leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M1.17342 18.9196H16.6115V19.2921C16.6114 19.4334 16.6537 19.5714 16.7328 19.6884C16.812 19.8053 16.9243 19.8957 17.0552 19.948C17.1862 20.0002 17.3297 20.0118 17.4674 19.9814C17.605 19.9509 17.7303 19.8797 17.8271 19.7771L19.8074 17.6794C19.9311 17.5484 20 17.3748 20 17.1944C20 17.014 19.9311 16.8404 19.8074 16.7093L17.8271 14.6115C17.7303 14.5089 17.605 14.4377 17.4674 14.4072C17.3297 14.3768 17.1862 14.3884 17.0552 14.4406C16.9243 14.4929 16.812 14.5833 16.7328 14.7002C16.6537 14.8172 16.6114 14.9552 16.6115 15.0965V15.469H1.17342C0.862315 15.4693 0.564042 15.5934 0.344054 15.8139C0.124066 16.0345 0.000331321 16.3335 0 16.6453L0 17.7433C0.000331321 18.0551 0.124066 18.3541 0.344054 18.5747C0.564042 18.7952 0.862315 18.9193 1.17342 18.9196Z"
                fill="#816FE9"
              />
              <path
                d="M7.6445 3.40831L9.73632 5.62609C9.83314 5.72875 9.95846 5.79992 10.0961 5.83039C10.2337 5.86087 10.3772 5.84924 10.5082 5.79702C10.6391 5.7448 10.7514 5.65439 10.8305 5.53748C10.9097 5.42058 10.952 5.28256 10.952 5.14128V4.72862C12.0123 4.91273 12.9949 5.40621 13.7769 6.14731C14.5588 6.88842 15.1054 7.84424 15.3482 8.89518C15.591 9.94612 15.5192 11.0455 15.1419 12.0558C14.7646 13.066 14.0985 13.9423 13.2269 14.575C13.1877 14.6047 13.1587 14.6459 13.1439 14.6929C13.1292 14.7399 13.1294 14.7903 13.1446 14.8371C13.1598 14.884 13.1893 14.9249 13.2288 14.9542C13.2683 14.9834 13.3159 14.9996 13.365 15.0005H15.9466C15.9992 15.0005 16.0503 14.9828 16.0916 14.9502C16.133 14.9176 16.1623 14.872 16.1747 14.8208C16.2368 14.5636 16.3837 14.3349 16.5914 14.1717C16.7992 14.0086 17.0558 13.9204 17.3198 13.9214C17.525 13.9214 17.7266 13.9761 17.9038 14.08C17.932 14.0963 17.9633 14.1066 17.9957 14.1102C18.028 14.1138 18.0608 14.1107 18.0919 14.101C18.123 14.0913 18.1517 14.0752 18.1763 14.0539C18.2009 14.0325 18.2209 14.0063 18.2349 13.9768C18.7986 12.7762 19.0899 11.4653 19.0877 10.1383C19.0779 7.89142 18.243 5.72683 16.7423 4.05784C15.2416 2.38885 13.1803 1.33239 10.952 1.09015V0.705728C10.952 0.564453 10.9097 0.426433 10.8305 0.309527C10.7514 0.192621 10.6391 0.102208 10.5082 0.0499877C10.3772 -0.00223277 10.2337 -0.0138579 10.0961 0.0166166C9.95846 0.047091 9.83314 0.118262 9.73632 0.220922L7.64544 2.43729C7.52033 2.56775 7.45037 2.74164 7.4502 2.92261C7.45002 3.10358 7.51964 3.27761 7.6445 3.40831Z"
                fill="#816FE9"
              />
              <path
                d="M2.47905 14.9982H6.65816C6.7073 14.9973 6.75495 14.9812 6.79448 14.9519C6.834 14.9226 6.86343 14.8817 6.87864 14.8349C6.89385 14.788 6.8941 14.7376 6.87934 14.6906C6.86458 14.6436 6.83556 14.6024 6.79631 14.5728C5.99784 13.9923 5.37078 13.2061 4.9815 12.2976C4.59222 11.389 4.45519 10.3919 4.58493 9.41165C4.71467 8.43143 5.10634 7.5046 5.71849 6.72926C6.33064 5.95392 7.1405 5.3589 8.06236 5.00718C8.09813 4.99304 8.12992 4.97037 8.15497 4.94112C8.18002 4.91188 8.19756 4.87695 8.20609 4.83936C8.21461 4.80177 8.21386 4.76267 8.20389 4.72544C8.19392 4.68821 8.17504 4.65399 8.14888 4.62574L7.30245 3.72827C7.09453 3.51003 6.97891 3.21961 6.97982 2.91782C6.98072 2.61604 7.09808 2.32631 7.3073 2.10933L7.56749 1.83328C7.6021 1.79519 7.62335 1.74681 7.62802 1.6955C7.63268 1.64419 7.62051 1.59276 7.59335 1.54902C7.56618 1.50528 7.52552 1.47163 7.47753 1.45318C7.42954 1.43473 7.37686 1.4325 7.32749 1.44682C6.00681 1.85604 4.79697 2.56259 3.79047 3.51245C2.78397 4.46231 2.00746 5.63031 1.52033 6.92716C1.03319 8.22401 0.848323 9.61536 0.979862 10.9948C1.1114 12.3743 1.55586 13.7053 2.27926 14.8862C2.30025 14.9204 2.32964 14.9486 2.36461 14.9682C2.39958 14.9878 2.43898 14.9981 2.47905 14.9982Z"
                fill="#816FE9"
              />
            </svg>
            <p className="">Sprint Card</p>
          </div>
          <div className="relative">
            <button
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => handleClick()}
              className="border-[#9C9A96] rounded-[56px] border-[1px] w-6 h-6 flex justify-center items-center"
              aria-label="More information"
            >
              <p className="font-lato font-[900] text-xs text-[#696763]">?</p>
            </button>
            {showTooltip && (
              <div className="absolute right-full top-1/2 transform -translate-y-1/4 -translate-x-4 p-2 bg-white border-[#696763] border-[1px] text-[#696763] text-xs rounded-md w-64 ">
                <p>
                  A sprint is a set period of time during which specific work
                  has to be completed and made ready for review. Here you will
                  find your goals and insights for the current sprint.
                </p>
                <div className="absolute left-full top-1/2 -translate-y-1/2 border-t-5 border-r-5 border-b-5 border-l-5 border-transparent border-l-black" />
              </div>
            )}
          </div>
        </div>

        <div className="bg-[#fafafa] pb-5 lg:pb-0">
          <div className="bg-white py-5 px-3 md:p-6 flex flex-row items-center justify-between">
            <div className="px-2 text-[#363430] space-y-2">
              <div className="flex flex-row items-center space-x-3">
                <h1 className="text-base md:text-2xl font-lato font-bold tracking-[0.56px] capitalize leading-5">
                  {sprint?.name}
                </h1>
                <div className="flex flex-row items-center justify-center bg-primary-neutral-100 rounded px-1.5 py-0.5 text-primary-neutral-400 font-satoshi text-3xs font-bold leading-3 tracking-[0.32px]">
                  Current Sprint
                </div>
              </div>
              <p className="text-xs font-satoshi md:text-[#ED4C41] tracking-[0.8px] leading-4">
                {`${moment(sprint?.startDate).format(
                  "DD MMM YYYY"
                )} -  ${moment(sprint?.endDate).format("DD MMM YYYY")}`}
              </p>
            </div>
            {/* <div
                onClick={() => {
                  history.push("/sprintInsights");
                }}
                className="py-1.5  flex justify-center md:space-x-4 md:bg-[#363430] md:px-6 md:py-4 md:rounded-2xl flex-row items-center space-x-2 text-sm font-lato font-bold underline underline-offset-2 tracking-[0.28px] leading-5 text-[#363430]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M14.0001 5.83203C13.0334 5.83203 12.4934 6.79203 12.7134 7.50536L10.3467 9.8787C10.1467 9.8187 9.85341 9.8187 9.65341 9.8787L7.95341 8.1787C8.18008 7.46536 7.64008 6.4987 6.66675 6.4987C5.70008 6.4987 5.15341 7.4587 5.38008 8.1787L2.34008 11.212C1.62675 10.992 0.666748 11.532 0.666748 12.4987C0.666748 13.232 1.26675 13.832 2.00008 13.832C2.96675 13.832 3.50675 12.872 3.28675 12.1587L6.32008 9.1187C6.52008 9.1787 6.81341 9.1787 7.01341 9.1187L8.71341 10.8187C8.48675 11.532 9.02675 12.4987 10.0001 12.4987C10.9667 12.4987 11.5134 11.5387 11.2867 10.8187L13.6601 8.45203C14.3734 8.67203 15.3334 8.13203 15.3334 7.16536C15.3334 6.43203 14.7334 5.83203 14.0001 5.83203Z"
                    class="fill-current text-black md:text-white"
                  />
                  <path
                    d="M10.0001 6.5L10.6267 5.12L12.0001 4.5L10.6267 3.88L10.0001 2.5L9.38675 3.88L8.00008 4.5L9.38675 5.12L10.0001 6.5ZM2.33341 7.83333L2.66675 6.5L4.00008 6.16667L2.66675 5.83333L2.33341 4.5L2.00008 5.83333L0.666748 6.16667L2.00008 6.5L2.33341 7.83333Z"
                    class="fill-current text-black md:text-white"
                  />
                </svg>{" "}
                <p className="md:text-white md:text-base md:text-bold">
                  Insights
                </p>
              </div> */}
          </div>
          <div className="sticky lg:relative top-0.5 justify-center flex bg-white z-30">
            <DividerTabs
              dark
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={[
                { label: "Goals", value: "goals" },
                // { label: "Engagements", value: "engagement" },
                {
                  label: "Curated Experiences",
                  value: "curated-experiences",
                },
              ]}
            />
          </div>
          <div className="space-y-6">
            {selectedTab == "goals" ? (
              <SprintGoalsSection sprint={sprint} />
            ) : (
              <CuratedExperiences />
            )}
          </div>

          <FooterContent sprint={sprint} isLocked />

          {/* <div
            onClick={() => setCreateNew(true)}
            className="fixed cursor-pointer bottom-20 right-2 bg-black rounded-full flex flex-row items-center w-14 h-14 justify-center"
          >
            <Plus size={32} className="text-white" />{" "}
          </div> */}
        </div>

        {realTimeUpdates.length > 0 && plannerMode && (
          <div className="border absolute bottom-0 flex w-full justify-between bg-white items-center">
            <div
              onClick={() => {
                dispatch(fetchAllgoals());
                dispatch(emptyListGoals());
              }}
              className="w-full flex items-center border-r justify-center p-6"
            >
              <h1 className="text-sm text-neutral-500 cursor-pointer">
                Discard Changes
              </h1>
            </div>
            <div
              onClick={() => {
                updateChanges();
              }}
              className="w-full flex items-center justify-center p-6"
            >
              <h1 className="text-sm text-primary-error-500 cursor-pointer">
                Save Changes
              </h1>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Sprint;
