import { Text } from 'helpers/constants/Text'
import React from 'react'
import { Img } from '../Img/Img'
import { MdNorthEast } from 'react-icons/md'
import { List } from '../List/List'

const Clans = () => {
  return (
    <>
    <div className="flex flex-col font-manrope gap-2 items-start mt-6 justify-start w-3/4">
              <div className="flex flex-row gap-2 items-center justify-between w-full">
                <Text
                  className="text-blue_gray-900 text-lg tracking-[0.90px] w-auto"
                  size="txtManropeSemiBold18"
                >
                  Your Clans
                </Text>
                <div className="flex flex-row font-satoshi gap-2 items-center justify-start px-4 w-auto">
                  <a
                    href="clan:"
                    className="text-gray-700 text-sm tracking-[0.70px] underline w-auto"
                  >
                    <Text size="txtSatoshiBold14Gray700">Learn More</Text>
                  </a>
                  <MdNorthEast />
                </div>
              </div>
              <div className="flex flex-col font-satoshi items-center justify-center p-8 w-full">
                <List
                  className="flex flex-row gap-10 items-center w-[87%]"
                  orientation="horizontal"
                >
                  {/* Clan Cards */}
                  {[1, 2, 3, 4].map((index) => (
                    <div
                      key={index}
                      className="bg-gray-100 flex flex-1 flex-col gap-8 items-center justify-center px-4 py-6 rounded-lg w-full"
                    >
                      <Img
                        className="h-20 w-20"
                        src="assets/svg/pass/fi_8512296.svg"
                        alt="clan"
                      />
                      <Text
                        className="text-blue_gray-900 text-sm tracking-[0.70px] w-auto"
                        size="txtSatoshiBold14Bluegray900"
                      >
                        Clan Name Here
                      </Text>
                    </div>
                  ))}
                </List>

                <div className="flex flex-row md:flex-row  gap-10 items-center justify-center w-[48%] md:w-full mt-10">
                  {[5, 6].map((index) => (
                    <div
                      key={index}
                      className="bg-gray-100 flex flex-col items-center justify-center px-4 py-6 rounded-lg w-40  "
                    >
                      <Img
                        className="h-20 w-20"
                        src="assets/svg/pass/fi_8512296.svg"
                        alt={`clan`}
                      />
                      <Text
                        className="text-gray-500 text-sm tracking-[0.70px] w-full"
                        size="txtSatoshiBold14Gray500"
                      >
                        Clan Name Here
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
            </div>
    </>
  )
}

export default Clans