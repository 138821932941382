import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { MdNorthEast } from "react-icons/md";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { assessments } from "./BoostCareer";

const activeSectionVariant = {
  active: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  inactive: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
export default function FormSubmitted({}) {
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);

  const [loadedLoader, setLoadedLoader] = useState(true);
  const [loadedHide, setLoadedHide] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (!loadedLoader) {
      setTimeout(() => {
        setLoadedLoader(true);
      }, 6000);
    }
  }, [loadedLoader]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoadedHide(true);
      }, 6000);
    }
  }, [loaded]);

  const assessment = assessments.find((a) => a.path === pathname);

  return (
    <div className="bg-white fixed top-0 bottom-0 right-0 left-0 z-50">
      {!loadedHide && (
        <motion.div
          initial={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          animate={
            !loaded
              ? {
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                  },
                }
              : {
                  opacity: 0,
                  transition: {
                    duration: 0.5,
                  },
                }
          }
          className="h-screen fixed top-0 right-0 left-0 z-[1000] bottom-0 bg-white  w-full flex flex-col items-center justify-center space-y-6 -mt-8 px-4"
        >
          <motion.img
            initial={{
              opacity: 0.4,
              transition: {
                duration: 0.3,
              },
            }}
            animate={
              loadedLoader
                ? {
                    opacity: 0.8,
                    transition: {
                      duration: 0.3,
                    },
                  }
                : {
                    opacity: 0.4,
                    transition: {
                      duration: 0.3,
                    },
                  }
            }
            transition={{ duration: 0.3 }}
            src="/assets/logo.svg"
            className="w-40 scale-110 h-auto"
          />
          <div className="text-primary-neutral-500 font-lato text-sm leading-6 tracking-[1.12px] font-normal flex flex-row items-baseline space-x-2">
            <p>Submitting Your Response</p> <div className="loader-34" />
          </div>
        </motion.div>
      )}
      <motion.div
        initial={activeSectionVariant.inactive}
        animate={
          loaded ? activeSectionVariant.active : activeSectionVariant.inactive
        }
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="w-full flex flex-col items-center justify-between py-6 px-4 h-screen overflow-y-auto"
      >
        <img
          onLoad={() => {
            setLoaded(true);
          }}
          src="/assets/images/form/end.svg"
          alt=""
          className="object-cover"
        />
        <div className="flex flex-col items-center space-y-2">
          <h1 className="text-black font-satoshi text-[24px] text-center font-medium leading-9 tracking-[0.96px]">
            <span className="text-caaryaRed">Congratulations!!!</span>{" "}
          </h1>
          <p className="text-primary-neutral-500 text-center font-lato text-sm font-normal leading-7 tracking-[0.56px]">
            On completing {assessment.title}
          </p>
        </div>
        <p className="text-sm text-black font-satoshi capitalize font-bold text-center tracking-[0.56px] leading-5">
          Your Responses have been recorded and your ME Profile has been
          updated.
        </p>
        <div className="flex flex-col w-full gap-6">
          <div
            className={`bg-black w-full flex flex-row items-center justify-center space-x-2 rounded-full pr-8 pl-10 py-4 cursor-pointer text-white font-satoshi text-sm font-bold leading-6`}
          >
            <p>Check Your ME Profile Now</p>
            <MdNorthEast className="h-5 w-5" />
          </div>
          <Link
            to="/"
            className="text-sm underline underline-offset-2 text-center font-satoshi font-bold leading-5 capitalize text-primary-neutral-800"
          >
            Or Go Back To Caarya Life
          </Link>
        </div>
      </motion.div>
    </div>
  );
}
