import { useHistory } from "react-router-dom";
import { mobileBottomNav } from "../../helpers/constants";
import React from "react";
function TabBar() {
  const history = useHistory();
  return (
    <div>
      <div
        className={`w-full fixed z-30 bottom-0 right-0 left-0 lg:hidden flex flex-row items-center justify-center transform transition-all ease-in-out duration-300 font-poppins`}
      >
        <div className="bg-white shadow-2xl rounded-t-[28px] py-1 px-2 w-full max-w-[480px] mx-auto">
          <div className="grid grid-cols-5">
            {mobileBottomNav?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    history.push(item?.path);
                  }}
                  className="relative"
                >
                  <div
                    className={`flex flex-col items-center py-2 px-1 space-y-2 cursor-pointer
                       ${
                         window.location.pathname.includes(item?.path)
                           ? "text-[#FF7E6E] font-bold"
                           : "text-[#CFCDC9] font-medium"
                       }`}
                  >
                    {idx == 2 ? (
                      <div className="absolute bottom-7 flex flex-row items-center justify-center rounded-full">
                        <img
                          src="/assets/caaryaLogos/logo.svg"
                          alt=""
                          className="w-16 h-16 rounded-full"
                        />
                      </div>
                    ) : (
                      <>{React.cloneElement(item?.svg, {})}</>
                    )}
                    {!item?.hideTitle && (
                      <p
                        className={`text-2xs font-satoshi text-center line-clamp-1 ${
                          idx == 2 ? "pt-6" : ""
                        } `}
                      >
                        {item.name}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabBar;
