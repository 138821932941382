import SignIn from "../pages/AuthPages/SignIn";
import Unauthorized from "../layout/Unauthorized";
import { checkAppAccess } from "helpers/utils/accessCheck";
import { Redirect } from "react-router-dom";
import RedirectPage from "pages/AuthPages/Redirect";
import Onboarding from "pages/Onboarding";
import BioSection from "pages/BioSection";
import ProfilePage from "pages/Squad/Profile";
import GoProStudentLife1 from "pages/Section2/goProStudentLife";
import Evalucon from "pages/Evalucon";
import Pass from "pages/Pass";
import GoProStudentLife from "pages/GoProStudentLife";
import PublicPass from "pages/Pass/Public";
import Programs from "pages/Programs";
import Form from "pages/Form";
import ProfileForm from "pages/CaaryaMeForms/ProfileForm";
import PhaseTwoPage from "pages/CaaryaMeForms/PhaseTwoForm";
import PhaseThreePage from "pages/CaaryaMeForms/PhaseThreeForm";
import SignUp from "pages/AuthPages/SignUp";
import Welcome from "pages/Onboarding/Welcome";

// Public Routes
// Routes for Authenticating Users
const publicRoutes = [
  // Authentication
  {
    path: "/life",
    component: GoProStudentLife1,
  },
  {
    path: "/evalucon",
    component: Evalucon,
  },
  {
    path: "/pass",
    component: Pass,
  },
  {
    path: "/learnMore",
    component: PublicPass,
  },
  {
    path: "/goProStudentLife",
    component: GoProStudentLife,
  },
  {
    path: "/welcome",
    component: Welcome,
  },
  {
    path: "/onboard",
    component: Onboarding,
  },
  {
    path: "/programs",
    component: Programs,
  },
  { path: "/phaseOne", component: ProfileForm },
  { path: "/phaseTwo", component: PhaseTwoPage },
  { path: "/phaseThree", component: PhaseThreePage },

  {
    path: "/AI5",
    component: Form,
  },
  {
    path: "/auth/signUp",
    component: SignUp,
  },

  {
    path: "/auth/signIn",
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <SignIn />
      ),
  },

  {
    path: "/accessDenied",
    component: Unauthorized,
  },
  {
    path: "/biosection",
    component: BioSection,
    name: "Biosection",
  },

  {
    path: "/redirect",
    component: RedirectPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
];

export { publicRoutes };
