import InterestDrawer from "components/ProfileForm/MobileForm/components/InterestSelectionForm/InterestDrawer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showNavigation } from "redux/profile-form";
import WebActiveInterestSelection from "./WebActiveInterestSelection";

const WebInterestSelectionForm = ({ field }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showNavigation());
  }, []);
  return (
    <div className=" font-manrope min-h-screen pb-40 overflow-y-scroll scrollbar-hidden flex flex-col   ">
      <div className="flex flex-col gap-2 p-6 px-10 border-b   ">
        <h1 className="text-primary-blue-500 text-xl font-medium ">
          How have your career interests evolved?
        </h1>

        <h3 className="text-md text-primary-grey-600">
          Add the things you’ve been interested in making a career in at
          different points in your life
        </h3>
      </div>
      <div className="flex justify-between gap-10 pt-8 w-full  ">
        <div className="flex-1 h-[80vh]  ">
          <WebActiveInterestSelection field={field} />
        </div>
        <div className="flex flex-col gap-8 items-end">
          <p className="font-satoshi font-bold text-md text-primary-yellow-700">
            Add At Least 3
          </p>
          <InterestDrawer field={field} close={() => null} />
        </div>
      </div>
    </div>
  );
};

export default WebInterestSelectionForm;
