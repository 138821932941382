import React from "react";
import { MdDelete, MdWarning } from "react-icons/md";

const DeleteAchievementModal = ({ handleDelete, handleCancel }) => {
  return (
    <div className="p-4">
      <div className="p-6 md:py-8 rounded-xl shadow flex flex-col gap-4 text-center bg-white ">
        <h1 className="font-manrope text-base md:text-lg font-medium">
          Delete Achievement
        </h1>
        <h3 className="font-satoshi text-sm md:text-md">
          Delete Are you sure you want to delete this achievement?
        </h3>
        <div className="flex flex-col gap-2 py-2 px-6 border-l-4 border-primary-red-300 bg-primary-red-25 ">
          <div className="flex items-center gap-2 ">
            <MdWarning className="text-primary-red-500 text-lg" />
            <h1 className="font-manrope text-base md:text-lg font-medium">
              Warning
            </h1>
          </div>
          <h3 className="font-satoshi text-sm md:text-md font-extrabold text-left text-primary-red-500">
            You cannot recover this once deleted.
          </h3>
        </div>
        <div className="py-2 flex justify-center gap-4 md:gap-10 items-center">
          <button
            onClick={handleCancel}
            className="p-3 px-5 md:px-6 border bg-white border-primary-grey-800 rounded-2xl text-primary-grey-800"
          >
            <p className="font-satoshi font-bold text-sm">No, Cancel</p>
          </button>
          <button
            onClick={handleDelete}
            className="p-3 px-5 md:px-6  bg-primary-red-500 rounded-2xl text-white flex gap-2 items-center"
          >
            <MdDelete />
            <p className="font-satoshi font-bold text-sm">Yes, Delete</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAchievementModal;
