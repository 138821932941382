import { FormComponents } from "helpers/constants/profileForm";
import React, { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Slider from "react-slick";
import {
  hideNavigation,
  showNavigation,
  updateDetails,
  updateValueQuizStep,
} from "redux/profile-form";
import WebValueQuizNavigation from "./WebValueQuizNavigation";
import { valueQuestionArr } from "./helpers/constant";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const ArrowButton = ({ type, onClick }) => {
  return (
    <button
      className={`absolute z-10 top-1/2 ${
        type === "next" ? "-right-14  " : " -left-14 "
      } transform -translate-y-1/2 disabled:text-primary-grey-300 p-3 rounded-full hover:bg-primary-grey-100 hover:shadow-md `}
      onClick={onClick}
    >
      {type === "next" ? (
        <ChevronRightIcon className="w-6" />
      ) : (
        <ChevronLeftIcon className="w-6" />
      )}
    </button>
  );
};

const WebValueQuizForm = (props) => {
  const quizStep = useSelector(
    (state) => state.profileForm.phaseOne.details.valueQuiz.quizStep
  );
  const { details, step, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const [showOverlay, setShowOverlay] = useState(true);
  const dispatch = useDispatch();
  const sliderRef = useRef(null);
  const valueOverlayRef = useRef(null);
  const { field } = props;

  const settings = {
    customPaging: function (index) {
      return (
        <motion.p
          className={` ${
            quizStep === index
              ? " underline-offset-2 text-primary-grey-600 font-semibold underline "
              : "text-primary-grey-400"
          } `}
        >
          {index + 1}
        </motion.p>
      );
    },
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    dotsClass: "slick-dots slick-thumb",
    className: "slides",
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => dispatch(updateValueQuizStep(next)),
    afterChange: (current) => dispatch(updateValueQuizStep(current)),
    nextArrow: <ArrowButton type="next" />,
    prevArrow: <ArrowButton type="prev" />,
  };

  const handleAnswerSelection = (quizStep, option) => {
    let updatedQuiz;
    const currentQuiz = details[field]?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );

    if (currentQuiz && currentQuiz?.options.includes(option)) {
      updatedQuiz = details[field].quizData.filter(
        (quiz) => quiz.quizStep !== quizStep
      );

      dispatch(
        updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
      );

      return;
    }
    if (currentQuiz) {
      updatedQuiz = details[field].quizData.map((quiz) => {
        if (quiz.quizStep === quizStep) {
          return {
            ...quiz,
            options: [option],
          };
        }
        return quiz;
      });
    } else {
      updatedQuiz = [
        ...details[field].quizData,
        {
          quizStep,
          options: [option],
        },
      ];
    }

    dispatch(
      updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
    );
  };

  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;

      if (key === "ArrowLeft") {
        if (quizStep === 0) return;
        dispatch(updateValueQuizStep(quizStep - 1));
      } else if (key === "ArrowRight") {
        if (quizStep === valueQuestionArr.length - 1) return;
        dispatch(updateValueQuizStep(quizStep + 1));
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [quizStep]);

  useEffect(() => {
    sliderRef.current.slickGoTo(quizStep);
    if (
      details[field].quizData.length === valueQuestionArr.length &&
      quizStep === valueQuestionArr.length - 1
    ) {
      dispatch(showNavigation());
    } else dispatch(hideNavigation());
  }, [quizStep, sliderRef, dispatch, details[field]]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        valueOverlayRef.current &&
        !valueOverlayRef.current.contains(event.target)
      ) {
        setShowOverlay(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [valueOverlayRef]);

  return (
    <div>
      {showOverlay && (
        <div className="fixed inset-0 z-50 p-24 bg-black/50 flex flex-col justify-end  ">
          <div
            ref={valueOverlayRef}
            className="text-white flex flex-col items-center gap-8 text-center text-2xl font-satoshi"
          >
            You can use Arrow keys to navigate this section
            <div className="flex gap-6 text-3xl">
              <MdKeyboardArrowLeft />
              <MdKeyboardArrowRight />
            </div>
          </div>
        </div>
      )}
      <div className=" font-manrope h-full flex flex-col px-28 py-8 ">
        <div className="flex flex-col gap-2   ">
          <h1 className="text-primary-blue-500 text-xl font-medium ">
            Which of these values would be the most important for you to uphold?
          </h1>

          <h3 className="text-md text-primary-grey-600">
            Think about what you consider to be the most important out of these
            qualities
          </h3>
        </div>
        <div className=" py-12  ">
          <Slider ref={sliderRef} {...settings}>
            {valueQuestionArr.map((question, idx) => (
              <div key={idx} className="flex flex-col  items-center ">
                <div className="flex flex-col gap-6  ">
                  <img
                    src={question.icon}
                    className="w-32 h-32 object-contain mx-auto"
                  />
                  <h2 className="font-satoshi font-bold text-md text-primary-grey-800 mx-auto">
                    {quizStep + 1}. {question.title}
                  </h2>
                </div>
                <div className="value_slick_grid my-10">
                  {question.options.map((option, idx) => {
                    const isOptionSelected =
                      details[field]?.quizData.find(
                        (quiz) => quiz.quizStep === quizStep
                      ) &&
                      details[field]?.quizData
                        .find((quiz) => quiz.quizStep === quizStep)
                        ?.options.includes(option);
                    return (
                      <div
                        key={idx}
                        onClick={() => handleAnswerSelection(quizStep, option)}
                        style={{
                          borderColor: question.dark,
                          background: isOptionSelected
                            ? question.dark
                            : "transparent",
                          color: isOptionSelected ? "white" : "inherit",
                        }}
                        className={`w-full  cursor-pointer  p-4 text-sm border-[1.5px] h-full max-w-2xl m-auto  rounded-full hover:border-[2.5px] grid place-items-center text-center text-primary-grey-500`}
                      >
                        <p>{option}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {!isNavigationVisible && (
        <WebValueQuizNavigation
          totalSteps={FormComponents(step, details).length}
          field={field}
        />
      )}
    </div>
  );
};

export default WebValueQuizForm;
