import { goalFocusTime } from "helpers/constants/goals";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import * as JournalAPI from "../../config/APIs/journals";
import ProgressTimer from "./ProgessTimer";
import ClockOutModal from "components/FocusArea/Modal/ClockOutModal";
import moment from "moment";
import { endSession } from "config/APIs/session";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
function ClockedIn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { focus } = useParams();
  const [goalInfo, setGoalInfo] = useState();
  const [openClockOut, setOpenClockOut] = useState(false);
  const [manualClockOut, setManualClockOut] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const clockInTime = new Date(goalInfo?.clockIn).getTime();
    const totalDurationMs = goalInfo?.duration * 60 * 1000;

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsed = currentTime - clockInTime;

      if (elapsed < totalDurationMs) {
        setElapsedTime(elapsed);
      } else {
        setElapsedTime(totalDurationMs);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [goalInfo?.clockIn, goalInfo?.duration]);

  const totalDurationMs = goalInfo?.duration * 60 * 1000;
  const percentage = (elapsedTime / totalDurationMs) * 100;

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  useEffect(() => {
    fetchOngoingJournals();
  }, []);

  const fetchOngoingJournals = async () => {
    try {
      const response = await JournalAPI.getOnGoingJournal(); // Add `await` here
      console.log(response.status);
      if (response.status === 200) {
        let data = response.data;
        setGoalInfo(data.data);
      }
    } catch (error) {
      console.error("An error occurred while fetching journals:", error);
    }
  };

  const clockOut = async (data) => {
    let time;
    if (
      moment(goalInfo?.clockIn).add(goalInfo?.duration, "minutes") > moment()
    ) {
      time = moment();
    } else {
      time = moment(goalInfo?.clockIn).add(goalInfo?.duration, "minutes");
    }
    try {
      const response = await endSession({
        sessionId: goalInfo?.sessionId,
        clockOut: time,
        goalId: goalInfo?.id,
        clockOutByUser: true,
        comments: data?.title,
        metaData: data?.mood,
      });

      // try {
      //   let body = {
      //     ...data,
      //     sessionId: goalInfo?.sessionId,
      //     goalId: goalInfo?.id,
      //   };
      //   console.log(body);
      //   const res = await JournalAPI.createAJournal(body);

      //   dispatch(fetchAllgoals());
      //   dispatch(fetchAllJournals());

      //   history.goBack();
      // } catch (err) {
      //   dispatch(
      //     showToast({ message: err?.response?.data?.message, type: "error" })
      //   );
      //   console.log("Error", err);
      // }
      history.goBack();
    } catch (err) {
      dispatch(
        showToast({ message: err?.response?.data?.message, type: "error" })
      );
      console.log("Error", err);
    }
  };
  return (
    <>
      <ClockOutModal
        goal={goalInfo}
        isOpen={openClockOut}
        manualClockOut={manualClockOut}
        clockIn={goalInfo?.clockIn}
        duration={goalInfo?.duration}
        closeModal={() => {
          setOpenClockOut(false);
        }}
        onUpdate={(data) => {
          clockOut(data);
          setOpenClockOut(false);
        }}
      />
      <div
        style={{
          background:
            goalFocusTime?.find((i) => i?.label == goalInfo?.focusIn)
              ?.backgroundRadiant ||
            goalFocusTime?.find((i) => i?.label == focus)?.backgroundRadiant,
        }}
        className="h-screen w-screen z-50 fixed top-0 right-0 left-0 bottom-0 overflow-y-auto"
      >
        <div className="py-6 px-4 space-y-4">
          <div className="flex flex-row items-center w-full space-x-4">
            <div className="w-12 h-12">
              <ProgressTimer
                clockIn={goalInfo?.clockIn}
                duration={goalInfo?.duration}
              />
            </div>
            <div className="flex flex-col space-y-1 w-6/12">
              <p className="text-primary-neutral-200 font-medium text-xs font-satoshi tracking-[0.24px] leading-5">
                {formatTime(goalInfo?.duration)}
              </p>
              <p className="text-primary-neutral-200 font-medium text-xs font-satoshi tracking-[0.24px] leading-5">
                {formatTime(elapsedTime)}
              </p>
            </div>
            <div
              onClick={() => setOpenClockOut(true)}
              className="flex cursor-pointer flex-row items-center py-2 space-x-2 px-4 rounded-full bg-black bg-opacity-25 text-white text-xs font-satoshi font-bold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M12.6663 4.77203L11.7263 3.83203L7.99967 7.5587L4.27301 3.83203L3.33301 4.77203L7.05967 8.4987L3.33301 12.2254L4.27301 13.1654L7.99967 9.4387L11.7263 13.1654L12.6663 12.2254L8.93967 8.4987L12.6663 4.77203Z"
                  fill="white"
                />
              </svg>{" "}
              <p className="text-white text-xs font-normal tracking-[0.24px] leading-5">
                Exit Forge
              </p>
            </div>
          </div>
          <div className="flex flex-row items-stretch space-x-4">
            {[5, 10, 15]?.map((item) => {
              return (
                <div className="text-primary-neutral-200 font-satoshi text-2xs font-bold leading-4 tracking-[0.2px] rounded-full border border-primary-neutral-200 py-1 px-2">
                  +{item}m
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-4">
          <div className="w-full rounded-2xl border border-primary-neutral-100 bg-white px-4 py-3.5 flex flex-row items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 0C15.5225 0 20 4.4775 20 10.0004C20 15.5225 15.5225 20 9.99958 20C4.4775 20 0 15.5225 0 9.99958C0 4.4775 4.4775 0 10.0004 0ZM10.0004 16.5217C10.8631 16.5314 11.7191 16.3698 12.5188 16.0464C13.3186 15.723 14.0463 15.2441 14.6597 14.6375C15.2731 14.0309 15.7601 13.3086 16.0924 12.5125C16.4248 11.7164 16.5959 10.8623 16.5958 9.99958C16.5957 9.13688 16.4245 8.28277 16.0921 7.4867C15.7597 6.69063 15.2726 5.96842 14.6591 5.3619C14.0456 4.75538 13.3179 4.2766 12.5181 3.95328C11.7182 3.62996 10.8622 3.46853 9.99958 3.47833C6.42775 3.5205 3.55442 6.428 3.55442 10C3.55442 13.572 6.42858 16.4795 10.0004 16.5217ZM10.0004 13.2006C9.5788 13.2027 9.16091 13.1215 8.77076 12.9617C8.38061 12.8018 8.0259 12.5665 7.72701 12.2691C7.42811 11.9717 7.19093 11.6182 7.02908 11.2289C6.86724 10.8396 6.78392 10.4221 6.78392 10.0005C6.78392 9.57883 6.86724 9.16136 7.02908 8.77204C7.19093 8.38271 7.42811 8.02921 7.72701 7.73183C8.0259 7.43446 8.38061 7.19908 8.77076 7.03922C9.16091 6.87937 9.5788 6.79818 10.0004 6.80033C11.7674 6.80083 13.1996 8.23342 13.1996 10.0004C13.1996 11.7674 11.7674 13.2001 10.0004 13.2006Z"
                fill="#ED4C41"
              />
            </svg>
            <p className="text-black font-lato text-sm font-semibold tracking-[0.28px] leading-5">
              {goalInfo?.title}
            </p>
          </div>
        </div>
        <img
          src="/assets/images/bgPart.svg"
          alt=""
          className="w-full h-[5vh]"
        />
        <div className="bg-primary-neutral-50 py-4 space-y-4 -mt-1">
          <div className="py-6 space-y-6 px-4">
            <div className="flex flex-col space-y-2 text-center items-center">
              <h1 className="text-black font-satoshi text-lg font-bold leading-7 tracking-[0.36px]">
                What would you like to do now?
              </h1>
              <p className="text-primary-neutral-500 font-satoshi text-xs leading-5 tracking-[0.24px]">
                Choose what you want to do right now to access tools that
                facilitate your work
              </p>
            </div>
            <div className="flex flex-col space-y-6">
              {[
                {
                  svg: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5.00033 10.8333C3.16699 10.8333 1.66699 12.3333 1.66699 14.1667C1.66699 16 3.16699 17.5 5.00033 17.5C6.83366 17.5 8.33366 16 8.33366 14.1667C8.33366 12.3333 6.83366 10.8333 5.00033 10.8333ZM10.0003 2.5C8.16699 2.5 6.66699 4 6.66699 5.83333C6.66699 7.66667 8.16699 9.16667 10.0003 9.16667C11.8337 9.16667 13.3337 7.66667 13.3337 5.83333C13.3337 4 11.8337 2.5 10.0003 2.5ZM15.0003 10.8333C13.167 10.8333 11.667 12.3333 11.667 14.1667C11.667 16 13.167 17.5 15.0003 17.5C16.8337 17.5 18.3337 16 18.3337 14.1667C18.3337 12.3333 16.8337 10.8333 15.0003 10.8333Z"
                          fill="#363430"
                        />
                      </svg>
                    </>
                  ),
                  name: "Start Deep Work",
                  description:
                    "Action description here lorem ipsum dolor sit amet, consectetur adipiscing elit",
                },
                {
                  svg: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M15.2735 18.7496C14.511 18.7496 13.4399 18.4739 11.836 17.5778C9.88562 16.484 8.37702 15.4742 6.43718 13.5395C4.56687 11.6703 3.65671 10.4602 2.38288 8.14222C0.94382 5.52503 1.18913 4.15315 1.46335 3.56683C1.78991 2.86604 2.27195 2.4469 2.89499 2.03089C3.24888 1.79903 3.62338 1.60027 4.01374 1.43714C4.0528 1.42034 4.08913 1.40433 4.12155 1.38987C4.31491 1.30276 4.60788 1.17112 4.97898 1.31175C5.22663 1.40472 5.44773 1.59495 5.79382 1.93675C6.50359 2.63675 7.47351 4.19573 7.83132 4.96136C8.07155 5.47737 8.23054 5.818 8.23093 6.20003C8.23093 6.64729 8.00593 6.99222 7.73288 7.36448C7.68171 7.4344 7.63093 7.5012 7.58171 7.56604C7.28444 7.95667 7.21921 8.06956 7.26218 8.27112C7.34929 8.6762 7.9989 9.88206 9.06648 10.9473C10.1341 12.0125 11.3051 12.6211 11.7118 12.7078C11.9219 12.7528 12.0372 12.6848 12.4403 12.377C12.4981 12.3328 12.5575 12.2871 12.6196 12.2414C13.036 11.9317 13.3649 11.7125 13.8016 11.7125H13.804C14.1841 11.7125 14.5094 11.8774 15.0485 12.1492C15.7516 12.5039 17.3575 13.4614 18.0618 14.1719C18.4044 14.5172 18.5954 14.7375 18.6887 14.9848C18.8294 15.3571 18.6969 15.6489 18.6106 15.8442C18.5962 15.8766 18.5801 15.9121 18.5634 15.9516C18.3989 16.3413 18.199 16.715 17.9661 17.068C17.5509 17.6891 17.1301 18.17 16.4278 18.4969C16.0671 18.6675 15.6725 18.7539 15.2735 18.7496Z"
                          fill="#363430"
                        />
                      </svg>
                    </>
                  ),
                  name: "Take A Call",
                  description:
                    "Action description here lorem ipsum dolor sit amet, consectetur adipiscing elit",
                },
                {
                  svg: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5.00033 5.0013H15.0003V15.0013H5.00033V5.0013ZM5.00033 1.66797H6.66699V4.16797H5.00033V1.66797ZM5.00033 15.8346H6.66699V18.3346H5.00033V15.8346ZM1.66699 5.0013H4.16699V6.66797H1.66699V5.0013ZM1.66699 13.3346H4.16699V15.0013H1.66699V13.3346ZM15.8337 5.0013H18.3337V6.66797H15.8337V5.0013ZM15.8337 13.3346H18.3337V15.0013H15.8337V13.3346ZM13.3337 1.66797H15.0003V4.16797H13.3337V1.66797ZM13.3337 15.8346H15.0003V18.3346H13.3337V15.8346Z"
                          fill="#363430"
                        />
                      </svg>
                    </>
                  ),
                  name: "Collect Inspirations",
                  description:
                    "Action description here lorem ipsum dolor sit amet, consectetur adipiscing elit",
                },
                {
                  svg: (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                      >
                        <path
                          d="M11.1663 15.5H2.83301C1.45467 15.5 0.333008 14.3783 0.333008 13V3C0.333008 1.62167 1.45467 0.5 2.83301 0.5H11.1663C12.5447 0.5 13.6663 1.62167 13.6663 3V13C13.6663 14.3783 12.5447 15.5 11.1663 15.5ZM2.83301 2.16667C2.37384 2.16667 1.99967 2.54083 1.99967 3V13C1.99967 13.4592 2.37384 13.8333 2.83301 13.8333H11.1663C11.6255 13.8333 11.9997 13.4592 11.9997 13V3C11.9997 2.54083 11.6255 2.16667 11.1663 2.16667H2.83301ZM10.333 7.16667H3.66634C3.43634 7.16667 3.24967 6.98 3.24967 6.75C3.24967 6.52 3.43634 6.33333 3.66634 6.33333H10.333C10.563 6.33333 10.7497 6.52 10.7497 6.75C10.7497 6.98 10.563 7.16667 10.333 7.16667ZM10.333 4.66667H3.66634C3.43634 4.66667 3.24967 4.48 3.24967 4.25C3.24967 4.02 3.43634 3.83333 3.66634 3.83333H10.333C10.563 3.83333 10.7497 4.02 10.7497 4.25C10.7497 4.48 10.563 4.66667 10.333 4.66667ZM10.333 9.66667H3.66634C3.43634 9.66667 3.24967 9.48 3.24967 9.25C3.24967 9.02 3.43634 8.83333 3.66634 8.83333H10.333C10.563 8.83333 10.7497 9.02 10.7497 9.25C10.7497 9.48 10.563 9.66667 10.333 9.66667ZM10.333 12.1667H3.66634C3.43634 12.1667 3.24967 11.98 3.24967 11.75C3.24967 11.52 3.43634 11.3333 3.66634 11.3333H10.333C10.563 11.3333 10.7497 11.52 10.7497 11.75C10.7497 11.98 10.563 12.1667 10.333 12.1667Z"
                          fill="#363430"
                        />
                      </svg>
                    </>
                  ),
                  name: "Document Your Work",
                  description:
                    "Action description here lorem ipsum dolor sit amet, consectetur adipiscing elit",
                },
              ]?.map((item) => {
                return (
                  <div className="rounded-[20px] border border-primary-neutral-300 p-4 flex flex-col space-y-2">
                    <div className="flex flex-row items-center space-x-2">
                      {item?.svg}
                      <h1 className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-5 tracking-[0.28px]">
                        {item?.name}
                      </h1>
                    </div>
                    <p className="text-primary-neutral-500 font-satoshi text-2xs leading-4 tracking-[0.4px]">
                      {item?.description}
                    </p>
                  </div>
                );
              })}
              <div className="rounded-full px-14 font-satoshi py-3.5 max-w-max mx-auto bg-primary-neutral-900 text-white text-sm font-bold leading-5 tracking-[0.7px] capitalize">
                View All
              </div>
            </div>
          </div>
          <div className="py-6 px-4 flex flex-col items-center space-y-6">
            <div className="flex flex-col space-y-2 text-center items-center">
              <h1 className="text-black font-satoshi text-lg font-bold leading-7 tracking-[0.36px]">
                Resource cards for you
              </h1>
              <p className="text-primary-neutral-500 font-satoshi text-xs leading-5 tracking-[0.24px]">
                Resource cards assist you in completing yor current goal
              </p>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              {[
                "Advanced Windows PowerShell techniques",
                "Being a Data Owner",
                "Coaching teams",
                "Consultive sales",
              ]?.map((item) => {
                return (
                  <div className="h-[200px] bg-primary-neutral-100 rounded-2xl p-4 flex flex-col justify-end space-y-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6 13C3.8 13 2 14.8 2 17C2 19.2 3.8 21 6 21C8.2 21 10 19.2 10 17C10 14.8 8.2 13 6 13ZM12 3C9.8 3 8 4.8 8 7C8 9.2 9.8 11 12 11C14.2 11 16 9.2 16 7C16 4.8 14.2 3 12 3ZM18 13C15.8 13 14 14.8 14 17C14 19.2 15.8 21 18 21C20.2 21 22 19.2 22 17C22 14.8 20.2 13 18 13Z"
                        fill="#363430"
                      />
                    </svg>
                    <p className="text-primary-neutral-800 font-satoshi text-xs font-bold leading-5 tracking-[0.24px] capitalize">
                      {item}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="rounded-full px-14 font-satoshi py-3.5 max-w-max mx-auto bg-primary-neutral-900 text-white text-sm font-bold leading-5 tracking-[0.7px] capitalize">
              View All
            </div>
          </div>

          <div className="py-6 px-4 flex flex-col items-center space-y-10">
            <div className="flex flex-col space-y-2 text-center items-center">
              <h1 className="text-black font-satoshi text-lg font-bold leading-7 tracking-[0.36px]">
                Submit Your Work
              </h1>
              <p className="text-primary-neutral-500 font-satoshi text-xs leading-5 tracking-[0.24px]">
                Upload your work files here{" "}
              </p>
            </div>
            <div className="h-[40vh] flex flex-col w-full items-center justify-center rounded-2xl border-2 border-dashed border-primary-neutral-300 space-y-4">
              <div className="rounded-full p-4 bg-primary-neutral-100 w-14 h-14 flex flex-row items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M4 19.5H20V12.5H22V20.5C22 20.7652 21.8946 21.0196 21.7071 21.2071C21.5196 21.3946 21.2652 21.5 21 21.5H3C2.73478 21.5 2.48043 21.3946 2.29289 21.2071C2.10536 21.0196 2 20.7652 2 20.5V12.5H4V19.5ZM13 9.5V16.5H11V9.5H6L12 3.5L18 9.5H13Z"
                    fill="#1A6BE5"
                  />
                </svg>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-primary-neutral-800 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]">
                  Tap to upload your files
                </h1>
                <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]">
                  or drop them here
                </p>

                <p className="text-primary-neutral-500 font-satoshi text-2xs font-bold leading-4 tracking-[0.2px]">
                  upload documents, links or images
                </p>
              </div>
            </div>

            <div className="h-[30vh] flex flex-col w-full items-center justify-center rounded-2xl bg-primary-neutral-100">
              <p className="text-primary-neutral-400 font-satoshi text-xs font-bold leading-5 tracking-[0.24px]">
                Your uploads will appear here
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClockedIn;
