import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ServiceActivated from "./ServiceActivated";
import ActivateService from "./ActivateService";
import { useState } from "react";
import { Modal } from "@mui/material";
import ConfirmationModal from "./ConfirmationModal";
import { updateUserService } from "config/APIs/users";
import { fetchAllUserServices } from "redux/life";
import { MdArrowBack } from "react-icons/md";

export default function ServiceDetails() {
  const { serviceId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userServices, services } = useSelector((state) => state?.life);
  const isLiked = userServices?.find((a) => a?.service === serviceId);
  const service = services?.find((s) => s._id === serviceId);

  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const onActivate = async () => {
    try {
      setLoading(true);
      let obj = { serviceId: serviceId };
      if (userServices?.find((a) => a?.service == serviceId)) {
        obj["remove"] = true;
      }

      await updateUserService(obj);
      dispatch(fetchAllUserServices());
      setConfirmationModal(true);
      window.scrollTo({
        top: 0,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onDeactivate = async () => {
    try {
      setLoading(true);
      let obj = { serviceId: serviceId, remove: true };
      await updateUserService(obj);
      dispatch(fetchAllUserServices());

      history.push("/services");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="h-[50px] fixed top-0 right-0 left-0 md:hidden bg-white z-30" />
      <div className="flex px-4 mt-[50px] py-6 flex-col space-y-2 w-full bg-white fixed top-0 z-10">
        <p
          className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-lato text-base tracking-[0.64px] leading-6"
          onClick={() => history.goBack()}
        >
          <MdArrowBack />
          <p>Service Details</p>
        </p>
      </div>
      {isLiked ? (
        <ServiceActivated
          onDeactivate={onDeactivate}
          loading={loading}
          service={service}
        />
      ) : (
        <ActivateService
          onActivate={onActivate}
          loading={loading}
          service={service}
        />
      )}
      <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <ConfirmationModal
          setConfirmationModal={setConfirmationModal}
          completed={2}
        />
      </Modal>
    </>
  );
}
