import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import {
  FormComponents,
  phaseTwoQuestionArr,
} from "helpers/constants/phaseTwoForm";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateQuizStepPhaseTwo,
  updateStepPhaseTwo,
  updateSubStepPhaseTwo,
} from "redux/profile-form";

const QuizNavigation = () => {
  const { quiz, step, subStep, quizStep } = useSelector(
    (state) => state.profileForm.phaseTwo
  );
  const dispatch = useDispatch();
  const getDisabledState = () => {
    if (
      step === FormComponents("mobile").length - 1 &&
      quizStep === phaseTwoQuestionArr[subStep]?.questions.length - 1
    )
      return true;

    if (!quiz[subStep] || !quiz[subStep][quizStep]) return true;
    if (
      quiz[subStep] &&
      quiz[subStep][quizStep] &&
      quiz[subStep][quizStep].length === 0
    )
      return true;
  };

  const handleQuizNextClick = () => {
    if (quizStep < phaseTwoQuestionArr[subStep]?.questions.length - 1) {
      dispatch(updateQuizStepPhaseTwo(quizStep + 1));
    } else {
      dispatch(updateStepPhaseTwo(step + 1));
      dispatch(updateSubStepPhaseTwo(subStep + 1));
      dispatch(updateQuizStepPhaseTwo(0));
    }
  };

  const handleQuizPreviousClick = () => {
    if (quizStep === 0) {
      dispatch(updateStepPhaseTwo(step - 1));
      dispatch(updateQuizStepPhaseTwo(0));
      if (subStep === 0) return;
      dispatch(updateSubStepPhaseTwo(subStep - 1));
    } else {
      dispatch(updateQuizStepPhaseTwo(quizStep - 1));
    }
  };
  return (
    <div className="bg-white z-10 xl:fixed bottom-0 inset-x-0">
      <div className="flex justify-between relative  gap-6 items-center p-4 lg:py-2 lg:px-20 ">
        <ProfileButton
          onClick={handleQuizPreviousClick}
          text={"Previous"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
        />
        <div className="xl:absolute xl:left-1/2 xl:top-1/2 transform xl:-translate-x-1/2 xl:-translate-y-1/2 ">
          <ProfileButton
            onClick={handleQuizNextClick}
            disabled={getDisabledState()}
            text={"Next"}
            size={"large"}
            variant={"filled"}
            icon={<ChevronRightIcon className="w-6" />}
            iconAlign={"right"}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizNavigation;
