import React from "react";
import { ReactComponent as Logo } from "../../../assets/GoProPage/Polygon.svg";
import { BsDownload } from "react-icons/bs";
import InViewWrapper from "components/Animation/InViewWrapper";

const SecondTab = ({ step, setStep }) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="lg:px-40 px-8 font-inter">
        <InViewWrapper classes="w-full pt-12 pb-6 text-2xl font-medium">
          <h1 className="max-sm:flex justify-around items-center font-poppins">
            Your Community, Our Rules
          </h1>
        </InViewWrapper>

        <div className="flex flex-col gap-8 w-full py-10">
          <InViewWrapper>
            <div className="flex flex-col w-full gap-2">
              <Logo />
              <h2 className=" text-[#CE5511]  text-xl  font-medium font-poppins">
                Your Caarya Network
              </h2>
              <p className=" text-lg font-light font-inter ">
                Connect with people in your Caarya clan and tribe
              </p>
            </div>
            <div className="flex w-full max-[600px]:justify-around">
              <img
                className="object-cover w-52 h-52   object-contain "
                src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
              />
            </div>
          </InViewWrapper>
          <InViewWrapper>
            <div className="flex flex-col gap-4 font-inter">
              <h2 className="font-semibold text-base">Know Your Clan</h2>
              <p className="font-light text-sm leading-5 tracking-[2%] ">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
              </p>
            </div>
            <div className="flex flex-col gap-4 font-inter">
              <h2 className="font-semibold text-base">Know your Tribe</h2>
              <p className="font-light text-sm leading-5 tracking-[2%] ">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
              </p>
            </div>
          </InViewWrapper>
          <InViewWrapper>
            <img
              className="object-cover h-52 mt-6  object-cover w-full max-w-4xl mx-auto "
              src="https://www.mahindrauniversity.edu.in/sites/default/files/2021/dummy-banner_3.jpg"
            />
          </InViewWrapper>
        </div>

        <InViewWrapper>
          <div className="py-10 py-4 w-full">
            <div className="flex flex-col w-full min-[600px]:py-10  min-[600px]:px-12 p-4 bg-[#FFF1EB] rounded-2xl">
              <div className="flex flex-col max-w-[1000px] gap-4">
                <Logo />
                <h2 className=" text-[#CE5511] lg:text-2xl text-xl font-medium font-poppins">
                  The Red Zone
                </h2>
                <p className="lg:text-lg text-base font-light font-inter">
                  And the need for professional conduct
                </p>

                <div className="flex mt-8 flex-col gap-10 md:gap-[72px] w-full ">
                  <InViewWrapper delay="0.2">
                    <div className="flex justify-between gap-6">
                      <div className="flex flex-col gap-4">
                        <div className="flex justify-around gap-6 items-center w-full min-[600px]:hidden">
                          <img
                            className="object-cover w-[70px] h-[70px] object-contain"
                            src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                          />
                          <h2 className="text-base font-semibold">
                            Community Guidelines to ensure a safe working
                            environment for everyone
                          </h2>
                        </div>
                        <h2 className="font-semibold max-[600px]:hidden">
                          Community Guidelines to ensure a safe working
                          environment for everyone
                        </h2>
                        <p className="text-sm font-light ">
                          Some insight or text here giving a glimpse of what is
                          inside community guidelines
                        </p>
                        <div className="flex gap-4 items-center">
                          <p className="font-light text-xs text-[#696763] ">
                            Read more about our general guidelines here:
                          </p>
                        </div>
                        <div className="flex justify-start max-[800px]:justify-around p-2">
                          <button className="flex gap-3 py-4 px-4 items-center rounded-lg bg-white shadow">
                            <BsDownload className="text-[#4CA6E5]" />
                            <p className="font-light text-sm ">
                              General Guidelines.pdf
                            </p>
                          </button>
                        </div>
                      </div>
                      <div className="flex self-start justify-end grow max-[600px]:hidden p-2">
                        <img
                          className="object-cover object-cover w-[88px] h-[88px] object-contain"
                          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                        />
                      </div>
                    </div>
                  </InViewWrapper>

                  <InViewWrapper delay="0.2">
                    <div className="flex justify-between gap-6">
                      <div className="flex flex-col gap-4">
                        <div className="flex justify-around gap-6 items-center w-full min-[600px]:hidden">
                          <img
                            className="object-cover w-[70px] h-[70px] object-contain"
                            src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                          />
                          <h2 className="text-base font-semibold">
                            Code of conduct we expect from our workforce
                          </h2>
                        </div>
                        <h2 className="font-semibold max-[600px]:hidden">
                          Code of conduct we expect from our workforce
                        </h2>
                        <p className=" text-sm font-light">
                          Some insight or text here giving a glimpse of what is
                          inside code of conduct
                        </p>
                        <div className="flex gap-4 items-center">
                          <p className="font-light text-xs text-[#696763] ">
                            Read more about our general guidelines here:
                          </p>
                        </div>
                        <div className="flex justify-start max-[800px]:justify-around p-2">
                          <button className="flex gap-3 py-4 px-4 items-center rounded-lg bg-white shadow">
                            <BsDownload className="text-[#4CA6E5]" />
                            <p className="font-light text-sm ">
                              Code of Conduct.pdf
                            </p>
                          </button>
                        </div>
                      </div>
                      <div className="flex self-start justify-end grow max-[600px]:hidden p-2">
                        <img
                          className="object-cover w-[88px] h-[88px] object-contain max-[600px]:hidden"
                          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                        />
                      </div>
                    </div>
                  </InViewWrapper>

                  <InViewWrapper delay="0.2">
                    <div className="flex justify-between gap-6">
                      <div className="flex flex-col gap-4">
                        <div className="flex justify-around gap-6 items-center w-full min-[600px]:hidden">
                          <img
                            className="object-cover w-[70px] h-[70px] object-contain "
                            src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                          />
                          <h2 className="text-base font-semibold">
                            An Insight into our culture, values, work and
                            learning environment
                          </h2>
                        </div>
                        <h2 className="font-semibold max-[600px]:hidden">
                          An Insight into our culture, values, work and learning
                          environment
                        </h2>
                        <p className="text-sm font-light ">
                          Some insight or text here giving a glimpse of what is
                          inside student handbook
                        </p>
                        <div className="flex gap-4 items-center">
                          <p className="font-light text-xs text-[#696763] ">
                            Read more about our general guidelines here:
                          </p>
                        </div>
                        <div className="flex justify-start max-[800px]:justify-around p-2">
                          <button className="flex gap-3 py-4 px-4 items-center rounded-lg bg-white shadow">
                            <BsDownload className="text-[#4CA6E5]" />
                            <p className="font-light text-sm ">
                              Student Handbook.pdf
                            </p>
                          </button>
                        </div>
                      </div>
                      <div className="flex self-start justify-end grow max-[600px]:hidden p-2">
                        <img
                          className="object-cover w-[88px] h-[88px] object-contain max-[600px]:hidden"
                          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                        />
                      </div>
                    </div>
                  </InViewWrapper>
                </div>
              </div>
            </div>
          </div>
        </InViewWrapper>

        <InViewWrapper>
          <div className="flex flex-col gap-4 py-10">
            <div className="flex flex-col gap-8 w-full ">
              <div className="flex flex-col w-full gap-2">
                <Logo />
                <h2 className=" text-[#CE5511]  text-2xl   font-medium font-poppins ">
                  Caarya Growth Centers For You
                </h2>
                <p className=" text-lg font-light font-inter ">
                  We are just a system away
                </p>
              </div>
              <p className=" text-sm font-light font-inter leading-5 tracking-[2%] ">
                Introduce CGCs here and talk about their local chaptersExcepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id es Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id es
              </p>
            </div>
            <img
              className="object-cover w-[280px] h-[280px] object-contain "
              src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
            />
          </div>
        </InViewWrapper>
      </div>

      <div className="">
        <button
          onClick={() => setStep(step + 1)}
          className="py-4 px-8 button-back-outer rounded-2xl w-fit lg:mb-12 bg-gradient-to-r from-primary-orange-button1 to-primary-orange-button2"
        >
          <span className="text-white font-bold w-fit text-sm">Next</span>
        </button>
      </div>
    </div>
  );
};

export default SecondTab;
