import LaptopForm from "components/ProfileForm/LaptopForm";
import MobileForm from "components/ProfileForm/MobileForm";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import WelcomeScreen from "components/ProfileForm/WelcomeScreen/WelcomeScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { fetchCandidateData } from "redux/profile-form/action";

const ProfileForm = () => {
  const theme = useTheme();
  const { isInititalDataFetched, error } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const largeView = useMediaQuery(theme.breakpoints.up("lg"));
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const fallback = () => {
    history.push("/auth/signIn");
  };

  useEffect(() => {
    if (!isInititalDataFetched) {
      dispatch(fetchCandidateData(id, fallback));
      const loadingTimeOut = setInterval(() => {
        if (isInititalDataFetched) {
          setShowWelcomeScreen(false);
        }
      }, [3000]);

      return () => clearTimeout(loadingTimeOut);
    }
    if (error) {
      history.push("/auth/signIn");
    }
  }, [dispatch, error, isInititalDataFetched]);

  return (
    <div>
      {/* {showWelcomeScreen && <WelcomeScreen />} */}
      <>{largeView ? <LaptopForm /> : <MobileForm />}</>
    </div>
  );
};

export default ProfileForm;
