import { useSelector } from "react-redux";

const ProgressBar = () => {
  const { step } = useSelector((state) => state.profileForm.phaseThree);

  return (
    <div className="w-full flex items-center gap-3 md:gap-4 mx-auto relative container">
      <p className="text-primary-grey-600 text-xs font-bold">{step}%</p>
      <div className="bg-gray-200 rounded-full h-1 w-full">
        <div
          className="w-[100%] bg-blue-600 h-1 rounded-full"
          style={{
            width: `${step}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
