import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStepPhaseTwo } from "redux/profile-form";
import LockIcon from "../../../../ProfileForm/MobileForm/components/JourneyForm/assets/lock.svg";

const CheckPoint = () => {
  const { step } = useSelector((state) => state.profileForm.phaseTwo);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col my-5 pb-16 ">
      <div className="flex gap-4 justify-end ">
        <div className="flex  justify-end  border-t-2 md:border-t-4 border-dashed  py-16">
          <div className="flex flex-col gap-2 max-w-[200px] text-primary-grey-500  justify-center ">
            <h2 className="text-lg   font-medium ">Step 1:</h2>
            <p className="font-satoshi text-xs md:text-sm">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.{" "}
            </p>
          </div>
        </div>

        <div className=" border-y-2 md:border-y-4  border-r-2 md:border-r-4 rounded-r-full border-dashed pr-16 lg:pr-44 py-16 sm:p-16 ">
          <div className="flex flex-col relative gap-2 w-24 md:w-40  justify-center items-center">
            <img
              className="w-24 md:w-40 h-24 md:h-40 object-cover rounded-full "
              src={
                "http://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-square-1.jpg"
              }
              alt=""
            />
            <p className="text-sm max-w-[96px] font-satoshi text-primary-red-500 font-semibold">
              What I Love
            </p>
            <div className="flex gap-12 items-center z-10 transform -bottom-16 translate-y-1/2 absolute">
              <div className="absolute left-[10px]  w-16 h-16 -top-2 rounded-full -z-10 bg-primary-blue-200 animate-ping "></div>{" "}
              <div className="absolute left-[20px]  w-10 h-10 top-1 rounded-full -z-10 bg-primary-blue-300 animate-ping "></div>{" "}
              <ProfileButton
                onClick={() => dispatch(updateStepPhaseTwo(step + 1))}
                text={"Start"}
                size={"small"}
                variant={"filled"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" min-h-[278px] border-2 border-dashed border-r-0 sm:border-0 rounded-l-full relative mr-32 md:mr-40 md:-mt-1 ">
        <div className="absolute sm:relative flex justify-between inset-y-0 left-0 flex-row-reverse gap-4">
          <div className="flex w-full justify-start sm:border-y-2 md:border-y-4 sm:border-dashed  py-16">
            <div className="flex flex-col gap-2 min-w-[150px] max-w-[200px] text-primary-grey-500  justify-center ">
              <h2 className="text-lg  font-medium ">Step 2:</h2>
              <p className="font-satoshi text-xs sm:text-sm">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.{" "}
              </p>
            </div>
          </div>

          <div className=" flex justify-start py-16 sm:p-16 sm:border-l-2 md:border-l-4 sm:border-y-2 md:border-y-4 rounded-l-full sm:border-dashed pl-16 lg:pl-44  ">
            <div className=" w-24 md:w-40 flex flex-col gap-2  justify-center items-center relative">
              <img
                className="w-24 md:w-40 h-24 md:h-40 object-cover rounded-full "
                src={
                  "http://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-square-1.jpg"
                }
                alt=""
              />
              <p className="text-sm max-w-[96px] text-center font-satoshi text-primary-grey-600 font-semibold">
                What I Care About
              </p>
              <img
                className="transform -bottom-16 translate-y-1/2 absolute"
                src={LockIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 justify-between transform md:-mt-1">
        <div className="flex  flex-1 justify-end   border-dashed  py-16">
          <div className="flex flex-col gap-2 max-w-[200px] text-primary-grey-500  justify-center  ">
            <h2 className="text-lg    font-medium ">Step 3:</h2>
            <p className="font-satoshi text-xs sm:text-sm">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.{" "}
            </p>
          </div>
        </div>

        <div className=" border-y-2 md:border-y-4  border-r-2 md:border-r-4 rounded-r-full border-dashed pr-16 lg:pr-44  py-16 sm:p-16 ">
          <div className="flex flex-col gap-2 w-24 md:w-40  justify-center items-center relative">
            <img
              className="w-24 h-24 md:w-40 md:h-40 object-cover rounded-full "
              src={
                "http://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-square-1.jpg"
              }
              alt=""
            />
            <p className="text-sm max-w-[96px] text-center font-satoshi  text-primary-grey-500 font-semibold">
              What I’m Good At
            </p>
            <img
              className="transform -bottom-16 translate-y-1/2 absolute z-50 "
              src={LockIcon}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className=" min-h-[278px] border-2 border-dashed border-r-0 sm:border-0 rounded-l-full relative mr-32 md:mr-40 transform  md:-mt-1">
        <div className="absolute sm:relative flex justify-between flex-row-reverse gap-4 inset-y-0 left-0 ">
          <div className="flex w-full justify-start sm:border-y-2 md:border-y-4 sm:border-dashed  py-16">
            <div className="flex flex-col gap-2 min-w-[150px] max-w-[200px] text-primary-grey-500  justify-center ">
              <h2 className="text-lg   font-medium ">Step 4:</h2>
              <p className="font-satoshi text-xs sm:text-sm">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.{" "}
              </p>
            </div>
          </div>

          <div className=" flex justify-start py-16 sm:p-16 sm:border-l-2 md:border-l-4 sm:border-y-2 md:border-y-4 rounded-l-full sm:border-dashed pl-16 lg:pl-44  ">
            <div className=" w-24 md:w-40 flex flex-col gap-2  justify-center items-center relative">
              <img
                className="w-24 md:w-40 h-24 md:h-40 object-cover rounded-full "
                src={
                  "http://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-square-1.jpg"
                }
                alt=""
              />
              <p className="text-sm max-w-[96px] text-center font-satoshi text-primary-grey-600 font-semibold">
                What I Can be Paid For
              </p>
              <img
                className="transform z-10 -bottom-16 translate-y-1/2 absolute"
                src={LockIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 justify-between md:-mt-1">
        <div className="flex  flex-1 justify-end   border-dashed  py-16">
          <div className="flex flex-col gap-2 max-w-[200px] text-primary-grey-500  justify-center  ">
            <h2 className="text-lg   font-medium ">Step 5:</h2>
            <p className="font-satoshi text-xs sm:text-sm">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.{" "}
            </p>
          </div>
        </div>

        <div className=" border-y-2 md:border-y-4  border-r-2 md:border-r-4 rounded-r-full border-dashed pr-16 lg:pr-44  py-16 sm:p-16 ">
          <div className="flex flex-col gap-2 w-24 md:w-40  justify-center items-center relative">
            <img
              className="w-24 md:w-40 h-24 md:h-40 object-cover rounded-full "
              src={
                "http://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-square-1.jpg"
              }
              alt=""
            />
            <p className="text-sm max-w-[96px] text-center font-satoshi text-primary-grey-500 font-semibold">
              My Needs From Life
            </p>
            <img
              className="transform -bottom-16 translate-y-1/2 absolute z-10 "
              src={LockIcon}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="w-full flex sm:justify-end -mt-1">
        <div className="sm:w-1/3 w-1/2 rounded-l-full py-16 pl-16 lg:pl-44 border-y-2 md:border-y-4 sm:border-b-0 md:border-b-0  border-l-2 md:border-l-4 border-dashed sm:mr-32 md:mr-40 relative ">
          <div className="absolute p-5 w-[140px] h-[140px] grid place-items-center bg-primary-yellow-50 border-2 rounded-3xl text-center border-primary-yellow-500  top-1/2 right-0 sm:left-0 trnasform translate-x-1/2 sm:-translate-x-1/2">
            <h2 className="font-satoshi max-w-[100px] font-semibold text-sm text-primary-grey-600 ">
              The path to my purpose
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckPoint;
