import { getHeader, lifeInstance } from "config";

export const getBoosters = () =>
  lifeInstance.get("/achievementCards/boosters", getHeader());

export const createAchievementCards = (body) =>
  lifeInstance.post("/achievementCards", body, getHeader());

export const findProjectById = (projectId) =>
  lifeInstance.get(`/achievementCards/project/${projectId}`, getHeader());

export const findAllProjectsForAchievementCards = () =>
  lifeInstance.get(`/achievementCards/project`, getHeader());
