import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "components/Common/Navigation";
import TypeForm from "components/Form/Typeform";
import { fetchAllCollege } from "redux/dropdown";
import { saveFormReply } from "config/APIs/form";

export default function Form() {
  const { code } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const save = useSelector((state) => state?.typeForm?.saveData);
  const details = useSelector((state) => state?.typeForm?.details);

  useEffect(() => {
    // getDriveData();
    dispatch(fetchAllCollege());
  }, [code]);

  useEffect(() => {
    if (save) {
      onSubmit();
    }
  }, [save]);

  // const getDriveData = async () => {
  //   try {
  //     const res = await getSourcingDrive(code);

  //     setDriveData(res?.data?.data);
  //     setDriveSections(res?.data?.data?.sectionMetaData);
  //   } catch (e) {
  //     console.log("Drive get one error: ", e, e?.response);
  //   }
  // };

  const onSubmit = async () => {
    // setSubmitting(true);
    try {
      let payload = {};
      let body = details;
      payload["name"] =
        body?.first_name + " " + (body?.last_name ? body?.last_name : "");
      payload["phoneNumber"] = body?.phone_number;

      // payload["college"] = body?.college_id;
      payload["email"] = body?.email;
      const formData = body.sectionMetaData.basicForm;
      const assessment1 = { data: [] };
      const assessment2 = { data: [] };
      // For Assesment1 in applicants table
      formData.Section1.forEach((element) => {
        assessment1.data.push({
          question: element.question,
          value: element.value,
          score: element.score,
        });
      });
      // For Assesment2 in applicants table
      formData.Section2.forEach((element) => {
        assessment2.data.push({
          question: element.question,
          value: element.value,
          score: element.score,
        });
      });
      payload["assessments"] = {
        assessment1: assessment1,
        assessment2: assessment2,
      };

      // For interests table
      console.log(formData.Section3[0].value);
      let brandingMarketingRoles = [];
      brandingMarketingRoles.push(...formData.Section3[0].value);
      brandingMarketingRoles.push(...formData.Section3[2].value);
      payload["interests"] = {
        transferrableSkills: formData.Section3[6].value,
        brandingMarketingRoles: brandingMarketingRoles,
        digitalTransform: formData.Section3[1].value,
        businessDev: formData.Section3[3].value,
        talentDev: formData.Section3[4].value,
        industries: formData.Section3[5].value,
        tools: body?.tools_interested,
        frameworks: body?.frameworks_interested,
        domains: body?.domains_interested,
        sdg: body?.sdgInterests,
      };

      // For work preferences
      payload["workPreference"] = {
        workTimings: formData.Section4[0].value,
        workHours: formData.Section4[1].value,
        weekendWork: formData.Section4[2].value,
      };

      // For Personal Info
      payload["personalInfo"] = {
        college: body?.college_name,
        workExperience: formData.Section4[3].value,
        priorExperience: formData.Section4[4].value,
        collegeYear: formData.Section5[4].value,
      };

      console.log("ans", payload);
      const res = await saveFormReply(user?._id, payload);
      if (res.data.data) {
      }
    } catch (e) {
      console.log("Save: ", e, e?.response);
    }
    // setSubmitting(false);
  };

  return (
    <div className="h-screen w-screen">
      <Navigation />
      <TypeForm />
    </div>
  );
}
