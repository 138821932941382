import { createSlice } from "@reduxjs/toolkit";
import { getAllColleges } from "config/APIs/colleges";
import { getRoles } from "config/APIs/roles";

const initialState = {
  colleges: [],
  roles: [],
};

export const dropdown = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    updateCollege: (state, action) => {
      state.colleges = action.payload.colleges;
    },
    updateRoles: (state, action) => {
      state.roles = action.payload.roles;
    },
  },
});

export const { updateCollege, updateRoles } = dropdown.actions;
export default dropdown.reducer;

export function fetchAllCollege() {
  return async (dispatch) => {
    try {
      const response = await getAllColleges();
      if (response.status === 200) {
        let data = response.data.data.response;

        // Dispatching college data to redux-store
        dispatch(
          updateCollege({
            colleges: data,
          })
        );
      }
    } catch (err) {
      console.log("college fetching error", err);
    }
  };
}

export function fetchAllRole() {
  return async (dispatch) => {
    try {
      const response = await getRoles();
      if (response.status === 200) {
        let data = response.data;

        // Dispatching college data to redux-store
        dispatch(
          updateRoles({
            roles: data?.data,
          })
        );
      }
    } catch (err) {
      console.log("role fetching error", err);
    }
  };
}
