import { instance } from "config";
import { lifeInstance, getHeader } from "config";

export const getColleges = (query) =>
  lifeInstance.get(
    `/colleges${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

export const getAllColleges = () => instance.get(`/public/college`);
