import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showNavigation } from "redux/profile-form";

const SelfReflectionForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showNavigation());
  }, []);
  return (
    <div className=" font-manrope h-full flex flex-col  p-4 md:px-10 gap-4 pt-24  ">
      <h4 className="text-xl text-primary-grey-500 max-w-sm font-medium ">
        Now then, a little more self reflection
      </h4>
      <h4 className="text-lg text-primary-grey-500 font-light">
        Let’s talk about your inclinations towards
        <span className="text-primary-blue-300 font-medium ml-1 ">
          societal needs.
        </span>{" "}
      </h4>

      <div className="grid place-items-center gap-4 my-4">
        <img
          className="w-72 h-72 object-contain "
          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
          alt=""
        />
        <p className="text-primary-grey-500 text-md text-left">
          In this final step, think about what you care for, and select the
          SDG’s that you like the most.
        </p>
      </div>
    </div>
  );
};

export default SelfReflectionForm;
