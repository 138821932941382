import React from "react";

function Navigation() {
  return (
    <div className="px-4 py-2 lg:py-4 z-40 lg:px-12 flex flex-row items-center border-b-2 border-primary-gray-200 bg-white fixed top-0 right-0 left-0">
      <img src="/assets/logo.svg" className="w-24 scale-110 h-auto" />
    </div>
  );
}

export default Navigation;
