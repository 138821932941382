import { useEffect, useMemo, useState } from "react";
import { MdArrowBack, MdDoneAll } from "react-icons/md";
import { StepFour, StepFive, StepSix } from "./Steps";
import { StepOne, StepTwo, StepThree } from "./BasicSteps";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isStepValid } from "helpers/utils";
import { setBoosters, setExisting, setValue } from "redux/achievementsForm";
import LoadingModal from "../Common/LoadingModal";
import ContinueModal from "../Common/ContinueModal";
import { IoArrowForwardOutline } from "react-icons/io5";
import "rsuite/DatePicker/styles/index.css";
import {
  createAchievementCards,
  findProjectById,
  getBoosters,
} from "config/APIs/achievementCards";
import { useLocation } from "react-router-dom";
import { useToaster } from "rsuite";
import Toast from "../Common/Toast";

export default function AddAchievement() {
  const history = useHistory();
  const { search } = useLocation();
  const toaster = useToaster();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const state = useSelector((state) => state.achievementsForm);

  const { currentRoleIdx, roles, achievements } = state;
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBoosters = async () => {
    if (state?.boosters?.length > 0) return;
    try {
      const {
        data: { data: boosters },
      } = await getBoosters();
      dispatch(setBoosters(boosters));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProject = async (projectId) => {
    try {
      const {
        data: { data: response },
      } = await findProjectById(projectId);
      const responseCards = response?.cards?.cards;

      const uniqueRoleIds = [
        ...new Set(responseCards?.map((card) => card.role_id)),
      ];
      const result = responseCards.reduce((acc, card) => {
        const { role_id, construct_id, completedOn } = card;

        if (!acc[role_id]) {
          acc[role_id] = [];
        }

        acc[role_id].push({
          construct_id,
          timeOfCompletion: completedOn,
        });

        return acc;
      }, {});

      dispatch(
        setExisting({
          projectName: response?.project?.name,
          aspect: response?.project?.details?.aspect,
          roles: uniqueRoleIds,
          achievements: result,
          moreContext: {
            category: response?.project?.details?.category,
            course: response?.project?.details?.courseName,
            company: response?.project?.details?.companyName,
            description: response?.project?.details?.companyName,
            boosters: response?.project?.details?.boosters,
          },
        })
      );
    } catch (error) {
      console.error(error);
      toaster.push(<Toast />, {
        placement: "topEnd",
      });
    }
  };

  useEffect(() => {
    if (searchParams.get("projectId") && searchParams.get("projectId") !== "") {
      fetchProject(searchParams.get("projectId"));
    }
    fetchBoosters();
  }, []);

  const handleNext = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
    if (step === 2) {
      dispatch(setValue({ key: "currentRoleIdx", value: -1 }));
    } else if (step === 3) {
      dispatch(setValue({ key: "currentRoleIdx", value: 0 }));
    } else if (step === 4) {
      if (currentRoleIdx < roles.length - 1) {
        dispatch(
          setValue({ key: "currentRoleIdx", value: currentRoleIdx + 1 })
        );
        return;
      }
      setIsOpen(true);
      return;
    } else if (step === 5) {
      nextStep();
      return;
    } else if (step === 6) {
      addAchievements();
      return;
    }
    nextStep();
  };

  const addAchievements = async () => {
    try {
      setIsLoading(true);
      let cards = [];
      Object.entries(achievements).forEach(([roleId, cardsArray]) => {
        cardsArray.forEach((card) => {
          cards.push({
            role_id: roleId,
            construct_id: card.construct_id,
            completedOn: card.timeOfCompletion,
          });
        });
      });

      let body = {
        project: {
          id: searchParams.get("projectId") ?? null,
          name: state?.projectName,
          type: "personal",
          details: {
            aspect: state?.aspect,
            category: state.moreContext.category,
            courseName: state.moreContext.course ?? "",
            companyName: state.moreContext.company ?? "",
            description: state.moreContext.description ?? "",
          },
        },
        boosters: state.moreContext.boosters,
        cards,
      };
      const {
        data: { data: response },
      } = await createAchievementCards(body);
      history.push(`/achievements/projects/${response.project._id}`);
    } catch (e) {
      console.log(e);
      toaster.push(<Toast />, {
        placement: "topEnd",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => setStep((prev) => prev + 1);

  const handleBack = () => {
    window.scroll({
      top: 0,
      left: 0,
    });
    if (step === 1) {
      history.goBack();
      return;
    }
    if (step === 3) {
      dispatch(setValue({ key: "currentRoleIdx", value: -1 }));
    }
    if (step === 4) {
      if (currentRoleIdx > 0) {
        dispatch(
          setValue({ key: "currentRoleIdx", value: currentRoleIdx - 1 })
        );
        return;
      }
    }

    setStep((prev) => prev - 1);
  };

  const isStepValidMemoized = useMemo(() => {
    return isStepValid(state, step, currentRoleIdx);
  }, [state, step, currentRoleIdx]);

  let component;
  switch (step) {
    case 1:
      component = <StepOne />;
      break;
    case 2:
      component = <StepTwo />;
      break;
    case 3:
      component = <StepThree />;
      break;
    case 4:
      component = <StepFour />;
      break;
    case 5:
      component = <StepFive />;
      break;
    case 6:
      component = <StepSix setStep={setStep} />;
      break;
    default:
      component = <StepOne />;
  }

  return (
    <div
      className={`flex flex-col w-full h-full min-h-[calc(100vh-6rem)] max-w-[1700px] mx-auto justify-between items-center font-lato relative ${
        step !== 6 ? "max-md:px-4" : "overflow-x-hidden"
      }`}
    >
      <ContinueModal
        isOpen={isOpen}
        onContinue={() => {
          setIsOpen(false);
          nextStep();
        }}
      />
      <LoadingModal isOpen={isLoading} />

      <div className="fixed flex w-full justify-between top-[52.5px] bg-white px-4 text-primary-neutral-500">
        <div
          onClick={handleBack}
          className="flex cursor-pointer flex-row items-center space-x-2 py-2 text-xs font-satoshi capitalize underline underline-offset-2 font-bold leading-5"
        >
          <MdArrowBack size={16} />
          <p>Back</p>
        </div>
        <button
          className="font-lato text-xs underline underline-offset-2 font-bold"
          onClick={() => history.push("/CareerFlow")}
        >
          Save & Exit
        </button>
      </div>

      <div className="mt-16 w-full">{component}</div>

      <div
        className={`flex justify-between self-stretch items-start w-full transition-all duration-300 ${
          step === 6 ? "px-4" : ""
        } ${
          isStepValidMemoized &&
          step < 5 &&
          "fixed bottom-0 left-0 bg-white p-4"
        }`}
      >
        <button
          className="flex gap-2 py-4 pl-8 pr-5 font-satoshi font-bold text-base w-full items-center justify-center cursor-pointer bg-primary-accent-700 rounded-full text-center shadow-lg text-white disabled:opacity-40"
          onClick={handleNext}
          disabled={!isStepValidMemoized}
        >
          {step === 4 && (
            <div className="flex items-center justify-center h-5 w-5 rounded-full bg-white text-xs font-bold text-primary-accent-700">
              {achievements[roles[currentRoleIdx]]?.length}
            </div>
          )}
          {step === 3 && state?.roles?.length > 0 && (
            <div className="flex items-center justify-center h-5 w-5 rounded-full bg-white text-xs font-bold text-primary-accent-700">
              {state.roles?.length}
            </div>
          )}
          {step === 6 ? (
            <>
              Done <MdDoneAll className="text-2xl" />
            </>
          ) : (
            <>
              Next <IoArrowForwardOutline className="text-2xl" />
            </>
          )}
        </button>
      </div>
      {/* <div className="absolute -right-[88px] bottom-16 rounded-full bg-white shadow-lg border border-primary-neutral-100 flex w-12 h-12 p-4 justify-center items-center">
        <FaArrowUp className="h-5 w-5" />
      </div> */}
    </div>
  );
}
