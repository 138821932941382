import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/20/solid";
import usePhaseTwo from "components/PhaseTwoForm/hooks/usePhaseTwo";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useSelector } from "react-redux";

const WebStepFour = () => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const { handleBackButtonClick, handleStepChangeButtonClick } = usePhaseTwo();

  return (
    <div className="flex justify-between px-28 py-20 gap-10 ">
      <div className=" font-manrope w-1/2 flex flex-col gap-8 ">
        <h4 className="text-3xl text-primary-blue-500  font-medium ">
          {`Well Done ${details.name}`}
        </h4>
        <div className="flex items-center gap-10">
          <h4 className="text-lg text-primary-grey-500 font-light">
            STEP 3 :
            <span className="text-primary-grey-500 font-medium ml-1 ">
              {`What I'm Good At`}
            </span>
          </h4>
          <div className="w-8 h-8 grid place-items-center rounded-full bg-primary-green-50 border-2 border-primary-green-500">
            {" "}
            <CheckIcon className="w-4" />{" "}
          </div>
        </div>

        <h4 className="text-xl text-primary-grey-500 font-light">
          STEP 4 :
          <span className="text-primary-green-500 font-medium ml-1 ">
            What I Can be Paid For
          </span>
        </h4>

        <p className="text-md font-satoshi text-primary-grey-500">
          You cannot go wrong doing the things you love. Figuring out and
          understanding what gives you happiness will help you in your efforts
          to find joy in your daily tasks.
        </p>
        <p className="text-md font-satoshi text-primary-grey-500">
          In the upcoming section, take a minute of introspection before
          answering the questions. Focus on the things that give you joy. You
          don’t necessarily have to be good at it. All that matters is that you
          love doing it!
        </p>

        <div>
          <ProfileButton
            onClick={handleStepChangeButtonClick}
            disabled={false}
            text={"Lets Go !"}
            size={"large"}
            variant={"filled"}
          />{" "}
          <p className="text-xs font-satoshi text-primary-grey-400 my-1">
            or press tab to continue
          </p>
        </div>
        <div>
          {" "}
          <ProfileButton
            onClick={handleBackButtonClick}
            text={"Back"}
            size={"large"}
            icon={<ArrowLeftIcon className="w-6" />}
            iconAlign={"left"}
          />
        </div>
      </div>
      <div className="flex w-1/2 flex-col items-end justify-center ">
        <img
          className="w-full max-w-[450px] object-contain"
          src="https://www.gynprog.com.br/wp-content/uploads/2017/06/wood-blog-placeholder.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default WebStepFour;
