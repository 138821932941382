import { ReactComponent as Spinner } from "assets/svg/spinner.svg";

export default function LoadingModal({ isOpen = true }) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="max-w-[350px] sm:max-w-[460px]  w-full rounded-[24px] bg-white p-12 flex flex-col items-center gap-6 ">
        <Spinner className="h-16 w-16 animate-spin" />
        <p className="text-primary-neutral-800 text-lg text-center font-satoshi font-bold">
          Crafting Your Achievement Cards...
        </p>
      </div>
    </div>
  );
}
