import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showNavigation } from "redux/profile-form";

const AchievementIntroForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showNavigation());
  }, []);
  return (
    <div className=" font-manrope h-full flex flex-col  p-4 md:px-10 gap-4 pt-24  ">
      <h4 className="text-xl text-primary-grey-500 max-w-sm font-medium ">
        Now, let’s talk about what you’re proud of
      </h4>
      <h4 className="text-lg text-primary-grey-500 font-light">
        Talk about the things that have given you a
        <span className="text-primary-purple-700 font-medium ml-2 ">
          sense of achievement.
        </span>{" "}
      </h4>

      <div className="grid place-items-center gap-4 my-4">
        <img
          className="w-72 h-72 object-contain "
          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
          alt=""
        />
        <p className="text-primary-grey-400 text-md text-center">
          The purpose here is to track and measure your growth through your
          accomplishments.
        </p>
      </div>
    </div>
  );
};

export default AchievementIntroForm;
