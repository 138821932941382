import React from "react";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

// Config
import logError from "helpers/utils/common/logError";
import { LIFE_ENDPOINT } from "config";

/**
 * Components
 * @param { Boolean } dashboard - To check if used in Dashboard or not
 */
function GoogleSignInButton() {
  const dispatch = useDispatch();

  const googleSignIn = () => {
    // try {
    //   let redirecturl = `${LIFE_ENDPOINT}auth/google`;
    //   window.location.replace(redirecturl);
    // } catch (e) {
    //   logError("Gmail mail Auth Redirect", e);
    //   dispatch(
    //     showToast({
    //       message: e?.response?.data?.message,
    //       type: "error",
    //     })
    //   );
    // }
  };

  return (
    <>
      <div className="pt-5">
        <input
          type="submit"
          id="send-otp-button"
          value="Connect with Google"
          className="rounded-2xl login-cta px-8 py-4 cursor-pointer text-primary-gray-100 font-inter text-sm font-bold"
          onClick={() => {
            googleSignIn();
          }}
        />
      </div>
    </>
  );
}

export default GoogleSignInButton;
