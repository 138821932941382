import { MdNorthEast } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateStep } from "redux/profile-form";
import { changeSection } from "redux/typeform";

const CareerCard = ({
  title,
  time,
  image,
  path,
  isLocked,
  isCompleted,
  isActive,
  form,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="bg-white min-w-[296px] max-w-[480px] w-full h-min max-h-[140px] relative shadow-md rounded-2xl p-5 space-x-4 flex justify-between flex-row">
      <div className={`gap-4 flex flex-col`}>
        <div className="space-y-1 flex flex-col max-w-[160px] xs:max-w-[200px]">
          <p
            className={`font-satoshi font-bold text-[14px] tracking-[0.3px] ${
              isCompleted
                ? "text-primary-neutral-400"
                : "text-primary-neutral-800"
            }`}
          >
            {title}
          </p>
          <p className="font-lato font-bold text-[10px] text-[#9C9A96]">
            {time}
          </p>
        </div>
        <div
          onClick={() => {
            // if (path == "/AI5") dispatch(changeSection({ ...form }));
            // if (path == "/phaseOne") dispatch(updateStep(form?.step));
            history.push(path);
          }}
        >
          {isLocked && (
            <button className="flex text-[#363430] justify-center items-center flex-row gap-x-1">
              <p className="font-lato font-bold text-xs underline underline-offset-2">
                Details
              </p>
              <MdNorthEast className="h-3 w-3" />
            </button>
          )}{" "}
          {isCompleted && (
            <button className="flex  text-[#ED4C41] justify-center items-center flex-row space-x-2">
              <p className="font-lato font-bold text-xs underline underline-offset-2">
                Details
              </p>
            </button>
          )}
          {isActive && (
            <button className="flex border border-[#FF7E6E] bg-[#FFE9E5] text-[#ED4C41] justify-center px-4 py-[6px] rounded-full items-center flex-row space-x-2">
              <p className="font-lato font-bold text-xs">Start Now</p>
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img src={image} alt="" className="w-20 h-20 object-contain" />
      </div>
      {isLocked && (
        <div className="w-6 h-6 rounded-full absolute top-2 right-2 bg-primary-neutral-800 flex flex-row items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M9 4H8.5V3C8.5 1.62 7.38 0.5 6 0.5C4.62 0.5 3.5 1.62 3.5 3V4H3C2.45 4 2 4.45 2 5V10C2 10.55 2.45 11 3 11H9C9.55 11 10 10.55 10 10V5C10 4.45 9.55 4 9 4ZM6 8.5C5.45 8.5 5 8.05 5 7.5C5 6.95 5.45 6.5 6 6.5C6.55 6.5 7 6.95 7 7.5C7 8.05 6.55 8.5 6 8.5ZM7.55 4H4.45V3C4.45 2.145 5.145 1.45 6 1.45C6.855 1.45 7.55 2.145 7.55 3V4Z"
              fill="#E7E6E5"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
export default CareerCard;
