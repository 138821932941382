import { Drawer } from "@mui/material";

import React from "react";
import { MdClose } from "react-icons/md";

import { SiTarget } from "react-icons/si";

export default function CrewDrawer({ isOpen, closeModal }) {
  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={500}
      PaperProps={{
        style: {
          width: window.innerWidth < 1024 ? "100%" : "100vw",
          height: window.innerWidth < 1024 ? "" : "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          boxShadow: "none",
        },
      }}
    >
      <div className="flex flex-row items-end justify-between lg:justify-end h-[100vh]">
        <div className="h-[85%] justify-center w-[100vw] lg:w-[720px] lg:h-screen relative rounded-t-[32px] lg:rounded-t-none">
          <div className="z-40 flex cursor-pointer flex-row items-center justify-center absolute -top-16 lg:top-[50%] lg:-left-20 left-0 right-0 w-full lg:max-w-max">
            <div
              onClick={() => {
                closeModal();
              }}
              className="text-white flex flex-row items-center justify-center h-10 w-10 bg-black rounded-full"
            >
              <MdClose className="w-[14px] h-[14px] text-[white]" />
            </div>
          </div>
          <div className="md:max-w-xl h-full lg:h-screen rounded-t-[32px] lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150  ">
            <div
              style={{
                boxShadow:
                  "0 -4px 6px -4px rgba(0, 0, 0, 0.1), 0 -8px 15px -3px rgba(0, 0, 0, 0.1)",
              }}
              className="flex flex-col p-6 gap-6 justify-between border-b-[1px] border-[#CFCDC9]  font-medium rounded-t-[32px] "
            >
              {/* <div className="h-[6px] w-[64px] rounded-full bg-primary-neutral-800 mx-auto"></div> */}
              <p className="font-satoshi   flex font-bold text-base text-[#363430]">
                More About Your CREW
              </p>
            </div>
            <div className="py-10 px-4 flex flex-col gap-10 flex-1">
              <div className="w-[280px] h-[130px] mx-auto">
                <img
                  src="/assets/images/yoshiCrew.png"
                  className="h-full w-full object-contain"
                />
              </div>
              <p className="text-primary-neutral-400 font-satoshi text-sm font-normal leading-[21px] tracking-[0.35px]">
                Some description of a CREW to come here in 3-4 lines..... lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
              <div className="py-4 flex flex-col gap-8">
                <h3 className="text-center font-bold font-lato text-lg">
                  Other Colleges in your CREW
                </h3>
                <div className="flex justify-center items-start gap-4 flex-wrap text-sm font-satoshi font-normal">
                  <div className="flex items-center px-4 py-2.5 rounded-full border border-primary-neutral-200">
                    College Name
                  </div>
                  <div className="flex items-center px-4 py-2.5 rounded-full border border-primary-neutral-200">
                    College Name
                  </div>
                  <div className="flex items-center px-4 py-2.5 rounded-full border border-primary-neutral-200">
                    College Name
                  </div>
                  <div className="flex items-center px-4 py-2.5 rounded-full border border-primary-neutral-200">
                    College Name
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
