import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { hideNavigation, updateStep } from "redux/profile-form";
import LockIcon from "../../../MobileForm/components/JourneyForm/assets/lock.svg";
import ScrollToTopButton from "components/ProfileForm/ui-components/ScrollToTopButton";

const WebJourneyForm = () => {
  const { step, details } = useSelector((state) => state.profileForm.phaseOne);
  const [ref, isInView] = useInView({
    triggerOnce: true,
    threshold: 0.75,
  });
  const intersectionRef = useRef(null);
  const [showUpButton, setShowUpButton] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideNavigation());
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowUpButton(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) observer.unobserve(intersectionRef.current);
    };
  }, [intersectionRef]);
  return (
    <div className=" font-manrope h-full flex flex-col gap-14 py-8 px-28 pb-20  ">
      <div className="flex flex-col gap-4">
        <h4 className="text-3xl text-primary-blue-400  font-medium ">
          Welcome aboard Caarya Me, {details.name}
        </h4>
        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light ">
          We will guide you in your pursuit for a purposeful career and help you
          build adaptable and resilient identities. To get started on this
          journey, you’ll first need a{" "}
          <span className="text-primary-blue-500 font-bold">Me Profile</span>
        </p>
      </div>
      <div className="flex flex-col gap-4 ">
        <h5 className="text-lg text-primary-grey-800 font-manrope   font-medium ">
          How would this help?
        </h5>
        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light ">
          We’ll help you get closer to finding a purposeful career by helping
          you self reflect, measure your growth, and express yourselves.
        </p>

        <img
          className="w-full h-[480px] object-contain mx-auto "
          src=" https://pbs.twimg.com/media/E1ISt3qWYAcTSiX.jpg:large"
          alt=""
        />

        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light ">
          In this phase of building your Me Profile, you would go through 5
          steps, each one carefully chosen to help bring you closer to
          identifying yourself, highlighting your own unique strengths and
          capabilities and express yourself to peers and potential employers.
          <br />
          <br />
          <br />
          We would recommend you to do this when you are in a calm and
          uninterrupted environment. Take out approximately 10 minutes where you
          can be free from any disturbances and can be in a mental space where
          you can reflect on yourself.
        </p>
      </div>

      <div className="flex flex-col  ">
        <div ref={intersectionRef} className="flex  gap-2 ">
          <div className="flex flex-col gap-2 w-[250px]">
            {" "}
            <h3 className="text-primary-blue-300 font-medium text-xl font-manrope">
              Self Reflection
            </h3>
            <p className="text-sm font-satoshi text-primary-grey-500 tracking-wide font-light ">
              To help you understand yourself. Identifying who you are, how you
              function and how that impacts your choices and your career.
            </p>
          </div>
          <div className="pl-28 w-full">
            <div className="my-2 grid grid-cols-3 gap-2 ">
              <div className="flex flex-col gap-2">
                <img
                  className="w-full h-[140px] object-contain "
                  src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                  alt=""
                />
                <p className="text-center text-xs font-satoshi font-semibold">
                  Natural Inclinations
                </p>
              </div>
              <div className="flex flex-col gap-2 relative">
                <img
                  className="w-full h-[140px] object-contain "
                  src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                  alt=""
                />
                <p className="text-center text-xs font-satoshi font-semibold">
                  Evolution of Career Interests
                </p>
                <img
                  className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-11 z-10 "
                  src={LockIcon}
                  alt=""
                />
              </div>
            </div>
            <div className=" border-t-2 border-r-2 border-dashed mx-4 w-full mt-16 py-4 rounded-r-full relative ">
              <div className="flex gap-[25%] items-center transform -translate-y-[40px] top-0 inset-x-0 ">
                {isInView && (
                  <>
                    <div className="absolute left-[10px]  w-16 h-16 -top-2 rounded-full -z-10 bg-primary-blue-200 animate-ping "></div>{" "}
                    <div className="absolute left-[20px]  w-10 h-10 top-1 rounded-full -z-10 bg-primary-blue-300 animate-ping "></div>{" "}
                  </>
                )}

                <ProfileButton
                  onClick={() => dispatch(updateStep(step + 1))}
                  text={"Start"}
                  size={"small"}
                  variant={"filled"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-2 ">
          <div className="flex flex-col gap-2 w-[250px]">
            {" "}
            <h3 className="text-primary-green-500 font-medium text-xl font-manrope">
              Measurement of Growth
            </h3>
            <p className="text-sm font-satoshi text-primary-grey-500 tracking-wide font-light ">
              To help you quantify your experiences, see how you’ve grown
              through them and help you showcase your unique process.
            </p>
          </div>
          <div className="pl-28 py-10 w-full rounded-l-full border-l-2 border-y-2 border-dashed mr-4">
            <div className="my-2 grid grid-cols-3 gap-2 ">
              <div className="flex flex-col gap-2  relative">
                <img
                  className="w-full h-[140px] object-contain "
                  src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                  alt=""
                />
                <p className="text-center text-xs font-satoshi font-semibold">
                  SMART Achievements
                </p>
                <img
                  className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-7 "
                  src={LockIcon}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="  border-r-2 border-dashed -mr-4 border-primary-grey-300 py-10 rounded-r-full "></div>

        <div className="flex items-center gap-2 ">
          <div className="flex flex-col gap-2 w-[250px]">
            {" "}
            <h3 className="text-primary-yellow-900 font-medium text-xl font-manrope">
              Self Expression
            </h3>
            <p className="text-sm font-satoshi text-primary-grey-500 tracking-wide font-light ">
              To help you let the world know what you believe in and stand for,
              and take control of your perception.
            </p>
          </div>
          <div
            ref={ref}
            className="pl-28 py-10 w-full rounded-l-full border-l-2 border-y-2 border-dashed relative mr-4"
          >
            <div className="my-2 grid grid-cols-3 gap-2  ">
              <div className="flex flex-col gap-2 relative">
                <img
                  className="w-full h-[140px] object-contain "
                  src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                  alt=""
                />
                <p className="text-center text-xs font-satoshi font-semibold">
                  Value Quiz
                </p>
                <img
                  className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-7 "
                  src={LockIcon}
                  alt=""
                />
              </div>

              <div className="flex flex-col gap-2 relative">
                <img
                  className="w-full h-[140px] object-contain "
                  src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                  alt=""
                />
                <p className="text-center text-xs font-satoshi font-semibold">
                  SDGs
                </p>
                <img
                  className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-7 "
                  src={LockIcon}
                  alt=""
                />
              </div>
            </div>

            <div className="flex justify-around gap-12 pl-28 transform absolute bottom-0 inset-x-0 z-20 translate-y-1/2"></div>
          </div>
        </div>
      </div>
      {showUpButton && <ScrollToTopButton />}
    </div>
  );
};

export default WebJourneyForm;
