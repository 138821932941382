import { configureStore } from "@reduxjs/toolkit";

import navigationReducer from "./navigation";
import toasterReducer from "./toaster";
import userReducer from "./user";
import squadReducer from "./squad";
import eventsReducer from "./events";
import goalsReducer from "./goal";
import journalReducer from "./journals";
import projectReducer from "./projects";
import lifeReducer from "./life";
import achievementsForm from "./achievementsForm";
import rolesReducer from "./roles";
import typeFormReducer from "./typeform";
import dropdownReducer from "./dropdown";
import profileFormReducer from "./profile-form";

const store = configureStore({
  reducer: {
    sideNav: navigationReducer,
    toaster: toasterReducer,
    user: userReducer,
    squad: squadReducer,
    goals: goalsReducer,
    journals: journalReducer,
    projects: projectReducer,
    events: eventsReducer,
    life: lifeReducer,
    achievementsForm,
    roles: rolesReducer,
    typeForm: typeFormReducer,
    dropdown: dropdownReducer,
    profileForm: profileFormReducer,
  },
});

export default store;
