import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleTextArea from "components/Common/Inputs/SimpleTextArea";
import { MdClose, MdDoneAll } from "react-icons/md";
import { SiTarget } from "react-icons/si";
import { ReactComponent as Logo } from "../../../assets/FocusArea/fi_3907524.svg";
import { ReactComponent as Emoji1 } from "../../../assets/FocusArea/Emoji1.svg";
import { ReactComponent as Emoji2 } from "../../../assets/FocusArea/Emoji2.svg";
import { ReactComponent as Emoji3 } from "../../../assets/FocusArea/Emoji3.svg";
import { ReactComponent as Emoji4 } from "../../../assets/FocusArea/Emoji4.svg";
import { ReactComponent as Emoji5 } from "../../../assets/FocusArea/Emoji5.svg";
import { TypeAnimation } from "react-type-animation";
import { useSelector } from "react-redux";
import { RiPenNibFill } from "react-icons/ri";

const TypingAnimation = ({ text }) => {
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    typeLetterByLetter(text);
  }, [text]); // This effect will run when the `text` prop changes.

  const typeLetterByLetter = (textToType, index = 0) => {
    if (index < textToType.length) {
      setTypedText((typedText) => typedText + textToType[index]);
      setTimeout(() => typeLetterByLetter(textToType, index + 1), 70); // Adjust speed as necessary
    }
  };

  return (
    <div
      style={{ fontSize: "1.5rem", fontFamily: "sans-serif", color: "white" }}
    >
      {typedText}
    </div>
  );
};

function ClockOutModal({
  isOpen,
  closeModal,
  onUpdate,
  goal,
  manualClockOut,
  clockIn,
  duration,
}) {
  const user = useSelector((state) => state?.user?.user);
  const signatureContent = `${user?.name} ${user?.last_name ?? ""} `;

  const [data, setData] = useState({});
  const [selectedLine, setSelectedLine] = useState(3);
  const [isSigningOff, setIsSigningOff] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [signComplete, setSignComplete] = useState(false);

  useEffect(() => {
    if (signComplete) {
      onUpdate({
        description: data?.description,
        mood: { emoji: selectedLine },
        title: data?.description,
      });
    }
  }, [signComplete]);

  useEffect(() => {
    const clockInTime = new Date(clockIn).getTime();
    const totalDurationMs = duration * 60 * 1000;

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsed = currentTime - clockInTime;

      if (elapsed < totalDurationMs) {
        setElapsedTime(elapsed);
      } else {
        setElapsedTime(totalDurationMs);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [clockIn, duration]);

  const totalDurationMs = duration * 60 * 1000;

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const handleLineClick = (lineNumber) => {
    setSelectedLine(lineNumber);
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => {
        setIsSigningOff(false);
        setSignComplete(false);
        closeModal();
      }}
      transitionDuration={500}
      PaperProps={{
        style: {
          width: window.innerWidth < 1024 ? "100%" : "100vw",
          height: window.innerWidth < 1024 ? "100vh" : "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          boxShadow: "none",
        },
      }}
    >
      <div className="h-screen flex flex-row items-end justify-between lg:justify-end">
        <div className="  justify-center h-[70vh] w-[100vh] lg:w-[720px] lg:h-screen relative rounded-t-[32px] lg:rounded-t-none">
          <div className="z-40 flex cursor-pointer flex-row items-center justify-center absolute -top-16 lg:top-[50%] lg:-left-20 left-0 right-0 w-full lg:max-w-max">
            <div
              onClick={() => {
                setIsSigningOff(false);
                setSignComplete(false);
                closeModal();
              }}
              className="text-white flex flex-row items-center justify-center h-10 w-10 bg-black rounded-full"
            >
              <MdClose className="w-[14px] h-[14px] text-[white]" />
            </div>
          </div>
          <div className="md:max-w-xl lg:h-screen   rounded-t-[32px] lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150  ">
            <div
              style={{
                boxShadow:
                  "0 -4px 6px -4px rgba(0, 0, 0, 0.1), 0 -8px 15px -3px rgba(0, 0, 0, 0.1)",
              }}
              className="flex flex-col items-center p-6 space-y-2 justify-center border-b-[1px] border-[#CFCDC9]  font-medium rounded-t-[32px] "
            >
              <div className="flex flex-row justify-center items-center space-x-2 text-lg">
                {manualClockOut ? (
                  "Goal Automatically Clocked Out"
                ) : (
                  <div className="flex flex-row justify-center items-center font-satoshi font-bold text-base  space-x-4">
                    <Logo />
                    <p>Clocking Out for Session</p>
                  </div>
                )}
              </div>
            </div>
            <div className="p-8 space-y-[56px] flex flex-col">
              <div className=" flex flex-col space-y-4">
                <div className="w-full px-4 flex space-x-2 items-center py-[14px] h-[52px]  rounded-2xl border-[1px]  border-[#E7E6E5] bg-white   flex      ">
                  <div>
                    <SiTarget className="text-primary-red-500  w-[20px] h-[20px]" />
                  </div>
                  <div className="font-lato font-semibold text-sm text-black">
                    {goal?.title}
                  </div>
                </div>
                <div className="flex flex-row justify-center items-end space-x-2">
                  <div>
                    <p className="font-lato font-bold text-base text-[black]">
                      {formatTime(elapsedTime)}
                    </p>
                  </div>
                  <div>
                    <p className="font-lato font-normal text-[#ED4C41] text-base">
                      {formatTime(totalDurationMs)[0] +
                        formatTime(totalDurationMs)[1] +
                        "h" +
                        formatTime(totalDurationMs)[3] +
                        formatTime(totalDurationMs)[4] +
                        "m"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full space-y-3 ">
                <div>
                  <p className="text-[#363430] font-satoshi text-base font-bold">
                    What did work on in this session?
                  </p>
                </div>
                <div>
                  <SimpleTextArea
                    srOnly
                    placeholder="Start typing..."
                    field="description"
                    details={data}
                    setDetails={setData}
                    className
                  />
                </div>
              </div>
              <div className="space-y-[32px] flex flex-col">
                <div>
                  <p className="font-satoshi text-base font-bold">
                    How was your session?
                  </p>
                </div>
                <div className="py-2 space-y-6 flex flex-col">
                  <div className="flex justify-center ">
                    {selectedLine === 0 && <Emoji1 />}
                    {selectedLine === 1 && <Emoji2 />}
                    {selectedLine === 2 && <Emoji3 />}
                    {selectedLine === 3 && <Emoji4 />}
                    {selectedLine === 4 && <Emoji5 />}
                  </div>
                  <input
                    type="range"
                    className="appearance-none bg-transparent range-input"
                    value={selectedLine}
                    style={{
                      background: `linear-gradient(to right, #FF7E6E ${
                        selectedLine * 25
                      }%, #ccc ${selectedLine * 25}%)`,
                    }}
                    onChange={(e) => setSelectedLine(+e.target.value)}
                    min={0}
                    max={4}
                    step="1"
                  />
                  {/* <div className="flex justify-between px-3 flex-row items-center">
                    <button
                      onClick={() => handleLineClick(1)}
                      className="bg-[#E7E6E5] w-[6px] h-[24px] rounded-[80px]"
                    ></button>
                    {selectedLine === 1 && (
                      <div className="bg-[#FFE9E5] w-[32px] rounded-full -ml-[24px] h-[32px] border-4 border-[#FF7E6E]"></div>
                    )}
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <button
                      onClick={() => handleLineClick(2)}
                      className="bg-[#E7E6E5] w-[6px] h-[24px] rounded-[80px]"
                    ></button>
                    {selectedLine === 2 && (
                      <div className="bg-[#FFE9E5] w-[32px] rounded-full -ml-[24px] h-[32px] border-4 border-[#FF7E6E]"></div>
                    )}
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <button
                      onClick={() => handleLineClick(3)}
                      className="bg-[#E7E6E5] w-[6px] h-[24px] rounded-[80px]"
                    ></button>
                    {selectedLine === 3 && (
                      <div className="bg-[#FFE9E5] w-[32px] rounded-full -ml-[24px] h-[32px] border-4 border-[#FF7E6E]"></div>
                    )}
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <button
                      onClick={() => handleLineClick(4)}
                      className="bg-[#E7E6E5] w-[6px] h-[24px] rounded-[80px]"
                    ></button>
                    {selectedLine === 4 && (
                      <div className="bg-[#FFE9E5] w-[32px] rounded-full -ml-[24px] h-[32px] border-4 border-[#FF7E6E]"></div>
                    )}
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <div className="bg-[#E7E6E5] w-[6px] h-[6px] rounded-[80px]"></div>
                    <button
                      onClick={() => handleLineClick(5)}
                      className="bg-[#E7E6E5] w-[6px] h-[24px] rounded-[80px]"
                    ></button>
                    {selectedLine === 5 && (
                      <div className="bg-[#FFE9E5] w-[32px] rounded-full -ml-[24px] h-[32px] border-4 border-[#FF7E6E]"></div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-full">
              <button
                onClick={() => setIsSigningOff(true)}
                className="w-full text-white flex flex-rox space-x-1 justify-center items-center bg-[#816FE9] px-[20px] py-[32px] disabled:bg-opacity-50"
                style={{
                  boxShadow:
                    "0 -4px 6px -4px rgba(0, 0, 0, 0.1), 0 -8px 15px -3px rgba(0, 0, 0, 0.1)",
                }}
              >
                {isSigningOff ? (
                  <>
                    <TypeAnimation
                      sequence={[
                        signatureContent,
                        10,
                        () => {
                          setSignComplete(true);
                        },
                      ]}
                      speed={180}
                      repeat={1}
                      cursor={false}
                      className="font-haviland text-3xl"
                    />
                    {signComplete && (
                      <MdDoneAll className="h-6 w-6 text-white" />
                    )}
                  </>
                ) : (
                  <p className="flex items-center gap-4 text-[16px] font-lato font-bold">
                    <RiPenNibFill className="h-6 w-6 " />
                    Sign Off
                  </p>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ClockOutModal;
