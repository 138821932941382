import { Drawer } from "@mui/material";
import { ArrowLeft, ArrowRight, Sparkle, X } from "@phosphor-icons/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function Variation({ isOpen, closeModal, task, item, list }) {
  const [variationItem, setVariationItem] = useState();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (item) {
      setVariationItem(item);
      setSelected(false);
    }
  }, [item]);
  useEffect(() => {
    if (variationItem) {
      setSelected(false);
    }
  }, [variationItem]);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={500}
      PaperProps={{
        style: {
          width: window.innerWidth < 1024 ? "100%" : "100vw",
          height: window.innerWidth < 1024 ? "100vh" : "100vh",
          background: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className="h-screen flex flex-row items-end justify-between lg:justify-end">
        <div className="variation-bg h-[75vh] w-[100vh] lg:w-[720px] lg:h-screen relative rounded-t-2xl lg:rounded-t-none">
          <div className="z-40 flex cursor-pointer flex-row items-center justify-center absolute -top-16 lg:top-[50%] lg:-left-20 left-0 right-0 w-full lg:max-w-max">
            <div
              onClick={() => {
                closeModal();
              }}
              style={{ boxShadow: "0px 0px 30px 0px #FFF" }}
              className="text-white flex flex-row items-center justify-center h-10 w-10 bg-black rounded-full"
            >
              <X size={24} />
            </div>
          </div>
          <div className=" rounded-t-2xl lg:rounded-t-none px-4 lg:px-6 py-6 lg:py-8 flex flex-col items-start lg:space-y-8 pb-5 overflow-y-auto max-h-[75vh] lg:max-h-[100vh]">
            <div className="bg-white rounded-lg p-3 lg:p-6 space-y-6 bg-opacity-60">
              <div className="flex flex-col space-y-2">
                <p
                  style={{ color: task?.bgColor }}
                  className="text-poppins text-xs  lg:text-sm font-medium leading-4"
                >
                  #{task?.title}
                </p>
                <p className="text-poppins text-xl text-black font-medium">
                  {task?.description}
                </p>
              </div>
              <div className="p-3 lg:p-4 bg-white bg-opacity-50 rounded-lg flex flex-col lg:flex-row items-stretch space-y-2 lg:space-y-0 lg:space-x-2">
                <div className="flex flex-col items-stretch space-y-2">
                  <p className="text-inter uppercase text-2xs lg:text-xs text-black font-light leading-5">
                    {variationItem?.name}
                  </p>
                  <p className="text-inter text-base lg:text-lg text-black font-semibold">
                    {variationItem?.description}
                  </p>
                </div>{" "}
                <img
                  src={variationItem?.image}
                  alt=""
                  className="w-full max-w-[160px] aspect-video object-cover mx-auto"
                />
              </div>
              {!selected && (
                <div className="flex flex-col items-stretch space-y-2 px-4">
                  <p className="text-poppins text-xs lg:text-sm text-black font-medium">
                    Submission
                  </p>
                  <p className="text-inter text-sm lg:text-base  font-light leading-7">
                    {task?.submission?.value}
                  </p>
                </div>
              )}
            </div>
            {selected ? (
              <>
                <div className="mt-8 flex flex-col items-stretch space-y-6 p-6 bg-white bg-opacity-70 rounded-lg">
                  <div className="flex flex-col space-y-2 text-black">
                    {React.cloneElement(task?.submission?.icon, {})}
                    <h1 className="font-inter text-sm font-medium leading-5">
                      Submission
                    </h1>
                    <p className="font-inter text-lg font-light leading-7">
                      {task?.submission?.value}
                    </p>
                  </div>
                  <div className="flex flex-col space-y-2 text-black">
                    <h1 className="font-inter text-sm font-semibold leading-5 text-primary-neutral-800">
                      Please ensure the following before submitting:
                    </h1>
                    <ul className="font-inter text-sm font-light leading-5 list-disc pl-5">
                      <li className="">
                        The video is not longer than 2 Minutes
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col space-y-3 text-black">
                    <h1 className="font-inter text-sm font-semibold leading-5 text-primary-neutral-800">
                      Add link
                    </h1>
                    <input
                      placeholder="Paste google drive link here"
                      className="px-4 py-3 text-black placeholder:text-primary-neutral-500 font-inter text-sm font-light rounded-lg border border-primary-neutral-300 bg-white"
                    />
                  </div>
                </div>
                <div
                  onClick={() => {
                    setSelected(false);
                  }}
                  className="flex flex-row items-stretch justify-center w-full mt-8"
                >
                  <div className="button py-3 px-8 cursor-pointer rounded-lg text-white font-inter text-sm font-semibold leading-5">
                    Submit
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mt-8 flex flex-col items-stretch space-y-4 p-6 bg-white bg-opacity-60 rounded-lg">
                  <div className="flex flex-row items-center space-x-2 font-poppins text-primary-red text-xs lg:text-sm font-medium">
                    <Sparkle size={16} />
                    <p>A suggestion from us</p>
                  </div>
                  <p className="text-black font-inter text-xs lg:text-sm font-light leading-5">
                    {variationItem?.suggestions}
                  </p>
                </div>
                <div
                  onClick={() => {
                    setSelected(true);
                  }}
                  className="flex flex-row items-stretch justify-center w-full mt-8"
                >
                  <div className="button py-3 px-8 cursor-pointer rounded-lg text-white font-inter text-sm font-semibold leading-5">
                    Pick & Submit
                  </div>
                </div>
              </>
            )}
            <div className="lg:hidden mt-8 w-full flex flex-row items-center justify-between space-x-4">
              <div
                onClick={() => {
                  let idx = list?.findIndex(
                    (a) => a?.name == variationItem?.name
                  );
                  if (idx == 0) {
                    setVariationItem(list[list?.length - 1]);
                  } else {
                    setVariationItem(list[idx - 1]);
                  }
                }}
                className="w-8 lg:w-12 h-8 lg:h-12 border border-black cursor-pointer rounded-full flex flex-row items-center justify-center"
              >
                <ArrowLeft
                  size={window.innerWidth ? 20 : 24}
                  color="black"
                  className="cursor-pointer"
                />
              </div>
              <div
                onClick={() => {
                  let idx = list?.findIndex(
                    (a) => a?.name == variationItem?.name
                  );
                  if (idx == list?.length - 1) {
                    setVariationItem(list[0]);
                  } else {
                    setVariationItem(list[idx + 1]);
                  }
                }}
                className="w-8 lg:w-12 h-8 lg:h-12 border border-black cursor-pointer rounded-full flex flex-row items-center justify-center"
              >
                <ArrowRight
                  size={window.innerWidth ? 20 : 24}
                  color="black"
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <div className="lg:absolute lg:bottom-0 p-6 lg:right-0 lg:left-0 hidden lg:flex flex-row items-center justify-between space-x-4">
            <div
              onClick={() => {
                let idx = list?.findIndex(
                  (a) => a?.name == variationItem?.name
                );
                if (idx == 0) {
                  setVariationItem(list[list?.length - 1]);
                } else {
                  setVariationItem(list[idx - 1]);
                }
              }}
              className="w-8 lg:w-12 h-8 lg:h-12 border border-black cursor-pointer rounded-full flex flex-row items-center justify-center"
            >
              <ArrowLeft
                size={window.innerWidth ? 20 : 24}
                color="black"
                className="cursor-pointer"
              />
            </div>
            <div
              onClick={() => {
                let idx = list?.findIndex(
                  (a) => a?.name == variationItem?.name
                );
                if (idx == list?.length - 1) {
                  setVariationItem(list[0]);
                } else {
                  setVariationItem(list[idx + 1]);
                }
              }}
              className="w-8 lg:w-12 h-8 lg:h-12 border border-black cursor-pointer rounded-full flex flex-row items-center justify-center"
            >
              <ArrowRight
                size={window.innerWidth ? 20 : 24}
                color="black"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Variation;
