import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const WorkContracts = () => {
  const history = useHistory();
  return (
    <div className="relative overflow-y-auto max-h-[90vh] bg-[#fafafa]">
      <div className="z-20 bg-primary-neutral-100">
        <div className="h-[50px] fixed top-0 right-0 left-0 md:hidden bg-white" />
        <div className="flex px-4 mt-[50px] py-6 flex-col space-y-2 w-full bg-white">
          <p className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-satoshi text-base tracking-[0.64px] leading-6">
            <MdArrowBack onClick={() => history.goBack()} />
            <p>Work Contracts</p>
          </p>
        </div>
        <div className="mt-2 bg-primary-neutral-50 py-6 space-y-6">
          <div className="px-4 py-2 space-y-6">
            {["For You To Learn", "For You To Earn"]?.map((item) => {
              return <ContractList heading={item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkContracts;

function ContractList({ heading }) {
  return (
    <div className="space-y-2">
      <h1 className="px-2 text-primary-neutral-800 font-lato text-xs font-bold capitalize leading-5 tracking-[0.48px]">
        {heading}
      </h1>
      <div className="py-2 flex flex-col space-y-6">
        {[1, 2, 3, 4]?.map((item) => {
          return (
            <div className="rounded-[20px] bg-white shadow-md p-4 space-y-5">
              <div className="flex flex-row items-center space-x-4">
                <div className="rounded-lg bg-primary-neutral-300 w-10 h-10" />
                <div className="flex flex-col">
                  <h1 className="text-black font-satoshi text-sm font-bold leading-5 capitalize tracking-[0.56px]">
                    User Persona Development
                  </h1>
                  <p className="text-primary-neutral-500 font-lato text-2xs leading-4 tracking-[1px]">
                    UX Designer
                  </p>
                </div>
              </div>
              <div className="text-primary-neutral-800 font-lato text-2xs leading-4 tracking-[0.4px]">
                Description of work here .... sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id es
              </div>
              <div className="flex flex-col">
                <h1 className="text-primary-neutral-300 font-satoshi text-2xs font-bold leading-4 capitalize tracking-[0.4px]">
                  Deliverables
                </h1>
                <p className="text-primary-neutral-800 font-lato text-2xs leading-4 tracking-[0.4px]">
                  Deliverable 1 , Deliverable 2 , Deliverable 3
                </p>
              </div>
              <div className="flex flex-row items-center justify-between space-x-2">
                <div className="flex flex-row items-center space-x-2">
                  <div className="rounded-full bg-primary-neutral-300 w-[18px] h-[18px]" />
                  <p className="text-primary-neutral-500 font-lato text-2xs leading-4 tracking-[0.4px]">
                    UX Designer
                  </p>
                </div>
                <p className="text-primary-neutral-800 font-lato text-xs font-bold underline tracking-[0.48px]">
                  Details
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
