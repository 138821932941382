const options = [
  {
    value: "Strongly Disagree",
    variant: "primary-purple-500",
    type: 0,
  },
  {
    value: "Disagree",
    variant: "primary-purple-500",
    type: 1,
  },
  {
    value: "Slightly Disagree",
    variant: "primary-purple-500",
    type: 2,
  },
  {
    value: "Neutral",
    variant: "primary-grey-300",
    type: 3,
  },
  {
    value: "Slightly Agree",
    variant: "primary-yellow-500",
    type: 4,
  },
  {
    value: "Agree",
    variant: "primary-yellow-500",
    type: 5,
  },
  {
    value: "Strongly Agree",
    variant: "primary-yellow-500",
    type: 6,
  },
];

const CheckBoxParent = ({ children, variant }) => {
  const className = `flex items-center gap-2 group relative text-${variant}`;
  return <div className={className}>{children}</div>;
};

const Tooltip = ({ text, variant }) => {
  const className = `absolute text-center max-w-28 ${
    text.length > 9 ? "w-28" : ""
  } -left-6 top-16 scale-0 transition-all rounded border bg-white border-${variant} p-2 text-[10px] text-${variant} group-hover:scale-100 font-bold`;
  return <span className={className}>{text}</span>;
};

const CheckBoxLg = ({ type, id, handleChange, isChecked, tooltip = false }) => {
  const checked = isChecked(id);
  const { variant, value } = options[type];
  const className = `custom_radio w-[44px] h-[44px] md:w-[52px] md:h-[52px] cursor-pointer border-2 border-${variant} checked:bg-${variant} text-${variant}`;
  return (
    <CheckBoxParent variant={variant}>
      <input
        type="radio"
        name={id}
        checked={checked?.value === value}
        id={id + variant + type}
        onChange={() => handleChange(value)}
        className={className}
      />
      {tooltip && <Tooltip text={value} variant={variant} />}
    </CheckBoxParent>
  );
};

const CheckBoxMd = ({
  type,
  id,
  name,
  handleChange,
  isChecked,
  tooltip = false,
}) => {
  const checked = isChecked(id);
  const { variant, value } = options[type];
  const className = `custom_radio w-[36px] h-[36px] cursor-pointer border-2 border-${variant} checked:bg-${variant} text-${variant}`;
  return (
    <CheckBoxParent variant={variant}>
      <input
        type="radio"
        name={name}
        checked={checked?.value === value}
        onChange={() => handleChange(value)}
        className={className}
      />
      {tooltip && <Tooltip text={value} variant={variant} />}
    </CheckBoxParent>
  );
};

const CheckBoxSm = ({
  type,
  id,
  name,
  handleChange,
  isChecked,
  tooltip = false,
}) => {
  const checked = isChecked(id);
  const { variant, value } = options[type];
  const className = `custom_radio w-[28px] h-[28px] cursor-pointer border-2 text-${variant} border-${variant}`;
  return (
    <CheckBoxParent variant={variant}>
      <input
        type="radio"
        name={name}
        checked={checked?.value === value}
        onChange={() => handleChange(value)}
        className={className}
      />
      {tooltip && <Tooltip text={value} variant={variant} />}
    </CheckBoxParent>
  );
};

const CheckBoxCenter = ({
  type,
  id,
  name,
  handleChange,
  isChecked,
  tooltip = false,
}) => {
  const checked = isChecked(id);
  const { variant, value } = options[type];
  return (
    <CheckBoxParent variant={variant}>
      <input
        type="radio"
        name={name}
        checked={checked?.value === value}
        onChange={() => handleChange(value)}
        className={`custom_radio w-[36px] h-[20px] cursor-pointer border-2 rounded-full text-${variant} border-${variant}`}
      />
      {tooltip && <Tooltip text={value} variant={variant} />}
    </CheckBoxParent>
  );
};

export { CheckBoxLg, CheckBoxMd, CheckBoxSm, CheckBoxCenter };
