import PreFooter from "./Components/PreFooter";
import CareerGoals from "./Components/CareerGoals";
// import Intro from "./Components/Intro";
// import WorkExperience from "./Components/WorkExperience";
// import Interests from "./Components/Interests";
// import Employer from "./Components/Employer";
// import { useSelector } from "react-redux";
// import NewLifeCard from "./Components/NewLifeCard";

function PreProgramDashboard() {
  // const user = useSelector((state) => state?.user?.user);
  return (
    <div className="pt-[52.5px] lg:pt-5 z-20 bg-white">
      <div
        className=""
        style={{
          background: "linear-gradient(180deg, #FFF6F5 0%, #FAFAFA 100%)",
        }}
      >
        <CareerGoals />
      </div>
      {/* <div className="bg-[#F3F2F2] w-full h-2" />
      <WorkExperience locked={user?.onboarding?.achievements ? false : true} />
      <div className="bg-[#F3F2F2] w-full h-2" />

      <Interests />
      <div className="bg-[#F3F2F2] w-full h-2" />

      <Employer
        locked={user?.onboarding?.visibility ? false : false}
        completed={false}
      /> */}

      <PreFooter />
    </div>
  );
}

export default PreProgramDashboard;
