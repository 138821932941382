import { createSlice } from "@reduxjs/toolkit";
import { getRoles } from "config/APIs/roles";

const initialState = {
  list: [],
};

const roles = createSlice({
  name: "roles",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { updateList } = roles.actions;
export default roles.reducer;

export const fetchAllRoles = () => {
  return async (dispatch) => {
    try {
      const response = await getRoles();
      if (response.status === 200) {
        let data = response.data?.data;
        dispatch(updateList(data));
      }
    } catch (err) {
      console.log("List fetching error", err);
    }
  };
};
