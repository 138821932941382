export const dummyDataRIASEC = [
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Assembling electronic parts",
      },
      {
        id: 2,
        title: "Understand how a hair-dryer functions",
      },
      {
        id: 3,
        title: "Conduct a musical choir",
      },
      {
        id: 4,
        title: "Give career guidance to people",
      },
      {
        id: 5,
        title: "Give a presentation about a product are assigned to sell",
      },
      {
        id: 6,
        title: "Keep a record of your financial transactions",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Operate a machine at a work",
      },
      {
        id: 2,
        title: "Study ways to reduce water pollution",
      },
      {
        id: 3,
        title: "Make a film on your hometown",
      },
      {
        id: 4,
        title: "Do volunteer work at a non-profit organization",
      },
      {
        id: 5,
        title: "Convince your friends to change their minds or actions.",
      },
      {
        id: 6,
        title: "Maintain a database for your class' attendance",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title:
          "Set up and operate the wiring for the audio system at your home",
      },
      {
        id: 2,
        title: "Study and understand the movement of planets",
      },
      {
        id: 3,
        title: "Play a musical instrument",
      },
      {
        id: 4,
        title: "Teach an exercise routineto your friend",
      },
      {
        id: 5,
        title: "Manage the operations of your school canteen for a day",
      },
      {
        id: 6,
        title: "Calculate the wages of employees",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Build a wooden chair for yourself",
      },
      {
        id: 2,
        title: "Modify an equipment design to reduce sound level",
      },
      {
        id: 3,
        title: "Write a book or play",
      },
      {
        id: 4,
        title: "Supervise the activities of children at a camp",
      },
      {
        id: 5,
        title: "Negotiate a deal with a seller",
      },
      {
        id: 6,
        title: "Enter information into a database",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Work on your lawn, planting new buds and trimming the grass",
      },
      {
        id: 2,
        title: "Think of ideas to increase the efficiency of a routine task",
      },
      {
        id: 3,
        title: "Design a set for your school play",
      },
      {
        id: 4,
        title: "Help elderly people with their daily activities",
      },
      {
        id: 5,
        title: "Start your own business venture",
      },
      {
        id: 6,
        title:
          "Monitoring and controlling resources and overseeing the spending of money",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Repair your malfunctioning electric kettle",
      },
      {
        id: 2,
        title:
          "Conceptualize a way to increase the accruacy of under sea mapping",
      },
      {
        id: 3,
        title: "Act in a movie",
      },
      {
        id: 4,
        title: "Teach an elementary school class",
      },
      {
        id: 5,
        title:
          "Sell an idea to others or influence their behaviour in some other way",
      },
      {
        id: 6,
        title: "Bring structure to the functiioning of your local library",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Build a wooden cabinate for your kitchen",
      },
      {
        id: 2,
        title:
          "Conduct a research to determine the infection rate of a new disease",
      },
      {
        id: 3,
        title: "Compose or arrange music",
      },
      {
        id: 4,
        title: "Work with mentally disabled children",
      },
      {
        id: 5,
        title: "Lead a class group project ",
      },
      {
        id: 6,
        title: "Prepare financial reports",
      },
    ],
  },
  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Operate machinery on a production line",
      },
      {
        id: 2,
        title: "Write a computer programme to understand your monthly spending",
      },
      {
        id: 3,
        title: "Sketch a comic of your close friends",
      },
      {
        id: 4,
        title: "Help conduct a group therapy session",
      },
      {
        id: 5,
        title: "Develop and build teams",
      },
      {
        id: 6,
        title: "Organize your documents, tasks and budgets",
      },
    ],
  },

  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Make exact measurements and determine property boundaries",
      },
      {
        id: 2,
        title:
          "Think of multiple alternative ways that a problem can be approached",
      },
      {
        id: 3,
        title: "Decorate a wall in your bedroom",
      },
      {
        id: 4,
        title: "Give theraputic counceling to a friend",
      },
      {
        id: 5,
        title: "Organize and manage a farewell party",
      },
      {
        id: 6,
        title:
          "Keep track of the overall budget for a week long trip with your friends",
      },
    ],
  },

  {
    question: "Select the activities that you find most appealing in each set.",
    options: [
      {
        id: 1,
        title: "Set up and operate machines to make products",
      },
      {
        id: 2,
        title: "Identify the underlying principles of a new theory",
      },
      {
        id: 3,
        title: "Paint a mural",
      },
      {
        id: 4,
        title: "Teach children how to read",
      },
      {
        id: 5,
        title:
          "Communicate with people outside your college to run placement drives",
      },
      {
        id: 6,
        title: "Analyze your friend's credit history",
      },
    ],
  },
];

export const dummyDataValueAssessment = {
  question:
    "Which of these values would be the most important for you to uphold?",
  subheading:
    "Think about what you consider to be the most important out of these qualities.",
  questions: [
    {
      heading: "Self-improvement.",
      icon: "/assets/svg/PersonalDevelopment.svg",
      options: [
        "Embrace equality",
        "Take responsibility",
        "Seek feedback",
        "Learn from mistakes",
        "Be honest and transparent",
        "Set goals",
      ],
    },
    {
      heading: "Empathetic-growth.",
      icon: "/assets/svg/EmpatheticGrowth.svg",
      options: [
        "Practice self-awareness",
        "Develop empathy",
        "Listen actively",
        "Show respect for boundaries",
        "Be open to feedback",
        "Prioritize personal growth",
      ],
    },
    {
      heading: "Inclusive-community",
      icon: "/assets/svg/inclusivecommunity.svg",
      options: [
        "Embrace diversity",
        "Create a culture of inclusivity",
        "Foster collaboration",
        "Listen actively",
        "Show respect",
        "Be a role model",
      ],
      dark: "#4ACADB",
    },
    {
      heading: "Resilient-growth",
      icon: "/assets/svg/resiliantgrowth.svg",
      options: [
        "Embrace challenges",
        "Learn from mistakes",
        "Pursue knowledge",
        "Cultivate a growth mindset",
        "Take initiative",
        "Take calculated risks",
      ],
      dark: "#B07AD1",
    },
    {
      heading: "Expert-leadership",
      icon: "/assets/svg/leadership.svg",
      options: [
        "Develop expertise",
        "Lead by example",
        "Prioritize responsibility",
        "Communicate effectively",
        "Take action",
        "Continuously learn and improve",
      ],
      dark: "#FBA804",
    },
    {
      heading: "Harmonious-coexistence",
      icon: "/assets/svg/harmony.svg",
      options: [
        "Build positive relationships",
        "Communicate effectively",
        "Encourage open communication",
        "Mediate conflicts",
        "Lead by example",
        "Promote forgiveness",
      ],
      dark: "#7DD04A",
    },
    {
      heading: "Balanced-liberty",
      icon: "/assets/svg/balance.svg",
      options: [
        "Respect diversity",
        "Promote self-expression",
        "Embrace equality",
        "Create opportunities for participation",
        "Address bias & discrimination",
        "Foster a culture of accountability",
      ],
      dark: "#2BA9EF",
    },
  ],
};
