import { useState } from "react";
import { dummyDataRIASEC } from "../helper";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { ReactComponent as RiasecSvg } from "assets/svg/riasec.svg";

export default function Riasec({ page, setPage, setCompleted }) {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState({});
  const handleNext = () => {
    setQuestionNumber(questionNumber + 1);
    document.getElementById("question").scrollIntoView();
  };
  return (
    <section>
      <div
        onClick={() => {
          if (page == 1) history.goBack();
          else setPage(page - 1);
        }}
        className="flex px-4 py-6 flex-row items-center justify-between space-x-2 text-sm font-satoshi"
      >
        <MdArrowBack size={24} />
        <div className="flex text-sm gap-2 pl-4 items-center text-primary-neutral-800">
          <RiasecSvg className="h-5 w-5" />
          <span className="text-sm font-medium leading-[21px] tracking-[0.7px]">
            Natural Interest Profiling
          </span>
        </div>
        <p className="font-lato text-primary-neutral-400 font-normal">
          {questionNumber + 1}/{dummyDataRIASEC.length}
        </p>
      </div>
      <div className="h-[6px] relative bg-primary-neutral-200 w-[100vw]">
        <div
          style={{
            width: `${((questionNumber + 1) * 100) / dummyDataRIASEC.length}%`,
            background: "#1A6BE5",
          }}
          className="h-[6px] relative w-full bg-opacity-60"
        ></div>
      </div>

      <div className="py-10 px-6 flex flex-col items gap-8 h-[calc(100vh-165px)] overflow-y-auto">
        <div className="flex flex-col gap-2" id="question">
          <p className="text-primary-accent-700 font-lato font-medium leading-[24px] tracking-[0.8px]">
            Select the activities that you find most appealing in each set.
          </p>
          <p className="text-primary-neutral-500 font-satoshi font-normal text-sm text-left">
            You can select <strong>at most 2</strong> options from each set.
          </p>
        </div>

        <div className="flex flex-col gap-6 items-start">
          {dummyDataRIASEC[questionNumber].options.map((opt) => {
            return (
              <div
                key={opt.id}
                onClick={() => {
                  const updatedAnswers = answers ? { ...answers } : {};
                  if (!updatedAnswers[questionNumber])
                    updatedAnswers[questionNumber] = [];

                  if (updatedAnswers[questionNumber].includes(opt.id)) {
                    updatedAnswers[questionNumber] = updatedAnswers[
                      questionNumber
                    ].filter((id) => id !== opt.id);
                  } else {
                    if (updatedAnswers[questionNumber]?.length === 2) return;
                    updatedAnswers[questionNumber].push(opt.id);
                  }

                  setAnswers(updatedAnswers);
                }}
                className={`cursor-pointer rounded-full border bg-white flex min-w-[200px] w-full py-[14px] px-5 flex-col justify-center items-center gap-2 ${
                  answers[questionNumber]?.includes(opt.id)
                    ? "border-primary-accent-700 text-primary-accent-700"
                    : "text-primary-gray-800"
                }`}
              >
                <span className="font-satoshi text-sm font-normal text-center ">
                  {opt.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex p-4 justify-between items-center border-t border-primary-neutral-100 bg-white">
        <div
          onClick={() => {
            if (questionNumber > 0) {
              setQuestionNumber(questionNumber - 1);
              document.getElementById("question").scrollIntoView();
            }
          }}
          className={`bg-transparent  flex flex-row items-center justify-center space-x-2 rounded-full px-8 py-4 cursor-pointer text-primary-gray-500 font-lato text-base font-bold leading-6`}
        >
          <MdArrowBack className="h-5 w-5" />
          <p>Back</p>
        </div>
        <div
          onClick={() =>
            questionNumber < dummyDataRIASEC.length - 1
              ? handleNext()
              : setCompleted(true)
          }
          className={`bg-black  flex flex-row items-center justify-center space-x-2 rounded-full pr-8 pl-10 py-4 cursor-pointer text-white font-lato text-base font-bold leading-6`}
        >
          <p>
            {questionNumber === dummyDataRIASEC.length - 1 ? "Submit" : "Next"}
          </p>
          <MdArrowForward className="h-5 w-5" />
        </div>
      </div>
    </section>
  );
}
