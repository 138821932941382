import QuestionComponent from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent";
import WebCheckpointForm from "components/PhaseTwoForm/LaptopForm/components/WebCheckpointForm";
import WebStepFive from "components/PhaseTwoForm/LaptopForm/components/WebStepFive";
import WebStepFour from "components/PhaseTwoForm/LaptopForm/components/WebStepFour";
import WebStepOne from "components/PhaseTwoForm/LaptopForm/components/WebStepOne";
import WebStepThree from "components/PhaseTwoForm/LaptopForm/components/WebStepThree";
import WebStepTwo from "components/PhaseTwoForm/LaptopForm/components/WebStepTwo";
import CheckpointForm from "components/PhaseTwoForm/MobileForm/components/CheckpointForm";
import StepFive from "components/PhaseTwoForm/MobileForm/components/StepFive";
import StepFour from "components/PhaseTwoForm/MobileForm/components/StepFour";
import StepOne from "components/PhaseTwoForm/MobileForm/components/StepOne";
import StepThree from "components/PhaseTwoForm/MobileForm/components/StepThree";
import StepTwo from "components/PhaseTwoForm/MobileForm/components/StepTwo";
import whatILove from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I love.svg";
import whatICare from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I care for.svg";
import whatIGood from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I good at.svg";
import whatIPaid from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I can be paid for.svg";
import whatINeeds from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/My needs from life.svg";
import whatILoveActive from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I love active.svg";
import whatICareActive from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I care for active.svg";
import whatIGoodActive from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I good at active.svg";
import whatIPaidActive from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/What I can be paid for active.svg";
import whatINeedsActive from "components/PhaseTwoForm/LaptopForm/components/QuestionComponent/assets/My needs from life active.svg";

export const FormComponents = (screen) => {
  return [
    {
      title: "Checkpoint Form ",
      component:
        screen === "mobile" ? <CheckpointForm /> : <WebCheckpointForm />,
      field: "",
    },
    {
      title: "Step One ",
      component: screen === "mobile" ? <StepOne /> : <WebStepOne />,
      field: "",
    },
    {
      title: "Step One Question ",
      component: <QuestionComponent />,
      field: "",
    },
    {
      title: "Step Two ",
      component: screen === "mobile" ? <StepTwo /> : <WebStepTwo />,
      field: "",
    },
    {
      title: "Step Two Question ",
      component: <QuestionComponent />,
      field: "",
    },
    {
      title: "Step Three ",
      component: screen === "mobile" ? <StepThree /> : <WebStepThree />,
      field: "",
    },
    {
      title: "Step Three Question ",
      component: <QuestionComponent />,
      field: "",
    },
    {
      title: "Step Four ",
      component: screen === "mobile" ? <StepFour /> : <WebStepFour />,
      field: "",
    },
    {
      title: "Step Four Question ",
      component: <QuestionComponent />,
      field: "",
    },
    {
      title: "Step Five ",
      component: screen === "mobile" ? <StepFive /> : <WebStepFive />,
      field: "",
    },
    {
      title: "Step Five Question ",
      component: <QuestionComponent />,
      field: "",
    },
  ];
};

export const quizNavigationData = [
  {
    title: "What I Love",
    icons: whatILove,
    activeIcons: whatILoveActive,
  },
  {
    title: "What I Care About",
    icons: whatICare,
    activeIcons: whatICareActive,
  },
  {
    title: "What I’m Good At",
    icons: whatIGood,
    activeIcons: whatIGoodActive,
  },
  {
    title: "What I Can be Paid For",
    icons: whatIPaid,
    activeIcons: whatIPaidActive,
  },
  {
    title: "My Needs From Life",
    icons: whatINeeds,
    activeIcons: whatINeedsActive,
  },
];

export const phaseTwoQuestionArr = [
  {
    questions: [
      {
        question:
          "Scenario1 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario2 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario3 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario4 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
    ],
  },
  {
    questions: [
      {
        question:
          "Scenario5 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario6 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario7 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario8 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
    ],
  },
  {
    questions: [
      {
        question:
          "Scenario9 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario10 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario11 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario12 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
    ],
  },
  {
    questions: [
      {
        question:
          "Scenario13 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario14 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario15 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario16 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
    ],
  },
  {
    questions: [
      {
        question:
          "Scenario17 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario18 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario19 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
      {
        question:
          "Scenario20 for question 1 lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.  minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
        options: [
          "Option1 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option2 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option3 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option4 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option5 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
          "Option6 tied to a skill lorem ipsum amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
        ],
      },
    ],
  },
];
