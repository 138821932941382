import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideNavigation, updateStep } from "redux/profile-form";

const WebAchievementIntroForm = () => {
  const { step } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideNavigation());
  }, []);

  const handleUserKeyPress = (event) => {
    const { key } = event;

    if (key === "Tab") {
      dispatch(updateStep(step + 1));
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  return (
    <div className="flex justify-between px-28 py-20 gap-10 ">
      <div className=" font-manrope w-1/2 flex flex-col gap-10 ">
        <h4 className="text-3xl text-primary-grey-500  font-medium ">
          Now, let’s talk about what you’re proud of
        </h4>
        <h4 className="text-xl text-primary-grey-500 font-light">
          Talk about the things that have given you a
          <span className="text-primary-purple-700 font-medium ml-2 ">
            sense of achievement.
          </span>
        </h4>

        <p className="text-md font-satoshi text-primary-grey-400">
          The purpose here is to track and measure your growth through your
          accomplishments.
        </p>

        <div>
          <ProfileButton
            onClick={() => dispatch(updateStep(step + 1))}
            disabled={false}
            text={"Continue"}
            size={"large"}
            variant={"filled"}
            icon={<ChevronRightIcon className="w-6" />}
            iconAlign={"right"}
          />{" "}
          <p className="text-xs font-satoshi text-primary-grey-400 my-1">
            or press tab to continue
          </p>
        </div>
        <div>
          {" "}
          <ProfileButton
            onClick={() => dispatch(updateStep(step - 1))}
            text={"Back"}
            size={"large"}
            icon={<ArrowLeftIcon className="w-6" />}
            iconAlign={"left"}
          />
        </div>
      </div>
      <div className="flex w-1/2 flex-col items-end justify-center ">
        <img
          className="w-full max-w-[450px] object-contain"
          src="https://www.gynprog.com.br/wp-content/uploads/2017/06/wood-blog-placeholder.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default WebAchievementIntroForm;
