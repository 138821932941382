import BreadCrumb from "components/Common/BreadCrumb";
import Header from "./Header";
import AppLinks from "./AppLinks";

function ToolsAndProducts() {
  return (
    <div className="pt-20 pb-10">
      <BreadCrumb
        back
        page1="Essentials"
        page2="Foundation Essentials"
        page3="Tools & Products"
      />

      <div className="pt-5 px-4 h-[80vh] overflow-y-auto">
        <div className="mb-4 w-full flex flex-col items-center justify-center space-y-16 lg:space-y-4">
          <Header
            name="Tools & Products"
            image="/assets/images/essentials/foundation/3.png"
          />
          <div className="w-full max-w-[100vw] z-20 flex flex-row items-center justify-center">
            <h4 className="text-2xl md:text-[32px] font-normal tracking-[2.4px] lg:tracking-[3.2px] lg:leading-[48px] text-black text-center font-satoshi leading-9 w-max">
              Tools & Products{" "}
            </h4>
          </div>
        </div>
        <AppLinks />
      </div>
    </div>
  );
}

export default ToolsAndProducts;
