import { FormComponents } from "helpers/constants/phaseTwoForm";
import React from "react";
import { useSelector } from "react-redux";
import FormNavigation from "./components/FormNavigation";

const renderStepComponent = (step, screen) => {
  const formComponents = FormComponents(screen);
  switch (step) {
    case step:
      return formComponents[step]
        ? formComponents[step].component
        : formComponents[0].component;
    default:
      return formComponents[0].component;
  }
};

const MobileForm = () => {
  const { step } = useSelector((state) => state.profileForm.phaseTwo);
  return (
    <div className="relative min-h-screen flex flex-col justify-between">
      {(step === 0 || step % 2 === 1) && (
        <div
          className={`flex w-full p-4 font-inter absolute top-0 left-0 z-10`}
        >
          <div className="shadow-md bg-white flex rounded-full p-1 px-3 ">
            <img
              src="/assets/images/logo/caaryaMe.svg"
              alt=""
              className="h-6 object-contain"
            />
          </div>
        </div>
      )}{" "}
      <div className="flex-1"> {renderStepComponent(step, "mobile")}</div>
      {step % 2 === 1 && (
        <div>
          <FormNavigation />
        </div>
      )}
    </div>
  );
};

export default MobileForm;
