import React, { useEffect } from "react";
import Topics from "./Topics";
import { BiSolidLeftArrow } from "react-icons/bi";
import { FiArrowLeft } from "react-icons/fi";

function Object({
  image,
  bgColor = "#fff",
  color = "#000",
  submission = {},
  title = "",
  description = "",
  topics = [],
  textBg,
  idx,
  setShow,
}) {
  return (
    <div
      // id={`evalucon-${idx}`}
      className="sticky-thc section absolute inset-0 z-50 pb-5 lg:pb-0 bg-white"
    >
      <FiArrowLeft
        style={{ color: color }}
        className="text-3xl cursor-pointer"
        onClick={() => setShow(false)}
      />
      <div className="max-w-7xl mx-auto  px-4 flex flex-col items-stretch space-y-12 lg:space-y-10">
        <div className="flex flex-col items-stretch space-y-4 lg:hidden">
          <h1
            style={{ color: color, borderColor: color }}
            id="heading-text"
            className="font-poppins text-lg fot-medium leading-8 uppercase bg-white border-l-4 pl-5"
          >
            {title}
          </h1>
          <p
            style={{ color: color }}
            className="font-poppins text-[22px] font-medium leading-9 pt-14"
          >
            {description}
          </p>
          <div className="w-full h-auto aspect-video">
            <img
              src={image}
              alt=""
              className="w-full h-auto aspect-video object-cover"
            />
          </div>
          <div
            style={{ background: "#fafafa" }}
            className="submission-bg rounded-2xl py-4 px-6 flex flex-col space-y-2 text-primary-neutral-600"
          >
            {React.cloneElement(submission?.icon, {})}
            <h1 className="font-poppins text-xs font-medium leading-5">
              Submission
            </h1>
            <p className="font-inter text-sm font-light leading-5">
              {submission?.value}
            </p>
          </div>
        </div>
        <div className="hidden lg:grid lg:grid-cols-2 gap-10">
          <div className="w-full lg:h-full flex flex-col items-stretch justify-center space-y-6">
            <div className="flex flex-col items-start space-y-4">
              <h1
                style={{ color: color, borderColor: color }}
                className="font-poppins text-xl fot-medium leading-8 uppercase  border-l-4 pl-5"
              >
                {title}
              </h1>
              <p
                style={{ color: color }}
                className="font-poppins text-[32px] font-medium leading-[48px]"
              >
                {description}
              </p>
            </div>
            <div
              style={{ background: "#fafafa" }}
              className="submission-bg rounded-2xl py-4 px-6 flex flex-col space-y-2 text-primary-neutral-600"
            >
              {React.cloneElement(submission?.icon, {})}
              <h1 className="font-poppins text-sm font-medium leading-5">
                Submission
              </h1>
              <p className="font-inter text-lg font-light leading-5">
                {submission?.value}
              </p>
            </div>
          </div>
          <div className="w-full h-auto aspect-video">
            <img
              src={image}
              alt=""
              className="w-full h-auto aspect-video object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col items-start space-y-4 lg:space-y-6">
          <h1 className=" font-poppins text-lg lg:text-xl font-medium leading-8 text-primary-neutral-800">
            Pick one of these topics:
          </h1>
          <Topics
            list={topics}
            task={{
              bgColor,
              color,
              submission,
              title,
              description,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Object;
