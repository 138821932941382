import Button from "helpers/constants/button";
import React from "react";
import { Img } from "../Img/Img";
import { Text } from "helpers/constants/Text";

const Header = () => {
  return (
    <>
      <header className="flex flex-col md:flex-row items-center justify-between px-4 md:px-20 py-2 shadow-bs1 w-full">
        <div className="flex items-center justify-start gap-2">
          <div className="bg-red-100 flex h-8 md:h-auto items-center justify-center p-[4.72px] rounded-[50%] w-8">
            <Button
              className="flex h-[22px] items-center justify-center w-[22px]"
              shape="square"
              color="red_500"
            >
              <Img
                className="h-[18px]"
                src="/assets/favicon/caaryaLogo.png"
                alt="caarya"
              />
            </Button>
          </div>
          <Text
            className="text-black-900 text-xl tracking-[0.50px]"
            size="txtPoppinsSemiBold20"
          >
            caarya
          </Text>
          <Text
            className="text-black-900 text-sm tracking-[0.35px] hidden md:inline"
            size="txtPoppinsLight14"
          >
            LIFE
          </Text>
        </div>

        <div className="flex font-manrope gap-2 h-[53px] md:h-auto items-center justify-start">
          <Button
            className="cursor-pointer font-semibold min-w-[112px] text-center text-sm"
            shape="square"
            color="red_500"
            size="md"
            variant="outline"
          >
            Your Caarya
          </Button>

          <div className="flex items-center justify-start p-4">
            <Button className="text-gray-700 text-sm" size="txtManropeLight14">
              Your CREW
            </Button>
          </div>
          <div className="flex items-center justify-start p-4">
            <Button className="text-gray-700 text-sm" size="txtManropeLight14">
              Your Community
            </Button>
          </div>
          <div className="flex items-center justify-start p-4">
            <Button className="text-gray-700 text-sm" size="txtManropeLight14">
              FYIs
            </Button>
          </div>
        </div>

        <div className="flex gap-4 items-center justify-start">
          <Button>
            <Img
              className="h-10 md:h-auto rounded-[50%] w-10"
              src="/assets/svg/navigation/ellipse.svg"
              alt="ellipse"
            />
          </Button>
          <Button>
            <Img
              className="h-8 w-8"
              src="/assets/svg/navigation/expand_more.svg"
              alt="arrowdown"
            />
          </Button>
        </div>
      </header>
      <div className="horizontal-line"> </div>
      <br />
    </>
  );
};

export { Header };
