import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { valueQuestionArr } from "components/ProfileForm/LaptopForm/components/WebValueQuizForm/helpers/constant";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "redux/profile-form";

const ValueQuizNavigation = ({ totalSteps, field, isInView }) => {
  const dispatch = useDispatch();
  const { step, details } = useSelector((state) => state.profileForm.phaseOne);

  const getDisabledState = useCallback(() => {
    if (details[field].quizData.length === valueQuestionArr.length) {
      let hasUnattempted = false;
      details[field].quizData.some((quiz) => {
        if (quiz.options.length === 0) {
          hasUnattempted = true;
        }
      });

      return hasUnattempted;
    }
    return true;
  }, [details[field]]);

  return (
    <div
      className={`bg-white z-10 fixed inset-x-0 bottom-0 transition-all duration-300 ease-in-out  ${
        isInView ? " opacity-100 translate-y-0 " : "translate-y-full opacity-0"
      }  `}
    >
      <div className="h-1 w-full bg-primary-grey-200 relative">
        <div
          style={{ width: ((step + 1) / totalSteps) * 100 + "%" }}
          className={`absolute  left-0 top-0 bottom-0 bg-primary-blue-500 transition-all duration-300 ease-in-out rounded-r-full `}
        ></div>
      </div>

      <div className="flex justify-between  gap-6 items-center p-4">
        <ProfileButton
          onClick={() => dispatch(updateStep(step - 1))}
          text={"Back"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
        />

        <ProfileButton
          onClick={() => dispatch(updateStep(step + 1))}
          disabled={getDisabledState()}
          text={"Continue"}
          size={"large"}
          variant={"filled"}
          icon={<ChevronRightIcon className="w-6" />}
          iconAlign={"right"}
        />
      </div>
    </div>
  );
};

export default ValueQuizNavigation;
