import { useDispatch, useSelector } from "react-redux";

const ProgressNavigation = () => {
  const { stepCompleted, currentSectionTotal, currentStep, currentSection } =
    useSelector((state) => state.typeForm);

  return (
    <>
      <div className="fixed bottom-[82px] lg:bottom-[86px] w-full">
        <div className={`grid gap-2 w-full grid-cols-5 bg-white`}>
          {[1, 2, 3, 4, 5]?.map((item) => {
            return (
              <div className="w-full bg-primary-gray-200 h-1">
                <div
                  style={{
                    width:
                      parseInt(currentSection?.replace("Section", "")) > item
                        ? "100%"
                        : parseInt(currentSection?.replace("Section", "")) ==
                          item
                        ? `${(currentStep / currentSectionTotal) * 100}%`
                        : "0%",
                  }}
                  className="progress-bar bg-primary-red-300 h-1"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProgressNavigation;
