import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showNavigation } from "redux/profile-form";

const ValueQuizIntro = () => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showNavigation());
  }, []);
  return (
    <div className=" font-manrope h-full flex flex-col  p-4 md:px-10 gap-4 pt-24  ">
      <h4 className="text-xl text-primary-grey-500 max-w-sm font-medium ">
        {`Let's Go ${details.name} !`}
      </h4>
      <h4 className="text-lg text-primary-grey-500 font-light">
        Let’s start this journey by talking about your
        <span className="text-primary-blue-500 font-medium ml-1 ">
          natural inclinations
        </span>
      </h4>

      <div className="grid place-items-center gap-4 my-4">
        <img
          className="w-72 h-72 object-contain "
          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
          alt=""
        />
        <p className="text-primary-grey-500 text-md ">
          In this section, think about the things you are naturally drawn
          towards, the things that fascinate you. It doesn’t matter if you’d do
          it in real life or not. This is about understanding your natural areas
          of interest
        </p>
      </div>
    </div>
  );
};

export default ValueQuizIntro;
