import { DividerTabs } from "components/Common/Tabs";
import Messages from "components/Inbox/Messages";
import React, { useState } from "react";

function Inbox() {
  const [selectedTab, setSelectedTab] = useState("received");
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className={`bg-[#fafafa] pt-[52.5px] min-h-[calc(100svh-70px)]`}>
      <div
        className="flex px-4 py-6 flex-row justify-between w-full"
        style={{
          background: "linear-gradient(180deg, #F5FBFF 0%, #FFF 100%)",
        }}
      >
        <div className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-satoshi text-base tracking-[0.64px] leading-6">
          <p className="text-primary-neutral-800 flex flex-row items-center space-x-2 font-bold font-satoshi text-base tracking-[0.64px] leading-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 8.66927C2.5 6.80243 2.5 5.86901 2.86331 5.15597C3.18289 4.52876 3.69282 4.01883 4.32003 3.69925C5.03308 3.33594 5.96649 3.33594 7.83333 3.33594H12.1667C14.0335 3.33594 14.9669 3.33594 15.68 3.69925C16.3072 4.01883 16.8171 4.52876 17.1367 5.15597C17.5 5.86901 17.5 6.80243 17.5 8.66927V9.66927C17.5 11.5361 17.5 12.4695 17.1367 13.1826C16.8171 13.8098 16.3072 14.3197 15.68 14.6393C14.9669 15.0026 14.0335 15.0026 12.1667 15.0026H6.17851C5.9575 15.0026 5.74553 15.0904 5.58926 15.2467L3.92259 16.9134C3.39762 17.4384 2.5 17.0665 2.5 16.3241V15.0026V10.8359V8.66927ZM7.5 6.66927C7.03977 6.66927 6.66667 7.04237 6.66667 7.5026C6.66667 7.96284 7.03977 8.33594 7.5 8.33594H12.5C12.9602 8.33594 13.3333 7.96284 13.3333 7.5026C13.3333 7.04237 12.9602 6.66927 12.5 6.66927H7.5ZM7.5 10.0026C7.03977 10.0026 6.66667 10.3757 6.66667 10.8359C6.66667 11.2962 7.03977 11.6693 7.5 11.6693H10C10.4602 11.6693 10.8333 11.2962 10.8333 10.8359C10.8333 10.3757 10.4602 10.0026 10 10.0026H7.5Z"
                fill="#363430"
              />
            </svg>
            <p>Inbox</p>
          </p>
        </div>
        <div className="relative">
          <button
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className="border-[#9C9A96] rounded-[56px] border-[1px] w-6 h-6 flex justify-center items-center"
            aria-label="More information"
            onClick={() => handleClick()}
          >
            <p className="font-lato font-[900] text-xs text-[#696763]">?</p>
          </button>
          {showTooltip && (
            <div className="absolute z-40 right-full top-1/2 transform -translate-y-1/4 -translate-x-4 p-2 bg-white border-[#696763] border-[1px] text-[#696763] text-xs rounded-md w-64 ">
              <p className="text-primary-neutral-400 font-satoshi text-xs tracking-[0.48px] leading-[18px]">
                Look out for messages from your own personal virtual talent
                manager, operations manager and professional growth manager{" "}
              </p>
              <div className="absolute left-full top-1/2 -translate-y-1/2 border-t-5 border-r-5 border-b-5 border-l-5 border-transparent border-l-black" />
            </div>
          )}
        </div>
      </div>{" "}
      <div className="sticky-thc bg-white z-30">
        <DividerTabs
          dark
          black
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={[
            { label: "Received", value: "received" },
            { label: "Sent", value: "sent" },
          ]}
        />
      </div>
      <Messages />
    </div>
  );
}

export default Inbox;
