import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "redux/profile-form";

const AchievementNavigation = () => {
  const { step } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col items-start bg-white mt-auto  gap-4 ">
      <button
        className="text-md font-satoshi text-primary-blue-500 font-bold text-center p-4 px-8 mx-auto"
        onClick={() => dispatch(updateStep(step + 1))}
      >
        Can’t think of anything right now
      </button>
      <ProfileButton
        onClick={() => dispatch(updateStep(step - 1))}
        text={"Back"}
        size={"large"}
        icon={<ArrowLeftIcon className="w-6" />}
        iconAlign={"left"}
      />
    </div>
  );
};

export default AchievementNavigation;
