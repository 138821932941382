import { lifeInstance } from "config";
import { getHeader } from "config";

/**
 * To get token after number verification
 */
export const loginWithFirebaseAuthToken = (token) => {
  return lifeInstance.post(`/auth/mobile/token`, { token });
};

/**
 * To verify if Phone Number exists in DB and is a Chronos user (admin)
 */
export const verifyPhoneNumberForOTP = (phone_number) => {
  return lifeInstance.post(`/auth/mobile/verify`, { phone_number });
};
export const refreshToken = () => lifeInstance.get("/auth/token", getHeader());
export const registerUser = (body) =>
  lifeInstance.post("/auth/register", body, getHeader());
