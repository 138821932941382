import { updateUserOnboard } from "config/APIs/users";
import logError from "helpers/utils/common/logError";
import React, { useState, useEffect } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { userUpdate } from "redux/user";

function Onboarding() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  // useEffect(() => {
  //   document.body.classList.add("register-page");
  //   return function cleanup() {
  //     document.body.classList.remove("register-page");
  //   };
  // });
  const [step, setStep] = useState(1);
  const [phoneNo, setPhoneNo] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedPersonalities, setSelectedPersonalities] = useState([]);
  const superPowers = [
    "Super Strength",
    "Super Speed",
    "Invisibility",
    "Flight",
    "Teleportation",
    "Telekinesis",
    "Mind Reading",
    "Super Strength",
    "Super Speed",
    "Invisibility",
    "Flight",
    "Teleportation",
    "Telekinesis",
    "Mind Reading",
    "Super Strength",
    "Super Speed",
    "Invisibility",
    "Flight",
    "Teleportation",
    "Telekinesis",
    "Mind Reading",
  ];
  const [selectedSuperPower, setSelectedSuperPower] = useState("");

  useEffect(() => {
    // setEmail(user?.email);
    // setPhoneNo(user?.phoneNumber);
    // setName(user?.firstName);
    // setLastName(user?.lastName);
  }, [user]);

  const onSave = async () => {
    try {
      let body = {
        firstName: name,
        lastName: lastName,
        phoneNumber: phoneNo,
        email: email,
        metaData: {
          ...user?.metaData,
          superPower: superPowers[selectedSuperPower],
          personalities: selectedPersonalities,
          onBoarded: true,
        },
      };

      let res = await updateUserOnboard(body);
      let data = res?.data?.data;
      data["role"] = "admin";
      data["loginType"] = "phone";
      dispatch(
        userUpdate({
          user: data,
        })
      );
      window.location.href = window.location.origin + "/";
    } catch (e) {
      logError("Onboard: ", e);
    }
  };

  return (
    <section className="bg-white relative h-screen overflow-hidden">
      <div className="hidden lg:block w-[1250px] h-[1250px] bg-[#CE551140] rounded-full absolute -bottom-1/3 transform translate-y-1/2"></div>
      <div className="container mx-auto flex flex-col lg:flex-row gap-16 justify-center items-center h-screen">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="h-16 w-16 relative">
            <img
              src={
                step == 1
                  ? "/assets/caaryaLogos/logo.png"
                  : step == 2
                  ? "/assets/caaryaLogos/profile.png"
                  : "/assets/caaryaLogos/star.png"
              }
              alt="logo"
              className="w-full h-full object-contain"
            />
            <div className="absolute -bottom-6 right-0 w-[100px] h-2 bg-[#CE5511FF] rounded-[4px]"></div>
          </div>
          <div className="flex flex-col  max-w-[496px]">
            <h1 className="font-roboto text-[35px] md:text-[40px] leading-[44px] md:leading-[50px] text-secondary-gray-300">
              {step == 1 ? (
                "Let's get to know you better!"
              ) : step == 2 ? (
                <>
                  2 more steps to go! <br />
                  You did a good job.
                </>
              ) : (
                <>
                  This one's tricky! <br /> Have some fun.
                </>
              )}
            </h1>
            <p className="text-secondary-gray-300 text-sm md:text-base">
              Lo rem ipsum, dolor sit amet consectetur adipisicing elit. Cum a
              laborum deserunt eveniet voluptatum. Rerum.
            </p>
          </div>
        </div>
        <div className="bg-white rounded-[4px] border-2 border-gray-100 pt-12 pb-14 px-14 max-w-[471px] w-full sm:w-[471px] h-[562px] z-10">
          {step === 1 ? (
            <div className="flex flex-col gap-8 w-full h-full">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="font-roboto text-[14px] leading-[24px] font-bold"
                >
                  First Name
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="eg. John Doe"
                    value={name}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();

                      setName(e.target.value);
                    }}
                    className="w-full p-0 pb-1 text-[14px] leading-[24px] border-b-2 border-0 border-gray-100 focus:outline-none focus:ring-0 focus:border-black"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="font-roboto text-[14px] leading-[24px] font-bold"
                >
                  Last Name
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="eg. John Doe"
                    value={lastName}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();

                      setLastName(e.target.value);
                    }}
                    className="w-full p-0 pb-1 text-[14px] leading-[24px] border-b-2 border-0 border-gray-100 focus:outline-none focus:ring-0 focus:border-black"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="font-roboto text-[14px] leading-[24px] font-bold"
                >
                  Phone
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="eg. 9876543210"
                    value={phoneNo}
                    onChange={(e) => {
                      e.target.value = e.target.value
                        .trimStart()
                        .replace(/[^0-9]/gi, "");
                      if (e.target.value?.length > 10) return;
                      setPhoneNo(e.target.value);
                    }}
                    className="w-full p-0 pb-1 text-[14px] leading-[24px] border-b-2 border-0 border-gray-100 focus:outline-none focus:ring-0 focus:border-black"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="font-roboto text-[14px] leading-[24px] font-bold"
                >
                  Email
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="eg. john@example.com"
                    value={email}
                    onChange={(e) => {
                      e.target.value = e.target.value.trimStart();

                      setEmail(e.target.value);
                    }}
                    className="w-full p-0 pb-1 text-[14px] leading-[24px] border-b-2 border-0 border-gray-100 focus:outline-none focus:ring-0 focus:border-black"
                  />
                </div>
              </div>

              <div className="mt-12 flex justify-end items-center">
                {/* <button className="border text-[#CE5511FF] border-[#CE5511FF] rounded-full p-4 text-2xl">
                  <LiaTimesCircle />
                </button> */}
                <button
                  className="text-white bg-[#CE5511FF] rounded-full p-4 text-2xl"
                  onClick={() => setStep((prev) => prev + 1)}
                >
                  <FiArrowRight />
                </button>
              </div>
            </div>
          ) : step === 2 ? (
            <>
              <div className="flex flex-col gap-4 w-full">
                <h3 className="font-bold text-sm">Choose your super power.</h3>
                <div className="flex flex-wrap gap-2 gap-y-3">
                  {superPowers.map((power, i) => (
                    <div
                      className={`px-2 rounded-full cursor-pointer text-sm py-1 ${
                        selectedSuperPower === i
                          ? "bg-[#CE5511FF] text-white"
                          : "bg-gray-100"
                      }`}
                      onClick={() => setSelectedSuperPower(i)}
                    >
                      {power}
                    </div>
                  ))}
                </div>
                <div className="mt-8 flex justify-between items-center">
                  <button
                    className="border text-[#CE5511FF] border-[#CE5511FF] rounded-full p-4 text-2xl"
                    onClick={() => setStep((prev) => prev - 1)}
                  >
                    <FiArrowLeft />
                  </button>
                  <button
                    className="text-white bg-[#CE5511FF] rounded-full p-4 text-2xl"
                    onClick={() => setStep((prev) => prev + 1)}
                  >
                    <FiArrowRight />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-4 w-full">
              <h3 className="font-bold text-sm">
                Choose 6 images that show your personality the best.
              </h3>
              <div className="grid grid-cols-3 gap-2">
                {[...Array(9)].map((_, i) => (
                  <div
                    className={`w-[110px] h-[114px] bg-gray-100 rounded-md ${
                      selectedPersonalities?.includes(i)
                        ? "border border-black"
                        : ""
                    }`}
                    key={i}
                    onClick={() => {
                      let arr = [...selectedPersonalities];
                      if (arr.includes(i)) {
                        arr = arr.filter((x) => x != i);
                      } else {
                        if (arr?.length == 6) return;
                        arr = [...arr, i];
                      }
                      setSelectedPersonalities(arr);
                    }}
                  >
                    <img
                      src={`/assets/images/personality-${i + 1}.png`}
                      alt="logo"
                      className="w-full h-full object-contain"
                    />
                  </div>
                ))}
              </div>
              <div className="mt-auto flex justify-between items-center">
                <button
                  className="border text-[#CE5511FF] border-[#CE5511FF] rounded-full p-4 text-2xl"
                  onClick={() => setStep((prev) => prev - 1)}
                >
                  <FiArrowLeft />
                </button>
                <button
                  className="text-white bg-[#CE5511FF] rounded-full p-4 text-2xl"
                  onClick={() => onSave()}
                >
                  <FiArrowRight />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Onboarding;
