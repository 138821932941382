import { BiUpArrowAlt } from "react-icons/bi";
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { motion, useMotionValue } from "framer-motion";
import { useScroll } from "framer-motion";
import { useEffect, useState } from "react";

const Navigation = ({ step, setStep }) => {
  const { scrollYProgress } = useScroll();

  const [x, setX] = useState(0);
  scrollYProgress.onChange((val) => {
    if (step == 0) {
      if (window.innerWidth > 1024) {
        setX(Math.min(0.205 + scrollYProgress.current / 3, 0.52));
      } else if (window.innerWidth > 540) {
        setX(Math.min(0.18 + scrollYProgress.current / 2, 0.54));
      } else {
        setX(Math.min(0.15 + scrollYProgress.current / 2, 0.56));
      }
    } else if (step == 1) {
      if (window.innerWidth > 1024) {
        setX(Math.min(0.52 + scrollYProgress.current / 3, 0.81));
      } else if (window.innerWidth > 540) {
        setX(Math.min(0.54 + scrollYProgress.current / 3, 0.86));
      } else {
        setX(Math.min(0.56 + scrollYProgress.current, 0.9));
      }
    } else if (step == 2) {
      if (window.innerWidth > 1024) {
        setX(Math.min(0.81 + scrollYProgress.current / 3, 1));
      } else if (window.innerWidth > 540) {
        setX(Math.min(0.86 + scrollYProgress.current / 3, 1));
      } else {
        setX(Math.min(0.9 + scrollYProgress.current / 3, 1));
      }
    }
  });

  const history = useHistory();
  return (
    <div className="flex flex-col gap-4 sticky w-full top-0 bg-white z-50">
      <div className="flex justify-center items-center p-[18px] gap-2 text-[#CE5511]">
        <h2
          onClick={() => {
            history.push("/auth/signIn");
          }}
          className="cursor-pointer text-[#CE5511] text-sm  font-medium underline underline-offset-4  "
        >
          Back to Login
        </h2>
        <BiUpArrowAlt />
      </div>
      <div className=" relative pb-20">
        <div className="w-full h-[2px] bg-[#F3F2F2]">
          <motion.div
            style={{
              width: `${x * 100}vw`,
              top: 0,
              left: 0,
              transformOrigin: 0,
            }}
            className="bg-[#F57D34] h-full"
          ></motion.div>
        </div>
        <div className="flex justify-evenly gap-10 absolute inset-x-0 -top-4">
          {[
            " The Go Pro Student Life",
            "Your Community, Our Rules",
            "Get To Know Us",
          ].map((tab, idx) => {
            const active = step >= idx;
            return (
              <div
                key={idx}
                onClick={() => setStep(idx)}
                className="flex flex-col gap-4 items-center cursor-pointer"
              >
                {active ? (
                  <BsCircleFill className="text-[#F57D34] max-lg:mt-1.5 text-xl lg:text-3xl" />
                ) : (
                  <BsCircle className="text-[#F3F2F2] max-lg:mt-1.5 text-xl lg:text-3xl bg-white rounded-full" />
                )}

                <h2
                  className={` ${
                    !active ? "text-[#9C9A96]" : "text-[#CE5511]"
                  } text-xs lg:text-sm  font-medium pl-2`}
                >
                  {tab}
                </h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
