import { useDispatch, useSelector } from "react-redux";
import { projectAspects } from "helpers/constants/achievementsForm";
import { setValue } from "redux/achievementsForm";
import { isStepValid } from "helpers/utils";
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
} from "react-icons/ri";

export const StepZero = () => {
  return (
    <>
      <div
        className="px-4 py-6 flex flex-col gap-8 w-full items-center justify-between mb-8"
        style={{
          flex: "1 0 0",
        }}
      >
        <img
          src="/assets/favicon/achievements-step1.png"
          alt="img"
          className="w-[220px] h-[220px] object-contain"
        />
        <div className="flex flex-col gap-2">
          <p className="text-primary-neutral-500 font-lato font-bold ">
            Step 1
          </p>
          <p className="text-black font-lato font-bold text-2xl">
            Describe Your Work
          </p>
          <p className="text-primary-neutral-500 font-satoshi font-normal text-sm">
            Small description of what will happen in this step and what they
            will have to do. Brief explanation of business functionalities
            (clans) & provisions for multiple role selection can also come here.
          </p>
        </div>
      </div>
    </>
  );
};

// Project name
export const StepOne = () => {
  const { projectName } = useSelector((state) => state.achievementsForm);
  const dispatch = useDispatch();

  const handleUpdate = (e) => {
    dispatch(setValue({ key: "projectName", value: e.target.value }));
  };

  return (
    <>
      <div className="mx-auto text-center">
        <h1 className="text-black font-bold text-2xl md:text-3xl font-satoshi mb-4 tracking-[1.2px] capitalize">
          Think of a project you worked on!
        </h1>
        <p className="font-satoshi text-sm md:text-base font-normal text-primary-neutral-500 tracking-[0.65px]">
          Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
          suscipit laboriosam, nisi ut al
        </p>
      </div>
      <div className="pt-[48px] w-full max-w-[480px] flex flex-col gap-3">
        <label
          htmlFor="projectName"
          className="text-sm font-medium font-lato text-primary-neutral-800 tracking-wider"
        >
          Project Name
        </label>
        <input
          type="text"
          id="projectName"
          placeholder="Start typing..."
          value={projectName}
          onChange={handleUpdate}
          className="p-4 w-full border-0 focus:ring-0 border-b border-b-primary-neutral-200 placeholder:text-primary-neutral-300 text-lg"
        />
      </div>
    </>
  );
};

// Aspects
export const StepTwo = () => {
  const { aspect, projectName } = useSelector(
    (state) => state.achievementsForm
  );
  const dispatch = useDispatch();
  const handleUpdate = (aspectId) => {
    if (aspect === aspectId) {
      dispatch(setValue({ key: "aspect", value: "" }));
    } else dispatch(setValue({ key: "aspect", value: aspectId }));
  };
  return (
    <>
      <div className="mx-auto text-center">
        <h1 className="text-black font-bold text-2xl md:text-3xl font-satoshi mb-4 tracking-[1.2px] capitalize">
          Which Aspect Of This Project Did You Work On?
        </h1>
        <p className="font-lato text-sm md:text-base font-normal text-primary-neutral-500 tracking-[0.65px] mb-4">
          Select the aspect that best describes your role in the project
        </p>
        <div className="mx-auto py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
          {projectName}
        </div>
      </div>
      <div className="py-20 w-full max-w-[918px] flex justify-center items-start gap-x-8 gap-y-4 flex-wrap">
        {projectAspects.options.map((asp, i) => (
          <div
            key={i}
            onClick={() => handleUpdate(asp.id)}
            className={`group flex w-full min-w-[160px] md:w-[256px] p-6 flex-col items-center gap-4 rounded-[32px] cursor-pointer border border-primary-neutral-200 hover:bg-primary-neutral-50 ${
              aspect === asp.id
                ? "!border-primary-accent-500 !bg-primary-accent-50 hover:!border-primary-accent-700"
                : ""
            }`}
          >
            {aspect === asp.id ? asp.selectedIcon : asp.icon}
            <div className="text-center">
              <p className="text-primary-neutral-800 font-lato font-semibold text-lg">
                {asp.title}
              </p>
              <p className="text-primary-neutral-500 font-lato font-normal text-xs leading-5">
                {asp.subtitle}
              </p>
            </div>
          </div>
        ))}
        <p className="font-lato text-center font-normal leading-5 text-xs md:text-sm text-primary-neutral-500 mt-12">
          <span className="text-primary-accent-700 font-semibold">NOTE:</span>{" "}
          If you worked on multiple aspects of this project, add a separate
          achievement for each
        </p>
      </div>
    </>
  );
};

// Roles
export const StepThree = () => {
  const state = useSelector((state) => state.achievementsForm);
  const { aspect, projectName, roles: storeRoles, currentRoleIdx } = state;
  const availableRoles = useSelector((state) => state?.roles?.list);
  const dispatch = useDispatch();
  const handleUpdate = (r) => {
    const newRoles = storeRoles.includes(r)
      ? storeRoles.filter((role) => role !== r)
      : [...storeRoles, r];
    dispatch(setValue({ key: "roles", value: newRoles }));
  };
  const isValid = isStepValid(state, 3, currentRoleIdx);
  return (
    <>
      <div className="bg-white w-full">
        <div className="mx-auto text-center">
          <h1 className="text-black font-bold text-3xl font-satoshi mb-4 tracking-[1.2px] capitalize">
            Which Role Did You Undertake?
          </h1>
          <p className="font-lato font-normal text-sm md:text-base text-primary-neutral-500 tracking-[0.65px] mb-4">
            Select the role that best describes your contribution to the project
          </p>
          <div className="flex items-center justify-center gap-4">
            <div className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
              {projectName}
            </div>
            <div className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
              {projectAspects.options.find((a) => a.id === aspect)?.title}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`pt-10 ${
          isValid ? "pb-20" : "pb-10"
        } w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-7xl place-items-center gap-8 `}
      >
        {availableRoles?.map((r, i) => {
          if (r?.aspect_id === aspect)
            return (
              <div
                key={i}
                onClick={() => handleUpdate(r._id)}
                className={`group flex min-w-[280px] w-full p-6 flex-col items-center gap-4 rounded-[24px] cursor-pointer border border-primary-neutral-200 hover:bg-primary-neutral-50 ${
                  storeRoles.includes(r._id)
                    ? "!border-primary-accent-500 !bg-primary-accent-50 hover:!border-primary-accent-700"
                    : ""
                }`}
              >
                <div className="w-full">
                  <p className="text-primary-neutral-800 font-lato font-semibold text-base flex items-start justify-between">
                    {r?.name}
                    {storeRoles.includes(r._id) ? (
                      <RiCheckboxCircleLine
                        className="text-primary-accent-700 rounded-full"
                        size={20}
                      />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        className="text-primary-neutral-200 rounded-full"
                        size={20}
                      />
                    )}
                  </p>
                  <p className="text-primary-neutral-500 font-lato font-normal text-xs leading-5 mt-2">
                    {r?.description}
                  </p>
                </div>
              </div>
            );
        })}
      </div>
    </>
  );
};
