import React from "react";
import { PiGraduationCapFill } from "react-icons/pi";
import { AiFillCheckCircle, AiOutlineTool } from "react-icons/ai";
import { HiOutlinePhone, HiOutlineMail } from "react-icons/hi";
import { BsBookmark } from "react-icons/bs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FiUsers } from "react-icons/fi";
import Navbar from "components/Common/Navigation/Navbar";
import { getMyProfile } from "config/APIs/users";
import logError from "helpers/utils/common/logError";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const Tag = ({ text, icon, type = "secondary" }) => {
  const types = {
    secondary: {
      bg: "bg-gray-100",
      text: "text-gray-500",
    },
    red: {
      bg: "bg-red-50",
      text: "text-red-500",
    },
  };
  return (
    <div
      className={`flex items-center gap-1 text-sm p-1 px-2 rounded-full ${types[type]["bg"]} ${types[type]["text"]}`}
    >
      <span className="text-xs">{icon}</span>
      <span className="text-xs">{text}</span>
    </div>
  );
};

export default function ProfilePage() {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [details, setDetails] = React.useState({});
  const [copy, setCopy] = React.useState({
    linkedIn: false,
    gitHub: false,
    portfolio: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [data, setData] = React.useState([
    {
      title: "Design & Software",
      icon: <BsBookmark />,
      category: "Industry Knowledge",
      type: "secondary",
    },
    {
      title: "Research",
      icon: <AiOutlineTool />,
      category: "Tools & Tech",
      type: "red",
    },
    {
      title: "User Experience",
      icon: <AiOutlineTool />,
      category: "Tools & Tech",
      type: "red",
    },
    {
      title: "UI Design",
      icon: <BsBookmark />,
      category: "Industry Knowledge",
      type: "secondary",
    },
  ]);
  const [preferences, setPreferences] = React.useState([
    {
      title: "Can put in 10 hours per week",
    },
    {
      title: "Available to work on weekends",
    },
    {
      title: "Comfortable with Jira, Zoom, Meet",
    },
    {
      title: "Not willing to work for free",
    },
    {
      title: "Smart & Punctual",
    },
  ]);
  const [category, setCategory] = React.useState("All");
  const getDetails = async () => {
    try {
      let res = await getMyProfile();
      let data = res?.data?.data;
      setDetails(data);
    } catch (e) {
      logError("Onboard: ", e);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const copyContent = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Content copied to clipboard");
      setCopy({ ...copy, [type]: true });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const redirect = async () => {
    // try {
    //   let res = await redirectToMe();
    //   let token = res?.data?.data?.token;
    //   window.open(
    //     `https://caaryame.firebaseapp.com/phase-one/${token}`,
    //     "__blank"
    //   );
    // } catch (e) {
    //   logError("Me Token", e);
    // }
  };

  return (
    <>
      <Navbar />
      <section className="relative my-5">
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-[2fr,1fr] gap-6">
            <div className="flex flex-col gap-6">
              <div className="bg-white rounded-md border border-[#DEE1E6FF] p-3">
                <div className="flex items-center gap-8">
                  <div className="w-[100px] h-[100px]">
                    <img
                      src={
                        details?.image?.url ||
                        "https://i.pinimg.com/736x/bb/d5/eb/bbd5eb8953cff9d99ddc96063cf2b63e.jpg"
                      }
                      alt=""
                      className="w-full h-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="font-roboto text-[#CE5511FF] font-semibold text-xl">
                      {details?.firstName || details?.first_name}{" "}
                      {details?.lastName
                        ? ` ${details?.lastName}`
                        : details?.last_name
                        ? ` ${details?.last_name}`
                        : ""}
                    </h2>
                    <p className="text-sm">
                      {details?.appliedRole?.role_name ||
                        details?.designation?.role?.role_name}
                    </p>
                    <p className="text-xs flex items-center gap-1 text-gray-500 mt-1">
                      <PiGraduationCapFill /> {details?.college?.college_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-md border border-[#DEE1E6FF] p-3">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="Tabs"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#CE5511FF",
                          height: "3px",
                        },
                      }}
                    >
                      <Tab
                        label="About & Skills"
                        {...a11yProps(0)}
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: value === 0 ? "#CE5511FF" : "#aaa",
                          "&.Mui-selected": {
                            color: "#CE5511FF",
                          },
                          "&.Mui-focusVisible": {
                            color: "#CE5511FF",
                          },
                        }}
                      />
                      <Tab
                        label="Interests & Hobbies"
                        {...a11yProps(1)}
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: value === 1 ? "#CE5511FF" : "#aaa",
                          "&.Mui-selected": {
                            color: "#CE5511FF",
                          },
                          "&.Mui-focusVisible": {
                            color: "#CE5511FF",
                          },
                        }}
                      />
                      <Tab
                        label="Work Preferences"
                        {...a11yProps(2)}
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: value === 2 ? "#CE5511FF" : "#aaa",
                          "&.Mui-selected": {
                            color: "#CE5511FF",
                          },
                          "&.Mui-focusVisible": {
                            color: "#CE5511FF",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div className="mt-2">
                      <div className="m-2">
                        <h2 className="font-roboto text-black font-semibold text-lg">
                          Overview
                        </h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Vitae eligendi dolor accusantium consequatur
                          quis error reiciendis ex in iusto. Excepturi fugiat
                          expedita quae sed eos amet tenetur placeat consequatur
                          ratione. Lorem ipsum dolor, sit amet consectetur
                          adipisicing elit. Explicabo, culpa.
                        </p>
                        <div className="flex gap-8 my-6 mx-3 text-center">
                          <div className="flex flex-col text-sm">
                            <h6 className="font-bold">
                              {details?.collegeYear} Year
                            </h6>
                            <p className="text-sm text-gray-500">Year</p>
                          </div>
                          <div className="flex flex-col text-sm">
                            <h6 className="font-bold">-</h6>
                            <p className="text-sm text-gray-500">Semester</p>
                          </div>
                          <div className="flex flex-col text-sm">
                            <h6 className="font-bold">
                              {" "}
                              {details?.appliedRole?.role_name ||
                                details?.designation?.role?.role_name}
                            </h6>
                            <p className="text-sm text-gray-500">
                              Aspires to be
                            </p>
                          </div>
                          <div className="flex flex-col text-sm">
                            <h6 className="font-bold">
                              {details?.metaData?.superPower}
                            </h6>
                            <p className="text-sm text-gray-500">Super Power</p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-white rounded-md border border-[#efefef] p-4">
                        <h2 className="font-roboto text-black font-semibold text-lg">
                          This is all I know!
                        </h2>
                        <div className="my-5 flex gap-5">
                          <div
                            className={`px-6 py-1.5 text-sm rounded-md cursor-pointer ${
                              category === "All"
                                ? "bg-[#CE5511FF] text-white font-semibold"
                                : "text-black"
                            }`}
                            onClick={() => setCategory("All")}
                          >
                            All
                          </div>
                          <div
                            className={`px-6 py-1.5 text-sm rounded-md cursor-pointer ${
                              category === "Industry Knowledge"
                                ? "bg-[#CE5511FF] text-white font-semibold"
                                : "text-black"
                            }`}
                            onClick={() => setCategory("Industry Knowledge")}
                          >
                            Industry Knowledge
                          </div>
                          <div
                            className={`px-6 py-1.5 text-sm rounded-md cursor-pointer ${
                              category === "Tools & Tech"
                                ? "bg-[#CE5511FF] text-white font-semibold"
                                : "text-black"
                            }`}
                            onClick={() => setCategory("Tools & Tech")}
                          >
                            Tools & Technologies
                          </div>
                        </div>
                        <div className="flex flex-col gap-4 mt-3">
                          {data
                            .filter(
                              (item) =>
                                item.category === category || category === "All"
                            )
                            .map((item) => (
                              <div className="flex items-center gap-2">
                                <AiFillCheckCircle className="text-[#CE5511FF] text-xl" />
                                <span>{item.title}</span>
                                <Tag
                                  text={item.category}
                                  icon={item.icon}
                                  type={item.type}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="flex flex-col gap-4 mt-2">
                      <div className="mb-2">
                        <h2 className="font-roboto text-black">
                          My moodboard :)
                        </h2>
                        <div className="flex gap-4 mt-3 flex-wrap">
                          {details?.metaData?.personalities?.map((_, i) => (
                            <div
                              className={`w-[110px] h-[114px] bg-gray-100 rounded-md`}
                              key={i}
                            >
                              <img
                                src="/assets/caaryaLogos/personality-1.png"
                                alt="logo"
                                className="w-full h-full object-contain"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="mb-2">
                        <h2 className="font-roboto text-black">
                          Just some more insight :D
                        </h2>
                        <div className="flex gap-4 mt-3 flex-wrap">
                          {[...Array(6)].map((_, i) => (
                            <div className="flex gap-1 items-center bg-[#FBF5F3] px-2 py-1 rounded-full text-sm">
                              <FiUsers />
                              Socializing
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="mb-2">
                        <h2 className="font-roboto text-black">
                          Just some more insight :D
                        </h2>
                        <div className="flex gap-4 mt-3 flex-wrap">
                          {[...Array(6)].map((_, i) => (
                            <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-lg text-sm">
                              UX Research
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <div className="flex flex-col gap-4 m-2 my-3.5">
                      {preferences.map((item) => (
                        <div className="flex items-center gap-2">
                          <AiFillCheckCircle className="text-[#CE5511FF] text-xl" />
                          <span>{item.title}</span>
                        </div>
                      ))}
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <div className="bg-white rounded-md border border-[#DEE1E6FF] p-6">
                <div className="flex flex-col gap-1">
                  <h2 className="font-roboto text-black font-semibold text-lg">
                    Contact Information
                  </h2>
                  <p className="text-sm flex items-center gap-2">
                    <HiOutlinePhone />
                    +91 {details?.phone_number || details?.phoneNumber}
                  </p>
                  <p className="text-sm flex items-center gap-2">
                    <HiOutlineMail />
                    <a href="mailto:example@gmail.com">{details?.email}</a>
                  </p>
                  <h2 className="font-roboto text-black font-semibold text-lg mt-4">
                    Current Location
                  </h2>
                  <p className="text-sm">{details?.location}</p>

                  <h2 className="flex justify-between items-center font-roboto text-black font-semibold text-lg mt-4">
                    Linked Profile
                    <span
                      onClick={() => {
                        copyContent(details?.details?.linkedIn, "linkedIn");
                      }}
                      className="text-sm text-[#CE5511FF] cursor-pointer font-bold"
                    >
                      {copy?.linkedIn ? "Copied!" : "Copy Link"}
                    </span>
                  </h2>
                  <input
                    type="text"
                    className="p-2 px-3 rounded-md bg-gray-100 border-0 text-gray-300"
                    value={details?.details?.linkedIn}
                  />

                  <h2 className="flex justify-between items-center font-roboto text-black font-semibold text-lg mt-4">
                    GitHub Profile
                    <span
                      onClick={() => {
                        copyContent(details?.details?.gitHub, "gitHub");
                      }}
                      className="text-sm text-[#CE5511FF] cursor-pointer font-bold"
                    >
                      {copy?.gitHub ? "Copied!" : "Copy Link"}
                    </span>
                  </h2>
                  <input
                    type="text"
                    className="p-2 px-3 rounded-md bg-gray-100 border-0 text-gray-300"
                    value={details?.details?.gitHub}
                  />

                  <h2 className="flex justify-between items-center font-roboto text-black font-semibold text-lg mt-4">
                    Portfolio Link
                    <span
                      onClick={() => {
                        copyContent(details?.details?.portfolio, "portfolio");
                      }}
                      className="text-sm text-[#CE5511FF] cursor-pointer font-bold"
                    >
                      {copy?.portfolio ? "Copied!" : "Copy Link"}
                    </span>
                  </h2>
                  <input
                    type="text"
                    className="p-2 px-3 rounded-md bg-gray-100 border-0 text-gray-300"
                    value={details?.details?.portfolio}
                  />
                </div>
              </div>
              <div className="bg-white rounded-md border border-[#DEE1E6FF] p-3 text-center h-full">
                <img
                  src="/assets/caaryaLogos/blank.png"
                  alt=""
                  className="w-full h-full max-h-[140px]"
                />
                <button
                  onClick={async () => {
                    if (details?.mongoId) {
                      window.open(
                        `https://caaryame.firebaseapp.com/user/me-profile/${details?.mongoId}`,
                        "__blank"
                      );
                    } else {
                      redirect();
                    }
                  }}
                  className="bg-[#CE5511FF] text-white font-semibold text-sm rounded-md px-8 py-2 mt-3"
                >
                  {details?.mongo_id ? "View ME Profile" : "Create Me Profile"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
