import { Checkbox } from "@mui/material";
import React from "react";
import optionIcon from "./assets/home_work.svg";

const SingleOption = ({
  option,
  handleOptionSelection,
  isSelected = false,
}) => {
  return (
    <div
      onClick={() => handleOptionSelection(option)}
      className={` ${
        isSelected && "text-primary-blue-400"
      } flex items-center gap-4 justify-between cursor-pointer  w-full lg:border border-transparent hover:border-primary-blue-300 hover:border-opacity-40 rounded-lg pl-3 `}
    >
      <div className="flex gap-4 items-center">
        <img src={optionIcon} alt="" className="w-4 h-4 object-contain" />

        <h5 className="text-sm flex-1">{option}</h5>
      </div>

      <Checkbox
        checked={isSelected}
        onChange={() => handleOptionSelection(option)}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
};

export default SingleOption;
