export const goalFocusTime = [
  {
    label: "Nightwatch",
    time: "12:00 A.M - 08:00 A.M",
    bgColor: "#F7F7FF",
    color: "#816FE9",
    borderColor: "#A193F2",
    height: 56,
    backgroundRadiant:
      "radial-gradient(145.59% 109.53% at 0% 5.32%, #816FE9 0%, #FB6F60 100%",
    progressColor: "#B05A6A",
    svg: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M18.6766 9.88102C17.1616 10.4979 14.9491 10.0685 13.0722 8.64227C11.1778 7.20352 10.1685 5.16727 10.3797 3.53539C10.4378 3.08414 9.95095 2.75227 9.5797 3.01477C8.79595 3.56914 8.11908 4.31352 7.61783 5.23289C6.64533 7.01789 6.62033 9.14727 7.4222 10.9429C8.15845 10.211 9.25533 9.75039 10.4791 9.75039C12.2947 9.75039 13.851 10.7473 14.3272 12.166C15.4303 12.3129 16.3316 12.9266 16.7666 13.7485C17.4016 13.3473 17.9803 12.8348 18.4616 12.201C18.861 11.6754 19.1678 11.1085 19.3847 10.5191C19.541 10.0935 19.096 9.71039 18.6766 9.88102Z"
            fill="currentColor"
          />
          <path
            d="M16.584 14.84C16.584 16.0744 15.3422 17.0769 13.8128 17.0769H7.15528C5.62591 17.0769 4.38403 16.0744 4.38403 14.84C4.38403 13.6338 5.56028 12.6556 7.03778 12.6069L7.00278 12.6031C7.27716 11.2488 8.72841 10.2188 10.4797 10.2188C12.2309 10.2188 13.6865 11.2494 13.9609 12.6031C15.4203 12.6669 16.584 13.6444 16.584 14.84Z"
            fill="currentColor"
          />
          <path
            d="M16.4502 5.76187L17.3977 5.3475C17.4764 5.31312 17.4764 5.20125 17.3977 5.16688L16.4502 4.7525C16.4277 4.7425 16.4096 4.72437 16.3996 4.70187L15.9852 3.75438C15.9508 3.67563 15.8389 3.67563 15.8046 3.75438L15.3902 4.70187C15.3802 4.72437 15.3621 4.7425 15.3396 4.7525L14.3921 5.16688C14.3133 5.20125 14.3133 5.31312 14.3921 5.3475L15.3396 5.76187C15.3621 5.77187 15.3802 5.79 15.3902 5.8125L15.8046 6.76C15.8389 6.83875 15.9508 6.83875 15.9852 6.76L16.3996 5.8125C16.4089 5.78938 16.4271 5.77125 16.4502 5.76187Z"
            fill="currentColor"
          />
          <path
            d="M3.95336 9.70344L4.86523 9.30469C4.94086 9.27156 4.94086 9.16406 4.86523 9.13094L3.95336 8.73219C3.93148 8.72281 3.91398 8.70531 3.90461 8.68344L3.50586 7.77156C3.47273 7.69594 3.36523 7.69594 3.33211 7.77156L2.93336 8.68344C2.92398 8.70531 2.90648 8.72281 2.88461 8.73219L1.97273 9.13094C1.89711 9.16406 1.89711 9.27156 1.97273 9.30469L2.88461 9.70344C2.90648 9.71281 2.92398 9.73031 2.93336 9.75219L3.33211 10.6641C3.36523 10.7397 3.47273 10.7397 3.50586 10.6641L3.90461 9.75219C3.91461 9.73031 3.93211 9.71281 3.95336 9.70344Z"
            fill="currentColor"
          />
        </svg>
      </>
    ),
  },
  {
    label: "Daylight",
    time: "08:00 A.M - 04:00 P.M",
    bgColor: "#FDF7E9",
    color: "#FFBC00",
    borderColor: "#FFD844",
    backgroundRadiant:
      "radial-gradient(283.82% 146.27% at 0% 2.74%, #F0C800 0%, #FAB187 100%)",
    progressColor: "#C68F02",
    svg: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <g clipPath="url(#clip0_1892_25587)">
            <path
              d="M13.8782 6.45503C12.9788 5.55556 11.7089 4.97354 10.3333 4.97354C8.95759 4.97354 7.68775 5.5291 6.78828 6.45503C5.88881 7.3545 5.3068 8.62434 5.3068 10C5.3068 11.3757 5.88881 12.6455 6.78828 13.545C7.68775 14.4444 8.95759 15.0265 10.3333 15.0265C11.7089 15.0265 12.9788 14.4709 13.8782 13.545C14.7777 12.6455 15.3597 11.3757 15.3597 10C15.3597 8.62434 14.8042 7.3545 13.8782 6.45503Z"
              fill="currentColor"
            />
            <path
              d="M10.3333 3.4127C10.7036 3.4127 11.0211 3.09524 11.0211 2.72487V0.687831C11.0211 0.31746 10.7036 0 10.3333 0C9.96288 0 9.64542 0.31746 9.64542 0.687831V2.72487C9.64542 3.09524 9.96288 3.4127 10.3333 3.4127Z"
              fill="currentColor"
            />
            <path
              d="M15.9682 5.34392L17.4232 3.88889C17.6878 3.62434 17.6878 3.20106 17.4232 2.93651C17.1586 2.67196 16.7354 2.67196 16.4708 2.93651L15.0158 4.39153C14.7512 4.65609 14.7512 5.07937 15.0158 5.34392C15.2539 5.60847 15.6772 5.60847 15.9682 5.34392Z"
              fill="currentColor"
            />
            <path
              d="M19.6454 9.31217H17.6084C17.238 9.31217 16.9206 9.62963 16.9206 10C16.9206 10.3704 17.238 10.6878 17.6084 10.6878H19.6454C20.0158 10.6878 20.3333 10.3704 20.3333 10C20.3333 9.62963 20.0158 9.31217 19.6454 9.31217Z"
              fill="currentColor"
            />
            <path
              d="M15.9417 14.6561C15.6772 14.3915 15.2539 14.3915 14.9893 14.6561C14.7248 14.9206 14.7248 15.3439 14.9893 15.6085L16.4444 17.0635C16.7089 17.328 17.1322 17.328 17.3967 17.0635C17.6613 16.7989 17.6613 16.3757 17.3967 16.1111L15.9417 14.6561Z"
              fill="currentColor"
            />
            <path
              d="M10.3333 16.5873C9.96288 16.5873 9.64542 16.9048 9.64542 17.2751V19.3122C9.64542 19.6825 9.96288 20 10.3333 20C10.7036 20 11.0211 19.6825 11.0211 19.3122V17.2751C11.0211 16.9048 10.7036 16.5873 10.3333 16.5873Z"
              fill="currentColor"
            />
            <path
              d="M4.69833 14.6561L3.2433 16.1111C2.97875 16.3757 2.97875 16.7989 3.2433 17.0635C3.50786 17.328 3.93114 17.328 4.19569 17.0635L5.65071 15.6085C5.91526 15.3439 5.91526 14.9206 5.65071 14.6561C5.41262 14.3915 4.98934 14.3915 4.69833 14.6561Z"
              fill="currentColor"
            />
            <path
              d="M3.74595 10C3.74595 9.62963 3.42849 9.31217 3.05812 9.31217H1.02108C0.650712 9.31217 0.333252 9.62963 0.333252 10C0.333252 10.3704 0.650712 10.6878 1.02108 10.6878H3.05812C3.42849 10.6878 3.74595 10.3704 3.74595 10Z"
              fill="currentColor"
            />
            <path
              d="M4.69833 5.34392C4.96288 5.60847 5.38616 5.60847 5.65071 5.34392C5.91526 5.07937 5.91526 4.65609 5.65071 4.39153L4.19569 2.93651C3.93114 2.67196 3.50786 2.67196 3.2433 2.93651C2.97875 3.20106 2.97875 3.62434 3.2433 3.88889L4.69833 5.34392Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_1892_25587">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.333252)"
              />
            </clipPath>
          </defs>
        </svg>
      </>
    ),
  },
  {
    label: "Sundown",
    time: "04:00 P.M - 12:00 A.M",
    bgColor: "#FFF1EB",
    color: "#FF8800",
    borderColor: "#FBA804",
    backgroundRadiant:
      "radial-gradient(145.59% 109.53% at 0% 5.32%, #FBC920 0%, #E0447C 100%)",
    progressColor: "#BC5E49",
    svg: (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9.58325 4.16667V6.66667C9.58325 6.77717 9.62715 6.88316 9.70529 6.9613C9.78343 7.03944 9.88941 7.08333 9.99992 7.08333C10.1104 7.08333 10.2164 7.03944 10.2945 6.9613C10.3727 6.88316 10.4166 6.77717 10.4166 6.66667V4.16667C10.4166 4.05616 10.3727 3.95018 10.2945 3.87204C10.2164 3.7939 10.1104 3.75 9.99992 3.75C9.88941 3.75 9.78343 3.7939 9.70529 3.87204C9.62715 3.95018 9.58325 4.05616 9.58325 4.16667Z"
            fill="currentColor"
          />
          <path
            d="M18.7499 12.5006H15.4166C15.2979 11.1514 14.6781 9.89562 13.6793 8.98082C12.6805 8.06602 11.3752 7.55859 10.0208 7.55859C8.66632 7.55859 7.36104 8.06602 6.36224 8.98082C5.36344 9.89562 4.7436 11.1514 4.62492 12.5006H1.24992C1.13941 12.5006 1.03343 12.5445 0.955291 12.6227C0.877151 12.7008 0.833252 12.8068 0.833252 12.9173C0.833252 13.0278 0.877151 13.1338 0.955291 13.2119C1.03343 13.2901 1.13941 13.334 1.24992 13.334H18.7499C18.8604 13.334 18.9664 13.2901 19.0445 13.2119C19.1227 13.1338 19.1666 13.0278 19.1666 12.9173C19.1666 12.8068 19.1227 12.7008 19.0445 12.6227C18.9664 12.5445 18.8604 12.5006 18.7499 12.5006Z"
            fill="currentColor"
          />
          <path
            d="M14.2873 8.63087C14.326 8.66992 14.3721 8.70092 14.4229 8.72208C14.4737 8.74323 14.5281 8.75412 14.5831 8.75412C14.6382 8.75412 14.6926 8.74323 14.7434 8.72208C14.7942 8.70092 14.8402 8.66992 14.879 8.63087L16.4831 7.02671C16.522 6.98786 16.5528 6.94173 16.5738 6.89097C16.5949 6.84022 16.6057 6.78581 16.6057 6.73087C16.6057 6.67593 16.5949 6.62153 16.5738 6.57077C16.5528 6.52001 16.522 6.47389 16.4831 6.43504C16.4443 6.39619 16.3982 6.36537 16.3474 6.34435C16.2967 6.32332 16.2423 6.3125 16.1873 6.3125C16.1324 6.3125 16.078 6.32332 16.0272 6.34435C15.9764 6.36537 15.9303 6.39619 15.8915 6.43504L14.2873 8.03921C14.2483 8.07794 14.2173 8.12402 14.1961 8.1748C14.175 8.22557 14.1641 8.28003 14.1641 8.33504C14.1641 8.39004 14.175 8.4445 14.1961 8.49528C14.2173 8.54605 14.2483 8.59214 14.2873 8.63087Z"
            fill="currentColor"
          />
          <path
            d="M5.41658 8.75171C5.47142 8.75202 5.52578 8.74151 5.57654 8.72078C5.62731 8.70004 5.67348 8.66949 5.71241 8.63087C5.75147 8.59214 5.78247 8.54605 5.80362 8.49528C5.82477 8.4445 5.83566 8.39004 5.83566 8.33504C5.83566 8.28003 5.82477 8.22557 5.80362 8.1748C5.78247 8.12402 5.75147 8.07794 5.71241 8.03921L4.10825 6.43504C4.0694 6.39619 4.02328 6.36537 3.97252 6.34435C3.92176 6.32332 3.86736 6.3125 3.81241 6.3125C3.75747 6.3125 3.70307 6.32332 3.65231 6.34435C3.60155 6.36537 3.55543 6.39619 3.51658 6.43504C3.47773 6.47389 3.44691 6.52001 3.42589 6.57077C3.40486 6.62153 3.39404 6.67593 3.39404 6.73087C3.39404 6.78581 3.40486 6.84022 3.42589 6.89098C3.44691 6.94173 3.47773 6.98786 3.51658 7.02671L5.12075 8.63087C5.15968 8.66949 5.20585 8.70004 5.25662 8.72078C5.30739 8.74151 5.36174 8.75202 5.41658 8.75171Z"
            fill="currentColor"
          />
          <path
            d="M14.9999 13.75H4.99992C4.88941 13.75 4.78343 13.7939 4.70529 13.872C4.62715 13.9502 4.58325 14.0562 4.58325 14.1667C4.58325 14.2772 4.62715 14.3832 4.70529 14.4613C4.78343 14.5394 4.88941 14.5833 4.99992 14.5833H14.9999C15.1104 14.5833 15.2164 14.5394 15.2945 14.4613C15.3727 14.3832 15.4166 14.2772 15.4166 14.1667C15.4166 14.0562 15.3727 13.9502 15.2945 13.872C15.2164 13.7939 15.1104 13.75 14.9999 13.75Z"
            fill="currentColor"
          />
          <path
            d="M12.9165 15.418H7.08317C6.97266 15.418 6.86668 15.4619 6.78854 15.54C6.7104 15.6181 6.6665 15.7241 6.6665 15.8346C6.6665 15.9451 6.7104 16.0511 6.78854 16.1293C6.86668 16.2074 6.97266 16.2513 7.08317 16.2513H12.9165C13.027 16.2513 13.133 16.2074 13.2111 16.1293C13.2893 16.0511 13.3332 15.9451 13.3332 15.8346C13.3332 15.7241 13.2893 15.6181 13.2111 15.54C13.133 15.4619 13.027 15.418 12.9165 15.418Z"
            fill="currentColor"
          />
          <path
            d="M12.5457 6.06118L12.129 6.89451C12.0796 6.99292 12.0712 7.10688 12.1055 7.2115C12.1399 7.31613 12.2142 7.40289 12.3124 7.45285C12.37 7.48369 12.4345 7.49946 12.4999 7.49868C12.5768 7.49845 12.6522 7.47691 12.7176 7.43646C12.7831 7.39601 12.8361 7.33823 12.8707 7.26951L13.2874 6.43618C13.3169 6.38708 13.336 6.33242 13.3435 6.27562C13.3509 6.21881 13.3466 6.16107 13.3307 6.10601C13.3149 6.05095 13.2878 5.99976 13.2513 5.95562C13.2147 5.91149 13.1695 5.87537 13.1184 5.84952C13.0672 5.82366 13.0113 5.80863 12.9541 5.80537C12.8969 5.8021 12.8396 5.81067 12.7859 5.83053C12.7322 5.85039 12.6831 5.88113 12.6418 5.92082C12.6004 5.9605 12.5677 6.00828 12.5457 6.06118Z"
            fill="currentColor"
          />
          <path
            d="M6.89587 5.88026C6.79773 5.93021 6.72336 6.01698 6.68901 6.1216C6.65465 6.22622 6.66311 6.34019 6.71254 6.43859L7.1292 7.27193C7.16383 7.34064 7.2168 7.39843 7.28226 7.43888C7.34771 7.47932 7.42309 7.50086 7.50004 7.50109C7.5654 7.50187 7.6299 7.48611 7.68754 7.45526C7.78567 7.40531 7.86004 7.31854 7.8944 7.21392C7.92875 7.1093 7.92029 6.99533 7.87087 6.89693L7.4542 6.06359C7.40425 5.96546 7.31748 5.89109 7.21286 5.85673C7.10824 5.82238 6.99427 5.83084 6.89587 5.88026Z"
            fill="currentColor"
          />
          <path
            d="M3.14587 10.3713L3.9792 10.788C4.03756 10.8169 4.10159 10.8326 4.1667 10.8338C4.24435 10.8344 4.32061 10.8132 4.38687 10.7727C4.45313 10.7322 4.50676 10.674 4.5417 10.6047C4.56668 10.5555 4.58164 10.5019 4.58572 10.4469C4.5898 10.3918 4.58292 10.3366 4.56548 10.2843C4.54804 10.2319 4.52038 10.1836 4.48411 10.142C4.44785 10.1005 4.40369 10.0665 4.3542 10.0422L3.52087 9.62552C3.42196 9.5764 3.30759 9.56855 3.2029 9.60371C3.0982 9.63887 3.01175 9.71415 2.96254 9.81302C2.91311 9.91142 2.90466 10.0254 2.93901 10.13C2.97336 10.2346 3.04773 10.3214 3.14587 10.3713Z"
            fill="currentColor"
          />
          <path
            d="M16.4792 9.62552L15.6458 10.0422C15.5967 10.0669 15.553 10.1011 15.5171 10.1428C15.4813 10.1846 15.4541 10.233 15.437 10.2852C15.42 10.3375 15.4134 10.3927 15.4178 10.4475C15.4222 10.5023 15.4374 10.5558 15.4625 10.6047C15.4971 10.6734 15.5501 10.7312 15.6156 10.7716C15.681 10.8121 15.7564 10.8336 15.8333 10.8338C15.8985 10.8326 15.9625 10.8169 16.0208 10.788L16.8542 10.3713C16.9523 10.3214 17.0267 10.2346 17.061 10.13C17.0954 10.0254 17.0869 9.91142 17.0375 9.81302C16.9883 9.71415 16.9018 9.63887 16.7971 9.60371C16.6925 9.56855 16.5781 9.5764 16.4792 9.62552Z"
            fill="currentColor"
          />
        </svg>
      </>
    ),
  },
];
