import {
  ArrowLeftIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import {
  tabComponents,
  tabs,
} from "components/ProfileForm/LaptopForm/components/WebAchievementForm/WebAchievementEditForm";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useMemo, useState } from "react";
import { MdEdit, MdOutlineFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { updateDetails } from "redux/profile-form";

const AchievementEditModal = ({
  goBack,
  field,
  type = "ADD",
  id,
  achievementDataModalProps,
  setAchievementDataModalProps,
}) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  const achievements = details[field];

  const [showTab, setShowTab] = useState(tabs[0].value);
  const doesAchievementExist =
    achievements.draft.find((_achieve) => _achieve.id === id) ||
    achievements.added.find((_achieve) => _achieve.id === id);

  const existedAchievement = useMemo(() => {
    if (doesAchievementExist) {
      const existAsDraft = achievements.draft.find(
        (_achieve) => _achieve.id === id
      );
      const existAsAdded = achievements.added.find(
        (_achieve) => _achieve.id === id
      );
      return existAsDraft ? existAsDraft : existAsAdded;
    } else return null;
  }, [achievements]);

  const [achievementInput, setAchievementInput] = useState(
    existedAchievement !== null
      ? existedAchievement
      : {
          title: "",
          field: "",
          background: "",
          approach: "",
          research: "",
          executionProcess: "",
          appliedKnowledge: "",
          obstacles: "",
          learnings: "",
          growthOpportunities: "",
        }
  );

  const handleAddAchievement = () => {
    let updatedAchievements;
    if (type === "EDIT") {
      if (achievements.draft.find((_achieve) => _achieve.id === id)) {
        updatedAchievements = {
          draft: achievements.draft.filter((_achieve) => _achieve.id !== id),
          added: [...achievements.added, { ...achievementInput }],
        };
      } else {
        updatedAchievements = {
          draft: achievements.draft,
          added: achievements.added.map((_achieve) => {
            if (_achieve.id === id) {
              return {
                id,
                ...achievementInput,
              };
            }
            return { ..._achieve };
          }),
        };
      }
    } else {
      updatedAchievements = {
        ...achievements,
        added: [...achievements.added, { ...achievementInput, id: uuid() }],
      };
    }

    dispatch(updateDetails({ field, value: updatedAchievements }));
    goBack();
  };

  const handleAddToDraft = () => {
    let updatedAchievements;
    if (type === "EDIT") {
      if (achievements.added.find((_achieve) => _achieve.id === id)) {
        updatedAchievements = {
          added: achievements.draft.filter((_achieve) => _achieve.id !== id),
          draft: [...achievements.draft, { ...achievementInput, id }],
        };
      } else {
        updatedAchievements = {
          added: achievements.added,
          draft: achievements.draft.map((_achieve) => {
            if (_achieve.id === id) {
              return {
                id,
                ...achievementInput,
              };
            }
            return { ..._achieve };
          }),
        };
      }
    } else {
      updatedAchievements = {
        ...achievements,
        draft: [...achievements.draft, { ...achievementInput, id: uuid() }],
      };
    }
    dispatch(updateDetails({ field, value: updatedAchievements }));
    goBack();
  };

  const handleBackClick = () => {
    if (type === "EDIT" || type === "ADD") {
      if (achievements.added.find((_achieve) => _achieve.id === id)) {
        goBack();
      } else handleAddToDraft();
    } else {
      goBack();
    }
  };

  const handleEditAchievement = () => {
    setAchievementDataModalProps({
      ...achievementDataModalProps,
      open: true,
      type: "EDIT",
      editID: id,
    });
  };
  return (
    <div className="flex  flex-col items-center w-full mx-auto h-screen ">
      <div className="flex flex-col items-start  w-full ">
        <div className="flex justify-between items-center w-full p-2">
          <ProfileButton
            onClick={handleBackClick}
            text={"Back"}
            size={"large"}
            icon={<ArrowLeftIcon className="w-6" />}
            iconAlign={"left"}
          />
          {type === "VIEW" && (
            <ProfileButton
              onClick={handleEditAchievement}
              text={"Edit"}
              size={"large"}
              variant={"filled"}
              icon={<MdEdit />}
              iconAlign={"left"}
            />
          )}
        </div>

        {type !== "VIEW" && (
          <div className="flex justify-between gap-10 items-center w-full p-2 px-4">
            <button
              className="text-md flex items-center gap-2 font-satoshi text-primary-blue-500 font-bold text-center"
              onClick={handleAddToDraft}
            >
              <MdOutlineFileDownload className="text-2xl" />
              Save As Draft
            </button>
            <ProfileButton
              onClick={handleAddAchievement}
              text={type === "EDIT" ? "Save" : "Add Now"}
              size={"large"}
              variant={"filled"}
              icon={
                type === "EDIT" ? (
                  <CheckIcon className="w-6" />
                ) : (
                  <PlusCircleIcon className="w-6" />
                )
              }
              iconAlign={"left"}
            />
          </div>
        )}
      </div>
      <div className=" w-full h-full flex-1 ">
        <div className="w-full h-full bg-white p-6 rounded-xl relative">
          {tabComponents(showTab, achievementInput, setAchievementInput, type)}

          <img
            className="w-8  object-contain absolute top-6 right-6 opacity-40"
            src={tabs.find((_tab) => _tab.value === showTab).icon}
            alt=""
          />
        </div>
      </div>

      <div className="grid grid-cols-4  bg-white">
        {tabs.map((tab, idx) => (
          <div
            onClick={() => setShowTab(tab.value)}
            key={idx}
            className={`text-xs font-bold p-[10px] h-full flex flex-col items-center gap-2 cursor-pointer ${
              showTab === tab.value
                ? "border border-primary-blue-500 bg-primary-blue-100 text-black"
                : "text-primary-grey-500"
            }`}
          >
            <img src={tab.icon} alt="" className="w-5 object-contain" />
            <p className="text-mini text-center">{tab.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AchievementEditModal;
