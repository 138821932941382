import { useLocation } from "react-router-dom";
import DesktopWrapper from "../components/Layout/DesktopWrapper";
import TabBar from "../components/Layout/TabBar";
import TopBar from "../components/Layout/TopBar";
import React from "react";

function AppWrapper({ children }) {
  const { pathname } = useLocation();
  return (
    <div className="h-screen w-screen">
      <TopBar />
      <div className="lg:hidden">{children}</div>
      <DesktopWrapper>
        <div className="overflow-y-auto">{children}</div>
      </DesktopWrapper>
      {isTabBarVisible(pathname) && <TabBar />}
    </div>
  );
}

const isTabBarVisible = (pathname) => {
  return !["achievements/add", "CareerGoals"].includes(pathname.slice(1));
};

export default AppWrapper;
