import React from "react";
import "assets/styles/scss/bioSection.scss";
import { Img } from "components/BioSection/Img/Img";
import { Text } from "helpers/constants/Text";
import { List } from "components/BioSection/List/List";
import { BsHexagonHalf } from "react-icons/bs";
import {MdLockOutline, MdNorthEast} from "react-icons/md/"
import { Header } from "components/BioSection/Header/Header";
import Button from "helpers/constants/button.js"
import WorkExp from "components/BioSection/WorkExp/WorkExp";
import Rtc from "components/BioSection/RoadToContract/Rtc";
import BuildIndetity from "components/BioSection/BuildYourIdentity/BuildIndetity";
import Clans from "components/BioSection/Clans/Clans";
import Tools from "components/BioSection/Tools/Tools";
import Castles from "components/BioSection/Castles/Castles";
import Profile from "components/BioSection/Profile/Profile";


const BioSection = () => {
  return (
    <div className="bg-white-A700 font-poppins">

      <Header/>

      <div className="  flex flex-col font-satoshi gap-12 items-start justify-start md:px-10 sm:px-5 px-[72px] w-full">
        <div className="flex flex-col gap-[7px] items-start justify-start w-auto md:w-full ">
          <Text
            className="capitalize text-2xl md:text-[22px] text-red-500 sm:text-xl tracking-[0.96px] w-auto ml-10 "
            size="txtManropeSemiBold24"
          >
            Your Life Bio
          </Text>
          <Text
            className="text-gray-700 text-sm tracking-[0.70px] w-auto ml-10"
            size="txtSatoshiLight14"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Text>
        </div>

        <div className=" mainDiv flex md:flex-col flex-row gap-4 items-start justify-start max-w-[1296px] mx-auto w-full">
          <div className=" secondDiv bg-white-A700 flex flex-1 flex-col gap-10 items-start justify-start pb-2 pt-6 w-full ml-auto">
            


            <WorkExp />
            <Rtc/>
            <BuildIndetity/>

            {/* clans */}
            <Clans/>
            

            {/* Tools */}
            <Tools/>

            {/* Carrer Castles Section */}

           <Castles/>
            
            <Profile/>







          </div>
        </div>
      </div>
    </div>
  );
};

export default BioSection;
