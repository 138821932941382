import { MdArrowBack, MdNorthEast, MdRestartAlt } from "react-icons/md";
import { Link, useHistory, useLocation } from "react-router-dom";
import { assessments } from "./BoostCareer";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export default function AlreadySubmitted({}) {
  const history = useHistory();
  const { pathname } = useLocation();
  const assessment = assessments.find((a) => a.path === pathname);
  return (
    <div className="bg-white fixed top-0 bottom-0 right-0 left-0 z-50">
      <div className="px-4 flex flex-col justify-between  h-screen overflow-y-auto space-y-8 py-6">
        <div
          onClick={() => {
            history.goBack();
          }}
          className="flex flex-row items-center space-x-2 text-sm font-satoshi capitalize underline underline-offset-2 font-bold leading-5 text-[#696763]"
        >
          <MdArrowBack size={24} />
          <p>Back</p>
        </div>
        <div className="flex flex-col gap-4 items-center">
          <img src={assessment.image} className="h-[120px] w-[120px] mx-auto" />
          <p
            className="text-center font-lato text-[24px] font-medium leading-9 tracking-[1.44px]"
            style={{
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              background: assessment.gradient,
              backgroundClip: "text",
            }}
          >
            {assessment.title}
          </p>
        </div>
        <div className="flex flex-col items-center py-6 gap-2">
          <div className="flex gap-2 items-center justify-center">
            <IoIosCheckmarkCircleOutline className="h-6 w-6 text-[#33A329]" />
            <span className="font-satoshi text-primary-neutral-800 font-medium leading-6">
              Completed
            </span>
          </div>
          <p className="font-satoshi text-xs text-primary-neutral-500 font-normal leading-4">
            on 24 Apr 2023
          </p>
        </div>
        <div className="flex flex-col gap-[18px] justify-between items-center pt-6 pb-12 px-4">
          <div
            className={`bg-black w-full flex flex-row items-center justify-center space-x-2 rounded-full pr-8 pl-10 py-4 cursor-pointer text-white font-satoshi text-sm font-bold leading-6`}
          >
            <p>Check Your ME Profile Now</p>
            <MdNorthEast className="h-5 w-5" />
          </div>
          <span className="font-satoshi text-sm font-medium text-primary-neutral-500 leading-5">
            OR
          </span>
          <Link
            to="/"
            className="text-sm underline underline-offset-2 flex items-center gasp-2 text-center font-satoshi font-bold leading-5 capitalize text-primary-neutral-800"
          >
            Retake <MdRestartAlt size={18} />
          </Link>
        </div>
      </div>
    </div>
  );
}
