import { useDispatch, useSelector } from "react-redux";
import {
  updatePhaseThreeCurrentQuestion,
  updatePhaseThreeResponse,
} from "redux/profile-form";

const usePhaseThree = () => {
  const dispatch = useDispatch();
  const { details, currentQuestion, response } = useSelector(
    (state) => state.profileForm.phaseThree
  );
  const { limitPerPage } = details;

  const getFormDetails = () => {
    return details;
  };

  const getCurrentQuestion = () => {
    return currentQuestion;
  };

  const getResponses = () => {
    return response;
  };

  const getResponse = (qId, ansId) => {
    return response?.find(
      (item) => item.questionId === qId && item.value === ansId
    )
      ? true
      : false;
  };

  const handleLimitInc = (currLimit) => {
    if (currLimit + limitPerPage >= details.questions.length) {
      return {
        isNextButtonDisabled: true,
        isPrevButtonDisabled: false,
      };
    }
    return {
      isNextButtonDisabled: false,
      isPrevButtonDisabled: false,
      newLimit: currLimit + limitPerPage,
    };
  };

  const handleLimitDec = (currLimit) => {
    if (currLimit - limitPerPage < 0) {
      return {
        isNextButtonDisabled: false,
        isPrevButtonDisabled: true,
      };
    }
    return {
      isNextButtonDisabled: false,
      isPrevButtonDisabled: false,
      newLimit: currLimit - limitPerPage,
    };
  };

  const handleQuestionChange = (id, currLimit) => {
    const questionIdx = details.questions.findIndex((q) => q.id === id);
    if (!questionIdx && questionIdx !== 0)
      return {
        isNextButtonDisabled: false,
      };
    const nextQuestionId =
      questionIdx + 1 < details.questions.length
        ? details.questions[questionIdx + 1]?.id
        : id;
    dispatch(updatePhaseThreeCurrentQuestion(nextQuestionId));

    if (
      questionIdx + 1 ===
      details.questions.slice(currLimit, limitPerPage + currLimit).length
    ) {
      return {
        isNextButtonDisabled: false,
      };
    }
    return {
      isNextButtonDisabled: false,
    };
  };

  const handleAnswerChange = (questionId, value) => {
    dispatch(updatePhaseThreeResponse({ questionId, value }));
  };

  return {
    getFormDetails,
    getCurrentQuestion,
    getResponses,
    getResponse,
    handleLimitInc,
    handleLimitDec,
    handleAnswerChange,
    handleQuestionChange,
  };
};

export default usePhaseThree;
