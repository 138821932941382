import { scrollToBottom } from "helpers/utils/profileForm/functions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showNavigation } from "redux/profile-form";

const CareerInterestForm = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToBottom();
    dispatch(showNavigation());
  }, []);

  return (
    <div className=" font-manrope h-full flex flex-col  p-4 md:px-10 gap-4 pt-24  ">
      <h4 className="text-xl text-primary-grey-500 max-w-sm font-medium ">
        Great, now lets talk about how your interests have evolved
      </h4>
      <h4 className="text-lg text-primary-grey-500 font-light">
        Think about how your{" "}
        <span className="text-primary-yellow-500 font-medium ">
          career interests
        </span>{" "}
        have changed over time.
      </h4>

      <div className="grid place-items-center gap-4 my-4">
        <img
          className="w-72 h-72 object-contain "
          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
          alt=""
        />
        <p className="text-primary-grey-500 text-md ">
          Who has not been asked what they wanted to be when they grow up, but
          the thing is that your career interests change over time. In this next
          section, think about all the things you have wanted to make a career
          in.
        </p>
      </div>
    </div>
  );
};

export default CareerInterestForm;
