export const titleProps = {
  field: "title",
  title: "Title",
  isTitle: true,
  tags: [
    "Completed",
    "Created",
    "Ranked",
    "Built",
    "Increased",
    "Participated in",
    "Contributed Towards",
  ],
  subtitle: "Give a brief account of what you’re proud of",
  placeholder: "Created a personal portfolio website using Webflow.",
  example: ["Successfully learned how to read, write and converse in French."],
};

export const backgroundProps = {
  field: "background",
  title: "Background",
  tags: [],
  subtitle:
    "Talk about the task you had to do, why you had to do it & your motivation to do it (if any)",
  placeholder:
    "As a 3rd year architecture student, I undertook the task of building a personal portfolio website to showcase my work and projects digitally. I recognized the importance of having a digital platform to exhibit my work, and decided to undertake this project as a means to learn about how to make websites.",
  example: [],
};

export const obstacleProps = {
  field: "obstacles",
  title: "Obstacles and Mentorship",
  tags: [],
  subtitle:
    "Think about any obstacles you faced along the way and how you overcame them. Mention if you sought mentorship from someone.    ",
  placeholder:
    "Technical Challenges: As I delved into Webflow and explored its features, I encountered technical difficulties, such as aligning elements, implementing custom interactions, or troubleshooting responsive design issues. To overcome these challenges, I extensively utilised Webflow's documentation, tutorials, and forums to find solutions and gain a deeper understanding of the platform's capabilities. I also sought help from online communities and discussion boards where experienced Webflow users shared their insights and advice.    ",
  example: [],
};

export const growthOpportunitiesProps = {
  field: "growthOpportunities",
  title: "Hindsight",
  tags: [],
  subtitle:
    "What aspects of your approach do you think could have been improved or done differently?    ",
  placeholder:
    "Planning and Time Management: In hindsight, I realised that I could have benefited from more detailed planning and better time management. Although I had a general roadmap of the project, breaking it down into smaller tasks and setting specific deadlines would have helped me stay more organised. ",
  example: [],
};

export const approachProps = {
  field: "approach",
  title: "Approach",
  tags: ["Learn", "Research", "Contact", "Organize", "Start"],
  subtitle: "What were some broad steps or actions you planned to take?",
  isPlaceHolderArr: true,
  placeholder: "Learn the tools used to make websites.",
  placeholders: [
    "Set a 3 month timeline",
    "Learn how to make websites.",
    "Learn the tools used to make websites.",
    "Research other portfolio websites.",
    "Organize my projects",
    "Make the website.",
  ],
  example: [],
};

export const researchProps = {
  field: "research",
  title: "Research",
  tags: [
    "Read up about",
    "Watched videos on",
    "Listened to podcasts about",
    "Search templates for",
    "Learn about",
    "Seek inspirations for",
  ],
  subtitle:
    "Talk about the kind of research you did (if any). Think about the kind of information you sought and from where.",
  isPlaceHolderArr: true,
  placeholder: "Watched videos about the basics of a website on Youtube.",
  placeholders: [
    "Watched videos about the basics of a website on Youtube.",
    "Read up about different website building platforms on Google.",
    "Watched videos to learn the basics of Webflow on Skill Share.",
    "Searched templates for portfolio websites on Webflow.",
    "Sought inspirations for colours and typography from Behance, Dribble and Pinterest.",
  ],
  example: ["Watched videos to learn the basics of Webflow on Skill Share."],
};

export const executionProps = {
  field: "executionProcess",
  title: "Execution Process",
  tags: [],
  subtitle:
    "Talk about your execution process. Put down step-by-step pointers on how you choose to complete your task (how you actually executed your task).",
  isPlaceHolderArr: true,
  placeholder:
    "I started by clarifying my goals for the website, which were to showcase my architectural projects to highlight my design skills, and attract potential clients or collaborators.",
  placeholders: [
    "I started by clarifying my goals for the website, which were to showcase my architectural projects to highlight my design skills, and attract potential clients or collaborators.",
    "My next step was to learn about how websites are actually made and what the basic elements for any functional websites are. I researched about this topic to find out the things I would need to understand and learn to start building a website. I got a basic understanding of domain addresses, and what basic programming languages are used to build websites.",
  ],
  example: [],
};

export const appliedKnowledgeProps = {
  field: "appliedKnowledge",
  title: "Applied Knowledge",
  tags: [],
  subtitle:
    " What knowledge did you put to use and where? Think about any particular subject or tool knowledge that helped you in completing the task",
  isPlaceHolderArr: true,
  placeholder:
    "I was able to put my knowledge of architecture concepts and design principles to use here as it helped me in shaping the overall layout and aesthetics of the website. ",
  placeholders: [
    "I was able to put my knowledge of architecture concepts and design principles to use here as it helped me in shaping the overall layout and aesthetics of the website. ",
    "My familiarity with tools relevant to architecture, such as CAD software and 3D modelling programs, helped me grasp visual editors like Webflow more easily.    ",
  ],
  example: [],
};
export const learningProps = {
  field: "learnings",
  title: "Learnings",
  tags: [
    "Learned",
    "Improved",
    "Increased knowledge of",
    "Gained",
    "Better understood",
    "Refined",
  ],
  subtitle:
    " Think about what you gained through this experience. Put down your key takeaways     ",
  isPlaceHolderArr: true,
  placeholder:
    "Learned how to effectively collaborate with others, listen to constructive criticism, and incorporate feedback to improve my work.     ",
  placeholders: [
    "Learned how to use Webflow",
    "Improved my storytelling and communication skills.",
    "Better understood how to articulate my unique strengths, style, and design approach.",
    "Learned how to effectively collaborate with others, listen to constructive criticism, and incorporate feedback to improve my work.",
    "Refined my understanding of design principles and their application across different disciplines.",
  ],
  example: [
    "Refined my understanding of design principles and their application across different disciplines.",
  ],
};
