import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "redux/profile-form";

const WebAchievementNavigation = () => {
  const { step } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();

  return (
    <div className="bg-white z-10 absolute bottom-0 inset-x-0">
      <div className="flex justify-between relative  gap-6 items-center p-4 lg:py-2 lg:px-20">
        <ProfileButton
          onClick={() => dispatch(updateStep(step - 1))}
          text={"Back"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
        />
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
          <button
            className="text-md font-satoshi text-primary-blue-500 font-bold text-center"
            onClick={() => dispatch(updateStep(step + 1))}
          >
            Can’t think of anything right now
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebAchievementNavigation;
