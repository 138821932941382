import { useCallback, useState } from "react";
import Question from "./Question";
import { questions } from "./questions";

export default function Goals({ onSubmit }) {
  const [selected, setSelected] = useState(null);
  const [selectedSub, setSelectedSub] = useState(null);

  const getSelectedCount = useCallback(() => {
    let count = 0;
    if (!selectedSub) return 0;
    Object.values(selectedSub).forEach((sub) => {
      Object.values(sub).forEach((arr) => {
        count += arr.length;
      });
    });
    return count;
  }, [selectedSub]);

  return (
    <div className="flex space-y-8 pt-4 pb-32 px-4 items-center text-center flex-col w-full">
      <div className="pb-6 pt-2 space-y-12">
        <div className="text-left flex p-4 flex-col items-start gap-4 rounded-[8px] border border-primary-neutral-200 text-primary-neutral-500 font-satoshi text-sm font-normal leading-5 tracking-[0.56px]">
          <p>What would you like to focus on for the next 6-8 months?</p>
          <p>Select up to 5</p>
        </div>
        {questions?.map((item) => {
          return (
            <Question
              {...item}
              selected={selected}
              setSelect={setSelected}
              selectedSub={selectedSub}
              setSelectedSub={setSelectedSub}
              getSelectedCount={getSelectedCount}
            />
          );
        })}
      </div>

      <div
        className={`transition-all duration-300 ${
          getSelectedCount() > 0
            ? "fixed bottom-0 left-0 w-full p-4 bg-white !mt-0"
            : "mt-4"
        }`}
      >
        <button
          onClick={() => onSubmit()}
          className={`w-full flex flex-row items-center justify-center space-x-3 bg-primary-neutral-900 rounded-full py-4 px-6 text-white font-lato text-base font-bold leading-7 tracking-[0.9px]`}
        >
          <div className="w-5 h-5 text-primary-neutral-900 font-lato text-xs font-bold capitalize rounded-full bg-white flex flex-row items-center justify-center">
            <p>{getSelectedCount()}</p>
          </div>
          <p>Add Now</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.9708 5.58906L13.7958 4.41406L8.5125 9.6974L9.6875 10.8724L14.9708 5.58906ZM18.5042 4.41406L9.6875 13.2307L6.20417 9.75573L5.02917 10.9307L9.6875 15.5891L19.6875 5.58906L18.5042 4.41406ZM0.3125 10.9307L4.97083 15.5891L6.14583 14.4141L1.49583 9.75573L0.3125 10.9307Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
