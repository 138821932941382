import React from "react";
import tempImg from "assets/images/bg/RadialBg.png";
import logo from "assets/images/icons/Logo.png";

const AchievementCard = ({ name, role, skills }) => {
  return (
    <div className="p-2">
      <div
        style={{
          boxShadow:
            "0px 24px 24px -24px rgba(0, 0, 0, 0.16), 0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)",
          background: "linear-gradient(0deg, #EDFFFF 0%, #FFF 33.53%), #FFF",
        }}
        className="flex p-4 rounded-2xl w-[240px] relative flex-col justify-center items-center space-y-8"
      >
        <div className="flex w-full flex-row items-center justify-between text-primary-neutral-400 font-lato text-3xs font-bold leading-3 capitalize">
          <div>Company Name</div>
          <div>Jan 5 2024</div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <img
            src={tempImg}
            alt=""
            className="w-12 h-12 object-cover rounded-lg"
          />
          <div className="flex flex-col items-center space-y-2 max-w-[200px]">
            <h1 className="px-5 text-black font-satoshi text-center text-base font-bold capitalize leading-6">
              {name}
            </h1>
            <p className="text-[#816FE9] text-center font-lato text-xs font-bold leading-[18px] tracking-[1.2px]">
              {role}
            </p>
          </div>
        </div>

        <div className="space-y-2">
          <p className="  font-bold text-[#CFCDC9] font-satoshi text-2xs leading-4 tracking-[0.4px] capitalize">
            Skills Employed
          </p>
          <p className="text-[#696763] font-lato text-2xs font-bold leading-4 capitalize">
            {skills}
          </p>
        </div>
        <div className="flex flex-row items-center justify-end w-full">
          <p className="font-bold text-primary-neutral-800 font-satoshi text-2xs leading-4 underline underline-offset-2 capitalize">
            Details
          </p>
        </div>
      </div>
    </div>
  );
};

export default AchievementCard;
