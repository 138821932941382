import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdArrowBack } from "react-icons/md";

function AchievementsRedirect() {
  const history = useHistory();
  let sliderRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setPage(current + 1);
    },
  };
  return (
    <div className="bg-white fixed top-[53px] bottom-0 right-0 left-0 z-50 pb-6 px-4 flex flex-col justify-between h-[calc(100vh-53px)] overflow-y-auto space-y-8">
      <div
        onClick={() => {
          if (page == 1) history.goBack();
          else setPage(1);
        }}
        className="flex flex-row items-center space-x-2 py-2 text-sm font-satoshi capitalize underline underline-offset-2 font-bold leading-5 text-[#696763]"
      >
        <MdArrowBack size={16} />
        <p>Back</p>
      </div>

      <Slider
        ref={(slider) => {
          sliderRef = slider;
        }}
        {...settings}
      >
        <div className="flex flex-col items-center space-y-8 relative">
          {loaded ? (
            <h1 className="text-black text-center font-lato text-[22px] font-bold leading-8 tracking-[0.88px] capitalize">
              Highlight your work experience with beautiful Achievement Cards
            </h1>
          ) : (
            <div className="flex flex-col items-center space-y-2">
              <h1 className="text-black h-6 w-full rounded-md bg-primary-neutral-100 animate-pulse text-center font-lato text-[22px] font-bold leading-8 tracking-[0.88px] capitalize"></h1>
              <h1 className="text-black h-6 w-[50vw] mx-auto rounded-md bg-primary-neutral-100 animate-pulse text-center font-lato text-[22px] font-bold leading-8 tracking-[0.88px] capitalize"></h1>
              <h1 className="text-black h-6 w-[70vw] mx-auto rounded-md bg-primary-neutral-100 animate-pulse text-center font-lato text-[22px] font-bold leading-8 tracking-[0.88px] capitalize"></h1>
            </div>
          )}{" "}
          {loaded ? (
            <></>
          ) : (
            <div className="absolute top-[120px] right-0 left-0 flex flex-row items-center justify-center z-50">
              <div className="w-[80vw] h-[40vh] rounded-md mx-auto bg-primary-neutral-100 animate-pulse " />
            </div>
          )}
          <img
            onLoad={() => {
              setLoaded(true);
            }}
            src="/assets/images/Acard.svg"
            alt=""
            className="mx-auto"
          />
        </div>

        <div className="flex flex-col items-center space-y-8">
          <h1 className="text-black font-lato text-[22px] font-bold leading-8 tracking-[0.88px] capitalize">
            Easily Showcase Your Work!
          </h1>
          <div className="py-2 px-4 w-full">
            <div className="pb-4 border-b border-primary-neutral-100 w-full">
              <div className="w-full p-2 flex flex-row space-x-3">
                <p className="text-black font-lato text-base font-bold leading-6 tracking-[0.64px]">
                  1
                </p>
                <div className="flex flex-col space-y-2">
                  <p className="text-black font-lato text-base font-bold leading-6 tracking-[0.64px]">
                    Describe Your Work
                  </p>
                  <p className="text-primary-neutral-500 font-satoshi text-xs font-normal leading-4 tracking-[0.48px]">
                    Tell us what all you did in a project as part of different
                    roles
                  </p>
                </div>
                <img src="/assets/images/11.png" alt="" className="w-16" />
              </div>
            </div>
            <div className="py-4 border-b border-primary-neutral-100 w-full">
              <div className="w-full p-2 flex flex-row space-x-3">
                <p className="text-black font-lato text-base font-bold leading-6 tracking-[0.64px]">
                  2
                </p>
                <div className="flex flex-col space-y-2">
                  <p className="text-black font-lato text-base font-bold leading-6 tracking-[0.64px]">
                    Make It Stand Out
                  </p>
                  <p className="text-primary-neutral-500 font-satoshi text-xs font-normal leading-4 tracking-[0.48px]">
                    Add insights about where your work helped you grow
                  </p>
                </div>
                <img src="/assets/images/22.png" alt="" className="w-16" />
              </div>
            </div>
            <div className="pt-4 border-b border-primary-neutral-100 w-full">
              <div className="w-full p-2 flex flex-row space-x-3">
                <p className="text-black font-lato text-base font-bold leading-6 tracking-[0.64px]">
                  3
                </p>
                <div className="flex flex-col space-y-2">
                  <p className="text-black font-lato text-base font-bold leading-6 tracking-[0.64px]">
                    Finish Up And Share
                  </p>
                  <p className="text-primary-neutral-500 font-satoshi text-xs font-normal leading-4 tracking-[0.48px]">
                    Add multiple experiences and share as single card or a
                    project deck
                  </p>
                </div>
                <img src="/assets/images/33.png" alt="" className="w-16" />
              </div>
            </div>
          </div>
        </div>
      </Slider>

      <div className="flex flex-col w-full space-y-8">
        <div className="flex flex-row items-center justify-center space-x-3">
          {[1, 2]?.map((item) => {
            return (
              <div
                className={`w-2 h-2 rounded-full bg-black ${
                  page == item ? "" : "opacity-20"
                }`}
              />
            );
          })}
        </div>
        <button
          disabled={!loaded}
          onClick={() => {
            if (page == 1) sliderRef.slickGoTo(1);
            else {
              history.push("/achievements/add");
            }
          }}
          className="w-full bg-[#282724] rounded-full flex flex-row items-center justify-center text-center py-4 px-5 text-white font-lato text-lg font-bold leading-7 capitalize tracking-[0.9px] space-x-3"
        >
          <p>{page == 1 ? "Next" : "Get Started!"}</p>
          {page == 2 && <ArrowRight size={20} />}
        </button>
      </div>
    </div>
  );
}

export default AchievementsRedirect;
