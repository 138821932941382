import { Drawer } from "@mui/material";
import { X } from "@phosphor-icons/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function LearnMore({ isOpen, closeModal, item }) {
  const [data, setData] = useState();

  useEffect(() => {
    if (item) {
      setData(item);
    }
  }, [item]);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={500}
      PaperProps={{
        style: {
          width: window.innerWidth < 1024 ? "100%" : "100vw",
          height: window.innerWidth < 1024 ? "100vh" : "100vh",
          background: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className="h-screen flex flex-row items-end justify-between lg:justify-end">
        <div className="variation-bg h-[75vh] w-[100vh] lg:w-[720px] lg:h-screen relative rounded-t-2xl lg:rounded-t-none">
          <div className="z-40 flex cursor-pointer flex-row items-center justify-center absolute -top-16 lg:top-[50%] lg:-left-20 left-0 right-0 w-full lg:max-w-max">
            <div
              onClick={() => {
                closeModal();
              }}
              style={{ boxShadow: "0px 0px 30px 0px #FFF" }}
              className="text-white flex flex-row items-center justify-center h-10 w-10 bg-black rounded-full"
            >
              <X size={24} />
            </div>
          </div>
          <div className=" rounded-t-2xl lg:rounded-t-none px-0 lg:px-8 py-0 lg:py-8 flex flex-col items-start lg:space-y-4 lg:pb-5 overflow-y-auto max-h-[75vh] lg:max-h-[100vh]">
            <div
              className="relative w-full p-4 flex flex-col items-center space-y-6 rounded-t-2xl lg:rounded-t-none"
              style={{
                background: item?.background,
                color: item?.color,
              }}
            >
              <img
                src={item?.image}
                alt=""
                className="w-[200px] h-[200px] object-contain"
              />
              <h1 className="font-poppins text-lg lg:text-2xl font-medium leading-9">
                {item?.name}
              </h1>

              {item?.unlocked || item?.completed ? null : (
                <div
                  className={`w-8 h-8 rounded-full flex flex-row items-center justify-center bg-primary-gray-80 absolute -top-2 right-2 z-20`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8.00008 1.33594C6.16208 1.33594 4.66675 2.83127 4.66675 4.66927V6.66927H4.00008C3.26475 6.66927 2.66675 7.26727 2.66675 8.0026V13.3359C2.66675 14.0713 3.26475 14.6693 4.00008 14.6693H12.0001C12.7354 14.6693 13.3334 14.0713 13.3334 13.3359V8.0026C13.3334 7.26727 12.7354 6.66927 12.0001 6.66927H11.3334V4.66927C11.3334 2.83127 9.83808 1.33594 8.00008 1.33594ZM12.0001 8.0026L12.0014 13.3359H4.00008V8.0026H12.0001ZM6.00008 6.66927V4.66927C6.00008 3.5666 6.89741 2.66927 8.00008 2.66927C9.10275 2.66927 10.0001 3.5666 10.0001 4.66927V6.66927H6.00008Z"
                      fill="#9C9A96"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="bg-white pt-4 px-6 pb-10 flex flex-col items-stretch space-y-6 rounded-2xl">
              <p className="text-primary-neutral-800 font-inter text-xs lg:text-lg font-light">
                Get started by working with various startups right as a freshman
                or a sophomore.
              </p>
              <div className="bg-primary-neutral-100 flex flex-row items-center space-x-4">
                <div className="bg-primary-neutral-50 w-16 h-16 flex flex-row items-center justify-center">
                  <img
                    src="/assets/svg/pass/1.svg"
                    alt=""
                    className="w-8 h-8"
                  />
                </div>
                <div className="flex flex-col items-start space-y-0 text-xs lg:text-sm">
                  <h1 className="text-primary-neutral-500 font-inter font-semibold leading-5">
                    Benefit
                  </h1>
                  <p className="text-primary-neutral-500 font-inter font-light leading-5">
                    Lorem ipsum dolor sit amin
                  </p>
                </div>
              </div>
              <div className="bg-primary-neutral-100 flex flex-row items-center space-x-4">
                <div className="bg-primary-neutral-50 w-16 h-16 flex flex-row items-center justify-center">
                  <img
                    src="/assets/svg/pass/2.svg"
                    alt=""
                    className="w-8 h-8"
                  />
                </div>
                <div className="flex flex-col items-start space-y-0 text-xs lg:text-sm">
                  <h1 className="text-primary-neutral-500 font-inter font-semibold leading-5">
                    Benefit
                  </h1>
                  <p className="text-primary-neutral-500 font-inter font-light leading-5">
                    Lorem ipsum dolor sit amin
                  </p>
                </div>
              </div>
              <div className="bg-primary-neutral-100 flex flex-row items-center space-x-4">
                <div className="bg-primary-neutral-50 w-16 h-16 flex flex-row items-center justify-center">
                  <img
                    src="/assets/svg/pass/3.svg"
                    alt=""
                    className="w-8 h-8"
                  />
                </div>
                <div className="flex flex-col items-start space-y-0 text-xs lg:text-sm">
                  <h1 className="text-primary-neutral-500 font-inter font-semibold leading-5">
                    Benefit
                  </h1>
                  <p className="text-primary-neutral-500 font-inter font-light leading-5">
                    Lorem ipsum dolor sit amin
                  </p>
                </div>
              </div>
              {item?.unlocked ? (
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, #2BB656 0%, #008B46 100%)",
                  }}
                  className={`rounded-[12px] cursor-pointer w-full shadow-md py-3.5 px-8 lg:hidden flex flex-row items-center justify-center space-x-2 text-white font-inter text-sm font-semibold`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M8.49984 1.83398C6.66184 1.83398 5.1665 3.32932 5.1665 5.16732V7.16732H4.49984C3.7645 7.16732 3.1665 7.76532 3.1665 8.50065V13.834C3.1665 14.5693 3.7645 15.1673 4.49984 15.1673H12.4998C13.2352 15.1673 13.8332 14.5693 13.8332 13.834V8.50065C13.8332 7.76532 13.2352 7.16732 12.4998 7.16732H11.8332V5.16732C11.8332 3.32932 10.3378 1.83398 8.49984 1.83398ZM12.4998 8.50065L12.5012 13.834H4.49984V8.50065H12.4998ZM6.49984 7.16732V5.16732C6.49984 4.06465 7.39717 3.16732 8.49984 3.16732C9.6025 3.16732 10.4998 4.06465 10.4998 5.16732V7.16732H6.49984Z"
                      fill="white"
                    />
                  </svg>
                  <p>Start Now</p>
                </div>
              ) : item?.completed ? (
                <div className="lg:hidden flex flex-col w-full">
                  <div className="flex flex-row items-center space-x-1">
                    <div className="w-11/12 h-1.5 bg-primary-green-700" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M12.0001 4.66656L11.0601 3.72656L6.83344 7.95323L7.77344 8.89323L12.0001 4.66656ZM14.8268 3.72656L7.77344 10.7799L4.98677 7.9999L4.04677 8.9399L7.77344 12.6666L15.7734 4.66656L14.8268 3.72656ZM0.273438 8.9399L4.0001 12.6666L4.9401 11.7266L1.2201 7.9999L0.273438 8.9399Z"
                        fill="#008B46"
                      />
                    </svg>
                  </div>
                  <div
                    className={`cursor-pointer w-full py-3.5 px-8 flex flex-row items-center justify-center space-x-2 text-primary-green-700 font-inter text-sm font-semibold`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.00714 4.51527C2.64663 5.63569 1.83333 6.96551 1.83333 8.00065C1.83333 9.0358 2.64663 10.3656 4.00714 11.486C5.34588 12.5885 7.03426 13.334 8.5 13.334C9.96574 13.334 11.6541 12.5885 12.9929 11.486C14.3534 10.3656 15.1667 9.0358 15.1667 8.00065C15.1667 6.96551 14.3534 5.63569 12.9929 4.51527C11.6541 3.41278 9.96574 2.66732 8.5 2.66732C7.03426 2.66732 5.34588 3.41278 4.00714 4.51527ZM3.15953 3.48603C4.65412 2.25519 6.63241 1.33398 8.5 1.33398C10.3676 1.33398 12.3459 2.25519 13.8405 3.48603C15.3133 4.69894 16.5 6.36913 16.5 8.00065C16.5 9.63217 15.3133 11.3024 13.8405 12.5153C12.3459 13.7461 10.3676 14.6673 8.5 14.6673C6.63241 14.6673 4.65412 13.7461 3.15953 12.5153C1.68671 11.3024 0.5 9.63217 0.5 8.00065C0.5 6.36913 1.68671 4.69894 3.15953 3.48603ZM4.5 8.00065C4.5 5.79163 6.29098 4.00065 8.5 4.00065C10.709 4.00065 12.5 5.79163 12.5 8.00065C12.5 10.2097 10.709 12.0007 8.5 12.0007C6.29098 12.0007 4.5 10.2097 4.5 8.00065ZM8.5 5.33398C7.02736 5.33398 5.83333 6.52801 5.83333 8.00065C5.83333 9.4733 7.02736 10.6673 8.5 10.6673C9.97264 10.6673 11.1667 9.4733 11.1667 8.00065C11.1667 6.52801 9.97264 5.33398 8.5 5.33398Z"
                        fill="#008B46"
                      />
                    </svg>
                    <p className="underline underline-offset-2">
                      View Response
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className={`rounded-[12px] cursor-pointer mt-10 w-full bg-primary-neutral-400 py-3.5 px-6 flex lg:hidden flex-row items-center justify-center space-x-4 text-primary-neutral-50 font-inter text-base font-semibold`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M8.49984 1.83398C6.66184 1.83398 5.1665 3.32932 5.1665 5.16732V7.16732H4.49984C3.7645 7.16732 3.1665 7.76532 3.1665 8.50065V13.834C3.1665 14.5693 3.7645 15.1673 4.49984 15.1673H12.4998C13.2352 15.1673 13.8332 14.5693 13.8332 13.834V8.50065C13.8332 7.76532 13.2352 7.16732 12.4998 7.16732H11.8332V5.16732C11.8332 3.32932 10.3378 1.83398 8.49984 1.83398ZM12.4998 8.50065L12.5012 13.834H4.49984V8.50065H12.4998ZM6.49984 7.16732V5.16732C6.49984 4.06465 7.39717 3.16732 8.49984 3.16732C9.6025 3.16732 10.4998 4.06465 10.4998 5.16732V7.16732H6.49984Z"
                      fill="white"
                    />
                  </svg>
                  <p>Start Now</p>
                </div>
              )}
            </div>
            {item?.unlocked ? (
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #2BB656 0%, #008B46 100%)",
                }}
                className={`rounded-[12px] cursor-pointer w-full shadow-md py-3.5 px-8 hidden lg:flex flex-row items-center justify-center space-x-2 text-white font-inter text-sm font-semibold`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M8.49984 1.83398C6.66184 1.83398 5.1665 3.32932 5.1665 5.16732V7.16732H4.49984C3.7645 7.16732 3.1665 7.76532 3.1665 8.50065V13.834C3.1665 14.5693 3.7645 15.1673 4.49984 15.1673H12.4998C13.2352 15.1673 13.8332 14.5693 13.8332 13.834V8.50065C13.8332 7.76532 13.2352 7.16732 12.4998 7.16732H11.8332V5.16732C11.8332 3.32932 10.3378 1.83398 8.49984 1.83398ZM12.4998 8.50065L12.5012 13.834H4.49984V8.50065H12.4998ZM6.49984 7.16732V5.16732C6.49984 4.06465 7.39717 3.16732 8.49984 3.16732C9.6025 3.16732 10.4998 4.06465 10.4998 5.16732V7.16732H6.49984Z"
                    fill="white"
                  />
                </svg>
                <p>Start Now</p>
              </div>
            ) : item?.completed ? (
              <div className="hidden lg:flex flex-col w-full">
                <div className="flex flex-row items-center space-x-1">
                  <div className="w-11/12 h-1.5 bg-primary-green-700" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M12.0001 4.66656L11.0601 3.72656L6.83344 7.95323L7.77344 8.89323L12.0001 4.66656ZM14.8268 3.72656L7.77344 10.7799L4.98677 7.9999L4.04677 8.9399L7.77344 12.6666L15.7734 4.66656L14.8268 3.72656ZM0.273438 8.9399L4.0001 12.6666L4.9401 11.7266L1.2201 7.9999L0.273438 8.9399Z"
                      fill="#008B46"
                    />
                  </svg>
                </div>
                <div
                  className={`cursor-pointer w-full py-3.5 px-8 flex flex-row items-center justify-center space-x-2 text-primary-green-700 font-inter text-sm font-semibold`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.00714 4.51527C2.64663 5.63569 1.83333 6.96551 1.83333 8.00065C1.83333 9.0358 2.64663 10.3656 4.00714 11.486C5.34588 12.5885 7.03426 13.334 8.5 13.334C9.96574 13.334 11.6541 12.5885 12.9929 11.486C14.3534 10.3656 15.1667 9.0358 15.1667 8.00065C15.1667 6.96551 14.3534 5.63569 12.9929 4.51527C11.6541 3.41278 9.96574 2.66732 8.5 2.66732C7.03426 2.66732 5.34588 3.41278 4.00714 4.51527ZM3.15953 3.48603C4.65412 2.25519 6.63241 1.33398 8.5 1.33398C10.3676 1.33398 12.3459 2.25519 13.8405 3.48603C15.3133 4.69894 16.5 6.36913 16.5 8.00065C16.5 9.63217 15.3133 11.3024 13.8405 12.5153C12.3459 13.7461 10.3676 14.6673 8.5 14.6673C6.63241 14.6673 4.65412 13.7461 3.15953 12.5153C1.68671 11.3024 0.5 9.63217 0.5 8.00065C0.5 6.36913 1.68671 4.69894 3.15953 3.48603ZM4.5 8.00065C4.5 5.79163 6.29098 4.00065 8.5 4.00065C10.709 4.00065 12.5 5.79163 12.5 8.00065C12.5 10.2097 10.709 12.0007 8.5 12.0007C6.29098 12.0007 4.5 10.2097 4.5 8.00065ZM8.5 5.33398C7.02736 5.33398 5.83333 6.52801 5.83333 8.00065C5.83333 9.4733 7.02736 10.6673 8.5 10.6673C9.97264 10.6673 11.1667 9.4733 11.1667 8.00065C11.1667 6.52801 9.97264 5.33398 8.5 5.33398Z"
                      fill="#008B46"
                    />
                  </svg>
                  <p className="underline underline-offset-2">View Response</p>
                </div>
              </div>
            ) : (
              <div
                className={`hidden w-full rounded-[12px] cursor-pointer bg-primary-neutral-400 py-3.5 px-6 lg:flex fle-row items-center justify-center space-x-4 text-primary-neutral-50 font-inter text-base font-semibold`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M8.49984 1.83398C6.66184 1.83398 5.1665 3.32932 5.1665 5.16732V7.16732H4.49984C3.7645 7.16732 3.1665 7.76532 3.1665 8.50065V13.834C3.1665 14.5693 3.7645 15.1673 4.49984 15.1673H12.4998C13.2352 15.1673 13.8332 14.5693 13.8332 13.834V8.50065C13.8332 7.76532 13.2352 7.16732 12.4998 7.16732H11.8332V5.16732C11.8332 3.32932 10.3378 1.83398 8.49984 1.83398ZM12.4998 8.50065L12.5012 13.834H4.49984V8.50065H12.4998ZM6.49984 7.16732V5.16732C6.49984 4.06465 7.39717 3.16732 8.49984 3.16732C9.6025 3.16732 10.4998 4.06465 10.4998 5.16732V7.16732H6.49984Z"
                    fill="white"
                  />
                </svg>
                <p>Start Now</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default LearnMore;
