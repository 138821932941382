import { useDispatch, useSelector } from "react-redux";
import {
  achievements,
  moreContext,
  finalStep,
  projectAspects,
} from "helpers/constants/achievementsForm";
import { IoMdAdd, IoMdAddCircle, IoMdCheckmark } from "react-icons/io";
import Card from "./Card";
import { useEffect, useMemo, useState } from "react";
import { addLastStep, setValue } from "redux/achievementsForm";
import { FiTrash } from "react-icons/fi";
import { MdBolt, MdDoneAll } from "react-icons/md";
import Booster from "./Booster";
import { getBoosterCount, listBoosterOptions } from "helpers/utils";
import { useToaster, Notification } from "rsuite";
import { BsExclamationCircleFill } from "react-icons/bs";

export const StepFour = () => {
  const {
    aspect,
    projectName,
    roles: storeRoles,
    currentRoleIdx,
    achievements: storeAchievements,
  } = useSelector((state) => state.achievementsForm);
  const availableRoles = useSelector((state) => state?.roles?.list);

  const dispatch = useDispatch();
  const list = useSelector((state) => state?.roles?.list);

  const handleUpdate = (option) => {
    let copy = { ...storeAchievements };
    let item = copy[storeRoles[currentRoleIdx]]?.find(
      (a) => a.construct_id === option?._id
    );

    if (item) {
      copy[storeRoles[currentRoleIdx]] = copy[
        storeRoles[currentRoleIdx]
      ]?.filter((a) => a.construct_id !== option?._id);
    } else {
      if (!copy[storeRoles[currentRoleIdx]])
        copy[storeRoles[currentRoleIdx]] = [];
      copy[storeRoles[currentRoleIdx]] = [
        ...copy[storeRoles[currentRoleIdx]],
        {
          construct_id: option?._id,
          timeOfCompletion: null,
        },
      ];
    }

    dispatch(setValue({ key: "achievements", value: copy }));
  };

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="mx-auto text-center">
        <h1 className="text-black font-bold text-2xl md:text-3xl font-satoshi mb-4 tracking-[1.2px] capitalize">
          {achievements.title}{" "}
          <span className="text-primary-accent-700">
            “
            {
              list?.find((role) => role._id === storeRoles[currentRoleIdx])
                ?.name
            }
            ”
          </span>
        </h1>
        <p className="font-lato font-normal text-sm md:text-base text-primary-neutral-500 tracking-[0.65px] mb-4">
          {achievements.subtitle}
        </p>
        <div className="flex items-center flex-wrap justify-center gap-4">
          <div className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
            {projectName}
          </div>
          <div className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
            {projectAspects.options.find((a) => a.id === aspect)?.title}
          </div>
          {storeRoles?.map((r, i) => (
            <div
              key={i}
              className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold"
            >
              {list?.find((role) => role._id === r)?.name}
            </div>
          ))}
        </div>
      </div>
      {scrollY > 100 && (
        <div className="fixed top-[56px] left-1/2 transform -translate-x-1/2 z-50 text-primary-neutral-500 bg-primary-neutral-100 px-4 py-1 text-sm rounded-full">
          {list?.find((role) => role._id === storeRoles[currentRoleIdx])?.name}
        </div>
      )}
      <div className="pt-20 pb-10 w-full max-w-6xl step-4">
        {availableRoles
          ?.find((role) => role._id === storeRoles[currentRoleIdx])
          ?.valueConstructs?.map((op, i) => {
            const isActive = storeAchievements[
              storeRoles[currentRoleIdx]
            ]?.some((r) => r.construct_id === op._id);
            return (
              <div
                className="flex sm:p-6 gap-12 max-md:mb-32 flex-col md:opacity-40 md:hover:opacity-100 transition-all"
                key={i}
              >
                <div className="flex gap-4 md:gap-6 items-start md:items-center font-lato">
                  <div className="relative max-md:top-2">{op.icon}</div>
                  <div>
                    <p className="text-primary-accent-500 font-lato font-semibold text-[20px] md:text-2xl">
                      {op?.niche}
                    </p>
                    <p className="text-primary-neutral-500 font-lato font-normal text-[12px] md:text-sm leading-5">
                      {op?.subheading}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col md:pl-10 md:pr-20 py-0">
                  <div
                    className={`flex gap-4 items-start font-lato p-1 py-4 md:px-8 cursor-pointer  md:hover:bg-primary-neutral-100 rounded-md md:rounded-[16px] transition-all`}
                    onClick={() => handleUpdate(op)}
                  >
                    <div className="w-max">
                      {isActive ? (
                        <IoMdCheckmark className="h-4 w-4 max-md:relative max-md:top-1 md:h-6 md:w-6 text-primary-accent-700 font-bold" />
                      ) : (
                        <IoMdAdd className="h-4 w-4 max-md:relative max-md:top-1 md:h-6 md:w-6 !text-primary-neutral-300" />
                      )}
                    </div>
                    <p
                      className={`w-full font-lato font-normal text-base leading-[150%] tracking-[0.64px] md:text-lg ${
                        isActive
                          ? "!text-primary-accent-700 font-bold active"
                          : "text-primary-neutral-500 font-normal"
                      }`}
                    >
                      {op?.responsibilityStatement}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export const StepFive = () => {
  const {
    aspect,
    projectName,
    roles: storeRoles,
    moreContext: storeMoreCtx,
    boosters,
  } = useSelector((state) => state.achievementsForm);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const list = useSelector((state) => state?.roles?.list);

  const [selectedBoosters, setSelectedBoosters] = useState([]);
  const toaster = useToaster();

  const handleChange = (boosterId, optionId) => {
    if (
      !selectedBoosters
        .find((b) => b.booster_id === boosterId)
        ?.selectedOptions?.includes(optionId) &&
      getBoosterCount(selectedBoosters) >= 4
    ) {
      toaster.push(
        <Notification
          style={{
            background: "#363430",
            borderRadius: 16,
            zIndex: 999,
          }}
        >
          <div className="flex items-center gap-3 text-white font-bold font-lato">
            <BsExclamationCircleFill /> You cannot add more than 4 boosters
          </div>
        </Notification>,
        {
          placement: "topEnd",
        }
      );
      return;
    }

    const selectedBooster = {
      ...selectedBoosters.find((b) => b.booster_id === boosterId),
    };
    if (!selectedBooster.booster_id) {
      setSelectedBoosters([
        ...selectedBoosters,
        { booster_id: boosterId, selectedOptions: [optionId] },
      ]);
      return;
    }
    if (selectedBooster.selectedOptions.includes(optionId)) {
      selectedBooster.selectedOptions = selectedBooster.selectedOptions.filter(
        (id) => id !== optionId
      );
    } else {
      selectedBooster.selectedOptions = [
        ...selectedBooster.selectedOptions,
        optionId,
      ];
    }

    const updatedBoosters = selectedBoosters.map((s) =>
      s.booster_id === boosterId ? selectedBooster : s
    );
    setSelectedBoosters(updatedBoosters);
  };

  const handleUpdate = (type, value) => {
    const cp = { ...storeMoreCtx };
    cp[type] = value;

    dispatch(setValue({ key: "moreContext", value: cp }));
  };
  const handleSaveBoosters = () => {
    handleUpdate("boosters", selectedBoosters);
    setIsOpen(false);
  };
  const count = useMemo(() => {
    return getBoosterCount(storeMoreCtx.boosters);
  }, [storeMoreCtx, storeMoreCtx.boosters]);

  return (
    <>
      <Booster
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        handleChange={handleChange}
        selectedBoosters={selectedBoosters}
        handleSaveBoosters={handleSaveBoosters}
        count={count}
        boosters={boosters}
      />
      <div className="mx-auto text-center">
        <h1 className="text-black font-bold text-3xl font-satoshi mb-4 tracking-[1.2px] capitalize">
          {moreContext.title}
        </h1>
        <p className="font-lato font-normal text-primary-neutral-500 tracking-[0.65px] mb-4">
          {moreContext.subtitle}
        </p>
        <div className="flex items-center justify-center flex-wrap gap-4">
          <div className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
            {projectName}
          </div>
          <div className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold">
            {projectAspects.options.find((a) => a.id === aspect)?.title}
          </div>
          {storeRoles?.map((r, i) => (
            <div
              key={i}
              className="py-2.5 px-5 inline-flex items-center justify-center rounded-[48px] bg-primary-neutral-100 text-primary-accent-700 text-xs font-lato tracking-wider font-semibold"
            >
              {list?.find((role) => role._id === r)?.name}
            </div>
          ))}
        </div>
      </div>
      <div className="py-20 w-full max-w-[680px] flex flex-col gap-20">
        {moreContext.options.map((c) => {
          switch (c.type) {
            case "boosters":
              return (
                <div>
                  <h3 className="mb-6 text-primary-neutral-800 text-xl font-semibold">
                    {c.title}
                  </h3>

                  {count === 0 ? (
                    <div className="p-4 border border-dotted border-primary-neutral-500 rounded-2xl flex flex-col items-center justify-center gap-4">
                      <p className="text-primary-neutral-500 text-sm">
                        Insights help make your achievements stand out
                      </p>
                      <div
                        className="flex flex-col items-center gap-2 cursor-pointer"
                        onClick={() => setIsOpen(true)}
                      >
                        <IoMdAddCircle className="h-10 w-10 fill-primary-accent-700" />
                        <p className="font-satoshi font-bold leading-5 text-sm tracking-[0.21px] cursor-pointer">
                          Add Now
                        </p>
                        <p className="text-primary-neutral-400 text-xs">
                          ({count}/4 equipped)
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex justify-between items-center gap-1 mb-4">
                        <p className="font-satoshi text-xs text-primary-neutral-800 font-normal capitalize">
                          ({count}/4 Equipped)
                        </p>
                        <div className="flex gap-2 items-start">
                          {[...Array(4)].map((_, i) => {
                            return (
                              <div
                                className={`h-2 w-2 border rounded-full ${
                                  count > i
                                    ? "bg-primary-accent-500 border-primary-accent-500"
                                    : "border-primary-neutral-200"
                                }`}
                                key={i}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                      {listBoosterOptions(storeMoreCtx.boosters, boosters)?.map(
                        (option) => {
                          return (
                            <div
                              key={option.option_id}
                              className="flex items-center p-2 group w-full justify-between hover:bg-primary-neutral-50 cursor-pointer rounded"
                            >
                              <div className="flex items-center gap-2">
                                <MdBolt
                                  className={`bg-primary-neutral-200 rounded-full text-black p-0.5 text-xl `}
                                />
                                <span className="text-primary-neutral-800 font-normal text-sm">
                                  {option.title}
                                </span>
                              </div>
                              <input
                                type="checkbox"
                                checked
                                className="w-4 h-4 rounded-sm border-2 border-primary-neutral-300 group-hover:border-primary-neutral-500 !outline-none !ring-none focus:!ring-none checked:bg-primary-accent-500 checked:!border-primary-accent-500 cursor-pointer"
                              />
                            </div>
                          );
                        }
                      )}
                      <div
                        className="border border-dotted border-primary-neutral-200 p-3 flex items-center justify-center gap-2 rounded-xl mt-4"
                        onClick={() => setIsOpen(true)}
                      >
                        <IoMdAddCircle className="h-5 w-5 fill-primary-accent-700" />
                        <p className="font-satoshi font-semibold leading-5 text-sm tracking-[0.21px] text-primary-accent-700 cursor-pointer">
                          Add Now
                        </p>
                      </div>
                    </>
                  )}
                </div>
              );
            case "radio":
              return (
                <div>
                  <h3 className="mb-6 text-primary-neutral-800 text-xl font-semibold">
                    {c.title}
                  </h3>
                  <div className="flex justify-center flex-col md:flex-row items-start gap-6">
                    {c?.opts?.map((o, j) => (
                      <div
                        key={j}
                        onClick={() => handleUpdate("category", o.title)}
                        className={`flex w-full max-md:h-[96px] md:w-1/3 py-6 px-4 md:flex-col items-center gap-6 rounded-[24px] cursor-pointer border border-primary-neutral-200 hover:border-primary-neutral-200 hover:bg-primary-neutral-50 ${
                          storeMoreCtx?.category === o.title
                            ? "!border-primary-accent-500 !bg-primary-accent-50"
                            : ""
                        }`}
                      >
                        {o.icon}
                        <div className="md:text-center">
                          <p className="text-primary-neutral-800 font-lato font-semibold md:text-lg">
                            {o.title}
                          </p>
                          <p className="text-primary-neutral-400 font-lato font-normal text-xs md:text-sm leading-5">
                            {o.subtitle}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            case "text":
              let label = c.label.toLowerCase();
              if (label.includes("company")) label = "company";
              if (c.show !== storeMoreCtx?.category) return null;
              return (
                <div>
                  <h3 className="mb-6 text-primary-neutral-800 text-xl font-semibold">
                    {c.title}
                  </h3>
                  <div className="max-w-md flex flex-col gap-2">
                    <label
                      htmlFor="projectName"
                      className="text-sm font-medium font-lato text-primary-gray-500 tracking-wider"
                    >
                      {c.label}
                    </label>
                    <input
                      type="text"
                      id="projectName"
                      placeholder="Start typing..."
                      value={storeMoreCtx?.[label]}
                      onChange={(e) => {
                        handleUpdate(label, e.target.value);
                      }}
                      className="p-3 w-full border-0 focus:ring-0 border-b border-b-primary-neutral-200 placeholder:text-primary-neutral-300 text-lg"
                    />
                  </div>
                </div>
              );
            case "textarea":
              return (
                <div>
                  <h3 className="mb-6 text-primary-neutral-800 text-xl font-semibold">
                    {c.title}
                  </h3>
                  <div className="max-w-md flex flex-col gap-3">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="projectName"
                        className="text-sm font-medium font-lato text-primary-neutral-500 tracking-wider"
                      >
                        {c.label}
                      </label>
                      <span className="text-xs font-normal font-lato text-primary-neutral-400 tracking-wider">
                        (Optional)
                      </span>
                    </div>
                    <textarea
                      type="text"
                      id="projectName"
                      placeholder="Start typing..."
                      value={storeMoreCtx?.description}
                      onChange={(e) =>
                        handleUpdate("description", e.target.value)
                      }
                      className="p-3 w-full rounded-lg focus:ring-0 border border-primary-neutral-200 placeholder:text-primary-neutral-300 text-lg"
                    ></textarea>
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

export const StepSix = ({ setStep }) => {
  const { achievements: storeAchievements, lastStep } = useSelector(
    (state) => state.achievementsForm
  );
  const availableRoles = useSelector((state) => state?.roles?.list);

  const dispatch = useDispatch();
  const list = useSelector((state) => state?.roles?.list);

  useEffect(() => {
    dispatch(setValue({ key: "lastStep", value: [] })); // Resets the cards array
    Object.keys(storeAchievements).forEach((roleId) => {
      const cardRole = list?.find((role) => role._id === roleId)?.name;
      const item = availableRoles?.find((cnst) => cnst._id === roleId);
      storeAchievements[roleId]?.forEach((construct) => {
        const selected = item?.valueConstructs?.find(
          (s) => s._id === construct.construct_id
        );
        dispatch(
          addLastStep({
            time: construct?.timeOfCompletion
              ? new Date(construct?.timeOfCompletion)
              : null,
            roleId,
            cardRole,
            value: selected?.responsibilityStatement,
            techSkills: selected?.technicalSkills || [],
            serviceName: selected?.name,
            construct_id: selected?._id,
          })
        );
      });
    });
  }, []);

  const handleDelete = (card) => {
    const copy = { ...storeAchievements };
    console.log(card);
    console.log(
      storeAchievements[card.roleId]?.find(
        (item) => item.construct_id === card.construct_id
      )
    );
    copy[card.roleId] = copy[card.roleId]?.filter(
      (item) => item.construct_id !== card.construct_id
    );
    if (copy[card.roleId]?.length === 0) {
      delete copy[card.roleId];
    }
    dispatch(setValue({ key: "achievements", value: copy }));

    let lastStepCopy = [...lastStep];
    lastStepCopy = lastStepCopy?.filter(
      (item) =>
        item.construct_id !== card.construct_id && item.roleId !== card.roleId
    );
    dispatch(setValue({ key: "lastStep", value: lastStepCopy }));
  };

  return (
    <>
      <div className="mx-auto text-center max-md:px-4">
        <h1 className="text-black font-bold text-[20px] font-lato mb-4 tracking-[1.2px] capitalize">
          {finalStep.title}
        </h1>
        <p className="font-normal font-satoshi text-sm text-primary-neutral-500 tracking-[0.65px] mb-4">
          {finalStep.subtitle}
        </p>
      </div>
      <div className="mt-10">
        <button
          className="py-[18px] px-10 border border-dashed border-primary-accent-500 rounded-2xl flex items-center gap-4 mx-auto"
          onClick={() => setStep(4)}
        >
          <IoMdAdd className="bg-primary-accent-700 rounded-full text-white p-0.5 text-xl cursor-pointer" />
          <span className="text-primary-accent-700 font-bold tracking-[0.8px]">
            Add More Achievements
          </span>
        </button>
      </div>
      <ul className="flex-col my-16 hidden md:flex ">
        {lastStep?.map((card, i) => {
          return (
            <li className="flex gap-2 max-w-[800px] mb-8" key={i}>
              <Card index={i} card={card} />
              <p className="text-primary-neutral-400 font-normal text-sm">
                {i + 1}/{lastStep.length}
              </p>
            </li>
          );
        })}
      </ul>
      {/* Mobile */}
      <div className="block md:hidden max-w-[100vw] w-full py-4">
        <div className="w-full mt-4 mb-16 mobile-slider">
          {lastStep?.map((card, i) => {
            return (
              <>
                <div>
                  <div className="h-2 bg-primary-neutral-200"></div>
                  <div className="flex items-center justify-between w-full gap-2 px-4 mt-10">
                    <p className="text-xs font-normal capitalize mt-0">
                      {card?.time ? (
                        <span className="italic text-[#2BB656] flex items-center gap-1">
                          <MdDoneAll size={12} />
                          Date Added
                        </span>
                      ) : (
                        <span className="italic text-[#F57D34]">
                          Date Missing
                        </span>
                      )}
                    </p>
                    <div className="text-center text-primary-neutral-500 text-sm font-bold">
                      {i + 1}/{lastStep?.length}
                    </div>
                    <div className="w-min flex items-center justify-center cursor-pointer gap-2 p-2 rounded-full border border-primary-neutral-100">
                      <FiTrash
                        className="h-5 w-5 text-caaryaRed"
                        onClick={() => handleDelete(card)}
                      />
                    </div>
                  </div>
                  <div className="p-5 h-full">
                    <Card card={card} index={i} />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
