import { Text } from 'helpers/constants/Text'
import React from 'react'
import { MdNorthEast } from 'react-icons/md'
import { List } from '../List/List'
import { Img } from '../Img/Img'
import Button from 'helpers/constants/button'

const Tools = () => {
  return (
   <>
   <div className="flex flex-col font-manrope gap-2 items-start mt-6 justify-start w-3/4">
              <div className="flex flex-row gap-2 items-center justify-between w-full">
                <Text
                  className="text-blue_gray-900 text-center text-lg tracking-[0.90px] w-auto"
                  size="txtManropeSemiBold18"
                >
                  Tools Unlocked
                </Text>
                <div className="flex flex-row font-satoshi gap-2 items-center justify-start px-4 w-auto">
                  <a
                    href="caarya:"
                    className="text-gray-700 text-sm tracking-[0.70px] underline w-auto"
                  >
                    <Text size="txtSatoshiBold14Gray700">Learn More</Text>
                  </a>
                  <MdNorthEast />
                </div>
              </div>
              <div className="flex flex-col font-satoshi items-center justify-center p-4 mt-6 pt-13 w-full">
                <List
                  className="flex flex-col gap-10 items-center w-[85%]"
                  orientation="vertical"
                >
                  <div className="gap-5 grid sm:grid-cols-1 md:grid-cols-3 grid-cols-2 items-center justify-between w-full">
                    {[1, 2, 3].map((index) => (
                      <div
                        key={index}
                        className="flex flex-1 flex-col gap-2 items-center justify-center px-3 py-2 w-full"
                      >
                        <Img
                          className="h-15 w-15"
                          src="assets/svg/pass/Ellipse_1196.svg"
                          alt={`Ecllipse`}
                        />
                        <Text
                          className="text-blue_gray-900 text-sm tracking-[0.70px] w-auto"
                          size="txtSatoshiRegular14"
                        >
                          Tool Name
                        </Text>
                      </div>
                    ))}
                    {[5, 6, 7].map((index) => (
                      <div
                        key={index}
                        className="flex flex-1 flex-col gap-2 items-center justify-center px-3 py-2 w-auto"
                      >
                   
                        <div className="border-2 border-stone-300 border-solid h-20 md:h-auto p-2 relative rounded-full w-22 items-center">
                          <div className="w-16 h-16 bg-stone-300 rounded-full overflow-hidden "></div>
                          <Button
                            className="absolute flex h-7 items-center justify-center right-[0] rounded-[50%] top-[0] w-7 position-absolute "
                            color="white_A700"
                          >
                            <Img
                              className="h-5 z-10"
                              src="assets/svg/pass/lock.svg"
                              alt="lock"
                            />
                          </Button>
                        </div>

                        <Text
                          className="text-gray-500 text-sm tracking-[0.70px] w-auto"
                          size="txtSatoshiRegular14Gray500"
                        >
                          Tool Name
                        </Text>
                      </div>
                    ))}
                  </div>
                </List>
              </div>
            </div>
   </>
  )
}

export default Tools