import { createSlice } from "@reduxjs/toolkit";
import {
  getUserCollegeClan,
  getUserMentorship,
  getUserProjectMate,
} from "config/APIs/users/squad";
import { getAdmin } from "helpers/utils/common/localStorage";
import logError from "helpers/utils/common/logError";

const initialState = {
  teamMembers: [],
  mentors: [],
  mentees: [],
  collegeClan: [],
  list: [],
  fetching: true,
};

// Storing User Squad
export const squad = createSlice({
  name: "squad",
  initialState,
  reducers: {
    updateSquad: (state, action) => {
      let tab = action.payload.tab;
      let list = action.payload.list;
      state[tab] = list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSquad } = squad.actions;

export default squad.reducer;

export function fetchAllSquad() {
  return async (dispatch) => {
    let list = [];
    let { id } = getAdmin();
    try {
      const response = await getUserMentorship();

      if (response.status === 200) {
        let data = response.data.data;

        list = list.concat(data?.mentor || []);
        list = list.concat(data?.mentees || []);

        dispatch(updateSquad({ tab: "mentors", list: data?.mentor || [] }));
        dispatch(updateSquad({ tab: "mentees", list: data?.mentees || [] }));
      }
    } catch (err) {
      logError("Squad Mentorship APIs ", err);
    }

    try {
      const response = await getUserProjectMate();

      if (response.status === 200) {
        let data = response.data.data?.projects;

        let temp = [];

        data?.map((project) => {
          if (project?.owner) {
            project.owner = project?.owner?.id == id ? null : project?.owner;
          }
          if (project?.members?.length > 0) {
            project.members = project?.members?.filter((m) => m?.id !== id);
          }
          if (project?.owner || project?.members?.length > 0) {
            let obj = {
              heading: project?.title,
              people: (project?.owner ? [project?.owner] : []).concat(
                project?.members || []
              ),
            };
            list = list.concat(project?.owner ? [project?.owner] : []);
            list = list.concat(project?.members || []);
            temp.push(obj);
          }
        });

        dispatch(updateSquad({ tab: "teamMembers", list: temp || [] }));
      }
    } catch (err) {
      logError("Squad Mentorship APIs ", err);
    }

    try {
      const response = await getUserCollegeClan();

      if (response.status === 200) {
        let data = response.data.data;

        list = list.concat(data?.mates || []);

        dispatch(updateSquad({ tab: "collegeClan", list: data?.mates || [] }));
      }
    } catch (err) {
      logError("Squad Mentorship APIs ", err);
    }
    list = [...new Map(list.map((item) => [item["id"], item])).values()];

    dispatch(updateSquad({ tab: "fetching", list: false }));
    dispatch(updateSquad({ tab: "list", list: list || [] }));
  };
}
