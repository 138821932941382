import { useDispatch } from "react-redux";
import { useEffect } from "react";
import AddAchievement from "components/Achievements/Add";
import { getRoles } from "config/APIs/roles";
import { updateList } from "redux/roles";

export default function AddAchievementPage() {
  const dispatch = useDispatch();

  const fetchAllRoles = async () => {
    const {
      data: { data: response },
    } = await getRoles();
    dispatch(updateList(response));
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  return (
    <div className="pb-6 pt-[53px] md:py-20 md:px-32 min-h-screen max-w-[100vw] bg-white">
      <AddAchievement />
    </div>
  );
}
