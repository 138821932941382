import { Drawer } from "@mui/material";

import React, { useEffect, useState } from "react";
import { ReactComponent as Emoji } from "../../assets/FocusArea/Emoji5.svg";
import { MdClose } from "react-icons/md";

import { SiTarget } from "react-icons/si";

function SprintDrawer({ isOpen, closeModal }) {
  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={500}
      PaperProps={{
        style: {
          width: window.innerWidth < 1024 ? "100%" : "100vw",
          height: window.innerWidth < 1024 ? "" : "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          boxShadow: "none",
        },
      }}
    >
      <div className="flex flex-row items-end justify-between lg:justify-end h-[100vh]">
        <div className="  justify-center  w-[100vh] lg:w-[720px] lg:h-screen relative rounded-t-[32px] lg:rounded-t-none">
          <div className="z-40 flex cursor-pointer flex-row items-center justify-center absolute -top-16 lg:top-[50%] lg:-left-20 left-0 right-0 w-full lg:max-w-max">
            <div
              onClick={() => {
                closeModal();
              }}
              className="text-white flex flex-row items-center justify-center h-10 w-10 bg-black rounded-full"
            >
              <MdClose className="w-[14px] h-[14px] text-[white]" />
            </div>
          </div>
          <div className="md:max-w-xl lg:h-screen   rounded-t-[32px] lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150  ">
            <div
              style={{
                boxShadow:
                  "0 -4px 6px -4px rgba(0, 0, 0, 0.1), 0 -8px 15px -3px rgba(0, 0, 0, 0.1)",
              }}
              className="flex items-center p-6 space-y-2 justify-between border-b-[1px] border-[#CFCDC9]  font-medium rounded-t-[32px] "
            >
              <div className="flex items-center">
                <p className="font-satoshi   flex font-bold text-base text-[#363430]">
                  Session Details
                </p>
              </div>
              <div className="flex items-center pb-1.5">
                <p className="font-satoshi flex font-normal text-sm text-[#363430]">
                  sat, 29 Mar
                </p>
              </div>
            </div>
            <div className="py-8 px-6  h-[512px] ">
              <div className=" flex flex-col space-y-[40px]">
                <div className=" px-4  w-[312px] space-x-2 items-center py-[14px] h-[52px]  rounded-2xl border-[1px]  border-[#E7E6E5] bg-white   flex      ">
                  <div>
                    <SiTarget className="text-primary-red-500  w-[20px] h-[20px]" />
                  </div>
                  <div className="font-lato font-semibold truncate text-sm font-black">
                    Goal Title to be shown here in a truncated manner
                  </div>
                </div>
                <div className="flex flex-row justify-between space-x-8">
                  <div className="flex flex-col  justify-start space-y-4">
                    <div className="space-x-2 flex items-center flex-row">
                      <div className="text-[#9C9A96] bg-[#F3F2F2] px-2 py-1 rounded-md">
                        <p className="text-[#363430] font-lato font-bold text-[10px]">
                          Session 1/4
                        </p>
                      </div>
                      <div>
                        <p className="text-[#9C9A96] font-lato font-bold text-[10px]">
                          11:00am - 01:15pm
                        </p>
                      </div>
                    </div>
                    <div className="flex px-4 flex-row justify-center items-end space-x-2">
                      <div>
                        <p className="font-lato font-bold text-base text-[black]">
                          1h30m
                        </p>
                      </div>
                      <div>
                        <p className="font-lato font-normal text-[#ED4C41] text-base">
                          +45m30s
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="justify-end   items-center flex">
                    <Emoji className="w-[40px] h-[40px]" />
                  </div>
                </div>
                <div className="flex flex-col space-y-4">
                  <div>
                    <p className="text-[#9C9A96] font-satoshi font-bold text-xs">
                      Work done in session
                    </p>
                  </div>
                  <div>
                    <p className="text-base font-lato font-normal text-[#363430]">
                      Any additional comments that might have been added appear
                      here in full details.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default SprintDrawer;
