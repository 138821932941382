import { DividerTabs } from "components/Common/Tabs";
import Explore from "components/Space/Explore";
import MySpace from "components/Space/MySpace";
import React, { useState } from "react";

function Space() {
  const [selectedTab, setSelectedTab] = useState("my");
  return (
    <div className="pt-[56px]">
      <DividerTabs
        tabs={[
          { label: "My Space", value: "my" },
          { label: "Explore", value: "explore" },
        ]}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab == "my" && <MySpace />}
      {selectedTab == "explore" && <Explore />}
    </div>
  );
}

export default Space;
