import { useEffect, useState } from "react";
import ClockInStreak from "./ClockInStreakFooter";
import ProgressTimerBar from "./ProgressTimerBar";
import RecentSessions from "./RecentSessions";
import RecentCompletedGoals from "./RecentCompletedGoal";
import moment from "moment";
import { useSelector } from "react-redux";
import { TASK_STATUS_COMPLETED } from "helpers/task";

function SprintGoalsSection({ sprint }) {
  return (
    <div className="py-6 px-4 space-y-10 bg-[#fafafa]">
      <DailyStreak />
      <SprintProgress sprint={sprint} />
      <RecentSessions sprint={sprint} />
      <Stats sprint={sprint} />
      <RecentCompletedGoals />
    </div>
  );
}

export default SprintGoalsSection;

function DailyStreak({ sprint }) {
  const list = useSelector((state) => state?.journals?.journals)?.filter(
    (a) =>
      moment(sprint?.startDate).unix() < moment(a?.startTime).unix() &&
      moment(sprint?.endDate).unix() > moment(a?.endTime).unix()
  );
  const [sortedOptions, setSortedOptions] = useState([]);
  useEffect(() => {
    let start = moment().startOf("week");
    let end = moment().endOf("week");
    let temp = [];
    while (moment(start).unix() < moment(end).unix()) {
      temp.push({
        date: moment(start),
        title: moment(start).format("dddd"),
        day: moment(start).format("dddd").substring(0, 1),
        status:
          moment(start).format("ll") == moment().format("ll")
            ? "active"
            : moment(start).unix() > moment().unix()
            ? "upcoming"
            : list?.filter(
                (a) =>
                  moment(a?.startTime).format("ll") == moment().format("ll")
              )
            ? "completed"
            : "missed",
      });
      start = moment(start).add(1, "day");
    }
    setSortedOptions(temp);
  }, []);

  function countConsecutiveCompleted() {
    let maxConsecutiveUpcoming = 0;
    let currentStreak = 0;

    sortedOptions.forEach((event) => {
      if (event.status === "completed") {
        currentStreak++;
        if (currentStreak > maxConsecutiveUpcoming) {
          maxConsecutiveUpcoming = currentStreak;
        }
      } else {
        currentStreak = 0;
      }
    });

    return maxConsecutiveUpcoming;
  }

  return (
    <div className="p-4 flex w-full flex-col space-y-2 bg-white rounded-lg">
      <p className="text-primary-neutral-500 font-satoshi text-2xs tracking-[0.5px] leading-4">
        You have been progressing daily for
      </p>

      <div className="flex flex-row items-center space-x-3">
        {countConsecutiveCompleted() == 0 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.395 2.55335C12.3316 2.42638 12.2419 2.31436 12.1319 2.22473C12.0219 2.1351 11.8941 2.06991 11.7569 2.03349C11.6198 1.99707 11.4764 1.99026 11.3364 2.01352C11.1965 2.03677 11.063 2.08955 10.945 2.16835C10.6 2.39835 10.331 2.72635 10.123 3.04835C9.90901 3.37835 9.72001 3.76135 9.55301 4.16435C9.21901 4.96835 8.93901 5.93235 8.71301 6.89835C8.43969 8.07884 8.23502 9.27417 8.10001 10.4783C7.69314 10.216 7.36622 9.84693 7.15501 9.41135C6.82701 8.73135 6.75701 7.87735 6.75701 6.75735C6.75696 6.5596 6.69829 6.3663 6.58841 6.20189C6.47852 6.03748 6.32236 5.90934 6.13966 5.83367C5.95696 5.758 5.75593 5.7382 5.56197 5.77676C5.36802 5.81533 5.18986 5.91054 5.05001 6.05035C4.39917 6.69978 3.88303 7.47139 3.53123 8.32085C3.17943 9.17032 2.9989 10.0809 3.00001 11.0003C3.00009 12.1515 3.28405 13.2848 3.82674 14.3C4.36944 15.3151 5.15411 16.1808 6.11125 16.8203C7.0684 17.4598 8.16849 17.8534 9.31407 17.9662C10.4596 18.079 11.6154 17.9075 12.6789 17.467C13.7423 17.0264 14.6808 16.3304 15.4111 15.4406C16.1413 14.5508 16.6409 13.4946 16.8655 12.3656C17.0901 11.2366 17.0328 10.0697 16.6987 8.96809C16.3645 7.86652 15.7639 6.86435 14.95 6.05035C14.358 5.45935 13.97 5.06535 13.602 4.58335C13.239 4.10735 12.878 3.52035 12.395 2.55335ZM12.12 15.1203C11.7005 15.5394 11.1661 15.8247 10.5846 15.9402C10.003 16.0557 9.40017 15.9963 8.85231 15.7695C8.30446 15.5426 7.83612 15.1585 7.50644 14.6657C7.17676 14.1728 7.00053 13.5933 7.00001 13.0003C7.00001 13.0003 7.87901 13.5003 9.50001 13.5003C9.50001 12.5003 10 9.50035 10.75 9.00035C11.25 10.0003 11.536 10.2933 12.121 10.8793C12.4 11.1575 12.6213 11.4881 12.7722 11.8521C12.923 12.2161 13.0005 12.6063 13 13.0003C13.0005 13.3944 12.923 13.7846 12.7722 14.1486C12.6213 14.5126 12.4 14.8432 12.121 15.1213L12.12 15.1203Z"
              fill="#CFCDC9"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.395 2.55335C12.3316 2.42638 12.2419 2.31436 12.1319 2.22473C12.0219 2.1351 11.8941 2.06991 11.7569 2.03349C11.6198 1.99707 11.4764 1.99026 11.3364 2.01352C11.1965 2.03677 11.063 2.08955 10.945 2.16835C10.6 2.39835 10.331 2.72635 10.123 3.04835C9.90901 3.37835 9.72001 3.76135 9.55301 4.16435C9.21901 4.96835 8.93901 5.93235 8.71301 6.89835C8.43969 8.07884 8.23502 9.27417 8.10001 10.4783C7.69314 10.216 7.36622 9.84693 7.15501 9.41135C6.82701 8.73135 6.75701 7.87735 6.75701 6.75735C6.75696 6.5596 6.69829 6.3663 6.58841 6.20189C6.47852 6.03748 6.32236 5.90934 6.13966 5.83367C5.95696 5.758 5.75593 5.7382 5.56197 5.77676C5.36802 5.81533 5.18986 5.91054 5.05001 6.05035C4.39917 6.69978 3.88303 7.47139 3.53123 8.32085C3.17943 9.17032 2.9989 10.0809 3.00001 11.0003C3.00009 12.1515 3.28405 13.2848 3.82674 14.3C4.36944 15.3151 5.15411 16.1808 6.11125 16.8203C7.0684 17.4598 8.16849 17.8534 9.31407 17.9662C10.4596 18.079 11.6154 17.9075 12.6789 17.467C13.7423 17.0264 14.6808 16.3304 15.4111 15.4406C16.1413 14.5508 16.6409 13.4946 16.8655 12.3656C17.0901 11.2366 17.0328 10.0697 16.6987 8.96809C16.3645 7.86652 15.7639 6.86435 14.95 6.05035C14.358 5.45935 13.97 5.06535 13.602 4.58335C13.239 4.10735 12.878 3.52035 12.395 2.55335ZM12.12 15.1203C11.7005 15.5394 11.1661 15.8247 10.5846 15.9402C10.003 16.0557 9.40017 15.9963 8.85231 15.7695C8.30446 15.5426 7.83612 15.1585 7.50644 14.6657C7.17676 14.1728 7.00053 13.5933 7.00001 13.0003C7.00001 13.0003 7.87901 13.5003 9.50001 13.5003C9.50001 12.5003 10 9.50035 10.75 9.00035C11.25 10.0003 11.536 10.2933 12.121 10.8793C12.4 11.1575 12.6213 11.4881 12.7722 11.8521C12.923 12.2161 13.0005 12.6063 13 13.0003C13.0005 13.3944 12.923 13.7846 12.7722 14.1486C12.6213 14.5126 12.4 14.8432 12.121 15.1213L12.12 15.1203Z"
              fill="url(#paint0_linear_3964_40824)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3964_40824"
                x1="10"
                y1="2"
                x2="10"
                y2="17.9998"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFBC00" />
                <stop offset="1" stop-color="#F57D34" />
              </linearGradient>
            </defs>
          </svg>
        )}
        <p className="text-primary-neutral-800 font-bold font-lato text-2xl leading-9 tracking-[1.2px]">
          {countConsecutiveCompleted()} Days
        </p>
      </div>
      <div className="py-2">
        <ClockInStreak streakDays={sortedOptions} />
      </div>
    </div>
  );
}

function SprintProgress({ sprint }) {
  const list = useSelector((state) => state?.journals?.journals);
  function calculateTotalHours() {
    let totalMilliseconds = 0;

    list
      ?.filter(
        (a) =>
          moment(sprint?.startDate).unix() < moment(a?.startTime).unix() &&
          moment(sprint?.endDate).unix() > moment(a?.endTime).unix()
      )
      ?.forEach((entry) => {
        const start = new Date(entry.startTime);
        const end = new Date(entry.endTime);
        const durationInMs = end - start;
        totalMilliseconds += durationInMs;
      });

    const totalHours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    const remainingMilliseconds = totalMilliseconds % (1000 * 60 * 60);
    const totalMinutes = Math.floor(remainingMilliseconds / (1000 * 60));

    return `${totalHours}h ${totalMinutes}m`;
  }

  return (
    <div className="">
      <div className="bg-[#5E4FBA] py-2 px-4 flex flex-row items-center space-x-6 rounded-2xl">
        <ProgressTimerBar />
        <div className="flex flex-col space-y-1">
          <p className="text-white font-lato text-xl leading-7 tracking-[1px]">
            <span className="font-bold">{calculateTotalHours()}</span>/ 40h
          </p>
          <p className="text-white font-satoshi text-2xs leading-4 tracking-[0.5px]">
            Work experience gained this sprint
          </p>
          <div className="flex flex-row items-center space-x-2 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.24992 1.66406C5.501 1.66406 5.72392 1.82473 5.80332 2.06293L8.74992 10.9027L9.94652 7.31293C10.0259 7.07473 10.2488 6.91406 10.4999 6.91406H12.8333C13.1554 6.91406 13.4166 7.17523 13.4166 7.4974C13.4166 7.81956 13.1554 8.08073 12.8333 8.08073H10.9204L9.30332 12.9319C9.22392 13.1701 9.001 13.3307 8.74992 13.3307C8.49883 13.3307 8.27592 13.1701 8.19652 12.9319L5.24992 4.09206L4.05332 7.68186C3.97392 7.92006 3.751 8.08073 3.49992 8.08073H1.16659C0.844419 8.08073 0.583252 7.81956 0.583252 7.4974C0.583252 7.17523 0.844419 6.91406 1.16659 6.91406H3.07948L4.69652 2.06293C4.77592 1.82473 4.99883 1.66406 5.24992 1.66406Z"
                fill="white"
              />
            </svg>
            <p className="text-white font-satoshi text-2xs font-bold leading-4 tracking-[0.5px]">
              +0hr Today
            </p>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 flex flex-row items-center space-x-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M10.3333 1.9974H12.9999C13.1767 1.9974 13.3463 2.06763 13.4713 2.19266C13.5963 2.31768 13.6666 2.48725 13.6666 2.66406V13.3307C13.6666 13.5075 13.5963 13.6771 13.4713 13.8021C13.3463 13.9272 13.1767 13.9974 12.9999 13.9974H0.999919C0.823108 13.9974 0.653538 13.9272 0.528514 13.8021C0.40349 13.6771 0.333252 13.5075 0.333252 13.3307V2.66406C0.333252 2.48725 0.40349 2.31768 0.528514 2.19266C0.653538 2.06763 0.823108 1.9974 0.999919 1.9974H3.66659V0.664062H4.99992V1.9974H8.99992V0.664062H10.3333V1.9974ZM12.3333 5.9974V3.33073H10.3333V4.66406H8.99992V3.33073H4.99992V4.66406H3.66659V3.33073H1.66659V5.9974H12.3333ZM12.3333 7.33073H1.66659V12.6641H12.3333V7.33073ZM2.99992 8.66406H6.33325V11.3307H2.99992V8.66406Z"
            fill="#ED4C41"
          />
        </svg>
        <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-5 tracking-[0.6px]">
          {moment(sprint?.endDate).diff(moment(), "days")} Days remaining this
          sprint
        </p>
      </div>
    </div>
  );
}

function Stats({ sprint }) {
  const journals = useSelector((state) => state?.journals?.journals)?.filter(
    (a) =>
      moment(sprint?.startDate).unix() < moment(a?.startTime).unix() &&
      moment(sprint?.endDate).unix() > moment(a?.endTime).unix()
  );
  const list = useSelector((state) => state?.goals?.goals)?.filter(
    (a) =>
      moment(sprint?.startDate).unix() < moment(a?.dueDate).unix() &&
      moment(sprint?.endDate).unix() > moment(a?.dueDate).unix()
  );
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="p-4 flex flex-col items-center space-y-2 bg-white border border-[#F3F2F2] rounded-2xl">
        <div className="flex flex-row items-center space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9.00037 0C13.9703 0 18 4.02975 18 9.00037C18 13.9703 13.9703 18 8.99963 18C4.02975 18 0 13.9703 0 8.99963C0 4.02975 4.02975 0 9.00037 0ZM9.00037 14.8695C9.77675 14.8782 10.5471 14.7328 11.2669 14.4418C11.9867 14.1507 12.6416 13.7197 13.1937 13.1738C13.7458 12.6278 14.1841 11.9778 14.4832 11.2613C14.7823 10.5448 14.9363 9.77605 14.9362 8.99962C14.9362 8.2232 14.7821 7.45449 14.4829 6.73803C14.1837 6.02157 13.7453 5.37158 13.1932 4.82571C12.641 4.27984 11.9861 3.84894 11.2662 3.55795C10.5464 3.26696 9.776 3.12167 8.99963 3.1305C5.78498 3.16845 3.19897 5.7852 3.19897 9C3.19897 12.2148 5.78572 14.8316 9.00037 14.8695ZM9.00037 11.8805C8.62092 11.8825 8.24482 11.8094 7.89368 11.6655C7.54255 11.5217 7.22331 11.3098 6.95431 11.0422C6.6853 10.7745 6.47184 10.4564 6.32617 10.106C6.18051 9.7556 6.10553 9.37988 6.10553 9.00041C6.10553 8.62095 6.18051 8.24523 6.32617 7.89483C6.47184 7.54444 6.6853 7.22628 6.95431 6.95865C7.22331 6.69101 7.54255 6.47917 7.89368 6.3353C8.24482 6.19143 8.62092 6.11836 9.00037 6.1203C10.5907 6.12075 11.8796 7.41007 11.8796 9.00037C11.8796 10.5907 10.5907 11.8801 9.00037 11.8805Z"
              fill="#ED4C41"
            />
          </svg>
          <p className="text-primary-neutral-800 font-lato text-xl font-bold leading-7 tracking-[1px]">
            {list?.filter(
              (a) =>
                a?.metrics?.find((m) => m?.name == "status")?.value ==
                TASK_STATUS_COMPLETED
            )?.length || 0}{" "}
            / {list?.length || 0}
          </p>
        </div>
        <p className="text-primary-neutral-500 font-satoshi text-2xs leading-4 tracking-[0.5px]">
          Sprint Goals Completed
        </p>
        <div className="flex flex-row items-center space-x-2 w-full">
          <div className="w-10/12 rounded-full bg-primary-neutral-100 h-2">
            <div
              className="h-2 rounded-full bg-[#FF7E6E]"
              style={{
                width: `${
                  ((list?.filter(
                    (a) =>
                      a?.metrics?.find((m) => m?.name == "status")?.value ==
                      TASK_STATUS_COMPLETED
                  )?.length || 0) *
                    100) /
                  (list?.length || 0)
                }`,
              }}
            ></div>
          </div>
          <p className="text-primary-neutral-500 font-satoshi text-3xs font-bold tracking-[0.4px] leading-3">
            {((list?.filter(
              (a) =>
                a?.metrics?.find((m) => m?.name == "status")?.value ==
                TASK_STATUS_COMPLETED
            )?.length || 0) *
              100) /
              (list?.length || 0)}
            %
          </p>
        </div>
      </div>
      <div className="p-4 flex flex-col items-center justify-center space-y-2 bg-white border border-[#F3F2F2] rounded-2xl">
        <div className="flex flex-row items-center space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M5 7.5C5 7.08578 5.33579 6.75 5.75 6.75H13.25C13.6642 6.75 14 7.08578 14 7.5C14 7.91422 13.6642 8.25 13.25 8.25H5.75C5.33578 8.25 5 7.91422 5 7.5Z"
              fill="#2BB656"
            />
            <path
              d="M5.75 9.75C5.33579 9.75 5 10.0858 5 10.5C5 10.9142 5.33578 11.25 5.75 11.25H13.25C13.6642 11.25 14 10.9142 14 10.5C14 10.0858 13.6642 9.75 13.25 9.75H5.75Z"
              fill="#2BB656"
            />
            <path
              d="M5 13.5C5 13.0858 5.33579 12.75 5.75 12.75H8.75C9.16423 12.75 9.5 13.0858 9.5 13.5C9.5 13.9142 9.16423 14.25 8.75 14.25H5.75C5.33579 14.25 5 13.9142 5 13.5Z"
              fill="#2BB656"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 3C2 1.75736 3.00736 0.75 4.25 0.75H14.75C15.9927 0.75 17 1.75736 17 3V15C17 16.2427 15.9927 17.25 14.75 17.25H4.25C3.00736 17.25 2 16.2427 2 15V3ZM4.25 2.25H14.75C15.1642 2.25 15.5 2.58578 15.5 3V15C15.5 15.4142 15.1642 15.75 14.75 15.75H4.25C3.83579 15.75 3.5 15.4142 3.5 15V3C3.5 2.58579 3.83578 2.25 4.25 2.25Z"
              fill="#2BB656"
            />
          </svg>
          <p className="text-primary-neutral-800 font-lato text-xl font-bold leading-7 tracking-[1px]">
            {journals?.length || 0}
          </p>
        </div>
        <p className="text-primary-neutral-500 font-satoshi text-2xs leading-4 tracking-[0.5px]">
          Sessions This Sprint
        </p>
      </div>
    </div>
  );
}
