import { Listbox, Transition } from "@headlessui/react";
import classNames from "helpers/utils/classNames";
import React, { Fragment } from "react";
import { TimeframeList } from "./utils/constant";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const InterestTimeDropdown = ({ interest, setInterest }) => {
  return (
    <div className="w-full  max-w-xl">
      <Listbox
        value={interest.timeframe || ""}
        onChange={(val) => {
          const index = TimeframeList.findIndex((time) => time.title === val);
          setInterest({ ...interest, timeframe: val, index });
        }}
        className="px-6 py-4 w-full font-satoshi"
      >
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                className={classNames(
                  "bg-white flex gap-3 items-center  w-full p-4 text-left text-md  font-medium  border-b border-primary-yellow-500  focus:outline-none focus:ring-transparent focus:bg-primary-grey-100   "
                )}
              >
                <span
                  className={`${
                    interest.timeframe === ""
                      ? "text-primary-grey-300"
                      : "text-primary-grey-800"
                  }  flex-1 text-left font-bold  `}
                >
                  {TimeframeList?.find((i) => i?.title == interest.timeframe)
                    ?.label ||
                    interest.timeframe ||
                    "Select time frame"}
                </span>
                <span className="grid place-items-center  pointer-events-none">
                  <ChevronDownIcon
                    className="h-6 w-6 text-primary-yellow-500"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              {/* {showError &&
            (!details[field] ||
              (details[field] && details[field] == "")) && (
              <p className="text-alert text-xs flex flex-row items-center mt-1 ml-0.5">
                {errorMessage}
              </p>
            )} */}
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={` absolute inset-x-0 z-10 w-full text-base  font-medium bg-white border  rounded-b shadow-sm max-h-60 rounded  overflow-auto focus:outline-none`}
                >
                  {TimeframeList.map((item, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-primary-blue-200" : "text-black",
                          "cursor-default select-none relative  ",
                          "hover:bg-primary-blue-100 cursor-pointer "
                        )
                      }
                      value={item?.title}
                    >
                      {({ selected }) => (
                        <div
                          className={`flex gap-4 items-center p-4  sm:py-4 sm:px-10 border-b ${
                            selected && "bg-primary-blue-100"
                          } `}
                        >
                          <div className="text-primary-yellow-500">
                            {item?.icon}
                          </div>
                          <div className="flex flex-col">
                            <h2 className="font-satoshi font-bold text-sm">
                              {item?.title}
                            </h2>
                            <p className="font-satoshi text-xs">
                              {item?.subTitle}
                            </p>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default InterestTimeDropdown;
