import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { privateRoutes } from "../../routes/PrivateRoute";

function PageHeader() {
  const routes = useLocation();
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    let page = privateRoutes?.find((p) => p?.path == routes?.pathname);

    setHeading(page?.name);
    setDescription(page?.description);
  }, [routes]);

  return (
    <div className="flex flex-row items-start justify-between p-4">
      <div className="md:w-8/12 relative">
        <h1
          className={`text-lg hidden md:flex flex-row items-center text-left font-semibold font-inter tracking-tight text-primary-gray-1000`}
        >
          <p>{heading ? heading : ""} </p>
        </h1>
        <p className="font-inter text-sm mt-2.5 text-primary-gray-250">
          {description}
        </p>
      </div>
    </div>
  );
}

export default PageHeader;
