import { Text } from 'helpers/constants/Text'
import React from 'react'
import { MdLockOutline, MdNorthEast } from 'react-icons/md'
import { List } from '../List/List'
import { Img } from '../Img/Img'
import Button from 'helpers/constants/button'

const Castles = () => {
  return (
    <>
     <div className="flex flex-col font-manrope gap-2 mt-6 items-start justify-start w-3/4">
             <div className="flex flex-row gap-2 items-center justify-between w-full">
                <Text
                  className="text-blue_gray-900 text-center text-lg tracking-[0.90px] w-auto"
                  size="txtManropeSemiBold18"
                >
                 Career Castles Conquered
                </Text>
                <div className="flex flex-row font-satoshi gap-2 items-center justify-start px-4 w-auto">
                  <a
                    href="caarya:"
                    className="text-gray-700 text-sm tracking-[0.70px] underline w-auto"
                  >
                    <Text size="txtSatoshiBold14Gray700">Learn More</Text>
                  </a>
                  <MdNorthEast />
                </div>
              </div>

              <div className="flex flex-col font-satoshi items-center justify-center mt-6 p-4 w-full">
                <div className="flex md:flex-col flex-row md:gap-10 items-center justify-between w-full">
                  <List className="flex flex-row gap-20 items-center w-[87%]"
                  orientation="horizontal"
                  >
                    {[...Array(4)].map((_, index) => (
                      <div
                        key={index}
                        className="bg-gray-100 flex flex-col items-center justify-center sm:ml-[0] px-4 py-6 rounded-lg w-36"
                      >
                        <div className="flex flex-col gap-[17px] items-start justify-start w-full">
                          <div className="flex flex-row gap-1.5 items-start justify-between md:ml-[0] ml-[5px] w-[96%] md:w-full">
                            <Img
                              className="h-20 mt-5 w-20"
                              src="assets/svg/pass/fi_10848987.svg"
                              alt="castle"
                            />
                            <Button
                              className="flex h-[22px] items-center justify-center mb-[78px] rounded-[50%] w-[22px]"
                              color="gray_50"
                            >
                              <MdLockOutline/>
                             
                            </Button>
                          </div>
                          <Text
                            className="text-gray-500 text-sm tracking-[0.70px] w-auto"
                            size="txtSatoshiBold14Gray500"
                          >
                            Castle Name
                          </Text>
                        </div>
                      </div>
                      
                    ))}
                  </List>
                </div>
               
                <div className="flex flex-row md:flex-row  gap-10 items-center justify-center w-[48%] md:w-full mt-10">
                  {[5, 6].map((index) => (
                    <div
                      key={index}
                      className="bg-gray-100 flex flex-col items-center justify-center px-4 py-6 rounded-lg w-36 "
                    >
                       <Button className="flex h-[22px] items-center justify-center  rounded-[50%] w-[22px] ml-12 mb-7  "
                      
                      >
                      <MdLockOutline></MdLockOutline> </Button>  
                      <Img
                        className="h-20 w-20"
                        src="assets/svg/pass/fi_10848987.svg"
                        alt="castle"
                      /> 
                                        
                      <Text
                        className="text-gray-500 text-sm tracking-[0.70px] w-auto pt-4"
                        size="txtSatoshiBold14Gray500" 
                      >
                        Castle Name 
                      </Text>
                    </div>
                  ))}
                </div>

                  


              </div>
            </div>

    
    </>
  )
}

export default Castles