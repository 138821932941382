import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import usePhaseTwo from "components/PhaseTwoForm/hooks/usePhaseTwo";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";

const FormNavigation = () => {
  const { handleBackButtonClick, handleStepChangeButtonClick } = usePhaseTwo();

  return (
    <div className="flex justify-between bg-white gap-6 items-center p-4">
      <ProfileButton
        onClick={handleBackButtonClick}
        text={"Back"}
        size={"large"}
        icon={<ArrowLeftIcon className="w-6" />}
        iconAlign={"left"}
      />

      <ProfileButton
        //   onClick={() =>
        //     step >= totalSteps - 1
        //       ? dispatch(showToast({ message: "Form is submitted" }))
        //       : dispatch(updateStep(step + 1))
        //   }
        onClick={handleStepChangeButtonClick}
        //disabled={getDisabledState(step, totalSteps, details, field)}
        text={"Continue"}
        size={"large"}
        variant={"filled"}
        icon={<ChevronRightIcon className="w-6" />}
        iconAlign={"right"}
      />
    </div>
  );
};

export default FormNavigation;
