import moment from "moment";

export const listBoosterOptions = (stateBoosters, boosters) => {
  if (!stateBoosters || stateBoosters?.length === 0) return [];
  const selected = [];

  stateBoosters.forEach((stateBooster) => {
    const booster = boosters.find((b) => b._id === stateBooster.booster_id);

    if (booster) {
      stateBooster.selectedOptions.forEach((optionId) => {
        const option = booster.options.find((opt) => opt._id === optionId);

        if (option) {
          selected.push({
            booster_id: stateBooster.booster_id,
            option_id: optionId,
            title: option.description,
          });
        }
      });
    }
  });

  return selected;
};

export const getBoosterCount = (selectedBoosters) => {
  if (!selectedBoosters) return 0;

  return selectedBoosters.reduce((count, booster) => {
    return booster.selectedOptions.length > 0
      ? count + booster.selectedOptions.length
      : count;
  }, 0);
};

export const disabledDate = (date) => {
  const maxDate = moment(new Date());

  const momentDate = moment(date);
  return momentDate.isAfter(maxDate, "day");
};

export const getColor = (category) => {
  if (category === "Academic") {
    return "#EEA638";
  } else if (category === "Work") {
    return "#816FE9";
  } else {
    return "#4CA6E5";
  }
};

export const getImage = (category) => {
  if (category === "Academic") {
    return "/assets/project3.svg";
  } else if (category === "Work") {
    return "/assets/project1.svg";
  } else {
    return "/assets/project2.svg";
  }
};

export const isStepValid = (state, step, currentRoleIdx) => {
  switch (step) {
    case 1:
      return state?.projectName?.length > 0;
    case 2:
      return state?.aspect?.length > 0;
    case 3:
      return state?.roles?.length > 0;
    case 4:
      const role = state?.roles[currentRoleIdx];
      return state?.achievements?.[role]?.length > 0;
    case 5:
      let _flag =
        state?.moreContext.category === "Academic"
          ? state?.moreContext?.course?.length
          : state?.moreContext?.category === "Work"
          ? state?.moreContext?.company?.length
          : true;
      return state?.moreContext?.category?.length > 0 && _flag;
    default:
      return true;
  }
};
