import EndingMe from "components/ProfileForm/Ending";
import WebAchievementForm from "components/ProfileForm/LaptopForm/components/WebAchievementForm";
import WebAchievementIntroForm from "components/ProfileForm/LaptopForm/components/WebAchievementIntroForm";
import WebCareerInterestForm from "components/ProfileForm/LaptopForm/components/WebCareerInterestForm";
import WebDreamCompanyForm from "components/ProfileForm/LaptopForm/components/WebDreamCompanyForm";
import WebFinalStepForm from "components/ProfileForm/LaptopForm/components/WebFinalStepForm";
import WebInterestIntro from "components/ProfileForm/LaptopForm/components/WebInterestIntroForm";
import WebInterestSelectionForm from "components/ProfileForm/LaptopForm/components/WebInterestSelectionForm";
import WebJourneyForm from "components/ProfileForm/LaptopForm/components/WebJourneyForm";
import WebQuizForm from "components/ProfileForm/LaptopForm/components/WebQuizForm";
import WebSelfReflectionForm from "components/ProfileForm/LaptopForm/components/WebSelfReflectionForm";
import WebValueQuizForm from "components/ProfileForm/LaptopForm/components/WebValueQuizForm";
import WebValueQuizIntro from "components/ProfileForm/LaptopForm/components/WebValueQuizIntro";
import AchievementForm from "components/ProfileForm/MobileForm/components/AcheivementForm";
import AchievementIntroForm from "components/ProfileForm/MobileForm/components/AchievementIntroForm";
import CareerInterestForm from "components/ProfileForm/MobileForm/components/CareerInterestForm";
import DreamCompanyForm from "components/ProfileForm/MobileForm/components/DreamCompanyForm";
import FinalStepForm from "components/ProfileForm/MobileForm/components/FinalStepForm";
import InterestIntro from "components/ProfileForm/MobileForm/components/InterestIntroForm";
import InterestSelectionForm from "components/ProfileForm/MobileForm/components/InterestSelectionForm";
import JourneyForm from "components/ProfileForm/MobileForm/components/JourneyForm";
import QuizForm from "components/ProfileForm/MobileForm/components/QuizForm";
import SelfReflectionForm from "components/ProfileForm/MobileForm/components/SelfReflectionForm";
import ValueQuizForm from "components/ProfileForm/MobileForm/components/ValueQuizForm";
import ValueQuizIntro from "components/ProfileForm/MobileForm/components/ValueQuizIntro";

export const FormComponents = (screen) => {
  return [
    {
      title: "Journey Form",
      component:
        screen === "mobile" ? (
          <JourneyForm field={""} />
        ) : (
          <WebJourneyForm field={""} />
        ),
      field: "",
    },
    {
      title: "Interest Inro Form",
      component:
        screen === "mobile" ? (
          <InterestIntro field={""} />
        ) : (
          <WebInterestIntro field={""} />
        ),
      field: "",
    },

    {
      title: "Quiz Form",
      component:
        screen === "mobile" ? (
          <QuizForm field={"quiz"} />
        ) : (
          <WebQuizForm field={"quiz"} />
        ),
      field: "quiz",
    },
    {
      title: "Submit",
      component: <EndingMe />,
      field: "",
    },
    {
      title: "Career Interest Form",
      component:
        screen === "mobile" ? (
          <CareerInterestForm field={""} />
        ) : (
          <WebCareerInterestForm field={""} />
        ),
      field: "",
    },
    {
      title: "Interest Selection Form",
      component:
        screen === "mobile" ? (
          <InterestSelectionForm field={"careerInterest"} />
        ) : (
          <WebInterestSelectionForm field={"careerInterest"} />
        ),
      field: "careerInterest",
    },
    {
      title: "Submit",
      component: <EndingMe />,
      field: "",
    },
    {
      title: "Achievement Intro Form",
      component:
        screen === "mobile" ? (
          <AchievementIntroForm field={""} />
        ) : (
          <WebAchievementIntroForm field={""} />
        ),
      field: "",
    },
    {
      title: "Achievement Form",
      component:
        screen === "mobile" ? (
          <AchievementForm field={"achievements"} />
        ) : (
          <WebAchievementForm field={"achievements"} />
        ),
      field: "achievements",
    },
    {
      title: "Value Quiz Intro",
      component:
        screen === "mobile" ? (
          <ValueQuizIntro field={""} />
        ) : (
          <WebValueQuizIntro field={""} />
        ),
      field: "",
    },
    {
      title: "Value Quiz Form",
      component:
        screen === "mobile" ? (
          <ValueQuizForm field={"valueQuiz"} />
        ) : (
          <WebValueQuizForm field={"valueQuiz"} />
        ),
      field: "valueQuiz",
    },
    {
      title: "Submit",
      component: <EndingMe />,
      field: "",
    },
    {
      title: "Self Reflection Form",
      component:
        screen === "mobile" ? (
          <SelfReflectionForm field={""} />
        ) : (
          <WebSelfReflectionForm field={""} />
        ),
      field: "",
    },
    {
      title: "Dream Company Form",
      component:
        screen === "mobile" ? (
          <DreamCompanyForm field={"dreamCompany"} />
        ) : (
          <WebDreamCompanyForm field={"dreamCompany"} />
        ),
      field: "dreamCompany",
    },
    {
      title: "Final Form",
      component:
        screen === "mobile" ? (
          <FinalStepForm field={""} />
        ) : (
          <WebFinalStepForm field={""} />
        ),
      field: "",
    },
  ];
};

export const achievementTypeOptions = [
  {
    title: "I want to talk about a thing I did to grow and learn",
    example:
      "Eg. That time when you made a website while learning about WordPress",
  },
  {
    title: "I want to talk about a time that I stood out from the crowd",
    example:
      "Eg. That time when you ranked 3rd in an inter-school art competition",
  },
  {
    title:
      "I want to talk about a time when I had to put my knowledge and skills towards achieving a goal",
    example: "Eg. That time when you had to submit your dissertation",
  },
];

export const RiasecOptions = [
  {
    title: "Realistic",
    options: [
      "Assembeling electronic parts",
      "Operate a machine at a work",
      "Set up and operate the wiring for the audio system at your home",
      "Build a wooden chair for yourself",
      "Work on your lawn, planting new buds and trimming the grass",
      "Repair your malfunctioning electric kettle",
      "Build a wooden cabinate for your kitchen",
      "Operate machinery on a production line",
      "Make exact measurements and determine property boundaries",
      "Set up and operate machines to make products",
    ],
  },
  {
    title: "Investigative",
    options: [
      "Understand how a hair-dryer functions",
      "Study ways to reduce water pollution",
      "Study and understand the movement of planets",
      "Modify an equipment design to reduce sound level",
      "Think of ideas to increase the efficiency of a routine task",
      "Conceptualize a way to increase the accruacy of under sea mapping",
      "Conduct a research to determine the infection rate of a new disease",
      "Write a computer programme to understand your monthly spending",
      "Think of multiple alternative ways that a problem can be approached",
      "Identify the underlying principles of a new theory",
    ],
  },
  {
    title: "Artistic",
    options: [
      "Conduct a musical choir",
      "Make a film on your hometown",
      "Play a musical instrument",
      "Write a book or play",
      "Design a set for your school play",
      "Act in a movie",
      "Compose or arrange music",
      "Sketch a comic of your close friends",
      "Decorate a wall in your bedroom",
      "Paint a mural",
    ],
  },
  {
    title: "Social",
    options: [
      "Give career guidance to people",
      "Do volunteer work at a non-profit organization",
      "Teach an exercise routineto your friend",
      "Supervise the activities of children at a camp",
      "Help elderly people with their daily activities",
      "Teach an elementary school class",
      "Work with mentally disabled children",
      "Help conduct a group therapy session",
      "Give theraputic counceling to a friend",
      "Teach children how to read",
      "Provide consultation and advice to others",
    ],
  },
  {
    title: "Enterprising",
    options: [
      "Give a presentation about a product are assigned to sell",
      "Convince your friends to change their minds or actions.",
      "Manage the operations of your school canteen for a day",
      "Negotiate a deal with a seller",
      "Start your own business venture",
      "Sell an idea to others or influence their behaviour in some other way",
      "Lead a class group project ",
      "Develop and build teams",
      "Organize and manage a farewell party",
      "Communicate with people outside your college to run placement drives",
    ],
  },
  {
    title: "Conventional",
    options: [
      "Keep a record of your financial transactions",
      "Maintain a database for your class' attendance",
      "Calculate the wages of employees",
      "Enter information into a database",
      "Monitoring and controlling resources and overseeing the spending of money",
      "Bring structure to the functiioning of your local library",
      "Prepare financial reports",
      "Organize your documents, tasks and budgets",
      "Keep track of the overall budget for a week long trip with your friends",
      "Analyze your friend's credit history",
      "Design a diagnostic routine for a powerplant",
    ],
  },
];

export const QuestionArr = [
  [
    "Assembeling electronic parts",
    "Understand how a hair-dryer functions",
    "Conduct a musical choir",
    "Give career guidance to people",
    "Give a presentation about a product are assigned to sell",
    "Keep a record of your financial transactions",
  ],
  [
    "Operate a machine at a work",
    "Study ways to reduce water pollution",
    "Make a film on your hometown",
    "Do volunteer work at a non-profit organization",
    "Convince your friends to change their minds or actions.",
    "Maintain a database for your class' attendance",
  ],
  [
    "Set up and operate the wiring for the audio system at your home",
    "Study and understand the movement of planets",
    "Play a musical instrument",
    "Teach an exercise routineto your friend",
    "Manage the operations of your school canteen for a day",
    "Calculate the wages of employees",
  ],
  [
    "Build a wooden chair for yourself",
    "Modify an equipment design to reduce sound level",
    "Write a book or play",
    "Supervise the activities of children at a camp",
    "Negotiate a deal with a seller",
    "Enter information into a database",
  ],
  [
    "Work on your lawn, planting new buds and trimming the grass",
    "Think of ideas to increase the efficiency of a routine task",
    "Design a set for your school play",
    "Help elderly people with their daily activities",
    "Start your own business venture",
    "Monitoring and controlling resources and overseeing the spending of money",
  ],
  [
    "Repair your malfunctioning electric kettle",
    "Conceptualize a way to increase the accruacy of under sea mapping",
    "Act in a movie",
    "Teach an elementary school class",
    "Sell an idea to others or influence their behaviour in some other way",
    "Bring structure to the functiioning of your local library",
  ],
  [
    "Build a wooden cabinate for your kitchen",
    "Conduct a research to determine the infection rate of a new disease",
    "Compose or arrange music",
    "Work with mentally disabled children",
    "Lead a class group project ",
    "Prepare financial reports",
  ],
  [
    "Operate machinery on a production line",
    "Write a computer programme to understand your monthly spending",
    "Sketch a comic of your close friends",
    "Help conduct a group therapy session",
    "Develop and build teams",
    "Organize your documents, tasks and budgets",
  ],
  [
    "Make exact measurements and determine property boundaries",
    "Think of multiple alternative ways that a problem can be approached",
    "Decorate a wall in your bedroom",
    "Give theraputic counceling to a friend",
    "Organize and manage a farewell party",
    "Keep track of the overall budget for a week long trip with your friends",
  ],
  [
    "Set up and operate machines to make products",
    "Identify the underlying principles of a new theory",
    "Paint a mural",
    "Teach children how to read",
    "Communicate with people outside your college to run placement drives",
    "Analyze your friend's credit history",
  ],
];
