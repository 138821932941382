import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Plus } from "@phosphor-icons/react/dist/ssr";
import { useHistory } from "react-router-dom";
// import { downvoteIssue, getAllIssues, upvoteIssue } from "config/APIs/issues";
import BreadCrumb from "components/Common/BreadCrumb";
import PageHeader from "components/Common/PageHeader";
import Tabs from "components/Common/Tabs";
import EmptyState from "components/Common/EmptyState";

const tabs = [
  { label: "All Issues", value: "all" },
  { label: "My Issues", value: "my" },
];
function Better() {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const { user } = useSelector((state) => state?.user);

  useEffect(() => {
    // getAllIssues({
    //   type: selectedTab,
    // })
    //   .then(({ data }) => {
    //     setList(data?.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [selectedTab]);

  return (
    <div className="pt-20 pb-10">
      <BreadCrumb back page1="Essentials" page2="Lets Make Us Better" />{" "}
      <div className="px-4 py-8 lg:p-20 h-[80vh] overflow-y-auto">
        <div className="w-full flex flex-col items-center justify-center">
          <PageHeader
            heading="Lets Make Us Better"
            description="Agree or disagree with what others feel are issues within Caarya. Or, write about what you feel is going wrong or can be improved in Caarya."
          />
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <div className="py-4 px-2 space-y-6 w-full">
            {list?.length > 0 ? (
              list?.map((item) => {
                return <Card item={item} user={user} />;
              })
            ) : (
              <EmptyState text="No Issues found!" />
            )}
          </div>

          <div
            onClick={() => {
              history.push("/issue/add");
            }}
            className="flex justify-end items-center w-full h-20 absolute bottom-20 px-4 py-4"
          >
            <div className="fixed cursor-pointer flex justify-center items-center gap-2 p-4 red-gradient rounded-2xl">
              {/* This is a Add Icon */}
              <Plus size={16} className="text-white" />{" "}
              <h3 className="font-lato text-white">Add Issues</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Better;

function Card({ item, user }) {
  const currVote = item?.voters?.find((voter) => voter.id === user.id)
    ?.issueVotes?.voteType;

  const [vote, setVote] = useState(currVote);
  const upvoteHandler = async (id) => {
    // await upvoteIssue(id);
    setVote("upvote");
  };

  const downvoteHandler = async (id) => {
    // await downvoteIssue(id);
    setVote("downvote");
  };

  return (
    <div
      style={{
        boxShadow:
          "0px 4px 6px -4px rgba(0, 0, 0, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
      }}
      className="bg-white pt-20 pb-10 rounded-xl p-4 space-y-4 w-full"
    >
      <div className="space-y-2">
        <h1 className="text-black font-lato text-base font-bold leading-6 tracking-[1.28px]">
          {item?.issue}
        </h1>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <button
            onClick={() => {
              if (item?.creatorId !== user?.id) {
                upvoteHandler(item?.id);
              }
            }}
            disabled={item?.creatorId === user?.id}
            style={{ padding: "6px 8px 8px 8px" }}
            className="disabled:opacity-45"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 0.625L0.625 10H6.25V20H13.75V10H19.375L10 0.625Z"
                fill={vote == "upvote" ? "#ED4C41" : "#CFCDC9"}
              />
            </svg>
          </button>
          <button
            onClick={() => {
              if (item?.creatorId !== user?.id) {
                downvoteHandler(item?.id);
              }
            }}
            disabled={item?.creatorId === user?.id}
            style={{ padding: "6px 8px 8px 8px" }}
            className="disabled:opacity-45"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.25 10V10.5H13.75H18.1679L10 18.6679L1.83211 10.5H6.25H6.75V10V0.5H13.25V10Z"
                fill={vote == "downvote" ? "#ED4C41" : "#CFCDC9"}
              />
            </svg>
          </button>
        </div>
        <p className="text-primary-neutral-400 font-lato text-3xs font-bold leading-3 tracking-[0.16px]">
          {moment(item?.createdAt).format("ll")}
        </p>
      </div>
    </div>
  );
}
