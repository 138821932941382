import React, { useState, useEffect } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressTimer = ({ clockIn, duration }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const clockInTime = new Date(clockIn).getTime();
    const totalDurationMs = duration * 60 * 1000;

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsed = currentTime - clockInTime;

      if (elapsed < totalDurationMs) {
        setElapsedTime(elapsed);
      } else {
        setElapsedTime(totalDurationMs);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [clockIn, duration]);

  const totalDurationMs = duration * 60 * 1000;
  const percentage = (elapsedTime / totalDurationMs) * 100;

  const formatTime = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <CircularProgressbarWithChildren
      value={percentage}
      styles={buildStyles({
        pathColor: "#FFFFFF",
        trailColor: "rgba(255, 255, 255, 0.2)",
        backgroundColor: "#3e98c7",
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M5 16.3346H8.33333V4.66797H5V16.3346ZM11.6667 4.66797V16.3346H15V4.66797H11.6667Z"
          fill="white"
        />
      </svg>
      {/* <div className="flex flex-col   justify-center items-center">
        <div
          className="font-lato font-medium"
          style={{
            position: "absolute",
            top: "20%",
            fontSize: "10px",
            color: "#FFFFFF",
          }}
        >
          {formatTime(totalDurationMs)[0] +
            formatTime(totalDurationMs)[1] +
            "h" +
            formatTime(totalDurationMs)[3] +
            formatTime(totalDurationMs)[4] +
            "m"}
        </div>

       
        <div
          className="font-lato font-bold flex items-start"
          style={{ fontSize: "18px", color: "#FFFFFF" }}
        >
          {formatTime(elapsedTime)}
        </div>

        
        <div
          className="w-[12px] h-[14px]"
          style={{ position: "absolute", bottom: "20%", color: "#FFFFFF" }}
        >
          <FaPause />
        </div>
      </div> */}
    </CircularProgressbarWithChildren>
  );
};

export default ProgressTimer;
