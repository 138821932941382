import { scrollToTop } from "helpers/utils/profileForm/functions";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/profile-form";
import InterestCard from "./InterestCard";

const ActiveInterestSelectionComponent = ({
  field,
  openDrawer,
  scrollable,
}) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  const [showNewCard, setShowNewCard] = useState(
    details[field].length === 0 ? true : false
  );

  const handleAddNewCard = async () => {
    await setShowNewCard(true);
    scrollToTop();
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#FAFAFA" : "",
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      details[field],
      result.source.index,
      result.destination.index
    );
    dispatch(updateDetails({ field, value: items }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      className={`w-full flex flex-col relative ${scrollable && "h-full "} `}
    >
      <div
        className={`flex  flex-col items-end bg-white ${
          scrollable && "fixed top-0 inset-x-0"
        }   `}
      >
        <button
          onClick={openDrawer}
          className="text-primary-grey-500  bg-white py-2 rounded-sm sticky top-0 text-md font-satoshi font-semibold flex items-center gap-1 self-end text-right px-6"
        >
          Your Career Interests{" "}
          {details[field].length > 0 && (
            <span className="text-xs w-4 h-4  text-center bg-primary-yellow-300 rounded-full text-white">
              {details[field].length}
            </span>
          )}
        </button>
        <p className="font-satoshi font-bold text-sm text-primary-yellow-700 pr-6">
          Add At Least 3
        </p>
      </div>
      <div className={`p-4 md:px-10 flex flex-col gap-4 `}>
        <div className={` flex flex-col gap-8  pr-2 ${showNewCard && "pb-14"}`}>
          {!showNewCard && (
            <div onClick={handleAddNewCard} className="flex flex-col gap-6  ">
              <div
                id="interest_hover_view"
                className="flex items-center gap-4 max-w-max cursor-pointer p-3 px-4 rounded-xl"
              >
                <button className="w-10 h-10 rounded-full border-2 font-semibold border-primary-yellow-500 grid place-items-center text-xl text-primary-yellow-500 ">
                  <MdAdd />
                </button>
                <p className="font-satoshi text-md flex-1">
                  Add what you were interested in before this
                </p>
              </div>

              <div
                id="hover_view_sibling"
                className={`${
                  details[field].length > 0 ? "p-10" : "p-20"
                } border-2 border-dashed border-primary-grey-200 rounded-3xl w-full`}
              ></div>
            </div>
          )}
          {showNewCard && (
            <InterestCard
              setShowNewCard={setShowNewCard}
              type={"new"}
              field={field}
              isFirstCard={details[field].length === 0}
            />
          )}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-col gap-8 "
                >
                  {[...details[field]]
                    .sort((b, a) => a?.index - b?.index)
                    .map((interest, idx) => (
                      <Draggable
                        key={`item-${idx}`}
                        draggableId={`item-${idx}`}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <InterestCard
                              setShowNewCard={setShowNewCard}
                              key={interest.id}
                              field={field}
                              data={interest}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default ActiveInterestSelectionComponent;
