import { Text } from 'helpers/constants/Text'
import React from 'react'
import { List } from '../List/List'
import { BsHexagonHalf } from 'react-icons/bs'
import Button from 'helpers/constants/button'
import { Img } from '../Img/Img'

const BuildIndetity = () => {
  return (
    <>
    
    <div
              className=" flex flex-col font-manrope gap-4 items-center justify-start px-8 py-10 rounded-[24px] shadow-bs3 w-3/4"
              style={{
                // background:
                //   "linear-gradient(20deg, rgba(76, 166, 229, 1) 10%, rgba(194, 232, 240, 1) 60%)",
                background: "linear-gradient(#4CA6E5, #C2E8F0)",
              }}
            >
              <div className="flex flex-col gap-4 items-center justify-start w-full">
                <Text
                  className="text-center text-white text-xl tracking-[1.00px] w-auto"
                  size="txtManropeSemiBold20"
                >
                  Build Your Own Identity
                </Text>
                <List
                  className="sm:flex-col flex-row font-satoshi gap-4 inline-block pt-10 sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-center w-full"
                  orientation="horizontal"
                >
                  <>
                    {[
                      { label: "Evalucon" },
                      { label: "Me Profiles" },
                      { label: "Phase 2" },
                      { label: "Phase 3" },
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="w-[188px] h-[149px] px-3 py-6 mx-4 bg-zinc-100 rounded-2xl flex-col justify-center items-center gap-8 inline-block
                      transform transition duration-500
                      hover:scale-150 hover:w-[138px] hover:h-[140px] hover:mx-10 hover:mr-12  hover:tex
                      "
                      >
                        <div
                          className="flex flex-col h-full items-center  justify-center  w-full  rounded-[16px]
                        text-center
                      "
                        >
                          <BsHexagonHalf className="h-full " size={"4rem"} color={"#A193F2"}  />

                          <Text
                            className={`text-${
                              index > 0 ? "gray-500" : "transparent"
                            } text-sm tracking-[0.70px] w-full pt-3 text-center `}
                            size={
                              index > 0
                                ? "txtSatoshiMedium14Gray500"
                                : "txtSatoshiBold16IndigoA200"
                            }
                          >
                            {item.label}
                          </Text>
                        </div>
                      </div>
                    ))}
                  </>
                 
                  <div className="w-[190px] h-2 bg-stone-200 rounded-[32px] justify-start items-center mt-20   inline-flex">
                    <div className="w-[145px] self-stretch bg-white rounded-[32px] " />
                  </div>
                  <div className="w-[196px] h-2 bg-stone-200 rounded-[32px] justify-start items-center my-12 ml-8 inline-flex">
                    <div className="w-[145px] self-stretch bg-white rounded-[32px] " />
                  </div>
                  <div className="w-[196px] h-2 bg-stone-200 rounded-[32px] justify-start items-center my-12  ml-8 inline-flex">
                    <div className="w-[145px] self-stretch bg-white rounded-[32px] " />
                  </div>
                  <div className="w-[196px] h-2 bg-stone-200 rounded-[32px] justify-start items-center my-12  ml-8 inline-flex">
                    <div className="w-[145px] self-stretch bg-white rounded-[32px] " />
                  </div>
                </List>
              </div>
              <div className="flex flex-row font-satoshi gap-2 items-center justify-end sm:px-5 px-6 py-2 w-full">
                <Text
                  className="text-center text-sm text-white  tracking-[0.70px] underline w-auto"
                  size="txtSatoshiBold14"
                >
                  CTA to Evalucon
                </Text>
                <Button>
                  <Img
                    className="h-4 w-4"
                    src="assets/svg/pass/MdNorthEast.svg"
                    alt="arrowleft"
                  />
                </Button>
              </div>
            </div>
    
    </>
  )
}

export default BuildIndetity