import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { hideNavigation, updateStep } from "redux/profile-form";

import LockIcon from "./assets/lock.svg";
import { useInView } from "react-intersection-observer";
import ScrollToTopButton from "components/ProfileForm/ui-components/ScrollToTopButton";

const JourneyForm = () => {
  const { step, details } = useSelector((state) => state.profileForm.phaseOne);
  const [ref, isInView] = useInView({
    triggerOnce: true,
    threshold: 0.75,
  });
  const intersectionRef = useRef(null);
  const [showUpButton, setShowUpButton] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideNavigation());
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowUpButton(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => {
      if (intersectionRef.current) observer.unobserve(intersectionRef.current);
    };
  }, [intersectionRef]);
  return (
    <div className=" font-manrope h-full  py-24 flex flex-col gap-7 ">
      <div className="flex flex-col px-4 md:px-10 gap-4">
        <h4 className="text-xl text-primary-blue-400 max-w-sm font-medium ">
          Welcome aboard Caarya Me, {details.name}
        </h4>
        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light">
          We will guide you in your pursuit for a purposeful career and help you
          build adaptable and resilient identities. To get started on this
          journey, you’ll first need a{" "}
          <span className="text-primary-blue-500 font-bold">Me Profile</span>
        </p>
      </div>

      <div className="flex flex-col gap-4 px-4 md:px-10">
        <h5 className="text-md text-primary-grey-800 font-manrope   font-medium ">
          How would this help?
        </h5>
        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light ">
          We’ll help you get closer to finding a purposeful career by helping
          you self reflect, measure your growth, and express yourselves.
        </p>
        <img
          className="w-full h-60 object-contain mx-suto"
          src=" https://pbs.twimg.com/media/E1ISt3qWYAcTSiX.jpg:large"
          alt=""
        />

        <p className="text-md font-satoshi text-primary-grey-500 tracking-wide font-light ">
          In this phase of building your Me Profile, you would go through 5
          steps, each one carefully chosen to help bring you closer to
          identifying yourself, highlighting your own unique strengths and
          capabilities and express yourself to peers and potential employers.
          <br />
          <br />
          <br />
          We would recommend you to do this when you are in a calm and
          uninterrupted environment. Take out approximately 10 minutes where you
          can be free from any disturbances and can be in a mental space where
          you can reflect on yourself.
        </p>
      </div>

      <div ref={intersectionRef} className="flex flex-col ">
        <div className="flex flex-col gap-2 pb-6 p-4 md:px-10">
          <h3 className="text-primary-blue-300 font-medium text-lg font-manrope">
            Self Reflection
          </h3>
          <p className="text-sm font-satoshi text-primary-grey-500 tracking-wide font-light ">
            To help you understand yourself. Identifying who you are, how you
            function and how that impacts your choices and your career.
          </p>
          <div className="my-2 grid grid-cols-3 gap-2 ">
            <div className="flex flex-col gap-2">
              <img
                className="w-full h-24 md:h-[140px] object-contain "
                src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                alt=""
              />
              <p className="text-center text-xs font-satoshi font-semibold">
                Natural Inclinations
              </p>
            </div>
            <div className="flex flex-col gap-2 relative">
              <img
                className="w-full h-24 md:h-[140px] object-contain "
                src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                alt=""
              />
              <p className="text-center text-xs font-satoshi font-semibold">
                Evolution of Career Interests
              </p>
              <img
                className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-3 z-10 "
                src={LockIcon}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className=" border-t-2 border-r-2 border-dashed mx-4 border-primary-grey-300 rounded-r-full ">
          <div className="flex gap-12 items-center transform -translate-y-1/2 relative">
            {isInView && (
              <>
                <div className="absolute left-[10px]  w-16 h-16 -top-2 rounded-full -z-10 bg-primary-blue-200 animate-ping "></div>{" "}
                <div className="absolute left-[20px]  w-10 h-10 top-1 rounded-full -z-10 bg-primary-blue-300 animate-ping "></div>{" "}
              </>
            )}

            <ProfileButton
              onClick={() => dispatch(updateStep(step + 1))}
              text={"Start"}
              size={"small"}
              variant={"filled"}
            />
          </div>
        </div>

        <div
          style={{ width: `calc(100vw + 24px)` }}
          className="flex flex-col gap-2 py-8 pr-4 relative  border-l-2  border-dashed border-primary-grey-400 rounded-l-full pl-20  self-end   "
        >
          <div className="absolute top-0 right-0 left-[110px]  border-t-2  border-dashed  border-primary-grey-300 mx-8"></div>
          <div className="absolute bottom-0 right-0 left-[110px]  border-t-2  border-dashed  border-primary-grey-300 mx-8"></div>
          <h3 className="text-primary-green-500 font-medium text-lg font-manrope">
            Measurement of Growth
          </h3>
          <p className="text-sm font-satoshi text-primary-grey-500 tracking-wide font-light ">
            To help you quantify your experiences, see how you’ve grown through
            them and help you showcase your unique process.
          </p>
          <div className="my-2 grid grid-cols-3 gap-2 ">
            <div className="flex flex-col gap-2 relative">
              <img
                className="w-full h-24 md:h-[140px] object-contain "
                src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                alt=""
              />
              <p className="text-center text-xs font-satoshi font-semibold">
                SMART Achievements
              </p>
              <img
                className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-5 "
                src={LockIcon}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="  border-r-2 border-dashed mr-4 -ml-4 py-6 border-primary-grey-300 rounded-r-full "></div>

        <div
          style={{ width: `calc(100vw + 24px)` }}
          className="flex flex-col gap-2 py-8 pr-4 relative  border-l-2 border-dashed border-primary-grey-400 rounded-l-full pl-20  self-end   "
        >
          <div className="absolute top-0 right-0 left-[110px]  border-t-2  border-dashed  border-primary-grey-300 mx-8"></div>
          <div className="absolute bottom-0 right-0 left-[110px]  border-t-2  border-dashed  border-primary-grey-300 mx-8"></div>
          <h3 className="text-primary-yellow-900 font-medium text-lg font-manrope">
            Self Expression
          </h3>
          <p className="text-sm font-satoshi text-primary-grey-500 tracking-wide font-light ">
            To help you let the world know what you believe in and stand for,
            and take control of your perception.
          </p>
          <div className="my-2 grid grid-cols-3 gap-2 ">
            <div className="flex flex-col gap-2 relative">
              <img
                className="w-full h-24 md:h-[140px] object-contain "
                src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                alt=""
              />
              <p className="text-center text-xs font-satoshi font-semibold">
                Value Quiz
              </p>
              <img
                className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-6 "
                src={LockIcon}
                alt=""
              />
            </div>

            <div ref={ref} className="flex flex-col gap-2 relative">
              <img
                className="w-full h-24 md:h-[140px] object-contain "
                src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
                alt=""
              />
              <p className="text-center text-xs font-satoshi font-semibold">
                SDGs
              </p>
              <img
                className="absolute top-full left-1/2 transform -translate-x-1/2 translate-y-6 "
                src={LockIcon}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {showUpButton && <ScrollToTopButton />}
    </div>
  );
};

export default JourneyForm;
