import { Disclosure } from "@headlessui/react";

export default function PublicNavbar() {
  return (
    <Disclosure as="nav" className="public-nav fixed top-0 left-0 right-0 z-20">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <a href="/" className="flex flex-shrink-0 items-center">
                  <img
                    className="h-7 w-auto"
                    src="/assets/caaryaLogos/life.svg"
                    alt="Your Company"
                  />
                </a>
              </div>
              {/* <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <div className="hidden sm:ml-6 sm:flex sm:space-x-6 text-white">
                  <a
                    href="#"
                    className="inline-flex items-center px-1 pt-1 text-sm hover:underline underline-offset-4 text-white font-poppins"
                  >
                    About Us
                  </a>
                  <a
                    href="https://caarya.in/"
                    target="__blank"
                    className="inline-flex items-center px-1 pt-1 text-sm hover:underline underline-offset-4 text-white font-poppins"
                  >
                    <p>Visit Our Website</p>
                  </a>
                </div>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <a
                  href="https://caarya.in/"
                  target="__blank"
                  className="inline-flex items-center px-1 pt-1 text-sm text-white font-poppins"
                >
                  <p>Visit Our Website</p>
                </a>
              </div> */}
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
