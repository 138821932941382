import { Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { BsTextLeft } from "react-icons/bs";
import { HiSparkles } from "react-icons/hi";
import academic from "../achievementTabComponents/assets/field/academic.svg";
import artistic from "../achievementTabComponents/assets/field/artistic.svg";
import athletic from "../achievementTabComponents/assets/field/athletic.svg";
import personal from "../achievementTabComponents/assets/field/personal.svg";
import professional from "../achievementTabComponents/assets/field/professional.svg";

export const fieldOptions = [
  {
    title: "Academic",
    icon: academic,
    tooltip: "lorem ipsum dolor sit ames",
  },
  {
    title: "Artistic",
    icon: artistic,
    tooltip: "lorem ipsum dolor sit ames",
  },
  {
    title: "Athletic",
    icon: athletic,
    tooltip: "lorem ipsum dolor sit ames",
  },
  {
    title: "Personal",
    icon: personal,
    tooltip: "lorem ipsum dolor sit ames",
  },
  {
    title: "Professional",
    icon: professional,
    tooltip: "lorem ipsum dolor sit ames",
  },
];

const DropdownInput = ({
  input,
  setInput,
  field,
  iconType = "text",
  title,
  type,
}) => {
  const inputComponentRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const placeholder = "Select your field";
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputComponentRef.current &&
        !inputComponentRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputComponentRef]);

  const handleFieldSelect = (_field) => {
    setInput({ ...input, [field]: _field.title });
    setShowMenu(false);
  };

  return (
    <div
      id="hover_input"
      ref={inputComponentRef}
      onClick={() => type !== "VIEW" && setIsFocused(true)}
      className={`flex flex-col ${
        isFocused ? "gap-8" : "gap-4"
      } items-start w-full`}
    >
      <div className="flex gap-4 items-center w-full">
        {iconType === "text" ? (
          <div className="border rounded-sm border-primary-blue-500 ">
            <BsTextLeft className="text-primary-blue-500 font-bold" />
          </div>
        ) : (
          <HiSparkles className="text-primary-blue-300 font-bold mt-1 text-lg" />
        )}
        <h2 className="font-manrope text-md lg:text-base text-primary-grey-800 font-medium">
          {title}
        </h2>
      </div>
      {isFocused ? (
        <div className={`flex flex-col gap-4 w-full ${isFocused && "lg:pl-9"}`}>
          {isFocused && (
            <h4 className="font-satoshi text-md text-primary-grey-800 ">
              Select the category in which your achievement fits best
            </h4>
          )}
          <div
            onClick={() => setShowMenu(true)}
            className={`flex w-full items-center gap-2  ${
              isFocused
                ? "border border-primary-blue-300 rounded-lg p-4 py-2 "
                : "border-none p-0 resize-none"
            }  `}
          >
            <p
              className={` w-full text-sm leading-6 ${
                input[field].length === 0
                  ? "text-primary-grey-400"
                  : "text-primary-grey-600"
              }`}
            >
              {input[field].length > 0 ? input[field] : placeholder}
            </p>
            {isFocused && (
              <BiChevronDown className="text-primary-blue-500 text-xl" />
            )}
          </div>
          {isFocused && showMenu && (
            <div className="flex flex-col lg:grid lg:grid-cols-5 lg:gap-6 lg:p-6 border rounded-lg lg:border-none lg:rounded-none ">
              {fieldOptions.map((_field, idx) => {
                const isSelected = input[field] === _field.title;
                return (
                  <div
                    onClick={() => handleFieldSelect(_field)}
                    key={idx}
                    className={`flex lg:flex-col gap-2 px-6 py-4 lg:p-4  items-center relative cursor-pointer hover:bg-primary-grey-100  ${
                      isSelected &&
                      "border border-primary-blue-500 rounded-lg lg:rounded-xl"
                    }  `}
                  >
                    <img
                      className="w-10 h-10 object-contain"
                      src={_field.icon}
                      alt=""
                    />
                    <p className="font-satoshi text-xs">{_field.title}</p>
                    <Tooltip title={_field.tooltip}>
                      <button>
                        <AiOutlineInfoCircle className="absolute top-1/2 lg:top-2 -translate-y-1/2 lg:translate-y-0  right-6 lg:right-2 text-primary-grey-400 " />
                      </button>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <p
          id="hover_input_border"
          className={`leading-6 text-sm w-full  ${
            input[field].length === 0 && "text-primary-grey-400"
          } `}
        >
          {input[field].length > 0 ? input[field] : placeholder}
        </p>
      )}
    </div>
  );
};

export default DropdownInput;
