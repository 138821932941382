import { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdEditNote, MdOutlineDelete } from "react-icons/md";
import StairIcon from "../../../../MeProfilePage/assets/stairs.svg";
import { fieldOptions } from "./inputs/DropdownInput";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/profile-form";
import { Modal, useMediaQuery, useTheme } from "@mui/material";
import DeleteAchievementModal from "./DeleteAchievementModal";

const AchievementCard = ({
  achievement,
  draft,
  field,
  achievementDataModalProps,
  setAchievementDataModalProps,
  isMobile = false,
}) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  const achievements = details[field];
  const [showMenu, setShowMenu] = useState(false);
  const theme = useTheme();
  const largeView = useMediaQuery(theme.breakpoints.up("lg"));
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);

  const { id, title, field: category } = achievement;
  const icon =
    fieldOptions.find((option) => option.title === category)?.icon ?? StairIcon;

  const handleDelete = () => {
    const existAsDraft = achievements.draft.find(
      (_achieve) => _achieve.id === id
    );
    const existAsAdded = achievements.added.find(
      (_achieve) => _achieve.id === id
    );
    let updatedAchievements;
    if (existAsDraft) {
      updatedAchievements = {
        ...achievements,
        draft: achievements.draft.filter((_achieve) => _achieve.id !== id),
      };
    } else if (existAsAdded) {
      updatedAchievements = {
        ...achievements,
        added: achievements.added.filter((_achieve) => _achieve.id !== id),
      };
    }
    dispatch(updateDetails({ field, value: updatedAchievements }));
  };

  useEffect(() => {
    if (isMobile) return;
    function handleClickOutside(event) {
      if (
        (menuRef.current && !menuRef.current.contains(event.target)) ||
        (menuBtnRef && menuBtnRef?.current?.contains(event.target))
      ) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, isMobile]);

  const handleCardClick = (e) => {
    if (
      menuRef?.current?.contains(e.target) ||
      menuBtnRef?.current?.contains(e.target)
    )
      return;
    setAchievementDataModalProps({
      ...achievementDataModalProps,
      open: true,
      type: "VIEW",
      editID: id,
    });
  };

  const handleAchievementEditClick = () => {
    if (isMobile) {
      setShowMenu(!showMenu);
    } else setShowMenu(true);
  };
  return (
    <div
      onClick={handleCardClick}
      id="quest_card"
      className={`p-8 cursor-pointer rounded-xl shadow-lg hover:shadow-xl border flex flex-col gap-4 relative ${
        showMenu ? "bg-primary-grey-150" : "bg-white"
      } h-[196px]`}
    >
      {draft && (
        <p className="bg-primary-blue-100 absolute top-3 right-12 font-satoshi text-xs font-bold text-primary-grey-400 rounded-lg p-1 px-2">
          DRAFT
        </p>
      )}
      <button
        ref={menuBtnRef}
        onClick={handleAchievementEditClick}
        className={` ${
          !showMenu && largeView && "hover-show-quest-btn"
        } absolute top-2 right-2 w-8 h-8 rounded-full grid place-items-center bg-primary-grey-200`}
      >
        <BsThreeDotsVertical className=" font-semibold" />
      </button>
      {showMenu && (
        <div
          ref={menuRef}
          className="flex flex-col absolute top-12 right-4 bg-white border-2 border-opacity-20 rounded-xl w-40"
        >
          <div
            onClick={() => {
              setAchievementDataModalProps({
                ...achievementDataModalProps,
                open: true,
                type: "EDIT",
                editID: id,
              });
              setShowMenu(false);
            }}
            className="p-3 px-4 flex gap-4 items-center border-b text-primary-grey-500 hover:bg-primary-blue-100  "
          >
            <MdEditNote />
            <p className=" font-bold text-xs">Edit</p>
          </div>
          <div
            onClick={() => {
              setShowDeleteConfirmation(true);
              setShowMenu(false);
            }}
            className="p-3 px-4 flex gap-4 items-center  text-primary-grey-500   hover:bg-primary-blue-100  "
          >
            <MdOutlineDelete />
            <p className=" font-bold text-xs">Delete</p>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2">
        <img src={icon} alt="" className="w-10 object-contain" />
        <p className="font-satoshi text-primary-grey-400 text-xs">
          {category === "" ? "Un-Specified Field" : category}
        </p>
      </div>
      <h3 className="text-sm font-satoshi font-bold leading-6  line-clamp-2  ">
        {title === "" ? "Untitled Accomplishment" : title}
      </h3>
      <Modal
        ref={menuRef}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <DeleteAchievementModal
          handleCancel={() => setShowDeleteConfirmation(false)}
          handleDelete={handleDelete}
        />
      </Modal>
    </div>
  );
};

export default AchievementCard;
