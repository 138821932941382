import React, { useState, useEffect } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressTimerBar = ({ clockIn, duration }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const clockInTime = new Date(clockIn).getTime();
    const totalDurationMs = duration * 60 * 60 * 1000; // assuming duration is in hours for the purpose of this design

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsed = currentTime - clockInTime;

      if (elapsed < totalDurationMs) {
        setElapsedTime(elapsed);
      } else {
        setElapsedTime(totalDurationMs);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [clockIn, duration]);

  const totalDurationMs = duration * 60 * 60 * 1000; // again assuming duration is in hours
  const percentage = (elapsedTime / totalDurationMs) * 100;

  // Formatting time in hours for display
  const formatHours = (time) => {
    const hours = (time / (1000 * 60 * 60)).toFixed(1);
    return `${hours} hr`;
  };

  return (
    <div style={{ width: 64, height: 64 }}>
      <CircularProgressbarWithChildren
        // value={percentage}
        value={40}
        styles={buildStyles({
          pathColor: "#FFFFFF",
          trailColor: "rgba(255, 255, 255, 0.2)",
          backgroundColor: "#4D4F5C",
        })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M22.0249 15.1157H19.0557L19.9442 7.11923C19.9881 6.72354 19.7773 6.34323 19.4185 6.17073C19.0595 5.99816 18.631 6.07123 18.3495 6.35279L9.31197 15.3903C9.04384 15.6584 8.96366 16.0616 9.10872 16.412C9.25384 16.7623 9.59566 16.9907 9.97484 16.9907H12.9441L12.0556 24.9872C11.9618 25.8818 13.0434 26.366 13.6503 25.7536L22.6878 16.7161C22.956 16.448 23.0362 16.0448 22.8911 15.6944C22.746 15.344 22.4042 15.1157 22.0249 15.1157ZM14.2143 22.5379L14.9233 16.1566C14.9528 15.8916 14.868 15.6266 14.6902 15.4279C14.5123 15.2292 14.2583 15.1156 13.9916 15.1156H12.2382L17.7855 9.56835L17.0765 15.9496C17.047 16.2146 17.1319 16.4796 17.3097 16.6784C17.4876 16.877 17.7416 16.9906 18.0083 16.9906H19.7617L14.2143 22.5379Z"
            fill="white"
          />
        </svg>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default ProgressTimerBar;
