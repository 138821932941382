import Button from "helpers/constants/button";
import React from "react";
import { Img } from "../Img/Img";

const Profile = () => {
  return (
    <>
      <div className="profile-container">
        <div className="container-2 ">
          <div className="div-2">
            <div className="overlap-2">
              <div className="div-2">
                <div className="portrait-young-happy-wrapper">
                  <img
                    className="portrait-young-happy"
                    alt="Portrait young happy"
                    src="assets\images\img_portraityoung.png"
                  />
                </div>
                <div className="frame-79">
                  <Img src="assets/images/img.png" />

                  <p className="leslie-alexander">
                    <span className="text-wrapper-22">Leslie Alexander</span>
                  </p>
                  <p className="shaheed-bhagat-singh">
                    <span className="text-wrapper-23">
                      Shaheed Bhagat Singh College
                    </span>
                  </p>
                  <div className="frame-80">
                    <p className="freshman">
                      <span className="text-wrapper-24">Freshman (1st)</span>
                    </p>
                  </div>
                  <Button>
                    <div className="edit-wrapper">
                      <img
                        className="img-2"
                        alt="Edit"
                        src="assets\svg\pass\edit.svg"
                      />
                    </div>
                  </Button>
                </div>
              </div>
              <div className="img-wrapper">
                <Button>
                  <img
                    className="img-2"
                    alt="Edit"
                    src="assets\svg\pass\edit.svg"
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="frame-81">
            <div className="frame-82">
              <img
                className="ellipse-4"
                alt="Ellipse"
                src="assets/svg/pass/Ellipse 1198.svg"
              />
              <p className="CGC-vizag">
                <span className="text-wrapper-11">CGC Vizag</span>
              </p>
            </div>
            <div className="frame-83">
              <img
                className="ellipse-4"
                alt="Ellipse"
                src="assets/svg/pass/Ellipse 1198.svg"
              />
              <p className="moko-CREW">
                <span className="text-wrapper-11">Moko CREW</span>
              </p>
            </div>
          </div>
        </div>
        <div className="frame-84">
          <div className="frame-85">
            <p className="interested-in">
              <span className="text-wrapper-26">Interested In</span>
            </p>
          </div>
          <div className="frame-86">
            <div className="frame-87">
              <p className="span-wrapper-7">
                <span className="text-wrapper-8"># Movies</span>
              </p>
            </div>
            <div className="frame-88">
              <p className="span-wrapper-7">
                <span className="text-wrapper-8"># Sports</span>
              </p>
            </div>
            <div className="frame-89">
              <p className="span-wrapper-7">
                <span className="text-wrapper-8"> # Reading</span>
              </p>
            </div>
            <div className="frame-90">
              <p className="span-wrapper-7">
                <span className="text-wrapper-8"># Swimming</span>
              </p>
            </div>
            <div className="frame-91">
              <p className="span-wrapper-7">
                <span className="text-wrapper-8"> # Baking</span>
              </p>
            </div>
            <div className="frame-92">
              <p className="add-tag">
                <span className="text-wrapper-12"> # Add Tag</span>
              </p>
            </div>
          </div>
        </div>
        <div className="frame-93">
          <div className="view-moodboard-wrapper">
            <p className="view-moodboard">
              <a className="text-wrapper-27" href="#">
                View Moodboard
              </a>
            </p>
          </div>
        </div>
        <div className="frame-94">
          <div className="frame-85">
            <p className="contact-details">
              <span className="text-wrapper-26">Contact Details</span>
            </p>
          </div>
          <div className="frame-95">
            <div className="frame-96">
              <div className="frame-97">
                <Button>
                  <img
                    className="img-2"
                    alt="Email"
                    src="assets/svg/pass/Email.svg"
                  />
                </Button>
              </div>
              <p className="span-wrapper-9">
                <span className="text-wrapper-18">jimmy_page@email.com</span>
              </p>
            </div>
            <div className="frame-98">
              <div className="frame-97">
                <Button>
                  <img
                    className="img-2"
                    alt="Phone"
                    src="assets/svg/pass/Phone.svg"
                  />
                </Button>
              </div>
              <p className="span-wrapper-9">
                <span className="text-wrapper-18">00000 00000</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
