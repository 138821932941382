import { useDispatch, useSelector } from "react-redux";
import { motion, useAnimation } from "framer-motion";
import { nextStep, prevStep, saveData } from "redux/typeform";
import { ArrowLeft } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { typeFormQuestions } from "helpers/constants/driveTypeForm";
const activeSectionVariant = {
  active: {
    opacity: 1,
    // y: "0",
    transition: {
      duration: 0.3,
    },
  },
  inactive: {
    opacity: 0,
    // y: "-100%",
    transition: {
      duration: 0.3,
    },
  },
};
const activeBgVariant = {
  active: {
    opacity: 1,
    scale: "1",
    transition: {
      duration: 0.5,
    },
  },
  inactive: {
    opacity: 0,
    scale: "0.8",
    transition: {
      duration: 0.5,
    },
  },
};

export default function Interval({ end }) {
  const { currentSection } = useSelector((state) => state.typeForm);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(
    parseInt(currentSection?.replace("Section", "")) * 40
  );
  const controls = useAnimation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    controls.start({ height: height });
  }, [controls, height]);
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        let el = document.getElementById("selected");
        var elDistanceToTop =
          window.pageYOffset + el.getBoundingClientRect().top;
        setHeight(elDistanceToTop + 100);
      }, 1000);
    }
  }, [loaded]);
  const handleKeyDown = (event) => {
    if (window.innerWidth > 1024) {
      if (event.key === " " || event.keyCode === 32) {
        if (end) {
          dispatch(saveData({ save: true }));
          dispatch(nextStep({}));
        } else dispatch(nextStep({ interval: true }));
      }
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      tabIndex="0"
      className="min-h-screen relative"
    >
      <motion.div
        initial={activeSectionVariant.inactive}
        animate={
          loaded ? activeSectionVariant.active : activeSectionVariant.inactive
        }
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="flex flex-row relative items-stretch min-h-[80vh] justify-center w-full p-4 lg:px-[120px]"
      >
        <div className="w-0.5 bg-primary-neutral-100">
          <motion.div
            className="w-0.5 relative"
            animate={controls}
            transition={{ duration: 0.5 }}
            style={{
              height: height,
              background: typeFormQuestions?.find(
                (a, idx) => currentSection === `Section${idx + 1}`
              )?.intoductionScreen?.lineColor,
            }}
          >
            <div
              className="w-3 h-3 flex flex-row items-center justify-center absolute rounded-full -left-[5px] -bottom-[5px]"
              style={{
                background: typeFormQuestions?.find(
                  (a, idx) => currentSection === `Section${idx + 1}`
                )?.intoductionScreen?.stopColor,
              }}
            >
              <div className="bg-white rounded-full w-2 h-2" />
            </div>
          </motion.div>
        </div>
        <div className="flex flex-col items-start pl-8 pb-20 lg:pl-20">
          {typeFormQuestions?.map((item, idx) => {
            if (currentSection === `Section${idx + 1}`) {
              return (
                <div id="selected" className="relative">
                  <SelectedState
                    {...item?.intoductionScreen}
                    onClick={() => {
                      if (end) {
                        dispatch(saveData({ save: true }));
                        dispatch(nextStep({}));
                      } else dispatch(nextStep({ interval: true }));
                    }}
                    idx={idx + 1}
                    setLoaded={setLoaded}
                  />
                </div>
              );
            } else {
              return (
                <div className="relative">
                  <div className="absolute -left-[39px] top-4 lg:-left-[87px]">
                    <div
                      className="w-3 h-3 flex flex-row items-center justify-center rounded-full bg-primary-neutral-100"
                      style={{
                        background:
                          parseInt(currentSection?.replace("Section", "")) >
                          idx + 1
                            ? typeFormQuestions?.find(
                                (a, idx) =>
                                  currentSection === `Section${idx + 1}`
                              )?.intoductionScreen?.doneColor
                            : "#F3F2F2",
                      }}
                    >
                      {parseInt(currentSection?.replace("Section", "")) <
                        idx + 1 && (
                        <div className="bg-white rounded-full w-2 h-2" />
                      )}
                    </div>
                  </div>
                  <NonSelectedState
                    name={item?.intoductionScreen?.title}
                    done={
                      parseInt(currentSection?.replace("Section", "")) > idx + 1
                    }
                  />
                </div>
              );
            }
          })}
        </div>
      </motion.div>{" "}
      <div
        style={{ boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.20)" }}
        className="w-full bg-white border-t border-primary-gray-200 fixed bottom-0 z-40"
      >
        <div className="mx-auto w-full flex flex-row items-center justify-between max-w-6xl py-6 px-4">
          <button
            className="flex justify-center items-center font-inter text-sm text-primary-gray-400 font-semibold py-0.5"
            onClick={() => {
              dispatch(
                prevStep({
                  interval: currentSection === `Section1` ? false : true,
                })
              );
            }}
          >
            <ArrowLeft size={16} className="mr-2" />
            Back
          </button>
        </div>
      </div>
      {/* <motion.div
        style={{
          background: backgroundGradient,
        }}
        initial={activeBgVariant.inactive}
        animate={
          loadedWhite ? activeBgVariant.active : activeBgVariant.inactive
        }
        transition={{ duration: 0.5 }}
        className="h-[40vh] relative z-10"
      >
        <div className="absolute top-0 right-0 left-0">
          <motion.img
            initial={activeSectionVariant.inactive}
            animate={
              loaded
                ? activeSectionVariant.active
                : activeSectionVariant.inactive
            }
            transition={{ duration: 0.5 }}
            src={image}
            alt=""
            className={classNames("object-cover w-full h-[40vh] z-0")}
            onLoad={() => {
              setLoaded(true);
            }}
          />
        </div>
        <div className="pt-[72px] h-[35vh] py-8 px-6 mx-auto w-full max-w-4xl flex flex-col items-center justify-center space-y-6">
          <div className="" />
          <h1 className="font-manrope text-center font-semibold text-2xl lg:text-[32px] text-white">
            {title}
          </h1>
        </div>
      </motion.div>
      <img
        src="/assets/images/bgwhite.png"
        alt=""
        className={classNames(
          "object-cover lg:object-fill w-full absolute top-[30vh] left-0 right-0 h-[40vh] z-20 object-left"
        )}
        onLoad={() => {
          setLoadedWhite(true);
        }}
      />
      <div className="py-16 lg:py-28 flex h-[55vh] flex-col items-center w-full space-y-8 px-6 z-20 relative -mt-20">
        <div className=" flex flex-col items-start w-full space-y-4 max-w-4xl mx-auto">
          {section && (
            <h1 className="hidden lg:block font-manrope text-left font-medium text-xl text-primary-gray-800">
              {section}
            </h1>
          )}
          <p className="font-manrope text-left leading-8 font-light text-sm lg:text-lg text-primary-gray-800">
            {description}
          </p>
        </div>

        <div
          style={{ ...buttonStyle }}
          onClick={() => {
            if (end) {
              dispatch(saveData({ save: true }));
              dispatch(nextStep({}));
            } else dispatch(nextStep({ interval: true }));
          }}
          className="cursor-pointer flex flex-row items-center justify-center space-x-2 rounded-[12px] py-3.5 px-6"
        >
          <p className="text-sm font-manrope font-semibold">Continue</p>
          <ArrowRight size={24} className="" />
        </div>
      </div>
      <div
        style={{ boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.20)" }}
        className="w-full bg-white border-t border-primary-gray-200 fixed bottom-0 z-40"
      >
        <div className="mx-auto w-full flex flex-row items-center justify-between max-w-6xl py-6 px-4">
          <button
            className="flex justify-center items-center font-inter text-sm text-primary-gray-400 font-semibold py-0.5"
            onClick={() => {
              dispatch(prevStep({ interval: true }));
            }}
          >
            <ArrowLeft size={16} className="mr-2" />
            Previous
          </button>
        </div>
      </div> */}
    </div>
  );
}

const SelectedState = ({
  onClick,
  title,
  description,
  image,
  buttonStyle,
  section,
  idx,
  setLoaded,
}) => {
  return (
    <div className="lg:p-12 py-10 flex flex-col lg:flex-row-reverse space-y-10 lg:space-y-0 lg:space-x-2 items-start lg:items-center justify-between">
      <img
        onLoad={() => {
          setLoaded(true);
        }}
        src={image}
        alt=""
        className="max-h-[120px] lg:max-h-[500px]"
      />
      <div className="flex flex-col items-start space-y-10 w-full lg:max-w-[50%]">
        <h1
          className={`section-Section${idx}-title font-medium leading-[42px] tracking-[2.8px] lg:leading-[60px] lg:tracking-[4px] text-[28px] lg:text-[40px] font-satoshi`}
        >
          {title}
        </h1>
        <div className="flex flex-col space-y-2 font-lato">
          <h2 className="text-primary-neutral-800 font-lato text-base leading-6 tracking-[2.4px] lg:text-lg font-semibold lg:leading-7 lg:tracking-[2.7px]">
            {section}
          </h2>
          <p className="text-black font-lato text-sm leading-[22px] tracking-[1.12px] lg:text-base font-light lg:leading-7 lg:tracking-[1.28px]">
            {description}
          </p>
        </div>
        <div className="flex flex-col space-y-2">
          <button
            onClick={onClick}
            style={{
              background: buttonStyle?.background,
              boxShadow: buttonStyle?.boxShadow,
            }}
            className="flex flex-row items-center justify-center space-x-3 rounded-full py-4 pl-8 pr-5 text-white font-lato text-base font-semibold leading-6 tracking-[0.8px]"
          >
            <p>Start</p>
            <div className="w-6 h-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                  fill="white"
                />
              </svg>
            </div>
          </button>
          <div className="px-2 hidden lg:flex flex-row items-center space-x-2 text-primary-neutral-400 font-lato text-xs font-normal">
            <p>
              or press <span className="font-bold">Space</span> to continue{" "}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M14.9997 7.5V10.8333H4.99967V7.5H3.33301V12.5H16.6663V7.5H14.9997Z"
                fill="#9C9A96"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const NonSelectedState = ({ name, done }) => {
  return (
    <div className="py-3 flex flex-row space-x-4 items-center text-primary-neutral-400 font-satoshi text-sm lg:text-base font-bold leading-5 tracking-[1.4px] lg:leading-6 lg:tracking-[1.6px]">
      <p>{name}</p>
      {done && (
        <div className="border-2 flex flex-row items-center justify-center rounded-full border-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M7.50065 13.4993L4.00065 9.99935L2.83398 11.166L7.50065 15.8327L17.5007 5.83268L16.334 4.66602L7.50065 13.4993Z"
              fill="#33A329"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
