import InterestCard from "components/ProfileForm/MobileForm/components/InterestSelectionForm/InterestCard";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateDetails } from "redux/profile-form";

const WebActiveInterestSelection = ({ field }) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  const [showNewCard, setShowNewCard] = useState(
    details[field].length === 0 ? true : false
  );
  const cardContainerRef = useRef(null);

  const handleAddNewCard = async () => {
    await setShowNewCard(true);
    cardContainerRef.current.scrollTop = 0;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#FAFAFA" : "",
    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      details[field],
      result.source.index,
      result.destination.index
    );
    dispatch(updateDetails({ field, value: items }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="w-full flex flex-col gap-8 h-full overflow-y-hidden  ">
      {!showNewCard && (
        <div className="flex flex-col px-4  ">
          <div
            id="interest_hover_view"
            onClick={handleAddNewCard}
            className="flex items-center gap-4 max-w-max cursor-pointer p-3 px-4 rounded-xl"
          >
            <button className="w-10 h-10 rounded-full border-2 font-semibold border-primary-yellow-500 grid place-items-center text-xl text-primary-yellow-500 ">
              <MdAdd />
            </button>
            <p className="font-satoshi text-md">
              Add what you were interested in before this
            </p>
          </div>
          <div id="hover_view_sibling" className="pr-12 pl-8">
            <div
              id="hover_view_card"
              className={` border-2 border-dashed border-primary-grey-200 rounded-3xl w-full`}
            ></div>
          </div>
        </div>
      )}
      <div
        ref={cardContainerRef}
        className={` flex flex-col gap-10 overflow-y-scroll pr-2 pb-14
         scroll-smooth`}
      >
        {showNewCard && (
          <InterestCard
            setShowNewCard={setShowNewCard}
            type={"new"}
            field={field}
            isFirstCard={details[field].length === 0}
          />
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col gap-8 "
              >
                {[...details[field]]
                  .sort((b, a) => a?.index - b?.index)
                  .map((interest, idx) => (
                    <Draggable
                      key={`item-${idx}`}
                      draggableId={`item-${idx}`}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <InterestCard
                            setShowNewCard={setShowNewCard}
                            key={interest.id}
                            field={field}
                            data={interest}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default WebActiveInterestSelection;
