import { dreamCompanyOptions } from "components/ProfileForm/MobileForm/components/DreamCompanyForm/utils/constant";
import { scrollToBottom } from "helpers/utils/profileForm/functions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showNavigation, updateDetails } from "redux/profile-form";
import { showToast } from "redux/toaster";

const WebDreamCompanyForm = ({ field }) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showNavigation());
  }, []);
  useEffect(() => {
    if (details[field].length === 4) {
      scrollToBottom();
    }
  }, [details[field]]);
  const handleCompanySelection = (val) => {
    if (details[field].includes(val)) {
      const filteredArr = details[field].filter((comp) => comp !== val);
      dispatch(updateDetails({ field, value: filteredArr }));
      return;
    }
    if (details[field].length === 4) {
      dispatch(
        showToast({
          message: "Cannot select more than 4 options",
          type: "error",
        })
      );
      return;
    }
    dispatch(updateDetails({ field, value: [...details[field], val] }));
  };
  return (
    <div className="px-28 py-8  ">
      <div className="flex flex-col gap-2">
        <h4 className="text-xl text-primary-blue-500  font-medium ">
          Which of these causes would you like your work to positively impact?
        </h4>
        <div className="flex justify-between ">
          <p className="font-satoshi text-primary-grey-600 text-md ">
            Select the ones you like the most
          </p>
          <div className="flex flex-col items-center text-center ">
            <p className="font-satoshi text-primary-grey-600 text-md font-bold tracking-widest">
              ({details[field].length} / 4)
            </p>
            <p className="font-satoshi text-primary-grey-600 text-xs ">
              selected
            </p>
          </div>
        </div>
      </div>
      <div className="py-10 pt-8 grid grid-cols-4 gap-8  pb-16   ">
        {dreamCompanyOptions.map((option, idx) => (
          <div
            onClick={() => handleCompanySelection(option.title)}
            key={idx}
            className={`cursor-pointer py-4 px-2 ${
              details[field].includes(option.title) &&
              "border border-primary-blue-500 bg-primary-grey-100"
            } rounded-2xl flex flex-col gap-4 items-center hover:bg-primary-grey-150 `}
          >
            <img className="w-14 h-14 object-contain" src={option.icon} />

            <p className="font-satoshi text-primary-grey-800 text-sm text-center ">
              {option.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebDreamCompanyForm;
