import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import usePhaseOne from "components/ProfileForm/hooks/usePhaseOne";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { QuestionArr } from "helpers/constants/profileForm";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateQuizStep, updateStep } from "redux/profile-form";
import { showToast } from "redux/toaster";

const WebQuizNavigation = ({ totalSteps, field, setShowRiasecInfo }) => {
  const { step: formStep, details } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const quizStep = useSelector(
    (state) => state.profileForm.phaseOne.details.quiz.quizStep
  );
  const { checkUnattemptedQuiz } = usePhaseOne(field);

  const dispatch = useDispatch();
  const getDisabledState = () => {
    const currentQuiz = details[field]?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );
    if (currentQuiz && currentQuiz?.options.length > 0) return false;
    return true;
  };

  const handleButtonWrapperClick = () => {
    if (details[field]?.quizData.length !== QuestionArr.length) {
      dispatch(updateQuizStep(checkUnattemptedQuiz()));
      if (quizStep !== checkUnattemptedQuiz() - 1) {
        dispatch(
          showToast({
            message:
              "You have to select at least one option from each section .",
            type: "error",
          })
        );
      }
    }

    return;
  };

  const handleClickNext = () => {
    if (
      details[field].quizData.length >= QuestionArr.length - 1 &&
      quizStep === QuestionArr.length - 1
    ) {
      setShowRiasecInfo(true);
    } else dispatch(updateQuizStep(quizStep + 1));
  };
  return (
    <div className="bg-white z-10 absolute bottom-0 inset-x-0">
      <div className="h-1 w-full bg-primary-grey-200 relative">
        <div
          style={{ width: (formStep / totalSteps) * 100 + "%" }}
          className={`absolute  left-0 top-0 bottom-0 bg-primary-blue-500 transition-all duration-300 ease-in-out rounded-r-full `}
        ></div>
      </div>

      <div className="flex justify-between relative  gap-6 items-center p-4 lg:py-2 lg:px-20">
        <ProfileButton
          onClick={() => dispatch(updateStep(formStep - 1))}
          text={"Back"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
        />
        <div
          onClick={handleButtonWrapperClick}
          className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 "
        >
          <ProfileButton
            onClick={handleClickNext}
            disabled={getDisabledState()}
            text={"Next"}
            size={"large"}
            variant={"filled"}
            icon={<ChevronRightIcon className="w-6" />}
            iconAlign={"right"}
          />
        </div>
      </div>
    </div>
  );
};

export default WebQuizNavigation;
