import { RiHistoryFill } from "react-icons/ri";
import { SiTarget } from "react-icons/si";

import { ReactComponent as Emoji1 } from "../../assets/FocusArea/Emoji1.svg";
import { ReactComponent as Emoji2 } from "../../assets/FocusArea/Emoji2.svg";
import { ReactComponent as Emoji3 } from "../../assets/FocusArea/Emoji3.svg";
import { ReactComponent as Emoji4 } from "../../assets/FocusArea/Emoji4.svg";
import { ReactComponent as Emoji5 } from "../../assets/FocusArea/Emoji5.svg";

const SessionCards = ({
  onCardClick,
  startTime,
  endTime,
  goalTitle,
  duration,
  comments,
  mood,
}) => {
  function formatTime(isoString) {
    const date = new Date(isoString);
    // Example: '11:00 AM'
    return date
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toLowerCase();
  }

  const multiLineTruncateStyle = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div
      onClick={onCardClick}
      style={{
        boxShadow:
          "0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -2px rgba(0,0,0,0.1)",
      }}
      className="bg-white mb-8 w-[293px] max-w-[313px] max-h-[235px] rounded-[20px] p-[16px] space-y-4  "
    >
      {/* <div className="text-[#9C9A96] bg-[#F3F2F2] px-2 py-1 rounded-md">
          <p className="text-[#363430] font-lato font-bold text-[10px]">
            Session {sessionNumber}/{totalSessions}
          </p>
        </div> */}
      <div className="flex flex-row items-center justify-between">
        <div>
          <div className="flex flex-row items-start space-x-2">
            <div className="py-1">
              <RiHistoryFill className="text-[#816FE9]" />
            </div>
            <div className="flex flex-col">
              <div>
                <p className="font-lato font-bold text-[18px] text-[#363430]">
                  {duration}
                </p>
              </div>
              <div>
                <p className="text-[#696763] font-lato font-normal text-[10px]">
                  {formatTime(startTime)} - {formatTime(endTime)}
                </p>
              </div>{" "}
            </div>
          </div>
        </div>

        <div>
          {mood?.emoji === 1 && <Emoji1 className="w-[40px] h-[40px]" />}
          {mood?.emoji === 2 && <Emoji2 className="w-[40px] h-[40px]" />}
          {mood?.emoji === 3 && <Emoji3 className="w-[40px] h-[40px]" />}
          {mood?.emoji === 4 && <Emoji4 className="w-[40px] h-[40px]" />}
          {mood?.emoji === 5 && <Emoji5 className="w-[40px] h-[40px]" />}
        </div>
      </div>

      <div className="border-[1px] items-center border-[#F3F2F2] px-[10px] py-[6px] flex flex-row space-x-2 rounded-lg">
        <div className="flex">
          <SiTarget className="text-[#ED4C41]" />
        </div>

        <div className="flex flex-grow">
          <p
            style={multiLineTruncateStyle}
            className="text-[#9C9A96] font-lato font-semibold text-[10px]"
          >
            {goalTitle}
          </p>
        </div>
      </div>
      <div className="space-y-2 flex flex-col">
        <div className="w-[273px] max-h-[42px]">
          <p
            style={multiLineTruncateStyle}
            className="text-[#696763] font-normal font-lato text-sm"
          >
            {comments}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SessionCards;
