import { updateMyProfile } from "config/APIs/users";
import { useSelector } from "react-redux";
import Lottie from "react-lottie-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import sun from "assets/lotties/sun.json";
import hello from "assets/lotties/hello.json";
import tie from "assets/lotties/tie.json";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.4, // Number of cards shown per view, adjust as needed
  slidesToScroll: 1,
};
function Welcome() {
  const history = useHistory();
  const user = useSelector((state) => state?.user?.user);

  const onSubmit = async () => {
    try {
      await updateMyProfile(user?._id, {
        metaData: { ...user?.metaData, onboarding: false },
      });
      history.push({ pathname: "/dashboard", state: { loader: true } });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="bg-[#1F2426] py-12 px-6 space-y-20">
      <div className="flex flex-col space-y-6">
        <h1 className="text-primary-neutral-50 font-lato text-xl font-bold leading-8 tracking-[0.4px] capitalize">
          How'dy from the ops manager, {user?.name} !
        </h1>
        <div className="flex flex-col space-y-4">
          <div className="w-8 h-8">
            <Lottie
              loop
              animationData={hello}
              play
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <p className="text-primary-neutral-100 font-satoshi text-base leading-6 tracking-[0.64px]">
            Good morning {user?.name}. I am your operations manager. I will be
            handling blah blah blah
            <br />
            <br /> Check out this video about Caarya
          </p>
        </div>
        <div className="w-full flex flex-row items-center justify-center h-[35vh] aspect-video rounded-2xl bg-[#3A4042]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M19.9999 3.33594C10.7999 3.33594 3.33325 10.8026 3.33325 20.0026C3.33325 29.2026 10.7999 36.6693 19.9999 36.6693C29.1999 36.6693 36.6666 29.2026 36.6666 20.0026C36.6666 10.8026 29.1999 3.33594 19.9999 3.33594ZM16.6666 27.5026V12.5026L26.6666 20.0026L16.6666 27.5026Z"
              fill="#E7E6E5"
            />
          </svg>
        </div>
        <p className="text-primary-neutral-100 font-satoshi text-base leading-6 tracking-[0.64px]">
          You can find this video in your caarya space which will hold all imp
          work resources
        </p>
      </div>

      <div className="flex flex-col space-y-6">
        <h1 className="text-primary-neutral-50 font-lato text-xl font-bold leading-8 tracking-[0.4px] capitalize">
          Hey {user?.name}, I am your professional growth manager
        </h1>
        <div className="flex flex-col space-y-4">
          <div className="w-8 h-8">
            <Lottie
              loop
              animationData={tie}
              play
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <p className="text-primary-neutral-100 font-satoshi text-base leading-6 tracking-[0.64px]">
            I will be doing blah blah blah
            <br />
            <br /> I have unlocked a couple of info cards that will help you
            understand career development better.
          </p>
        </div>
        <div className="block">
          <Slider {...settings}>
            {["Future of Careers", "Career Development Pathways"]?.map(
              (item) => {
                return (
                  <div className="pr-2.5">
                    <div className="bg-[#3A4042] rounded-2xl p-4 flex flex-row items-end h-[240px] w-[200px]">
                      <p className="text-primary-neutral-50 font-lato text-base font-bold leading-6 tracking-[0.32px] capitalize">
                        {item}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </Slider>
        </div>
        <p className="text-primary-neutral-100 font-satoshi text-base leading-6 tracking-[0.64px]">
          Check them out in your space
        </p>
      </div>

      <div className="flex flex-col space-y-6">
        <h1 className="text-primary-neutral-50 font-lato text-xl font-bold leading-8 tracking-[0.4px] capitalize">
          Yo {user?.name}! I am your talent manager.{" "}
        </h1>
        <div className="flex flex-col space-y-4">
          <div className="w-8 h-8">
            <Lottie
              loop
              animationData={sun}
              play
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <p className="text-primary-neutral-100 font-satoshi text-base leading-6 tracking-[0.64px]">
            I represent you with other startups. <br />
            <br />
            Keep checking your inbox for msgs from me
          </p>
        </div>
      </div>
      <div
        onClick={() => {
          onSubmit();
        }}
        className="py-4 px-6 flex flex-row items-center justify-center rounded-full bg-primary-red-500 text-center text-white font-satoshi text-base font-bold leading-6 tracking-[0.8px] capitalize"
      >
        Get started with my Caarya life
      </div>
    </div>
  );
}

export default Welcome;
