import Achievements from "components/CareerFlow/Achievements";
import CareerFooter from "components/CareerFlow/CareerFooter";
import NeoGeneralistProfile from "components/CareerFlow/NeoGeneralistProfile";
import PR from "components/CareerFlow/PR";
import ServicesOffered from "components/CareerFlow/ServicesOffered";

const CareerFlow = () => {
  return (
    <div className="relative overflow-y-auto max-h-[90vh] bg-[#fafafa]">
      <div className="space-y-2 z-20 bg-primary-neutral-100">
        <div
          style={{
            background:
              "linear-gradient(rgb(255, 233, 154) 0%, rgb(245 237 218) 100%)",
          }}
          className="h-[56px] md:hidden md:bg-white sm fixed top-0 left-0 right-0 z-30"
        />
        <NeoGeneralistProfile />
        <Achievements />
        <PR />
        {/* <CareerWorkbooks /> */}
        <ServicesOffered />
        <CareerFooter />
        {/* <CuratedWorkContracts /> */}
      </div>
    </div>
  );
};

export default CareerFlow;
