import { showToast } from "redux/toaster";
import {
  completePhaseOne,
  createProfileFailure,
  createProfileStart,
  createProfileSuccess,
  fetchProfileDataStart,
  updateDetails,
} from ".";
import { uploadProfileImage } from "config/APIs/me/phaseOne/uploadProfileImage";
import { createPhaseOneProfile } from "config/APIs/users";

export const createMeProfile = (postData, userID) => async (dispatch) => {
  dispatch(createProfileStart());

  try {
    const { data } = await createPhaseOneProfile(userID, postData);
    dispatch(createProfileSuccess());
    dispatch(showToast({ message: "Form is submitted" }));
    dispatch(completePhaseOne());
  } catch (error) {
    dispatch(createProfileFailure(error.message));
    dispatch(showToast({ message: error.message, type: "error" }));
  }
};

export const uploadPhaseoneProfile = (file, name) => async (dispatch) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("name", name);
  try {
    const { data: res } = await uploadProfileImage(formData);
    console.log(res);
    dispatch(updateDetails({ field: "profileImage", value: res.data.link }));
    dispatch(showToast({ message: res.message }));
  } catch (error) {
    dispatch(showToast({ message: error.message, type: "error" }));
  }
};

export const fetchCandidateData = (userID, fallback) => async (dispatch) => {
  dispatch(fetchProfileDataStart());
  try {
    let data = JSON.parse(localStorage.getItem("admin"));

    // if (!data) {
    //   fallback();
    //   throw { message: "Not found" };
    // }
    try {
      dispatch(
        updateDetails({
          field: "name",
          value: data?.name || "",
        })
      );
    } catch (e) {
      console.log(e);
    }
    try {
      dispatch(
        updateDetails({
          field: "lastName",
          value: "",
        })
      );
    } catch (e) {
      console.log(e);
    }
    try {
      dispatch(
        updateDetails({
          field: "course",
          value: data?.personalInfo?.course ?? "",
        })
      );
    } catch (e) {
      console.log(e);
    }
    try {
      dispatch(
        updateDetails({ field: "profileImage", value: data?.image?.url ?? "" })
      );
    } catch (e) {
      console.log(e);
    }
    try {
      dispatch(
        updateDetails({
          field: "collegeYear",
          value: data?.personalInfo?.collegeYear || "",
        })
      );
    } catch (e) {
      console.log(e);
    }

    try {
      dispatch(updateDetails({ field: "email", value: data?.email ?? "" }));
    } catch (e) {
      console.log(e);
    }

    try {
      dispatch(
        updateDetails({
          field: "mobile",
          value: data?.phoneNumber || data?.phone_number || "",
        })
      );
    } catch (e) {
      console.log(e);
    }

    try {
      dispatch(updateDetails({ field: "college", value: data?.college ?? "" }));
    } catch (e) {
      console.log(e);
    }

    console.log("redux", data);
  } catch (error) {}
};
