import { useHistory } from "react-router-dom";

function Messages() {
  const history = useHistory();

  return (
    <div className="pt-8 pb-16 flex flex-col space-y-4 px-4">
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-primary-neutral-800 font-satoshi text-2xs font-bold uppercase leading-4 tracking-[0.4px]">
          Latest Messages
        </h1>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M0.5625 4.21875H17.4375V5.90625H0.5625V4.21875ZM3.375 8.15625H14.625V9.84375H3.375V8.15625ZM6.75 12.0938H11.25V13.7812H6.75V12.0938Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="flex flex-col space-y-[14px] pb-16">
        {[
          "Professional Growth Manager",
          // "Talent Manager",
          // "Operations Manager",
          // "Professional Growth Manager",
          // "Talent Manager",
          // "Operations Manager",
        ]?.map((item, idx) => {
          return (
            <div
              key={idx}
              className="bg-white cursor-pointer p-4 rounded-2xl flex flex-col space-y-4"
              style={{
                boxShadow:
                  "0px 24px 24px -24px rgba(0, 0, 0, 0.16), 0px 2px 4px -2px rgba(0, 0, 0, 0.10), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)",
              }}
              onClick={() => {
                if (idx === 0) history.push("/inbox/messageId");
              }}
            >
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center space-x-2">
                  <div className="bg-[#ABE085] w-[26px] h-[26px] rounded-full flex flex-row items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.25004 1.16406C5.50113 1.16406 5.72404 1.32473 5.80344 1.56293L8.75004 10.4027L9.94664 6.81293C10.026 6.57473 10.249 6.41406 10.5 6.41406H12.8334C13.1555 6.41406 13.4167 6.67523 13.4167 6.9974C13.4167 7.31956 13.1555 7.58073 12.8334 7.58073H10.9205L9.30344 12.4319C9.22404 12.6701 9.00113 12.8307 8.75004 12.8307C8.49896 12.8307 8.27604 12.6701 8.19664 12.4319L5.25004 3.59206L4.05344 7.18186C3.97404 7.42006 3.75113 7.58073 3.50004 7.58073H1.16671C0.844541 7.58073 0.583374 7.31956 0.583374 6.9974C0.583374 6.67523 0.844541 6.41406 1.16671 6.41406H3.0796L4.69664 1.56293C4.77604 1.32473 4.99896 1.16406 5.25004 1.16406Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <p className="font-lato text-primary-neutral-500 text-2xs font-bold capitalize leading-4 tracking-[0.4px]">
                    {idx === 0 ? "Set Up Your Career Goals" : "New Assessment"}
                  </p>
                </div>
                <div className="text-primary-neutral-400 font-satoshi text-2xs leading-4 tracking-[0.2px] capitalize">
                  17 Apr, 2024
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <h1 className="text-primary-neutral-800 font-lato text-sm font-bold leading-5 tracking-[0.56px] capitalize">
                  Message title to go here... lorem ipsum dolor sit amet,
                  consectetur adipiscing elit,{" "}
                </h1>
                <p className="text-primary-neutral-800 font-lato text-2xs capitalize leading-4 tracking-[0.4px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div className="flex flex-row items-center space-x-2 justify-end">
                <img
                  src="/assets/images/demo.png"
                  alt=""
                  className="w-4 h-4 rounded-full"
                />
                <p className="text-primary-neutral-500 font-lato text-2xs font-bold capitalize leading-4 tracking-[0.4px]">
                  {item}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Messages;
