import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 1,
  totalStep: {},
  stepCompleted: {},
  currentStep: 0,
  currentSectionTotal: 0,
  currentSection: "Section1",
  saveData: false,
  details: {
    first_name: JSON.parse(localStorage.getItem("admin"))?.name || undefined,
    gender: undefined,
    email: JSON.parse(localStorage.getItem("admin"))?.email || undefined,
    phone_number:
      JSON.parse(localStorage.getItem("admin"))?.phoneNumber || undefined,
    college_id: undefined,
    college_year: undefined,
    branch: undefined,
    image_id: null,
    portfolio: undefined,
    linkedIn: undefined,
    resumeUrl: null,
    college_name: null,

    tools_interested: [],
    frameworks_interested: [],
    domains_interested: [],
    sdg_interests: [],
    sectionMetaData: {
      basicForm: {
        // Section1: [{ id: 1, question: "", value: "Answer" }],
      },
    },
  },
};

// Storing User details and Token
export const typeForm = createSlice({
  name: "user",
  initialState,
  reducers: {
    nextStep: (state, action) => {
      state.step = state.step + 1;
      if (state.currentStep + 1 > state.currentSectionTotal) {
        state.stepCompleted[state.currentSection] = true;
      }
      if (action.payload?.section) {
        let newStep = state.currentStep + 1;
        if (newStep > state.currentSectionTotal) {
          let t = Object.keys(state.totalStep)?.map((i) => {
            if (state.totalStep[i] !== 0) return i;
          });
          let index = t.indexOf(state.currentSection);
          state.currentSection = t[index + 1];
          state.currentSectionTotal = state.totalStep[t[index + 1]];
          state.currentStep = 1;
        } else state.currentStep = newStep;
      }
      if (action.payload?.interval) {
        state.currentStep = 1;
      }
    },
    prevStep: (state, action) => {
      state.step = state.step - 1;
      if (state.step - 1 !== 2) {
        if (action.payload?.section) {
          let newStep = state.currentStep - 1;
          if (newStep <= 0) {
          } else state.currentStep = newStep;
        }
        if (action.payload?.interval) {
          let t = Object.keys(state.totalStep)?.map((i) => {
            if (state.totalStep[i] !== 0) return i;
          });
          let index = t.indexOf(state.currentSection);
          state.currentSection = t[index - 1];
          state.currentSectionTotal = state.totalStep[t[index - 1]];
          state.currentStep = state.totalStep[t[index - 1]];
        }
      }
    },
    updateTotalStep: (state, action) => {
      state.totalStep = action.payload.data;
      // state.currentSectionTotal = Object.values(action.payload.data)?.find(
      //   (i) => i !== 0
      // );
      // let t = Object.keys(action.payload.data)?.map((i) => {
      //   if (action.payload.data[i] !== 0) return i;
      // });
      // state.currentSection = t[0];
    },
    updateDetails: (state, action) => {
      let field = action.payload.field;
      let value = action.payload.value;
      let d = state.details;
      d[field] = value;
      state.details = d;
      console.log("Redux Object: ", { d, field });
    },
    saveData: (state, action) => {
      state.saveData = action.payload.save;
    },
    updateAns: (state, action) => {
      let section = action.payload.section;
      let id = action.payload.id;
      let value = action.payload.value;
      let d = state.details?.sectionMetaData?.basicForm[section];
      let score = action.payload.score;
      console.log("Redux Object: ", { section, d });

      d?.map((i) => {
        if (i?.id == id) {
          i["value"] = value;
          i["score"] = score || 0;
        }
      });

      state.details.sectionMetaData.basicForm[section] = d;
    },
    updateStep: (state, action) => {
      let section = action.payload.step;
      state.currentSectionTotal =
        state.totalStep[Object.keys(state.totalStep)[section - 1]];
      state.currentSection = Object.keys(state.totalStep)[section - 1];
      state.currentStep = 1;
    },
    changeSection: (state, action) => {
      let {
        step,
        currentSection,
        currentStep,
        currentSectionTotal,
        stepCompleted,
      } = action.payload;
      state.step = step;
      state.currentSection = currentSection;
      state.currentStep = currentStep;
      state.currentSectionTotal = currentSectionTotal;
      state.stepCompleted = stepCompleted;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  nextStep,
  prevStep,
  updateTotalStep,
  updateDetails,
  updateAns,
  saveData,
  updateStep,
  changeSection,
} = typeForm.actions;

export default typeForm.reducer;
