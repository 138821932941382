import React from "react";
import { useDispatch } from "react-redux";
import { emptyListGoals, fetchAllgoals, updatePlannerMode } from "redux/goal";

const PlannerMode = ({ setUpdateGoalModal }) => {
  const dispatch = useDispatch();
  return (
    <div className=" w-[320px]  bg-white p-6 absolute top-1/3 z-50 rounded shadow-lg ml-auto mr-auto left-0 right-0">
      <h1 className="text-normal">Your change(s) have not been saved yet!</h1>
      <p className="mt-6 text-sm text-neutral-500">
        Are you sure you want to leave planner mode?
      </p>
      <div className="flex justify-evenly mt-4">
        <button
          onClick={() => {
            setUpdateGoalModal(false);
          }}
          className="text-sm text-neutral-500"
        >
          No, Cancel
        </button>
        <button
          onClick={() => {
            dispatch(updatePlannerMode());
            dispatch(emptyListGoals());
            dispatch(fetchAllgoals());
            setUpdateGoalModal(false);
          }}
          className="text-sm text-primary-error-500"
        >
          Yes, I'm sure
        </button>
      </div>
    </div>
  );
};

export default PlannerMode;
