import { useState, useEffect } from "react";

import Welcome from "./Components/Welcome";
import { useDispatch, useSelector } from "react-redux";
import Interval from "./Components/Interval";
import { updateDetails, updateTotalStep } from "redux/typeform";
import TextInput from "./Components/TextInput";
import { typeFormQuestions } from "helpers/constants/driveTypeForm";
import Scale from "./Components/Scale";
import Choices from "./Components/Choices";
import Introduction from "./Components/Introduction";
import Ending from "./Components/Ending";
import College from "./Components/College";

export default function TypeForm() {
  const step = useSelector((state) => state?.typeForm?.step);
  const dispatch = useDispatch();
  const [questionList, setQuestionList] = useState([
    {
      step: 1,
      component: <Welcome />,
    },
  ]);

  const getList = () => {
    let list = typeFormQuestions;
    let section1 = list[0];
    let section2 = list[1];
    let section3 = list[2];
    let section4 = list[3];
    let section5 = list[4];

    dispatch(
      updateTotalStep({
        data: {
          Section1: section1?.questions?.length || 0,
          Section2: section2?.questions?.length || 0,
          Section3: section3?.questions?.length || 0,
          Section4: section4?.questions?.length || 0,
          Section5: section5?.questions?.length || 0,

          total:
            (section1?.questions?.length || 0) +
            (section2?.questions?.length || 0) +
            (section3?.questions?.length || 0) +
            (section4?.questions?.length || 0) +
            (section5?.questions?.length || 0),
        },
      })
    );

    let ansObj = {
      Section1: [],
      Section2: [],
      Section3: [],
      Section4: [],
      Section5: [],
    };

    let t = [];

    t.push({
      step: 1,
      component: <Welcome />,
    });
    t.push({
      step: 2,
      component: <Introduction />,
    });

    t.push({
      step: t?.length + 1,
      component: <Interval {...section1?.intoductionScreen} />,
    });

    section1?.questions?.map((item) => {
      t.push({
        step: t?.length + 1,
        component: <Scale {...item} />,
      });

      ansObj.Section1.push({ ...item });
    });
    t.push({
      step: t?.length + 1,
      component: <Ending />,
    });
    t.push({
      step: t?.length + 1,
      component: <Interval {...section2?.intoductionScreen} />,
    });

    section2?.questions?.map((item) => {
      t.push({
        step: t?.length + 1,
        component: <Scale {...item} />,
      });
      ansObj.Section2.push({ ...item });
    });
    t.push({
      step: t?.length + 1,
      component: <Ending />,
    });
    t.push({
      step: t?.length + 1,
      component: <Interval {...section3?.intoductionScreen} />,
    });

    section3?.questions?.map((item) => {
      t.push({
        step: t?.length + 1,
        component: <Choices {...item} selectOne={item?.type == "choice"} />,
      });

      ansObj.Section3.push({ ...item });
    });
    t.push({
      step: t?.length + 1,
      component: <Ending />,
    });
    t.push({
      step: t?.length + 1,
      component: <Interval {...section4?.intoductionScreen} />,
    });

    section4?.questions?.map((item) => {
      t.push({
        step: t?.length + 1,
        component: <Choices {...item} selectOne={item?.type == "choice"} />,
      });
      ansObj.Section4.push({ ...item });
    });
    t.push({
      step: t?.length + 1,
      component: <Ending />,
    });
    t.push({
      step: t?.length + 1,
      component: <Interval {...section5?.intoductionScreen} />,
    });

    section5?.questions?.map((item) => {
      switch (item?.type) {
        case "choice":
        case "choices":
          t.push({
            step: t?.length + 1,
            component: <Choices {...item} selectOne={item?.type == "choice"} />,
          });
          break;
        case "text":
          t.push({
            step: t?.length + 1,
            component: <TextInput {...item} />,
          });
          break;
        case "email":
          t.push({
            step: t?.length + 1,
            component: <TextInput {...item} />,
          });
          break;
        case "phoneNumber":
          t.push({
            step: t?.length + 1,
            component: <TextInput {...item} />,
          });
          break;
        case "college":
          t.push({
            step: t?.length + 1,
            component: <College {...item} />,
          });
          break;
      }
      ansObj.Section5.push({ ...item });
    });
    t.push({
      step: t?.length + 1,
      component: <Ending />,
    });
    setQuestionList(t);
    dispatch(
      updateDetails({
        field: "sectionMetaData",
        value: { basicForm: ansObj },
      })
    );
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="mt-[66px] h-screen w-screen bg-white">
      {questionList?.find((i) => i?.step === step)?.component}
    </div>
  );
}
