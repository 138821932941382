import React from "react";
import QuestionNavigation from "./QuestionNavigation";
import { AnimatePresence, motion } from "framer-motion";
import ChronosButton from "components/Common/Buttons/FormButton";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import ProgressNavigation from "./ProgressNavigation";

function Wrapper({ children, onNextClick, onBackClick, disabled }) {
  return (
    <div className="min-h-screen xl:h-screen flex flex-col ">
      <QuestionNavigation />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-row items-center justify-center h-[80vh] pt-2 overflow-y-hidden"
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <ProgressNavigation />
      <div
        // style={{ boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.20)" }}
        className="w-full bg-white border-0 border-primary-gray-200 fixed bottom-0"
      >
        <div className="mx-auto w-full flex flex-row items-center justify-between max-w-6xl py-4 px-4">
          <button
            className="flex justify-center items-center font-lato text-sm lg:text-base lg:tracking-[0.32px] text-primary-gray-400 font-semibold"
            onClick={onBackClick}
          >
            <ArrowLeft size={16} className="mr-3" />
            Previous
          </button>

          <ChronosButton
            secondary
            disabled={disabled}
            text="Next"
            onClick={onNextClick}
            icon={<ArrowRight size={16} className="ml-3" />}
          />
        </div>
      </div>
    </div>
  );
}

export default Wrapper;
