import { RiTimer2Fill } from "react-icons/ri";
const ClockInStreak = ({ streakDays }) => {
  const isTimeAlmostUp = (date) => {
    const now = new Date();
    const endOfDay = new Date(date.setHours(23, 59, 59, 999));
    const timeLeft = endOfDay - now;
    // Check if there are 3 hours or less remaining
    return timeLeft <= 3 * 60 * 60 * 1000;
  };
  return (
    <div className="flex flex-col    w-full md:items-center md:space-y-10">
      {/* <div className="flex flex-row justify-center md:space-x-2 items-center">
        <div className="flex flex-row space-x-2 items-center ">
          <div>
            <HiFire className="    text-[#F57D34]" />
          </div>
          <div>
            <p className="font-satoshi font-bold text-sm text-[#ffffff]">
              Clock In Streak
            </p>
          </div>
        </div>
      </div> */}
      <div className="flex flex-row justify-around w-full space-x-[20px]">
        {streakDays.map((day, index) => {
          let style = "font-lato font-bold text-[12px] text-[#696763] "; // Default for upcoming
          if (day.status === "completed" || day?.hasSession === true)
            style =
              "text-[white] bg-gradient-to-b from-[#FFBC00] to-[#F57D34] rounded-[144px] font-lato font-bold text-[12px] ";
          else if (day.status === "missed")
            style =
              "text-[#9C9A96] bg-[#E7E6E5] rounded-[144px] font-lato font-bold text-[12px]";
          else if (day.status === "active")
            style =
              "border-[#363430] border-[1px] text-primary-neutral-500   rounded-[144px] font-lato font-bold text-[12px] ";

          return (
            <button
              key={day.day + index}
              className={`w-6 h-6 flex items-center justify-center ${style}`}
              disabled={day.status === "upcoming"}
            >
              <p>{day.day}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default ClockInStreak;
