import MultilineTextInput from "../inputs/MultilineTextInput";
import TextInput from "../inputs/TextInput";
import { growthOpportunitiesProps, learningProps } from "./tags";

const ReflectionTakeawaysTab = ({ input, setInput, type }) => {
  return (
    <div className="flex flex-col gap-10  w-full h-full ">
      <h1 className="text-primary-blue-500 text-lg md:text-xl font-manrope ">
        Reflection & Takeaways
      </h1>
      <div className="flex flex-col gap-8 h-[55vh] overflow-y-scroll w-full max-w-3xl scrollbar-hidden pb-8">
        <MultilineTextInput
          input={input}
          setInput={setInput}
          type={type}
          {...learningProps}
        />
        <TextInput
          input={input}
          setInput={setInput}
          iconType={"sparkles"}
          type={type}
          {...growthOpportunitiesProps}
        />
      </div>
    </div>
  );
};

export default ReflectionTakeawaysTab;
