import React from "react";
import { useSelector } from "react-redux";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function PR() {
  const user = useSelector((state) => state?.user?.user);
  return (
    <div
      style={{
        background:
          "radial-gradient(107.92% 50% at 50% 50%, #FDF7E9 0%, #FFE99A 100%)",
      }}
      className="px-4  py-8 space-y-8"
    >
      <div className=" space-y-2">
        <h1 className="text-primary-neutral-800 font-lato text-lg font-bold leading-7 capitalize">
          Your PR Profile
        </h1>
        <p className="text-primary-neutral-500 font-satoshi text-xs leading-5">
          This is the profile which would be shown to potential recruiters. We
          call them PR Profiles
        </p>
      </div>
      <div className="flex flex-col space-y-6 items-center">
        <div className="flex flex-col items-center space-y-2">
          <div className="w-[104px] h-[104px]">
            <CircularProgressbarWithChildren
              value={20 || 0}
              text={``}
              circleRatio={1}
              styles={buildStyles({
                rotation: 1 / 2 + 1 / 4,
                strokeLinecap: "butt",
                pathColor: "#EEA638",
                trailColor: "#9C9A96",
              })}
            >
              <img
                src={user?.image?.url}
                alt=""
                className="w-20 h-20 rounded-full"
              />
            </CircularProgressbarWithChildren>
          </div>
          <div className="flex flex-row items-center justify-center space-x-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.2577 14.3829L9.99935 14.2269L9.74098 14.3829L5.60548 16.8789L6.70294 12.1745L6.7715 11.8806L6.5434 11.683L2.8891 8.5173L7.69995 8.10915L8.00038 8.08366L8.11804 7.80605L9.99935 3.36724L11.8807 7.80605L11.9983 8.08366L12.2987 8.10915L17.1096 8.5173L13.4553 11.683L13.2272 11.8806L13.2958 12.1745L14.3932 16.8789L10.2577 14.3829Z"
                fill="#363430"
                stroke="#363430"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M11.8808 7.79797L11.9983 8.07491L12.2981 8.10076L17.1128 8.51594L13.4633 11.6833L13.2359 11.8806L13.3039 12.1739L14.3951 16.88L10.2577 14.3829L9.99935 14.2269L9.74098 14.3829L5.60548 16.8789L6.70294 12.1745L6.7715 11.8806L6.5434 11.683L2.8891 8.5173L7.69995 8.10915L8.00038 8.08366L8.11804 7.80605L9.9997 3.36642L11.8808 7.79797ZM9.49935 13.2526V13.5345L9.7406 13.6804L12.8823 15.5804L13.898 16.1947L13.6279 15.0388L12.8632 11.7659L15.402 9.56363L16.294 8.78987L15.1176 8.68781L11.7675 8.39716L10.4598 5.30771L9.49935 5.5026V13.2526Z"
                fill="#363430"
                stroke="#363430"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M11.8808 7.79797L11.9983 8.07491L12.2981 8.10076L17.1128 8.51594L13.4633 11.6833L13.2359 11.8806L13.3039 12.1739L14.3951 16.88L10.2577 14.3829L9.99935 14.2269L9.74098 14.3829L5.60548 16.8789L6.70294 12.1745L6.7715 11.8806L6.5434 11.683L2.8891 8.5173L7.69995 8.10915L8.00038 8.08366L8.11804 7.80605L9.9997 3.36642L11.8808 7.79797ZM6.37913 15.0305L6.10936 16.1851L7.12443 15.5723L9.99913 13.8368L12.8823 15.5804L13.898 16.1947L13.6279 15.0388L12.8632 11.7659L15.402 9.56363L16.294 8.78987L15.1176 8.68781L11.7675 8.39716L10.4598 5.30771L9.99857 4.21802L9.53866 5.30827L8.23915 8.38886L4.88947 8.67948L3.71307 8.78154L4.60504 9.5553L7.14382 11.7576L6.37913 15.0305Z"
                fill="#363430"
                stroke="#363430"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M11.8808 7.79797L11.9983 8.07491L12.2981 8.10076L17.1128 8.51594L13.4633 11.6833L13.2359 11.8806L13.3039 12.1739L14.3951 16.88L10.2577 14.3829L9.99935 14.2269L9.74098 14.3829L5.60548 16.8789L6.70294 12.1745L6.7715 11.8806L6.5434 11.683L2.8891 8.5173L7.69995 8.10915L8.00038 8.08366L8.11804 7.80605L9.9997 3.36642L11.8808 7.79797ZM6.37913 15.0305L6.10936 16.1851L7.12443 15.5723L9.99913 13.8368L12.8823 15.5804L13.898 16.1947L13.6279 15.0388L12.8632 11.7659L15.402 9.56363L16.294 8.78987L15.1176 8.68781L11.7675 8.39716L10.4598 5.30771L9.99857 4.21802L9.53866 5.30827L8.23915 8.38886L4.88947 8.67948L3.71307 8.78154L4.60504 9.5553L7.14382 11.7576L6.37913 15.0305Z"
                fill="#363430"
                stroke="#363430"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M11.8808 7.79797L11.9983 8.07491L12.2981 8.10076L17.1128 8.51594L13.4633 11.6833L13.2359 11.8806L13.3039 12.1739L14.3951 16.88L10.2577 14.3829L9.99935 14.2269L9.74098 14.3829L5.60548 16.8789L6.70294 12.1745L6.7715 11.8806L6.5434 11.683L2.8891 8.5173L7.69995 8.10915L8.00038 8.08366L8.11804 7.80605L9.9997 3.36642L11.8808 7.79797ZM6.37913 15.0305L6.10936 16.1851L7.12443 15.5723L9.99913 13.8368L12.8823 15.5804L13.898 16.1947L13.6279 15.0388L12.8632 11.7659L15.402 9.56363L16.294 8.78987L15.1176 8.68781L11.7675 8.39716L10.4598 5.30771L9.99857 4.21802L9.53866 5.30827L8.23915 8.38886L4.88947 8.67948L3.71307 8.78154L4.60504 9.5553L7.14382 11.7576L6.37913 15.0305Z"
                fill="#363430"
                stroke="#363430"
              />
            </svg>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <p className="text-primary-neutral-800 font-lato text-sm font-bold">
              1.5
            </p>
            <button
              //   onMouseEnter={() => setShowTooltip(true)}
              //   onMouseLeave={() => setShowTooltip(false)}
              //   onClick={() => handleClick()}
              className="border-[#9C9A96] rounded-[56px] border-[1px] w-4 h-4 flex justify-center items-center"
              aria-label="More information"
            >
              <p className="font-lato font-[900] text-2xs text-[#696763]">?</p>
            </button>
          </div>
        </div>
        <div className="py-2 px-6 border-l-4 border-[#F57D34] bg-[#FFF1EB] space-y-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9.96018 1.66406C5.38685 1.66406 1.66602 5.4024 1.66602 9.9974C1.66602 14.5924 5.40435 18.3307 9.99935 18.3307C14.5943 18.3307 18.3327 14.5924 18.3327 9.9974C18.3327 5.4024 14.5768 1.66406 9.96018 1.66406ZM9.99935 16.6641C6.32352 16.6641 3.33268 13.6732 3.33268 9.9974C3.33268 6.32156 6.30518 3.33073 9.96018 3.33073C13.6585 3.33073 16.666 6.32156 16.666 9.9974C16.666 13.6732 13.6752 16.6641 9.99935 16.6641Z"
              fill="#F57D34"
            />
            <path
              d="M9.16602 5.83594H10.8327V11.6693H9.16602V5.83594ZM9.16602 12.5026H10.8327V14.1693H9.16602V12.5026Z"
              fill="#F57D34"
            />
          </svg>
          <h1 className="text-primary-neutral-800 text-xs font-satoshi font-bold">
            Your Profile Rating Is Low!
          </h1>
          <p className="text-primary-neutral-600 font-satoshi text-2xs">
            Complete your profile to increase your rating. A better rating helps
            your reach more recruiters
          </p>
        </div>
      </div>

      <div className="p-4 flex flex-col space-y-4 rounded-[20px] bg-white">
        {[
          { text: "Achievement Cards", subtext: "2 Added", tick: true },
          { text: "Skill Maps", subtext: "0/0 Skills Mapped", tick: false },
          { text: "Assessment Insights", subtext: "0/7 Added", tick: false },
          { text: "Some Other Metric", subtext: "! Not Added", tick: false },
        ]?.map((item) => {
          return (
            <div className="flex flex-row items-center space-x-4">
              {item?.tick ? (
                <div className="w-6 h-6 rounded-full border border-[#2BB656] flex flex-row items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M5.51259 11.4515L5.86615 11.805L6.2197 11.4515L12.9328 4.73836L13.159 4.96458L5.86615 12.2575L2.83992 9.23125L3.06615 9.00502L5.51259 11.4515Z"
                      fill="#2BB656"
                      stroke="#2BB656"
                    />
                  </svg>
                </div>
              ) : (
                <div className="w-6 h-6 rounded-full border border-primary-neutral-400 border-dotted" />
              )}
              <div className="flex flex-col">
                <h1 className="text-primary-neutral-800 font-satoshi text-xs font-bold">
                  {item?.text}
                </h1>
                <p className="text-primary-neutral-600 font-satoshi text-2xs">
                  {item?.subtext}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex flex-row items-center justify-center">
        <div className="bg-primary-neutral-900 rounded-full py-3.5 px-14 text-white font-satoshi text-sm font-bold leading-7 tracking-[0.9px] flex flex-row items-center space-x-2">
          <p>Enhance your PR Profile</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M8.00065 4.66406V6.33073H13.4923L3.83398 15.9891L5.00898 17.1641L14.6673 7.50573V12.9974H16.334V4.66406H8.00065Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default PR;
