import { useDispatch, useSelector } from "react-redux";
import { updatePhaseThreeResponse } from "redux/profile-form";
import {
  CheckBoxCenter,
  CheckBoxLg,
  CheckBoxMd,
  CheckBoxSm,
} from "../Checkboxes";

const QuestionComponent = ({
  question,
  isActive = false,
  currQuestionChange,
}) => {
  const { response } = useSelector((state) => state.profileForm.phaseThree);
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(updatePhaseThreeResponse({ questionId: question.id, value }));
    currQuestionChange(question.id);
  };
  const getChecked = (id) => {
    return response.find((item) => item.questionId === id);
  };
  return (
    <div
      className={`flex flex-col gap-5 w-full max-w-3xl mx-auto border-b-2 py-8 ${
        isActive ? "opacity-100" : "opacity-50"
      }`}
    >
      <h4 className="text-lg text-primary-grey-800 font-bold font-satoshi">
        {question.question ?? ""}
      </h4>
      <div
        className={`flex flex-col gap-5 w-full max-w-md mx-auto ${
          isActive ? "my-4" : "my-0"
        }`}
      >
        <div className="flex gap-2 md:gap-3 w-full justify-between">
          <CheckBoxLg
            type={0}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
          <CheckBoxMd
            type={1}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
          <CheckBoxSm
            type={2}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
          <CheckBoxCenter
            type={3}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
          <CheckBoxSm
            type={4}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
          <CheckBoxMd
            type={5}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
          <CheckBoxLg
            type={6}
            id={question.id}
            handleChange={handleChange}
            isChecked={getChecked}
          />
        </div>
        <div className="flex justify-between">
          <p className="text-md text-primary-grey-800 font-bold font-satoshi">
            Disagree
          </p>

          <p className="text-md text-primary-grey-800 font-bold font-satoshi">
            Agree
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionComponent;
