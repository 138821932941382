import { BsExclamationCircleFill } from "react-icons/bs";
import { Notification } from "rsuite";

export default function Toast({ message }) {
  return (
    <Notification
      style={{
        background: "#363430",
        borderRadius: 16,
        zIndex: 999,
      }}
    >
      <div className="flex items-center gap-3 text-white font-bold font-lato">
        <BsExclamationCircleFill /> {message ?? "Something went wrong"}
      </div>
    </Notification>
  );
}
