import React from "react";
import { motion } from "framer-motion";

const InViewWrapper = ({
  children,
  classes,
  className,
  yInitial = 200,
  delay = 0,
}) => {
  // const { ref, inView } = useInView({
  //   threshold: 0.2,
  // });

  const variant = {
    offscreen: {
      y: yInitial,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 0.7,
        delay: delay,
      },
    },
  };

  // const animation = useAnimation();

  // useEffect(() => {
  //   if (inView) {
  //     animation.start({
  //       x: 0,
  //       transition: {
  //         type: "tween",
  //         duration: 0.5,
  //         ease: [0.42, 0, 0.58, 1],
  //       },
  //     });
  //   }
  //   if (!inView) {
  //     animation.start({
  //       x: "-100vw",
  //     });
  //   }

  //   console.log("Is Inview", inView);
  // }, [inView]);

  return (
    // remove ref = {ref} from div
    <div className={classes + " " + className}>
      {/* removed animate = {animation} */}
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
      >
        <motion.div variants={variant}>{children}</motion.div>
      </motion.div>
    </div>
  );
};

export default InViewWrapper;
