import { FormComponents } from "helpers/constants/profileForm";
import { useSelector } from "react-redux";
import FormNavigation from "./components/FormNavigation";

const renderStepComponent = (step, screen) => {
  const formComponents = FormComponents(screen);
  switch (step) {
    case step:
      return formComponents[step]
        ? formComponents[step].component
        : formComponents[0].component;
    default:
      return formComponents[0].component;
  }
};

const MobileForm = () => {
  const { step, details, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  return (
    <div className="relative min-h-screen flex flex-col justify-between">
      <div className={`flex w-full p-4 font-inter absolute top-0 left-0 z-10`}>
        <div className="shadow-md bg-white flex rounded-full p-1 px-3 ">
          <img
            src="/assets/images/logo/caaryaMe.svg"
            alt=""
            className="h-6 object-contain"
          />
        </div>
      </div>
      <div className="flex-1"> {renderStepComponent(step, "mobile")}</div>

      {isNavigationVisible && (
        <div>
          <FormNavigation
            totalSteps={FormComponents(step, details).length}
            field={FormComponents(step, details)[step]?.field || ""}
          />
        </div>
      )}
    </div>
  );
};

export default MobileForm;
