import { ArrowRight } from "@phosphor-icons/react";
import ClockInModal from "components/FocusArea/Modal/ClockInModal";
import GoalsInFocusModal from "components/FocusArea/Modal/GoalInFocusModal";
import { endSession, startSession } from "config/APIs/session";
import { goalFocusTime } from "helpers/constants/goals";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllgoals } from "redux/goal";
import { updateFocusOfGoals } from "redux/goal";
import {
  fetchManualCheckoutJournals,
  fetchOngoingJournals,
  updateOngoingJournals,
} from "redux/journals";
import { showToast } from "redux/toaster";
import DeskCards from "./DeskCards";
import TopBar from "components/Layout/TopBar";
import TabBar from "components/Layout/TabBar";
import SwitchZone from "components/Modals/Common/SwitchZoneModal";
import { ReactComponent as Daylight } from "assets/FocusArea/daylight.svg";
import { ReactComponent as Nightwatch } from "assets/FocusArea/nightwatch.svg";
import { ReactComponent as Sundown } from "assets/FocusArea/sundown.svg";
import { ReactComponent as EmptyFocusZone } from "assets/FocusArea/emptyFocusZone.svg";
import { ReactComponent as EmptyDeskZone } from "assets/FocusArea/emptyDeskZone.svg";
import { useTransition } from "react-transition-state";
import InSessionModal from "components/FocusArea/Modal/InSessionModal";

const options = [
  {
    title: "Daylight",
    icon: <Daylight className="text-primary-neutral-500 h-5 w-5" />,
  },
  {
    title: "Sundown",
    icon: <Sundown className="text-primary-neutral-500 h-5 w-5" />,
  },
  {
    title: "Nightwatch",
    icon: <Nightwatch className="text-primary-neutral-500 h-5 w-5" />,
  },
];

function FocusArea() {
  const history = useHistory();
  const allGoals = useSelector((state) => state.goals?.goals);
  const ongoingGoal = useSelector((state) => state.journals?.onGoing);
  const manualCheckOut = useSelector((state) => state.journals?.manualCheckOut);
  const [sortedOptions, setSortedOptions] = useState([...options]);
  const [selectedFocus, setSelectedFocus] = useState("Nightwatch");
  const [originalFocus, setOriginalFocus] = useState("Nightwatch");
  const dispatch = useDispatch();
  const [openClockIn, setOpenClockIn] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [openFocusGoals, setOpenFocusGoals] = useState(false);
  const [currentSession, setCurrentSession] = useState();
  const [progress, setProgress] = useState(0);
  const [focusedGoal, setFocusedGoal] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [showSwitchZone, setShowSwitchZone] = useState(false);
  const [inSession, setInSession] = useState(false);
  const [startingIn, setStartingIn] = useState("");

  useEffect(() => {
    console.log(selectedFocus, originalFocus);
    (() => {
      switch (selectedFocus) {
        case "Nightwatch":
          setStartingIn(getTimeLeftOrTimeToStart("8:00"));
          break;
        case "Daylight":
          setStartingIn(getTimeLeftOrTimeToStart("16:00"));
          break;
        case "Sundown":
          setStartingIn(getTimeLeftOrTimeToStart("23:59"));
          break;
        default:
          break;
      }
    })();
  }, [selectedFocus]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !openClockOut) {
      dispatch(fetchManualCheckoutJournals());
      if (manualCheckOut?.data?.sessionId) {
        setFocusedGoal(manualCheckOut?.data);
        setCurrentSession({
          id: manualCheckOut?.data?.sessionId,
          duration: manualCheckOut?.data?.duration,
          clockIn: manualCheckOut?.data?.clockIn,
          clockOut: manualCheckOut?.data?.clockOut,
        });

        setOpenClockOut(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (isMounted && !openClockOut) {
      dispatch(fetchOngoingJournals());
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    dispatch(updateFocusOfGoals());
    dispatch(fetchAllgoals());
  }, []);

  const getTimeLeftOrTimeToStart = (endDate = "00:00") => {
    var now = moment();
    var end = moment(endDate, "HH:mm");

    var duration = moment.duration(end.diff(now));
    if (duration.seconds() < 0) {
      duration.add(24, "hours");
    }

    //Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(moment.duration(hours, "hours"));

    //Get Minutes and subtract from duration
    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, "minutes"));

    let remaining = hours + "h " + minutes + "min";
    return remaining;
    //This prints -22hours and -41 minutes
    //Here I would also check if current time is between deal hours, past or before.
  };

  useEffect(() => {
    let time = +moment().format("HH");

    if (time >= 0 && time < 8) {
      setSortedOptions([
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
      ]);
      setSelectedFocus("Nightwatch");
      setOriginalFocus("Nightwatch");
      setProgress(time * 8);
      setTimeLeft(getTimeLeftOrTimeToStart("8:00"));
    } else if (time >= 8 && time < 16) {
      setSortedOptions([
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
      ]);
      setSelectedFocus("Daylight");
      setOriginalFocus("Daylight");
      setProgress((time - 8) * 8);
      setTimeLeft(getTimeLeftOrTimeToStart("16:00"));
    } else {
      setSortedOptions([
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
      ]);
      setSelectedFocus("Sundown");
      setOriginalFocus("Sundown");
      setProgress((time - 16) * 8);
      setTimeLeft(getTimeLeftOrTimeToStart("23:59"));
    }
  }, []);

  useEffect(() => {
    if (currentSession) {
      if (currentSession?.clockIn) {
        const time1 = moment(currentSession?.clockIn);
        const time2 = moment();

        const format = "HH:mm:ss";

        const diffInMilliseconds = moment(time2, format).diff(
          moment(time1, format)
        );
      }
    }
  }, [currentSession]);

  const clockIn = async (data) => {
    try {
      let duration = parseInt(data.split(" ")[0]);
      if (data.split(" ")[1] === "Hour") duration *= 60;
      let time = moment();
      const response = await startSession({
        clockIn: time,
        goalId: focusedGoal?._id,
        duration,
      });
      setCurrentSession(response?.data?.data);
      console.log(response?.data?.data);
      setTimeout(async () => {
        dispatch(fetchOngoingJournals());
        if (ongoingGoal?.length > 0) {
          await clockOut(false);
          updateOngoingJournals([]);

          setOpenClockOut(true);
        }
      }, duration * 3600);

      dispatch(fetchAllgoals());

      history.push(`/clockedIn/${focusedGoal?.focusIn}`);
    } catch (err) {
      if (err?.response?.status) setInSession(true);
      else
        dispatch(
          showToast({ message: err?.response?.data?.message, type: "error" })
        );
      console.log("Error", err);
    }
  };

  const clockOut = async () => {
    let time;
    if (
      moment(focusedGoal?.clockIn).add(focusedGoal?.duration, "minutes") >
      moment()
    ) {
      time = moment();
    } else {
      time = moment(focusedGoal?.clockIn).add(focusedGoal?.duration, "minutes");
    }
    try {
      const response = await endSession({
        sessionId: currentSession?.id,
        clockOut: time,
        goalId: focusedGoal?.id,
        clockOutByUser: true,
      });

      dispatch(fetchAllgoals());
      setFocusedGoal();
    } catch (err) {
      dispatch(
        showToast({ message: err?.response?.data?.message, type: "error" })
      );
      console.log("Error", err);
    }
  };

  const [{ status }, toggle] = useTransition({
    timeout: 500,
    initialEntered: true,
    preEnter: true,
  });

  const scrollToFocusZone = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log(entry);
          if (entry.isIntersecting) {
            toggle(false);
            setTimeout(() => {
              setSelectedFocus(entry.target.id.split("-")[1]);
              toggle(true);
            }, 300);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    document.querySelectorAll(".focusZones").forEach((element) => {
      observer.observe(element);
    });

    // Cleanup
    return () => {
      document.querySelectorAll(".focusZones").forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const getGoals = (focus) => {
    let temp = [];
    temp = allGoals?.filter((i) =>
      focus
        ? i?.metrics?.find((a) => a?.name == "focusZone")?.value == focus
        : i?.metrics?.find((a) => a?.name == "focusZone")?.value ==
          selectedFocus
    );
    return temp;
  };

  return (
    <>
      <InSessionModal
        isOpen={inSession}
        closeModal={() => {
          setInSession(false);
        }}
        onClick={() => {
          history.push(`/clockedIn/${ongoingGoal?.data?.focusIn}`);

          setInSession(false);
        }}
      />
      <ClockInModal
        isOpen={openClockIn}
        closeModal={() => {
          setOpenClockIn(false);
        }}
        onUpdate={(data) => {
          clockIn(data);
          setOpenClockIn(false);
        }}
      />{" "}
      <GoalsInFocusModal
        isOpen={openFocusGoals}
        closeModal={() => {
          setOpenFocusGoals(false);
        }}
        onUpdate={() => {
          setOpenFocusGoals(false);
        }}
      />
      <SwitchZone
        isOpen={showSwitchZone}
        closeModal={() => setShowSwitchZone(false)}
        setSelectedFocus={(zone) => {
          toggle(false);
          setTimeout(() => {
            setSelectedFocus(zone);
            toggle(true);
            scrollToFocusZone(`focusZone-${zone}-start`);
          }, 300);
        }}
        selectedFocus={selectedFocus}
        getGoals={getGoals}
      />
      <div className="fixed top-0 bottom-0 right-0 left-0 z-40 bg-white">
        <TopBar focusZone />

        <div
          style={{
            background: goalFocusTime?.find((i) => i?.label == selectedFocus)
              ?.backgroundRadiant,
            transition: "background 0.5s ease",
          }}
          className="h-screen pt-[55px]"
        >
          <div className="relative">
            {/* <div className="px-4 py-1 flex flex-row items-center space-x-2">
              <Crosshair size={12} color="#fff" />
              <p className="text-white text-2xs font-semibold font-lato">
                Focus Zone
              </p>
            </div> */}
            <div className=" text-white font-lato flex flex-row items-center justify-center space-x-2">
              <div className="py-2 px-5 space-y-1 flex flex-col items-center text-white">
                <div
                  className={`flex flex-row items-center space-x-2 cursor-pointer basic-transition ${status}`}
                  onClick={() => setShowSwitchZone(true)}
                >
                  {selectedFocus &&
                    React.cloneElement(
                      goalFocusTime?.find((i) => i?.label == selectedFocus)
                        ?.svg,
                      {}
                    )}
                  <p className={`font-satoshi text-base font-medium leading-6`}>
                    {selectedFocus}
                  </p>
                </div>
                <p className="font-satoshi text-[10px] font-medium leading-5">
                  {goalFocusTime?.find((i) => i?.label == selectedFocus)?.time}
                </p>
              </div>
            </div>
            <div className="py-1 px-6 w-full space-y-2">
              <div className="w-full py-2">
                <div className="h-[2px] relative w-full bg-white/[.20]">
                  {originalFocus === selectedFocus && (
                    <div
                      style={{
                        width: `${progress}%`,
                        // background: goalFocusTime?.find(
                        //   (i) => i?.label == selectedFocus
                        // ).progressColor,
                      }}
                      className="h-[2px] relative w-full bg-white"
                    ></div>
                  )}
                  {originalFocus === selectedFocus && (
                    <div
                      className={`absolute h-3 bg-white w-3 rounded-full top-[-5px]`}
                      style={{
                        left: `${progress}%`,
                      }}
                    ></div>
                  )}
                </div>
              </div>
              {originalFocus === selectedFocus ? (
                <div className="w-full font-lato text-2xs font-bold flex flex-row items-center justify-center text-center text-white">
                  {timeLeft} left
                </div>
              ) : (
                <div className="w-full font-lato text-2xs font-bold flex flex-row items-center justify-center text-center text-white">
                  Starting in {startingIn}
                </div>
              )}
            </div>
          </div>

          <div className="relative">
            <img
              src="/assets/images/bgCurve.png"
              alt=""
              className="w-full object-cover h-auto"
            />

            <div className="flex justify-between items-center bg-white shadow px-4">
              <div className="flex justify-center items-center gap-2">
                <div className="flex py-1.5 px-1 justify-center items-center gap-2">
                  <Daylight className="text-[#FFBC00] h-5 w-5" />
                  <p className="text-primary-neutral-800 text-sm font-lato font-bold">
                    {getGoals("Daylight").length || 0}
                  </p>
                </div>
                {/* <div className="h-[29px] w-[2px] rounded-full bg-primary-neutral-200"></div> */}
                <div className="flex py-1.5 px-1 justify-center items-center gap-2">
                  <Sundown className="text-[#F57D34] h-5 w-5" />
                  <p className="text-primary-neutral-800 text-sm font-lato font-bold">
                    {getGoals("Sundown").length || 0}
                  </p>
                </div>
                {/* <div className="h-[29px] w-[2px] rounded-full bg-primary-neutral-200"></div> */}
                <div className="flex py-1.5 px-1 justify-center items-center gap-2">
                  <Nightwatch className="text-[#816FE9] h-5 w-5" />
                  <p className="text-primary-neutral-800 text-sm font-lato font-bold">
                    {getGoals("Nightwatch").length || 0}
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  history.push("/organize");
                }}
                className="p-2 flex flex-row items-center gap-2  rounded-lg  text-primary-neutral-800 font-lato text-xs font-bold cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 7H3V2H4V7ZM3 14H4V11H3V14ZM8 14H9V8H8V14ZM13 14H14V12H13V14ZM14 2H13V8H14V2ZM9 2H8V4H9V2ZM5 8H2C1.45 8 1 8.45 1 9C1 9.55 1.45 10 2 10H5C5.55 10 6 9.55 6 9C6 8.45 5.55 8 5 8ZM10 5H7C6.45 5 6 5.45 6 6C6 6.55 6.45 7 7 7H10C10.55 7 11 6.55 11 6C11 5.45 10.55 5 10 5ZM15 9H12C11.45 9 11 9.45 11 10C11 10.55 11.45 11 12 11H15C15.55 11 16 10.55 16 10C16 9.45 15.55 9 15 9Z"
                    fill="currentColor"
                  />
                </svg>{" "}
                <p className="underline">Organize</p>
              </div>
            </div>
            <div className="bg-white px-4 pt-4 flex flex-col space-y-6 h-[70vh] pb-[132px] sm:pb-24 overflow-y-auto">
              <div className="space-y-2">
                <h1 className="text-primary-neutral-500 font-lato text-xs font-bold">
                  In Focus
                </h1>
                {ongoingGoal?.data &&
                Object.keys(ongoingGoal?.data)?.length > 0 ? (
                  <div className="focusZones space-y-6 bg-primary-neutral-100 rounded-2xl flex flex-col items-center py-4 px-2 text-primary-neutral-400 font-lato text-xs font-normal">
                    <div className="rounded-2xl bg-white w-full py-3.5 px-4 flex flex-row items-center space-x-4">
                      <img
                        src="/assets/svg/target.svg"
                        alt=""
                        className="w-5 h-5"
                      />
                      <p className="text-black font-lato text-sm font-semibold leading-5 tracking-[0.28px]">
                        {ongoingGoal?.data?.title}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        history.push(
                          `/clockedIn/${ongoingGoal?.data?.focusIn}`
                        );
                      }}
                      className="flex flex-row items-center text-[#816FE9] justify-center space-x-2 text-center"
                    >
                      <p className="font-lato text-center text-sm font-bols underline underline-offset-4">
                        Go To Session
                      </p>
                      <ArrowRight size={16} className="-rotate-45" />
                    </div>
                  </div>
                ) : (
                  <div className="bg-primary-neutral-100 rounded-2xl flex flex-col items-center space-y-2 py-4 px-2 text-primary-neutral-400 font-lato text-xs font-normal">
                    <EmptyFocusZone />
                    <p>You’re not working on anything right now</p>
                  </div>
                )}
              </div>

              {sortedOptions.map((opt, i) => {
                return (
                  <div>
                    <div
                      className="flex my-6 py-4 justify-center items-center gap-4 w-full flex-auto"
                      key={i}
                      id={`focusZone-${opt.title}-start`}
                    >
                      <div className="w-1/3 bg-primary-neutral-200 h-[2px] flex-[1]"></div>
                      <div className="flex gap-2 items-center justify-center">
                        {opt.icon}
                        <span className="font-satoshi text-sm text-primary-neutral-500 font-medium">
                          {opt.title}
                        </span>
                      </div>
                      <div className="w-1/3 bg-primary-neutral-200 h-[2px] flex-[1]"></div>
                    </div>
                    <div className="space-y-2">
                      <h1 className="text-primary-neutral-500 font-lato text-xs font-bold">
                        On My Desk
                      </h1>
                      <div
                        id={`focusZone-${opt.title}`}
                        className="focusZones bg-primary-neutral-100 rounded-2xl flex flex-col items-center space-y-2 py-4 px-2 text-primary-neutral-400 font-lato text-xs font-normal"
                      >
                        <div className="p-2 flex w-full flex-col space-y-4">
                          {getGoals(opt.title)?.length > 0 ? (
                            getGoals(opt.title)?.map((item) => {
                              return (
                                <>
                                  <DeskCards
                                    item={item}
                                    category="Caarya"
                                    onFocus={() => {
                                      if (
                                        ongoingGoal?.data &&
                                        Object.keys(ongoingGoal?.data)?.length >
                                          0
                                      ) {
                                        setInSession(true);
                                      } else {
                                        setOpenClockIn(true);
                                        setTimeout(() => {
                                          setFocusedGoal({
                                            ...item,
                                            status: "InProgress",
                                          });
                                        }, 1000);
                                      }
                                    }}
                                    originalFocus={originalFocus}
                                    getCount={(val) => {
                                      return getGoals(val)?.length || 0;
                                    }}
                                  />
                                </>
                              );
                            })
                          ) : (
                            <div className="flex flex-col py-20 gap-y-6 items-center">
                              <EmptyDeskZone />
                              <p>No Goals picked up in this time-zone</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <TabBar />
      </div>
    </>
  );
}

export default FocusArea;
