import { Drawer } from "@mui/material";
import usePhaseOne from "components/ProfileForm/hooks/usePhaseOne";
import { motion } from "framer-motion";
import { FormComponents, QuestionArr } from "helpers/constants/profileForm";
import { useEffect, useRef, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateQuizStep } from "redux/profile-form";
import SelectedOption from "../../../MobileForm/components/QuizForm/assets/selected.png";
import UnselectedOption from "../../../MobileForm/components/QuizForm/assets/unselected.png";
import RiasecStats from "./RiasecStats";
import WebQuizNavigation from "./WebQuizNavigation";

const WebQuizForm = (props) => {
  const quizStep = useSelector(
    (state) => state.profileForm.phaseOne.details.quiz.quizStep
  );
  const { details, step, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );
  const [showOverlay, setShowOverlay] = useState(true);
  const [questions, setQuestions] = useState(QuestionArr);
  const [showRiasecInfo, setShowRiasecInfo] = useState(false);
  const dispatch = useDispatch();
  const overlayRef = useRef(null);
  const { field } = props;

  const { handleAnswerSelection, handleOptionDeselect } = usePhaseOne(field);

  useEffect(() => {
    const handleUserKeyPress = (event) => {
      const { key } = event;

      if (key === "ArrowLeft") {
        if (quizStep === 0) return;
        dispatch(updateQuizStep(quizStep - 1));
      } else if (key === "ArrowRight") {
        if (quizStep === QuestionArr.length - 1) return;
        dispatch(updateQuizStep(quizStep + 1));
      }
    };
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [quizStep]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const handlePaginationClick = (index) => {
    dispatch(updateQuizStep(index));
  };

  const handleQuizPaginationLeftClick = () => {
    if (quizStep === 0) return;
    dispatch(updateQuizStep(quizStep - 1));
  };

  const handleQuizPaginationRightClick = () => {
    if (quizStep === questions.length - 1) return;
    dispatch(updateQuizStep(quizStep + 1));
  };

  useEffect(() => {
    setQuestions(QuestionArr.map((question) => shuffle(question)));
    if (
      details[field].quizData.length >= QuestionArr.length - 1 &&
      quizStep === QuestionArr.length - 1
    ) {
      setShowRiasecInfo(true);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowOverlay(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef]);

  return (
    <div className="h-full  ">
      {showOverlay && (
        <div className="fixed inset-0 z-50 p-24 bg-black/50 flex flex-col justify-end  ">
          <div
            ref={overlayRef}
            className="text-white flex flex-col max-w-max mx-auto items-center gap-8 text-center text-2xl font-satoshi"
          >
            You can use Arrow keys to navigate this section
            <div className="flex gap-6 text-3xl">
              <MdKeyboardArrowLeft />
              <MdKeyboardArrowRight />
            </div>
          </div>
        </div>
      )}
      <div className="  font-manrope h-full flex flex-col gap-8 px-28 py-8 ">
        <div className="flex flex-col gap-2   ">
          <h1 className="text-primary-blue-500 text-xl font-medium ">
            Which of these do would you be most interested in doing?
          </h1>

          <h3 className="text-md text-primary-grey-600">
            Select the ones that you think you’ll enjoy doing the most. The ones
            that fascinate you, irrespective of whether or not you’ll actually
            do it.
          </h3>
        </div>
        <div className="flex justify-between gap-8 items-center">
          <div className="flex items-center gap-4">
            <div className="w-10 h-10 font-bold">
              <CircularProgressbar
                styles={buildStyles({
                  pathColor: "#FBC920",
                  trailColor: "#F7F4D4",
                  textSize: "2.5rem",
                  textColor: "#FF8800",
                })}
                value={((quizStep + 1) / QuestionArr.length) * 100}
                text={QuestionArr.length - quizStep}
              />
            </div>

            <p className="text-primary-grey-500 text-xs ">
              Steps away from understanding your natural inclinations
            </p>
          </div>
          {details[field]?.quizData.find(
            (quiz) => quiz.quizStep === quizStep
          ) ? (
            <div className="flex items-center gap-4">
              <p className="text-primary-grey-500 text-xs ">
                {2 -
                  details[field]?.quizData.find(
                    (quiz) => quiz.quizStep === quizStep
                  )?.options.length}{" "}
                Choices Remaining
              </p>

              {[0, 1].map((idx) => {
                if (
                  details[field]?.quizData.find(
                    (quiz) => quiz.quizStep === quizStep
                  )?.options[idx]
                ) {
                  return (
                    <img
                      key={idx}
                      src={SelectedOption}
                      alt=""
                      className="w-6 h-6 object-contain"
                    />
                  );
                } else
                  return (
                    <img
                      key={idx}
                      src={UnselectedOption}
                      alt=""
                      className="w-6 h-6 object-contain"
                    />
                  );
              })}
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <p className="text-primary-grey-500 text-xs ">
                2 Choices Remaining
              </p>
              <img
                src={UnselectedOption}
                alt=""
                className="w-6 h-6 object-contain"
              />
              <img
                src={UnselectedOption}
                alt=""
                className="w-6 h-6 object-contain"
              />
            </div>
          )}
        </div>
        <div className="p-2 border-b border-primary-grey-400 flex gap-6 items-center min-h-[64px] ">
          {details[field]?.quizData.find(
            (quiz) => quiz.quizStep === quizStep
          ) &&
            details[field]?.quizData
              .find((quiz) => quiz.quizStep === quizStep)
              ?.options.map((option, idx) => (
                <div
                  key={idx}
                  onClick={() => handleOptionDeselect(quizStep, option)}
                  className="p-3 px-4 rounded-full border hover:bg-primary-grey-150 border-primary-grey-200 text-center cursor-pointer  active:border-primary-blue-250 active:bg-primary-blue-50 "
                >
                  <p className="font-satoshi text-sm text-primary-grey-800">
                    {option}
                  </p>
                </div>
              ))}
        </div>
        <div className="  flex flex-col gap-6 h-full  ">
          <div className="flex gap-6 items-center flex-wrap py-6 ">
            {questions[quizStep].map((option, idx) => {
              const currentQuiz = details[field]?.quizData.find(
                (quiz) => quiz.quizStep === quizStep
              );
              return (
                <div
                  key={idx}
                  onClick={() =>
                    !(currentQuiz && currentQuiz?.options.includes(option)) &&
                    handleAnswerSelection(quizStep, option)
                  }
                  className={`w-full text-center  p-3 px-4 rounded-full border  max-w-max active:border-primary-blue-250 active:bg-primary-blue-50 ${
                    currentQuiz && currentQuiz?.options.includes(option)
                      ? "bg-primary-grey-150 text-primary-grey-150 border-primary-grey-150 cursor-not-allowed"
                      : "  hover:bg-primary-grey-150 border-primary-grey-200  text-primary-grey-800 cursor-pointer"
                  }  `}
                >
                  <p className="font-satoshi text-sm ">{option}</p>
                </div>
              );
            })}
          </div>

          <div className="flex justify-center items-center gap-5 w-full mt-20">
            <button
              onClick={handleQuizPaginationLeftClick}
              className="font-bold"
            >
              <BsChevronLeft />
            </button>

            {questions.map((_, index) => (
              <motion.p
                key={index}
                onClick={() => handlePaginationClick(index)}
                className={` cursor-pointer ${
                  quizStep === index
                    ? " underline-offset-2 text-primary-grey-600 font-semibold underline  "
                    : "text-primary-grey-400"
                } `}
              >
                {index + 1}
              </motion.p>
            ))}
            <button
              onClick={handleQuizPaginationRightClick}
              className="font-bold"
            >
              <BsChevronRight />
            </button>
          </div>
        </div>
      </div>
      {showRiasecInfo && (
        <Drawer
          anchor={"bottom"}
          open={showRiasecInfo}
          onClose={() => setShowRiasecInfo(false)}
        >
          <RiasecStats
            close={() => setShowRiasecInfo(false)}
            quizData={details[field]?.quizData}
          />
        </Drawer>
      )}
      {!isNavigationVisible && (
        <WebQuizNavigation
          totalSteps={FormComponents(step, details).length}
          field={field}
          setShowRiasecInfo={setShowRiasecInfo}
        />
      )}
    </div>
  );
};

export default WebQuizForm;
