import { CheckIcon } from "@heroicons/react/20/solid";
import {
  phaseTwoQuestionArr,
  quizNavigationData,
} from "helpers/constants/phaseTwoForm";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateQuizStepPhaseTwo,
  updateStepPhaseTwo,
  updateSubStepPhaseTwo,
} from "redux/profile-form";

const QuestionNavigation = ({ quiz, step, subStep, quizStep }) => {
  const { stepCompleted } = useSelector((state) => state.profileForm.phaseTwo);
  const dispatch = useDispatch();

  const handleNavigationClick = (idx) => {
    if (
      idx !== 0 &&
      stepCompleted[`step${idx}`] === false &&
      stepCompleted[`step${idx - 1}`] === false
    )
      return;

    dispatch(updateStepPhaseTwo(2 * idx + 1));
    dispatch(updateSubStepPhaseTwo(idx));
    dispatch(updateQuizStepPhaseTwo(0));
  };

  return (
    <div>
      <div className="border-t-2 border-primary-grey-200 relative mt-6 lg:mt-10 xl:mt-6 mx-2 lg:mx-10 xl:mx-0 ">
        <div className="hidden xl:flex gap-6 items-center absolute bottom-full -translate-y-8 right-10 xl:right-0 font-satoshi text-sm">
          <p className="text-primary-grey-400 text-xs ">Saved 2 minutes Ago</p>
          <button className="text-primary-blue-500 font-bold ">
            Save & Exit
          </button>
        </div>
        <div className="absolute bottom-0  inset-x-0 transform translate-y-6 sm:translate-y-10 lg:translate-y-12 flex justify-between px-2 lg:px-10 items-start  gap-4 lg:gap-6 font-satoshi">
          {quizNavigationData.map((item, idx) => (
            <div
              onClick={() => handleNavigationClick(idx)}
              key={idx}
              className="flex flex-col z-10 cursor-pointer items-center relative text-center"
            >
              {stepCompleted[`step${idx}`] === true ? (
                <div className="w-12 h-12 p-2 lg:w-16 lg:h-16 lg:p-4 bg-white rounded-full relative">
                  <div className="w-8 h-8 grid place-items-center rounded-full bg-primary-green-50 border-2 border-primary-green-500">
                    {" "}
                    <CheckIcon className="w-4" />{" "}
                  </div>
                </div>
              ) : (
                <div className="w-12 h-12 p-2 lg:w-16 lg:h-16 lg:p-4 bg-white rounded-full relative">
                  <CircularProgressbar
                    styles={buildStyles({
                      pathColor: "#4CA6E5",
                    })}
                    value={
                      quiz[idx]
                        ? (Object.keys(quiz[idx]).length /
                            phaseTwoQuestionArr[idx].questions.length) *
                          100
                        : 0
                    }
                  />
                  <div className=" rounded-full absolute inset-3 lg:inset-6 grid place-items-center text-primary-grey-400 bg-white  ">
                    <img
                      className="w-4 h-4 object-contain"
                      src={subStep === idx ? item.activeIcons : item.icons}
                      alt=""
                    />
                  </div>
                </div>
              )}

              <div
                className={`sm:hidden absolute -bottom-4 w-40  ${
                  idx === 0 && "left-0 text-left"
                } ${
                  idx === quizNavigationData.length - 1 && "right-0 text-right"
                } `}
              >
                {subStep == idx && (
                  <p
                    className={` text-xs  ${
                      subStep === idx
                        ? "text-primary-blue-275 font-semibold "
                        : "text-primary-grey-400 "
                    }-translate-y-1   `}
                  >
                    {item.title}
                  </p>
                )}
              </div>

              <p
                className={` text-xs hidden sm:block ${
                  subStep === idx
                    ? "text-primary-blue-275 font-semibold "
                    : "text-primary-grey-400 "
                }-translate-y-1 lg:-translate-y-3 `}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex xl:hidden gap-3 px-6 md:px-10 items-end mt-10 flex-col font-satoshi text-sm">
        <p className="text-primary-grey-400 text-xs ">Saved 2 minutes Ago</p>
        <button className="text-primary-blue-500 font-bold ">
          Save & Exit
        </button>
      </div>
    </div>
  );
};

export default QuestionNavigation;
