import { checkAppAccess } from "helpers/utils/accessCheck";
import { Redirect } from "react-router-dom";
import Today from "pages/Today";
import FocusArea from "pages/FocusArea";
import Sprint from "pages/SprintCard";
import CareerFlow from "pages/CareerFlow";
import SprintInsights from "pages/SprintCard/SprintInsights";
import ClockedIn from "pages/FocusArea/ClockedIn";
import Verse from "components/Dashboard/Verse";
import Essentials from "pages/FoundationEssentials/Essentials";
import Organize from "pages/Organize";
import Inbox from "pages/Inbox";
import AchievementsRedirect from "pages/CareerFlow/AchievementsRedirect";
import WorkContracts from "pages/CareerFlow/WorkContracts";
import Services from "pages/CareerFlow/Services";
import LaptopFocusArea from "pages/FocusArea/LaptopFocusArea";
import { useState, useEffect } from "react";
import AddAchievementPage from "pages/Achievements/Add";
import ViewProjectPage from "pages/Achievements/ViewProject";
import ProjectsPage from "pages/Achievements/Projects";
import BoostCareer from "pages/Assessments/BoostCareer";
import Assessment from "pages/Assessments/Assessment";
import MeProfile from "pages/Today/MeProfile";
import CareerInsights from "pages/SprintCard/CareerInsights";
import Space from "pages/Space";
import NewLifeCardPage from "pages/Today/NewLifeCard";
import ServiceDetails from "components/CareerFlow/ServiceDetails";
import CareerGoals from "pages/CareerGoals";
import MessageDetails from "pages/Inbox/MessageDetails";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

function ResponsiveFocusArea() {
  const { width } = useWindowSize();
  const breakpoint = 1024;

  return width >= breakpoint ? <LaptopFocusArea /> : <FocusArea />;
}
const privateRoutes = [
  {
    path: "/dashboard",
    component: Today,
    name: "Dashboard",
  },
  {
    path: "/meProfile",
    component: MeProfile,
    name: "Dashboard",
  },
  {
    path: "/newLifeCard",
    component: NewLifeCardPage,
    name: "New Life Card",
  },
  {
    path: "/rewards",
    component: Space,
    name: "Rewards",
  },
  {
    path: "/clockedIn/:focus",
    component: ClockedIn,
    name: "Clocked In",
  },
  {
    path: "/workContract",
    component: WorkContracts,
    name: "Work Contract",
  },
  {
    path: "/services",
    exact: true,
    component: Services,
    name: "Services",
  },
  {
    path: "/service/:serviceId",
    component: ServiceDetails,
    name: "Service Details",
  },
  {
    path: "/boostCareer",
    component: BoostCareer,
    name: "Services",
  },
  {
    path: "/assessments/:assessmentName",
    component: Assessment,
    name: "Services",
  },
  {
    path: "/achievementsRedirect",
    component: AchievementsRedirect,
    name: "Achievement",
  },
  {
    name: "Essentials",
    description: "",
    tab: "Essentials",
    path: "/essentials/:type",
    component: Essentials,
  },

  {
    name: "My Desk",
    description: "",
    tab: "My Desk",
    path: "/myDesk",
    component: ResponsiveFocusArea,
  },
  {
    name: "Inbox",
    description: "",
    tab: "Inbox",
    path: "/inbox",
    component: Inbox,
    exact: true,
  },
  {
    name: "Message Details",
    description: "",
    tab: "Message Details",
    path: "/inbox/:messageId",
    component: MessageDetails,
  },
  {
    name: "Sprint Card",
    description: "",
    tab: "Sprint Card",
    path: "/sprint",
    component: Sprint,
  },
  {
    name: "Organize",
    description: "",
    tab: "Organize",
    path: "/organize",
    component: Organize,
  },
  {
    name: "Sprint Insights",
    description: "",
    path: "/sprintInsights",
    component: SprintInsights,
  },
  {
    name: "Career Insights",
    description: "",
    path: "/careerInsights",
    component: CareerInsights,
  },
  {
    name: "Tribe Vibe",
    description: "",
    tab: "Tribe Vibe",
    path: "/tribe",
    component: Verse,
  },
  {
    name: "Career Flow",
    description: "",
    tab: "Career Flow",
    path: "/CareerFlow",
    component: CareerFlow,
  },
  {
    name: "Career Goals",
    description: "",
    tab: "Career Goals",
    path: "/CareerGoals",
    component: CareerGoals,
  },
  {
    path: "/achievements/add",
    component: AddAchievementPage,
  },
  {
    path: "/achievements/projects/:projectId",
    component: ViewProjectPage,
    exact: true,
  },
  {
    path: "/achievements/projects",
    component: ProjectsPage,
    exact: true,
  },
  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
