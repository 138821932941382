import React from "react";
import { ReactComponent as Logo } from "../../../assets/GoProPage/Polygon.svg";
import CaaryaMe from "../../../assets/GoProPage/caarya-ME.png";
import TargetIcon from "../assets/Target.svg";
import VisionaryIcon from "../assets/Visionary.svg";
import { focusPoints } from "./constants";
import InViewWrapper from "components/Animation/InViewWrapper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const ThirdTab = ({ step, setStep }) => {
  return (
    <div className="flex flex-col items-center gap-8">
      <div className="lg:px-40 px-6 font-inter">
        <InViewWrapper>
          <h1 className="w-full pt-12 pb-6 text-3xl leading-10 font-medium font-poppins">
            Get To Know Us
          </h1>
        </InViewWrapper>

        <InViewWrapper delay="0.1">
          <div className="flex flex-col gap-8 w-full py-10 px-2">
            <div className="flex flex-col w-full gap-2">
              <Logo />
              <h2 className="text-[#CE5511] text-2xl font-medium font-poppins">
                The Future of Caarya
              </h2>
              <p className="text-lg font-light font-inter ">
                Caarya (Kaarya): Work
              </p>
            </div>
            <div className="flex flex-col gap-12 lg:gap-20 justify-center mx-auto max-w-2xl ">
              <InViewWrapper delay="0.2">
                <div className="flex gap-8">
                  <img
                    className="w-16 h-16 object-contain"
                    src={VisionaryIcon}
                    alt=""
                  />
                  <div className="flex flex-col gap-2 font-inter text-sm">
                    <h2 className="font-semibold">Our Vision</h2>
                    <p className="font-light ">
                      Drive the Indian Startup Industry by creating and
                      nourishing impact startups
                    </p>
                  </div>
                </div>
              </InViewWrapper>
              <InViewWrapper delay="0.2">
                <div className="flex gap-8">
                  <img
                    className="w-16 h-16 object-contain"
                    src={TargetIcon}
                    alt=""
                  />
                  <div className="flex flex-col gap-2 font-inter text-sm">
                    <h2 className="font-semibold">Our Mission</h2>
                    <p className="font-light ">
                      Enriching the startup landscape by establishing growth
                      centers which empower an ecosystem of students,
                      entrepreneurs, promoters and skill experts.
                    </p>
                  </div>
                </div>
              </InViewWrapper>
            </div>
          </div>
        </InViewWrapper>
        <InViewWrapper>
          <div className="py-8 lg:py-10 w-full">
            <div className="flex flex-col gap-4 lg:gap-8 w-full py-10 lg:px-12 px-6 bg-[#FFF1EB] rounded-2xl">
              <div className="flex flex-col w-full gap-2">
                <Logo />
                <h2 className=" text-[#CE5511] text-2xl  font-medium font-poppins">
                  Our Focus Points
                </h2>
                <p className=" text-lg font-light font-inter ">
                  Hop on to know us better
                </p>
              </div>
              <div className="lg:px-4 lg:px-6 grid sm:grid-cols-2 md:grid-cols-3 gap-6 w-full md:justify-around">
                {focusPoints.map((point) => (
                  <div className="bg-white rounded-2xl shadow-lg p-8 flex sm:flex-col gap-4 items-center lg:justify-around w-full">
                    <Logo />
                    <p className="text-lg font-light font-inter justify text-center w-full">
                      {point}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </InViewWrapper>
        <InViewWrapper>
          <div className="flex flex-col lg:gap-8 gap-4 w-full py-10">
            <div className="flex flex-col w-full gap-2">
              <Logo />
              <h2 className=" text-[#CE5511] text-2xl  font-medium font-poppins">
                Life of a Student at Caarya
              </h2>
              <p className="text-lg font-light font-inter ">
                We are just a system away
              </p>
            </div>
            <div className="flex gap-6">
              <img
                className="lg:mx-auto lg:max-w-4xl h-auto "
                src="https://geisenhoferklinik.de/typo3temp/assets/_processed_/b/4/csm_dummy_video_2b14aff50b.png"
              />
              <div className="flex items-end">
                <div className="flex rounded-full shadow-lg items-center p-4 justify-center items-center w-14 h-14">
                  <ArrowUpwardIcon />
                </div>
              </div>
            </div>
          </div>
        </InViewWrapper>
      </div>
    </div>
  );
};

export default ThirdTab;
