import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AchievementCard from "./AchievementCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RiAddFill } from "react-icons/ri";

const Achievements = () => {
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.4, // Number of cards shown per view, adjust as needed
    slidesToScroll: 1,
  };
  return (
    <div className="bg-primary-neutral-50 py-8 space-y-8">
      <div className="flex flex-col gap-2 px-4">
        <div className="flex flex-row justify-between ">
          <p className="text-primary-neutral-800 font-satoshi capitalize text-[18px] leading-[18px] tracking-[0.6px] font-bold">
            Your Recent Achievements
          </p>

          <p className="flex flex-row items-center space-x-2">
            <p className="text-[#ED4C41] text-xs font-lato font-bold capitalize underline tracking-[0.48px] leading-[18px] underline-offset-2">
              View All
            </p>
          </p>
        </div>
        <p className="font-satoshi text-primary-neutral-500 text-xs font-normal tracking-[0.48px] leading-[18px]">
          Achievement cards added recently appear here
        </p>
      </div>
      {localStorage.getItem("achievements") ? (
        <div className="pl-2">
          <Slider {...settings}>
            {[
              {
                role: "Role Strategist",
                name: "Strategic Development",
                skills:
                  "Business Strategy, Critical Thinking, Commercial Awareness, Leadership Skills, Written Communication",
              },
              {
                role: "Social Media Manager",
                name: "Social Media Strategic Oversight",
                skills:
                  "Business Strategy, Critical Thinking, Commercial Awareness, Leadership Skills, Written Communication",
              },
              {
                role: "Graphic Designer",
                name: "Visual Strategy Development",
                skills:
                  "Business Strategy, Critical Thinking, Commercial Awareness, Leadership Skills, Written Communication",
              },
              {
                role: "Content Creator",
                name: "Strategic Content Creation",
                skills:
                  "Business Strategy, Critical Thinking, Commercial Awareness, Leadership Skills, Written Communication",
              },
            ]?.map((item) => {
              return <AchievementCard {...item} />;
            })}
          </Slider>
        </div>
      ) : (
        <div className="px-4">
          <div className="bg-primary-neutral-100 rounded-2xl py-4 px-6 flex flex-col items-center space-y-4">
            <img src="/assets/images/emptyCard.svg" alt="" className="" />
            <div className="flex flex-col items-center text-center font-satoshi">
              <h1 className="font-bold  leading-6 tracking-[0.32px] text-base mb-2">
                <span className="text-[#F57D34]">!</span>{" "}
                <span className="text-primary-neutral-800">
                  No Achievement Cards To Show
                </span>
              </h1>
              <p className="text-xs leading-4 text-primary-neutral-500 tracking-[0.24px]">
                You have not added any achievement cards yet. Add achievement
                cards to be eligible for work contracts{" "}
              </p>
            </div>
            <button
              onClick={() => {
                history.push("/achievementsRedirect");
              }}
              className="flex items-center justify-end text-white gap-2 py-3 pl-5 pr-6 bg-[#ED4C41] rounded-full"
            >
              <RiAddFill className="h-5 w-5 " />
              <p className="font-satoshi font-bold leading-5 text-sm tracking-[0.21px] cursor-pointer">
                Add Now
              </p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Achievements;
