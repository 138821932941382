import { createSlice } from "@reduxjs/toolkit";
import { getUserEvents } from "config/APIs/events";

import logError from "helpers/utils/common/logError";

const initialState = {
  upcoming: [],
  scheduled: [],
  pending: [],
  recommended: [],
  list: [],
  fetching: true,
};

// Storing User Squad
export const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    updateEvents: (state, action) => {
      let tab = action.payload.tab;
      let list = action.payload.list;
      state[tab] = list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateEvents } = events.actions;

export default events.reducer;

export function fetchAllUserEvents() {
  return async (dispatch) => {
    let list = [];

    try {
      const response = await getUserEvents("upcoming");

      if (response.status === 200) {
        let data = response.data.data;

        list = list.concat(data || []);

        dispatch(updateEvents({ tab: "upcoming", list: data || [] }));
      }
    } catch (err) {
      logError("Upcoming Events APIs ", err);
    }

    try {
      const response = await getUserEvents("scheduled");

      if (response.status === 200) {
        let data = response.data.data;

        list = list.concat(data || []);

        dispatch(updateEvents({ tab: "scheduled", list: data || [] }));
      }
    } catch (err) {
      logError("Scheduled Events APIs ", err);
    }
    try {
      const response = await getUserEvents("pending");

      if (response.status === 200) {
        let data = response.data.data;

        list = list.concat(data || []);

        dispatch(updateEvents({ tab: "pending", list: data || [] }));
      }
    } catch (err) {
      logError("Pending Events APIs ", err);
    }
    list = [...new Map(list?.map((item) => [item["id"], item])).values()];

    dispatch(updateEvents({ tab: "fetching", list: false }));
    dispatch(updateEvents({ tab: "list", list: list || [] }));
  };
}
