import ClockInModal from "components/FocusArea/Modal/ClockInModal";
import GoalsInFocusModal from "components/FocusArea/Modal/GoalInFocusModal";
import { endSession, startSession } from "config/APIs/session";
import { goalFocusTime } from "helpers/constants/goals";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllgoals, updateFocusOfGoals } from "redux/goal";
import {
  fetchManualCheckoutJournals,
  fetchOngoingJournals,
  updateOngoingJournals,
} from "redux/journals";
import { showToast } from "redux/toaster";
import DeskCards from "./DeskCards";
import SwitchZone from "components/Modals/Common/SwitchZoneModal";
import { ReactComponent as Daylight } from "assets/FocusArea/daylight.svg";
import { ReactComponent as Nightwatch } from "assets/FocusArea/nightwatch.svg";
import { ReactComponent as Sundown } from "assets/FocusArea/sundown.svg";
import { ReactComponent as EmptyDeskZone } from "assets/FocusArea/emptyDeskZone.svg";
import { useTransition } from "react-transition-state";
import InSessionModal from "components/FocusArea/Modal/InSessionModal";
import { ReactComponent as RedDesk } from "../../assets/FocusArea/RedDesk.svg";
import { ReactComponent as Desk } from "../../assets/FocusArea/fi_3907524.svg";
import { GiSettingsKnobs } from "react-icons/gi";
const options = [
  {
    title: "Daylight",
    icon: <Daylight className="text-[#ffffff] h-5 w-5" />,
  },
  {
    title: "Sundown",
    icon: <Sundown className="text-[#ffffff] h-5 w-5" />,
  },
  {
    title: "Nightwatch",
    icon: <Nightwatch className=" text-[#ffffff] h-5 w-5" />,
  },
];

function LaptopFocusArea() {
  const history = useHistory();
  const allGoals = useSelector((state) => state.goals?.goals);
  const ongoingGoal = useSelector((state) => state.journals?.onGoing);
  const manualCheckOut = useSelector((state) => state.journals?.manualCheckOut);
  const [sortedOptions, setSortedOptions] = useState([...options]);
  const [selectedFocus, setSelectedFocus] = useState("Nightwatch");
  const [originalFocus, setOriginalFocus] = useState("Nightwatch");
  const dispatch = useDispatch();
  const [openClockIn, setOpenClockIn] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [openFocusGoals, setOpenFocusGoals] = useState(false);
  const [currentSession, setCurrentSession] = useState();
  const [progress, setProgress] = useState(0);
  const [focusedGoal, setFocusedGoal] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [showSwitchZone, setShowSwitchZone] = useState(false);
  const [inSession, setInSession] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !openClockOut) {
      dispatch(fetchManualCheckoutJournals());
      if (manualCheckOut?.data?.sessionId) {
        setFocusedGoal(manualCheckOut?.data);
        setCurrentSession({
          id: manualCheckOut?.data?.sessionId,
          duration: manualCheckOut?.data?.duration,
          clockIn: manualCheckOut?.data?.clockIn,
          clockOut: manualCheckOut?.data?.clockOut,
        });

        setOpenClockOut(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    let isMounted = true;
    console.log(focusedGoal);
    if (isMounted && !openClockOut) {
      dispatch(fetchOngoingJournals());
      // if (Object.keys(ongoingGoal?.data).length !== 0) {
      //   setOpenClockIn(false);
      //   setFocusedGoal(ongoingGoal?.data);
      //   setCurrentSession({
      //     id: ongoingGoal?.data?.sessionId,
      //     clockIn: ongoingGoal?.data?.clockIn,
      //     duration: ongoingGoal?.data?.duration,
      //   });
      // }
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    dispatch(updateFocusOfGoals());
    dispatch(fetchAllgoals());
  }, []);
  const getTimeLeftOrTimeToStart = (endDate = "00:00") => {
    var now = moment();
    var end = moment(endDate, "HH:mm");

    var duration = moment.duration(end.diff(now));

    //Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(moment.duration(hours, "hours"));

    //Get Minutes and subtract from duration
    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, "minutes"));

    //Get seconds
    var seconds = duration.seconds();

    let remaining = hours + "h " + minutes + "min";
    return remaining;
    //This prints -22hours and -41 minutes
    //Here I would also check if current time is between deal hours, past or before.
  };

  useEffect(() => {
    let time = moment().format("HH");

    if (time >= 0 && time <= 8) {
      setSortedOptions([
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
      ]);
      setSelectedFocus("Nightwatch");
      setOriginalFocus("Nightwatch");
      setProgress(time * 8);
      setTimeLeft(getTimeLeftOrTimeToStart("8:00"));
    } else if (time > 8 && time <= 16) {
      setSortedOptions([
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
      ]);
      setSelectedFocus("Daylight");
      setOriginalFocus("Daylight");
      setProgress((time - 8) * 8);
      setTimeLeft(getTimeLeftOrTimeToStart("16:00"));
    } else {
      setSortedOptions([
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
      ]);
      setSelectedFocus("Sundown");
      setOriginalFocus("Sundown");
      setProgress((time - 16) * 8);
      setTimeLeft(getTimeLeftOrTimeToStart("23:59"));
    }
  }, []);
  // useEffect(() => {
  //   let interval;
  //   if (running) {

  //   } else if (!running) {
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [running]);

  useEffect(() => {
    if (currentSession) {
      if (currentSession?.clockIn) {
        const time1 = moment(currentSession?.clockIn);
        const time2 = moment();

        const format = "HH:mm:ss";

        const diffInMilliseconds = moment(time2, format).diff(
          moment(time1, format)
        );
      }
    }
  }, [currentSession]);

  const clockIn = async (data) => {
    try {
      let duration = parseInt(data.split(" ")[0]);
      if (data.split(" ")[1] === "Hour") duration *= 60;
      let time = moment();
      const response = await startSession({
        clockIn: time,
        goalId: focusedGoal?.id,
        duration,
      });
      setCurrentSession(response?.data?.data);
      console.log(response?.data?.data);
      setTimeout(async () => {
        dispatch(fetchOngoingJournals());
        if (ongoingGoal?.length > 0) {
          await clockOut(false);
          updateOngoingJournals([]);

          setOpenClockOut(true);
        }
      }, duration * 3600);

      dispatch(fetchAllgoals());

      history.push(`/clockedIn/${focusedGoal?.focusIn}`);
    } catch (err) {
      if (err?.response?.status) setInSession(true);
      else
        dispatch(
          showToast({ message: err?.response?.data?.message, type: "error" })
        );
      console.log("Error", err);
    }
  };

  const clockOut = async () => {
    let time;
    if (
      moment(focusedGoal?.clockIn).add(focusedGoal?.duration, "minutes") >
      moment()
    ) {
      time = moment();
    } else {
      time = moment(focusedGoal?.clockIn).add(focusedGoal?.duration, "minutes");
    }
    try {
      const response = await endSession({
        sessionId: currentSession?.id,
        clockOut: time,
        goalId: focusedGoal?.id,
        clockOutByUser: true,
      });

      dispatch(fetchAllgoals());
      setFocusedGoal();
    } catch (err) {
      dispatch(
        showToast({ message: err?.response?.data?.message, type: "error" })
      );
      console.log("Error", err);
    }
  };

  const [{ status }, toggle] = useTransition({
    timeout: 500,
    initialEntered: true,
    preEnter: true,
  });

  const scrollToFocusZone = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            toggle(false);
            setTimeout(() => {
              setSelectedFocus(entry.target.id.split("-")[1]);
              toggle(true);
            }, 300);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    document.querySelectorAll(".focusZones").forEach((element) => {
      observer.observe(element);
    });

    // Cleanup
    return () => {
      document.querySelectorAll(".focusZones").forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  const getGoals = (focus) => {
    let temp = [];
    temp = allGoals?.filter((i) =>
      focus ? i?.focusIn == focus : i?.focusIn == selectedFocus
    );
    return temp;
  };
  return (
    <>
      <InSessionModal
        isOpen={inSession}
        closeModal={() => {
          setInSession(false);
        }}
        onClick={() => {
          history.push(`/clockedIn/${ongoingGoal?.data?.focusIn}`);

          setInSession(false);
        }}
      />
      <ClockInModal
        isOpen={openClockIn}
        closeModal={() => {
          setOpenClockIn(false);
        }}
        onUpdate={(data) => {
          clockIn(data);
          setOpenClockIn(false);
        }}
      />{" "}
      <GoalsInFocusModal
        isOpen={openFocusGoals}
        closeModal={() => {
          setOpenFocusGoals(false);
        }}
        onUpdate={() => {
          setOpenFocusGoals(false);
        }}
      />
      <SwitchZone
        isOpen={showSwitchZone}
        closeModal={() => setShowSwitchZone(false)}
        setSelectedFocus={(zone) => {
          toggle(false);
          setTimeout(() => {
            setSelectedFocus(zone);
            toggle(true);
            scrollToFocusZone(`focusZone-${zone}-start`);
          }, 300);
        }}
        selectedFocus={selectedFocus}
        getGoals={getGoals}
      />
      <div className="flex flex-col space-y-2">
        <div className="border-b-2 border-[#E7E6E5] px-6 py-4 space-x-4 flex-row flex">
          <div>
            <RedDesk />
          </div>
          <div>
            <p className="text-[#ED4C41] font-satoshi font-bold text-base">
              My Desk
            </p>
          </div>
        </div>
        <div className="p-6 space-y-[40px] flex flex-col">
          <div className="flex flex-row justify-between">
            <div>
              <p className="font-lato text-[18px] text-[#696763] font-normal">
                Drag and drop goals move across zones or to start focusing on
                them
              </p>
            </div>
            <div className="bg-[#363430] flex flex-row justify-center items-center px-6 py-4 space-x-4 rounded-2xl">
              <div>
                <GiSettingsKnobs className="text-white" />
              </div>
              <div>
                <p className="text-white text-base font-bold">Organize Desk</p>
              </div>
            </div>
          </div>
          <div className="space-y-4 items-center justify-center flex flex-col">
            <div className="flex justify-center">
              <p className="font-lato font-bold text-[18px] text-[#696763]">
                In Focus
              </p>
            </div>
            <div className="py-[40px] w-[640px] px-6 space-y-4 justify-center flex flex-col rounded-2xl border-[1px] border-[#E7E6E5] bg-[#F3F2F2]">
              <div className="flex justify-center">
                <Desk className="w-[56px] text-[#9C9A96] h-[56px]" />
              </div>
              <div className="flex justify-center">
                <p className="text-[#9C9A96] font-normal font-lato text-sm">
                  Drop goals hear to start working on them
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-6  flex flex-col">
            <div className="flex justify-center">
              <p className="font-lato font-bold text-lg text-[#696763]">
                On Your Desk
              </p>
            </div>
            <div className="flex flex-row space-x-6 ">
              {sortedOptions.map((opt, i) => {
                return (
                  <div className="bg-[#f3f2f2] w-[365px] rounded-[32px]">
                    <div
                      className="flex rounded-t-[32px] flex-col pt-6 pb-4 space-y-4  "
                      style={{
                        background: goalFocusTime?.find(
                          (i) => i?.label == opt.title
                        )?.backgroundRadiant,
                      }}
                    >
                      <div className="flex justify-between px-4 flex-row">
                        <div className="flex  items-center flex-row space-x-4">
                          <div className="flex items-center">{opt.icon}</div>
                          <div className="flex flex-col justify-start">
                            <div>
                              <p className="text-white font-lato text-[18px] drop-shadow">
                                {opt.title}
                              </p>
                            </div>
                            <div>
                              <p className="text-white text-sm drop-shadow font-normal font-lato">
                                {
                                  goalFocusTime?.find(
                                    (i) => i?.label == opt.title
                                  )?.time
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        {originalFocus === selectedFocus &&
                          selectedFocus === opt.title && (
                            <div className="flex justify-center space-y-2 flex-col">
                              <div className="px-2 py-1 rounded bg-[#000000] opacity-[8%]">
                                {" "}
                                <p className="text-white font-bold text-[10px]">
                                  Current Zone
                                </p>
                              </div>
                              <div className="justify-center flex">
                                {" "}
                                <p className="text-white font-bold text-[10px]">
                                  {timeLeft} left
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="w-full py-2">
                        <div className="h-[2px] relative w-full bg-white">
                          {originalFocus === selectedFocus &&
                            selectedFocus === opt.title && (
                              <div
                                style={{
                                  width: `${progress}%`,
                                  background: goalFocusTime?.find(
                                    (i) => i?.label == selectedFocus
                                  ).progressColor,
                                }}
                                className="h-[2px] relative w-full bg-opacity-60"
                              ></div>
                            )}
                          {originalFocus === selectedFocus &&
                            selectedFocus === opt.title && (
                              <div
                                className={`absolute h-3 bg-white w-3 rounded-full top-[-5px]`}
                                style={{
                                  left: `${progress}%`,
                                }}
                              ></div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {getGoals(opt.title)?.length > 0 ? (
                        getGoals(opt.title)?.map((item) => {
                          return (
                            <>
                              <DeskCards
                                item={item}
                                onFocus={() => {
                                  if (
                                    ongoingGoal?.data &&
                                    Object.keys(ongoingGoal?.data)?.length > 0
                                  ) {
                                    setInSession(true);
                                  } else {
                                    setOpenClockIn(true);
                                    setTimeout(() => {
                                      setFocusedGoal({
                                        ...item,
                                        status: "InProgress",
                                      });
                                    }, 1000);
                                  }
                                }}
                                originalFocus={originalFocus}
                                getCount={(val) => {
                                  return getGoals(val)?.length || 0;
                                }}
                              />
                            </>
                          );
                        })
                      ) : (
                        <div className="flex flex-col py-20 gap-y-6 items-center">
                          <EmptyDeskZone />
                          <p>No Goals picked up in this time-zone</p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}

export default LaptopFocusArea;
