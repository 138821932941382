import Onboarding from "pages/Onboarding/indexOld";
import ProfilePage from "pages/Squad/Profile";
import GoogleAuth from "pages/AuthPages/GoogleAuth";
import GoogleSignIn from "pages/AuthPages/GoogleAuth/GoogleSignIn";

// Public Routes
// Routes for Authenticating Users
const authenticatedNoLayoutRoute = [
  {
    path: "/auth/onboard",
    component: Onboarding,
  },
  {
    path: "/onboard/google",
    component: GoogleSignIn,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/googleAuth",
    component: GoogleAuth,
  },
];

export { authenticatedNoLayoutRoute };
