import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Card, { PublicCard } from "./Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function PassSection({ name, sections, description, idx }) {
  const [fadeOffset, setFadeOffset] = useState();
  const [fadeOffsetDiff, setFadeOffsetDiff] = useState(0);
  const [maxHeight, setMaxHeight] = useState();
  const [inView, setInView] = useState(false);

  const onScrollPage = () => {
    const offset = window?.scrollY;
    if (offset > 8 && !maxHeight) {
      var ele1 = document.getElementById("indulgence");
      if (ele1) {
        var offsetHeight = ele1.offsetHeight;
        setMaxHeight(offsetHeight - 1000);
      }
    }
    let ele = document.getElementById("indulgence");
    if (ele1) {
      let offsetDiv = ele.offsetTop;
      setFadeOffsetDiff(offsetDiv - 200);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollPage, true);
    return () => {
      window.removeEventListener("scroll", onScrollPage);
    };
  }, []);

  // useMotionValueEvent(scrollY, "change", (latest) => {
  //   console.log(
  //     "Page scroll: ",
  //     latest,
  //     fadeOffset - fadeOffsetDiff,
  //     maxHeight
  //   );
  //   setFadeOffset(latest);
  // });

  return (
    <div className="section">
      <InView
        as="div"
        onChange={(inView) => {
          setInView(inView);
        }}
      >
        <div
          id="indulgence"
          className="bg-white px-2 pt-[60px] flex flex-col items-center"
        >
          <div
            style={{
              borderColor: inView ? "#FEB8B1" : "#e7e6e5",
            }}
            className="p-6 z-20 rounded-full w-[70vw] max-w-4xl text-center bg-white mx-auto flex flex-row items-center justify-center border-4 text-primary-neutral-800 font-poppins text-sm lg:text-lg font-medium leading-5 lg:leading-7"
          >
            {name}
          </div>
          <div className="relative w-full max-w-7xl mx-auto">
            <div
              style={{
                borderColor: "#FEB8B1",
              }}
              className={`${
                idx % 2 == 0
                  ? "rounded-tl-[24px] border-l-4 left-1"
                  : "rounded-tr-[24px] border-r-4 right-1"
              }   border-t-4 absolute -top-8  w-[40vw] h-20`}
            />
            <motion.div
              style={{
                height:
                  fadeOffset - fadeOffsetDiff > maxHeight
                    ? maxHeight
                    : fadeOffset - fadeOffsetDiff < 0
                    ? 10
                    : fadeOffset - fadeOffsetDiff,
                backgroundColor: "#FEB8B1",
              }}
              className={`${
                idx % 2 == 0 ? "left-1" : "right-1"
              } w-1 rounded absolute top-0  bottom-0 z-20`}
            />
            <div
              style={{
                backgroundColor: "#e7e6e5",
              }}
              className={`${
                idx % 2 == 0 ? "left-1" : "right-1"
              } w-1 rounded absolute top-0 bottom-0 z-10`}
            />
            <div
              style={{
                borderColor: "#FEB8B1",
              }}
              className={`${
                idx % 2 == 0
                  ? "rounded-bl-[24px] border-l-4 left-1"
                  : "rounded-br-[24px] border-r-4 right-1"
              }  border-b-4 absolute -bottom-28 w-[40vw] h-[40vh]`}
            />
            <p className="pt-8 px-6 text-center max-w-4xl mx-auto text-primary-neutral-800 font-inter text-base lg:text-2xl font-light leading-6 lg:leading-[30px]">
              {description}
            </p>
            <div className="relative w-full max-w-5xl mx-auto px-4 py-12 gap-8 grid lg:grid-cols-3">
              {sections?.map((item, index) => {
                return <Card item={item} />;
              })}
            </div>
          </div>
        </div>
      </InView>
    </div>
  );
}

export default PassSection;

function PassSectionPublic({ name, sections, description, idx }) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="section">
      <InView as="div">
        <div
          id="indulgence"
          className="bg-white py-6 flex flex-col space-y-3 px-5"
        >
          <div className="text-primary-neutral-800 md:text-center font-poppins text-lg lg:text-2xl font-medium leading-5 lg:leading-7">
            {name}
          </div>
          <p className="max-w-4xl mx-auto md:text-center text-primary-neutral-800 font-inter text-sm lg:text-base font-light leading-6 lg:leading-[30px]">
            {description}
          </p>
          <div className="relative w-full max-w-7xl mx-auto py-1.5 lg:py-6">
            <Slider {...settings}>
              {sections?.map((item, index) => {
                return (
                  <>
                    <div className="block lg:hidden">
                      <PublicCard item={item} />
                    </div>
                    <div className="hidden lg:block px-4">
                      <Card item={item} />
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
        </div>
      </InView>
    </div>
  );
}

export { PassSectionPublic };
