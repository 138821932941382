import PublicNavbar from "components/Common/Navigation/PublicNavbar";
import React from "react";

function Programs() {
  return (
    <div
      style={{
        background:
          window.innerWidth > 1024
            ? `linear-gradient(to right, #C63434, #0497AE)`
            : "linear-gradient(to top right, #C63434, #0497AE)",
      }}
      className=""
    >
      <PublicNavbar />
      <div className="px-6 py-[120px] min-h-screen space-y-14 max-w-5xl mx-auto">
        <div className="flex flex-col items-center w-full space-y-2">
          <h1 className="text-white font-satoshi text-center text-2xl font-bold leading-9 tracking-[0.96px]">
            Kickstart Your Caarya Life
          </h1>
          <p className="text-white opacity-60 text-center font-satoshi text-sm leading-5 tracking-[0.56px] capitalize">
            With programs catered to your career stage
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-14">
          {[
            {
              image: "/assets/images/program1.png",
              name: "Observerships",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              image: "/assets/images/program2.png",
              name: "Work Scholarships",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              image: "/assets/images/program3.png",
              name: "Industrial Work Contracts",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ]?.map((item) => {
            return (
              <div className="p-6 flex flex-col items-center space-y-6 rounded-3xl border border-white border-opacity-25">
                <img
                  src={item?.image}
                  alt=""
                  className="w-14 h-14 lg:w-20 lg:h-20 object-cover"
                />
                <div className="flex flex-col items-center space-y-2">
                  <h1 className="text-white text-center font-satoshi text-base font-bold leading-6 tracking-[0.64px]">
                    {item?.name}
                  </h1>
                  <p className="text-white opacity-60 text-center font-lato text-2xs font-normal leading-4 tracking-[0.4px] capitalize">
                    {item?.description}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center">
                  <div className="bg-white px-10 py-4 rounded-full text-black text-center font-lato text-sm font-bold leading-5 tracking-[0.56px] capitalize">
                    Get Started
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Programs;
