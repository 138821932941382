import React, { useEffect, useRef, useState } from "react";
import { BsTextLeft } from "react-icons/bs";
import { HiSparkles } from "react-icons/hi";

const MultilineTextInput = ({
  input,
  setInput,
  field,
  tags = [],
  iconType = "text",
  title,
  type,
  placeholder = "",
  subtitle = "",
  example = [],
  isPlaceHolderArr,
  placeholders = [],
}) => {
  const inputComponentRef = useRef(null);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputComponentRef.current &&
        !inputComponentRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputComponentRef]);

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter" && input[field].trim() !== "") {
      event.preventDefault();
      setInput({ ...input, [field]: input[field] + "\n" + breakSymbol });
    }
  };

  const handleTagClick = (tag) => {
    setInput({
      ...input,
      [field]:
        input[field].length > 0
          ? input[field] + "\n" + breakSymbol + tag
          : breakSymbol + tag,
    });
    inputRef.current.focus();
  };

  const breakSymbol = "・ ";
  return (
    <div
      id="hover_input"
      ref={inputComponentRef}
      onClick={() => type !== "VIEW" && setIsFocused(true)}
      className={`flex flex-col ${
        isFocused ? "gap-8" : "gap-4"
      } items-start w-full`}
    >
      <div className="flex gap-4 items-center w-full">
        {iconType === "text" ? (
          <div className="border rounded-sm border-primary-blue-500 ">
            <BsTextLeft className="text-primary-blue-500 font-bold" />
          </div>
        ) : (
          <HiSparkles className="text-primary-blue-300 font-bold mt-1 text-lg" />
        )}
        <h2 className="font-manrope text-md lg:text-base text-primary-grey-800 font-medium">
          {title}
        </h2>
      </div>
      <>
        {isFocused ? (
          <div className="flex flex-col gap-4 w-full lg:pl-9 ">
            {isFocused && (
              <h4 className="font-satoshi text-md text-primary-grey-800 ">
                {subtitle}
              </h4>
            )}
            <textarea
              type="text"
              ref={inputRef}
              value={input[field]}
              disabled={!isFocused}
              onChange={(e) =>
                setInput({
                  ...input,
                  [field]:
                    input[field].length > 0
                      ? e.target.value
                      : breakSymbol + e.target.value,
                })
              }
              onKeyDown={handleInputKeyPress}
              placeholder={placeholder}
              className={` ${
                isFocused
                  ? "border border-primary-blue-300 p-4 resize-y"
                  : "border-none p-0 resize-none"
              }   leading-6 text-sm rounded-lg w-full  outline-none focus:outline-none focus:ring-transparent placeholder:text-primary-grey-400`}
            />
            {isFocused && (
              <div className="flex flex-col gap-6">
                {example.length > 0 && (
                  <div className="flex gap-2 font-satoshi text-sm ">
                    <p className="font-bold">Eg.</p>
                    <div>
                      {example.map((example, idx) => (
                        <p key={idx}>{example}</p>
                      ))}
                    </div>
                  </div>
                )}

                {tags.length > 0 && (
                  <div className="flex flex-col lg:flex-row gap-4">
                    <h3 className="font-satoshi font-bold text-primary-grey-500 text-xs">
                      You might start with :
                    </h3>
                    <div className="flex gap-2 flex-wrap items-center w-full max-w-md ">
                      {tags.map((_tag, idx) => (
                        <div
                          key={idx}
                          onClick={() => handleTagClick(_tag)}
                          className="p-2 py-[2px] border hover:bg-primary-blue-100 hover:text-primary-grey-600 hover:border-primary-grey-300 cursor-pointer rounded-full font-satoshi text-xs text-primary-grey-400 "
                        >
                          {_tag} ...
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div id="hover_input_border">
            {input[field].length > 0 ? (
              <ul>
                {input[field]
                  .trim()
                  .split(breakSymbol)
                  .map(
                    (text, idx) =>
                      text !== "" && (
                        <li
                          key={idx}
                          className={`leading-6 text-sm list-disc `}
                        >
                          {text}
                        </li>
                      )
                  )}
              </ul>
            ) : (
              <ul className="flex flex-col">
                {placeholders.map((text, idx) => (
                  <li
                    key={idx}
                    className={`leading-6 text-sm text-primary-grey-400 list-disc`}
                  >
                    {text}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default MultilineTextInput;
