import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import React from "react";
import { useHistory } from "react-router-dom";

function Card({ item }) {
  const history = useHistory();
  return (
    <div
      className="relative cursor-pointer p-5 bg-white shadow rounded-3xl w-full flex flex-col justify-between space-y-6"
      onClick={() => {
        item?.path && history.push(item?.path);
      }}
    >
      <h1
        style={{ color: item?.color }}
        className="font-lato text-2xl leading-9 font-bold"
      >
        {item?.name}
      </h1>
      <p
        className={`flex flex-row justify-between items-end h-[12vh] text-white font-karla`}
      >
        <div
          className="rounded-full w-8 h-8 flex flex-row items-center justify-center"
          style={{ background: item?.color }}
        >
          <ArrowRight
            onClick={() => {
              item?.path && history.push(item?.path);
            }}
            size={24}
            className="-rotate-45"
          />
        </div>
        {item?.image && <img src={item?.image} alt="" className="" />}
      </p>
    </div>
  );
}

export default Card;
