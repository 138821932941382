import { ArrowSmallUpIcon } from "@heroicons/react/20/solid";
import React from "react";

const ScrollToTopButton = () => {
  return (
    <button
      onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
      className="fixed bottom-10 right-6 w-16 h-16 rounded-full grid place-items-center bg-white shadow-md text-primary-grey-500 "
    >
      <ArrowSmallUpIcon className="w-8" />
    </button>
  );
};

export default ScrollToTopButton;
