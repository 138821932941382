import { typeFormQuestions } from "helpers/constants/driveTypeForm";
import { useState } from "react";

import { MdArrowBack, MdDoneAll, MdInsights } from "react-icons/md";
import { ReactComponent as AI5Svg } from "assets/svg/ai5.svg";
import {
  CheckBoxCenter,
  CheckBoxLg,
  CheckBoxMd,
} from "components/Form/Typeform/Components/Scale";

export default function AI5({ page, setPage, setCompleted }) {
  const [answers, setAnswers] = useState({});

  const handleChange = (id, value) => {
    setAnswers({
      ...answers,
      [`${id}`]: { value },
    });

    const el = document.getElementById("question_" + (id + 1));
    if (el) {
      el.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };
  const handleDeSelect = (id) => {
    setAnswers((prev) => {
      const state = { ...prev };
      delete state[`${id}`];
      return state;
    });
  };
  const getChecked = (id) => {
    return answers[id];
  };

  const { questions } = typeFormQuestions[0];

  return (
    <section>
      <div
        onClick={() => {
          if (page == 1) history.goBack();
          else setPage(page - 1);
        }}
        className="flex px-4 py-6 flex-row items-center justify-between space-x-2 text-sm font-satoshi fixed top-0 w-full bg-white z-10"
      >
        <MdArrowBack size={24} />
        <div className="flex text-sm gap-2 pl-4 items-center text-primary-neutral-800">
          <AI5Svg className="h-5 w-5" />
          <span className="text-sm font-medium leading-[21px] tracking-[0.7px]">
            Natural Interest Profiling
          </span>
        </div>
        <p className="font-lato text-primary-neutral-400 font-normal">
          {Math.floor((Object.keys(answers).length * 100) / questions.length)}%
        </p>
      </div>
      <div className="h-[6px] bg-primary-neutral-200 w-[100vw] fixed top-[69px] z-10">
        <div
          style={{
            width: `10%`,
            background: "#816FE9",
          }}
          className="h-[6px] relative w-full bg-opacity-60"
        ></div>
      </div>
      <div className="flex flex-col items-center overflow-y-auto px-4 pt-6 mt-[76px] pb-12">
        {questions.map(({ id, question, subtitle, left, right }, i) => {
          return (
            <div
              key={i}
              style={{
                scrollMargin: "100px",
              }}
              id={`question_${id}`}
              className={`flex justify-start flex-col w-full max-w-3xl mx-auto px-2 py-10 border-b border-primary-neutral-200`}
            >
              {subtitle && (
                <div className="w-full capitalize font-satoshi text-sm flex flex-col md:flex-row md:items-center justify-start space-y-2 md:space-y-0 md:space-x-3 text-secondary-indigo-500 font-bold tracking-[1.2px] leading-5">
                  <div className="w-5 h-5 lg:w-6 lg:h-6">
                    <MdInsights />
                  </div>{" "}
                  <p>{subtitle}</p>
                </div>
              )}
              <h1
                className={`w-full mt-2 text-black text-left font-satoshi text-lg leading-7 font-medium`}
              >
                {question}
              </h1>
              <div
                className={`flex flex-col space-y-4 w-full max-w-[960px] mx-auto mt-10`}
              >
                <div className="flex flex-row items-center w-full justify-between">
                  <CheckBoxLg
                    type={0}
                    id={id}
                    handleChange={(value) => handleChange(id, value)}
                    handleDeSelect={() => handleDeSelect(id)}
                    isChecked={getChecked}
                    cls="bg-[#816FE9] border-[#816FE9]"
                  />
                  <CheckBoxMd
                    type={1}
                    id={id}
                    handleChange={(value) => handleChange(id, value)}
                    handleDeSelect={() => handleDeSelect(id)}
                    isChecked={getChecked}
                    cls="bg-[#816FE9] border-[#816FE9]"
                  />
                  <CheckBoxCenter
                    type={2}
                    id={id}
                    handleChange={(value) => handleChange(id, value)}
                    handleDeSelect={() => handleDeSelect(id)}
                    isChecked={getChecked}
                    cls="bg-[#816FE9] border-[#816FE9]"
                  />
                  <CheckBoxMd
                    type={3}
                    id={id}
                    handleChange={(value) => handleChange(id, value)}
                    handleDeSelect={() => handleDeSelect(id)}
                    isChecked={getChecked}
                    cls="bg-[#816FE9] border-[#816FE9]"
                  />
                  <CheckBoxLg
                    type={4}
                    id={id}
                    handleChange={(value) => handleChange(id, value)}
                    handleDeSelect={() => handleDeSelect(id)}
                    isChecked={getChecked}
                    cls="bg-[#816FE9] border-[#816FE9]"
                  />
                </div>
                <div className="flex flex-row items-center justify-between text-primary-neutral-400 font-lato text-xs lg:text-sm font-normal">
                  <p>{left}</p>
                  <p>{right}</p>
                </div>
              </div>
            </div>
          );
        })}
        <div className="py-[72px]">
          <button
            onClick={() => setCompleted(true)}
            className={`bg-black w-full flex flex-row items-center justify-center space-x-2 rounded-full pr-10 pl-12 py-4 cursor-pointer text-white font-satoshi text-base font-bold leading-6`}
          >
            <span>Submit Assessment</span>
            <MdDoneAll className="h-5 w-5" />
          </button>
        </div>
      </div>
    </section>
  );
}
