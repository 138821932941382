import Drawer from "@mui/material/Drawer";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { MdBolt } from "react-icons/md";
import "rsuite/Notification/styles/index.css";
import "rsuite/useToaster/styles/index.css";
import { getBoosterCount } from "helpers/utils";
import { useState } from "react";

function Booster({
  boosters,
  closeModal,
  isOpen,
  selectedBoosters,
  handleChange,
  handleSaveBoosters,
}) {
  const count = getBoosterCount(selectedBoosters);
  const [show, setShow] = useState(false);
  return (
    <>
      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        PaperProps={{
          style: {
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100%" : "560px",
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0.25) !important",
          },
        }}
        open={isOpen}
        onClose={() => {
          closeModal();
        }}
        transitionDuration={250}
      >
        <button
          aria-label="Close"
          type="button"
          onClick={closeModal}
          className="self-center mb-2 bg-primary-gray-1000 p-2 rounded-full"
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
        <div
          style={{
            boxShadow: "-5px 0px 10px #E7E6E5",
          }}
          className="rounded-t-2xl lg:rounded-t-none bg-white relative md:max-w-xl lg:h-screen lg:pt-20 mx-auto w-full transform transition-all ease-in-out duration-150"
        >
          <div className="p-6 flex justify-between items-center border-b border-b-primary-neutral-100">
            <span className="font-lato text-primary-neutral-800 font-bold">
              Add Insights
            </span>
            <div className="flex flex-col items-center gap-1">
              <div className="flex gap-2 items-start">
                {[...Array(4)].map((_, i) => {
                  return (
                    <div
                      className={`h-2 w-2 border rounded-full ${
                        count > i
                          ? "bg-primary-accent-500 border-primary-accent-500"
                          : "border-primary-neutral-200"
                      }`}
                      key={i}
                    ></div>
                  );
                })}
              </div>
              <p className="font-satoshi text-xs text-primary-neutral-800 font-normal capitalize">
                ({count}/4 Equipped)
              </p>
            </div>
          </div>
          <div className="h-[calc(100vh-200px)] md:h-[600px] overflow-y-scroll px-6 pt-10 pb-12">
            {boosters.map((booster) => {
              return (
                <div className="flex flex-col gap-4 mb-12 " key={booster._id}>
                  <p className="text-primary-accent-700 font-bold font-lato text-lg">
                    {booster.title}
                  </p>
                  <div className="flex flex-col gap-2">
                    {booster.options.map((option) => {
                      return (
                        <div
                          key={option._id}
                          className="flex items-center p-2 group w-full justify-between hover:bg-primary-neutral-50 cursor-pointer rounded"
                          onClick={() => {
                            setShow(true);
                            handleChange(booster._id, option._id);
                            return;
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <MdBolt
                              className={`bg-primary-neutral-200 rounded-full text-black p-0.5 text-xl `}
                            />
                            <span className="text-primary-neutral-800 font-normal text-sm">
                              {option.description}
                            </span>
                          </div>
                          <input
                            type="checkbox"
                            checked={
                              selectedBoosters
                                .find((b) => b.booster_id === booster._id)
                                ?.selectedOptions?.includes(option._id) || false
                            }
                            className="w-4 h-4 rounded-sm border-2 !shadow-none border-primary-neutral-300 group-hover:border-primary-neutral-500 focus:!outline-none !ring-none focus:!ring-none focus:!shadow-none checked:!bg-primary-accent-500 checked:!border-primary-accent-500 cursor-pointer"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {show && (
            <div className="flex shadow-2xl fixed bottom-0 w-full">
              <button
                onClick={closeModal}
                className="px-4 py-5 flex justify-center items-center gap-2 flex-[1_0_0] bg-white text-primary-neutral-500 font-satoshi font-bold"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveBoosters}
                className="px-4 py-5 flex justify-center items-center gap-2 flex-[1_0_0] bg-primary-accent-700 text-white font-satoshi font-bold"
              >
                Done
              </button>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default Booster;
