import { Pagination, useMediaQuery, useTheme } from "@mui/material";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { phaseTwoQuestionArr } from "helpers/constants/phaseTwoForm";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePhaseTwoQuiz } from "redux/profile-form";
import QuestionNavigation from "../QuestionNavigation";
import QuizNavigation from "./QuizNavigation";
import SingleOption from "./SingleOption";

const QuestionComponent = () => {
  const theme = useTheme();
  const largeView = useMediaQuery(theme.breakpoints.up("lg"));
  const { step, subStep, quizStep, quiz } = useSelector(
    (state) => state.profileForm.phaseTwo
  );

  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const handleOptionSelection = (option) => {
    let updatedQuiz;

    if (quiz[subStep] && quiz[subStep][quizStep]) {
      if (quiz[subStep][quizStep].includes(option)) {
        updatedQuiz = {
          ...quiz,
          [subStep]: {
            ...quiz[subStep],
            [quizStep]: quiz[subStep][quizStep].filter(
              (_option) => _option !== option
            ),
          },
        };

        dispatch(updatePhaseTwoQuiz(updatedQuiz));
      } else {
        updatedQuiz = {
          ...quiz,
          [subStep]: {
            ...quiz[subStep],
            [quizStep]: [...quiz[subStep][quizStep], option],
          },
        };

        dispatch(updatePhaseTwoQuiz(updatedQuiz));
      }
    } else {
      updatedQuiz = {
        ...quiz,
        [subStep]: { ...quiz[subStep], [quizStep]: [option] },
      };

      dispatch(updatePhaseTwoQuiz(updatedQuiz));
    }
  };

  useEffect(() => {
    window.scrollTo({ top: "0px", behavior: "smooth" });
    scrollRef.current.scrollTop = 0;
  }, [quizStep]);
  return (
    <div className="min-h-screen xl:h-screen flex flex-col ">
      {!largeView && (
        <div className=" grid place-items-center bg-white p-4 border-b border-primary-grey-200 border-opacity-30">
          <img
            src="/assets/images/logo/caaryaMe.svg"
            alt=""
            className="h-6 object-contain"
          />
        </div>
      )}

      <QuestionNavigation
        quiz={quiz}
        step={step}
        quizStep={quizStep}
        subStep={subStep}
      />
      <AnimatePresence>
        <motion.div
          key={quizStep}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="xl:px-28 xl:pt-16 xl:pb-0 xl:mt-10 p-6 py-2 pb-10 flex flex-col flex-1  items-center font-satoshi gap-6 ">
            <h4 className="text-md text-primary-grey-800">
              {phaseTwoQuestionArr[subStep]?.questions[quizStep]?.question ??
                ""}
            </h4>

            <div
              ref={scrollRef}
              className="xl:h-[50vh] xl:overflow-y-scroll w-full "
            >
              <div className="flex flex-col gap-5 w-full max-w-3xl mx-auto pt-10 ">
                {phaseTwoQuestionArr[subStep]?.questions[quizStep]?.options.map(
                  (option, idx) => (
                    <SingleOption
                      key={idx}
                      option={option}
                      isSelected={
                        quiz[subStep] &&
                        quiz[subStep][quizStep] &&
                        quiz[subStep][quizStep]?.includes(option)
                      }
                      handleOptionSelection={handleOptionSelection}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
      <QuizNavigation />
    </div>
  );
};

export default QuestionComponent;
