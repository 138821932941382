import { useRef, useState } from "react";
import CareerGoals from "./CareerGoals";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import College from "./College";
import { useHistory } from "react-router-dom";
import Personal from "./Personal";
import logError from "helpers/utils/common/logError";
import { registerUser } from "config/APIs/auth";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import CrewDrawer from "./CrewModal";

function SignUp() {
  const history = useHistory();
  const dispatch = useDispatch();
  let sliderRef = useRef(null);
  const [details, setDetails] = useState({});
  const [step, setStep] = useState(1);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipe: false,
    slidesToShow: 1, // Number of cards shown per view, adjust as needed
    slidesToScroll: 1,
    afterChange: function (index) {
      setStep(index + 1);
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    adaptiveHeight: true,
  };

  const onRegister = async () => {
    try {
      let body = {
        ...details,
        metaData: {
          careerGoal: details?.careerGoal,
        },
        personalInfo: {
          college:
            details?.collegeName == "Others"
              ? details?.newCollegeName
              : details?.collegeName,
        },
      };

      let res = await registerUser(body);
      if (res?.status == 201) {
        history.push({
          pathname: "/auth/signIn",
          state: { phoneNumber: body?.phoneNumber },
        });
      }
    } catch (e) {
      logError("Sign up", e);
      dispatch(
        showToast({
          message: e?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const [isCrewModalOpen, setIsCrewModalOpen] = useState(false);

  return (
    <section
      className={`relative bg-white min-h-[100vh] max-w-[100vw] flex flex-col items-center`}
    >
      <CrewDrawer
        isOpen={isCrewModalOpen}
        closeModal={() => setIsCrewModalOpen(false)}
      />
      <div className="flex !sticky top-0 header flex-col min-h-[15vh] pt-10 items-center space-y-2 lg:space-y-3 z-50">
        <svg
          onClick={() => {
            if (step == 1) {
              history.goBack();
            } else {
              sliderRef.slickGoTo(step - 2);
              setStep(step - 1);
            }
          }}
          className="absolute top-6 left-6 z-50"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M20 11.375H7.83L13.42 5.785L12 4.375L4 12.375L12 20.375L13.41 18.965L7.83 13.375H20V11.375Z"
            fill="white"
          />
        </svg>
        <div className="flex flex-row items-center space-x-2">
          <img
            src="/assets/images/life.jpg"
            alt=""
            className="w-6 h-6 lg:w-8 rounded-full lg:h-8"
          />
          <p className="font-poppins font-semibold text-xl text-black ">
            caarya
          </p>
        </div>
        <p className="font-lato capitalize text-black text-sm lg:text-lg font-light tracking-[0.84px]">
          Own Your Career
        </p>
        {/* <!--Waves Container--> */}
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
        {/* <!--Waves end--> */}
      </div>

      <section className="mx-auto w-full bg-white">
        <div className="max-w-[500px] mx-auto pt-5 lg:h-auto min-h-[70vh] w-full">
          <div className="mb-4 flex flex-row items-center justify-center space-x-3">
            {[1, 2, 3]?.map((item) => {
              return (
                <div
                  className={`w-2 h-2 rounded-full  ${
                    step >= item ? "bg-[#ED4C41]" : "border border-[#ED4C41]"
                  }`}
                />
              );
            })}
          </div>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            <CareerGoals
              details={details}
              setDetails={setDetails}
              onNext={() => {
                sliderRef.slickGoTo(step);
                setStep(step + 1);
                window.scrollTo({
                  top: 0,
                });
              }}
            />
            <College
              details={details}
              setDetails={setDetails}
              openCrewModal={() => setIsCrewModalOpen(true)}
              onNext={() => {
                sliderRef.slickGoTo(step);
                setStep(step + 1);
                window.scrollTo({
                  top: 0,
                });
              }}
            />
            <Personal
              details={details}
              setDetails={setDetails}
              onNext={() => {
                onRegister();
              }}
            />
          </Slider>
        </div>
      </section>
    </section>
  );
}

export default SignUp;
