/* eslint-disable no-undef */
const config = {
  environment: process.env.REACT_APP_ENV || "local",
  coreEndpoints: {
    prod: "https://chronos-core-prod-tk4gwh76qa-el.a.run.app",
    dev: "https://chronos-core-dev-tk4gwh76qa-el.a.run.app",
    local: "http://localhost:4193",
  },
  lifeEndpoints: {
    prod: "https://chronos-life-prod-tk4gwh76qa-el.a.run.app",
    dev: "https://chronos-life-dev-tk4gwh76qa-el.a.run.app",
    local: "http://localhost:3002",
  },
  talentEndpoints: {
    prod: "https://chronos-talent-prod-tk4gwh76qa-el.a.run.app",
    dev: "https://chronos-talent-dev-tk4gwh76qa-el.a.run.app",
    local: "https://chronos-talent-dev-tk4gwh76qa-el.a.run.app",
  },
  meEndpoints: {
    prod: "https://caaryame-backend-tk4gwh76qa-el.a.run.app",
    dev: "https://caaryame-backend-tk4gwh76qa-el.a.run.app",
    local: "https://caaryame-backend-tk4gwh76qa-el.a.run.app",
  },

  getEndpoint() {
    switch (this.environment) {
      case "local":
        return {
          coreEndpoint: this.coreEndpoints.dev,
          talentEndpoint: this.talentEndpoints.local,
          meEndpoint: this.meEndpoints.local,
          lifeEndpoint: this.lifeEndpoints.local,
        };
      case "development":
        return {
          coreEndpoint: this.coreEndpoints.dev,
          talentEndpoint: this.talentEndpoints.dev,
          meEndpoint: this.meEndpoints.dev,
          lifeEndpoint: this.lifeEndpoints.dev,
        };
      case "production":
        return {
          coreEndpoint: this.coreEndpoints.prod,
          talentEndpoint: this.talentEndpoints.prod,
          meEndpoint: this.meEndpoints.prod,
          lifeEndpoint: this.lifeEndpoints.prod,
        };
      default:
        return {
          coreEndpoint: "https://chronos-core-dev-tk4gwh76qa-el.a.run.app",
          talentEndpoint: "https://chronos-talent-dev-tk4gwh76qa-el.a.run.app",
          meEndpoint: this.meEndpoints.dev,
          lifeEndpoint: this.lifeEndpoints.dev,
        };
    }
  },
};

export default config;
