import { Drawer } from "@mui/material";
import { Clock, Sparkle } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { RiTimer2Line } from "react-icons/ri";
import { ReactComponent as Emoji1 } from "../../../assets/FocusArea/Emoji1.svg";
import { ReactComponent as Emoji2 } from "../../../assets/FocusArea/Emoji2.svg";
import { ReactComponent as Emoji3 } from "../../../assets/FocusArea/Emoji3.svg";
import { ReactComponent as Emoji4 } from "../../../assets/FocusArea/Emoji4.svg";
import { ReactComponent as Emoji5 } from "../../../assets/FocusArea/Emoji5.svg";

function ClockInModal({ isOpen, closeModal, onUpdate }) {
  const dispatch = useDispatch();
  const [selectedLine, setSelectedLine] = useState(3);
  const [data, setData] = useState({});
  //state to handle the custom time input visibility
  const [showCustomTimeInput, setShowCustomTimeInput] = useState(false);
  const [selectedTime, setselectedTime] = useState(false);
  const [ctaText, setctaText] = useState(false);
  const handleTimeChange = (event) => {
    const { value } = event.target;
    setData(value); // Assuming you want to store the custom time as a string
    setselectedTime(value);
  };

  useEffect(() => {
    if (data === "custom") {
      setShowCustomTimeInput(true);
      setselectedTime(false); // To initially hide the "Clock In Now" button
    } else {
      // setShowCustomTimeInput(false);

      setselectedTime(data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedTime) {
      setTimeout(() => {
        setctaText(true);
      }, 500);
    }
  }, [selectedTime]);
  useEffect(() => {
    if (!ctaText) {
      setTimeout(() => {
        setselectedTime(false);
      }, 50);
    }
  }, [ctaText]);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "32px 32px 0px 0px" : "0px",
          maxHeight: "100vh",
          height: window.innerWidth < 1024 ? "auto" : "100%",
          width: window.innerWidth < 1024 ? "100%" : "560px",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
        setData({});
      }}
      transitionDuration={250}
    >
      <div className="h-[95vh] relative flex flex-col items-stretch justify-end">
        <div className="flex justify-center items-center flex-row left-0 right-0 absolute top-0 ">
          <button className="inline-flex " onClick={closeModal}>
            <div className="w-[40px] h-[40px] rounded-40px bg-[#363430] p-2 flex justify-center items-center">
              <MdClose className="w-[14px] h-[14px] text-[white]" />
            </div>
          </button>
        </div>
        <div
          style={{
            boxShadow: "-5px 0px 10px #E7E6E5",
          }}
          className="md:max-w-xl lg:h-screen px-6 pt-6 pb-24 space-y-8 rounded-t-2xl lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150"
        >
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center justify-between space-x-2 text-[#363430] font-satoshi text-[18px] font-bold rounded-t-2xl">
              You’ll be Taken to Your Forge while working...
            </div>
            <p className="text-[#696763] font-lato text-sm font-normal">
              Please let us know how long you’re planning to stay engrossed in
              work!{" "}
            </p>
          </div>
          <div className="flex flex-row items-stretch -ml-2 -mt-2 flex-wrap">
            {[
              "15 Minutes",

              "30 Minutes",

              "1 Hour",
              "2 Hour",
              "4 Hour",
              "custom",
            ]?.map((item) => {
              return (
                <div
                  onClick={() => {
                    if (item === "custom") {
                      setData(item);
                      setShowCustomTimeInput(true); // Show the custom time input
                    } else {
                      setData(item);
                      setselectedTime(true);
                      setShowCustomTimeInput(false);
                      // Hide the custom time input if it was previously shown
                    }
                  }}
                  className={`m-2 flex rounded-lg flex-row  items-center justify-center px-3 py-2 border cursor-pointer ${
                    data == item
                      ? "border-[#FF7E6E]  bg-[#FFF4F2]  text-primary-gray-800"
                      : "border-primary-neutral-200 text-primary-neutral-400"
                  } ${
                    item == "custom" && showCustomTimeInput
                      ? "border-[#FF7E6E]  bg-[#FFF4F2]  text-primary-gray-800"
                      : "border-primary-neutral-200 text-primary-neutral-400"
                  } font-lato text-xs font-bold`}
                >
                  {item === "custom" && <RiTimer2Line className="mr-1" />}
                  {item}
                </div>
              );
            })}
          </div>
          <div className="space-y-5 flex flex-col">
            <p className="font-satoshi text-base font-bold">
              How are you currently feeling?
            </p>

            <div className="py-2 space-y-6 flex flex-col">
              <div className="flex justify-center ">
                {selectedLine === 0 && <Emoji1 />}
                {selectedLine === 1 && <Emoji2 />}
                {selectedLine === 2 && <Emoji3 />}
                {selectedLine === 3 && <Emoji4 />}
                {selectedLine === 4 && <Emoji5 />}
              </div>
              <input
                type="range"
                className="appearance-none bg-transparent range-input"
                value={selectedLine}
                style={{
                  background: `linear-gradient(to right, #FF7E6E ${
                    selectedLine * 25
                  }%, #ccc ${selectedLine * 25}%)`,
                }}
                onChange={(e) => setSelectedLine(+e.target.value)}
                min={0}
                max={4}
                step="1"
              />
            </div>
          </div>
          {showCustomTimeInput && (
            <TextField
              type="time"
              onChange={handleTimeChange}
              className="m-2"
            />
          )}
          <div className="flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <Sparkle size={16} color="#A193F2" />
              <p className="text-primary-neutral-400 font-satoshi text-xs font-medium">
                Pro Tip!
              </p>
            </div>
            <p className="text-[#363430] font-lato text-2xs font-normal">
              Try setting realistic session times, so you can manage your time
              better. Though you can always extend your session
              <br />
              <br />
            </p>
          </div>
          <div
            style={{
              boxShadow:
                "0px -4px 6px -4px rgba(0, 0, 0, 0.10), 0px -8px 15px -3px rgba(0, 0, 0, 0.10)",
            }}
            className={`flex bg-[#ED4C41] flex-col fixed bottom-0 right-0 left-0 clockInButton ${
              selectedTime ? "visible" : "hidden"
            }`}
          >
            <div
              onClick={() => {
                onUpdate(data);
              }}
              className={`w-full py-6 px-5 flex cursor-pointer flex-row space-x-2 items-center justify-center text-primary-red-darker font-lato text-sm font-semibold transition duration-150 ease-in-out ${
                ctaText ? "opacity-100" : "opacity-0"
              }`}
            >
              <Clock className="text-white" weight="fill" size={24} />
              <p className="text-white">Clock In Now</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ClockInModal;
