import React, { useState, useEffect } from "react";

// APIs
import firebase from "config/firebase.js";
import {
  loginWithFirebaseAuthToken,
  verifyPhoneNumberForOTP,
} from "config/APIs/auth";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { userUpdate } from "redux/user";
import moment from "moment";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

// Core components

const buttonStates = {
  init: {
    text: "Continue",
    disabled: false,
  },
  verifyNumber: {
    text: "Verifying Number",
    disabled: true,
  },
  sendOtp: {
    text: "Sending OTP",
    disabled: true,
  },
  askOtp: {
    text: "Verify OTP",
    disabled: false,
  },
  verifyOtp: {
    text: "Verifying OTP",
    disabled: true,
  },
  successOtp: {
    text: "Verified, you'll be redirected shortly",
    disabled: false,
  },
};

function LoginCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const [phoneNo, setPhoneNo] = useState("");
  const [user, setUser] = useState();
  const [counter, setCounter] = useState(120);
  const [option, setOption] = useState("phone");
  const [code, setCode] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [buttonState, setButtonState] = useState(buttonStates["init"]);
  function _handleTextFieldChange(e) {
    if (!isNaN(e.target.value) && e.target.value.toString().length <= 10) {
      setPhoneNo(e.target.value);
    }
  }

  const handleChange = (value1, event) => {
    if (event.target.value?.length > 1) {
    } else setCode({ ...code, [value1]: event.target.value });
  };
  useEffect(() => {
    if (location?.state?.phoneNumber) {
      setPhoneNo(location?.state?.phoneNumber);
      setTimeout(() => {
        handleClick(location?.state?.phoneNumber);
      }, 500);
    }
  }, []);

  const inputfocus = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;
      console.log(next);
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex;
      console.log(next);
      if (next < 6 && e.key !== "Shift") {
        e.target.form.elements[next].focus();
      }
    }
  };

  const handleClick = (number) => {
    setButtonState(buttonStates["verifyNumber"]);
    //First check whether phone is registered or not
    console.info("Checking number");
    verifyPhoneNumberForOTP(number ? number : phoneNo)
      .then((res) => {
        console.info("Number found");
        if (res.status == "200" && res.data.success) {
          setUser(res?.data?.data?.user);
          console.info("Status success");
          setButtonState(buttonStates["sendOtp"]);
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "send-otp-button",
            {
              size: "invisible",
            }
          );
          console.info("Recaptcha verified");
          const appVerifier = window.recaptchaVerifier;
          console.info("Sending firebase the number");
          firebase
            .auth()
            .signInWithPhoneNumber(
              "+91" + (number ? number : phoneNo),
              appVerifier
            )
            .then(function (confirmationResult) {
              console.info("Firebase sign in success");
              setButtonState(buttonStates["askOtp"]);
              setOption("otp");
              window.confirmationResult = confirmationResult;
              console.info("Set confirmation");
              setCounter(120);
              console.log(confirmationResult);
            });
        }
      })
      .catch((e) => {
        console.info("Err in auth number", e, "\n", e?.response);
        setButtonState(buttonStates["init"]);
        if (e?.response?.status == 404) {
          history.push("/auth/signUp");
        } else {
          dispatch(
            showToast({
              message: e?.response?.data?.message,
              type: "error",
            })
          );
        }
      });
  };

  function otpSubmit() {
    setButtonState(buttonStates["verifyOtp"]);
    console.info("Submitting otp");
    let OTP =
      code?.otp1 +
      code?.otp2 +
      code?.otp3 +
      code?.otp4 +
      code?.otp5 +
      code?.otp6;
    window.confirmationResult
      .confirm(OTP)
      .then((result) => {
        console.info("OTP matched");

        setButtonState(buttonStates["successOtp"]);
        dispatch(
          showToast({
            message: "OTP Login Successfull!",
          })
        );

        const token = result.user.getIdToken().then((res) => {
          const body = {
            idToken: res,
          };
          loginWithFirebaseAuthToken(res)
            .then((res) => {
              const { user: cred, token, type } = res.data.data;
              cred["role"] = "admin";
              cred["loginType"] = "phone";
              dispatch(
                userUpdate({
                  user: cred,
                  token: token,
                })
              );
              localStorage.setItem("role", "admin");
              localStorage.setItem("login_type", "phone");
              if (cred?.metaData?.onboarding == false) {
                window.location.href = window.location.origin + "/";
              } else window.location.href = window.location.origin + "/onboard";
            })
            .catch((e) => {
              console.log(e);
            });
        });
      })
      .catch((error) => {
        setButtonState(buttonStates["init"]);
        setOption("phone");
        dispatch(
          showToast({
            message: "OTP Login Unsuccessfull!",
            type: "error",
          })
        );
      });
  }

  return (
    <section className="mx-auto w-full bg-white">
      <div className="max-w-[560px] mx-auto pt-12 lg:pt-20 lg:h-auto h-[70vh] w-full">
        <div className="pb-6 px-6">
          <div className="flex flex-col space-y-8">
            <div className="text-black font-lato text-base font-bold leading-6 tracking-[0.32px] capitalize flex flex-col items-center text-center">
              {option == "phone" ? (
                "Enter your mobile number"
              ) : (
                <div className="flex flex-row items-center justify-center space-x-4">
                  {moment().format("HH") >= 4 && moment().format("HH") < 12 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_3432_69363)">
                        <path
                          d="M18.2599 17.6027C21.2727 14.2355 21.0822 9.08829 17.8287 5.95302C14.5752 2.81774 9.42453 2.81774 6.17107 5.95302C2.91761 9.08829 2.7271 14.2355 5.73989 17.6027H18.2599Z"
                          fill="#F0C419"
                        />
                        <path
                          d="M2 12.4016H0.4C0.179086 12.4016 0 12.2225 0 12.0016C0 11.7806 0.179086 11.6016 0.4 11.6016H2C2.22091 11.6016 2.4 11.7806 2.4 12.0016C2.4 12.2225 2.22091 12.4016 2 12.4016Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M3.33927 7.40343C3.26906 7.40342 3.20008 7.38494 3.13927 7.34983L1.75367 6.54983C1.56399 6.43862 1.49962 6.19517 1.60956 6.00475C1.71951 5.81433 1.96253 5.74835 2.15367 5.85703L3.53927 6.65703C3.69605 6.74755 3.77248 6.93209 3.72563 7.10696C3.67877 7.28182 3.52031 7.40342 3.33927 7.40343Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M7.00007 3.74259C6.85702 3.74273 6.72479 3.66647 6.65327 3.54259L5.85327 2.15699C5.78067 2.03316 5.77999 1.87993 5.85149 1.75546C5.92298 1.63099 6.05568 1.55437 6.19922 1.55469C6.34276 1.555 6.47512 1.63221 6.54607 1.75699L7.34607 3.14259C7.41748 3.26628 7.41752 3.41867 7.34617 3.54241C7.27482 3.66614 7.1429 3.74244 7.00007 3.74259Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M12.0001 2.4C11.7792 2.4 11.6001 2.22091 11.6001 2V0.4C11.6001 0.179086 11.7792 0 12.0001 0C12.221 0 12.4001 0.179086 12.4001 0.4V2C12.4001 2.22091 12.221 2.4 12.0001 2.4Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M16.9999 3.74259C16.8571 3.74244 16.7252 3.66614 16.6538 3.54241C16.5825 3.41867 16.5825 3.26628 16.6539 3.14259L17.4539 1.75699C17.5249 1.63221 17.6572 1.555 17.8008 1.55469C17.9443 1.55437 18.077 1.63099 18.1485 1.75546C18.22 1.87993 18.2193 2.03316 18.1467 2.15699L17.3467 3.54259C17.2752 3.66647 17.143 3.74273 16.9999 3.74259Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M20.6608 7.40343C20.4797 7.40342 20.3213 7.28182 20.2744 7.10696C20.2276 6.93209 20.304 6.74755 20.4608 6.65703L21.8464 5.85703C22.0375 5.74835 22.2805 5.81433 22.3905 6.00475C22.5004 6.19517 22.436 6.43862 22.2464 6.54983L20.8608 7.34983C20.8 7.38494 20.731 7.40342 20.6608 7.40343Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M23.6001 12.4016H22.0001C21.7792 12.4016 21.6001 12.2225 21.6001 12.0016C21.6001 11.7806 21.7792 11.6016 22.0001 11.6016H23.6001C23.821 11.6016 24.0001 11.7806 24.0001 12.0016C24.0001 12.2225 23.821 12.4016 23.6001 12.4016Z"
                          fill="#F3D55B"
                        />
                        <path
                          d="M13.2 17.9984C12.9791 17.9984 12.8 17.8194 12.8 17.5984V14.7984C12.8 14.5775 12.9791 14.3984 13.2 14.3984C13.421 14.3984 13.6 14.5775 13.6 14.7984V17.5984C13.6 17.8194 13.421 17.9984 13.2 17.9984Z"
                          fill="#E57E25"
                        />
                        <path
                          d="M11.2 17.9969C10.9791 17.9969 10.8 17.8178 10.8 17.5969V15.1969C10.8 14.976 10.9791 14.7969 11.2 14.7969C11.421 14.7969 11.6 14.976 11.6 15.1969V17.5969C11.6 17.8178 11.421 17.9969 11.2 17.9969Z"
                          fill="#E57E25"
                        />
                        <path
                          d="M15.7521 7.72705C15.254 6.73068 14.1363 6.2073 13.0521 6.46265C11.9678 6.718 11.2012 7.68511 11.2001 8.79905C10.5601 11.3591 7.35606 12.383 6.10406 12.6911C5.77786 12.7713 5.56305 13.0826 5.60383 13.416C5.64462 13.7495 5.92814 13.9998 6.26406 13.9991H7.95206L9.42406 14.699C10.4129 16.0477 12.2733 16.4075 13.6937 15.5249C15.1141 14.6423 15.6153 12.8148 14.8441 11.3311C14.7398 11.1315 14.8017 10.8855 14.9881 10.759C15.3107 10.5302 15.5718 10.2252 15.7481 9.87105L15.7521 7.72705Z"
                          fill="#3B97D3"
                        />
                        <path
                          d="M16.8748 10.1932L15.5696 9.82036C15.1142 9.69048 14.8001 9.27435 14.8 8.80076C14.8001 8.32716 15.1142 7.91103 15.5696 7.78116L16.8748 7.40836C16.9798 7.37816 17.0923 7.41845 17.1543 7.50841C17.2162 7.59838 17.2137 7.71787 17.148 7.80516L16.5152 8.64836C16.4476 8.73871 16.4476 8.8628 16.5152 8.95316L17.148 9.79636C17.2137 9.88364 17.2162 10.0031 17.1543 10.0931C17.0923 10.1831 16.9798 10.2234 16.8748 10.1932Z"
                          fill="#E57E25"
                        />
                        <path
                          d="M13.6 8.40156C13.8209 8.40156 14 8.22248 14 8.00156C14 7.78065 13.8209 7.60156 13.6 7.60156C13.379 7.60156 13.2 7.78065 13.2 8.00156C13.2 8.22248 13.379 8.40156 13.6 8.40156Z"
                          fill="#35495E"
                        />
                        <path
                          d="M8.62228 14.7969H7.95028C7.79906 14.7962 7.66518 14.699 7.61769 14.5554C7.57019 14.4119 7.6197 14.254 7.74068 14.1633C10.8475 11.8121 11.1999 8.79688 11.1999 8.79688C11.1999 8.79688 11.7411 9.34888 11.9767 11.2021C12.2191 13.1093 10.5355 14.7969 8.62228 14.7969Z"
                          fill="#2980BA"
                        />
                        <path
                          d="M23.6 18.0031H0.4C0.179086 18.0031 0 17.824 0 17.6031C0 17.3822 0.179086 17.2031 0.4 17.2031H23.6C23.8209 17.2031 24 17.3822 24 17.6031C24 17.824 23.8209 18.0031 23.6 18.0031Z"
                          fill="#F29C1F"
                        />
                        <path
                          d="M21.6 20.0031H2.4C2.17909 20.0031 2 19.824 2 19.6031C2 19.3822 2.17909 19.2031 2.4 19.2031H21.6C21.8209 19.2031 22 19.3822 22 19.6031C22 19.824 21.8209 20.0031 21.6 20.0031Z"
                          fill="#E57E25"
                        />
                        <path
                          d="M19.1999 22.0031H4.7999C4.57899 22.0031 4.3999 21.824 4.3999 21.6031C4.3999 21.3822 4.57899 21.2031 4.7999 21.2031H19.1999C19.4208 21.2031 19.5999 21.3822 19.5999 21.6031C19.5999 21.824 19.4208 22.0031 19.1999 22.0031Z"
                          fill="#D25627"
                        />
                        <path
                          d="M16.0001 24.0031H8.0001C7.77918 24.0031 7.6001 23.824 7.6001 23.6031C7.6001 23.3822 7.77918 23.2031 8.0001 23.2031H16.0001C16.221 23.2031 16.4001 23.3822 16.4001 23.6031C16.4001 23.824 16.221 24.0031 16.0001 24.0031Z"
                          fill="#C03A2B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3432_69363">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : moment().format("HH") >= 12 &&
                    moment().format("HH") < 16 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_3432_69427)">
                        <path
                          d="M20.6709 20.046C22.4233 18.0298 23.4844 15.3967 23.4844 12.5156C23.4844 6.17297 18.3427 1.03125 12 1.03125C5.65734 1.03125 0.515625 6.17297 0.515625 12.5156C0.515625 14.0498 0.817219 15.5134 1.36317 16.8513L20.6709 20.046Z"
                          fill="#64B6EC"
                        />
                        <path
                          d="M20.2841 13.0721L20.2482 13.0053C20.2311 13.0048 20.2145 12.9967 20.2033 12.9821C19.6635 12.2779 18.9759 11.8631 18.7052 11.7174C18.6725 11.6997 18.6374 11.6908 18.6023 11.6908C18.5794 11.6908 18.5566 11.6946 18.5344 11.7022C18.4772 11.7219 18.4317 11.7646 18.4063 11.8223C18.1299 12.4507 17.5848 12.7755 17.2845 12.9132C17.266 12.9218 17.2444 12.9219 17.2256 12.9134C17.207 12.905 17.1934 12.8897 17.1876 12.8703C17.0373 12.3739 16.7146 11.9486 16.5355 11.7393C16.4917 11.6881 16.4275 11.6607 16.3611 11.6644C16.2936 11.6681 16.2329 11.7023 16.1944 11.7585C15.7274 12.4415 15.0807 12.8219 14.7368 12.9878C14.7156 12.998 14.6918 12.9974 14.6716 12.9861C14.6506 12.9745 14.6371 12.9539 14.6344 12.9296C14.574 12.3761 14.2833 11.9674 14.1155 11.7732C14.0716 11.7222 14.0074 11.695 13.9409 11.6983C13.8752 11.7016 13.8159 11.7342 13.7784 11.7877C13.1768 12.6447 12.1962 12.856 11.8026 12.907C11.7814 12.9095 11.7607 12.9029 11.7451 12.8884C11.736 12.88 11.721 12.8615 11.7226 12.8329C11.7431 12.4643 11.6853 12.1257 11.6331 11.9066C11.6142 11.8268 11.5529 11.7657 11.4732 11.7471C11.3936 11.7284 11.3118 11.756 11.2596 11.8189C10.7305 12.4557 9.66948 12.7645 9.23785 12.8676C9.20026 12.8764 9.17659 12.8554 9.1681 12.8458C9.1501 12.8253 9.14518 12.7977 9.15493 12.7717C9.21123 12.6224 9.26031 12.4659 9.30081 12.3065C9.3227 12.2203 9.29012 12.1311 9.2177 12.0792C9.14523 12.0273 9.05021 12.0251 8.97559 12.0735C8.42767 12.4294 7.60956 12.6312 7.17067 12.7198C7.12731 12.729 7.10293 12.6987 7.09665 12.6891C7.09224 12.6825 7.08203 12.6636 7.08521 12.6402C6.49107 13.1345 5.93134 13.6281 5.40259 14.1185C6.12193 13.898 6.85285 13.8082 7.59128 13.8514C9.96292 13.9901 11.4124 14.8351 11.783 16.2952C12.1088 17.5787 11.5966 19.2906 10.341 21.1157C9.79651 21.907 9.1299 22.687 8.38388 23.4164C9.52093 23.7932 10.7365 23.9979 12 23.9979C15.4811 23.9979 18.6001 22.4488 20.7062 20.0028C21.2638 16.1456 20.2841 13.0721 20.2841 13.0721Z"
                          fill="#725057"
                        />
                        <path
                          d="M15.375 19.4741C17.0156 15.7241 14.7368 12.9929 14.7368 12.9929C14.7156 13.0031 14.6918 13.0025 14.6716 12.9912C14.6506 12.9796 14.6371 12.9589 14.6344 12.9346C14.574 12.3811 14.2833 11.9725 14.1155 11.7782C14.0716 11.7273 14.0074 11.7001 13.9409 11.7034C13.8752 11.7067 13.8159 11.7393 13.7784 11.7928C13.1768 12.6498 12.1962 12.861 11.8026 12.912C11.7814 12.9146 11.7607 12.908 11.7451 12.8935C11.736 12.885 11.721 12.8666 11.7226 12.838C11.7431 12.4693 11.6853 12.1308 11.6331 11.9117C11.6142 11.8319 11.5529 11.7708 11.4732 11.7521C11.3936 11.7335 11.3118 11.7611 11.2596 11.824C10.7305 12.4608 9.66948 12.7695 9.23785 12.8727C9.20026 12.8815 9.17659 12.8604 9.1681 12.8509C9.1501 12.8304 9.14518 12.8027 9.15493 12.7768C9.21123 12.6274 9.26031 12.4709 9.30081 12.3116C9.3227 12.2254 9.29012 12.1362 9.2177 12.0843C9.14523 12.0324 9.05021 12.0302 8.97559 12.0785C8.42767 12.4345 7.60956 12.6363 7.17067 12.7249C7.12731 12.734 7.10293 12.7037 7.09665 12.6942C7.09224 12.6875 7.08203 12.6687 7.08521 12.6453C6.49107 13.1396 5.93134 13.6331 5.40259 14.1236C6.12193 13.9031 6.85285 13.8133 7.59128 13.8565C9.96292 13.9952 11.4124 14.8402 11.783 16.3003C12.1088 17.5837 11.5966 19.2956 10.341 21.1207C9.79651 21.9121 9.1299 22.692 8.38388 23.4214C9.52088 23.7984 10.7364 24.003 12 24.003C12.9251 24.003 13.8243 23.8926 14.6861 23.686C13.9337 23.0405 14.3466 21.8246 15.375 19.4741Z"
                          fill="#543438"
                        />
                        <path
                          d="M7.59547 13.7842C5.11494 13.6392 3.02398 14.9735 1.34253 16.8031C2.6067 19.944 5.21876 22.3968 8.4627 23.446C12.5167 19.4626 13.9883 14.1578 7.59547 13.7842Z"
                          fill="#8E6877"
                        />
                        <path
                          d="M19.8114 9.81633C18.1352 4.8248 14.4764 0.353068 11.0015 0.0390526C7.15856 -0.308197 5.2981 1.77455 5.2981 1.77455L5.31544 1.84449C5.38847 1.83291 5.4585 1.84805 5.51785 1.88321C5.66297 1.96918 5.9333 2.15602 6.11274 2.44177C6.5205 3.09076 7.12833 3.15526 7.76494 3.48038C7.9193 3.54765 7.91785 3.76707 7.76222 3.83218C7.20239 4.06665 6.4732 4.47174 6.11752 5.0828L6.14255 5.18382C6.14255 5.18382 11.3229 6.30933 7.03824 12.5711C6.96577 12.6771 7.05905 12.8174 7.18486 12.792C7.64302 12.6995 8.46033 12.4955 9.01449 12.1355C9.12366 12.0646 9.26358 12.1649 9.23152 12.291C9.19781 12.4236 9.15127 12.5809 9.08803 12.7486C9.04735 12.8566 9.14222 12.9661 9.25439 12.9393C9.74461 12.8221 10.7806 12.5093 11.3145 11.8667C11.39 11.7759 11.5363 11.8104 11.5635 11.9252C11.6165 12.148 11.6709 12.4756 11.6511 12.8311C11.6462 12.9198 11.7235 12.9914 11.8116 12.98C12.2142 12.9278 13.2185 12.7117 13.8368 11.8309C13.8899 11.7552 14.0008 11.7519 14.0613 11.822C14.2289 12.016 14.5053 12.4087 14.5632 12.9395C14.574 13.0385 14.678 13.0976 14.7676 13.0543C15.0953 12.8963 15.7705 12.507 16.2533 11.8009C16.3069 11.7225 16.4193 11.7157 16.4811 11.7878C16.6584 11.9952 16.9729 12.4102 17.119 12.8932C17.1439 12.9753 17.2362 13.0161 17.3142 12.9803C17.5941 12.852 18.1802 12.5158 18.4716 11.8533C18.5066 11.7737 18.5947 11.7413 18.6713 11.7825C18.9583 11.9371 19.621 12.3424 20.1465 13.0278C20.1788 13.0699 20.2329 13.0888 20.2839 13.0743C20.3408 13.0581 20.3798 13.0052 20.3776 12.9461C20.3387 11.8817 20.1509 10.8271 19.8114 9.81633Z"
                          fill="#E6F0FF"
                        />
                        <path
                          d="M12.4372 5.70748L12.9124 5.60018C12.9665 5.58799 12.9888 5.52227 12.9523 5.48051C11.0177 3.26323 7.90877 3.63242 7.90877 3.63242L7.87844 3.63288C7.88796 3.7121 7.84924 3.79652 7.76238 3.8329C7.20255 4.06737 6.47336 4.47246 6.11768 5.08352L6.14271 5.18454C6.14271 5.18454 11.3231 6.31006 7.03839 12.5719C6.96593 12.6778 7.05921 12.8181 7.18502 12.7927C7.64318 12.7002 8.46049 12.4962 9.01464 12.1362C9.12382 12.0654 9.26374 12.1656 9.23172 12.2917C9.19802 12.4243 9.15143 12.5817 9.08819 12.7494C9.0475 12.8573 9.14238 12.9668 9.25455 12.94C9.74477 12.8228 10.7808 12.51 11.3147 11.8674C11.3901 11.7766 11.5364 11.8111 11.5637 11.926C11.6167 12.1488 11.6711 12.4763 11.6513 12.8319C11.6463 12.9206 11.7237 12.9921 11.8118 12.9806C12.2144 12.9285 13.2187 12.7123 13.8369 11.8316C13.8901 11.7559 14.0009 11.7526 14.0614 11.8226C14.0882 11.8536 14.1179 11.8902 14.1489 11.931C14.6988 8.42004 12.8503 6.28971 12.4009 5.83109C12.3605 5.78974 12.3808 5.72023 12.4372 5.70748Z"
                          fill="#C5DEF8"
                        />
                        <path
                          d="M7.87735 2.0196C7.86798 2.07168 7.8623 2.12502 7.8623 2.17977C7.8623 2.67664 8.2651 3.07949 8.76198 3.07949C9.25885 3.07949 9.66165 2.67669 9.66165 2.17977C9.66165 1.97432 9.59199 1.78555 9.47612 1.6341C8.72101 1.61371 8.18199 1.84016 7.87735 2.0196Z"
                          fill="#725057"
                        />
                        <path
                          d="M9.67217 1.12694C8.26503 0.988567 7.4893 1.71208 7.45419 1.7456C7.3116 1.88196 7.30541 2.10921 7.44182 2.25185C7.51227 2.32549 7.60635 2.36275 7.70052 2.36275C7.78864 2.36275 7.87686 2.33013 7.94582 2.26418C7.97146 2.24055 8.53653 1.73327 9.60177 1.83799C9.7835 1.85585 9.94874 1.73107 9.98431 1.55196C10.0252 1.34618 9.88096 1.14747 9.67217 1.12694Z"
                          fill="#725057"
                        />
                        <path
                          d="M7.79364 3.41406C7.79364 3.41406 7.72535 3.43136 7.60422 3.46037C6.55324 4.15459 5.1197 3.94061 3.95017 4.22913C3.34361 4.3788 3.02894 4.79313 2.86558 5.17741C2.85372 5.29769 2.86675 5.37714 2.86675 5.37714C3.64849 4.59541 6.1427 5.18298 6.1427 5.18298C6.47622 4.54492 7.24197 4.1268 7.78999 3.89725C8.0035 3.80786 8.00589 3.50659 7.79364 3.41406Z"
                          fill="#FDCA00"
                        />
                        <path
                          d="M9.03054 2.28917C9.08283 2.05953 8.93905 1.83098 8.70941 1.77869C8.47976 1.7264 8.25121 1.87017 8.19892 2.09982C8.14664 2.32946 8.29041 2.55801 8.52006 2.6103C8.7497 2.66259 8.97825 2.51881 9.03054 2.28917Z"
                          fill="#7E5966"
                        />
                        <path
                          d="M5.21436 5.02623C5.74334 5.089 6.14262 5.18298 6.14262 5.18298C6.47614 4.54492 7.24189 4.1268 7.7899 3.8973C8.00346 3.80786 8.00581 3.50655 7.79356 3.41406C7.79356 3.41406 7.72526 3.43136 7.60414 3.46037C6.94709 3.89434 6.14056 3.97342 5.34246 4.04219L5.29817 4.1177C5.29817 4.1177 5.83784 4.39933 5.29817 4.939C5.26925 4.96788 5.2414 4.99703 5.21436 5.02623Z"
                          fill="#FEAC00"
                        />
                        <path
                          d="M6.90421 3.02381C6.60421 2.89303 6.34738 2.67754 6.17333 2.40042C5.98794 2.10529 5.71325 1.91254 5.5543 1.81842C5.477 1.77267 5.38663 1.7557 5.29813 1.77126C4.57827 1.89768 1.16183 2.66573 2.57896 5.67492C2.6263 5.77546 2.77574 5.7576 2.79674 5.64848C2.88177 5.20584 3.14605 4.49864 3.96721 4.296C5.18989 3.99431 6.71338 4.24668 7.79357 3.41151L6.90421 3.02381Z"
                          fill="#FFE400"
                        />
                        <path
                          d="M5.15221 3.206L4.7728 3.09983C4.58277 3.04663 4.47187 2.84947 4.52502 2.65949C4.57818 2.4695 4.77529 2.35864 4.96537 2.4117L5.34477 2.51788C5.53476 2.57108 5.64571 2.76824 5.59255 2.95822C5.53944 3.14811 5.34247 3.25916 5.15221 3.206Z"
                          fill="#FEAC00"
                        />
                        <path
                          d="M10.0586 14.3067C9.42017 14.0258 8.60623 13.8433 7.59547 13.7842C5.11494 13.6392 3.02398 14.9735 1.34253 16.8031C2.0029 18.4437 3.03162 19.8961 4.32551 21.0593C6.42505 19.8854 8.5279 19.0946 6.85929 17.8351C4.5342 16.08 8.85195 14.6582 10.0586 14.3067Z"
                          fill="#725057"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3432_69427">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_3432_69487)">
                        <path
                          d="M12 1.03125C5.65734 1.03125 0.515625 6.17297 0.515625 12.5156C0.515625 18.8583 5.65734 24 12 24C16.5504 24 20.4825 21.3534 22.3414 17.5156L22.863 16.25C23.2654 15.0792 23.4844 13.823 23.4844 12.5156C23.4844 6.17297 18.3427 1.03125 12 1.03125Z"
                          fill="#C35AC7"
                        />
                        <path
                          d="M6.98426 1.01105C6.39476 0.388781 5.56127 0 4.63647 0C2.85016 0 1.4021 1.44806 1.4021 3.23438C1.4021 5.02069 2.85016 6.46875 4.63647 6.46875C5.56127 6.46875 6.39476 6.07997 6.98426 5.4577V1.01105Z"
                          fill="#FED402"
                        />
                        <path
                          d="M3.28113 3.23438C3.28113 1.77492 4.24797 0.541687 5.57599 0.139078C5.27862 0.0489375 4.96329 0 4.63647 0C2.85016 0 1.4021 1.44806 1.4021 3.23438C1.4021 5.02069 2.85016 6.46875 4.63647 6.46875C4.96329 6.46875 5.27862 6.41981 5.57599 6.32967C4.24797 5.92706 3.28113 4.69383 3.28113 3.23438Z"
                          fill="#FAC600"
                        />
                        <path
                          d="M14.1472 18.6326L14.1153 18.5625L14.0676 18.623C13.5626 19.2647 12.809 19.6328 12 19.6328C11.2063 19.6328 10.4616 19.2761 9.95691 18.6542L9.90816 18.5941L9.87746 18.6651C9.74466 18.9725 9.56719 19.251 9.35522 19.4953V20.1404C9.35522 20.8251 9.73721 21.4246 10.2984 21.7372C10.6259 21.5548 10.8916 21.2742 11.0567 20.9365V21.901C11.0567 22.5857 11.4387 23.1853 12 23.4979C12.5612 23.1853 12.9432 22.5857 12.9432 21.901V20.9365C13.1083 21.2742 13.374 21.5548 13.7015 21.7372C14.2627 21.4246 14.6447 20.825 14.6447 20.1404V19.4062C14.4453 19.1769 14.2767 18.9173 14.1472 18.6326Z"
                          fill="#704FB9"
                        />
                        <path
                          d="M10.3199 20.14V19.0211C10.189 18.9116 10.067 18.7895 9.95691 18.6538L9.90816 18.5938L9.87746 18.6648C9.74466 18.9721 9.56719 19.2507 9.35522 19.495V20.14C9.35522 20.8247 9.73721 21.4243 10.2984 21.7369C10.4806 21.6354 10.6427 21.5027 10.7799 21.3475C10.4944 21.0251 10.3199 20.6021 10.3199 20.14Z"
                          fill="#5D3F9A"
                        />
                        <path
                          d="M17.0625 16.2949V0.977774C17.0625 0.79468 16.8517 0.692071 16.7076 0.804992L13.8644 3.03376C13.7098 3.15493 13.5191 3.22079 13.3226 3.22079H10.6774C10.481 3.22079 10.2902 3.15493 10.1356 3.03376L7.29249 0.804992C7.14839 0.692024 6.9375 0.79468 6.9375 0.977774V16.2949H17.0625Z"
                          fill="#704FB9"
                        />
                        <path
                          d="M8.34375 10.3323V1.6291L7.29248 0.804992C7.14839 0.692024 6.9375 0.79468 6.9375 0.977774V13.0079C6.9375 11.8979 7.49447 10.9185 8.34375 10.3323Z"
                          fill="#5D3F9A"
                        />
                        <path
                          d="M9.65093 4.48438C8.7416 4.48438 8.00439 5.22153 8.00439 6.13091C8.00439 6.27477 8.02296 6.41427 8.0576 6.5473C8.12786 5.7297 8.81538 5.08569 9.65088 5.08569C10.4864 5.08569 11.1739 5.72966 11.2442 6.54725C11.2788 6.41427 11.2974 6.27477 11.2974 6.13091C11.2975 5.22153 10.5603 4.48438 9.65093 4.48438Z"
                          fill="#4B3087"
                        />
                        <path
                          d="M14.349 5.08569C15.1845 5.08569 15.872 5.7297 15.9423 6.5473C15.9769 6.41427 15.9955 6.27477 15.9955 6.13091C15.9955 5.22158 15.2583 4.48438 14.3489 4.48438C13.4396 4.48438 12.7024 5.22153 12.7024 6.13091C12.7024 6.27477 12.721 6.41431 12.7556 6.54734C12.826 5.7297 13.5135 5.08569 14.349 5.08569Z"
                          fill="#4B3087"
                        />
                        <path
                          d="M9.36853 16.2962V11.2529C9.36853 10.7082 9.53494 10.2016 9.81952 9.78125C8.19802 9.96364 6.9375 11.3389 6.9375 13.0092V16.2962H9.36853Z"
                          fill="#4B3087"
                        />
                        <path
                          d="M7.79264 17.4688C7.74258 17.4688 7.69547 17.4882 7.66003 17.5237L6.9375 18.2462V20.6497C8.29256 20.6497 9.45338 19.82 9.94088 18.641C9.67467 18.3065 9.48792 17.9062 9.40969 17.4688H7.79264Z"
                          fill="#4B3087"
                        />
                        <path
                          d="M14.6315 16.2972H17.0625V12.9223C17.0625 11.229 15.7669 9.83886 14.113 9.6875C14.4385 10.1255 14.6314 10.6675 14.6314 11.2538V16.2972H14.6315Z"
                          fill="#5D3F9A"
                        />
                        <path
                          d="M14.5902 17.4688C14.5144 17.8928 14.3368 18.282 14.0835 18.6101C14.5844 19.7586 15.7294 20.5619 17.0625 20.5619V17.4688H14.5902Z"
                          fill="#5D3F9A"
                        />
                        <path
                          d="M9.36304 17.4609C9.58424 18.7151 10.6851 19.6759 12.0001 19.6759C13.3152 19.6759 14.416 18.7151 14.6372 17.4609H9.36304Z"
                          fill="#7E5FC9"
                        />
                        <path
                          d="M14.6785 16.2919V11.2487C14.6785 9.77556 13.4732 8.57031 12.0001 8.57031C10.527 8.57031 9.32178 9.77556 9.32178 11.2487V16.2919H14.6785Z"
                          fill="#7E5FC9"
                        />
                        <path
                          d="M10.518 11.9327L10.8425 11.5599C10.9239 11.4663 10.8575 11.3203 10.7333 11.3203H10.0844C9.96031 11.3203 9.8938 11.4663 9.97526 11.5599L10.2997 11.9327C10.3574 11.999 10.4603 11.999 10.518 11.9327Z"
                          fill="#D3C4F7"
                        />
                        <path
                          d="M12.109 11.9327L12.4335 11.5599C12.515 11.4663 12.4485 11.3203 12.3244 11.3203H11.6755C11.5514 11.3203 11.4849 11.4663 11.5663 11.5599L11.8908 11.9327C11.9485 11.999 12.0514 11.999 12.109 11.9327Z"
                          fill="#D3C4F7"
                        />
                        <path
                          d="M13.7001 11.9327L14.0246 11.5599C14.106 11.4663 14.0396 11.3203 13.9155 11.3203H13.2665C13.1424 11.3203 13.0759 11.4663 13.1574 11.5599L13.4819 11.9327C13.5395 11.999 13.6425 11.999 13.7001 11.9327Z"
                          fill="#D3C4F7"
                        />
                        <path
                          d="M11.3136 13.464L11.6381 13.0911C11.7196 12.9975 11.6531 12.8516 11.529 12.8516H10.88C10.756 12.8516 10.6895 12.9976 10.7709 13.0911L11.0954 13.464C11.1531 13.5303 11.256 13.5303 11.3136 13.464Z"
                          fill="#D3C4F7"
                        />
                        <path
                          d="M12.9047 13.464L13.2292 13.0911C13.3106 12.9975 13.2442 12.8516 13.12 12.8516H12.4711C12.347 12.8516 12.2805 12.9976 12.362 13.0911L12.6865 13.464C12.7442 13.5303 12.847 13.5303 12.9047 13.464Z"
                          fill="#D3C4F7"
                        />
                        <path
                          d="M12.1027 14.8624L12.4272 14.4896C12.5086 14.396 12.4422 14.25 12.318 14.25H11.6691C11.545 14.25 11.4785 14.396 11.56 14.4896L11.8845 14.8624C11.9422 14.9287 12.045 14.9287 12.1027 14.8624Z"
                          fill="#D3C4F7"
                        />
                        <path
                          d="M9.65093 8.33213C10.5603 8.33213 11.2975 7.59495 11.2975 6.68559C11.2975 5.77624 10.5603 5.03906 9.65093 5.03906C8.74157 5.03906 8.00439 5.77624 8.00439 6.68559C8.00439 7.59495 8.74157 8.33213 9.65093 8.33213Z"
                          fill="white"
                        />
                        <path
                          d="M8.98821 6.68555C8.98821 5.94755 9.47378 5.32298 10.1428 5.11383C9.98749 5.06527 9.8223 5.03906 9.65093 5.03906C8.7416 5.03906 8.00439 5.77622 8.00439 6.68559C8.00439 7.59492 8.74155 8.33212 9.65093 8.33212C9.82225 8.33212 9.98749 8.30592 10.1428 8.25736C9.47374 8.04811 8.98821 7.42359 8.98821 6.68555Z"
                          fill="#FBF0FF"
                        />
                        <path
                          d="M9.65094 7.45081C10.0753 7.45081 10.4193 7.1068 10.4193 6.68244C10.4193 6.25808 10.0753 5.91406 9.65094 5.91406C9.22658 5.91406 8.88257 6.25808 8.88257 6.68244C8.88257 7.1068 9.22658 7.45081 9.65094 7.45081Z"
                          fill="#5D3F9A"
                        />
                        <path
                          d="M9.37494 6.68244C9.37494 6.34494 9.59399 6.0582 9.89713 5.95527C9.81974 5.92897 9.7371 5.91406 9.65094 5.91406C9.22729 5.91406 8.88257 6.25878 8.88257 6.68244C8.88257 7.10609 9.22729 7.45081 9.65094 7.45081C9.73715 7.45081 9.81974 7.43591 9.89713 7.40961C9.59399 7.30667 9.37494 7.01994 9.37494 6.68244Z"
                          fill="#4B3087"
                        />
                        <path
                          d="M14.3489 8.33213C15.2583 8.33213 15.9955 7.59495 15.9955 6.68559C15.9955 5.77624 15.2583 5.03906 14.3489 5.03906C13.4396 5.03906 12.7024 5.77624 12.7024 6.68559C12.7024 7.59495 13.4396 8.33213 14.3489 8.33213Z"
                          fill="white"
                        />
                        <path
                          d="M13.6756 6.68559C13.6756 5.94567 14.1637 5.3198 14.8355 5.11223C14.6817 5.0647 14.5183 5.03906 14.3489 5.03906C13.4396 5.03906 12.7024 5.77622 12.7024 6.68559C12.7024 7.59492 13.4395 8.33213 14.3489 8.33213C14.5183 8.33213 14.6817 8.30648 14.8355 8.25895C14.1637 8.05144 13.6756 7.42556 13.6756 6.68559Z"
                          fill="#FBF0FF"
                        />
                        <path
                          d="M14.3489 7.45081C14.7733 7.45081 15.1173 7.1068 15.1173 6.68244C15.1173 6.25808 14.7733 5.91406 14.3489 5.91406C13.9246 5.91406 13.5806 6.25808 13.5806 6.68244C13.5806 7.1068 13.9246 7.45081 14.3489 7.45081Z"
                          fill="#5D3F9A"
                        />
                        <path
                          d="M14.0624 6.68244C14.0624 6.34297 14.284 6.05469 14.5899 5.9533C14.514 5.92817 14.4332 5.91406 14.3489 5.91406C13.9252 5.91406 13.5806 6.25878 13.5806 6.68244C13.5806 7.10609 13.9253 7.45081 14.3489 7.45081C14.4331 7.45081 14.514 7.4367 14.5899 7.41158C14.284 7.31019 14.0624 7.02195 14.0624 6.68244Z"
                          fill="#4B3087"
                        />
                        <path
                          d="M12.1107 9.17485L13.1191 7.60768C13.1565 7.5496 13.1426 7.47249 13.0874 7.431L12.079 6.67477C12.0322 6.63966 11.9678 6.63966 11.9209 6.67477L10.9126 7.431C10.8573 7.47244 10.8434 7.54955 10.8808 7.60768L11.8892 9.17485C11.9411 9.25547 12.0589 9.25547 12.1107 9.17485Z"
                          fill="#FED402"
                        />
                        <path
                          d="M11.8501 7.60768C11.8128 7.5496 11.8266 7.47249 11.8819 7.431L12.4846 6.97894L12.079 6.67477C12.0322 6.63966 11.9678 6.63966 11.9209 6.67477L10.9126 7.431C10.8573 7.47244 10.8434 7.54955 10.8808 7.60768L11.8892 9.17485C11.9411 9.25543 12.0589 9.25543 12.1107 9.17485L12.4846 8.59383L11.8501 7.60768Z"
                          fill="#FAC600"
                        />
                        <path
                          d="M22.863 16.2487H20.6426C20.6053 16.2487 20.5695 16.2339 20.5431 16.2075L18.2129 13.8772C18.1387 13.8031 18.0126 13.8332 17.9798 13.9327L17.6353 14.9802C17.6187 15.0306 17.6319 15.086 17.6694 15.1236L18.5544 16.0086C18.643 16.0972 18.5803 16.2487 18.455 16.2487H4.40427C4.37507 16.2487 4.34662 16.2578 4.32281 16.2747L2.93737 17.259C2.82571 17.3384 2.88182 17.5143 3.01884 17.5143H5.60502C5.73032 17.5143 5.79304 17.6658 5.70445 17.7544L5.00137 18.4574C4.94643 18.5124 4.94643 18.6014 5.00137 18.6563L5.69742 19.3524C5.75235 19.4073 5.84137 19.4073 5.89631 19.3524L7.69316 17.5555C7.71956 17.5291 7.75532 17.5143 7.79259 17.5143H22.3414C22.5396 17.1054 22.7138 16.6828 22.863 16.2487Z"
                          fill="#A44F3E"
                        />
                        <path
                          d="M10.9136 15.6641C10.6857 15.6641 10.4877 15.7964 10.3912 15.9878C10.2948 15.7964 10.0968 15.6641 9.86891 15.6641C9.64105 15.6641 9.44305 15.7964 9.34659 15.9878C9.25012 15.7964 9.05212 15.6641 8.82426 15.6641C8.50251 15.6641 8.23926 15.9273 8.23926 16.2491V17.7951C8.23926 18.1169 8.50251 18.3801 8.82426 18.3801C9.05212 18.3801 9.25012 18.2478 9.34659 18.0564C9.44305 18.2478 9.64105 18.3801 9.86891 18.3801C10.0968 18.3801 10.2948 18.2478 10.3912 18.0564C10.4877 18.2478 10.6857 18.3801 10.9136 18.3801C11.2353 18.3801 11.4986 18.1169 11.4986 17.7951V16.2491C11.4986 15.9273 11.2353 15.6641 10.9136 15.6641Z"
                          fill="#FED402"
                        />
                        <path
                          d="M9.20943 17.7951V16.2491C9.20943 16.1289 9.24618 16.017 9.30894 15.9238C9.20371 15.7676 9.02573 15.6641 8.82426 15.6641C8.50251 15.6641 8.23926 15.9273 8.23926 16.2491V17.7951C8.23926 18.1169 8.50251 18.3801 8.82426 18.3801C9.02573 18.3801 9.20371 18.2766 9.30894 18.1204C9.24618 18.0272 9.20943 17.9153 9.20943 17.7951Z"
                          fill="#FAC600"
                        />
                        <path
                          d="M15.1758 15.6641C14.9479 15.6641 14.7499 15.7964 14.6534 15.9878C14.557 15.7964 14.359 15.6641 14.1311 15.6641C13.9033 15.6641 13.7053 15.7964 13.6088 15.9878C13.5123 15.7964 13.3143 15.6641 13.0865 15.6641C12.7647 15.6641 12.5015 15.9273 12.5015 16.2491V17.7951C12.5015 18.1169 12.7647 18.3801 13.0865 18.3801C13.3143 18.3801 13.5123 18.2478 13.6088 18.0564C13.7053 18.2478 13.9033 18.3801 14.1311 18.3801C14.359 18.3801 14.557 18.2478 14.6534 18.0564C14.7499 18.2478 14.9479 18.3801 15.1758 18.3801C15.4975 18.3801 15.7608 18.1169 15.7608 17.7951V16.2491C15.7608 15.9273 15.4975 15.6641 15.1758 15.6641Z"
                          fill="#FED402"
                        />
                        <path
                          d="M13.4752 17.7951V16.2491C13.4752 16.1301 13.5113 16.0192 13.5729 15.9266C13.4679 15.7689 13.2891 15.6641 13.0865 15.6641C12.7647 15.6641 12.5015 15.9273 12.5015 16.2491V17.7951C12.5015 18.1169 12.7647 18.3801 13.0865 18.3801C13.2891 18.3801 13.4679 18.2753 13.5729 18.1176C13.5113 18.025 13.4752 17.9141 13.4752 17.7951Z"
                          fill="#FAC600"
                        />
                        <path
                          d="M2.51068 11.3816C3.05982 11.3816 3.55458 11.5487 3.90333 11.816C3.93596 11.841 3.98189 11.841 4.01452 11.816C4.36327 11.5487 4.85799 11.3816 5.40718 11.3816C5.61352 11.3816 5.81218 11.4052 5.99832 11.4488C6.08447 11.469 6.14588 11.3679 6.0915 11.2981C5.83922 10.9741 5.44571 10.7656 5.0033 10.7656C4.62085 10.7656 4.275 10.9215 4.02525 11.173C3.98855 11.21 3.92935 11.21 3.89269 11.173C3.64294 10.9215 3.2971 10.7656 2.91464 10.7656C2.47224 10.7656 2.07872 10.9741 1.82644 11.2981C1.77207 11.3679 1.83347 11.4689 1.91963 11.4488C2.10572 11.4051 2.30438 11.3816 2.51068 11.3816Z"
                          fill="#B342BC"
                        />
                        <path
                          d="M19.5263 8.25652C20.0755 8.25652 20.5703 8.42367 20.919 8.691C20.9516 8.71598 20.9976 8.71598 21.0302 8.691C21.3789 8.42367 21.8737 8.25652 22.4228 8.25652C22.6292 8.25652 22.8278 8.28014 23.014 8.32373C23.1001 8.34394 23.1616 8.24288 23.1072 8.17303C22.8549 7.84908 22.4613 7.64062 22.0189 7.64062C21.6365 7.64062 21.2906 7.79653 21.0409 8.04802C21.0042 8.08495 20.945 8.08495 20.9083 8.04802C20.6586 7.79653 20.3127 7.64062 19.9303 7.64062C19.4879 7.64062 19.0943 7.84908 18.8421 8.17308C18.7877 8.24287 18.8491 8.34394 18.9353 8.32378C19.1214 8.28014 19.3201 8.25652 19.5263 8.25652Z"
                          fill="#B342BC"
                        />
                        <path
                          d="M1.57318 3.08469C2.12232 3.08469 2.61708 3.25184 2.96583 3.51917C2.99846 3.54416 3.04439 3.54416 3.07702 3.51917C3.42577 3.25184 3.92049 3.08469 4.46968 3.08469C4.67602 3.08469 4.87468 3.10831 5.06082 3.15191C5.14697 3.17211 5.20838 3.07105 5.154 3.0012C4.90172 2.67725 4.50821 2.46875 4.0658 2.46875C3.68335 2.46875 3.3375 2.62466 3.08775 2.87614C3.05105 2.91308 2.99185 2.91308 2.95519 2.87614C2.70544 2.62466 2.3596 2.46875 1.97714 2.46875C1.53474 2.46875 1.14122 2.6772 0.888941 3.0012C0.834566 3.071 0.895972 3.17206 0.982129 3.15191C1.16822 3.10827 1.36688 3.08469 1.57318 3.08469Z"
                          fill="#B342BC"
                        />
                        <path
                          d="M16.3593 3.37869V2.26588C16.3593 2.1486 16.2242 2.08288 16.1319 2.15521L14.7124 3.26802C14.6072 3.35047 14.6655 3.51932 14.7991 3.51932H16.2186C16.2963 3.51932 16.3593 3.45636 16.3593 3.37869Z"
                          fill="#7E5FC9"
                        />
                        <path
                          d="M9.28758 3.26801L7.86802 2.1552C7.77572 2.08287 7.64062 2.14864 7.64062 2.26587V3.37868C7.64062 3.45635 7.70358 3.51931 7.78125 3.51931H9.20081C9.33445 3.51931 9.39272 3.35046 9.28758 3.26801Z"
                          fill="#7E5FC9"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3432_69487">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}{" "}
                  <p>
                    {moment().format("HH") >= 4 && moment().format("HH") < 12
                      ? "Good Morning"
                      : moment().format("HH") >= 12 &&
                        moment().format("HH") < 16
                      ? "Good Afternoon"
                      : "Good Evening"}{" "}
                    {user?.name}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3 w-full">
              {option == "phone" ? (
                <>
                  <div className="flex flex-col space-y-3">
                    <label
                      htmlFor="phone"
                      className="sr-only text-white text-sm lg:text-base font-semibold font-satoshi"
                    >
                      Mobile
                    </label>
                    <div className="flex flex-row items-stretch bg-white rounded-xl border border-[#E7E6E5] gap-2">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="eg. 1212121222"
                        value={phoneNo}
                        onChange={(e) => {
                          e.target.value = e.target.value
                            .trimStart()
                            .replace(/[^0-9]/gi, "");

                          _handleTextFieldChange(e);
                        }}
                        className="w-full  px-6 py-3.5 text-base leading-[24px] border-0 focus:outline-none focus:ring-0 bg-transparent"
                      />
                    </div>{" "}
                    <p className="text-2xs lg:text-xs text-center font-lato text-primary-neutral-800">
                      A verification code will be sent to your mobile number{" "}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="otp"
                      className="sr-only text-white text-sm lg:text-base font-semibold font-satoshi"
                    >
                      Enter OTP
                    </label>
                    <form className="inputField mt-2 py-3 flex items-center justify-between text-primary-blue-dark font-normal">
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp1"
                        type="number"
                        autoComplete="off"
                        value={code.otp1}
                        onChange={(e) => handleChange("otp1", e)}
                        tabIndex="1"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp2"
                        type="number"
                        autoComplete="off"
                        value={code.otp2}
                        onChange={(e) => handleChange("otp2", e)}
                        tabIndex="2"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp3"
                        type="number"
                        autoComplete="off"
                        value={code.otp3}
                        onChange={(e) => handleChange("otp3", e)}
                        tabIndex="3"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp4"
                        type="number"
                        autoComplete="off"
                        value={code.otp4}
                        onChange={(e) => handleChange("otp4", e)}
                        tabIndex="4"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp5"
                        type="number"
                        autoComplete="off"
                        value={code.otp5}
                        onChange={(e) => handleChange("otp5", e)}
                        tabIndex="5"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />{" "}
                      <input
                        className="border text-xl sm:text-5xl text-center mr-2 w-10 h-[50px] sm:mr-5 sm:w-14 sm:h-20 focus:ring-0 focus:border-black rounded-xl border-[#E7E6E5]"
                        name="otp6"
                        type="number"
                        autoComplete="off"
                        value={code.otp6}
                        onChange={(e) => handleChange("otp6", e)}
                        tabIndex="6"
                        maxLength={1}
                        onKeyUp={(e) => inputfocus(e)}
                      />
                    </form>
                    <p className="text-2xs lg:text-xs text-center font-lato text-primary-neutral-800">
                      OTP Sent to mobile no. {phoneNo}
                    </p>
                  </div>{" "}
                </>
              )}
            </div>
          </div>
          <div
            className="flex flex-col max-w-[360px] submit-wrapper transition-all duration-300 mx-auto items-center justify-end w-full"
            style={{
              flex: "1 0 0",
            }}
          >
            <input
              type="submit"
              id="send-otp-button"
              value={buttonState?.text}
              className={`bg-black w-full rounded-full px-8 py-4 cursor-pointer text-primary-gray-100 font-lato text-base font-bold leading-6`}
              onClick={() => {
                if (option == "phone") {
                  handleClick();
                } else {
                  otpSubmit();
                }
              }}
              disabled={buttonState.disabled}
            />
          </div>
        </div>

        <div className="fixed z-40 bottom-0 right-0 left-0 p-6 flex flex-row items-center justify-center space-x-2">
          <p className="text-black font-lato text-2xs font-normal leading-4 tracking-[0.25px]">
            a product by{" "}
          </p>
          <img src="/assets/caaryaLogos/caarya.svg" alt="" className="h-8" />
        </div>
      </div>
    </section>
  );
}

export default LoginCard;
