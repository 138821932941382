import DropdownInput from "../inputs/DropdownInput";
import TextInput from "../inputs/TextInput";
import { titleProps } from "./tags";

const CoverTab = ({ input, setInput, type }) => {
  return (
    <div className="flex flex-col gap-10  w-full h-full overflow-y-scroll scrollbar-hidden">
      <h1 className="text-primary-blue-500 text-lg md:text-xl font-manrope ">
        Cover
      </h1>
      <div className="flex flex-col gap-8 h-[55vh] overflow-y-scroll w-full max-w-3xl scrollbar-hidden">
        <TextInput
          input={input}
          setInput={setInput}
          type={type}
          {...titleProps}
        />
        <DropdownInput
          input={input}
          setInput={setInput}
          type={type}
          field={"field"}
          title={"Field"}
          iconType="sparkle"
        />
      </div>
    </div>
  );
};

export default CoverTab;
