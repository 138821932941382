import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SprintChart from "components/Sprint/SprintChart";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { useState } from "react";
function CareerInsights() {
  const history = useHistory();

  return (
    <>
      <div
        className={`bg-[#fafafa] pt-[52.5px] lg:pt-0 h-[95vh] overflow-y-auto`}
      >
        <div className="flex px-3 bg-white space-x-4 py-5 flex-row items-center w-full">
          <svg
            onClick={() => {
              history.goBack();
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M16.6666 9.16927H6.52492L11.1833 4.51094L9.99992 3.33594L3.33325 10.0026L9.99992 16.6693L11.1749 15.4943L6.52492 10.8359H16.6666V9.16927Z"
              fill="#363430"
            />
          </svg>
          <p className="text-primary-neutral-800 font-lato text-base font-bold leading-6 tracking-[0.64px]">
            Career Insights
          </p>
        </div>

        <div className="bg-[#fafafa] pb-5 space-y-10 py-4 lg:pb-0">
          <div className="flex flex-col items-center space-y-2 text-center">
            <h1 className="text-black font-lato text-xl font-bold leading-[30px] tracking-[0.4px] capitalize">
              Weekly Sprint Report
            </h1>
            <p className="text-secondary-indigo-500 font-satoshi text-base font-bold leading-6 tracking-[0.64px]">
              Sprint 4
            </p>
          </div>
          <Sprints />
          <Stats />
          <SkillMaps />
          <Services />
          <FocusZoneStat />
        </div>
      </div>
    </>
  );
}

export default CareerInsights;

function Sprints() {
  return (
    <div className="px-4">
      <div className="p-4 space-y-6 rounded-2xl border border-primary-neutral-100 bg-white">
        <p className="text-primary-neutral-800 font-satoshi text-sm leading-5 tracking-[0.7px]">
          You gained <b>20%</b> more experience this sprint
        </p>
        <div className="px-5">
          <SprintChart />
        </div>
      </div>
    </div>
  );
}

function Stats() {
  return (
    <div className="px-4">
      <div className="p-4 space-y-6 rounded-2xl border border-primary-neutral-100 bg-white">
        <p className="text-primary-neutral-800 font-satoshi text-sm leading-5 tracking-[0.7px]">
          You spent <b>1.5</b> more hours building your career this sprint
        </p>

        <SprintChart />
        <div className="grid grid-cols-2 gap-4">
          <div className="rounded-2xl border border-primary-neutral-100 p-4 space-y-3 flex flex-col items-center">
            <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-4 tracking-[0.48px] text-center">
              Goals Completed
            </p>
            <div className="flex flex-col items-center">
              <p className="text-primary-neutral-800 font-lato text-xl font-bold leading-9 tracking-[1px]">
                13/14
              </p>
              <p className="flex flex-row items-center space-x-1">
                <CircularProgressbar
                  value={96 || 0}
                  text={``}
                  circleRatio={0.5}
                  styles={buildStyles({
                    rotation: 1 / 2 + 1 / 4,
                    strokeLinecap: "butt",
                    pathColor: "#816FE9",
                    trailColor: "#E7E6E5",
                  })}
                />
                <p className="text-primary-neutral-500 font-satoshi text-2xs leading-4 tracking-[0.4px]">
                  96%
                </p>
              </p>
            </div>
          </div>
          <div className="rounded-2xl border border-primary-neutral-100 p-4 space-y-3 flex flex-col items-center">
            <p className="text-primary-neutral-500 font-satoshi text-xs font-bold leading-4 tracking-[0.48px] text-center">
              Sessions Tracked
            </p>
            <div className="flex flex-col items-center">
              <p className="text-primary-neutral-800 font-lato text-xl font-bold leading-9 tracking-[1px]">
                12
              </p>
              <p className="text-primary-neutral-500 font-satoshi text-2xs leading-4 tracking-[0.4px]">
                1h4m Avg, session
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SkillMaps() {
  return (
    <div className="px-4">
      <div className="p-4 space-y-6 rounded-2xl border border-primary-neutral-100 bg-white">
        <p className="text-primary-neutral-800 font-satoshi text-sm leading-5 tracking-[0.7px]">
          You learned <b>3</b> New skills and improved on <b>5</b> skills this
          sprint
        </p>

        <div className="flex flex-row items-center justify-center flex-wrap gap-2">
          {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => {
            return (
              <div className="rounded-full text-primary-neutral-500 font-satoshi text-xs font-medium border border-primary-neutral-100 px-3 py-1.5">
                Skill Name
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Services() {
  const [labels, setLabels] = useState([
    "Sprint 1",
    "Sprint 2",
    "Sprint 3",
    "Sprint 4",
  ]);

  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Session",
        data: [2, 4, 3, 6],
        backgroundColor: "#A193F2",
      },
    ],
  });
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: "Sprint Chart",
      },
    },
  };
  return (
    <div className="px-4">
      <div className="p-4 space-y-6 rounded-2xl border border-primary-neutral-100 bg-white">
        <p className="text-primary-neutral-800 font-satoshi text-sm leading-5 tracking-[0.7px]">
          You worked on <b>2</b> Services this sprint{" "}
        </p>

        <div className="flex flex-col space-y-4">
          {[1, 2]?.map((item) => {
            return (
              <div className="rounded-2xl border border-primary-neutral-100 p-3 flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-primary-neutral-100" />
                <div className="">
                  <p className="text-black font-satoshi text-sm font-bold leading-5 tracking-[0.56px] capitalize">
                    User Research Studies
                  </p>
                  <p className="text-primary-neutral-500 font-satoshi text-2xs uppercase">
                    UX RESEARCHER
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="p-2 flex flex-col space-y-4">
          <p className="text-primary-neutral-500 font-satoshi text-2xs font-bold tracking-[0.4px]">
            Total Experience Hours
          </p>
          {/* <div className="">
            <Pie options={options} data={data} />
          </div> */}
        </div>
      </div>
    </div>
  );
}

function FocusZoneStat() {
  return (
    <div className="px-4">
      <div className="p-4 space-y-6 rounded-2xl border border-primary-neutral-100 bg-white">
        <p className="text-primary-neutral-800 font-satoshi text-sm leading-5 tracking-[0.7px]">
          You seem to have been a night owl this sprint!
        </p>

        <div className="flex flex-col space-y-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <g clip-path="url(#clip0_3964_41213)">
              <path
                d="M32 2.75C15.0863 2.75 1.375 16.4613 1.375 33.375C1.375 50.2887 15.0863 64 32 64C44.1344 64 54.6199 56.9424 59.5771 46.7084L60.968 43.3334C62.0411 40.2111 62.625 36.8614 62.625 33.375C62.625 16.4613 48.9137 2.75 32 2.75Z"
                fill="#C35AC7"
              />
              <path
                d="M18.625 2.69613C17.053 1.03675 14.8304 0 12.3643 0C7.60076 0 3.73926 3.8615 3.73926 8.625C3.73926 13.3885 7.60076 17.25 12.3643 17.25C14.8304 17.25 17.053 16.2132 18.625 14.5539V2.69613Z"
                fill="#FED402"
              />
              <path
                d="M8.75001 8.625C8.75001 4.73312 11.3283 1.4445 14.8696 0.370875C14.0766 0.1305 13.2358 0 12.3643 0C7.60076 0 3.73926 3.8615 3.73926 8.625C3.73926 13.3885 7.60076 17.25 12.3643 17.25C13.2358 17.25 14.0766 17.1195 14.8696 16.8791C11.3283 15.8055 8.75001 12.5169 8.75001 8.625Z"
                fill="#FAC600"
              />
              <path
                d="M37.7255 49.6947L37.6405 49.5078L37.5134 49.6692C36.1666 51.3804 34.157 52.3619 31.9998 52.3619C29.8831 52.3619 27.8973 51.4108 26.5515 49.7523L26.4215 49.5921L26.3396 49.7814C25.9855 50.6011 25.5123 51.3438 24.947 51.9953V53.7154C24.947 55.5413 25.9656 57.1402 27.4623 57.9737C28.3355 57.4873 29.044 56.7391 29.4844 55.8384V58.4104C29.4844 60.2363 30.503 61.8352 31.9996 62.6688C33.4963 61.8352 34.5149 60.2363 34.5149 58.4104V55.8384C34.9553 56.7391 35.6638 57.4872 36.537 57.9737C38.0336 57.1401 39.0523 55.5412 39.0523 53.7154V51.7577C38.5206 51.1462 38.0709 50.4541 37.7255 49.6947Z"
                fill="#704FB9"
              />
              <path
                d="M27.5195 53.7171V50.7333C27.1705 50.4414 26.845 50.1156 26.5515 49.754L26.4215 49.5938L26.3396 49.7831C25.9855 50.6028 25.5123 51.3455 24.947 51.997V53.7171C24.947 55.543 25.9656 57.1419 27.4623 57.9754C27.948 57.7048 28.3803 57.3509 28.7463 56.9371C27.9848 56.0773 27.5195 54.9494 27.5195 53.7171Z"
                fill="#5D3F9A"
              />
              <path
                d="M45.4999 43.4557V2.61C45.4999 2.12175 44.9375 1.84813 44.5533 2.14925L36.9715 8.09263C36.5593 8.41575 36.0506 8.59138 35.5268 8.59138H28.4729C27.9491 8.59138 27.4404 8.41575 27.0281 8.09263L19.4464 2.14925C19.0621 1.848 18.4998 2.12175 18.4998 2.61V43.4557H45.4999Z"
                fill="#704FB9"
              />
              <path
                d="M22.2498 27.5555V4.34688L19.4464 2.14925C19.0621 1.848 18.4998 2.12175 18.4998 2.61V34.6904C18.4998 31.7304 19.985 29.1185 22.2498 27.5555Z"
                fill="#5D3F9A"
              />
              <path
                d="M25.736 11.9688C23.3111 11.9688 21.3452 13.9345 21.3452 16.3595C21.3452 16.7431 21.3947 17.1151 21.4871 17.4699C21.6745 15.2896 23.5078 13.5723 25.7358 13.5723C27.9638 13.5723 29.7972 15.2895 29.9847 17.4698C30.0771 17.1151 30.1266 16.7431 30.1266 16.3595C30.1267 13.9345 28.161 11.9688 25.736 11.9688Z"
                fill="#4B3087"
              />
              <path
                d="M38.2639 13.5723C40.4919 13.5723 42.3253 15.2896 42.5127 17.4699C42.6052 17.1151 42.6545 16.7431 42.6545 16.3595C42.6545 13.9346 40.6888 11.9688 38.2638 11.9688C35.8389 11.9688 33.873 13.9345 33.873 16.3595C33.873 16.7431 33.9225 17.1153 34.015 17.47C34.2027 15.2896 36.036 13.5723 38.2639 13.5723Z"
                fill="#4B3087"
              />
              <path
                d="M24.9828 43.4591V30.0103C24.9828 28.5577 25.4265 27.2068 26.1854 26.0859C21.8614 26.5723 18.5 30.2396 18.5 34.6937V43.4591H24.9828Z"
                fill="#4B3087"
              />
              <path
                d="M20.7804 46.5859C20.6469 46.5859 20.5213 46.6379 20.4268 46.7324L18.5 48.6592V55.0684C22.1135 55.0684 25.209 52.8558 26.509 49.7119C25.7991 48.8201 25.3011 47.7526 25.0925 46.5859H20.7804Z"
                fill="#4B3087"
              />
              <path
                d="M39.017 43.454H45.4998V34.4544C45.4998 29.9388 42.0449 26.2317 37.6345 25.8281C38.5025 26.996 39.0169 28.4415 39.0169 30.005V43.454H39.017Z"
                fill="#5D3F9A"
              />
              <path
                d="M38.9069 46.5781C38.7047 47.7089 38.2312 48.7468 37.5557 49.6216C38.8914 52.6845 41.9448 54.8265 45.4998 54.8265V46.5781H38.9069Z"
                fill="#5D3F9A"
              />
              <path
                d="M24.9678 46.5859C25.5576 49.9303 28.4931 52.4926 32 52.4926C35.5069 52.4926 38.4424 49.9303 39.0323 46.5859H24.9678Z"
                fill="#7E5FC9"
              />
              <path
                d="M39.1422 43.4582V30.0094C39.1422 26.0812 35.9282 22.8672 31.9999 22.8672C28.0717 22.8672 24.8577 26.0812 24.8577 30.0094V43.4582H39.1422Z"
                fill="#7E5FC9"
              />
              <path
                d="M28.0481 31.8284L28.9134 30.8342C29.1306 30.5846 28.9534 30.1953 28.6224 30.1953H26.8919C26.561 30.1953 26.3836 30.5847 26.6009 30.8342L27.4661 31.8284C27.6199 32.0052 27.8944 32.0052 28.0481 31.8284Z"
                fill="#D3C4F7"
              />
              <path
                d="M32.291 31.8284L33.1563 30.8342C33.3735 30.5846 33.1963 30.1953 32.8653 30.1953H31.1348C30.8039 30.1953 30.6265 30.5847 30.8438 30.8342L31.709 31.8284C31.8628 32.0052 32.1373 32.0052 32.291 31.8284Z"
                fill="#D3C4F7"
              />
              <path
                d="M36.534 31.8284L37.3992 30.8342C37.6165 30.5846 37.4392 30.1953 37.1082 30.1953H35.3777C35.0468 30.1953 34.8695 30.5847 35.0867 30.8342L35.952 31.8284C36.1057 32.0052 36.3802 32.0052 36.534 31.8284Z"
                fill="#D3C4F7"
              />
              <path
                d="M30.1695 35.9066L31.0347 34.9123C31.252 34.6627 31.0747 34.2734 30.7437 34.2734H29.0132C28.6823 34.2734 28.505 34.6628 28.7222 34.9123L29.5875 35.9066C29.7413 36.0833 30.0157 36.0833 30.1695 35.9066Z"
                fill="#D3C4F7"
              />
              <path
                d="M34.4124 35.9066L35.2776 34.9123C35.4949 34.6627 35.3176 34.2734 34.9866 34.2734H33.2561C32.9253 34.2734 32.7479 34.6628 32.9651 34.9123L33.8304 35.9066C33.9843 36.0833 34.2586 36.0833 34.4124 35.9066Z"
                fill="#D3C4F7"
              />
              <path
                d="M32.2735 39.6331L33.1387 38.6389C33.356 38.3892 33.1787 38 32.8477 38H31.1172C30.7863 38 30.609 38.3894 30.8262 38.6389L31.6915 39.6331C31.8453 39.8097 32.1197 39.8097 32.2735 39.6331Z"
                fill="#D3C4F7"
              />
              <path
                d="M25.7357 22.2268C28.1607 22.2268 30.1265 20.261 30.1265 17.8361C30.1265 15.4111 28.1607 13.4453 25.7357 13.4453C23.3108 13.4453 21.345 15.4111 21.345 17.8361C21.345 20.261 23.3108 22.2268 25.7357 22.2268Z"
                fill="white"
              />
              <path
                d="M23.9685 17.8359C23.9685 15.8679 25.2633 14.2024 27.0475 13.6447C26.6332 13.5152 26.1927 13.4453 25.7357 13.4453C23.3108 13.4453 21.345 15.4111 21.345 17.8361C21.345 20.2609 23.3107 22.2268 25.7357 22.2268C26.1926 22.2268 26.6332 22.1569 27.0475 22.0274C25.2632 21.4694 23.9685 19.8041 23.9685 17.8359Z"
                fill="#FBF0FF"
              />
              <path
                d="M25.7358 19.8871C26.8674 19.8871 27.7848 18.9697 27.7848 17.8381C27.7848 16.7064 26.8674 15.7891 25.7358 15.7891C24.6041 15.7891 23.6868 16.7064 23.6868 17.8381C23.6868 18.9697 24.6041 19.8871 25.7358 19.8871Z"
                fill="#5D3F9A"
              />
              <path
                d="M24.9998 17.8381C24.9998 16.9381 25.5839 16.1734 26.3923 15.8989C26.1859 15.8288 25.9655 15.7891 25.7358 15.7891C24.606 15.7891 23.6868 16.7083 23.6868 17.8381C23.6868 18.9678 24.606 19.8871 25.7358 19.8871C25.9656 19.8871 26.1859 19.8473 26.3923 19.7772C25.5839 19.5027 24.9998 18.7381 24.9998 17.8381Z"
                fill="#4B3087"
              />
              <path
                d="M38.264 22.2268C40.689 22.2268 42.6548 20.261 42.6548 17.8361C42.6548 15.4111 40.689 13.4453 38.264 13.4453C35.8391 13.4453 33.8733 15.4111 33.8733 17.8361C33.8733 20.261 35.8391 22.2268 38.264 22.2268Z"
                fill="white"
              />
              <path
                d="M36.4685 17.8361C36.4685 15.8629 37.7702 14.1939 39.5617 13.6404C39.1515 13.5137 38.7158 13.4453 38.264 13.4453C35.8392 13.4453 33.8733 15.4111 33.8733 17.8361C33.8733 20.2609 35.839 22.2268 38.264 22.2268C38.7158 22.2268 39.1515 22.1584 39.5617 22.0317C37.7702 21.4783 36.4685 19.8093 36.4685 17.8361Z"
                fill="#FBF0FF"
              />
              <path
                d="M38.2641 19.8871C39.3957 19.8871 40.3131 18.9697 40.3131 17.8381C40.3131 16.7064 39.3957 15.7891 38.2641 15.7891C37.1325 15.7891 36.2151 16.7064 36.2151 17.8381C36.2151 18.9697 37.1325 19.8871 38.2641 19.8871Z"
                fill="#5D3F9A"
              />
              <path
                d="M37.4998 17.8381C37.4998 16.9328 38.0906 16.1641 38.9063 15.8937C38.704 15.8267 38.4885 15.7891 38.2638 15.7891C37.134 15.7891 36.2148 16.7083 36.2148 17.8381C36.2148 18.9678 37.1341 19.8871 38.2638 19.8871C38.4883 19.8871 38.704 19.8494 38.9063 19.7824C38.0906 19.5121 37.4998 18.7434 37.4998 17.8381Z"
                fill="#4B3087"
              />
              <path
                d="M32.2951 24.4558L34.9841 20.2767C35.0839 20.1218 35.0469 19.9162 34.8995 19.8056L32.2105 17.789C32.0856 17.6953 31.9139 17.6953 31.789 17.789L29.1 19.8056C28.9526 19.9161 28.9156 20.1217 29.0154 20.2767L31.7044 24.4558C31.8426 24.6708 32.1569 24.6708 32.2951 24.4558Z"
                fill="#FED402"
              />
              <path
                d="M31.6001 20.2845C31.5005 20.1297 31.5374 19.924 31.6848 19.8134L33.2921 18.6079L32.2105 17.7968C32.0856 17.7032 31.9139 17.7032 31.789 17.7968L29.1 19.8134C28.9526 19.9239 28.9156 20.1295 29.0154 20.2845L31.7044 24.4637C31.8426 24.6785 32.1569 24.6785 32.2951 24.4637L33.2921 22.9143L31.6001 20.2845Z"
                fill="#FAC600"
              />
              <path
                d="M60.968 43.3324H55.0467C54.9472 43.3324 54.8518 43.2929 54.7816 43.2226L48.5675 37.0084C48.3698 36.8108 48.0333 36.8911 47.9461 37.1564L47.0272 39.9498C46.9829 40.0842 47.0182 40.2321 47.1183 40.3322L49.4783 42.6923C49.7146 42.9286 49.5472 43.3324 49.2132 43.3324H11.7446C11.6667 43.3324 11.5908 43.3567 11.5273 43.4017L7.83282 46.0267C7.53507 46.2383 7.6847 46.7074 8.05007 46.7074H14.9466C15.2807 46.7074 15.4479 47.1113 15.2117 47.3476L13.3368 49.2224C13.1903 49.3689 13.1903 49.6063 13.3368 49.7528L15.1929 51.6089C15.3394 51.7554 15.5768 51.7554 15.7233 51.6089L20.5149 46.8173C20.5853 46.7469 20.6807 46.7074 20.7801 46.7074H59.577C60.1053 45.6169 60.57 44.4902 60.968 43.3324Z"
                fill="#A44F3E"
              />
              <path
                d="M29.1029 41.7734C28.4953 41.7734 27.9673 42.1263 27.7101 42.6367C27.4528 42.1264 26.9248 41.7734 26.3172 41.7734C25.7096 41.7734 25.1816 42.1263 24.9243 42.6367C24.6671 42.1264 24.1391 41.7734 23.5314 41.7734C22.6734 41.7734 21.9714 42.4754 21.9714 43.3334V47.4563C21.9714 48.3143 22.6734 49.0163 23.5314 49.0163C24.1391 49.0163 24.6671 48.6634 24.9243 48.1531C25.1816 48.6633 25.7096 49.0163 26.3172 49.0163C26.9248 49.0163 27.4528 48.6634 27.7101 48.1531C27.9673 48.6633 28.4953 49.0163 29.1029 49.0163C29.9609 49.0163 30.6629 48.3143 30.6629 47.4563V43.3334C30.6629 42.4754 29.9609 41.7734 29.1029 41.7734Z"
                fill="#FED402"
              />
              <path
                d="M24.5586 47.4563V43.3334C24.5586 43.0131 24.6566 42.7146 24.8239 42.4662C24.5433 42.0496 24.0687 41.7734 23.5314 41.7734C22.6734 41.7734 21.9714 42.4754 21.9714 43.3334V47.4563C21.9714 48.3143 22.6734 49.0163 23.5314 49.0163C24.0687 49.0163 24.5433 48.7402 24.8239 48.3236C24.6566 48.0752 24.5586 47.7767 24.5586 47.4563Z"
                fill="#FAC600"
              />
              <path
                d="M40.4687 41.7734C39.861 41.7734 39.333 42.1263 39.0758 42.6367C38.8185 42.1264 38.2905 41.7734 37.6829 41.7734C37.0753 41.7734 36.5473 42.1263 36.29 42.6367C36.0328 42.1264 35.5048 41.7734 34.8972 41.7734C34.0392 41.7734 33.3372 42.4754 33.3372 43.3334V47.4563C33.3372 48.3143 34.0392 49.0163 34.8972 49.0163C35.5048 49.0163 36.0328 48.6634 36.29 48.1531C36.5473 48.6633 37.0753 49.0163 37.6829 49.0163C38.2905 49.0163 38.8185 48.6634 39.0758 48.1531C39.333 48.6633 39.861 49.0163 40.4687 49.0163C41.3267 49.0163 42.0287 48.3143 42.0287 47.4563V43.3334C42.0287 42.4754 41.3267 41.7734 40.4687 41.7734Z"
                fill="#FED402"
              />
              <path
                d="M35.9337 47.4563V43.3334C35.9337 43.0162 36.0302 42.7206 36.1944 42.4736C35.9144 42.0529 35.4375 41.7734 34.8972 41.7734C34.0392 41.7734 33.3372 42.4754 33.3372 43.3334V47.4563C33.3372 48.3143 34.0392 49.0163 34.8972 49.0163C35.4375 49.0163 35.9144 48.7368 36.1944 48.3162C36.03 48.0692 35.9337 47.7736 35.9337 47.4563Z"
                fill="#FAC600"
              />
              <path
                d="M6.69505 30.3456C8.15943 30.3456 9.4788 30.7914 10.4088 31.5042C10.4958 31.5709 10.6183 31.5709 10.7053 31.5042C11.6353 30.7914 12.9546 30.3456 14.4191 30.3456C14.9693 30.3456 15.4991 30.4086 15.9954 30.5249C16.2252 30.5788 16.3889 30.3093 16.2439 30.123C15.5712 29.2591 14.5218 28.7031 13.3421 28.7031C12.3222 28.7031 11.3999 29.1189 10.7339 29.7895C10.6361 29.888 10.4782 29.888 10.3804 29.7895C9.71443 29.1189 8.79218 28.7031 7.7723 28.7031C6.59255 28.7031 5.54318 29.259 4.87043 30.123C4.72543 30.3093 4.88918 30.5786 5.11893 30.5249C5.61518 30.4085 6.14493 30.3456 6.69505 30.3456Z"
                fill="#B342BC"
              />
              <path
                d="M52.0699 22.0174C53.5343 22.0174 54.8537 22.4631 55.7837 23.176C55.8707 23.2426 55.9932 23.2426 56.0802 23.176C57.0102 22.4631 58.3294 22.0174 59.7939 22.0174C60.3442 22.0174 60.8739 22.0804 61.3703 22.1966C61.6001 22.2505 61.7638 21.981 61.6188 21.7948C60.9461 20.9309 59.8966 20.375 58.7168 20.375C57.6969 20.375 56.7747 20.7907 56.1087 21.4614C56.0108 21.5599 55.8529 21.5599 55.7552 21.4614C55.0892 20.7907 54.1669 20.375 53.1471 20.375C51.9673 20.375 50.9179 20.9309 50.2452 21.7949C50.1002 21.981 50.2639 22.2505 50.4937 22.1968C50.9901 22.0804 51.5198 22.0174 52.0699 22.0174Z"
                fill="#B342BC"
              />
              <path
                d="M4.19505 8.22063C5.65943 8.22063 6.9788 8.66638 7.9088 9.37925C7.9958 9.44588 8.1183 9.44588 8.2053 9.37925C9.1353 8.66638 10.4546 8.22063 11.9191 8.22063C12.4693 8.22063 12.9991 8.28363 13.4954 8.39988C13.7252 8.45375 13.8889 8.18425 13.7439 7.998C13.0712 7.13413 12.0218 6.57812 10.8421 6.57812C9.82218 6.57812 8.89993 6.99388 8.23393 7.6645C8.13605 7.763 7.97818 7.763 7.88043 7.6645C7.21443 6.99388 6.29218 6.57812 5.2723 6.57812C4.09255 6.57812 3.04318 7.134 2.37043 7.998C2.22543 8.18413 2.38918 8.45363 2.61893 8.39988C3.11518 8.2835 3.64493 8.22063 4.19505 8.22063Z"
                fill="#B342BC"
              />
              <path
                d="M43.625 8.99943V6.03193C43.625 5.71918 43.2648 5.54393 43.0187 5.7368L39.2333 8.7043C38.9528 8.92418 39.1083 9.37443 39.4647 9.37443H43.25C43.4572 9.37443 43.625 9.20655 43.625 8.99943Z"
                fill="#7E5FC9"
              />
              <path
                d="M24.7669 8.70428L20.9814 5.73678C20.7352 5.5439 20.375 5.71928 20.375 6.0319V8.9994C20.375 9.20653 20.5429 9.3744 20.75 9.3744H24.5355C24.8919 9.3744 25.0472 8.92415 24.7669 8.70428Z"
                fill="#7E5FC9"
              />
            </g>
            <defs>
              <clipPath id="clip0_3964_41213">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <p className="text-primary-neutral-800 font-satoshi text-2xs">
            You Worked <b>9.5h</b> in Nightwatch
          </p>
        </div>
      </div>
    </div>
  );
}
