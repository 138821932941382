import { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { ReactComponent as WorkPreference } from "assets/svg/workPreference.svg";
import { BiTimeFive } from "react-icons/bi";
import { typeFormQuestions } from "helpers/constants/driveTypeForm";

export default function WorkPreferences({ page, setPage, setCompleted }) {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState({});
  const handleNext = () => {
    setQuestionNumber(questionNumber + 1);
    document.getElementById("question").scrollIntoView();
  };
  const { questions } = typeFormQuestions[3];
  return (
    <section>
      <div
        onClick={() => {
          if (page == 1) history.goBack();
          else setPage(page - 1);
        }}
        className="flex px-4 py-6 flex-row items-center justify-between space-x-2 text-sm font-satoshi"
      >
        <MdArrowBack size={24} />
        <div className="flex text-sm gap-2 pl-4 items-center text-primary-neutral-800">
          <WorkPreference className="h-5 w-5" />
          <span className="text-sm font-medium leading-[21px] tracking-[0.7px]">
            Work Preferences
          </span>
        </div>
        <p className="font-lato text-primary-neutral-400 font-normal">
          {questionNumber + 1}/{questions.length}
        </p>
      </div>
      <div className="h-[6px] relative bg-primary-neutral-200 w-[100vw]">
        <div
          style={{
            width: `${((questionNumber + 1) * 100) / questions.length}%`,
            background: "#2BB656",
          }}
          className="h-[6px] relative w-full bg-opacity-60"
        ></div>
      </div>

      <div className="py-10 px-6 flex flex-col items gap-8 h-[calc(100vh-165px)] overflow-y-auto">
        <div className="flex flex-col gap-2 text-[#2BB656]" id="question">
          <BiTimeFive size={20} />
          <p className="font-satoshi text-xs font-bold capitalize tracking-[1.2px] leading-5">
            {questions[questionNumber].subtitle}
          </p>
          <p className=" font-lato font-medium leading-[24px] tracking-[0.8px] text-primary-neutral-800">
            {questions[questionNumber].question}
          </p>
        </div>

        <div className="flex flex-col gap-6 items-start">
          {questions[questionNumber].options.map((opt, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  const updatedAnswers = answers ? { ...answers } : {};
                  if (!updatedAnswers[questionNumber])
                    updatedAnswers[questionNumber] = -1;

                  if (updatedAnswers[questionNumber] === i) {
                    updatedAnswers[questionNumber] = -1;
                  } else {
                    updatedAnswers[questionNumber] = i;
                  }

                  setAnswers(updatedAnswers);
                }}
                className={`cursor-pointer rounded-full border bg-white flex min-w-[200px] w-full py-[14px] px-5 flex-col justify-center items-center gap-2 ${
                  answers[questionNumber] === i
                    ? "border-[#2BB656] text-[#2BB656]"
                    : "text-primary-gray-800"
                }`}
              >
                <span className="font-satoshi text-sm font-normal text-center ">
                  {opt}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex p-4 justify-between items-center border-t border-primary-neutral-100 bg-white">
        <div
          onClick={() => {
            if (questionNumber > 0) {
              setQuestionNumber(questionNumber - 1);
              document.getElementById("question").scrollIntoView();
            }
          }}
          className={`bg-transparent  flex flex-row items-center justify-center space-x-2 rounded-full px-8 py-4 cursor-pointer text-primary-gray-500 font-lato text-base font-bold leading-6`}
        >
          <MdArrowBack className="h-5 w-5" />
          <p>Back</p>
        </div>
        <div
          onClick={() =>
            questionNumber < questions.length - 1
              ? handleNext()
              : setCompleted(true)
          }
          className={`bg-black  flex flex-row items-center justify-center space-x-2 rounded-full pr-8 pl-10 py-4 cursor-pointer text-white font-lato text-base font-bold leading-6`}
        >
          <p>{questionNumber === questions.length - 1 ? "Submit" : "Next"}</p>
          <MdArrowForward className="h-5 w-5" />
        </div>
      </div>
    </section>
  );
}
