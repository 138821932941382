import CleanEnergyIcon from "../assets/svgs/clean-energy 1.svg";
import CleanIcon from "../assets/svgs/clean-water 1.svg";
import ClimateIcon from "../assets/svgs/climate 1.svg";
import EducationIcon from "../assets/svgs/education 1.svg";
import EqualityIcon from "../assets/svgs/equal 1.svg";
import GenderIcon from "../assets/svgs/equality 1.svg";
import PovertyIcon from "../assets/svgs/family 1.svg";
import ForestIcon from "../assets/svgs/forest 1.svg";
import HealthIcon from "../assets/svgs/good-heart 1.svg";
import CityIcon from "../assets/svgs/green-city 1.svg";
import GrowthIcon from "../assets/svgs/growth (1) 1.svg";
import InnovationIcon from "../assets/svgs/innovation 1.svg";
import LawIcon from "../assets/svgs/law 1.svg";
import HungerIcon from "../assets/svgs/meal 1.svg";
import OceanIcon from "../assets/svgs/ocean 1.svg";
import ResponsibleIcon from "../assets/svgs/responsible-consumption 1.svg";

export const dreamCompanyOptions = [
  {
    title: "No Poverty",
    icon: PovertyIcon,
  },
  {
    title: "Zero Hunger",
    icon: HungerIcon,
  },
  {
    title: "Good Health and Well-Being",
    icon: HealthIcon,
  },
  {
    title: "Quality Education",
    icon: EducationIcon,
  },
  {
    title: "Gender Equality",
    icon: GenderIcon,
  },

  {
    title: "Clean Water and Sanitation",
    icon: CleanIcon,
  },
  {
    title: "Affordable and Clean Energy",
    icon: CleanEnergyIcon,
  },
  {
    title: "Decent Work and Economic Growth",
    icon: GrowthIcon,
  },
  {
    title: "Industry, Innovation and Infrastructure",
    icon: InnovationIcon,
  },
  {
    title: "Reduced Inequalities",
    icon: EqualityIcon,
  },

  {
    title: "Sustainable Cities and Communities",
    icon: CityIcon,
  },
  {
    title: "Responsible Consumption and Production",
    icon: ResponsibleIcon,
  },
  {
    title: "Climate Action",
    icon: ClimateIcon,
  },
  {
    title: "Life Below Water",
    icon: OceanIcon,
  },
  {
    title: "Life On Land",
    icon: ForestIcon,
  },
  {
    title: "Peace, Justice and Strong Institutions",
    icon: LawIcon,
  },
];
