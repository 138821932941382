import { HiOutlineAcademicCap } from "react-icons/hi";
import { MdClose, MdRadioButtonChecked } from "react-icons/md";
import { RiArrowDownFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import GrowthIcon from "../../../../MeProfilePage/assets/growth.svg";

const InterestDrawer = ({ close, field }) => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);

  return (
    <div>
      <div className="flex lg:hidden justify-end p-4 shadow text-xl">
        <MdClose className="cursor-pointer" onClick={close} />
      </div>

      <div className="flex flex-col gap-4 lg:border-dashed lg:border-2 lg:rounded-3xl  p-6 mx-auto  h-screen max-h-[400px] min-h-[200px] w-full max-w-4xl ">
        <div className="flex gap-4 items-center">
          <div
            className={` 
              bg-yellow-50 rounded-lg p-[6px] grid place-items-center `}
          >
            <img className="w-5 object-contain" src={GrowthIcon} alt="" />
          </div>

          <h2 className="text-md md:text-base font-manrope font-medium">
            Evolution of my Career Interests
          </h2>
        </div>
        <div className="h-full max-h-[70vh] lg:max-h-[350px] overflow-y-scroll scrollbar-hidden">
          {details[field].length === 0 ? (
            <div className="text-center p-10 grid place-items-center">
              <h4 className="text-primary-grey-300 text-md font-bold font-satoshi">
                Interests You Add Will Appear Here
              </h4>
            </div>
          ) : (
            <div className="flex w-full max-w-3xl mx-auto p-2  ">
              <div
                className={` relative border-l-2 p-5 border-primary-yellow-500 text-primary-yellow-500 `}
              >
                <div className="flex flex-col gap-[60px] h-full py-7 absolute left-0 right-0 inset-y-0 -translate-x-1/2 ">
                  {[...details[field]].reverse().map((_, idx) => (
                    <div
                      key={idx}
                      style={{ transform: `translateY(-${idx * 2}px)` }}
                      className="bg-white w-10 h-10 rounded-full grid place-items-center"
                    >
                      <HiOutlineAcademicCap className="text-xl" />
                    </div>
                  ))}
                </div>
                <RiArrowDownFill className="text-xl absolute top-full left-0  -translate-x-[13px] -translate-y-1 " />
              </div>
              <div className="flex flex-col gap-6 w-full">
                <div className="flex flex-col">
                  {[...details[field]].reverse().map((item, idx) => (
                    <div
                      key={idx}
                      style={{ transform: `translateY(-${idx * 2}px)` }}
                      className={` w-full relative flex ${
                        idx % 2 === 0 && "justify-end"
                      }`}
                    >
                      <div
                        className={` p-8 w-1/2   border-y-2 border-dashed ${
                          idx % 2 === 0
                            ? "border-r-2  rounded-r-full"
                            : "border-l-2  rounded-l-full"
                        } border-primary-yellow-500  `}
                      >
                        <MdRadioButtonChecked
                          className={`text-primary-yellow-500 absolute top-0 ${
                            idx % 2 === 0 ? "right-12" : "left-12"
                          } -translate-y-1/2`}
                        />
                        <h3
                          className={`font-satoshi text-sm font-bold truncate ${
                            idx % 2 === 0 && "text-right"
                          }`}
                        >
                          {item.interest}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterestDrawer;
