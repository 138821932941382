import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { nextStep } from "redux/typeform";

const activeSectionVariant = {
  active: {
    opacity: 1,
    // y: "0",
    transition: {
      duration: 0.3,
    },
  },
  inactive: {
    opacity: 0,
    // y: "-100%",
    transition: {
      duration: 0.3,
    },
  },
};
export default function Welcome() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [loadedLoader, setLoadedLoader] = useState(true);
  const [loadedHide, setLoadedHide] = useState(false);
  const [loadedWhite, setLoadedWhite] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (!loadedLoader) {
      setTimeout(() => {
        setLoaded(true);
      }, 500);
    }
  }, [loadedLoader]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoadedHide(true);
      }, 500);
    }
  }, [loaded]);

  return (
    <div className="min-h-screen relative">
      {!loadedHide && (
        <motion.div
          initial={{
            opacity: 1,
            // y: "0",
            transition: {
              duration: 0.3,
            },
          }}
          animate={
            !loaded
              ? {
                  opacity: 1,
                  // y: "0",
                  transition: {
                    duration: 0.5,
                  },
                }
              : {
                  opacity: 0,
                  // y: "0",
                  transition: {
                    duration: 0.5,
                  },
                }
          }
          className="h-screen fixed top-0 right-0 left-0 z-[1000] bottom-0 bg-white  w-full flex flex-col items-center justify-center -mt-6 px-4"
        >
          <motion.img
            initial={{
              opacity: 0.4,
              // y: "0",
              transition: {
                duration: 0.3,
              },
            }}
            animate={
              loadedLoader
                ? {
                    opacity: 0.8,
                    // y: "0",
                    transition: {
                      duration: 0.3,
                    },
                  }
                : {
                    opacity: 0.4,
                    // y: "0",
                    transition: {
                      duration: 0.3,
                    },
                  }
            }
            transition={{ duration: 0.3 }}
            src="/assets/logo.svg"
            className="w-40 scale-110 h-auto"
          />
        </motion.div>
      )}
      <motion.div
        initial={activeSectionVariant.inactive}
        animate={
          loaded ? activeSectionVariant.active : activeSectionVariant.inactive
        }
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="lg:h-[90vh] w-full flex flex-col items-center justify-center space-y-10 lg:space-y-12 py-6 lg:py-14 px-4"
      >
        <img
          onLoad={() => {
            setLoadedLoader(false);
          }}
          src="/assets/images/form/start.svg"
          alt=""
          className=""
        />
        <div className="flex flex-col items-center space-y-2">
          <h1 className="text-black font-satoshi text-[28px] text-center lg:text-[40px] lg:leading-[60px] lg:tracking-[12px] font-medium leading-[42px] tracking-[5.6px]">
            The <span className="text-caaryaRed">AI5</span> Assessment
          </h1>
          <p className="text-primary-neutral-800 text-center font-satoshi lg:text-base lg:leading-8 lg:tracking-[0.8px] text-sm font-extralight leading-7 tracking-[0.7px]">
            Aptitude for Industry 5.0
          </p>
        </div>
        <p className="text-base text-black font-lato capitalize font-light lg:text-lg lg:leading-8 lg:tracking-[1.44px] text-center tracking-[1.28px] leading-6">
          Test your aptitude to be a{" "}
          <span className="text-primary-peddle-500 font-normal">
            professional worker
          </span>{" "}
          in <span className="text-caaryaRed font-normal">industry 5.0</span>
        </p>
        <button
          onClick={() => {
            dispatch(nextStep());
          }}
          className="btn-red-gradient flex flex-row items-center justify-center space-x-3 rounded-full py-4 pl-8 pr-5 text-white font-lato text-base font-semibold leading-6 tracking-[0.8px]"
        >
          <p>Get Started</p>
          <div className="w-6 h-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill="white"
              />
            </svg>
          </div>
        </button>
      </motion.div>

      {/* <motion.div
        style={{
          background:
            "linear-gradient(180deg, rgba(231, 33, 19, 0.80) 0%, rgba(251, 111, 96, 0.80) 100%)",
        }}
        initial={activeBgVariant.inactive}
        animate={
          loadedWhite ? activeBgVariant.active : activeBgVariant.inactive
        }
        transition={{ duration: 1 }}
        className="h-[40vh] relative z-10"
      >
        <div
          // initial={{ opacity: 0, y: "-100%" }}
          // animate={{ opacity: 1, y: "0" }}
          // exit={{ opacity: 0, y: "-100%" }}
          // transition={{ duration: 0.3 }}
          className="absolute top-0 right-0 left-0"
        >
          <motion.img
            initial={activeSectionVariant.inactive}
            animate={
              loaded
                ? activeSectionVariant.active
                : activeSectionVariant.inactive
            }
            exit={{ opacity: 0, y: "-100%" }}
            transition={{ duration: 0.3 }}
            src="/assets/images/newForm/welcomeImage.png"
            alt=""
            className="object-cover w-full h-[40vh] z-0 opacity-10"
            onLoad={() => {
              setLoaded(true);
            }}
          />
        </div>
        <div className="lg:pt-[72px] h-[30vh] py-20 px-6 mx-auto w-full max-w-4xl flex flex-col items-center justify-center space-y-4">
          <h2 className="font-marope text-white text-center text-2xl lg:text-[40px] font-light">
            Hello There!
          </h2>
          <h1 className="font-manrope text-center font-medium text-2xl lg:text-[40px] text-white">
            Welcome to Caarya’s Kickstarter Form
          </h1>
        </div>
      </motion.div>
      <img
        src="/assets/images/newForm/bgWhite.png"
        alt=""
        className="object-cover lg:object-fill w-full absolute top-[30vh] left-0 right-0 h-[40vh] z-20 object-left"
        onLoad={() => {
          setLoadedWhite(true);
        }}
      />
      <div className="-mt-20 z-20 relative">
        <div className="py-24 flex flex-col items-center w-full space-y-4 px-6 max-w-2xl mx-auto">
          <div className=" flex flex-col items-center w-full space-y-10 lg:space-y-8 max-w-4xl mx-auto">
            <h1 className="leading-9 font-manrope text-center font-light text-xl lg:text-2xl text-primary-gray-800">
              Get started by working with various startups right as a{" "}
              <span className="font-medium text-primary-peddle-500">
                freshman
              </span>{" "}
              or a{" "}
              <span className="font-medium text-primary-peddle-500">
                sophomore.
              </span>
            </h1>
            <div className="font-manrope flex flex-row items-center space-x-4 lg:space-x-6 text-center text-sm lg:text-base text-primary-gray-500">
              <p className="font-medium">Exclusive For:</p>
              <div className="flex flex-row items-center space-x-2 font-light">
                <img
                  src="/assets/images/icons/student.svg"
                  alt=""
                  className="w-5 h-5"
                />
                <p>College students</p>
              </div>
            </div>
            <div
              onClick={() => {
                dispatch(nextStep());
              }}
              className="cursor-pointer flex flex-row items-center justify-center space-x-2 text-primary-red-500 border border-primary-red-500 rounded-full py-4 px-5"
            >
              <p className="text-sm font-manrope font-semibold">Get Started</p>
              <ArrowRight size={24} className="" />
            </div>
          </div>
        </div>{" "}
      </div> */}
    </div>
  );
}
