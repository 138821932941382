import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function LifeCards() {
  const history = useHistory();
  const [selected, setSelected] = useState("explore");
  return (
    <div className="py-8 space-y-10">
      <div className="px-4 overflow-x-auto flex flex-row space-x-3">
        {[
          { name: "Career Exploration", value: "explore" },
          { name: "Career Alignment", locked: true },
          { name: "Career Alignment", locked: true },
        ]?.map((item, index) => {
          return (
            <div
              onClick={() => {
                if (!item?.locked) setSelected(item?.value);
              }}
              className={`text-2xs min-w-max font-medium py-2 px-4 rounded-full border flex flex-row items-center space-x-2 font-satoshi ${
                selected == item?.value
                  ? "text-white border-primary-neutral-800 bg-primary-neutral-800"
                  : "text-primary-neutral-500 border-primary-neutral-200"
              }`}
            >
              {item?.locked && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99984 1.33594C6.16184 1.33594 4.6665 2.83127 4.6665 4.66927V6.0026H3.99984C3.2645 6.0026 2.6665 6.6006 2.6665 7.33594V13.3359C2.6665 14.0713 3.2645 14.6693 3.99984 14.6693H11.9998C12.7352 14.6693 13.3332 14.0713 13.3332 13.3359V7.33594C13.3332 6.6006 12.7352 6.0026 11.9998 6.0026H11.3332V4.66927C11.3332 2.83127 9.83784 1.33594 7.99984 1.33594ZM5.99984 4.66927C5.99984 3.5666 6.89717 2.66927 7.99984 2.66927C9.1025 2.66927 9.99984 3.5666 9.99984 4.66927V6.0026H5.99984V4.66927ZM12.0012 13.3359H8.6665V11.8173C9.06317 11.5859 9.33317 11.1606 9.33317 10.6693C9.33317 9.93394 8.73517 9.33594 7.99984 9.33594C7.2645 9.33594 6.6665 9.93394 6.6665 10.6693C6.6665 11.1599 6.9365 11.5859 7.33317 11.8173V13.3359H3.99984V7.33594H11.9998L12.0012 13.3359Z"
                    fill="#9C9A96"
                  />
                </svg>
              )}
              <p>{item?.name}</p>
            </div>
          );
        })}
      </div>
      <div className="px-4 flex flex-col space-y-8 pb-14">
        {[1, 2, 4]?.map((item) => {
          return (
            <div
              style={{
                background: `url('/assets/images/bgBrain.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className="rounded-2xl w-full h-[360px] flex flex-col justify-between"
            >
              <div className="p-4 flex flex-row items-center justify-between">
                <div className="py-2 px-4 rounded-full bg-black bg-opacity-50 text-white font-satoshi text-2xs font-bold leading-4 tracking-[0.4px] capitalize">
                  For a Sense of Purpose
                </div>
                <div className="p-2 flex flex-row items-center space-x-2 rounded-full bg-black bg-opacity-50 text-white font-satoshi text-2xs font-bold leading-4 tracking-[0.4px] capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                  >
                    <path
                      d="M14 7.5C14 7.5 11.375 2.6875 7 2.6875C2.625 2.6875 0 7.5 0 7.5C0 7.5 2.625 12.3125 7 12.3125C11.375 12.3125 14 7.5 14 7.5ZM1.02637 7.5C1.44945 6.85698 1.93606 6.2581 2.47887 5.71237C3.605 4.5845 5.145 3.5625 7 3.5625C8.855 3.5625 10.3941 4.5845 11.522 5.71237C12.0648 6.2581 12.5514 6.85698 12.9745 7.5C12.9237 7.57613 12.8678 7.66013 12.8039 7.752C12.5108 8.172 12.0776 8.732 11.522 9.28763C10.3941 10.4155 8.85413 11.4375 7 11.4375C5.145 11.4375 3.60588 10.4155 2.478 9.28763C1.93519 8.7419 1.44946 8.14301 1.02637 7.5Z"
                      fill="white"
                    />
                    <path
                      d="M7 5.3125C6.41984 5.3125 5.86344 5.54297 5.4532 5.9532C5.04297 6.36344 4.8125 6.91984 4.8125 7.5C4.8125 8.08016 5.04297 8.63656 5.4532 9.0468C5.86344 9.45703 6.41984 9.6875 7 9.6875C7.58016 9.6875 8.13656 9.45703 8.5468 9.0468C8.95703 8.63656 9.1875 8.08016 9.1875 7.5C9.1875 6.91984 8.95703 6.36344 8.5468 5.9532C8.13656 5.54297 7.58016 5.3125 7 5.3125ZM3.9375 7.5C3.9375 6.68777 4.26016 5.90882 4.83449 5.33449C5.40882 4.76016 6.18777 4.4375 7 4.4375C7.81223 4.4375 8.59118 4.76016 9.16551 5.33449C9.73984 5.90882 10.0625 6.68777 10.0625 7.5C10.0625 8.31223 9.73984 9.09118 9.16551 9.66551C8.59118 10.2398 7.81223 10.5625 7 10.5625C6.18777 10.5625 5.40882 10.2398 4.83449 9.66551C4.26016 9.09118 3.9375 8.31223 3.9375 7.5Z"
                      fill="white"
                    />
                  </svg>{" "}
                  <p>200+ Views</p>
                </div>
              </div>

              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 25%, #000 100%)",
                }}
                className="p-4 flex flex-col space-y-2"
              >
                <h1 className="text-white font-lato text-base font-bold leading-6 tracking-[0.64px] capitalize">
                  Identifying Personal Values
                </h1>
                <div className="flex flex-row items-center space-x-4">
                  <div className="flex flex-row items-center space-x-1 text-primary-neutral-100 font-satoshi font-bold text-2xs leading-4 tracking-[0.4px] capitalize">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M6.99984 1.66406C3.77984 1.66406 1.1665 4.2774 1.1665 7.4974C1.1665 10.7174 3.77984 13.3307 6.99984 13.3307C10.2198 13.3307 12.8332 10.7174 12.8332 7.4974C12.8332 4.2774 10.2198 1.66406 6.99984 1.66406ZM5.83317 10.1224V4.8724L9.33317 7.4974L5.83317 10.1224Z"
                        fill="#F3F2F2"
                      />
                    </svg>{" "}
                    <p className="mt-0.5">2</p>
                  </div>

                  <div className="flex flex-row items-center space-x-1 text-primary-neutral-100 font-satoshi font-bold text-2xs leading-4 tracking-[0.4px] capitalize">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M1.33317 6.4974H3.08317C3.39259 6.4974 3.68934 6.62031 3.90813 6.8391C4.12692 7.0579 4.24984 7.35464 4.24984 7.66406V10.5807C4.24984 10.8901 4.12692 11.1869 3.90813 11.4057C3.68934 11.6245 3.39259 11.7474 3.08317 11.7474H1.33317C1.02375 11.7474 0.727005 11.6245 0.508213 11.4057C0.28942 11.1869 0.166504 10.8901 0.166504 10.5807V6.4974C0.166504 3.27565 2.77809 0.664062 5.99984 0.664062C9.22159 0.664062 11.8332 3.27565 11.8332 6.4974V10.5807C11.8332 10.8901 11.7103 11.1869 11.4915 11.4057C11.2727 11.6245 10.9759 11.7474 10.6665 11.7474H8.9165C8.60708 11.7474 8.31034 11.6245 8.09155 11.4057C7.87275 11.1869 7.74984 10.8901 7.74984 10.5807V7.66406C7.74984 7.35464 7.87275 7.0579 8.09155 6.8391C8.31034 6.62031 8.60708 6.4974 8.9165 6.4974H10.6665C10.6665 5.25972 10.1748 4.07273 9.29967 3.19756C8.4245 2.32239 7.23751 1.83073 5.99984 1.83073C4.76216 1.83073 3.57518 2.32239 2.70001 3.19756C1.82484 4.07273 1.33317 5.25972 1.33317 6.4974Z"
                        fill="#F3F2F2"
                      />
                    </svg>
                    <p className="mt-0.5">2</p>
                  </div>

                  <div className="flex flex-row items-center space-x-1 text-primary-neutral-100 font-satoshi font-bold text-2xs leading-4 tracking-[0.4px] capitalize">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M10.0625 1.375H3.9375C3.47353 1.37551 3.0287 1.56004 2.70062 1.88812C2.37254 2.2162 2.18801 2.66103 2.1875 3.125V11.875C2.18801 12.339 2.37254 12.7838 2.70062 13.1119C3.0287 13.44 3.47353 13.6245 3.9375 13.625H10.0625C10.5265 13.6245 10.9713 13.44 11.2994 13.1119C11.6275 12.7838 11.812 12.339 11.8125 11.875V3.125C11.812 2.66103 11.6275 2.2162 11.2994 1.88812C10.9713 1.56004 10.5265 1.37551 10.0625 1.375ZM7 8.8125H4.8125C4.69647 8.8125 4.58519 8.76641 4.50314 8.68436C4.42109 8.60231 4.375 8.49103 4.375 8.375C4.375 8.25897 4.42109 8.14769 4.50314 8.06564C4.58519 7.98359 4.69647 7.9375 4.8125 7.9375H7C7.11603 7.9375 7.22731 7.98359 7.30936 8.06564C7.39141 8.14769 7.4375 8.25897 7.4375 8.375C7.4375 8.49103 7.39141 8.60231 7.30936 8.68436C7.22731 8.76641 7.11603 8.8125 7 8.8125ZM9.1875 6.625H4.8125C4.69647 6.625 4.58519 6.57891 4.50314 6.49686C4.42109 6.41481 4.375 6.30353 4.375 6.1875C4.375 6.07147 4.42109 5.96019 4.50314 5.87814C4.58519 5.79609 4.69647 5.75 4.8125 5.75H9.1875C9.30353 5.75 9.41481 5.79609 9.49686 5.87814C9.57891 5.96019 9.625 6.07147 9.625 6.1875C9.625 6.30353 9.57891 6.41481 9.49686 6.49686C9.41481 6.57891 9.30353 6.625 9.1875 6.625ZM9.1875 4.4375H4.8125C4.69647 4.4375 4.58519 4.39141 4.50314 4.30936C4.42109 4.22731 4.375 4.11603 4.375 4C4.375 3.88397 4.42109 3.77269 4.50314 3.69064C4.58519 3.60859 4.69647 3.5625 4.8125 3.5625H9.1875C9.30353 3.5625 9.41481 3.60859 9.49686 3.69064C9.57891 3.77269 9.625 3.88397 9.625 4C9.625 4.11603 9.57891 4.22731 9.49686 4.30936C9.41481 4.39141 9.30353 4.4375 9.1875 4.4375Z"
                        fill="#F3F2F2"
                      />
                    </svg>
                    <p className="mt-0.5">2</p>
                  </div>
                </div>
                <div className="flex flex-row items-center space-x-2">
                  <div className="py-1 px-2 rounded bg-white text-primary-neutral-800 font-satoshi text-2xs font-bold leading-4 tracking-[0.4px] capitalize">
                    Self Discovery
                  </div>
                  <div className="py-1 px-2 rounded bg-white text-primary-neutral-800 font-satoshi text-2xs font-bold leading-4 tracking-[0.4px] capitalize">
                    Career Vision
                  </div>
                  <div className="py-1 px-2 rounded bg-white text-primary-neutral-800 font-satoshi text-2xs font-bold leading-4 tracking-[0.4px] capitalize">
                    Personal Vision
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LifeCards;
