import { FaGraduationCap } from "react-icons/fa";
import { useEffect, useState } from "react";
import { findAllProjectsForAchievementCards } from "config/APIs/achievementCards";
import { useHistory } from "react-router-dom";
import { useToaster } from "rsuite";
import Toast from "../Common/Toast";
import { IoIosAddCircle, IoMdAdd } from "react-icons/io";
import { CgMoreVerticalAlt } from "react-icons/cg";
import { BsDot, BsFillBuildingsFill } from "react-icons/bs";
import { getBoosterCount, getImage } from "helpers/utils";

const getAssets = ({ name, details }) => {
  if (details?.category === "Work") {
    return {
      title: <span className="text-black">{name}</span>,
      icon: (
        <BsFillBuildingsFill size={18} className="fill-secondary-indigo-500" />
      ),
      image: getImage(details?.category),
      category: (
        <span className="font-bold text-secondary-indigo-500">Work</span>
      ),
      info: details?.companyName,
    };
  } else if (details?.category === "Academic") {
    return {
      title: <span className="text-black">{name}</span>,
      icon: (
        <FaGraduationCap size={18} className="fill-primary-yellow-medium" />
      ),
      image: getImage(details?.category),
      category: (
        <span className="font-bold text-primary-yellow-medium">Academic</span>
      ),
      info: details?.courseName,
    };
  } else {
    return {
      title: <span className="text-black">{name}</span>,
      icon: (
        <BsFillBuildingsFill size={18} className="fill-primary-accent-500" />
      ),
      image: getImage(details?.category),
      category: (
        <span className="font-bold text-primary-accent-500">Personal</span>
      ),
      info: null,
    };
  }
};

export default function ProjectList() {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const toaster = useToaster();

  const getProjects = async () => {
    try {
      const {
        data: { data: response },
      } = await findAllProjectsForAchievementCards();
      setProjects(response);
    } catch (error) {
      console.log(error);
      toaster.push(<Toast />, {
        placement: "topEnd",
      });
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="px-4 py-6 pb-24 bg-primary-neutral-50">
      {projects.length > 0 ? (
        <>
          <div className="flex justify-start items-center">
            <button
              onClick={() => history.push("/achievements/add")}
              className="flex gap-2 py-[14px] pr-6 pl-4 items-center border bg-transparent border-primary-accent-700 text-primary-accent-700 rounded-xl shadow-lg max-sm:text-sm font-lato font-bold disabled:opacity-40"
            >
              <IoMdAdd className="text-lg md:text-2xl" /> New Project
            </button>
          </div>

          <div className="flex items-center md:items-start gap-8 flex-wrap mt-8">
            {projects.map((p, i) => (
              <ProjectCard
                key={i}
                {...p}
                index={i}
                onClick={() => history.push(`/achievements/projects/${p._id}`)}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mx-auto text-center">
            <h1 className="text-primary-accent-700 font-medium text-3xl font-satoshi mb-4 tracking-[1.5px]">
              Hello Mr. Chronos!
            </h1>
            <p className="font-lato font-normal text-primary-neutral-500 tracking-[0.65px]">
              Present your work as beautiful Achievement Cards
            </p>
          </div>
          <div className="mt-32">
            <div
              className="mx-auto p-14 text-center max-w-[324px] cursor-pointer border rounded-2xl border-dashed border-[#CFCDC9]"
              onClick={() => history.push("/achievements/add")}
            >
              <IoIosAddCircle className="text-primary-accent-700 text-4xl mx-auto mb-4" />
              <p className="text-primary-gray-800 font-lato font-semibold">
                Add New Achievement Card
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const ProjectCard = ({
  name,
  details,
  onClick,
  achievementsCount = 0,
  uniqueRoles = 0,
}) => {
  const { title, image, icon, category, info } = getAssets({ name, details });
  return (
    <div
      onClick={onClick}
      className="max-sm:mx-auto relative p-4 flex flex-col justify-between items-start gap-4 w-[80%] sm:w-[212px] h-[155px] rounded-3xl bg-white shadow-md cursor-pointer"
    >
      <div className="flex justify-between w-full">
        <div className="flex flex-col items-start gap-2 flex-[1_0_0]">
          <p className="text-[16px] leading-6 tracking-[0.64px] font-lato font-bold w-full">
            {title}
          </p>
          <div className="flex items-center gap-1">
            <span className="font-satoshi text-primary-neutral-500 font-medium capitalize leading-4 tracking-[0.4px] text-2xs">
              {achievementsCount} Achievements
            </span>
            <BsDot className="h-1 w-1 rounded-full bg-primary-neutral-500" />
            <span className="font-satoshi text-primary-neutral-500 font-medium capitalize leading-4 tracking-[0.4px] text-2xs">
              {uniqueRoles} Roles
            </span>
            <BsDot className="h-1 w-1 rounded-full bg-primary-neutral-500" />
            <span className="font-satoshi text-primary-neutral-500 font-medium capitalize leading-4 tracking-[0.4px] text-2xs">
              {getBoosterCount(details?.boosters)} Insights
            </span>
          </div>
        </div>
        <div>
          <CgMoreVerticalAlt size={24} className="fill-primary-neutral-500" />
        </div>
      </div>
      {/* <div className="absolute bottom-4 left-0">
        <img src={image} alt={name} />
      </div> */}
      <div className="flex justify-between items-end self-stretch">
        <div className="flex items-center text-xs font-satoshi font-normal leading-5 capitalize gap-1 text-primary-neutral-500">
          <div className="flex items-center justify-center gap-2">
            {icon} {category}
          </div>
          {info && (
            <BsDot className="h-1 w-1 rounded-full bg-primary-neutral-500" />
          )}
          <span>{info}</span>
        </div>
        <img src={image} alt={name} className="h-14 w-14 object-fill" />
      </div>
    </div>
  );
};
