import React from "react";
import { useSelector } from "react-redux";
import GoalHubCard from "./GoalHubCard";
import { TASK_STATUS_COMPLETED } from "helpers/task";

function RecentCompletedGoals() {
  const list = useSelector((state) => state?.goals?.goals)?.filter(
    (a) =>
      a?.metrics?.find((m) => m?.name == "status")?.value ==
      TASK_STATUS_COMPLETED
  );
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-center justify-between">
        <p className="text-black font-lato text-sm font-bold leading-5 tracking-[0.7px]">
          Recently Completed Goals
        </p>
      </div>
      {list?.length > 0 ? (
        <div className="py-4 px-2 bg-primary-neutral-100 flex flex-col w-full space-y-6">
          {list?.map((item) => {
            return <GoalHubCard item={item} />;
          })}
        </div>
      ) : (
        <div className="rounded-[20px] bg-primary-neutral-100 p-8 space-y-4 flex flex-col items-center">
          <img
            src="/assets/images/file.png"
            alt=""
            className="w-20 h-20 mx-auto"
          />
          <p className="text-primary-neutral-800 font-satoshi text-center text-xs leading-4 tracking-[0.6px]">
            You have not completed any goals this sprint
          </p>
        </div>
      )}
    </div>
  );
}

export default RecentCompletedGoals;
