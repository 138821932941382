import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateGoal } from "config/APIs/task/goal";
import { fetchAllgoals } from "redux/goal";
import { goalFocusTime } from "helpers/constants/goals";
import { XMarkIcon } from "@heroicons/react/20/solid";
import moment from "moment";
const options = [
  {
    title: "Daylight",
  },
  {
    title: "Sundown",
  },
  {
    title: "Nightwatch",
  },
];
function PickUpLater({ isOpen, closeModal, goal, getCount }) {
  const dispatch = useDispatch();

  const [selectedFocus, setSelectedFocus] = useState();
  const [originalFocus, setOriginalFocus] = useState("Nightwatch");
  const [sortedOptions, setSortedOptions] = useState([...options]);

  useEffect(() => {
    setSelectedFocus(goal?.metrics?.find((a) => a?.name == "focusZone")?.value);
  }, [goal]);

  useEffect(() => {
    let time = moment().format("HH");

    if (time >= 0 && time <= 8) {
      setSortedOptions([
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
      ]);

      setOriginalFocus("Nightwatch");
    } else if (time > 8 && time <= 16) {
      setSortedOptions([
        options.find((option) => option.title === "Daylight"),
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
      ]);

      setOriginalFocus("Daylight");
    } else {
      setSortedOptions([
        options.find((option) => option.title === "Sundown"),
        options.find((option) => option.title === "Nightwatch"),
        options.find((option) => option.title === "Daylight"),
      ]);

      setOriginalFocus("Sundown");
    }
  }, []);

  const handleFocusGoalSelect = async (element) => {
    try {
      let m = goal?.metrics?.filter((a) => a?.name !== "focusZone");
      m.push({ name: "focusZone", value: element?.label });
      let body = { ...goal, metrics: m };
      await updateGoal(goal?._id, body);
      dispatch(fetchAllgoals());
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "32px 32px 0px 0px" : "0px",
          maxHeight: "100vh",
          height: window.innerWidth < 1024 ? "auto" : "100%",
          width: window.innerWidth < 1024 ? "100%" : "560px",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
      }}
      transitionDuration={250}
    >
      <button
        aria-label="Close"
        type="button"
        onClick={() => {
          closeModal();
        }}
        className="self-center mb-2 bg-primary-gray-1000 p-2 rounded-full"
      >
        <XMarkIcon className="h-5 w-5 text-white" />
      </button>
      <div className="relative flex flex-col items-stretch justify-end">
        <div
          style={{
            boxShadow: "-5px 0px 10px #E7E6E5",
          }}
          className="md:max-w-xl lg:h-screen px-6 pt-10 pb-10 space-y-5 rounded-t-2xl lg:rounded-t-none bg-white mx-auto w-full transform transition-all ease-in-out duration-150"
        >
          <div className="rounded-xl border border-[#E7E6E5] flex flex-row items-center justify-center space-x-2 px-4 py-3.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10.0004 0C15.5225 0 20 4.4775 20 10.0004C20 15.5225 15.5225 20 9.99958 20C4.4775 20 0 15.5225 0 9.99958C0 4.4775 4.4775 0 10.0004 0ZM10.0004 16.5217C10.8631 16.5314 11.7191 16.3698 12.5188 16.0464C13.3186 15.723 14.0463 15.2441 14.6597 14.6375C15.2731 14.0309 15.7601 13.3086 16.0924 12.5125C16.4248 11.7164 16.5959 10.8623 16.5958 9.99958C16.5957 9.13688 16.4245 8.28277 16.0921 7.4867C15.7597 6.69063 15.2726 5.96842 14.6591 5.3619C14.0456 4.75538 13.3179 4.2766 12.5181 3.95328C11.7182 3.62996 10.8622 3.46853 9.99958 3.47833C6.42775 3.5205 3.55442 6.428 3.55442 10C3.55442 13.572 6.42858 16.4795 10.0004 16.5217ZM10.0004 13.2006C9.5788 13.2027 9.16091 13.1215 8.77076 12.9617C8.38061 12.8018 8.0259 12.5665 7.72701 12.2691C7.42811 11.9717 7.19093 11.6182 7.02908 11.2289C6.86724 10.8396 6.78392 10.4221 6.78392 10.0005C6.78392 9.57883 6.86724 9.16136 7.02908 8.77204C7.19093 8.38271 7.42811 8.02921 7.72701 7.73183C8.0259 7.43446 8.38061 7.19908 8.77076 7.03922C9.16091 6.87937 9.5788 6.79818 10.0004 6.80033C11.7674 6.80083 13.1996 8.23342 13.1996 10.0004C13.1996 11.7674 11.7674 13.2001 10.0004 13.2006Z"
                fill="#ED4C41"
              />
            </svg>
            <p className="text-black truncate font-sm font-lato font-semibold tracking-[0.28px] leading-5">
              {goal?.title}
            </p>
          </div>
          <p className="font-bold text-lg text-center font-satoshi capitalize tracking-[0.72px] leading-7">
            Focusing In
          </p>
          <div className="flex flex-col w-full space-y-[28px]">
            {sortedOptions?.map((item) => {
              return (
                <div
                  onClick={() => {
                    setSelectedFocus(item?.title);
                    handleFocusGoalSelect(
                      goalFocusTime?.find((a) => a?.label == item?.title)
                    );
                  }}
                  className={`flex flex-row items-center justify-between `}
                >
                  <div className="flex flex-row items-center space-x-6">
                    <div
                      style={{
                        borderColor:
                          selectedFocus ==
                          goalFocusTime?.find((a) => a?.label == item?.title)
                            ?.label
                            ? goalFocusTime?.find(
                                (a) => a?.label == item?.title
                              )?.color
                            : "#F3F2F2",
                        backgroundColor:
                          selectedFocus ==
                          goalFocusTime?.find((a) => a?.label == item?.title)
                            ?.label
                            ? goalFocusTime?.find(
                                (a) => a?.label == item?.title
                              )?.bgColor
                            : "#FAFAFA",
                        color:
                          selectedFocus ==
                          goalFocusTime?.find((a) => a?.label == item?.title)
                            ?.label
                            ? goalFocusTime?.find(
                                (a) => a?.label == item?.title
                              )?.color
                            : "#9C9A96",
                      }}
                      className={`w-[52px] rounded-lg h-[52px] flex flex-row items-center justify-center border`}
                    >
                      {goalFocusTime?.find((a) => a?.label == item?.title)?.svg}
                    </div>
                    <div
                      className={`flex cursor-pointer flex-col text-primary-neutral-800`}
                    >
                      <p className="font-lato text-base font-bold capitalize tracking-[0.64px] leading-6">
                        {
                          goalFocusTime?.find((a) => a?.label == item?.title)
                            ?.label
                        }
                      </p>{" "}
                      <p className="font-lato text-2xs font-normal capitalize tracking-[0.2px] leading-4">
                        {
                          goalFocusTime?.find((a) => a?.label == item?.title)
                            ?.time
                        }
                      </p>
                    </div>
                  </div>
                  <div
                    className={`flex flex-row items-center justify-center text-xs font-lato tracking-[0.24px] leading-4 rounded-full w-6 h-6 ${
                      selectedFocus ==
                      goalFocusTime?.find((a) => a?.label == item?.title)?.label
                        ? "bg-primary-neutral-800 text-white font-bold"
                        : "text-primary-neutral-400 bg-primary-neutral-100 font-bold"
                    }`}
                  >
                    {getCount
                      ? getCount(
                          goalFocusTime?.find((a) => a?.label == item?.title)
                            ?.label
                        )
                      : 0}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default PickUpLater;
