import { MdPlayCircleFilled } from "react-icons/md";
import Exclude from "assets/svg/Exclude.svg";
import fiIcon from "assets/svg/fiIcon.svg";
import heart from "assets/svg/heart.svg";
import muscle from "assets/svg/muscle.svg";
import world from "assets/svg/world.svg";
import money from "assets/svg/money.svg";
import rectangle from "assets/images/icons/rectangle.png";
import { CiHeart } from "react-icons/ci";
import { Heading } from "./ServiceActivated";
import { CgSpinner } from "react-icons/cg";

const ActivateService = ({ onActivate, loading, service }) => {
  return (
    <>
      <div className="w-full mt-[110px] flex flex-col gap-10 items-center pt-8 pb-28 px-4">
        <div className="flex flex-col gap-4  justify-center items-center">
          <img
            src={rectangle}
            className="w-[50px] h-[50px]"
            alt="dashboard"
          ></img>
          <div className="flex-col justify-center items-center inline-flex">
            <div className="self-stretch text-center text-black text-xl font-bold font-satoshi capitalize leading-[30px] tracking-wide">
              {service?.name}
            </div>
            <div className="text-center text-primary-neutral-500 text-sm font-normal font-satoshi uppercase leading-[21px] tracking-wider">
              {service?.niche}
            </div>
          </div>
        </div>
        <div className="text-primary-neutral-800 text-sm font-normal font-satoshi leading-[21px] tracking-wide">
          {service?.description}
        </div>
        <div className="justify-start items-start gap-2 flex w-full">
          <div className="grow shrink basis-0 flex-col justify-center items-center gap-2 inline-flex">
            <div className="text-primary-neutral-800 text-2xl font-bold font-satoshi leading-9 tracking-wide">
              00
            </div>
            <div className="text-primary-neutral-500 text-xs font-normal font-satoshi leading-[18px] tracking-wide">
              Active Users
            </div>
          </div>
          {/* <div className="w-0.5 self-stretch bg-primary-neutral-200 rounded-full" />
        <div className="grow shrink basis-0 flex-col justify-center items-center gap-2 inline-flex">
          <div className="text-primary-neutral-800 text-2xl font-bold font-satoshi leading-9 tracking-wide">
            00
          </div>
          <div className="text-primary-neutral-500 text-xs font-normal satoshi leading-[18px] tracking-wide">
            Contracts Available
          </div>
        </div> */}
        </div>

        <div className="h-[200px] w-full p-1 bg-primary-neutral-100 rounded-2xl justify-center items-center gap-2 inline-flex">
          <MdPlayCircleFilled size={30} />
        </div>

        <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-8 w-full">
          <div className="w-full flex justify-between items-center gap-4">
            <Heading title="Key Technical Skills" />
          </div>
          <div className="w-full flex justify-center items-start gap-24">
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="w-20 h-20 p-1 bg-orange-50 rounded-xl flex justify-center items-center">
                <img src={fiIcon}></img>
              </div>
              <div className="w-full text-center text-black text-sm font-medium font-satoshi leading-[21px] tracking-wide">
                Persona Creation
              </div>
            </div>
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="w-20 h-20 p-1 bg-orange-50 rounded-xl flex justify-center items-center">
                <img src={fiIcon} />
              </div>
              <h1 className="w-full text-center text-black text-sm font-medium font-satoshi leading-[21px]  tracking-wide">
                Scenario <br /> Development
              </h1>
            </div>
          </div>
          {/* <div className="w-full px-4 py-3 bg-primary-neutral-50 rounded-[32px] flex items-center gap-2">
          <BsStars color="red" />
          <h1 className="text-primary-neutral-500 text-xs font-normal font-satoshi leading-[18px] tracking-wide ">
            Some insight here on their skill affinity
          </h1>
        </div> */}
        </div>

        <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-8 w-full">
          <div className="w-full flex justify-between items-center gap-4">
            <Heading title="Service Deliverables" />
          </div>
          <p className="w-full text-primary-neutral-800 text-sm font-normal font-satoshi ">
            You'll explore what other companies are doing and what potential
            customers need, helping startups carve their niche.
          </p>
          <div className="w-full flex flex-col justify-start items-start gap-3">
            <h1 className="text-stone-400 text-sm font-bold font-satoshi">
              Deliverables:
            </h1>
            <div className="w-full flex flex-col justify-start items-start gap-3">
              {service?.deliverables?.map((d, k) => {
                return (
                  <div
                    className="w-full p-2 bg-primary-neutral-50 rounded-lg flex justify-start items-center gap-4"
                    key={k}
                  >
                    <div className="min-w-[40px] w-[40px] h-10 bg-[#D9D9D9] rounded" />
                    <div className="flex flex-col justify-start items-start">
                      <span className="w-full text-primary-neutral-800 text-xs font-bold font-satoshi capitalize">
                        {d}
                      </span>
                      <p className="w-full text-primary-neutral-500 text-xs font-normal font-satoshi capitalize leading-[18px] tracking-wide">
                        Short description of what this deliverable would
                        comprise of
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-8 w-full">
          <div className="w-full flex justify-between items-center gap-4">
            <Heading title="Ikigai Connection" />
          </div>
          <img src={Exclude} />
          <p className="w-full text-primary-neutral-800 text-sm font-normal font-satoshi leading-[21px] tracking-wide">
            Your talent for <span className="font-bold">detailed analysis</span>{" "}
            and interest in{" "}
            <span className="font-bold">competitive landscapes</span> meet the
            startup need for{" "}
            <span className="font-bold">strategic positioning</span> in a{" "}
            <span className="font-bold">competitive ecosystem.</span>
          </p>
          <div className="w-full grid grid-cols-2  items-start gap-4">
            <div className="flex flex-col items-center p-4 rounded-2xl border-2 border-red-400 gap-2">
              <img src={heart} />
              <span className="text-center text-primary-neutral-800 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                Competitive Landscapes
              </span>
            </div>
            <div className="flex flex-col items-center p-4 rounded-2xl border-2 border-orange-400 gap-2">
              <img src={muscle} />
              <span className="text-center text-primary-neutral-800 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                Strategic Positioning
              </span>
            </div>
            <div className="flex flex-col items-center p-4 rounded-2xl border-2 border-blue-300 gap-2">
              <img src={world} />
              <span className="text-center text-primary-neutral-800 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                Detailed Analysis
              </span>
            </div>
            <div className="flex flex-col items-center p-4 rounded-2xl border-2 border-green-400 gap-2">
              <div className="w-6 h-6 relative">
                <img src={money} />
              </div>
              <span className="text-center text-primary-neutral-800 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                Competitive Ecosystem
              </span>
            </div>
          </div>
        </div>
        <div className="p-2 rounded-3xl flex flex-col justify-start items-center gap-8 w-full">
          <div className="flex self-stretch justify-between items-center gap-4">
            <Heading title="Fluid Career Pathways" />
          </div>
          <div className="flex justify-center items-start gap-0">
            <div className="flex flex-col justify-start items-start gap-0">
              <div
                className="pr-[53px] pt-16 pb-8 rounded-br-[80px] border-r-8 border-b-4"
                style={{
                  borderRightColor: "#fda4af",
                  borderBottomColor: "#a5f3fc",
                }}
              >
                <div className="text-center text-red-500 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                  Market Strategist
                </div>
              </div>
              <div
                className="w-[156px] pr-[52px] pt-[131px] pb-12 rounded-br-[80px] border-r-8 border-b-4"
                style={{
                  borderRightColor: "#fda4af",
                  borderBottomColor: "#a5f3fc",
                  marginTop: "-128px",
                }}
              >
                <div className="text-center text-red-500 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                  Strategic Planner
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start gap-0">
              <div
                className="pl-8 pt-[78px] pb-28 rounded-bl-[80px] border-l-8 border-b-4"
                style={{
                  borderLeftColor: "#fda4af",
                  borderBottomColor: "#a5f3fc",
                }}
              >
                <div className="text-center text-red-500 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                  Competitive Intelligence Analyst
                </div>
              </div>
              <div
                className="w-[156px] pl-14 pt-[135px] pb-8 rounded-bl-[80px] border-l-8 border-b-4"
                style={{
                  borderLeftColor: "#fda4af",
                  borderBottomColor: "#a5f3fc",
                  marginTop: "-128px",
                }}
              >
                <div className="text-center text-red-500 text-xs font-bold font-satoshi leading-[18px] tracking-wide">
                  Business Analyst
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-[328px] h-52 p-2 rounded-3xl flex-col justify-start items-center gap-8 inline-flex">
        <div className="flex self-stretch justify-between items-center gap-4">
          <Heading title="Available Work Contracts" />
        </div>
        <div className="self-stretch justify-start items-start gap-x-16 inline-flex">
          <div className="flex flex-col justify-center items-center gap-2">
            <div className="text-primary-neutral-800 text-2xl font-bold font-satoshi leading-9 tracking-wide">
              20+
            </div>
            <div className="text-primary-neutral-500 text-xs font-normal font-satoshi ">
              Learning Contracts
            </div>
          </div>
          <div className="w-0.5 bg-primary-neutral-200 rounded-[120px]" />
          <div className="flex flex-col justify-center items-center gap-2">
            <div className="text-primary-neutral-800 text-2xl font-bold font-satoshi leading-9 tracking-wide">
              15+
            </div>
            <div className="text-primary-neutral-500 text-xs font-normal font-satoshi leading-[18px] tracking-wide">
              Paid Contracts
            </div>
          </div>
        </div>
        <div className="self-stretch text-center text-primary-neutral-800 text-sm font-normal font-satoshi ">
          Activate the service to see the available learning an paid work
          contracts
        </div>
      </div> */}
        <div
          className="w-[328px] h-14 px-6 py-4 rounded-[64px] border border-red-500 justify-center items-center gap-2 inline-flex"
          onClick={onActivate}
        >
          {!loading && <CiHeart color="red" size={20} />}

          <button className="text-red-500 text-base font-bold font-satoshi capitalize leading-normal tracking-wider">
            {loading ? (
              <div className="flex items-center gap-2">
                <CgSpinner className="animate-spin" size={20} />
                Adding...
              </div>
            ) : (
              "Add to Career Pathway"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ActivateService;
