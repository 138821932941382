import React, { useState, useEffect } from "react";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";

function SprintChart() {
  const [labels, setLabels] = useState([
    "Sprint 1",
    "Sprint 2",
    "Sprint 3",
    "Sprint 4",
  ]);

  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Session",
        data: [2, 4, 3, 6],
        backgroundColor: "#A193F2",
      },
    ],
  });
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: "Sprint Chart",
      },
    },
  };

  // useEffect(() => {
  //   let minDate = moment().add(-7, "days");
  //   let maxDate = moment();
  //   let array = [];
  //   let tot = 0;
  //   while (minDate.isSameOrBefore(maxDate)) {
  //     array.push(minDate.format("YYYY-MM-DD"));
  //     minDate.add(1, "days");
  //   }
  //   let data = [];
  //   array.map((i) => {
  //     data?.push(
  //       list?.find(
  //         (a) =>
  //           moment(a?.date).format("YYYY-MM-DD") ==
  //           moment(i).format("YYYY-MM-DD")
  //       )?.sessionCount || 0
  //     );
  //     tot += parseInt(
  //       list?.find(
  //         (a) =>
  //           moment(a?.date).format("YYYY-MM-DD") ==
  //           moment(i).format("YYYY-MM-DD")
  //       )?.sessionCount || 0
  //     );
  //   });

  //   setLabels(array.reverse()?.map((a) => moment(a).format("DD")));
  //   setData({
  //     labels,
  //     datasets: [
  //       {
  //         label: "Session",
  //         data: data?.reverse(),
  //         backgroundColor: "#A193F2",
  //       },
  //     ],
  //   });
  // }, [list]);
  return <div>{/* <Bar options={options} data={data} /> */}</div>;
}

export default SprintChart;
