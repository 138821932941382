import * as JournalAPI from "../../config/APIs/journals";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  journals: [],
  onGoing: {},
  manualCheckOut: [],
};

// Storing journals
export const journals = createSlice({
  name: "journals",
  initialState,
  reducers: {
    updateJournals: (state, action) => {
      state.journals = action.payload.journals;
    },
    updateOngoingJournals: (state, action) => {
      state.onGoing = action.payload.onGoing;
    },
    updateManualCheckoutJournals: (state, action) => {
      state.manualCheckOut = action.payload.manualCheckout;
    },
  },
});

export const {
  updateJournals,
  updateOngoingJournals,
  updateManualCheckoutJournals,
} = journals.actions;

export default journals.reducer;

export const fetchAllJournals = () => {
  return async (dispatch) => {
    try {
      const response = await JournalAPI.findMyJournals();
      if (response.status === 200) {
        let data = response.data?.data;
        // Dispatching journals data to redux-store
        dispatch(
          updateJournals({
            journals: data,
          })
        );
      }
    } catch (err) {
      console.log("Journals fetching error", err);
    }
  };
};

export const fetchOngoingJournals = () => {
  return async (dispatch) => {
    try {
      const response = await JournalAPI.getOnGoingJournal();
      if (response.status === 200) {
        let data = response.data;
        // Dispatching ongoing journal data to redux-store
        dispatch(
          updateOngoingJournals({
            onGoing: data,
          })
        );
      }
    } catch (err) {
      console.log("Ongoing journals fetching error", err);
    }
  };
};

export const fetchManualCheckoutJournals = () => {
  return async (dispatch) => {
    try {
      const response = await JournalAPI.getManualCheckoutJournals();
      if (response.status === 200) {
        let data = response.data;
        // Dispatching manually checked out journal data to redux-store
        dispatch(
          updateManualCheckoutJournals({
            manualCheckout: data,
          })
        );
      }
    } catch (err) {
      console.log("Manual Checkout journals fetching error", err);
    }
  };
};
