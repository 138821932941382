import { valueQuestionArr } from "components/ProfileForm/LaptopForm/components/WebValueQuizForm/helpers/constant";
import usePhaseOne from "components/ProfileForm/hooks/usePhaseOne";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import { motion } from "framer-motion";
import { FormComponents } from "helpers/constants/profileForm";
import { scrollToTop } from "helpers/utils/profileForm/functions";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import {
  hideNavigation,
  showNavigation,
  updateDetails,
  updateValueQuizStep,
} from "redux/profile-form";
import ValueQuizNavigation from "./ValueQuizNavigation";
import { showToast } from "redux/toaster";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
const ArrowButton = ({ type, onClick }) => {
  return (
    <button
      className={`fixed z-10 top-1/2 ${
        type === "next" ? "right-0 " : "left-0  "
      } transform -translate-y-1/2 disabled:text-primary-grey-300 p-3 rounded-full hover:bg-primary-grey-100 hover:shadow-md `}
      onClick={onClick}
    >
      {type === "next" ? (
        <ChevronRightIcon className="w-6" />
      ) : (
        <ChevronLeftIcon className="w-6" />
      )}
    </button>
  );
};

const ValueQuizForm = ({ field }) => {
  const quizStep = useSelector(
    (state) => state.profileForm.phaseOne.details.valueQuiz.quizStep
  );
  const { details, step, isNavigationVisible } = useSelector(
    (state) => state.profileForm.phaseOne
  );

  const dispatch = useDispatch();
  const sliderRef = useRef(null);
  const [questionRef, isQuestionInView] = useInView({ threshold: 1 });
  const valueQuiz = useMemo(() => details[field], [details]);

  const { checkUnattemptedQuiz } = usePhaseOne(field);
  const settings = {
    customPaging: function (index) {
      return (
        <motion.p
          className={` ${
            quizStep === index
              ? " underline-offset-2 text-primary-grey-600 font-semibold underline "
              : "text-primary-grey-400"
          } `}
        >
          {index + 1}
        </motion.p>
      );
    },
    dots: true,
    arrows: true,
    adaptiveHeight: true,
    dotsClass: "slick-dots slick-thumb",
    className: "slides",
    lazyLoad: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => dispatch(updateValueQuizStep(next)),
    afterChange: (current) => dispatch(updateValueQuizStep(current)),
    nextArrow: <ArrowButton type="next" />,
    prevArrow: <ArrowButton type="prev" />,
  };

  const handleAnswerSelection = (quizStep, option) => {
    let updatedQuiz;
    const currentQuiz = valueQuiz?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );

    if (currentQuiz && currentQuiz?.options.includes(option)) {
      updatedQuiz = details[field].quizData.filter(
        (quiz) => quiz.quizStep !== quizStep
      );

      dispatch(
        updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
      );

      return;
    }
    if (currentQuiz) {
      updatedQuiz = valueQuiz.quizData.map((quiz) => {
        if (quiz.quizStep === quizStep) {
          return {
            ...quiz,
            options: [option],
          };
        }
        return quiz;
      });
    } else {
      updatedQuiz = [
        ...valueQuiz.quizData,
        {
          quizStep,
          options: [option],
        },
      ];
    }

    dispatch(
      updateDetails({ field, value: { quizStep, quizData: updatedQuiz } })
    );
    scrollQuizTop();
  };

  const scrollQuizTop = useCallback(() => {
    if (valueQuiz.quizData.length === valueQuestionArr.length) {
      scrollToTop();
    }
  }, [details]);

  useEffect(() => {
    sliderRef.current.slickGoTo(quizStep);
    dispatch(hideNavigation());
  }, [quizStep, sliderRef]);

  const getDisabledState = () => {
    const currentQuiz = valueQuiz?.quizData.find(
      (quiz) => quiz.quizStep === quizStep
    );
    if (currentQuiz && currentQuiz?.options.length > 0) return false;
    return true;
  };

  const handleButtonWrapperClick = () => {
    if (valueQuiz?.quizData.length !== valueQuestionArr.length) {
      dispatch(updateValueQuizStep(checkUnattemptedQuiz()));
      if (quizStep !== checkUnattemptedQuiz() - 1) {
        dispatch(
          showToast({
            message:
              "You have to select at least one option from each section .",
            type: "error",
          })
        );
      }
    }
  };
  return (
    <div className=" font-manrope   flex flex-col relative scrollbar-hidden  pt-24 ">
      <div
        ref={questionRef}
        className="flex flex-col gap-4  h-full px-4 md:px-10  "
      >
        <h1 className="text-primary-blue-400 text-lg font-medium ">
          Which of these values would be the most important for you to uphold?
        </h1>

        <h3 className="text-md text-primary-grey-600">
          Think about what you consider to be the most important out of these
          qualities
        </h3>
      </div>
      <div className={` ${!isQuestionInView && "sticky top-0  "}   `}>
        <div className={` pb-32  `}>
          <Slider ref={sliderRef} {...settings}>
            {valueQuestionArr.map((question, idx) => (
              <div
                key={idx}
                className={` flex flex-col h-[60vh] ${
                  !isQuestionInView && "overflow-y-scroll"
                }  items-center px-6 mt-10    `}
              >
                <div className="flex flex-col gap-6  ">
                  <img
                    src={question.icon}
                    className="w-16 h-16 object-contain mx-auto"
                  />
                  <h2 className="font-satoshi font-bold text-sm text-primary-grey-800 mx-auto">
                    {quizStep + 1}. {question.title}
                  </h2>
                </div>
                {question.options.map((option, idx) => {
                  const isOptionSelected =
                    details[field]?.quizData.find(
                      (quiz) => quiz.quizStep === quizStep
                    ) &&
                    details[field]?.quizData
                      .find((quiz) => quiz.quizStep === quizStep)
                      ?.options.includes(option);
                  return (
                    <div
                      key={idx}
                      onClick={() => handleAnswerSelection(quizStep, option)}
                      style={{
                        borderColor: question.dark,
                        background: isOptionSelected
                          ? question.dark
                          : "transparent",
                        color: isOptionSelected ? "white" : "inherit",
                      }}
                      className={`w-full  cursor-pointer  p-4 text-sm border-[1.5px]  m-auto  rounded-full hover:border-[2.5px] grid place-items-center text-center text-primary-grey-500 my-6`}
                    >
                      <p>{option}</p>
                    </div>
                  );
                })}
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {!isNavigationVisible && (
        <ValueQuizNavigation
          totalSteps={FormComponents(step, details).length}
          field={field}
          isInView={isQuestionInView}
        />
      )}
      <div className="grid place-items-center p-4 bg-white fixed bottom-0 inset-x-0 ">
        <div
          onClick={handleButtonWrapperClick}
          className={` transition-all duration-300 ease-in-out  ${
            !isQuestionInView
              ? " opacity-100 translate-y-0 "
              : "translate-y-full opacity-0"
          }   `}
        >
          <ProfileButton
            onClick={() => dispatch(updateValueQuizStep(quizStep + 1))}
            disabled={getDisabledState()}
            text={"Next"}
            size={"large"}
            variant={"filled"}
            icon={<ChevronRightIcon className="w-6" />}
            iconAlign={"right"}
          />
        </div>
      </div>
    </div>
  );
};

export default ValueQuizForm;
