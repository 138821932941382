import Goals from "components/CareerGoals/Goals";
import { useHistory } from "react-router-dom";

export default function CareerGoals() {
  const history = useHistory();
  return (
    <div className="pt-14">
      <div
        onClick={() => history.goBack()}
        className="flex flex-row items-center justify-between space-x-2 py-2 px-4"
      >
        <div className="flex gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13.3337 7.33073H5.22033L8.94699 3.60406L8.00033 2.66406L2.66699 7.9974L8.00033 13.3307L8.94033 12.3907L5.22033 8.66406H13.3337V7.33073Z"
              fill="#696763"
            />
          </svg>
          <p className="text-primary-neutral-500 font-satoshi text-xs cursor-pointer underline capitalize font-bold">
            Back
          </p>
        </div>
        <img
          src="/assets/images/tie.png"
          className="h-8 w-8 object-contain"
          alt="Tie"
        />
      </div>
      <Goals
        onSubmit={() => {
          history.goBack();
        }}
      />
    </div>
  );
}
