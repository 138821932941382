import { useState } from "react";
import React from "react";
import WideModalsWrapper from "components/Modals/ModalsWrapper/WideModalWrapper";
import LogoutModal from "./LogoutModal";
import PageHeader from "./PageHeader";
import Sidebar from "./Sidebar";

function DesktopWrapper({ children }) {
  const [logOut, setLogOut] = useState(false);

  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("navList");
    localStorage.removeItem("selectedTab");
    window.location.href = window.location.origin + "/";
  };

  const isMyDeskRoute = location.pathname === "/mydesk";
  return (
    <>
      <WideModalsWrapper
        isOpen={logOut}
        closeModal={() => {
          setLogOut(false);
        }}
        component={<LogoutModal onLogout={onLogout} />}
      />

      <div className="w-screen h-screen lg:flex flex-row items-stretch hidden">
        <Sidebar />

        <div className="flex flex-col w-full bg-primary-neutral-50">
          {!isMyDeskRoute && <PageHeader />}
          <div
            className={`flex flex-col items-stretch mx-auto ${
              isMyDeskRoute ? "w-full" : "w-[80vw] max-w-[1380px]"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default DesktopWrapper;
