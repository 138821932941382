import React, { useEffect, useState } from "react";
import { getDashboardData } from "config/APIs/dashboard";
import PreProgramDashboard from "components/Dashboard/PreProgramDashboard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PageLoader from "pages/Onboarding/PageLoader";

function Today() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [onLoaded, setonLoaded] = useState(false);

  useEffect(() => {
    setLoader(location?.state?.loader);
    if (location?.state?.loader) {
      setTimeout(() => {
        setonLoaded(true);
        setTimeout(() => {
          setLoader(false);
        }, 500);
      }, 6000);
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await getDashboardData();
      let data = response.data.data;
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => fetchData(), []);

  return (
    <>
      {loader && <PageLoader disappear={onLoaded} />}
      <div className="relative overflow-y-auto max-h-[95vh] bg-[#fafafa]">
        <PreProgramDashboard data={data} />
      </div>
    </>
  );
}

export default Today;
