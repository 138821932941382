import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-none",
  round: "rounded-lg",
  circle: "rounded-[50%]",
};
const variants = {
  outline: {
    red_500: "border-b-4 border-red-500 border-solid text-black-900",
    gray_300: "border border-gray-300 border-solid",
  },
  fill: {
    red_500: "bg-red-500",
    white_A700: "bg-white-A700",
    gray_100: "bg-gray-100",
    deep_orange_300: "bg-deep_orange-300 shadow-bs",
    gray_50: "bg-gray-50",
  },
  gradient: { yellow_200_deep_orange_300: "bg-gradient ",
              orange: "rgba(255, 233, 154, 1)"
},
};
const sizes = { xs: "p-0.5", sm: "p-2", md: "px-4 py-[17px]" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "circle",
  size = "xs",
  variant = "fill",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round", "circle"]),
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  variant: PropTypes.oneOf(["outline", "fill", "gradient"]),
  color: PropTypes.oneOf([
    "red_500",
    "gray_300",
    "white_A700",
    "gray_100",
    "deep_orange_300",
    "gray_50",
    "yellow_200_deep_orange_300",
  ]),
};

export default Button;
