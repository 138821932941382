import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CareerGoals() {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);

  return (
    <div className="flex space-y-8 py-8 px-6 items-center text-center flex-col w-full">
      <div className="flex space-y-2 flex-col w-full text-left">
        <p className="text-primary-neutral-800 font-bold font-lato text-lg tracking-[0.36px] leading-6">
          Let’s get Started, {user?.name}
        </p>
      </div>
      <div className="flex w-full justify-center items-start gap-4 h-[calc(100svh-180px)]">
        <div className="flex pt-1 flex-col items-center">
          <div className="flex w-4 h-4 justify-center items-center rounded-full border-2 border-caaryaRed"></div>
          <div
            className="h-[370px] w-[2px]"
            style={{
              backgroundImage:
                "linear-gradient(to top, transparent 50%, #ED4C41 50%)",
              backgroundSize: "100% 10px",
            }}
          ></div>
        </div>
        <div className="flex w-full pb-10 justify-center flex-col items-center gap-6 flex-[1_0_0]">
          <div className="flex flex-col items-start gap-3 self-stretch">
            <img
              src="/assets/images/tie.png"
              className="h-6 w-6 object-contain"
              alt="Tie"
            />
            <div className="flex gap-1 flex-col items-start">
              <p className="text-primary-neutral-800 font-satoshi text-sm font-bold leading-6 tracking-[0.28px]">
                Talk to your Personal Growth Manager
              </p>
              <p className="text-primary-neutral-400 font-satoshi text-xs font-normal leading-[8px] tracking-[0.48px]">
                To set up your career goals
              </p>
            </div>
          </div>

          <img
            src="/assets/images/target.svg"
            alt="Career Goals"
            className="w-[171px] h-[120px] object-contain"
          />

          <div className="flex flex-col items-center gap-4 self-stretch">
            <p className="text-center text-primary-neutral-800 font-satoshi text-sm font-medium leading-5 tracking-[0.28px]">
              You can get in touch with your PGM from your inbox
            </p>
            <div className="flex flex-row items-center justify-center">
              <div
                onClick={() => history.push("/inbox")}
                className="bg-primary-neutral-900 rounded-full py-3.5 px-14 text-white font-satoshi text-sm font-bold leading-7 tracking-[0.9px] cursor-pointer"
              >
                View Now
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerGoals;
