import React, { useEffect } from "react";
import QuestionComponent from "./components/QuestionComponent";
import MobileQuestionComponent from "./components/QuestionComponent/Mobile";
import QuestionNavigation from "./components/QuestionNavigation";
import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useMediaQuery, useTheme } from "@mui/material";
import ProfileButton from "components/ProfileForm/ui-components/ProfileButton";
import usePhaseThree from "../hooks/usePhaseThree";

const Type1Form = () => {
  const theme = useTheme();
  const largeView = useMediaQuery(theme.breakpoints.up("sm"));
  const ref = React.useRef(null);
  const containerRef = React.useRef(null);
  const {
    getFormDetails,
    getCurrentQuestion,
    handleLimitInc,
    handleLimitDec,
    handleQuestionChange,
  } = usePhaseThree();
  const { questions, limitPerPage } = getFormDetails();
  const currentQuestion = getCurrentQuestion();
  const [currLimit, setCurrLimit] = React.useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = React.useState(true);
  const [continueButtonVisible, setContinueButtonVisible] =
    React.useState(false);

  const handleLimitDecrement = () => {
    const { isNextButtonDisabled, isPrevButtonDisabled, newLimit } =
      handleLimitDec(currLimit);
    setIsNextButtonDisabled(isNextButtonDisabled);
    setIsPrevButtonDisabled(isPrevButtonDisabled);
    setCurrLimit(newLimit);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleLimitIncrement = () => {
    const { isNextButtonDisabled, isPrevButtonDisabled, newLimit } =
      handleLimitInc(currLimit);
    setIsNextButtonDisabled(isNextButtonDisabled);
    setIsPrevButtonDisabled(isPrevButtonDisabled);
    setCurrLimit(newLimit);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const currQuestionChangeHandler = (questionId) => {
    const { isNextButtonDisabled } = handleQuestionChange(
      questionId,
      currLimit
    );
    setIsNextButtonDisabled(isNextButtonDisabled);
  };

  useEffect(() => {
    if (currLimit === 0) {
      setIsPrevButtonDisabled(true);
    }
    if (currLimit + limitPerPage >= questions?.length) {
      setContinueButtonVisible(true);
    } else {
      setContinueButtonVisible(false);
    }
  }, [currLimit]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentQuestion]);

  // TODO:  Add submit handler logic here
  const handleSubmit = () => {};

  return (
    <>
      <QuestionNavigation />
      <div className="container w-full max-w-7xl mx-auto mt-10 px-10 md:px-14">
        <div className="opacity-0 h-1 w-1 absolute" ref={containerRef}></div>
        {questions?.slice(currLimit, limitPerPage + currLimit).map((q, idx) =>
          largeView ? (
            <div
              key={q.id}
              ref={idx !== 0 && currentQuestion === q.id ? ref : null}
              className="py-4"
            >
              <QuestionComponent
                question={q}
                isActive={currentQuestion === q.id}
                currQuestionChange={currQuestionChangeHandler}
              />
            </div>
          ) : (
            <div
              key={q.id}
              ref={idx !== 0 && currentQuestion === q.id ? ref : null}
            >
              <MobileQuestionComponent
                question={q}
                isActive={currentQuestion === q.id}
                currQuestionChange={currQuestionChangeHandler}
              />
            </div>
          )
        )}
      </div>
      <div className="flex justify-center items-center gap-10 my-16">
        <ProfileButton
          onClick={handleLimitDecrement}
          text={"Back"}
          size={"large"}
          icon={<ArrowLeftIcon className="w-6" />}
          iconAlign={"left"}
          disabled={isPrevButtonDisabled}
        />
        {!continueButtonVisible ? (
          <button
            onClick={handleLimitIncrement}
            disabled={isNextButtonDisabled}
            className="relative bg-primary-blue-500 disabled:bg-primary-grey-300 text-white text-md font-bold py-4 pr-9 pl-6 rounded-2xl"
          >
            Next
            <ChevronRightIcon className="absolute h-6 w-6 right-0 top-[1px] translate-y-4 -translate-x-3" />
          </button>
        ) : (
          <button
            onClick={handleSubmit}
            className="relative bg-primary-blue-500 disabled:bg-primary-grey-300 text-white text-md font-bold py-4 pr-9 pl-6 rounded-2xl"
          >
            Continue
            <ChevronRightIcon className="absolute h-6 w-6 right-0 top-[1px] translate-y-4 -translate-x-3" />
          </button>
        )}
      </div>
    </>
  );
};

export default Type1Form;
