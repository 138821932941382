import React from "react";
import { useState } from "react";
import LearnMore from "./LearMore";
import { useHistory } from "react-router-dom";
import { ArrowRight } from "@phosphor-icons/react";

function Card({ item }) {
  const history = useHistory();
  const [selected, setSelected] = useState(false);
  return (
    <div
      onClick={() => {
        if (item?.path) {
          window.scrollTo({ top: 0 });
          history.push(item?.path);
        } else if (item?.link) {
          window.open(item?.link, "__blank");
        } else {
          setSelected(true);
        }
      }}
      style={{
        backgroundImage: `url(${item?.backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="rounded-2xl lg:w-[250px] xl:w-[300px] h-[300px]"
    >
      <LearnMore
        isOpen={selected}
        closeModal={() => {
          setSelected(false);
        }}
        item={item}
      />
      <div
        className={`relative rounded-2xl w-full h-full p-4 flex ${
          item?.unlocked || item?.completed
            ? "flex-col-reverse space-x-0"
            : "lg:flex-col-reverse flex-row space-x-2 lg:space-x-0"
        }  items-center `}
        style={{
          background: item?.background,
          color: item?.color,
        }}
      >
        <div
          className={`${
            item?.unlocked || item?.completed
              ? "mt-4 text-center items-center"
              : "items-start lg:items-center lg:mt-4 text-left lg:text-center"
          } flex flex-col space-y-2 w-full`}
        >
          <h1 className="font-poppins text-lg font-medium leading-7">
            {item?.name}
          </h1>
          <p className="font-inter text-sm font-light leading-5 h-20">
            {item?.description}
          </p>
          {item?.unlocked ? (
            <div
              // onClick={() => {
              //   if (item?.path) {
              //     window.scrollTo({ top: 0 });
              //     history.push(item?.path);
              //   } else if (item?.link) {
              //     window.open(item?.link, "__blank");
              //   } else {
              //     setSelected(true);
              //   }
              // }}
              className={`rounded-[12px] cursor-pointer w-full shadow-md bg-white py-3.5 px-8 flex flex-row items-center justify-center space-x-2 text-primary-green-700 font-inter text-sm font-semibold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.25 3.50095L5.416 2.87695L13.416 8.20995V9.45795L5.416 14.792L4.25 14.168V3.50095ZM5.75 4.90195V12.766L11.648 8.83395L5.75 4.90195Z"
                  fill="#008B46"
                />
              </svg>
              <p>Start Now</p>
            </div>
          ) : item?.completed ? (
            <div className="flex flex-col w-full">
              <div className="flex flex-row items-center space-x-1">
                <div
                  className="w-11/12 h-1.5"
                  style={{
                    background:
                      "linear-gradient(180deg, #FFF 0%, #FAFAFA 53.12%, #FFF 100%)",
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.0001 4.66656L11.0601 3.72656L6.83344 7.95323L7.77344 8.89323L12.0001 4.66656ZM14.8268 3.72656L7.77344 10.7799L4.98677 7.9999L4.04677 8.9399L7.77344 12.6666L15.7734 4.66656L14.8268 3.72656ZM0.273438 8.9399L4.0001 12.6666L4.9401 11.7266L1.2201 7.9999L0.273438 8.9399Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                className={`cursor-pointer w-full py-3.5 px-8 flex flex-row items-center justify-center space-x-2 text-white font-inter text-sm font-semibold`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.50714 5.01527C2.14663 6.13569 1.33333 7.46551 1.33333 8.50065C1.33333 9.5358 2.14663 10.8656 3.50714 11.986C4.84588 13.0885 6.53426 13.834 8 13.834C9.46574 13.834 11.1541 13.0885 12.4929 11.986C13.8534 10.8656 14.6667 9.5358 14.6667 8.50065C14.6667 7.46551 13.8534 6.13569 12.4929 5.01527C11.1541 3.91278 9.46574 3.16732 8 3.16732C6.53426 3.16732 4.84588 3.91278 3.50714 5.01527ZM2.65953 3.98603C4.15412 2.75519 6.13241 1.83398 8 1.83398C9.86759 1.83398 11.8459 2.75519 13.3405 3.98603C14.8133 5.19894 16 6.86913 16 8.50065C16 10.1322 14.8133 11.8024 13.3405 13.0153C11.8459 14.2461 9.86759 15.1673 8 15.1673C6.13241 15.1673 4.15412 14.2461 2.65953 13.0153C1.18671 11.8024 0 10.1322 0 8.50065C0 6.86913 1.18671 5.19894 2.65953 3.98603ZM4 8.50065C4 6.29163 5.79098 4.50065 8 4.50065C10.209 4.50065 12 6.29163 12 8.50065C12 10.7097 10.209 12.5007 8 12.5007C5.79098 12.5007 4 10.7097 4 8.50065ZM8 5.83398C6.52736 5.83398 5.33333 7.02801 5.33333 8.50065C5.33333 9.9733 6.52736 11.1673 8 11.1673C9.47264 11.1673 10.6667 9.9733 10.6667 8.50065C10.6667 7.02801 9.47264 5.83398 8 5.83398Z"
                    fill="white"
                  />
                </svg>
                <p className="underline underline-offset-2">View Response</p>
              </div>
            </div>
          ) : (
            <p
              onClick={() => {
                if (item?.path) {
                  window.scrollTo({ top: 0 });
                  history.push(item?.path);
                } else if (item?.link) {
                  window.open(item?.link, "__blank");
                } else {
                  setSelected(true);
                }
              }}
              className={`font-inter cursor-pointer text-sm text-left lg:text-right w-full font-semibold leading-5 underline underline-offset-2`}
            >
              {item?.comingSoon ? "Coming Soon" : "Learn More"}
            </p>
          )}
        </div>
        {/* <img
          src={item?.image}
          alt=""
          className="w-20 h-20 lg:w-28 lg:h-28 object-contain"
        /> */}
        {item?.unlocked || item?.completed ? null : (
          <div
            className={`w-8 h-8 -ml-2 lg:ml-0  rounded-full flex flex-row items-center justify-center bg-primary-gray-80 absolute top-2 right-2 z-20`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.00008 1.33594C6.16208 1.33594 4.66675 2.83127 4.66675 4.66927V6.66927H4.00008C3.26475 6.66927 2.66675 7.26727 2.66675 8.0026V13.3359C2.66675 14.0713 3.26475 14.6693 4.00008 14.6693H12.0001C12.7354 14.6693 13.3334 14.0713 13.3334 13.3359V8.0026C13.3334 7.26727 12.7354 6.66927 12.0001 6.66927H11.3334V4.66927C11.3334 2.83127 9.83808 1.33594 8.00008 1.33594ZM12.0001 8.0026L12.0014 13.3359H4.00008V8.0026H12.0001ZM6.00008 6.66927V4.66927C6.00008 3.5666 6.89741 2.66927 8.00008 2.66927C9.10275 2.66927 10.0001 3.5666 10.0001 4.66927V6.66927H6.00008Z"
                fill="#9C9A96"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;

function PublicCard({ item }) {
  const history = useHistory();
  const [selected, setSelected] = useState(false);
  return (
    <>
      <LearnMore
        isOpen={selected}
        closeModal={() => {
          setSelected(false);
        }}
        item={item}
      />
      <div className="px-2">
        <div
          onClick={() => {
            setSelected(true);
          }}
          className={`relative rounded-2xl mx-auto p-3 h-[200px] w-[144px] flex flex-col justify-between space-y-2 `}
          style={{
            background: item?.mobileBackground,
            color: item?.color,
          }}
        >
          {item?.comingSoon ? (
            <p className="font-inter text-2xs font-light underline underline-offset-2 flex flex-row items-center justify-end space-x-1 text-white">
              Comming Soon
            </p>
          ) : (
            <p className="font-inter text-2xs font-light underline underline-offset-2 flex flex-row items-center justify-end space-x-1 text-white">
              <p>Learn More</p> <ArrowRight size={10} />
            </p>
          )}
          <h1 className="font-poppins text-sm text-white font-medium leading-5">
            {item?.name}
          </h1>
        </div>
      </div>
    </>
  );
}

export { PublicCard };
