import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useSelector } from "react-redux";

const StepFour = () => {
  const { details } = useSelector((state) => state.profileForm.phaseOne);
  return (
    <div className=" font-manrope h-full flex flex-col  p-4 md:px-10 gap-4 pt-24  ">
      <h4 className="text-xl text-primary-blue-500  font-medium ">
        {`Well Done ${details.name}`}
      </h4>
      <div className="flex items-center gap-10">
        <h4 className="text-md text-primary-grey-500 font-light">
          STEP 3 :
          <span className="text-primary-grey-500 font-medium ml-1 ">
            What I’m Good At
          </span>
        </h4>
        <div className="w-8 h-8 grid place-items-center rounded-full bg-primary-green-50 border-2 border-primary-green-500">
          {" "}
          <CheckIcon className="w-4" />{" "}
        </div>
      </div>

      <h4 className="text-lg text-primary-grey-500 max-w-sm font-medium ">
        STEP 4 :
        <span className="text-primary-green-500 font-medium ml-1 ">
          What I Can be Paid For
        </span>
      </h4>

      <div className="grid place-items-center gap-8">
        <img
          className="w-72 h-72 object-contain mt-8 mb-4 "
          src="https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg"
          alt=""
        />
        <p className="text-primary-grey-500 text-md font-satoshi ">
          You cannot go wrong doing the things you love. Figuring out and
          understanding what gives you happiness will help you in your efforts
          to find joy in your daily tasks.
        </p>
        <p className="text-primary-grey-500 text-md font-satoshi ">
          In the upcoming section, take a minute of introspection before
          answering the questions. Focus on the things that give you joy. You
          don’t necessarily have to be good at it. All that matters is that you
          love doing it!
        </p>
      </div>
    </div>
  );
};

export default StepFour;
