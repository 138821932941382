import { Drawer } from "@mui/material";
import { MdClose } from "react-icons/md";
import Radio from "@mui/material/Radio";
export const ViewProjectFilter = ({
  isOpen,
  title,
  icon,
  value,
  options,
  handleChange,
  closeModal,
}) => {
  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      open={isOpen}
      onClose={closeModal}
      transitionDuration={200}
      PaperProps={{
        style: {
          width: window.innerWidth < 1024 ? "100%" : "100vw",
          height: window.innerWidth < 1024 ? "" : "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          boxShadow: "none",
        },
      }}
    >
      <div className="flex flex-row items-end justify-between lg:justify-end h-[100vh]">
        <div className="bg-white justify-center w-screen lg:w-[720px] lg:h-screen relative rounded-t-[32px] lg:rounded-t-none">
          <div className="z-40 flex cursor-pointer flex-row items-center justify-center absolute -top-16 lg:top-[50%] lg:-left-20 left-0 right-0 w-full lg:max-w-max">
            <div
              onClick={() => {
                closeModal();
              }}
              className="text-white flex flex-row items-center justify-center h-10 w-10 bg-black rounded-full"
            >
              <MdClose className="w-[14px] h-[14px] text-[white]" />
            </div>
          </div>
          <div
            style={{
              boxShadow:
                "0 -4px 6px -4px rgba(0, 0, 0, 0.1), 0 -8px 15px -3px rgba(0, 0, 0, 0.1)",
            }}
            className="flex  items-center p-6 space-y-2 justify-between border-b-[1px] border-[#CFCDC9]  font-medium rounded-t-[32px] "
          >
            <div className="flex items-center gap-2">
              {icon}
              <p className="font-lato font-bold text-base text-primary-neutral-800 capitalize leading-6 tracking-[0.64px]">
                {title}
              </p>
            </div>
          </div>
          <div className="p-6 !pb-12 flex flex-col items-center self-stretch w-full">
            {options.map((opt, i) => {
              const isSelected = opt.value === value;
              return (
                <div
                  key={i}
                  className={`px-4 py-3 flex items-center justify-between self-stretch gap-4 rounded-lg w-full cursor-pointer ${
                    isSelected && "bg-[#FFF6F5]"
                  }`}
                  onClick={() => handleChange(opt.value)}
                >
                  <span
                    className={`font-satoshi text-sm  leading-5 ${
                      isSelected
                        ? "text-caaryaRed font-bold"
                        : "text-primary-neutral-800 font-normal"
                    }`}
                  >
                    {opt.label}
                  </span>
                  <Radio
                    checked={isSelected}
                    onChange={(e) => handleChange(e.target.value)}
                    value={opt.value}
                    name={opt.label}
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#ED4C41",
                      },
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
